import React, { useEffect, useState } from 'react';

import { Breadcrumb, Footer } from '../../components';

import './styles.scss';

import AcaoCircle from '../../assets/acoes-circle-icon.svg';
import ObraCircle from '../../assets/obras-circle-icon.svg';

import FilterObrasAcoes from 'components/filterHomeServidor';
import MakeTemposListCardsServidor from 'components/makeTemposListCardsServidor';

import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { useHomeServidor } from 'utils/context/homeServidorContext';
import { TitlePage } from 'components/TitlePage';
import { getObras } from 'services/resources/obrasServices';
import { getAcoes } from 'services/resources/acoesServices';
import { addInfosInActions, addInfosInObras } from 'utils/addInfosInObrasAndActions';
import InfoProvide from 'components/InfoProvide';

const antIcon = <LoadingOutlined spin />;

const HomeServidor = () => {
  /* pegando funções e variáveis do contexto de HomeServidor  */
  const { updateIniciativasList, isLoading, listIsEmpty, setIsLoading } =
    useHomeServidor();

  const [loadingObras, setLoadingObras] = useState(false);

  /* Links para o Breadcrumb */
  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/home-servidor',
      title: 'Lista de Equipamentos e ações',
    },
  ];

  /* Lista de categoruiasd  */
  const categoriasList = [
    {
      id: 1,
      categoria: 'Tempo de Nascer',
    },
    {
      id: 2,
      categoria: 'Tempo de Crescer',
    },
    {
      id: 3,
      categoria: 'Tempo de Brincar',
    },
    {
      id: 4,
      categoria: 'Tempo de Aprender',
    },
    {
      id: 5,
      categoria: 'Ações da Pandemia',
    },
  ];

  /* Pegando obras e ações da API quando abrir a página ou sempre que os parametros do filtro mudarem */
  useEffect(() => {
    setIsLoading(true);
    
    const responseAcoes = async () => {
      const dataAcoes = await getAcoes();

      const newdateAcoes = dataAcoes && addInfosInActions(dataAcoes);
      
      updateIniciativasList( newdateAcoes || [], "acoes");

    };

    const responseObras = async () => {

      setLoadingObras(true)

      const dataObras = await getObras().finally(() => setLoadingObras(false));
      
      const newDataObras = dataObras && addInfosInObras(dataObras);
      
      updateIniciativasList( newDataObras || [], "obras");


    };

    responseAcoes().finally(() => setIsLoading(false));
    responseObras().finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="home-servidor-container container-box">
        <Breadcrumb links={links} />
        <TitlePage
          title="Cadastro de Equipamentos e Ações"
          subtitle="Registre novas iniciativas"
        />

        <FilterObrasAcoes isLoadingObras={loadingObras}/>

        <div className="iniciativas-container">
          <div className="inf-type">
        
          <div>
            <img src={ObraCircle} alt="" />
            <span>Equipamentos</span>
          </div>
          <div>
            <img src={AcaoCircle} alt="" />
            <span>Ações</span>
          </div>
        </div>

        <InfoProvide 
          message="Os dados dos equipamentos são fornecidos pelo Sistema WebMapp do Governo do Ceará" 
          type="webmapp"
        />

          {
            ( loadingObras && !isLoading ) && <Spin
              className="spin-loading"
              tip="Aguarde... Estamos buscando os equipamentos"
              size="large"
              indicator={antIcon}
            />
          }

          {!isLoading ? (
            !listIsEmpty ? (
              categoriasList.map(categoria => (
                <MakeTemposListCardsServidor
                  key={categoria.id}
                  categoria={categoria.categoria}
                />
              ))
            ) : (
              !loadingObras && <span className="message"> Sem resultados </span>
            )
          ) : (
            <Spin
              className="spin-loading"
              tip="Aguarde"
              size="large"
              indicator={antIcon}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeServidor;
