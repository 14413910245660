import React from 'react';
import { 
	TopContent,
	PageTitle,
	PageSubTitle,
	GoBackButton,
	PageTitleContent
} from './styles';

import arrowPreviousGreen from '../../../assets/articulator/arrow-prev-green.svg';

interface IGoBackProps {
	title?: string;
	subtitle?: string;
	onClick: () => void;
  className?: string;
}

export function GoBack({ onClick, subtitle, title, className="" }: IGoBackProps) {
	//if (!title) title = ''
	//if (!subtitle) subtitle = ''
	return (
		<TopContent>
			<GoBackButton
        className={className}
				onClick={onClick}
			>
				<img src={arrowPreviousGreen} alt="back to articulator home" />
			</GoBackButton>
			<PageTitleContent>
				<PageTitle>
					{title}
				</PageTitle>
				<PageSubTitle>
					{subtitle}
				</PageSubTitle>
			</PageTitleContent>
		</TopContent>
  );
}