import React, { memo, useEffect, useRef, useState } from 'react';

import './styles.scss';

import { Link } from 'react-router-dom';

import logoCeara from '../../assets/logo-ceara-claro.svg';

import userIconImg from '../../assets/user-icon.svg';

import { Avatar } from 'antd';
import { Divider } from 'antd';

import { Authorizer } from 'components';
import { Device } from 'components/Device';
import HamburguerMenu from './HamburguerMenu';
import { UserOutlined } from '@ant-design/icons';

const Header = () => {

  const host = process.env.REACT_APP_LOGIN_URL;
  const appKey = process.env.REACT_APP_IRIS_SOCIAL_KEY;
  const url = `${host}/?origin=/programa-mais-infancia&app-key=${appKey}`;

  const clickForaRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [showSubMenuObras, setShowSubMenuObras] = useState(false);
  const [showSubMenuCartao, setShowSubMenuCartao] = useState(false);
  const [showSubMenuMapaCZRM, setShowSubMenuMapaCZRM] = useState(false);

  const [showOptions, setShowOptions] = useState(false);

  const device = Device.useDevice();

  const auth = Authorizer.useAuth();

  function getAgenteCMICRedirect() {
    const { isSPSAdmin, isArticulator, isPublicServer } = Authorizer.checkRoles;
    const { roles } = auth.user;

    if (isSPSAdmin(roles) || isPublicServer(roles)) {
      return '/coordenador-home';
    }

    if (isArticulator(roles)) return '/articulador-inicio';

    return '/nao-autorizado?redirect-uri=/programa-mais-infancia';
  }

  function getGestorRedirect() {
    const { isSPSAdmin } = Authorizer.checkRoles;
    const { roles } = auth.user;

    if (isSPSAdmin(roles)) {
      return '/gestor-cadastro';
    }

    return '/nao-autorizado?redirect-uri=/programa-mais-infancia';
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSubMenuObras(false)
          setShowSubMenuCartao(false)
          setShowSubMenuMapaCZRM(false)
          setShowOptions(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(clickForaRef);

  function closeOptions() {
    setShowOptions(false);
  }

  function openOptions() {
    setShowOptions(true);
  }

  function generateUserHeader() {

    return (
      <div className="login-header">
        {
          !auth.authorized ? (
            <div className="area-login" onClick={openOptions} style={{ cursor: 'pointer' }} >
              {device.isSM("bigger") && <p>Olá, bem vindo!</p>}

              <Avatar src={userIconImg} />
              {
                showOptions && <div ref={clickForaRef} className="servidor-login">
                  <h1>Área do Servidor</h1>

                  <a href={url} className="login-button" >ENTRAR</a>
                </div>
              }
            </div>
          ) : (
            <div className="area-login">
              <div onClick={openOptions} style={{ cursor: "pointer" }}>
                {device.isSM("bigger") && <p>{`Olá, ${auth.user.client.name}`}</p>}

                {
                  auth.user.client.image ? (
                    <Avatar src={auth.user.client.image} />
                  ) : (
                    <Avatar icon={<UserOutlined />} />
                  )
                }
              </div>

              {showOptions && (
                <div ref={clickForaRef} className="servidor-login">
                  <h1>Área do Servidor</h1>
                  {(auth.adm || auth.obrasAcoes) && <>
                    <h2>Obras e Ações</h2>
                    <Link to='/home-servidor' onClick={closeOptions}>
                      <h3>Cadastro de Equipamentos e Ações</h3>
                    </Link>
                    <Link to='/quantitativos-gerais' onClick={closeOptions}>
                      <h3>Quantitativos Gerais</h3>
                    </Link>
                  </>}

                  {(auth.adm || auth.pesquisaCMIC || auth.agent) && <h2>CMIC</h2>}

                  {(auth.adm || auth.pesquisaCMIC) && <Link to="/pesquisa-cmic" onClick={closeOptions}>
                    <h3>Pesquisa</h3>
                  </Link>}

                  {(auth.adm || auth.agent) && <Link to={getAgenteCMICRedirect()} onClick={closeOptions}>
                    <h3>Agente</h3>
                  </Link>}

                  <h2>Big Data Social</h2>


                  <a href={`${process.env.REACT_APP_BIGDATA_URL}`} target="_blank" rel="noopener noreferrer">
                    <h3>Início</h3>
                  </a>

                  {(auth.adm) && <Link to={getGestorRedirect()} onClick={closeOptions}>
                    <h3>Cadastro de gestores</h3>
                  </Link>}

                  <Divider />

                  <h3 onClick={() => {
                    auth.user.logout();
                  }}>
                    Sair
                  </h3>
                </div>
              )}
            </div>
          )
        }
      </div>
    )
  }

  return (
    <header className={`header ${device.prefix()}`}>
      <div className="container-box">

        {device.isMD("less") && <HamburguerMenu />}

        <div className="header-logos">
          <Link to="/programa-mais-infancia">
            <img src={logoCeara} alt="Logo do Governo do Estado do Ceará" />
          </Link>
        </div>

        <div className={`header-menu ${device.prefix()}`}>
          {generateUserHeader()}
          {!device.isMD("less") &&
            <div className="header-menu-elements">
              <div className="header-submenu">
                <Link to="/programa-mais-infancia">Início</Link>
              </div>
              <div className="header-submenu">
                <p
                  className={showSubMenuObras ? 'mid-menu after' : 'mid-menu'}
                  onClick={() => setShowSubMenuObras(!showSubMenuObras)}
                >
                  Equipamentos e Ações
                </p>
                {showSubMenuObras && (
                  <div ref={clickForaRef} className="header-submenu-options">
                    <div className="container-box">
                      <Link onClick={() => setShowSubMenuObras(!showSubMenuObras)} to="/dados-gerais">Indicadores</Link>
                      <Link onClick={() => setShowSubMenuObras(!showSubMenuObras)} to="/obras-detalhamento">Linha do Tempo</Link>
                      {/*                    */}
                      {/* <Link onClick={()=>setShowSubMenuObras(!showSubMenuObras)} to="/obras">Mapa</Link> */}
                    </div>
                  </div>
                )}
              </div>
              <div className="header-submenu">
                <p
                  className={showSubMenuCartao ? 'mid-menu after' : 'mid-menu'}
                // onClick={() => setShowSubMenuCartao(!showSubMenuCartao)}
                >
                  <Link to="/cartao-cmic">CARTÃO MAIS INFÂNCIA</Link>

                </p>
                {/* {showSubMenuCartao && (
                  <div ref={clickForaRef} className="header-submenu-options">
                    <div className="container-box">
                      <Link
                        onClick={() => setShowSubMenuCartao(!showSubMenuCartao)}
                        to="/cartao-cmic"
                      >
                        Cartão
                      </Link>
                      <Link
                        onClick={() => setShowSubMenuCartao(!showSubMenuCartao)}
                        to="/pesquisa-cmic"
                      >
                        Pesquisa
                      </Link>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="header-submenu">
                <p className={showSubMenuMapaCZRM ? 'mid-menu after' : 'mid-menu'}>
                  {/*        <Link to="/mapa-czrm">Mapa CZRM</Link> */}
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    </header>
  );
};


export default memo(Header);
