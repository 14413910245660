import React from "react";
import { DataInfo } from "../DataInfo";

import './styles.scss';

type DataBoxProps = {
  title: string,
  titleNumber?: string,
  dataList: any[],
}

export function DataBox({title, dataList, titleNumber}: DataBoxProps) {

  const withDe = titleNumber?.includes('milhões' || 'trilhões' || 'bilhões' || 'toneladas')

  return (
    <div className='data-box container-box'>
      { 
        titleNumber ? <h1> <span className='quantitativo'>{`${titleNumber}`}</span> {withDe ? `de ${title}` : title} </h1> :
        <h1>{title}</h1>
      }
      <div className='list-data'>
        {
          dataList.map((data, i) => {
            return (
              <DataInfo 
                key={i} 
                number={data.number} 
                titleOrange={data.titleOrange}
                titleBlack={data?.titleBlack}
                suffix={data?.suffix} 
                withBackground={data?.withBackground}
                titleCard={data?.titleCard}
              />
            )
          })
        }
      </div>
    </div>
  );
}