import React from 'react';
import { FormInstance } from 'antd';
import { FormContainer } from './style'
import { InfoBasicConfirm } from './InfoBasicConfirm';
import { CurrentSituationConfirm } from './CurrentSituationConfirm'


interface FormProps {
  form?: FormInstance;
}

export function FormObrasConfirm({ form }: FormProps) {
  return (
    <FormContainer>
      <InfoBasicConfirm form={form} />
    </FormContainer>
  );
};


FormObrasConfirm.CurrentSituationForm = function ({ form}: FormProps) {
  return (
    <FormContainer>
      <CurrentSituationConfirm form={form} />
    </FormContainer>
  );
}
