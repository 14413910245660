import styled from 'styled-components';
import { flexLayout } from '../../../styles/layouts';

export const StyledTitle = styled.div`
  h3.ant-typography {
    font-size: 18px;
  }
`;

export const StyledFormWrapper = styled.div`
  ${flexLayout('flex-start', 'stretch')}

  div.ant-picker {
    width: 100%;
  }
`;

export const StyledForm = styled.div`
  width: 100%;
`

export const StyledVerticalLine = styled.div`
  width: 3px;
  margin-bottom: 15px;
  background-color: #FFDE32;
  margin-right: 20px;
`