import React, { useEffect, useState } from 'react';
import { acoesFiltros } from '../../utils/inputsFiltros'
import 'moment/locale/pt-br';
import './styles.scss';


import { useHistoryAcao } from 'utils/context/historyAcaoContext';
import moment from 'moment';

type FiltroHistoricoAcaoProps = {
  idAcao: string
  nameActionCurrent: string
}

const FiltroHistoricoAcao = ({idAcao, nameActionCurrent}: FiltroHistoricoAcaoProps) => {
  const {
    selectedCitiesList,
    selectedRegiaoList,
    selectedTime,
    setSelectedCitiesList,
    setSelectedRegiaoList,
    setSelectedType,
    filterEvents,
    actionInfos,
    setSelectedTime,
    selectedType
  } = useHistoryAcao();
  const [acaoAtual, setAcaoAtual] = useState('')
  
  useEffect(() => {
    if(actionInfos?.total > 0) {
      for(let key in actionInfos.elementos[0]) {
        if(key === 'arquivosRegistro' ||
           key === 'id' ||
           key === 'ultimaModificacao' ||
           key === 'ultimoEditor') continue
        setAcaoAtual(key)
      }
    }
  }, [actionInfos])

  
  const filtrarEventos = () => {
    filterEvents(idAcao, nameActionCurrent)
  }

  const tagsCities = selectedCitiesList.map(city => {
    return (
      <p>{city.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase())}</p>
    );
  });

  const tagsRegioes = selectedRegiaoList.map(regiao => {
    return (
      <p>{regiao}</p>
    )
  })

  return (
  <>
    {acaoAtual !== '' && (
    <div className="history-list-filter">
      <section>
        {acoesFiltros[acaoAtual](setSelectedTime, setSelectedCitiesList, filtrarEventos, setSelectedRegiaoList, acaoAtual, setSelectedType)}
      </section>
      <section>
        {
           selectedTime?.length > 1 && (
            <p> De {moment(new Date(selectedTime[0])).format('yyyy-MM')} até {moment(new Date(selectedTime[1])).format('yyyy-MM')}</p>
           )
        }
        {tagsRegioes}
        {tagsCities}
        { selectedType &&
          <p>{selectedType}</p>
        }
      </section>
    </div>
  )}
  </>
  );
};

export default FiltroHistoricoAcao;
