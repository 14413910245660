import { isValidCEP } from '../../../utils/cep';
import { Rule } from 'rc-field-form/lib/interface';

const rules: Record<string, Rule[]> = {
  zipCode: [
    {
      required: true,
      validator(_, value) {
        if (isValidCEP(value)) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('CEP inválido'));
      },
    },
  ],
  address: [
    {
      required: true,
      message: 'Endereço não preenchido',
    },
  ],
  addressNumber: [
    {
      required: true,
      message: 'Número não preenchido',
    },
  ],
  district: [
    {
      required: true,
      message: 'Bairro não preenchido',
    },
  ],
  addressState: [
    {
      required: true,
      message: 'UF não preenchido',
    },
  ],
  addressCity: [
    {
      required: true,
      message: 'Município não preenchido',
    },
  ],
};

export default rules;
