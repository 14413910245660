import React, { ReactNode } from 'react';
import { Device } from 'components';

import './styles.scss';

interface SectionInfoYellowProps {
  image: string;
  title: string;
  children: ReactNode;
  text?: string;
}

const SectionInfoYellow = ({
  image,
  title,
  children,
  text,
}: SectionInfoYellowProps): JSX.Element => {
  const device = Device.useDevice();

  return (
    <section
      className={`container__sectionYellow ${
        device.isMD('less') && 'sectionYellow-isMD'
      }`}
    >
      <img
        src={image}
        alt="icone da section"
        className="container__sectionYellow-image"
      />

      <div className="container__sectionYellow-infos">
        <h1 className="title">{title}</h1>
        {children}
      </div>
      <p className="subtitle">{text}</p>
    </section>
  );
};

export default SectionInfoYellow;
