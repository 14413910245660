import styled from 'styled-components';
import { GeneralInfoBoxTitle } from 'pages/ArticulatorWorkPlan/styles';

export const Container = styled.div``;

export const MobileMain = styled.main`
  background: #fff;
  padding: 2rem 4rem;
  background: #fff;
`;

export const Main = styled.main`
  background: #fff;
  padding: 5rem 10rem;
  background: #fff;
`;

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.6rem;
  flex-wrap: wrap;
  margin-top: 5rem;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 2.5rem;
  color: #2d4040;
`;

export const SubTitle = styled.h2`
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2rem;
  color: #2d4040;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 40.1rem;
  min-width: 200px;
  height: max-content;

  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  align-items: center;
  text-align: center;

  padding: 2rem;
`;

export const WorkplanBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 40.1rem;
  min-width: 200px;
  height: max-content;

  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  align-items: center;
  text-align: center;

  padding: 2rem;

  .search {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 22.4rem;
    height: 36px;

    background: #ffffff;
    border: 1px solid #4b9f37;
    border-radius: 5px;

    text-decoration: none;
    text-transform: uppercase;
    color: #4b9f37;

    font-weight: bold;
    line-height: 2.2rem;

    padding: 1rem;
    transition: background 0.4s;

    &:hover {
      background: #55900e;
      color: #fff;
    }
  }
`;

export const BoxTitle = styled.h6`
  font-weight: 700;
  font-size: 2rem;
  line-height: 4rem;
  color: #2d4040;
`;

export const BoxDescription = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #2d4040;
`;

export const BoxButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22.4rem;
  height: 36px;

  background: #4b9f37;
  border: 0;
  border-radius: 5px;

  text-decoration: none;
  text-transform: uppercase;
  color: #fff;

  font-weight: bold;
  line-height: 2.2rem;

  padding: 1rem;
  transition: background 0.4s;
  cursor: pointer;

  &:hover {
    background: #55900e;
    color: #fff;
  }
`;

export const BottomContent = styled.div`
  padding: 3rem 16rem;
  background: #e5e5e5;

  .ant-divider {
    background: #ccc;
  }
`;

export const MobileBottomContent = styled.div`
  padding: 3rem 7rem;
  background: #e5e5e5;

  .ant-divider {
    background: #ccc;
  }
`;

export const BottomTitle = styled.h6`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #2d4040;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-top: 30px;

  .ant-form-item {
    min-width: 200px;
    width: 30%;
    flex-direction: column;

    &.centered {
      label {
        opacity: 0;
      }
    }

    .ant-form-item-label {
      text-align: left;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;

      label {
        color: #2d4040;
        &::after {
          display: none;
        }
      }
    }

    .ant-select,
    .ant-input {
      width: 100%;
      min-width: 200px;
      border-radius: 4px;
      height: 35px;
    }
  }

  button.clear-filters {
    text-decoration: underline;
    border: 0;
    background: transparent;
    height: max-content;
    color: #4b9f37;

    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 900px) {
    .ant-form-item {
      width: 100%;
    }
  }
`;

export const MobileBottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-top: 30px;

  .ant-form-item {
    min-width: 200px;
    width: 30%;
    flex-direction: column;

    &.centered {
      label {
        opacity: 0;
      }
    }

    .ant-form-item-label {
      text-align: left;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;

      label {
        color: #2d4040;
        &::after {
          display: none;
        }
      }
    }

    .ant-select,
    .ant-input {
      width: 100%;
      min-width: 200px;
      border-radius: 4px;
      height: 35px;
    }
  }

  button.clear-filters {
    text-decoration: underline;
    border: 0;
    background: transparent;
    height: max-content;
    color: #4b9f37;

    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 900px) {
    .ant-form-item {
      width: 100%;
    }
  }
`;

export const BottomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  height: 3.2rem;

  background: #4b9f37;
  border-radius: 5px;

  text-decoration: none;
  text-transform: uppercase;
  color: #fff;

  font-weight: bold;
  line-height: 2.2rem;

  padding: 1rem;
  border: 0;
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchResultsLink = styled.button`
  margin: 6px 0;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-decoration: underline;

  color: #4b9f37;
  width: max-content;
  cursor: pointer;
  border: 0;
  background: transparent;
`;

export const PendingIcon = styled.img`
  margin: 0 8px;
`;

// Comments
export const CommentContainer = styled.div`
  flex: 1;
  padding: 6rem 17rem;
  display: flex;
  flex-direction: column;

  background: #f2f2f2;
  height: 100%;
  position: relative;

  textarea.ant-input {
    min-height: 10rem;
    background: #fff;
    color: #464a4e;
  }

  .comment-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 992px;
    margin: 0 auto;
    gap: 2rem;
  }

  @media (max-width: 800px) {
    padding: 3rem;
  }
`;

export const CommentContainerTitle = styled(GeneralInfoBoxTitle)`
  margin: 0;
  text-align: left;
`;

export const CommentBox = styled.div`
  width: 100%;
  height: max-content;

  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 7px;

  .observation {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.3rem;

    color: #464a4e;
  }
`;

export const ProfileLikeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ProfileDateContent = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
  svg {
    width: 3.68rem;
    height: 3.68rem;
    border-radius: 50%;
  }
`;

export const CommentUserName = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;

  color: #464a4e;
`;

export const CommentDate = styled.span`
  font-size: 1.6rem;
  line-height: 2.2rem;

  color: #464a4e;
`;

export const LikeButton = styled.button`
  width: 3.6rem;
  height: 3.6rem;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #33a02c;
  border-radius: 5px;
  background: transparent;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 1rem;

  button {
    width: 16.4rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    height: 3.6rem;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
  }

  @media (max-width: 550px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: initial;

    button {
      width: 100%;
    }
  }
`;

export const CancelButton = styled.button`
  background: #ffffff;
  border: 1px solid #33a02c;
  color: #33a02c;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const CommentButton = styled.button`
  background: #4b9f37;
  border: 0;
  color: #ffffff;

  @media (max-width: 550px) {
    order: -1;
  }
`;

export const CountLikesContainer = styled.div`
  position: absolute;
  bottom: -5px;
  left: -5px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.6rem;
  height: 1.6rem;

  border-radius: 50%;
  background: #ffde32;

  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #464a4e;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftContent = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightContent = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;
