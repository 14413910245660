import { notificationToast } from "components/NotificationToast";
import { apiBDS } from "services/api";

export function getInfosMapCMICBds() {
  const response = apiBDS
    .get(`/cmic?aggregation=COD_IBGE&aggregations=MUNICIPIO`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getFamiliasBeneficiadas(){
  const response = apiBDS
    .get("/cmic")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Ocorreu um erro ao buscar as informações do Big Data Social sobre famílias beneficiadas!',
      });
      return Promise.reject(err);
    });
  return response;
}

export function getVisitasRealizadas(){
  const response = apiBDS
    .get("/familia-agentes")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Ocorreu um erro ao buscar as informações do Big Data Social sobre visitas realizadas pelos agentes!',
      });
      return Promise.reject(err);
    });
  return response;
}

export function getDinheiroInvestido(year?: string) {
  const response = apiBDS
  .get(`/cmic/payment-invested?year=${year}`)
  .then(res => {
    return res.data;
  })
  .catch(err => {
    notificationToast({
      type: 'error',
      message: 'Ocorreu um erro ao buscar as informações do Big Data Social sobre dinheiro ivestido!',
    });
    return Promise.reject(err);
  });
  return response;
}