import styled from 'styled-components';

export const Container = styled.div`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    
    padding: 18px;

    & > img {
      align-self: flex-start;
    }

    .calendar-status-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-self: flex-start;
      height: 100%;

      & > img {
        width: 14px;
        height: 18px;
      }

      .calendar-link {
        background: #F2F2F2;
        border-radius: 7px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;

        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  main {
    padding: 35px 18px;
    background: #f2f2f2;

    .title {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      
      h1 {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.2rem;

        color: #2D4040;
      }
    }

    .info-box {
      background: #FFF;
      padding: 22px 10px 3px;
      max-width: 500px;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content {
        display: flex;
        border-left: 3px solid #FFDE32;
        height: 100%;
        justify-content: space-between;

        h4 {
          font-family: Nunito;
          font-style: normal;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.8rem;

          color: #464A4E;

        }

        span {
          font-family: Nunito;
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 16px;

          color: #464A4E;

        }
      }

      .button-container {
        padding: 30px 30px 10px;
        width: 100%;

        button {
          height: 35px;
          margin: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          border: 0;
          background: #4B9F37;
          border-radius: 5px;

          font-family: Nunito;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;

          color: #FFFFFF;
          text-transform: uppercase;

          cursor: pointer;

          &:disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #f2f2f2;
    padding: 18px;

    .footer-content {
      max-width: 992px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;

      h1 {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.2rem;

        color: #2D4040;
      }

      textarea.ant-input {
        display: flex;
        font-family: Nunito;
        font-style: italic;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 16px;
        width: 100%;

        color: #464A4E;
      }
    }

    
  }

`;

export const Warning = styled.span`
  color: red;
  font-size: 1.5rem;
  padding: 10px 0 0;
  justify-self: flex-end;
`;