import { Rule } from 'rc-field-form/lib/interface';
import { isValidCPF, isValidPhone } from '../../../utils/cpf';

const rules: Record<string, Rule[]> = {
  cpf: [
    {
      required: true,
      validator(_, value) {
        if (isValidCPF(value)) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('CPF inválido'));
      },
    },
  ],
  name: [
    {
      required: true,
      message: 'Nome não preenchido',
    },
  ],
  birthdate: [
    {
      required: true,
      message: 'Data de nascimento não preenchida',
      type: 'date',
    },
  ],
  'marital-status': [
    {
      required: true,
      message: 'Estado civil não preenchido',
    },
  ],
  email: [
    {
      required: true,
      message: 'E-mail não preenchido',
      type: 'email',
    },
  ],
  phone: [
    {
      required: true,
      validator(_, value) {
        if (isValidPhone(value)) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Telefone inválido'));
      },
    },
  ],
  municipio: [
    {
      required: true,
      message: 'Município não selecionado',
    },
  ],
  profissao: [
    {
      required: true,
      message: 'Profissão não preenchida',
    },
  ],
  setor: [
    {
      required: true,
      message: 'Setor não preenchido',
    },
  ],
  orgao: [
    {
      required: true,
      message: 'Orgão não preenchido',
    },
  ],
  competencia: [
    {
      required: true,
      message: 'Competência não selecionada',
    },
  ],
};

export default rules;
