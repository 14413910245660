import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 auto 30px;

  .ant-form-item-label {
    font-weight: bold;
  }

  .ant-row.ant-form-item {
    margin-bottom: 2rem;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
    height: 175px;
    padding: 15px;
  }

  .img-default {
      background: #fafafa;
      display: grid;
      align-items: center;
      grid-template-rows: 17.5rem;
      justify-content: center;
      height: 175px;
      overflow: hidden;
      svg {
        color: rgba(0, 0, 0, 0.1);
        font-size: 7rem;
      }
    }
`;
