
import React from 'react';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const DateFilter = ({setSelectedTime}) => {
  
  const onSelectedTime = (value: any) => {
  console.log(value)
    setSelectedTime(value);
  };
  
  
  return (
    <>
        <div>
          <strong>Período</strong>
          <RangePicker locale={locale} onChange={onSelectedTime} picker="month" />
        </div>
    </>
  );
};

export default DateFilter;
