import React, { CSSProperties, ReactNode } from 'react';

import './styles.scss';

interface CardMediumGeralProps {
  children: ReactNode;
  text: JSX.Element | string;
  style?: CSSProperties;
}

const CardMediumGeral = ({
  children,
  text,
  style,
}: CardMediumGeralProps): JSX.Element => {
  return (
    <>
      <section className="container__mediumGeral" style={style}>
        {children}
        <div className="container__mediumGeral-footer">
          <p>{text}</p>
        </div>
      </section>
    </>
  );
};

export default CardMediumGeral;
