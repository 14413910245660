import { citiesNamesCE } from 'utils/citiesNames';

const generalInfos = {
  fields: [
    {
      key: 'nome',
      label: 'Nome',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'qtdTurmas',
      label: 'Número de turmas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'valorInvestido',
      label: 'Valor investido',
      widget: 'number',
      viewWidget: 'money-view',
      widgetProps: {
        min: 0,
      },
    },
    {
      key: 'valorAditivo',
      label: 'Valor Aditivo',
      widget: 'number',
      viewWidget: 'money-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'qtdAgentesCapacitados',
      label: 'Quantidade de capacitações',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'formato',
      label: 'Formato',

      viewWidget: 'simple-view',
      widget: 'select',
      options: ['Online', 'Presencial'],
    },
    {
      key: 'municipios',
      label: 'Municípios',
      widget: 'select',
      options: citiesNamesCE,
      widgetProps: { mode: 'multiple', allowClear: true },
      viewWidget: 'list-view',
    },
  ],
};

const periodoInfos = {
  columns: 2,
  fields: [
    {
      key: 'periodo.dataInicio',
      label: 'Data início',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'periodo.dataFim',
      label: 'Data fim',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
  ],
};

export function capacitacaoAgentesComunitariosSaude() {
  return [
    { title: 'Informações', value: generalInfos },
    { title: 'Períodos', value: periodoInfos },
  ];
}
