import styled, { css } from 'styled-components';

interface ICalendarStyleProps {
  isEditDateScreen: boolean;
  isMobile: boolean;
}

export const Container = styled.div<ICalendarStyleProps>`
  .ant-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  ${props => !props.isMobile ? css`
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  
  .loading-calendar-container {
    display: flex;
    width: 100vh;
    justify-content: center;
    align-items: center;

    div.ant-spin-text {
      color: #4b9f37;
      font-size: 18px;
    }
  }
 
  

  .ant-picker-calendar,
  .ant-picker-calendar-full {
    border: 1px solid #f2f2f2;
    max-width: 992px;
    margin: 0 auto;
    border-radius: 7px;



    .ant-picker-panel .ant-picker-body td {
      text-align: left;
      padding: 0;
    }

    
    .ant-picker-body {
      padding: initial;
    }
    .ant-picker-content {
      thead {
        text-align: center;
        background: #2D4040;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);

        tr {
          th{
            &:first-child, &:last-child {
              display: none;
            }

            
          }
        }

        tr {
          th {
            border: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
          }

        }
      }

      tbody {
        tr {
          
          td {
            border: 1px solid #DCDCDC;
            text-align: left;
            padding: 0;
            /* overflow-y: scroll; */
            /* height: 200px; */

            &.ant-picker-cell-disabled {
              opacity: 0.6;
              background: transparent;

            }

            div.ant-picker-calendar-date {
              padding: 0;
              margin: 0;
            }

            &:first-child, &:last-child {
              display: none;
              pointer-events: none;
              /* cursor: not-allowed; */
              background: transparent;
            }

            &.ant-picker-cell-selected {
              background:  ${props.isEditDateScreen ? '#FFDE34' : ''};
              .ant-picker-calendar-date-value {
              
            }
          }
        }
      }
      td {
        .ant-picker-cell {
         
        }

        .ant-picker-cell-inner{
          display: flex;
          justify-content: center;
          align-items: center;
          
          color: transparent;
          display: none;
          pointer-events: none;
          border: 0;
          min-height: 45px;
          transition: 0;

          /* &:hover {
            display: none;
          }

          &.ant-picker-calendar-date-today{
            border: 0;
            

            &::before {
              display: none;
            }
          }  */
        }

        &.ant-picker-cell-in-view {
          /* height: 183px; */
          text-align: left;
            .ant-picker-cell-inner{
              height: 100%;
              font-weight: normal;
              font-size: 16px;
              line-height: 22px;
              background: transparent;
              pointer-events: initial;
              color: #464A4E;
              display: flex;
              align-items: left;
              flex-direction: column;
              text-align: left;
              position: relative;

              .ant-picker-calendar-date-value {
                font-family: Nunito;
                font-style: normal;
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 2.22rem;
                text-align: left;

                color: #464A4E;
                
              }
            }
          }
        }
      }
    }
  }
  
  ` : css`
    padding: 0 5px;
    border-radius: 4px;

    .ant-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .ant-picker-calendar,
    .ant-picker-calendar-full {
      border: 1px solid #f2f2f2;
      max-width: 992px;
      margin: 0 auto;

      
      .ant-picker-body {
        border: 0;
        padding: 10px;
      }
      .ant-picker-content {
        thead {
          text-align: center;
          tr {
            th{
              &:first-child, &:last-child {
                display: none;
              }
            }
          }

          tr {
            th {
              border: 0;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              color: #2D4040;
            }

          }
        }

        tbody {
          tr {
            td{

              &.ant-picker-cell-disabled {
                opacity: 0.6;
                background: transparent;

              }

              &:first-child, &:last-child {
                display: none;
                pointer-events: none;
                /* cursor: not-allowed; */
                background: transparent;
              }

            
            }
          }

          td {
            .ant-picker-cell-inner{
              display: flex;
              justify-content: center;
              align-items: center;
              
              color: transparent;
              display: none;
              pointer-events: none;
              border: 0;
              min-height: 45px;
              transition: 0;

              &:hover {
                display: none;
              }

              &.ant-picker-calendar-date-today{
                border: 0;
              

                &::before {
                  display: none;
                }
              } 
            }

              &.ant-picker-cell-in-view {
                &:disabled {
                opacity: 0.6;
                background: #000;
              }

                
                .ant-picker-cell-inner{
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 22px;
                  background: transparent;
                  pointer-events: initial;
                  color: #464A4E;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  position: relative;

                  .ant-picker-calendar-date-value {
                    font-family: Nunito;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.8rem;
                    line-height: 2.22rem;

                    color: #464A4E;
                    align-self: center;

                    
                    /* position: absolute;
                    top: 50%; */
                  }

                  .ant-picker-calendar-date-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  
                }
              }

            &.ant-picker-cell-selected {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .ant-picker-calendar-date-value {
                background:  #FFDE32;;
                border-radius: 50%;
                z-index: 9;
                /* width: 2rem;
                height: 2rem; */
                padding: 2rem;
              }

              

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  `};
`;

export const CalendarHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: center;

  .wrapper-semester {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;
  }

  .activity-container {
    display: flex;
    justify-self: flex-start;

    button {
      width: 100%;
      height: 100%;
      background: #4B9F37;
      border: 0;
      border-radius: 5px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      color: #FFF;
      padding: 3px 20px;
      cursor: pointer;
      transition: background 0.2s;
      outline: 0;

      word-break: break-word;
      text-transform: uppercase;
    }
  }

  .family-visitation-container {
    display: flex;
    justify-self: flex-start;

    button {
      width: 100%;
      height: 100%;
      background: #4B9F37;
      border: 0;
      border-radius: 5px;
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      color: #FFF;
      padding: 3px 20px;
      cursor: pointer;
      transition: background 0.2s;
      outline: 0;

      word-break: break-word;
      text-transform: uppercase;
    }
  }

  .month-year-content {
    display: flex;
    gap: 10px;

    span {
      font-family: Nunito;
      font-style: normal;
      font-weight: 800;
      font-size: 2rem;
      line-height: 2.2rem;

      color: #2D4040;
    }
  }

  .change-month-content {
    display: flex;
    gap: 10px;
  }
`;


export const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.2rem;
  height: 4.7rem;
  background: #F2F2F2;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  img {
    width: 23px;
    height: 13px;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;

    img {
     cursor: not-allowed; 
    }
  }
`;

export const FamilyInnerCalendar = styled.div`
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;

  z-index: 1000;

  gap: 20px;
  padding: 4px 10px;
  margin-bottom: 10px;

  span {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;

    color: #464A4E;

  }

  &:hover {
    border: 1px solid #C2102A;
  }
`;

export const DropFamilyContent = styled.div`
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 20px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  
  & > span {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;

    color: #464A4E;
  }

  .content-family-info-detail {
    border-left: 3px solid #FFDE32;;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  
    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-left: 8px;
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #464A4E;

      strong {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */


        color: #464A4E;

      }
    }
  }

  .content-observation-visit{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  
    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-left: 8px;
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #464A4E;

      strong {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */


        color: #464A4E;
      }
    } 
}


  .form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;

    background: #4B9F37;
    border-radius: 5px;
    border: 0;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;

    color: #FFFFFF;
    cursor: pointer;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .title-close-content {
    display: flex;
    justify-content: space-between;

    span {
      word-break: break-word;
      width: 80%;
      font-family: Nunito;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;

      color: #464A4E;
    }
    
    .overlay-title {
      font-size: 3rem;
      font-family: Nunito, sans-serif;
      text-align: center;
      align-self: center;
      word-break: break-word;
      width: 90%;
    }

    .close-family-container-button {
      background: transparent;
      border: 0;
      
      display: flex;
      justify-content: center;
      align-items: center;

      color: #C2102A;
      font-size: 2rem;
      font-family: Nunito, sans-serif;

      cursor: pointer;
      transition: filter .2s;
      align-self: flex-start;
      outline: 0;

      &:hover {
        filter: brightness(0.7);
      }
    }
  }

  

  .overlay-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;

    border-left: 3px solid #FFDE32;
    padding-left: 10px;

    span {
      font-size: 1.5rem;
      font-family: Nunito, sans-serif;

    }
  }

  .overlay-button-group {
    width: 100%;
    /* padding: 0 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    text-align: left;

    strong {
      align-self: flex-start;
    }

    .overlay-button-activity {
      border: 0;
      font-size: 1.4rem;
      font-family: Nunito, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 20px;;

      transition: background .2s;
      cursor: pointer;

      &.selected {
        background:  #4B9F37;
        color: #FFFF;
      }

      &:hover {
        background: #4B9F37;
        opacity:  0.4;
        color: #FFFFFF;
      }

      &:active {
        background:  #4B9F37;
        color: #FFFF;
      }
    }
  }

  .date-overlay-info {
    display: flex;

    align-items: center;
    gap: 5px;
  }
  
`;

export const HoveredCreateActivity = styled.div` 
  flex: 1;
`;

export const DropDownContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #C2102A;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;

  .bordered-container {
    border-left: 3px solid #FFDE32;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 10px;
    justify-content: space-between;

    p {
      padding-bottom: 0;
      justify-self: flex-end;
      font-size: 2rem;
      font-family: Nunito, sans-serif;
      text-align: left;
      margin: 0;
    }
  }

  .header-overlay{
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
  
    button {
      border: 0;
      background: transparent;
      cursor: pointer;
      color:  #C2102A;
      font-size: 1.9rem;
      outline: 0;
      transition: filter 0.2s;

      &:hover{
        filter: brightness(0.7);
      }
    }
  }

  .date-info {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 5px;

    span {
      font-size: 1.6rem;
      font-family: Nunito, sans-serif;

    }

    .hour {
      font-weight: bold;
    }
  }

  strong {
    font-size: 2.4rem;
    font-family: Nunito, sans-serif;

  }
  
  & > span {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;

    color: #464A4E;
  }
`;


export const ActivityBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  width: 100%;
  height: 50px;

  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  overflow: hidden;
  margin: 20px 0;
  z-index: 100;

  .date {
    display: flex;
    align-self: flex-start;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: right;

      color: #464A4E;
    }
  }
`;

interface StatusBgProps {
  status: string;
}

export const StatusBg = styled.div<StatusBgProps>`
  display: flex;
  justify-self: center;
  background: ${props => props.status==='VISITED' ? '#55A546' : ''};
  background: ${props => props.status==='UNVISITED' ? '#C2102A' : ''};
  background: ${props => props.status==='SCHEDULED' ? 'blue' : ''};
`;

export const Warning = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  
  .warning {
    color: red;
    font-size: 1.5rem;
    justify-self: flex-end;
    word-break: break-word;
    font-weight: normal;
  }
`;