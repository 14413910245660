import XLSX from 'xlsx';

export function formatXLSXpraia(data, workbook) {

  let newTabPraia: any = [];
  let linhaVM: any = ["Nome", 
                     "Data", 
                     "É data comemorativa?", 
                     "Menores de 12 anos",
                     "Entre 13 e 17 anos",
                     "Adultos",
                     "Idosos maior de 60",
                     "Municipio", 
                     "Rua",
                     "Bairro",
                     "CEP",
                     "Número",
                     "Ponto de referência",
                     "Estação"
                   
                    ]
     workbook.SheetNames.push(
      "Praia acessível",
        );

  newTabPraia.push(linhaVM)
  data.forEach((praiaObj, index: number) => {
  
    
    const infosPraia = {...praiaObj.praiaAcessivel}
    delete infosPraia.classe;

   
    linhaVM = [];

    if(infosPraia.localizacao){

      linhaVM.push(infosPraia.nome)
      linhaVM.push(new Date(infosPraia.data).toLocaleDateString('pt-BR'))
      linhaVM.push(infosPraia.dataComemorativa ? 'Sim' : 'Não')
      linhaVM.push(infosPraia.atendimentos.menoresDe12anos)
      linhaVM.push(infosPraia.atendimentos.entre13e17)
      linhaVM.push(infosPraia.atendimentos.adultos)
      linhaVM.push(infosPraia.atendimentos.idosoMaiorQue60)

      linhaVM.push(infosPraia.localizacao?.municipio || '-')
      linhaVM.push(infosPraia.localizacao?.rua || '-')
      linhaVM.push(infosPraia.localizacao?.bairro || '-')
      linhaVM.push(infosPraia.localizacao?.cep || '-')
      linhaVM.push(infosPraia.localizacao?.numero || '-')
      linhaVM.push(infosPraia.localizacao?.pontoDeReferencia || '-')
      linhaVM.push(infosPraia.localizacao?.estacao || '-')
      newTabPraia.push(linhaVM) 
      
    }else {
      linhaVM.push(infosPraia.nome)
      linhaVM.push(new Date(infosPraia.data).toLocaleDateString('pt-BR') )
      linhaVM.push(infosPraia.dataComemorativa ? 'Sim' : 'Não')
      linhaVM.push(infosPraia.atendimentos.menoresDe12anos)
      linhaVM.push(infosPraia.atendimentos.entre13e17)
      linhaVM.push(infosPraia.atendimentos.adultos)
      linhaVM.push(infosPraia.atendimentos.idosoMaiorQue60)
      newTabPraia.push(linhaVM) 
    }

   
  });
   
  let content = XLSX.utils.aoa_to_sheet(newTabPraia);
  workbook.Sheets[
    "Praia acessível"
  ] = content;

 
  return workbook;
}
