import locale from 'antd/lib/date-picker/locale/pt_BR';
const generalInfos = {
  fields: [
    {
      key: 'nome',
      label: 'Nome',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'descricao',
      label: 'Descrição',
      widget: 'textarea',
      viewWidget: 'simple-view',
    },
    {
        key: 'publicoAlvo',
        label: 'Público alvo',
        viewWidget: 'simple-view',
      },
    {
      key: 'valorInvestido',
      label: 'Valor investido',
      viewWidget: 'simple-view',
   
    },
    {
      key: 'valorAditivo',
      label: 'Valor Aditivo',

      viewWidget: 'simple-view',
    
    },
   
    {
      key: 'capacitacoesGerais',
      label: 'Capacitações Gerais',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 }
    },
  ],
};

const periodoInfos = {
  columns: 2,
  fields: [
    {
      key: 'periodo.dataInicio',
      label: 'Data início',
      name: 'dataInicio',
      widget: 'date-picker',
      viewWidget: 'date-view',
      widgetProps: { locale: locale }
      
    },
    {
      key: 'periodo.dataFim',
      name: 'dataFim',
      label: 'Data fim',
      widget: 'date-picker',
      viewWidget: 'date-view',
      widgetProps: { locale: locale }
    }
  ],
};

export function capacitacao() {
  return [
    { title: 'Informações', value: generalInfos },
    { title: 'Períodos', value: periodoInfos },
  ];
}
