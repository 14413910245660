import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  convertArraysFromString,
  formatObject,
  genereteRows,
  genereteWidthColumn,
} from '../utils/formatter';
import {
  headCapacitacaoAgentes,
  headDocumentario,
  headEducacaoAmbiental,
  headLancamentoDeLivroOuRelatorio,
  headNCapacitacao,
  headNEP,
  headPrevFamilia,
  headSementesDoAmanha,
  headSeminarioInternacionalMaisInfancia,
  headTodaBandaDeMusicaEscola,
  headValeGas,
  headPublicacoes,
  obrasTitle,
  headPraiaAcessivel,
  headArteNaPraca,
} from '../utils/headers';


export function obrasAcoesGerais(eventData: any, type: string, name: string) {
  const doc: any = new jsPDF();

  const isObras = type === 'obras' ? true : false;

  const head = isObras
    ? obrasTitle
    : name === 'Capacitação'? headNCapacitacao 
    : name === 'Capacitação dos Agentes Comunitários de Saúde'
    ? headCapacitacaoAgentes 
    : name === 'Prevenção em Família'
    ? headPrevFamilia
    : name === 'Toda Banda de Música é uma escola'
    ? headTodaBandaDeMusicaEscola
    : name === 'Lançamento de Livro ou Relatório'
    ? headLancamentoDeLivroOuRelatorio
    : name === 'Seminário Internacional Mais Infância Ceará'
    ? headSeminarioInternacionalMaisInfancia
    : name === 'Sementes do Amanhã'
    ? headSementesDoAmanha
    : name === 'Documentários'
    ? headDocumentario
    : name === 'Educação Ambiental'
    ? headEducacaoAmbiental
    : name === 'Vale Gás'
    ? headValeGas
    : name === 'Publicações'
    ? headPublicacoes
    : name === 'Arte na Praça'
    ? headArteNaPraca
    : name === 'Praia Acessível'
    ? headPraiaAcessivel
    : headNEP;

    
    
  const position: any = [];

  const rows = genereteRows(head);
  
  eventData.forEach(event => {

    let objectLine: any = [];
    let novoObj: any = [];
    formatObject(event, novoObj);

    Object.keys(rows).forEach(coluna => {
      let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
      objectLine.push(objeto);
    });

    position.push(objectLine);
  });

  doc.addPage('a4', 'landscape');

  var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(15);
  doc.text(pageWidth / 2, 15, `${isObras ? "Equipamento - " : ""} ${name}`, {align: 'center'});
  doc.setFontSize(8);  
  doc.text(2, pageHeight - 2, `Documento gerado em ${new Date().toLocaleDateString('pt-br')} às ${new Date().toLocaleTimeString('pt-br')}`);


  doc.autoTable({
    startX: 0,
    rowPageBreak: 'avoid',
    columnStyles: genereteWidthColumn(rows),
    head: head,
    startY: 25,
    margin: {
      top: 10,
      bottom: 40,
      left: 2,
      right: 2,
    },
    headerStyles: {
      fontSize: 8,
      fontFamily: 'Roboto',
      fillColor: '#80c470',
      halign: 'center',
    },
    styles: { fontSize: 8, columnWidth: 'wrap' },
    horizontalPageBreak: false,
    body: convertArraysFromString(position),
    theme: 'striped',
  });

  doc.deletePage(1);
  doc.save(`${name}.pdf`);

}