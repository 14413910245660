// componente de erro dos endpoints do big data social

import { Tooltip } from "antd";
import React from "react";


export function ErrorMessageBDS(){
  return (
    <Tooltip title="Ocorreu um erro ao buscarmos as innformações vindas do Big Data Social">
      ?
    </Tooltip>
  )
}