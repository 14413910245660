import React from 'react';
import { Form, FormInstance, Input, InputNumber, Select, Row, Col } from 'antd';
import locale from 'antd/es/locale/pt_BR';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { City } from 'types';
import { FormBox, DatePicker } from 'components/UI';
import { Authorizer } from 'components/Authorizer';

import rules from './rules';

interface ProfessionalFormProps {
  form?: FormInstance;
  cities: City[];
  loading?: boolean;
}

const { Item } = Form;
const { Option } = Select;

dayjs.locale('pt-br');

export function ProfessionalForm({
  form,
  cities,
  loading = false,
}: ProfessionalFormProps): JSX.Element {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const { user } = Authorizer.useAuth();
  const { isArticulator } = Authorizer.checkRoles;
  const roles = user.roles ?? [];

  const disabled = isArticulator(roles);

  return (
    <FormBox title="Dados Profissionais" form={currentForm}>
      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item label="Profissão" name="occupation" rules={rules.occupation}>
            <Input disabled={disabled} />
          </Item>
        </Col>

        <Col
          xxl={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
          md={24}
          xs={24}
        >
          <Item
            label="Formação / Graduação"
            name="graduation"
            rules={rules.graduation}
          >
            <Input disabled={disabled} />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item
            label="Data de Admissão"
            name="admissionDate"
            rules={rules.admissionDate}
          >
            <DatePicker
              locale={locale.DatePicker}
              picker="month"
              format="MM/YYYY"
              placeholder=""
              disabled={disabled}
            />
          </Item>
        </Col>
        <Col
          xxl={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
          md={24}
          xs={24}
        >
          <Item
            label="Carga Horária (Horas semanais)"
            name="workload"
            rules={rules.workload}
          >
            <InputNumber disabled={disabled} />
          </Item>
        </Col>
      </Row>

      <Item
        label="Município Alocado"
        wrapperCol={{ xxl: 11, xl: 11, lg: 11, md: 24, xs: 24 }}
        name="city"
        rules={rules.city}
      >
        <Select loading={loading} showSearch disabled={disabled}>
          {cities.map(city => (
            <Option value={city.name} key={city.id}>
              {city.name}
            </Option>
          ))}
        </Select>
      </Item>
    </FormBox>
  );
}
