import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const FilterBox = styled.div`
  width: 30rem;
  height: max-content;
  background: #F2F2F2;
  border-radius: 7px;
  grid-area: filterbox;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 15px;

  gap: 20px;

  .filters {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;

    gap: 30px;
    
    .ant-divider {
      padding: 0;
      margin: 0;
    }

    div {
      display: block;
      width: 100%;
      gap: 5px;

      span {
        align-self: flex-start;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.6rem;

        color: #464A4E;
      }

      .ant-select {
        width: 100%;

        span {
          color: #707070;
          font-weight: normal;
        }
        
      }
    }

    .apply-filters-button {
      height: 36px;
      border: 0;
      background: #4B9F37;
      border-radius: 5px;
      justify-self: flex-end;

      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;

      color: #FFFFFF;
      text-transform: uppercase;

    }
  }

  .filter-box-content {
    span {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.7rem;

      color: #2D4040;

      display: flex;
      gap: 5px;

      svg {
        transform: rotate(90deg);
      }
    }
  }
  
`;

export const FilterMobileBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 35px;
  flex-wrap: wrap;
  gap: 15px;

  div {
    display: block;
    align-items: center;
    gap: 5px;
    min-width: 140px;

    span {
      align-self: flex-start;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.6rem;

      color: #464A4E;
    }

    .ant-select {
      width: 100%;

      span {
        color: #707070;
        font-weight: normal;
      }
      
    }
  }

  .filter-mobile-button {
    display: flex;
    align-self: flex-end;

    .apply-filters-button {
      width: 100%;
      height: 36px;
      border: 0;
      background: #4B9F37;
      border-radius: 5px;

      span {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;

        color: #FFFFFF;
        text-transform: uppercase;
      }
    }
  }
`;