import React, { 
  useState,  
  Dispatch, 
  SetStateAction,
  useMemo,
  useEffect
} from 'react';

import { RiErrorWarningLine } from 'react-icons/ri';

import { 
  Calendar as AntCalendar, 
  CalendarProps, 
  Dropdown,
  Input,
  message,
  Button,
  Upload
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import { 
  Container, 
  CalendarHeader, 
  ArrowButton, 
  FamilyInnerCalendar, 
  DropFamilyContent, 
  DropDownContainer, 
  ActivityBox,
  StatusBg as BackgroundStatus,
  Warning,
} from './styles'; 

import arrowLeftIcon from 'assets/articulator/arrowleft.svg';
import arrowRightIcon from 'assets/articulator/arrowright.svg';
import { Activity, IJustify, Justify, MarkedVisitationPerFamily } from 'types';
import moment, { Moment } from 'moment';

import doneGreenImg from 'assets/articulator/done-green.svg';
import doneRedImg from 'assets/articulator/done-red.svg';
import doneBlueImg from 'assets/articulator/done-blue.svg';

import { BiCalendar } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { currentSemester } from '..'; 

import {justifyService} from '../../../services/resources/justifyService'

const { TextArea } = Input;


type ICalendarProps = CalendarProps<Moment> & {
  showEventIndicators?: boolean;
  isEditDateScreen?: boolean;
  filteredVisits: MarkedVisitationPerFamily[];
  dateClicked: moment.Moment | undefined;
  onCreateActivityClose?: () => void;
  activities: Activity[];
  setActivities: Dispatch<SetStateAction<Activity[]>>;
  workplanId: number;
  agentId?: string;
  isCreateNewActivityButtonClicked?: boolean;
  showOnlyVisitationDataInCalendar?: boolean;
  isAgent?: boolean;
  createActivityBoxOverlay: () => JSX.Element;
  showsCreateActivityBox?: boolean;
  onCreateActivityBoxButtonClicked: () => void;
  isMobile?: boolean;
  SelectSemester: JSX.Element;
  navigate?: {
    month: string;
    year: string;
  };
  selectValue?: string;
};

moment.updateLocale('en', {
  weekdaysMin : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]
});

const uploadProof = {
  name: 'file',
  action: 'http://localhost:3030/',
  accept: 'application/pdf',
  headers: {
    authorization: 'authorization-pdf',
  },

  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === 'done') {
      message.success(`${info.file.name} arquivo enviado com sucesso`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} falha no envio do arquivo`);
    }
  },
};

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const monthBySemester = {
  '1': [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
  ],
  '2': [
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ]
}

export function Calendar({ 
  showEventIndicators=true, 
  filteredVisits=[], 
  isEditDateScreen=false,
  dateClicked,
  onCreateActivityClose,
  workplanId,
  activities,
  agentId,
  setActivities,
  showOnlyVisitationDataInCalendar=false,
  isCreateNewActivityButtonClicked=false,
  isAgent=false,
  createActivityBoxOverlay,
  showsCreateActivityBox=false,
  onCreateActivityBoxButtonClicked,
  isMobile=false,
  SelectSemester,
  navigate,
  selectValue,
  ...rest 
}: ICalendarProps) {
  const cs = currentSemester();

  const [isVisible, setIsVisible] = useState(false);
  const [isVisitationCardClicked, setIsVisitationCardClicked] = useState(-1);   
  const [isOverlayActivityDetailVisible, setIsOverlayActivityDetailVisible] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(-1);
  const [calendarIntervalDisabled, setCalendarIntervalDisabled] = useState(false);
  const [hasSelectValueChanged, setHasSelectValueChange] = useState(false);

  const [nonNavigatedYet, setNonNavigatedYet] = useState(true);
  const monthToNavigate = navigate?.month;
  const yearToNavigate = navigate?.year;

  const [observation, setObservation] = useState('');
  const [justify, setJusfity] = useState<IJustify[]>([]);
  
  const [loading, setLoading] = useState(false);

  const semester = useMemo(() => {
    if(monthToNavigate !== undefined) {
      setCalendarIntervalDisabled(true);
      return Number(monthToNavigate) === 0 ? 1 : 2;
    }
    return undefined;
  }, [monthToNavigate]);

  const now = moment(new Date());
  const history = useHistory();

  function handleNavigateToFamilyJotFormAnswers(nis: string) {
    history.push(`/pesquisa-do-articulador/${nis}`);
  }

  function handleNavigateToForwardingOfDemandsFamily(id : number) {
    history.push(`/articulador-encaminhamento-de-demandas/${id}`);
  }

  useEffect(() => {
    if(!!selectValue) {
      setHasSelectValueChange(true);
      setNonNavigatedYet(true);
    }
  }, [selectValue]);

  async function getJustify(id: number){
      await justifyService.getJustify(id)
      .then(data => setJusfity(data))
      .catch(error => message.error("Deu erro: "+error))
    return justify;
  }

  async function handleJustify(visit: number, observation: string, ){
    setLoading(true);
    const data = {
      visit,
      observation
    }
    try {
      await justifyService.createJustify(data);
      setObservation('');
      message.success('Justificativa criada com sucesso!!');
    } catch (err: any) {
      message.error(err.response.data.mensagem)
    }
    setLoading(false);
  }

  function headerRender({ value, type, onChange, onTypeChange }) {
    onTypeChange('month');
    const month = (monthToNavigate!==''&& nonNavigatedYet && !isEditDateScreen) ? Number(monthToNavigate) : value.month();
    const year = (yearToNavigate!=='' && nonNavigatedYet && !isEditDateScreen) ? Number(yearToNavigate) : value.year();

    if(hasSelectValueChanged) {
      value.month(month);
      value.year(year);
      onChange(value);
    }

    return (
      <CalendarHeader key={month}>
        <div className="change-month-content">
          <ArrowButton
            type="button"
            disabled={!monthBySemester[semester || cs].includes(months[month-1])}
            onClick={() => {
              setNonNavigatedYet(false);
              const newValue = value.clone();
              const currentYear = value.year();
              const currentMonth = value.month();
              newValue.year(currentYear);
              newValue.month(currentMonth - 1);
              onChange(newValue);
            }}
          >
            <img
              src={arrowRightIcon}
              alt="icon-right"
            />
          </ArrowButton>
          <ArrowButton
            type="button"
            disabled={!monthBySemester[semester || cs].includes(months[month+1])}
            onClick={() => {
              setNonNavigatedYet(false);
              const newValue = value.clone();
              const currentYear = value.year();
              const currentMonth = value.month();
              newValue.year(currentYear);
              newValue.month(currentMonth + 1);
              onChange(newValue);
            }}
          >
            <img
              src={arrowLeftIcon}
              alt="icon-left"              
            />
          </ArrowButton>

         {(dateClicked && !isEditDateScreen && !isMobile) && isAgent && (
          < >
              <Dropdown
                visible={showsCreateActivityBox}
                overlay={createActivityBoxOverlay()}
              >
                <div className="activity-container">
                <button 
                  type="button"
                  onClick={onCreateActivityBoxButtonClicked}  
                  className="create-activity-button"
                >
                  Criar nova atividade em {dateClicked?.format('DD/MM/YYYY')}
                </button>
              </div>
              </Dropdown>
          </>
         )}
        </div>
        <div className="wrapper-semester">
          <div className="month-year-content">
            <span key={month}>{months[month]}</span>
            <span key={year}>{year}</span>
          </div>
          {SelectSemester}
        </div>
      </CalendarHeader>
    );
  }
  
  function handleVisitationCardClicked(visitationId: number) {
    setIsVisible(true);
    setIsVisitationCardClicked(visitationId);
  }

  function handleActivityCardClicked(activityId: number) {
    setIsOverlayActivityDetailVisible(true)
    setSelectedActivityId(activityId);
  }
  
  function dateCellRenderDesktop(date: moment.Moment) {
    const cellDate = date.format('YYYY-MM-DD');

    return (
      <div
        key={cellDate}
      >
         {showEventIndicators && (
        <>
        {!showOnlyVisitationDataInCalendar && activities.map((activity) => {
          if(cellDate===moment(activity.start).format('YYYY-MM-DD')) {
            return (
              <Dropdown
                key = {activity.id}
                visible={activity.id===selectedActivityId && isOverlayActivityDetailVisible}
                overlay={
                  <DropDownContainer key = {activity.id}>
                    <div className="header-overlay">
                      <strong>{activity.type.name}</strong>
                      <button title="Fechar" type="button" onClick={() => setIsOverlayActivityDetailVisible(false)}>x</button>
                    </div>
                    <div className="bordered-container">
                      <div className="date-info">
                        <BiCalendar size={24} color="gray"/>
                        <span className="hour">{moment(activity.start).format('DD/MM/YYYY HH:mm')}</span>
                        <span> até </span>
                        <span className="hour">{moment(activity.end).format('HH:mm')}</span>
                      </div>
                      <p>{activity.observation}</p>
                      <Upload.Dragger {...uploadProof}>
                        <Button icon={<UploadOutlined />} style={{margin:"20px 20px"}}> Upload de comprovante da sua atividade </Button>
                      </Upload.Dragger>
                    </div>
                  </DropDownContainer>
                }
                trigger={['click']}
              >
                <ActivityBox
                  onClick={() => handleActivityCardClicked(activity.id)}
                >
                    <StatusBg status={activity.checked}/>
                    <strong>{activity.type.name}</strong>
                </ActivityBox>
              </Dropdown>
            )
          } else return <div />
        })}
        {filteredVisits.map((visitationData, idx: number) => {
          if(cellDate===moment(visitationData.date).format('YYYY-MM-DD')) {
        //{console.log(visitationData.jotform)}
            return (
              <button key = {visitationData.id} onClick={() => getJustify(visitationData.id)} style={{border: '0'}}>
              <Dropdown
                key={`${visitationData.id}-${idx}`}
                visible={isVisible && isVisitationCardClicked===visitationData.id} 
                overlay={
                  <DropFamilyContent key= {visitationData.id}>
                    <div className="title-close-content">
                      <span>{visitationData.family.nameRF}</span>
                      <button 
                        type="button" 
                        onClick={() => {setIsVisible(false)}}
                        className="close-family-container-button"
                      >
                        x
                      </button>
                    </div>
                    <div className="content-family-info-detail">
                      <div>
                        <strong>NIS</strong>
                        {visitationData.family.nis}
                      </div>
                      <div>
                        <strong>Telefone</strong>
                        <span>{visitationData.family.phone || 'Sem telefone cadastrado'}</span>
                      </div>
                      <div>
                        <strong>Endereço</strong>
                        <span>{visitationData.family.addressStreet} {' '} {visitationData.family.addressNumber || 'S/N'}</span>
                      </div>
                    </div>
                    {!visitationData.jotform ? (
                      <>
                        <div className="content-observation-visit">
                          <Warning>
                            <RiErrorWarningLine color="red" />
                            <span className="warning">Não há entrevistas associadas a esta família!</span>
                          </Warning>

                          <strong>Observação</strong>

                          {isAgent && justify.length === 0 ? (
                            <>
                              <div className="overlay-observation">
                                <TextArea value={observation} onChange={e => setObservation(e.target.value)} style={{ width: '100%' }} />
                              </div>
                              <button
                                type="button"
                                className="form-button"
                                disabled={loading}
                                onClick={() => handleJustify(visitationData.id, observation)}
                              >
                                Salvar
                              </button>
                            </>
                          ):(
                            justify.map((i) => (
                              <span>{i.observation || 'Não há observações'}</span>
                            ))
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="form-button"
                          disabled={visitationData.jotform === null}
                          onClick={() => handleNavigateToFamilyJotFormAnswers(visitationData.family.nis)}
                        >
                          Formulários
                        </button>
                        {isAgent ? (
                          <button 
                            type="button" 
                            className="form-button"
                            disabled={visitationData.jotform === null}
                            onClick= {() => handleNavigateToForwardingOfDemandsFamily(visitationData.family.id)}
                          >
                              Encaminhamento de Demanda
                          </button>) : null
                        }
                      </>
                    )}

                  </DropFamilyContent>
                } 
                placement="bottomLeft"
              >
                <FamilyInnerCalendar onClick={() => handleVisitationCardClicked(visitationData.id)}>
                  {/* <span>Visita</span> */}
                  {(visitationData.visitStatusEnum==='VISITED') && (
                    <img src={doneGreenImg} alt="indica que visita foi realizada"/>
                  )}
                  {visitationData.visitStatusEnum==='UNVISITED' && (
                    <img src={doneRedImg} alt="indica que visita não foi realizada"/>
                  )}
                  {visitationData.visitStatusEnum==='SCHEDULED' && (
                    <img src={doneBlueImg} alt="indica que visita foi realizada"/>
                  )}
                  <span>
                    VISITA - {visitationData.family.nameRF}
                  </span>
                </FamilyInnerCalendar>
              </Dropdown>
              </button>
            );
          } return null;
        })}
      </>
    )}

    </div>
    );
  }

  function dateCellRenderMobile(date: moment.Moment) {
    const cellDate = date.format('DD/MM/YYYY');

    return (
      showEventIndicators && (
        <div 
          className="dot_indicator"
          style={{
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            top: '70%',
            zIndex: 10,
            gap: 1,
          }}
          key={cellDate}
        >
          
          {filteredVisits.map((family, idx: number) => {
            if(moment(family.date).format('DD/MM/YYYY')===cellDate) {
              return (
                <BackgroundStatus
                  key={idx} 
                  style={{
                    width: 4,
                    height: 4,
                  }}
                  status={family.visitStatusEnum}
                />
              
              );
            }
            return (<div key={idx}/>);
          })}
          {!showOnlyVisitationDataInCalendar && activities?.map((activity) => {
            if(moment(activity.start).format('DD/MM/YYYY')===cellDate) {
              return (
                <StatusBgTwo
                  key={activity.id} 
                  status={activity.checked}
                />
              
              );
            }
          })}
        </div>
      )
    )
  }

  const StatusBg = ({ status }) => {
    const background = status ? 'green' : 'red';
    return <Dot background={background}/>
  };

  const Dot = ({ background }) => {
    return (
      <div 
        style={{
          display : 'flex',
          background: background, 
          width: 10, 
          height: 10, 
          borderRadius: '5px' 
        }}
      />
    )  
  }
  
  const MobileDot = ({ background }) => (
    <div 
      style={{
        display : 'flex',
        background: background, 
        width: 4, 
        height: 4, 
      }}
    />
  )

  const StatusBgTwo = ({ status }) => {
    const background = status ? 'green' : 'red';
    return <MobileDot background={background}/>
  };

  return (
    <Container isMobile={isMobile} isEditDateScreen={isEditDateScreen}>
      <AntCalendar 
        {...rest}
        disabledDate={(date) => {
          if(calendarIntervalDisabled)
            return false;
          if(date.isBefore(now))
            return true;
          else return false;
        }}
        fullscreen={!isMobile}
        headerRender={headerRender}  
        dateCellRender={isMobile ? dateCellRenderMobile : dateCellRenderDesktop}
      /> 

    </Container>
    
  );
}


