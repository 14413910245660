import React from 'react';
import { Steps } from 'components';

interface StepPanelProps {
  step: number;
  articulator: boolean;
}

const coordinatorSteps = ['Informações gerais', 'Confirmação'];
const articulatorSteps = ['Informações gerais', 'Informações complementares', 'Confirmação'];

export function StepPanel({ step, articulator }: StepPanelProps) {

  const steps = articulator ? articulatorSteps : coordinatorSteps;

  return (
    <div className="StepPanel">
      <Steps current={step} steps={steps} />
    </div>
  )
}