import React, { useState } from 'react';
import { Input } from 'antd';
import { ChangeEvent } from '../../../types';
import { splitByIndex } from '../../../utils/strings';

interface InputPhoneProps {
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export function InputPhone({
  value,
  bordered = true,
  disabled = false,
  onChange,
}: InputPhoneProps): JSX.Element {
  const [size, setSize] = useState(14);

  function maskPhone(phone?: string) {
    if (!phone) return undefined;

    const splitByTwo = splitByIndex(phone, 2);
    const [ddd, ...rest] = splitByTwo;

    const phoneNumber = rest
      .join('')
      .split('')
      .map((pn, ind1, arr) => {
        const hasBeginNine = arr[0] === '9';

        if (hasBeginNine && ind1 === 4 && arr[ind1+1]) return `${pn}-`;
        if (!hasBeginNine && ind1 === 3 && arr[ind1+1]) return `${pn}-`;

        return pn;
      })
      .join('');

    return [ddd, phoneNumber]
      .map((part, ind1, arr) => {
        if (ind1 === 0 && arr[ind1 + 1] !== '') return `(${ddd}) `;

        return part;
      })
      .join('');
  }

  const handleChange: ChangeEvent = e => {
    const changedValue = e.target.value
      .replace(/\(|\)|-|\s/g, '')
      .replace(/[^\d]/g, '');

    const phoneNumber = changedValue.slice(2);

    if (phoneNumber !== '' && phoneNumber[0] === '9') {
      setSize(15);
    }

    if (onChange) {
      onChange(changedValue);
    }
  }

  return (
    <Input
      bordered={bordered}
      disabled={disabled}
      value={maskPhone(value)}
      onChange={handleChange}
      maxLength={size}
    />
  );
}
