import React from 'react'
import {
  Form,
  FormInstance,
  Input,
  Select,
  Row,
  Col,
} from 'antd';
import { FormBox, InputUpload } from '../../UI/index';
import municipiosCE from '../../../data/ceara-full.json'

const {TextArea} = Input

interface InfoBasicFormProps {
  form?: FormInstance;
}

const { Item } = Form;
const { Option } = Select;

export function InfoBasicConfirm({ form }: InfoBasicFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  return (
    <FormBox title="Dados da Obra" form={currentForm}>
      <Item
        label="Nome"
        name="nome"
        wrapperCol={{ span: 12 }}
      >
        <Input disabled={true} bordered={false}/>
      </Item>

      <Item
        label="Município"
        name="municipio"
        wrapperCol={{ span: 6 }}
      >
        <Select
          showSearch
          disabled={true} bordered={false}
          defaultValue="Selecione um município"
          showArrow={false}
        >
          {municipiosCE.features.map(city => (
            <Option value={city.properties.name.toUpperCase()} key={city.properties.id}>
              {city.properties.name.toUpperCase()}
            </Option>
          ))}
        </Select>
      </Item>
      <Row>
        <Col span={12}>
          <Item
          label="Endereço Completo"
          name="enderecoCompleto"
        >
          <Input disabled={true} bordered={false}/>
        </Item>
        </Col>

        <Col span={11} offset={1}>
        <Item
        label="Ponto de referência"
        name="pontoDeReferencia"
      >
        <Input placeholder="Não informado" disabled={true} bordered={false}/>
      </Item>
        </Col>
      </Row>

      <Item
        label="Descrição"
        name="descricao">
        <TextArea placeholder="Não informado" autoSize disabled={true} bordered={false}/>
      </Item>

      <Item
        label="Foto da Obra"
        name="imagem"
        wrapperCol={{ span: 6 }}>
        <InputUpload disabled={true} bordered={false}/>
      </Item>
      
    </FormBox>
  );
};
