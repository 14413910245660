import React from 'react';
import { Form, FormInstance, Radio, InputNumber } from 'antd';

import { FormBox } from '../..';
import rules from './rules';

interface FamilyFormProps {
  form?: FormInstance;
}

const { Item } = Form;

export function FamilyForm({ form }: FamilyFormProps): JSX.Element {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const hasChildren = currentForm.getFieldValue('hasChildren');

  return (
    <FormBox title="Dados Familiares" form={currentForm}>
      <Item
        label="Tem filhos com idade até 6 anos?"
        name="hasChildren"
        rules={rules.hasChildren}
      >
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      </Item>

      <Item
        label="Quantos?"
        name="numChildren"
        rules={hasChildren ? rules.numChildren : undefined}
      >
        <InputNumber disabled={!hasChildren} />
      </Item>
    </FormBox>
  )
}