import React, { useEffect, useState } from 'react';

import iconGestante from '../../assets/CMIC/gestante/woman.svg';
import NavigationCMIC from 'components/NavigationCMIC';
import amarelinhaImg from '../../assets/CMIC/gestante/amarelinha.svg';
import bonecaImg from '../../assets/CMIC/gestante/boneca.svg';

import gestanteEnableIconImg from '../../assets/CMIC/gestante/gestante-color.svg';
import gestanteDisableIconImg from '../../assets/CMIC/gestante/gestante-black.svg';
import bebeBarrigaIconImg from '../../assets/CMIC/gestante/bebe-barriga.svg';

import { Breadcrumb, Footer } from 'components';
import { TitlePage } from 'components/TitlePage';
import { CardMediumGeral, CardSmallHorizontal } from 'components/CardsCMIC';
import SectionInfoYellow from 'components/SectionInfoYellow';
import SectionInfosCMIC from 'components/SectionInfosCMIC';
import { Device } from 'components/Device';

import './styles.scss';
import EstimatedCard from 'components/EstimatedCard';
import findData from 'utils/findAndFormatCMIC';
import { getPregnantData } from 'services/resources/pregnantService';
import InfoProvide from 'components/InfoProvide';

type GestanteQuestion = {
  question: string;
  answer: string;
};

const GestanteCMIC = (): JSX.Element => {
  const device = Device.useDevice();

  const [dataGestante, setDataGestante] = useState<Array<GestanteQuestion>>();

  useEffect(() => {
    const response = async () => {
      const data = await getPregnantData();
      setDataGestante(data);
    };
    response().catch(() => setDataGestante([]));
  }, []);

  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/pesquisa-cmic',
      title: 'Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará',
    },
    {
      path: '/pesquisa-cmic/gestante',
      title: 'Gestante',
    },
  ];

  return (
    <>
      <section className="container-box">
        <section className="container__gestante">
          <div className="container__gestante-header">
            <Breadcrumb links={links} />
            <TitlePage style={{ margin: '0' }} title="Gestante" backButton />
          </div>
          <InfoProvide 
            message="Os dados abaixo foram coletados do Big Data Social a partir da base da Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará" 
            type="cemic"
          />
          <section className="container__gestante-center">

            <SectionInfoYellow image={iconGestante} title="Gestante">
              <ul
                className={`center__gestante-cards ${
                  device.isMD('less') && 'isXS'
                }`}
              >
                <li>
                  <CardMediumGeral
                    text="No Ceará, 15,28% dos partos realizados foram de mães adolescentes (idade menor que 20 anos). Fonte: Datapedia, 2019."
                  >
                    <EstimatedCard
                      iconEnable={gestanteEnableIconImg}
                      iconDisable={gestanteDisableIconImg}
                      number={
                        findData(
                          'X em cada 10 mulheres tiveram filhos na adolescência',
                          dataGestante,
                        )
                      }
                      title="mulheres tiveram filhos na adolescência"
                      withBackground={true}
                      subtitle={'(idade menor que 20 anos)'}
                    />
                  </CardMediumGeral>
                </li>
                <li>
                  <div className="card__consulta">
                    <div className="icon-card">
                      <img src={bebeBarrigaIconImg} alt="Feto" />
                    </div>
                    <p>
                      Média de consultas realizadas no pré-natal do último filho
                    </p>
                    <h2>
                      {findData('Média de consultas pré-natais', dataGestante)}
                    </h2>
                  </div>

                  <CardSmallHorizontal
                    number={findData('Idade média das mães no nascimento do primeiro filho', dataGestante)}
                    title="Idade média das mães no nascimento do primeiro filho"
                    type="meses"
                    style={{ marginBottom: '1rem' }}
                  />
                  <CardMediumGeral text="Ao todo nos 24 municípios, foram 73% das gestantes. Fonte: Datapedia, 2020. *Média obtida para os 24 municípios da pesquisa.">
                    <CardSmallHorizontal
                      number={findData('X% das gestantes compareceram a mais de 7 consultas pré-natais', dataGestante) }
                      title="das gestantes compareceram a mais de 7 consultas pré-natais"
                      type="porc"
                    />
                  </CardMediumGeral>
                </li>
              </ul>
            </SectionInfoYellow>

            {device.isMD('bigger') && (
              <div className="amarelinha-joking">
                <img
                  className="image-boneca"
                  src={bonecaImg}
                  alt="imagem de boneca"
                />
                <img
                  className="image-amarelinha"
                  src={amarelinhaImg}
                  alt="imagem de amarelinha"
                />
              </div>
            )}
          </section>

          <SectionInfosCMIC title="Vulnerabilidade" subtitle="Gestante">
            <div
              className={`container__infos-green ${
                device.isMD('less') && 'isXS'
              }`}
            >
              <CardSmallHorizontal
                number={findData(
                  'Porcentagem de entrevistados que se consideram incapazes de desempenhar um papel útil em sua vida',
                  dataGestante,
                )}
                title="Se sentem incapazes de desempenhar um papel útil em sua vida"
                type="porc"
              />
              <CardSmallHorizontal
                number={findData(
                  'Porcentagem de entrevistados que não se sentem seguras em casa',
                  dataGestante,
                )}
                title="Não se consideram seguras em casa"
                type="porc"
              />
            </div>
          </SectionInfosCMIC>
        </section>
      </section>
      <NavigationCMIC type="gestante" />
      <Footer />
    </>
  );
};

export default GestanteCMIC;
