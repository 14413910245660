function currentSemester(month?: number) {
  if(month)
    return month < 7 ? 1 : 2;
  const currentMonth = new Date().getMonth()+1;
  return currentMonth < 7 ? 1 : 2;
}

function currentYear() {
  const year = new Date().getFullYear();

  return year;
}

export {
  currentSemester,
  currentYear
}