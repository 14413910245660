import { onlyNumbers } from './general';

const RESERVED_NUMBERS = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

const CHECK_DIGITS_INDEXES = [9, 10];

export function isValidFormat(cpf: string): boolean {
  return /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/.test(cpf);
}

export function generateChecksum(
  base: string | number,
  weight: number | number[],
): number {
  const digits = onlyNumbers(base);

  const weights =
    typeof weight === 'number'
      ? Array(digits.length)
          .fill(0)
          .map((_, i) => weight - i)
      : weight;

  return digits
    .split('')
    .reduce((acc, digit, i) => acc + parseInt(digit, 10) * weights[i], 0);
}

export function isReservedNumber(cpf: string): boolean {
  return RESERVED_NUMBERS.indexOf(cpf) >= 0;
}

export function isValidChecksum(cpf: string): boolean {
  return CHECK_DIGITS_INDEXES.every(i => {
    const mod =
      generateChecksum(
        cpf
          .slice(0, i)
          .split('')
          .reduce((acc, digit) => acc + digit, ''),
        i + 1,
      ) % 11;

    return cpf[i] === String(mod < 2 ? 0 : 11 - mod);
  });
}

export function isValidCPF(cpf: string): boolean {
  if (!cpf || typeof cpf !== 'string') return false;

  const digits = onlyNumbers(cpf);

  return (
    isValidFormat(cpf) && !isReservedNumber(digits) && isValidChecksum(digits)
  );
}

export function isValidPhone(phone: string) {
  if (!phone || typeof phone !== 'string') return false;

  const digits = onlyNumbers(phone);

  return (
    digits.length >= 10
  );
}
