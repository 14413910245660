const generalInfos = {
  columns: 2,

  fields: [
    {
      key: 'nome',
      label: 'Nome',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'edicao',
      label: 'Edição',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Edição é obrigatório',
        },
      ],
    },
    {
      key: 'descricaoProjeto',
      label: 'Descrição do projeto',
      widget: 'textarea',
      viewWidget: 'simple-view',
    },
    {
      key: 'valorInicial',
      label: 'Valor Inicial',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'valorAditivo',
      label: 'Valor Aditivo',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'total',
      label: 'Total (Valor Inicial + Valor Aditivo)',
      widget: 'number',
      vieWidget: 'simple-view',
      widgetProps: { bordered: false, disabled: true },
    }
    
  ],
};

const periodoInfos = {
  columns: 2,
  fields: [
    {
      key: 'periodo.dataInicio',
      label: 'Data início',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'periodo.dataFim',
      label: 'Data fim',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
  ],
};

export function prevencaoEmFamilia() {
  return [
    { title: 'Informações gerais', value: generalInfos },
    { title: 'Periodos', value: periodoInfos },
  ];
}
