import React, { memo, useContext, useEffect, useState } from 'react';

import { Steps } from 'antd';

import './styles.scss';
import { TimelineContext } from 'utils/context/timelineContext';

interface ITimelineCardInfo {
  iniciativasRes: {
    categoria: string;
    idIniciativa: string;
    nome: string;
    qtd: number;
    regioes: string[];
  }[];
  mes: number;
}
interface CurrentStepProps {
  ano: number;
  meses: ITimelineCardInfo[];
}
interface StepsMobileProps {
  allYear: Array<CurrentStepProps>;
  onClickStep: (value: boolean) => void;
}

const StepsMobile = ({
  allYear,
  onClickStep,
}: StepsMobileProps): JSX.Element => {
  const { currentYearScroll } = useContext(TimelineContext);

  const [current, setCurrent] = useState(0);

  const { Step } = Steps;

  function handleOnChange(event) {
    onClickStep(true);

    setCurrent(event);
  }
  useEffect(() => {
    let indiceCurrentElementScroll = 0;
    for (let i = 0; i < allYear.length; i += 1) {
      if (allYear[i].ano === parseInt(currentYearScroll, 10)) {
        indiceCurrentElementScroll = i;
      }
    }

    setCurrent(indiceCurrentElementScroll);
  }, [currentYearScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClickStep(ano: number) {
    onClickStep(true);

    const myElement = document.getElementById(`${ano}`);
    if (myElement) {
      myElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
  return (
    <section className="container__steps" id="step-controler">
      <p>Anos</p>
      <div className="align-central">
        <Steps
          progressDot
          current={current}
          onChange={handleOnChange}
          direction="vertical"
        >
          {allYear.map(ano => {
            return (
              <Step
                key={ano.ano + 1}
                onClick={() => handleClickStep(ano.ano)}
              />
            );
          })}
        </Steps>
      </div>
    </section>
  );
};

export default memo(StepsMobile);
