import React from 'react';
import { Link } from 'react-router-dom';

import { Modal } from 'antd';
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import './styles.scss';

interface IModal {
  mensagem: string
  id: string
}

const ModalConfirma = ({ mensagem, id}: IModal) => {
  return (
        <Modal
          centered
          visible
          width={363}
          cancelText={false}
          okText={false}
          closable={false}
        >
          <div className="modal">
            <div className="icon-image">
              <IoCheckmarkCircleOutline/>
            </div>
            <h1>Tudo certo!</h1>
            <p>
              {mensagem}
            </p>
            <Link to={`/historico-acao/${id}`}> OK</Link>
          </div>
        </Modal>
  );
};

export default ModalConfirma;
