import { yearsNames } from 'pages/NewRegisterAction/utils/inputs';

const generalInfos = {
  fields: [
    {
      key: 'municipio',
      label: 'Município',
      viewWidget: 'simple-view',
    },
    {
      key: 'localizacao',
      label: 'Localização',
      viewWidget: 'simple-view',
    },
    {
      key: 'mes',
      label: 'Mês',
      widget: 'select',
      options: [
        [1, 'Janeiro'],
        [2,'Fevereiro'],
        [3,'Março'],
        [4,'Abril'],
        [5,'Maio'],
        [6,'Junho'],
        [7,'Julho'],
        [8,'Agosto'],
        [9,'Setembro'],
        [10,'Outubro'],
        [11,'Novembro'],
        [12,'Dezembro']
      ],
      viewWidget: 'simple-view',
    },
    {
      key: 'ano',
      label: 'Ano',
      widget: 'select',
      options: yearsNames,
    },
    {
      key: 'qtdAtendimentos',
      label: 'Número de atendimentos',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'qtdCriancasAtendidas',
      label: 'Número de crianças atendidas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ],
};

export function nucleoEstimulacaoPrecoce() {
  return [{ title: 'Informações geral', value: generalInfos }];
}
