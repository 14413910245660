export function onlyNumbers(input: string | number): string {
  return String(input).replace(/[^\d]/g, '');
}

export function uniqueKeyGenerator(
  parentIndex: number,
  childIndex: number,
): number {
  let parentIndexAux = parentIndex;
  let childIndexAux = childIndex;
  if (parentIndex > 0) {
    parentIndexAux = 5 / parentIndexAux;
  } else parentIndexAux = (parentIndexAux + 3) / 5;
  if (childIndex > 0) {
    childIndexAux = 3 / childIndexAux;
  } else childIndexAux = (childIndexAux + 7) / 5;

  return parentIndexAux * 7 + (childIndexAux * 5) / 11;
}
