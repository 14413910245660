import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import './styles.scss';

const antIcon = <LoadingOutlined spin />;

export function LoaderCMIC() {

  return (
    <Spin className="spin-loading-cmic" size="large" indicator={antIcon} /> 
  );
}