import React from 'react';

import './styles.scss';

import reguaLogos from '../../assets/footer/iris_regua_de_logos.png';
import facebookImg from '../../assets/footer/facebook.svg';
import instagramImg from '../../assets/footer/instagram.svg';
import twitterImg from '../../assets/footer/twitter.svg';
import youtubeImg from '../../assets/footer/youtube.svg';
import { Device } from 'components/Device';

const Footer = () => {

  const device = Device.useDevice();

  return (
    <footer>
      <div className="line">
        <div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
      <section className="footer__content container-box">
        <img style={{width: '90%'}} src={reguaLogos} alt="Logos do laboratório Iris, do Programa Mais Infância e do Governo do Estado do Ceará junto ao nome da SPS" />

        { device.isMD() && 
          <div className="menu-footer">
            <span><a href="google.com">Acesso à Informação</a></span>
            <span><a href="google.com">Mapa do Site</a></span>
            <span><a href="google.com">Políticas de Privacidade</a></span>
          </div>
        }
          <div className="bloco__redes">
            <div className="icon-img">
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/governodoceara"><img src={facebookImg} alt="logo facebook" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/governodoceara/"><img src={instagramImg} alt="logo instagram" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/GovernoDoCeara"><img src={twitterImg} alt="logo twitter" /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCkpDYTqJkGp66nHngzZVzAw"><img src={youtubeImg} alt="logo youtube" /></a>
            </div>
            <p>Central de Atendimento - <b>155</b></p>
            <p>Acessibilidade - <b>0800 275 0022</b></p>
          </div>
       
      </section>
      <section className="acesso__infor">
          <p>&copy; Governo do Estado do Ceará. Todos os direitos reservados.</p>
      </section>
    </footer>
  )
  
};

export default Footer;
