import React, { Dispatch, SetStateAction } from 'react';
import { Button, Checkbox, Divider, Select } from 'antd';
import { GoSettings } from 'react-icons/go';

import { Container, FilterBox, FilterMobileBox } from './styles';

type FilterActivitiesProps = {
  isMobileScreen?: boolean;
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
  neighborhoods: string[];
  neighborhoodState: string;
  setNeighborhood: Dispatch<SetStateAction<string>>;
  showOnlyVisitationDataInCalendar: boolean;
  setShowOnlyVisitationDataInCalendar: Dispatch<SetStateAction<boolean>>;
  onFilterButtonClicked: () => void;
  isFilterLoading: boolean;
};

const { Option } = Select;

export function ActivityFilters({ 
  isMobileScreen=false,
  status, 
  setStatus,
  neighborhoods,
  neighborhoodState,
  setNeighborhood,
  showOnlyVisitationDataInCalendar,
  setShowOnlyVisitationDataInCalendar,
  onFilterButtonClicked,
  isFilterLoading,
}: FilterActivitiesProps) {
  return (
    <Container>
      {isMobileScreen ? (
        <FilterMobileBox>
          <div>
            <span>Exibir</span>
            <Select defaultValue="Todos os status" value={status} onChange={setStatus}>
              <Option key='1' value="0">Todos os status</Option>
              <Option key='2' value="VISITED">Visitados</Option>
              <Option key='3' value="UNVISITED">Não Visitados</Option>
              <Option key='4' value="SCHEDULED">Agendados</Option>
            </Select>
          </div>
          <div>
            <span>Ordenar por</span>
            <Select value={neighborhoodState} onChange={setNeighborhood}>
              <Option key='155' value="0">Todos os bairros</Option>
              {neighborhoods.map(neighbor => (
                <Option 
                  value={neighbor}
                  key={neighbor}
                >
                  {neighbor}
                </Option>
              ))}
              
            </Select>

            <Checkbox
              checked={showOnlyVisitationDataInCalendar}
              value={showOnlyVisitationDataInCalendar} 
              onChange={e => setShowOnlyVisitationDataInCalendar(e.target.checked)}
            >
              <span
                style={{
                  fontWeight: 'normal',
                }}
              >Mostrar somente dados de visitação?</span>
            </Checkbox>
          </div>
          <div className="filter-mobile-button">
            <Button 
              type="primary" 
              className="apply-filters-button" 
              onClick={onFilterButtonClicked}
              loading={isFilterLoading}
            >
              <span>Aplicar filtros</span>
            </Button>
            </div>
          </FilterMobileBox>
        ) : (
          <FilterBox>
          <div className="filter-box-content">
            <span>
              <GoSettings color="#2D4040" size={22}/>
              Filtro de exibição das visitas
            </span>
          </div>
          <div className="main-filter-box-content">
            <div className="filters">
              <div>
                <span>Exibir</span>
                <Select defaultValue="Todos os status" value={status} onChange={setStatus}>
                  <Option key='1' value="0">Todos os status</Option>
                  <Option key='2' value="VISITED">Visitados</Option>
                  <Option key='3' value="UNVISITED">Não Visitados</Option>
                  <Option key='4' value="SCHEDULED">Agendados</Option>
                </Select>
              </div>

              <Divider />
              <div>
                <span>Ordenar por</span>
                <Select 
                  style={{ marginBottom: 10 }} 
                  showSearch 
                  defaultValue="Bairro-A-Z" 
                  value={neighborhoodState} 
                  onChange={setNeighborhood}
                >
                  <Option key='155' value="0">Todos os bairros</Option>
                  {neighborhoods.map(neighbor => (
                    <Option 
                      value={neighbor}
                      key={neighbor}
                    >
                      {neighbor}
                    </Option>
                  ))}
                </Select>

                <Checkbox
                  checked={showOnlyVisitationDataInCalendar}
                  value={showOnlyVisitationDataInCalendar} 
                  onChange={e => setShowOnlyVisitationDataInCalendar(e.target.checked)}
                >
                  <span
                    style={{
                      fontWeight: 'normal',
                      // fontSize: '1.4rem'
                    }}
                  >Mostrar somente dados de visitação?</span>
                </Checkbox>
              </div>
              
              <Button 
                type="primary" 
                className="apply-filters-button" 
                onClick={onFilterButtonClicked}
                loading={isFilterLoading}
              >
                Aplicar filtros
              </Button>
            </div>
          </div>
        </FilterBox>
      )}
    </Container>
  );
}