import { formattedStringKey } from 'utils/strings';
import XLSX from 'xlsx';


export function titleTable(data, atualDados) {

  for (let key in data) {
    if (
      key === 'classe' ||
      key === 'ultimaModificacao' ||
      key === 'id' ||
      key === 'tipo' ||
      key === 'anexos' ||
      key === 'ultimoEditor' ||
      key === 'arquivosRegistro'
    )
      continue;
    else {
      if (Array.isArray(data[key])) {
        if(typeof data[key][0] === 'string')atualDados.push(formattedStringKey(key));
        else continue
      } 
      else if (
        typeof data[key] === 'object' &&
        Array.isArray(data[key]) === false
      )
        titleTable(data[key], atualDados);
      else atualDados.push(formattedStringKey(key));
    }
  }
}

function testFilter(arrayEvent){
  let tipos: any = []
  tipos.push(arrayEvent.filter(action => {
    return action.criancaFeliz.tipo === 'Registro acumulado'
  }))
  tipos.push(arrayEvent.filter(action => {
    return action.criancaFeliz.tipo === 'Registro capacitação'
  }))
  tipos.push(arrayEvent.filter(action => {
    return action.criancaFeliz.tipo === 'Evento regular'
  }))

  return tipos
}

function percorrerObjeto(obj, linha) {
  Object.keys(obj).forEach(key => {
    if(typeof obj[key] === 'object' && Array.isArray(obj[key]) === false) percorrerObjeto(obj[key], linha)
    else if(Array.isArray(obj[key])) linha.push(obj[key].join(', '))
    else if (key.includes('data')) linha.push(new Date(obj[key]).toLocaleDateString('fr-FR'));
    else linha.push(obj[key])
  })
}

const titulos = ['Registro Acumulado', 'Registro Capacitação', 'Evento Regular']

export function formatXLSXcriancaFeliz(data, workbook) {
  const tipos = testFilter(data);

  tipos.forEach((value, index) => {
    if(value.length > 0){
      let dados: any = []
      let linha: any = []
      let tipo = index === 0 ? 'registroAcumulado' : 
                 index === 1 ? 'registroCapacitacao' : 
                 'eventoRegular'
      workbook.SheetNames.push(
        `${titulos[index]}`,
      );
  
      titleTable(value[0]?.criancaFeliz, linha)
      dados.push(linha)
      
      value.forEach(dado => {
        linha = []
        const criancaFeliz = dado.criancaFeliz[tipo]
        percorrerObjeto(criancaFeliz, linha)
        dados.push(linha)
      })
    
  
   
        let content = XLSX.utils.aoa_to_sheet(dados);
      workbook.Sheets[
        titulos[index]
      ] = content;
      
    }

  })
  return workbook
}