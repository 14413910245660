import { socialApi } from "services/api";
import { Demand } from 'types'

const service = () => {
  const resource = `/demands`;

  async function postDemand(data: Demand | undefined) {
    try {
      const response = await socialApi.post(`${resource}`, data)
      
      return response.data;
    }catch (err) {
      return Promise.reject(err);
    }
  }

  return{
    postDemand
  }
}

export const demandService = service();