import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  
`;

export const Main = styled.main`
  background: #FFF;
  padding: 5rem 10rem;
  background: #FFF;
  max-width: 1400px;
  margin: 0 auto;

  @media(max-width: 900px) {
    padding: 2rem;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.6rem;
  flex-wrap: wrap;
  margin-top: 5rem;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 4rem;
  color: #2D4040;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  
  width: 40.1rem;
  min-width: 200px;
  height: 100%;

  background: #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  align-items: center;
  text-align: center;

  padding: 2rem;
`;

export const BoxTitle = styled.h6`
  font-weight: 700;
  font-size: 2rem;
  line-height: 4rem;
  color: #2D4040;
  
`;

export const BoxDescription = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color:  #2D4040;
`;

export const BoxButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 80%;
  height: 36px;

  background: #4B9F37;
  border-radius: 5px;

  text-decoration: none;
  text-transform:uppercase;
  color: #FFF;
  
  font-weight: bold;
  line-height: 2.2rem;

  padding: 1rem;
  transition: background 0.4s;

  &:not(:disabled):hover {
    background: #55900E;
    color: #FFF;
  }

  &:disabled {
    background: #ddd;
  }
`;  

export const ActivityBoxButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 80%;
  height: 36px;

  background: #4B9F37;
  border-radius: 5px;
  border: 0;

  text-decoration: none;
  text-transform:uppercase;
  color: #FFF;
  
  font-weight: bold;
  line-height: 2.2rem;

  padding: 1rem;
  transition: background 0.4s;
  cursor: pointer;

  &:not(:disabled):hover {
    background: #55900E;
    color: #FFF;
  }

  &:disabled {
    background: #ddd;
    cursor: not-allowed;
  }

`;

export const BottomContent = styled.div`
  padding: 3rem 16rem;
  
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;

  .ant-divider {
    background: #ccc;
  }

  @media(max-width: 900px) {
    padding: 2rem ;
  }
`;

export const BottomTitle = styled.h6`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #2D4040;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-top: 30px;

  .ant-form-item {
    min-width: 200px;
    width: 30%;
    flex-direction: column;
    
    &.centered {
      label {
        opacity: 0;
      }
    }
    
    .ant-form-item-label{
      text-align: left;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;

      label {
        color: #2D4040;
        &::after {
          display: none;
        }
      }
    }

    .ant-select, .ant-input {
      width: 100%;
      min-width: 200px;
      border-radius: 4px;
      height: 35px;
    }
    
  }

  button.clear-filters {
    text-decoration: underline;
    border: 0;
    background: transparent;
    height: max-content;
    color: #4B9F37;

    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media(max-width: 900px) {
    .ant-form-item {
      width: 100%;
      
    }
  }
`;

export const BottomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  height: 40px;

  background: #4B9F37;
  border-radius: 5px;

  text-decoration: none;
  text-transform:uppercase;
  color: #FFF;
  
  font-weight: bold;
  line-height: 2.2rem;

  padding: 1rem;
  border: 0;
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow: hidden;
`;

export const SearchResultsLink = styled.button`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-decoration: underline;
  align-self: flex-start;

  color: #4B9F37;
  width: max-content;
  cursor: pointer;
  border: 0;
  background: transparent;
  
  white-space: nowrap;
  text-overflow: '...';
`;