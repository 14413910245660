import React, { useState, useEffect } from 'react';
import { Switch, Modal, Button, message, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import moment, { Moment } from 'moment';

import { Container, ModalButton, ModalInnerContent } from './styles';

import { Authorizer, currentSemester, currentYear, Footer, Calendar, GoBack, useWindowDimensions } from 'components';

import alertIcon from 'assets/articulator/alertIcon.svg';

import { Activity, MarkedVisitationPerFamily, VisitationFamily } from 'types'
import { visitService } from 'services/resources/visitService';
import { activityService, agentService, workPlanService } from 'services';

interface IGetParams {
  urlToGoBack: string;
  family: VisitationFamily;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const { Option } = Select;

export function EditDateOfVisitArticulator() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId; 

  const { state } = useLocation<IGetParams>();
  const { width } = useWindowDimensions();

  const [family, setFamily] = useState({} as VisitationFamily);

  const query = useQuery();
  const queryDate = query.get('prev_date') ?? '';

  const history = useHistory();
  
  const [checked, setChecked] = useState(true);
  const [hasChanged, setHasChanged] = useState(true);
  const lastVisitDate = moment(new Date(String(queryDate)));

  const [isModalVisible, setIsModalVisible] = useState(false);  

  const [isLoading, setIsLoading] = useState(false);

  const [isSaveButtonModalLoading, setIsSaveButtonModalLoading] = useState(false);

  const [markedVisitationFamilies, setMarkedVisitationFamilies] = useState<MarkedVisitationPerFamily[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  
  const [date, setDate] = useState<Moment>(lastVisitDate);

  const year = currentYear();
  const semester = currentSemester();

  const [workplanId, setWorkplanId] = useState(0);
  const [semestersSelect, setSemestersSelect] = useState<any[]>([]);

  async function getWorkplanId(agentId: string) {
    try {
      const response = await workPlanService.getHistoricWorkPlans(agentId);
      const semesterSelectInfos = response.map(workplan => ({ year: workplan.year, semester: workplan.semester, id: workplan.id }))
      setSemestersSelect(semesterSelectInfos);
      const firstWorkPlan = response.find(workplan => workplan.year===year && workplan.semester===semester && workplan.status==='APPROVED');
      setWorkplanId(Number(firstWorkPlan?.id));
      return firstWorkPlan;

    } catch(err) {
      message.error("Erro ao buscar workplan");
    }
  }

  function handleGoBack() {
    if(hasChanged) {
      return handleOpenModal();
    }
    
    history.push(`/articulador-plano-de-atividades/${family.nameRF}`, family);
  }  

  function handleOpenModal() {
    setIsModalVisible(true);
  }

  function handleCloseModal() {
    setIsModalVisible(false);
    history.goBack();
  }

  async function handleGetAllVisitationsData(workplan: number) {
    const key = 'calendarLoading';
    message.loading({ content: 'Carregando dados do calendário', key });
    try {
      const response = await visitService.getAllVisitationsDataExisting(workplan);

      setMarkedVisitationFamilies(response);
      message.success({ content: 'Dados carregados com sucesso', key })

    } catch(err) {
      message.error({ content: 'Erro ao buscar dados de visitação', key });
    }

  }

  async function handleCreateVisit() {
    setHasChanged(false);
    if(!date) 
      return;

    if(!isModalVisible) {
      setIsLoading(true);
    } else {
      setIsSaveButtonModalLoading(true);
    }
    const formatedDate = date.toISOString();
    
    const data = {
      date: formatedDate,
      observation: '',
      workplan: workplanId,
      family: family.id,
    }

    try {
      await visitService.createVisit(data);

      setIsModalVisible(false);

      message.success('Visita criada com sucesso!!');

      history.goBack();
      
    } catch(err: any) {
      console.log(err);
      const errMsg = err.response.data.mensagem || 'Erro';
      message.error(errMsg);
      setHasChanged(true);
    } finally {
      setIsLoading(false);
      setIsSaveButtonModalLoading(false);
    }
  }

  async function getActivitiesByWorkplanId(workplan: number) {
    try {
      const response = await activityService.getActivitiesByWorkplan(workplan);

      setActivities(response);
    } catch(err) {
      message.error('Erro ao buscar dados de atividades!!!');
    }
  }

  useEffect(() => {
    agentService.getProfessional(userId)
      .then(res => {
        getWorkplanId(String(res.id))
          .then(res => {
            if(res) {
              handleGetAllVisitationsData(res.id);
              getActivitiesByWorkplanId(res.id);
            }
          }).catch(err => message.error(err?.response.data.mensagem || 'Não foi possível buscar atividades relacionadas ao plano de trabalho atual!!'));
      })
      .catch(err => message.error('Cliente logado não é um articulador')); 
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if(state)
      setFamily(state.family);
  }, [state]);

  const SelectSemester = (
    !!semestersSelect && (
      <Select placeholder="Selecione um semestre" style={{ width: 200 }}>
        {semestersSelect.map(s => (
          <Option 
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} 
            key={s.id} 
            value={s.id}
          >
            {s.year}.{s.semester}
          </Option>
        ))}  
      </Select>
    )
  );

  const adaptativeCalendar = (isMobile=false) => (
    <Calendar 
      onChange={(date) => {
        setHasChanged(true);
        setDate(date);
      }} 
      dateClicked={date} 
      activities={activities}
      setActivities={setActivities}
      filteredVisits={markedVisitationFamilies}
      workplanId={workplanId}
      isMobile={isMobile}
      isEditDateScreen
      createActivityBoxOverlay={() => <div />}
      onCreateActivityBoxButtonClicked={() => {}}
      showEventIndicators={checked}
      SelectSemester={<div/>}
    />           
  )

  return (
    <>
      <Container hasChanged={hasChanged}>
        <header className="header-visitation-edit">
          <GoBack 
            onClick={handleGoBack}
            subtitle="Agendamento de visita"
            title={family.nameRF}
          />

          <div className="show-agenda">
            <span>
              Mostrar todos os agendamentos
              <Switch 
                size="small"
                checked={checked}
                onChange={(_checked, _) => {
                  setChecked(_checked);
              
                }}
              />

            </span>
          </div>
        </header>
        {width < 768 ? 
          adaptativeCalendar(true)
        : (
          adaptativeCalendar()
        )}

        <Button 
          className="save-updates-button" 
          type="primary"
          disabled={!hasChanged}
          onClick={handleCreateVisit}
          loading={isLoading}
          style={{
            background: '#4B9F37',
          }}
        >
          Salvar alterações
        </Button>
        
      </Container>
      <Modal 
        visible={isModalVisible}  
        closable={false}
        // destroyOnClose
        centered
        wrapClassName="modal-visit-content"
        maskClosable={false}
        onCancel={handleCloseModal}
        // confirmLoading={true}
      >
        <ModalInnerContent>
          <img src={alertIcon} alt="icone de alerta"/>

          <span>Alterações foram feitas</span>

          <p>Deseja salvar as alterações?</p>

          <div>
            <ModalButton
              onClick={handleCloseModal}
              color="#C2102A"
              htmlType="button"
              background="transparent"
              hasBorder={true}
              danger
              ghost
              style={{ cursor: 'pointer' }}
            >
              Cancelar
            </ModalButton>

            <ModalButton
              type="primary"
              loading={isSaveButtonModalLoading}
              color="#fff"
              htmlType="button"
              onClick={handleCreateVisit}
              style={{
                background: '#4B9F37',
                border: 0,
                color: '#fff',
                cursor: 'pointer',
              }}
            >
              Salvar
            </ModalButton>
          </div>
        </ModalInnerContent>
      </Modal>
      <Footer />
    </>
  );
}
