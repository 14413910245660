import { api } from 'services/api';

export function getInfosMapCMIC() {
  const response = api
    .get(`cemic/map`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}
