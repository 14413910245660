import React from 'react'

import './styles.scss';

type infoCardOnly = {
  image: string,
  title: string,
  number: string | JSX.Element
}

interface CardDoubleInfoProps {
  infosLeft: infoCardOnly,
  infosRight: infoCardOnly,
}

export const CardDoubleInfos = ({
    infosLeft,
    infosRight
  }: CardDoubleInfoProps): JSX.Element => {
    
  return(
    <section className="container__cardDoubleInfo" >  
      <div className="infos-left">
        <img src={infosLeft.image} alt={ `Icon de ${infosLeft.title}`} />
        <strong>{infosLeft.title}</strong>
        <div className="percent">
          <h2>{infosLeft.number}</h2>
          <p>%</p>
        </div>
      </div>
      <div className="infos-right">
        <img src={infosRight.image} alt={ `Icon de ${infosRight.title}`} />
        <strong>{infosRight.title}</strong>
        <div className="percent">
          <h2>{infosRight.number}</h2>
          <p>%</p>
        </div>
      </div>
    </section>
  )
}