import React, { useContext } from 'react';
import {
  TileLayer,
  GeoJSON,
  MapContainer,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import { UtilContext } from '../../utils/context/ultilContext';
import { IoLocateSharp } from 'react-icons/io5';

import L from 'leaflet';
import MapMarker from '../mapMarker';

import cearaGeojson from '../../data/ceara-full.json';

import iconObrasAndamento from '../../assets/iconObraAndamento.svg';
import iconObraInaugurada from '../../assets/iconObraInaugurada.svg';
import ObrasConcluidas from '../../assets/obras-concluidas.svg';
import ObrasStatusOff from '../../assets/obras-no-status.svg';

import { CheckboxContext } from '../../utils/context/checkboxContext';

import './styles.scss';

function MyComponent({ latitude, longitude }) {
  const mapa = useMap();
  mapa.flyTo([latitude, longitude], 11);

  const map = useMapEvents({
    click: () => {
      map.setView([latitude, longitude], 11);
    },
  });
  return null;
}

interface IObras {
  categoria: string;
  descricao: string;
  eventos: {
    datas: {
      dataFimPrevisto: string;
      dataInicio: string;
      dataOrdemServico: string;
    };
    descricao: string;
    docs: {
      documento: string;
      imagem: string;
    };
    id: string;
    localizacao: {
      enderecoCompleto: string;
      estacao: string;
      latitude: number;
      longitude: number;
      municipio: string;
      pontoDeReferencia: string;
      regiaoPlanejamento: string;
    };
    nome: string;
    observacao: string;
    recursos: {
      mudasFrutiferas: number;
      mudasNativas: number;
      totalInvestido: number;
    };
    status: string;
    ultimaModificacao: string;
  }[];
  id: string;
  mapeamento: {
    datasMapeamento: {
      dataFimPrevisto: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      dataInicio: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      dataOrdemServico: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    docMapeamento: {
      documento: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      imagem: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    localizacaoMapeamento: {
      enderecoCompleto: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      estacao: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      latitudeLongitude: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      municipio: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      pontoDeReferencia: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      regiaoPlanejamento: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    nomeEventoMapeamento: {
      editavel: boolean;
      obrigatorio: boolean;
      usado: boolean;
    };
    observacaoMapeamento: {
      editavel: boolean;
      obrigatorio: boolean;
      usado: boolean;
    };
    recursosMapeamento: {
      mudasFrutiferas: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      mudasNativas: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      totalInvestido: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    statusMapeamento: {
      editavel: boolean;
      obrigatorio: boolean;
      usado: boolean;
    };
  };
  nome: string;
  subcategoria: string;
  tempoDe: string;
  totalDeEventos: number;
  ultimaModificacao: string;
}

const serviceMapStyle = {
  color: '#00B894',
  weight: 2,
  opacity: 1,
  fillColor: '#52DF9A',
  fillOpacity: 0.15,
};

const getMapCenter = (regionGeojson: any) => {
  const polygon = L.polygon(regionGeojson[0].geometry.coordinates)
    .getBounds()
    .getCenter();

  const { lat: longitude, lng: latitude } = polygon;

  return { latitude, longitude };
};

interface IBaseMap {
  dataObras: Array<IObras> | undefined;
}

const BaseMap = ({ dataObras }: IBaseMap): JSX.Element => {
  const { selectRegion } = useContext(UtilContext);

  const selectRegionGeojson = cearaGeojson.features.filter(
    municipio =>
      municipio.properties.name.toUpperCase() === selectRegion.toUpperCase(),
  );

  const { latitude, longitude } = getMapCenter(selectRegionGeojson);
  const { checkedListObras, checkObrasFilterOn, checkedListStatus } =
    useContext(CheckboxContext);

  return (
    <div className="servicemap-container">
      <MapContainer
        center={[latitude, longitude]}
        zoom={11}
        maxZoom={18}
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" />
        <GeoJSON
          key={selectRegionGeojson[0].properties.id}
          data={JSON.parse(JSON.stringify(selectRegionGeojson))}
          style={serviceMapStyle}
        />

        <span className="center">
          <IoLocateSharp />
        </span>

        <MyComponent latitude={latitude} longitude={longitude} />

        {checkObrasFilterOn && (
          <>
            {dataObras?.map(obras =>
              obras.eventos
                .filter(
                  obrasDaRegião =>
                    obrasDaRegião.localizacao.municipio.toUpperCase() ===
                      selectRegion.toUpperCase() &&
                    checkedListObras?.includes(obras.nome),
                )
                .map(praca => (
                  <MapMarker
                    key={praca.id}
                    marker={praca}
                    icon={
                      checkedListStatus.includes('Em andamento') &&
                      praca.status === 'Em andamento'
                        ? iconObrasAndamento
                        : checkedListStatus.includes('Concluida') &&
                          praca.status === 'Concluida'
                        ? ObrasConcluidas
                        : checkedListStatus.includes('Inaugurada') &&
                          praca.status === 'Inaugurada'
                        ? iconObraInaugurada
                        : ObrasStatusOff
                    }
                  />
                )),
            )}
          </>
        )}
      </MapContainer>
    </div>
  );
};

export default BaseMap;
