import React, {useState} from 'react';
import { Upload, message, Row, Col } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IoCamera } from 'react-icons/io5'

interface InputCEPProps {
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export function InputUpload({
  value = 'uma url para imagem',
  onChange,
  bordered,
  disabled,
}: InputCEPProps) {

  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(value);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  
  const UploadImage = async (e: any) => {
    const file = e.file
    setLoading(true)
    const base64 = await getBase64(file.originFileObj)
    setLoading(false)
    let strBase64 = String(base64)
    setText(strBase64)
    if (onChange) {
      onChange(text || '');
    }
    
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Somente arquivos PNG e JPeg!');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('O arquivo deve ser menor 10MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  return (
    <>

    {!disabled ? 
    <Row>
     <Col span={15}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        accept="image/png, image/jpeg"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={UploadImage}
      >
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
      </Upload>
      </Col>
      <Col span={8} offset={1}>
        <div className="img-default">
        {value !== "uma url para imagem" ? <img src={value} alt="avatar" style={{ width: '100%' }}/> :
        <IoCamera/>}
        </div>
      </Col>
    </Row> : 
    <div className="img-default">
      {value !== "uma url para imagem" ? <img src={value} alt="avatar" style={{ width: '100%' }}/> :
      <IoCamera/>}
    </div>
    }
    </>
  );
}