import { ITimelineCard } from 'components/timeline2.0/types';
import VerticalTimeLine from 'components/verticalTimeline';
import React, { memo } from 'react';
import './styles.scss';

interface ISliderMobile {
  forYear?: ITimelineCard;
}
const SliderMobile = ({ forYear }: ISliderMobile): JSX.Element => {
  return (
    <section className="container__slider-center">
      {forYear?.meses.map((iniciativa: any, i) => {
        return (
          <div
            key={`slider-center-${i}`}
            className="container__section-mobile"
            style={{
              minHeight: `${
                iniciativa.iniciativasRes.length > 1
                  ? iniciativa.iniciativasRes.length * 215
                  : iniciativa.iniciativasRes.length * 300
              }px`,
            }}
          >
            <VerticalTimeLine
              ano={iniciativa.ano}
              currentFrame={iniciativa}
              defaultMonth={iniciativa.mes}
            />
            <hr />
          </div>
        );
      })}
    </section>
  );
};

export default memo(SliderMobile);
