import React from 'react';
import { FormInstance } from 'antd';

interface PraiaAcessivelFormProps {
  form?: FormInstance;
}

export function PraiaAcessivelForm({ form }: PraiaAcessivelFormProps) {
  // const [antForm] = Form.useForm();

  // const [data, setData] = useState(camposActions.praiaAcessivel);

  // function addEdition(index) {
  //   const newData = {
  //     edicao: index + 1,
  //     idade: {
  //       adultos: 0,
  //       entre12e19: 0,
  //       idosoMaiorQue60: 0,
  //       menoresDe6: 0,
  //     },
  //     periodo: {
  //       dataFim: '',
  //       dataInicio: '',
  //     },
  //   };
  // }

  // function removeEdition(index) {
  //   /*     data.pessoasPorFaixaEtariaAcao.splice(index, 1); */
  //   setData({ ...data });
  // }

  return <div></div>;
}
