import React, { useEffect, useState } from 'react';
import { Select, Input, Form, Divider, message, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { 
	BottomContent,
	BottomTitle,
	BottomWrapper,
	Box, 
	BoxButton, 
	BoxDescription, 
	BoxTitle, 
	BoxWrapper, 
	Container, 
	Main,
	PageTitle,
	SearchResults,
	SearchResultsLink, 
} from "./styles";
import { agentService, familiesService, localService } from 'services';
import { VisitationFamily } from 'types';
import { Authorizer, Footer } from 'components';
import Pagination from 'antd/es/pagination';


const { Option } = Select;
const { Item } = Form;

export function ArticulatorHome() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;

  const [families, setFamilies] = useState<VisitationFamily[]>([]);
  const [neighborhood, setNeighborhood] = useState('');
  const [nis, setNis] = useState('');
  const [isFamilyLoading, setIsFamilyLoading] = useState(false);
  const history = useHistory();
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [agentCity, setAgentCity] = useState('');
  const pageSize = 25;

  async function handleSearchForFamily(_page: number) {
    setIsFamilyLoading(true);
    
    try {
      const response = await familiesService.getFamilyByCityNeighborhoodAndNis(_page, agentCity, neighborhood, nis);

      if(response.content.length===0)
        message.error('Naõ foi encontrada nenhuma família correspondente!');

      setFamilies(response.content);
      setTotalPages(response.total);

    } catch(err) {
      message.error('Não encontrado');
    }

    setIsFamilyLoading(false);
  }

  function handleNavigateToFamilyDetail(family: VisitationFamily) {
    history.push(`/articulador-plano-de-atividades/${family.nis}`);
  }

  function handleCleanFilters() {
    setNeighborhood('');
    setNis('');
  }

  async function handleGetAgent() {
    const response = await agentService.getProfessional(userId);
    return response;
  }

  async function getNeighborhoodByAlocatedCityAgent(city: string) {
    try {
      const response = await localService.getNeighborhoodByCity(city);

      setNeighborhoods(response);

    } catch(err) {
      message.error(err.response?.data?.mensagem || 'Não foi possível pegar famílias do bairro especificado');
    }
  }

  useEffect(() => {
    handleGetAgent()
      .then((res) => {
        setAgentCity(res.city);
        getNeighborhoodByAlocatedCityAgent(res.city)
      })
      .catch(err => message.error(err.response?.data?.mensagem || 'Deu erro'));

      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  
	return (
		<>
      <Container>
        <Main>
          <PageTitle>
            Área do Agente Mais Infância
          </PageTitle>

          <BoxWrapper>
            <Box>
              <BoxTitle>Plano de Trabalho</BoxTitle>
              <BoxDescription>
                Monte seu plano semestral de trabalho e acompanhe as famílias visitadas.
              </BoxDescription>

              <BoxButton to="/articulador-plano-de-trabalho">
                Plano de Trabalho
              </BoxButton>
            </Box>

            <Box>
              <BoxTitle>Plano de Atividades</BoxTitle>

              <BoxDescription>
                Monte seu plano mensal de atividades, montando seus horários e datas.
              </BoxDescription>

              <BoxButton to="/articulador-plano-de-atividades">
                Plano de Atividades
              </BoxButton>
            </Box>

            <Box>
              <BoxTitle>Famílias</BoxTitle>

              <BoxDescription>
                Verifique famílias ativas visitadas e não visitadas
              </BoxDescription>

              <BoxButton to="/articulador-familias-visitadas">
                Famílias Visitadas
              </BoxButton>
              <BoxButton to="/articulador-familias-nao-visitadas">
                Famílias Não Visitadas
              </BoxButton>
            </Box>

          </BoxWrapper>
        </Main>
        <BottomContent>
          <BottomTitle>
            Acompanhamento de famílias
          </BottomTitle>

          <BottomWrapper>
            
            <Item className='input' name="district" label="Bairro">
                    <Select placeholder='Bairro' value={neighborhood} onChange={setNeighborhood} showSearch>
                      {neighborhoods.map((district: string, key: number) => (
                        <Option value={district} key={key}>
                          {`${district}`}
                        </Option>
                      ))}
                    </Select>
                    <span
                style={{ opacity: 0 }}
              >
                bairro
            </span>
                  </Item>
            
            <Item label="Número do NIS">
              <Input 
                maxLength={11} 
                minLength={11}
                value={nis}
                onChange={e => setNis(e.target.value)}
              />

              <button  
                className="clear-filters"
                type="button"
                onClick={handleCleanFilters}
              >
                Limpar filtros
              </button>
            </Item>
            <Item label="." className="centered">
              <Button
                type="primary"
                onClick={()=>handleSearchForFamily(0)}
                style={{ 
                  background: '#4B9F37',
                  textTransform: 'uppercase',
                  border: 0,
                  fontWeight: 'bold',
                  width: '100%'
                }}
                loading={isFamilyLoading}
              >
                Buscar Família
              </Button>
              <span
                style={{ opacity: 0 }}
              >
                buscar
              </span>
            </Item>
        
          </BottomWrapper>
          <Divider />
          <SearchResults>
            {families.length > 0 && families.map(family => (
              <SearchResultsLink 
                key={family.nis}
                onClick={() => handleNavigateToFamilyDetail(family)}
              >
                {family.nameRF}
              </SearchResultsLink>
            ))}
          { families.length > 0 && (
              <Pagination 
                hideOnSinglePage
                showSizeChanger={false}
                defaultCurrent={1} 
                total={totalPages} 
                pageSize={pageSize}
                onChange={(_page, pageSize) => {
                  setPage(_page);
                  handleSearchForFamily(_page-1);
                }}
                responsive
                current={page}
              />
          ) }
          </SearchResults>
        </BottomContent>
		  </Container>
      <Footer />
    </>
	);
}