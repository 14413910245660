const menuIcons = [
  { image: require('../../../assets/pieChart-icons/home-icon.svg') },
  { image: require('../../../assets/pieChart-icons/care-icon.svg') },
  { image: require('../../../assets/pieChart-icons/health-care-icon.svg') },
  { image: require('../../../assets/pieChart-icons/anti-gun-icon.svg') },
  { image: require('../../../assets/pieChart-icons/clean-icon.svg') },
  { image: require('../../../assets/pieChart-icons/eat-icon.svg') },
  { image: require('../../../assets/pieChart-icons/work-icon.svg') },
  { image: require('../../../assets/pieChart-icons/studies-icon.svg') },
];

const healthSubIcons = [
  { image: require('../../../assets/pieChart-icons/eat-icon.svg') },
  { image: require('../../../assets/pieChart-icons/studies-icon.svg') },
  { image: require('../../../assets/pieChart-icons/work-icon.svg') },
  { image: require('../../../assets/pieChart-icons/studies-icon.svg') },
  { image: require('../../../assets/pieChart-icons/work-icon.svg') },
];

const violenceSubIcons = [
  { image: require('../../../assets/pieChart-icons/eat-icon.svg') },
  { image: require('../../../assets/pieChart-icons/work-icon.svg') },
  { image: require('../../../assets/pieChart-icons/studies-icon.svg') },
  { image: require('../../../assets/pieChart-icons/work-icon.svg') },
];

export { menuIcons, healthSubIcons, violenceSubIcons };
