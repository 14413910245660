
const generalInfos = {
  fields: [
    {
      key: 'numeroFamiliasAtendias',
      label: 'Quantidade de famílias atendidas',
      widget: 'number',
      viewWidget: 'simple-view',
    },
    {
      key: 'numeroRecargasDistribuidas',
      label: 'Quantidade de recargas distribuídas',
      widget: 'number',
      viewWidget: 'simple-view',
    },
  ],

  
};
export function valeGas() {
  return [
    { title: 'Informações', value: generalInfos },
  ];
}
