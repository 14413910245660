export function makeJSONAction(nameAction: string, values: any) {

  if (nameAction === 'Arte na Praça') return { arteNaPraca: values };
  if (nameAction === 'Toda Banda de Música é uma escola')
    return { bandaMusicaEscola: values };
  if (nameAction === 'Capacitação dos Agentes Comunitários de Saúde')
    return { capacitacaoAgentesComunitariosSaude: values };
  if (nameAction === 'Documentários') return { documentario: values };
  if (nameAction === 'Lançamento de Livro ou Relatório')
    return { lancamentoLivroRelatorio: values };
  if (nameAction === 'Núcleos de Estimulação Precoce')
    return { nucleoEstimulacaoPrecoce: values };
  if (nameAction === 'Praia Acessível') return { praiaAcessivel: values };
  if (nameAction === 'Prevenção em Família')
    return { prevencaoEmFamilia: values };
  if (nameAction === 'Sementes do Amanhã') return { sementesDoAmanha: values };
  if (nameAction === 'Seminário Internacional Mais Infância Ceará')
    return { seminarioMaisInfancia: values };
  if (nameAction === 'Educação Ambiental') return { educacaoAmbiental: values };
  if (nameAction === 'Padin') return { padin: values };
  if (nameAction === 'Publicações') return { publicacoes: values };
  if (nameAction === 'Mais Nutrição') return { maisNutricao: values };
  if (nameAction === 'Criança Feliz') return { criancaFeliz: values };
  if (nameAction === 'Capacitação') return { capacitacao: values };
  if (nameAction === 'Formação Seduc') return { formacaoSeduc: values };
  if (nameAction === 'Vale Gás') return { valeGas: values };
}
