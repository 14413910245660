import CitiesCeara from '../data/ceara-full.json';

function citiesNamesCeara() {
  const citiesNames: string[] = [];

  CitiesCeara.features.map(city => {
    return citiesNames.push(city.properties.name);
  });

  return citiesNames;
}

export const citiesNamesCE = citiesNamesCeara();
