import React, { useEffect, useState } from 'react';
import { Col, Input } from 'antd';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import moment from 'moment';

import { Footer, GoBack } from 'components';
import { VisitationFamily } from 'types'

import { Container } from './styles';
import calendarImg from 'assets/articulator/calendar.svg';

import { socialApi } from 'services/api';

const { TextArea } =  Input;

export function FamilySearchedForArticulatorDetail() {
  const { url } = useRouteMatch();
  const history = useHistory();

  const params = useParams() as { id: string; };
  const nis = params.id;


  const [data, setData] = useState({} as VisitationFamily);


  useEffect(() => {
    socialApi.get(`families?nis=${nis}`).then(res => setData(res.data.content[0]));
  }, [nis]);

  function handleNavigateToEditVisitDate() {
    const params = {
      family: data,
      urlToGoBack: url,
    }

    history.push(`${url}/editar-visita?prev_date=${data?.lastVisit || new Date().toISOString()}`, params);
  }

  function handleNavigateToJotformAnswersByNis(nis: string) {
    history.push(`/pesquisa-do-articulador/${nis}`);
  }

  function handleNavigateToForwardingOfDemandsFamily(id : number) {
    history.push(`/articulador-encaminhamento-de-demandas/${id}`);
  }

  return (
    <>
      <Container>
        <header>
          <GoBack 
            onClick={()=>history.push('/articulador-plano-de-atividades')}
            subtitle={data?.lastVisit ? `Última visita em ${moment(data?.lastVisit).format('DD/MM/YYYY')}` : 'NÃO HOUVE ÚLTIMA VISITA'}
            title={data?.nameRF}
          />

          <div className="calendar-status-content">
            <button 
              className="calendar-link" 
              onClick={handleNavigateToEditVisitDate}
              title={`Criar nova visita`}
            >
              <img src={calendarImg} alt="calendário"/>
            </button>
          </div>
        </header>
        <main>
          <div className="title">
            <h1>Informações gerais da família</h1>
          </div>
          <div className="info-box">
            <div className="content">
              <Col style={{
                marginLeft:12,
              }}>
                <Col style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}>
                  <h4>Representante familiar</h4>
                  <span>{data?.nameRF}</span>
                </Col>
                <Col style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 30,
                  gap: 10,
                }}>
                  <h4>Data de nascimento</h4>
                  <span>{data?.birth}</span>
                </Col>
              </Col>

              <Col style={{
                marginLeft:12,
              }}>
                <Col style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 30,
                  gap: 10
                  
                }}>
                  <h4>Bairro</h4>
                  <span>{data?.neighborhood}</span>
                </Col>
              </Col>
            </div>
            {/* {!data.jotform && (
              <Warning>Não há entrevistas vinculadas a esta família!</Warning>
            )} */}

            <div className="button-container">
              <button 
                type="button"
                onClick={() => handleNavigateToJotformAnswersByNis(data?.nis)}  
                // disabled={data.jotform===null}  
                className="jotform-navigation-button"
              >
                Formulário completo
              </button>
              <button 
                type="button"
                onClick= {() => handleNavigateToForwardingOfDemandsFamily(data?.id)}
              >
                  Encaminhamento de Demanda
              </button>
            </div>
          </div>
        </main>

        <footer>
          <div className="footer-content">
            <h1>Observações gerais</h1>
            <TextArea 
              placeholder={"Não há observações"}
              value={data?.generalObservation}
              disabled
            />
            </div>
        </footer>

      </Container>
      <Footer />
    </>
  );
}
