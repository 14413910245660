import React from 'react'
import { useHistory } from 'react-router-dom'

import Modal from 'react-modal';
import { AgentType } from 'types';

import { Device } from 'components'

import './styles.scss'

import rightArrow from '../../assets/green-right-arrow.svg'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '440px'
  },
};

const mobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '390px'
  },
}

interface ModalAgentSearchProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  agents?: AgentType[]
}

export default function ModalFamilySearch({ isOpen, closeModal, agents }: ModalAgentSearchProps) {

  const history = useHistory()
  const device = Device.useDevice()

  function handleNavigateAgent(agent: AgentType) {
    //TODO Alterar essa lógica para obter o agentId
    localStorage.removeItem("agentId");
    localStorage.setItem("agentId", String(agent.id));
    history.push(`/coordenador-articulador/${agent.id}`, agent);
  }

  if (agents === undefined) {
    agents = []
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={ device.isSM() ? modalStyles : mobileModalStyles }
    >
      <div className="modal">

        {
          agents?.length > 0 ?
            (<>
              <h2>Agentes Encontrados(as)</h2>

              <div className={ device.isSM() ? 'agents-container' : 'mobile-agents-container'}>

                <div className="agent-units-container">
                  {agents?.map((agent, j: number) => (
                    <div className="agent-box" onClick={() => handleNavigateAgent(agent)}>
                      <div className="agent-box-left">
                        <span>{agent.name}</span>
                      </div>

                      <div className="agent-box-right">
                        <img src={rightArrow} alt="" />
                      </div>

                    </div>
                  ))}
                </div>

              </div>
            </>)
            :
            (<>
              <h2>Agente não encontrado(a)</h2>
            </>)
        }

      </div>
    </Modal>
  )
}