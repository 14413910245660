import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { makeOptitionsNamesCurrentPOLOS } from 'utils/makeOptionsPolos';
import { convertArraysFromString, formatObject, genereteRows, genereteWidthColumn } from '../utils/formatter';
import { headPadinVisitaMunicipio, headPadinFormacao, headPadinEncontrosComunitarios } from '../utils/headers';


function testFilter(arrayEvent){
  const newArrayFormacao = arrayEvent.filter(action => {
    return action.tipo === 'Formação'
  });
  const newArrayVisita = arrayEvent.filter(action => {
    return action.tipo === 'Visita Municipio'
  });
  const newArrayEncontros = arrayEvent.filter(action => {
    return action.tipo === 'Encontros Comunitários'
  });

  return [newArrayFormacao, newArrayVisita, newArrayEncontros];
}

export function padinPDF(eventData: any) {
  const [eventFormacao, eventVisita, eventEncontros] = testFilter(eventData);
    
  const docFormacao: any = new jsPDF();
  const docVisita: any = new jsPDF();
  const docEncontros: any = new jsPDF()

  const tableVisita: any = [];
  const tableEncontros: any = [];

  const rowsVisitas = genereteRows(headPadinVisitaMunicipio);
  const rowsEncontros = genereteRows(headPadinEncontrosComunitarios);


  eventVisita.forEach(event => {
    let objectLine: any = [];
    let novoObj: any = [];
    formatObject(event, novoObj);

    Object.keys(rowsVisitas).forEach(coluna => {
      let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
      objectLine.push(objeto);
    });

    tableVisita.push(objectLine);
  });

  docVisita.addPage('a4', 'landscape');

  docVisita.setFontSize(20);

  docVisita.setFontSize(15);
  docVisita.text(2, 12, `Padin - Visita Municipio`);

  docVisita.autoTable({
    startX: 0,
    rowPageBreak: 'avoid',
    columnStyles: genereteWidthColumn(rowsVisitas),
    head: headPadinVisitaMunicipio,
    startY: 25,
    margin: {
      top: 10,
      bottom: 40,
      left: 2,
      right: 2,
    },
    headerStyles: {
      fontSize: 8,
      fontFamily: 'Roboto',
      fillColor: '#80c470',
      halign: 'center',
    },
    styles: { fontSize: 8, columnWidth: 'wrap' },
    horizontalPageBreak: false,
    body: convertArraysFromString(tableVisita),
    theme: 'striped',
  });

  
  
  eventEncontros.forEach(event => {
    let objectLine: any = [];
    let novoObj: any = [];
    formatObject(event, novoObj);

    Object.keys(rowsEncontros).forEach(coluna => {
      let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
      objectLine.push(objeto);
    });

    tableEncontros.push(objectLine);
  });

  docEncontros.addPage('a4', 'landscape');
  docEncontros.setFontSize(15);
  docEncontros.text(2, 12, `Padin - Encontros Comunitários`);

  docEncontros.setFontSize(20);

  docEncontros.autoTable({
    startX: 0,
    rowPageBreak: 'avoid',
    columnStyles: genereteWidthColumn(rowsEncontros),
    head: headPadinEncontrosComunitarios,
    startY: 25,
    margin: {
      top: 10,
      bottom: 40,
      left: 2,
      right: 2,
    },
    headerStyles: {
      fontSize: 8,
      fontFamily: 'Roboto',
      fillColor: '#80c470',
      halign: 'center',
    },
    styles: { fontSize: 8, columnWidth: 'wrap' },
    horizontalPageBreak: false,
    body: convertArraysFromString(tableEncontros),
    theme: 'striped',
  });

 

  
  
   eventFormacao.forEach((event, index) => {

    const tableFormacao: any = [];
    let dataInicial: any;
    let dataFim: any;

    const rowsFormacao = genereteRows(headPadinFormacao);
      let novoObj: any = [...event.formacao.ciclosPadin];

      novoObj.forEach((ciclo, index)=> {
        let objectLine: any = [];
        if(index === 0) {
          dataInicial = new Date(ciclo['periodo'].dataInicio).toLocaleDateString('pt-BR')
        }
        if(index === novoObj.length-1) {
          dataFim = new Date(ciclo['periodo'].dataFim).toLocaleDateString('pt-BR')
        }
        Object.keys(rowsFormacao).forEach(coluna => {
      
          if(coluna==="municipios"){
           objectLine.push({title: ciclo["polos"].join(', '), dataKey: coluna});

          }else if(coluna==="polos"){
         
            const polos = makeOptitionsNamesCurrentPOLOS(ciclo["polos"]);
            objectLine.push({title: polos.join(', '), dataKey: coluna});
          }else if(coluna==="dataInicio"){
            objectLine.push({title: new Date(ciclo["periodo"].dataInicio).toLocaleDateString('pt-BR'), dataKey: coluna});
          }else if(coluna==="dataFim"){
            objectLine.push({title: new Date(ciclo["periodo"].dataFim).toLocaleDateString('pt-BR'), dataKey: coluna});
          }else {
            objectLine.push({title: ciclo[coluna], dataKey: coluna});
          }
        });
        tableFormacao.push(objectLine);
      })



      docFormacao.addPage('a4', 'landscape');
  
      docFormacao.setFontSize(15);
      docFormacao.text(2, 12, `Formação (${index+1}) de ${dataInicial} até ${dataFim} `);
      docFormacao.autoTable({
      startX: 0,
      rowPageBreak: 'avoid',
      columnStyles: genereteWidthColumn(rowsFormacao),
      head: headPadinFormacao,
      startY: 25,
      margin: {
        top: 10,
        bottom: 40,
        left: 2,
        right: 2,
      },
      headerStyles: {
        fontSize: 9,
        fontFamily: 'Roboto',
        fillColor: '#80c470',
        halign: 'center',
      },
      styles: { fontSize: 9, columnWidth: 'wrap' },
      horizontalPageBreak: false,
      body: convertArraysFromString(tableFormacao),
      theme: 'grid',
    });

   })

 if(eventFormacao.length > 0){
  docFormacao.deletePage(1); 
  docFormacao.save(`padin-formacao.pdf`);
 }
 if(eventVisita.length > 0) {

 

  docVisita.deletePage(1); 
  docVisita.save(`padin-visita-municipio.pdf`);
 }

 if(eventEncontros.length > 0) {
  
  

  docEncontros.deletePage(1); 
  docEncontros.save(`padin-encontros-comunitários.pdf`);
 }



}