import React from 'react';
import { StyledIdentifier, StyledTitle, StyledStep, StyledSeparator, StyledSteps } from './styles';
import { Device } from 'components';

interface StepsProps {
  steps: string[];
  current: number;
}

export function Steps({ steps, current }: StepsProps) {
  const device = Device.useDevice();

  function renderStep() {
    return steps.map((step, ind, arr) => {
      const size = arr.length;

      return (
        <div key={step} className="steps-box">
          <Step
            title={step}
            identifier={ind + 1}
            finished={current > ind}
            current={ind === current}
          />
          { ind < size - 1 && <StyledSeparator finished={current > ind} />}
        </div>
      );
    });
  }

  function renderStepMobile() {
    const stepIndex = steps.findIndex((stp, i) => i === current);
    const index = stepIndex > -1 ? stepIndex : 0;
    const step = steps[index];

    return (
      <div className={`steps-box steps-box-${device.prefix()}`}>
        <Step
          title={step}
          identifier={index+1}
          finished={current > stepIndex}
          current={index === current}
        />
      </div>
    )
  }

  return (
    <StyledSteps>
      {device.isSM('exclusive') ? renderStepMobile() : renderStep()}
    </StyledSteps>
  );
}

interface StepProps {
  title: string;
  identifier: number;
  finished: boolean;
  current: boolean;
}

function Step({ title, identifier, finished, current }: StepProps) {
  return (
    <StyledStep>
      <StyledIdentifier finished={finished} current={current}>
        {identifier}
      </StyledIdentifier>

      <StyledTitle finished={finished} current={current}>
        {title}
      </StyledTitle>
    </StyledStep>
  );
}