import React from 'react';

import './styles.scss';

interface FloatingBlocksProps {
  type: string;
  qtdFamilies: number;
  nameCity: string;
}
const FloatingBlocks = ({
  type,
  qtdFamilies,
  nameCity,
}: FloatingBlocksProps): JSX.Element => {
  return (
    <div className={`fa-map-marker-icon-${type}`}>
      <div className={`line-${type}`}></div>
      <section className={`fa-map-infos-${type}`}>
        <div className={`fa-circle-qtdFamilias-${type}`}>{qtdFamilies}</div>
        <span>{nameCity}</span>
      </section>
    </div>
  );
};
export default FloatingBlocks;
