import React, { useState } from 'react';

import {
  UploadOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import {
  deleteFileAcao,
  downloadFileAcao,
} from 'services/resources/acoesServices';

import '../styles.scss';
import { Popconfirm } from 'antd';

interface Props {
  name: '';
  extensao: '';
  tamanho: '';
}
interface PropsFiles {
  name: '';
  lastModified: number;
  size: number;
}
interface FormCreateFilesProps {
  idAcao;
  idEvento;
  arquivosRegistro: any;
  selectedFile: Props[];
  onSelectedFile: (value: any) => void;
  setArquivosRegistro: (value: any) => void;
  editMode;
}

export function FormCreateFiles({
  idAcao,
  idEvento,
  arquivosRegistro,
  selectedFile,
  onSelectedFile,
  setArquivosRegistro,
  editMode,
}: FormCreateFilesProps) {
  const [namesFiles, setNamesFiles] = useState([] as any);
 
  const[makeDownload, setMakeDownload] = useState(false);

  const onFileChange = event => {
    const modifiedFiles: PropsFiles[] = Array.from(event.target.files);
    const nameFile = modifiedFiles.map(file => {
      return {
        id: file.lastModified,
        name: file.name,
      };
    });
    const newFile = event.target.files;

    setNamesFiles(prev => [...prev, ...nameFile]);
    onSelectedFile(prev => [...prev, ...newFile]);
  };

  const onRemoveFile = nameFile => {

    const newNameFiles = namesFiles.filter(file => file.name !== nameFile);
    const newSelectFiles = [...selectedFile].filter(
      file => file.name !== nameFile,
    );

    onSelectedFile(newSelectFiles);
    setNamesFiles(newNameFiles);
  };


  
  function onDownload(file) {
   
    setMakeDownload(true)
    downloadFileAcao(idAcao, idEvento, file, setMakeDownload);
  
  }
  function onRemove(file) {
    deleteFileAcao(
      idAcao,
      idEvento,
      file,
      setArquivosRegistro,
      arquivosRegistro,
    );
  }

  return (
    <section className="container__formArquivos">
      <h3 className="ant-typography">Arquivos</h3>

      <div className="formArquivos-files">
        <label
          htmlFor="selecao-arquivo"
          className={
            namesFiles.length === 0 ? 'label-files animation' : 'label-files'
          }
        >
          <UploadOutlined />
          <p>Selecionar arquivos </p>
        </label>
        <input
          id="selecao-arquivo"
          type="file"
          className="input-files"
          onChange={onFileChange}
          multiple
        ></input>

        <ul className="files-fileNames">
          {editMode &&
            namesFiles.length > 0 &&
            arquivosRegistro?.length > 0 && <strong>Novos arquivos</strong>}
          {namesFiles?.map(file => {
     
            return (
              <>
                <li className="content__namesFiles">
                  <div>
                    <PaperClipOutlined /> <h3>{file.name}</h3>
                    <button
                      className="remover-file"
                      onClick={() => onRemoveFile(file.name)}
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                </li>
              </>
            );
          })}
          {editMode && arquivosRegistro?.length > 0 && (
            <strong className="exists">Arquivos já existentes</strong>
          )}
          {arquivosRegistro?.map(file => {
            return (
              <li className="content__namesFiles">
              <div>
                <PaperClipOutlined /> <h3>{file.nome}</h3>
                  <Popconfirm
                    title="Deseja apagar esse arquivo?"
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={() => onRemove(file)}
                  >
                    <button className="remover-file">
                      <DeleteOutlined />
                    </button>
                  </Popconfirm>
                  <button
                    className="download-file"
                    onClick={() => onDownload(file)}
                  >
                    <DownloadOutlined />
                  </button>
              </div>
               
              </li>
            );
          })}
            {
                makeDownload && (
                  <div className="download-progress">
                    <div>
                      <div className="line"></div>  
                    </div>
                    <strong>Fazendo download</strong>
                  </div>
                )
              }
        </ul>
      </div>
    </section>
  );
}
