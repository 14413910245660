import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from 'antd';

import { MdAddBox, MdEdit } from 'react-icons/md';

import './styles.scss';

import AcaoCircle from '../../assets/acoes-circle-icon.svg';
import ObraCircle from '../../assets/obras-circle-icon.svg';

interface IIniciativa {
  tipo: 'Obra' | 'Ação';
  descricao: string;
  id: string;
  nome: string;
  subcategoria: string;
  tempoDe: string;
  eventos: any;
  totalEventos: number;
  categoria: string;
}

type CardIniciativaServidorProps = {
  iniciativa: IIniciativa;
  categoria: string;
};

const CardIniciativaServidor = ({
  iniciativa,
  categoria
}: CardIniciativaServidorProps): JSX.Element => {
  const [tapCurrent, setTapCurrent] = useState('infos');

  const toggleTapCurrent = (value: string) => {
    setTapCurrent(value);
  };

  // const { updateCurrentIniciativa } = useIniciativa();

  return (
    <div className="card-iniciativa-servidor">
      <div className="card-title">
        <div className="iniciativa-icon">
          <Avatar
            size={40}
            src={iniciativa.tipo === 'Ação' ? AcaoCircle : ObraCircle }
          />
        </div>

        <strong>{iniciativa.nome}</strong> 

        {
          iniciativa.tipo === 'Ação' &&
          <Link
            to={
              iniciativa.tipo === 'Ação'
                ? `/registrar-acao/${iniciativa.id}`
                : `/registrar-obra/${iniciativa.id}`
            }
          >
            <Tooltip placement="top" title="Registrar nova edição">
              <MdAddBox size="30" color="#80C470" />
            </Tooltip>
          </Link>
        }
      </div>

      <div className="card-body">
        <div className="body-tabs">
          <button
            onClick={() => {
              toggleTapCurrent('infos');
            }}
          >
            Informações
          </button>
          <button
            onClick={() => {
              toggleTapCurrent('story');
            }}
          >
            Histórico
          </button>
          <div
            className="line-tab"
            style={tapCurrent === 'infos' ? { left: 0 } : { left: '50%' }}
          ></div>
        </div>

        {tapCurrent === 'infos' ? (
          <div className="body-content">
            {
              iniciativa.tipo === 'Ação' ? (
                <>
                  <strong>Subcategoria</strong>
                  <p>{iniciativa.subcategoria}</p>
                </>
              ) : (
                <>
                  <strong>Categoria</strong>
                  <p>{iniciativa.subcategoria}</p>
                </>
              )
            }
            <strong>Descrição</strong>
            <p>{iniciativa.descricao}</p>
          </div>
        ) : (
          <div className="body-content">
            {iniciativa?.eventos.length ? (
              <>
                <ul>
                  <li>
                    {
                      iniciativa.tipo === 'Ação' ? (
                        <span>
                          {
                            iniciativa?.eventos[0]?.display || 
                            <Tooltip title="Data da ultima modificação">
                              {new Date(iniciativa?.eventos[0]?.ultimaModificacao).toLocaleDateString('pt-br')}
                            </Tooltip>
                          }
                        </span>
                      ) : (
                        <span>{iniciativa?.eventos[0]?.nome}</span>
                      )
                    }
                    {
                      ((iniciativa.tipo === 'Ação' && iniciativa.nome !== 'Padin') || (iniciativa.tipo === 'Ação' && iniciativa?.eventos[0]?.padin?.tipo === 'Formação')) &&
                      <Tooltip placement="left" title="Editar">
                        <Link
    
                          to={
                            `/editar-acao/${iniciativa.id}/${iniciativa?.eventos[0]?.id}`
                          }
                        >
                          <MdEdit size="25" />
                        </Link>
                      </Tooltip>
                    }
                  </li>
                  {iniciativa.eventos.length > 1 && (
                    <li>
                      {
                        iniciativa.tipo === 'Ação' ? (
                          <span>
                            {
                              iniciativa?.eventos[1]?.display || 
                              <Tooltip title="Data da ultima modificação">
                                {new Date(iniciativa?.eventos[1]?.ultimaModificacao).toLocaleDateString('pt-br')}
                              </Tooltip>
                            }
                          </span>
                        ) : (
                          <span>{iniciativa?.eventos[1]?.nome}</span>
                        )
                      }
                      {
                        ((iniciativa.tipo === 'Ação' && iniciativa.nome !== 'Padin') || (iniciativa.tipo === 'Ação' && iniciativa?.eventos[1]?.padin?.tipo === 'Formação')) &&
                        <Tooltip placement="left" title="Editar">
                          <Link
                            to={
                              `/editar-acao/${iniciativa.id}/${iniciativa?.eventos[1]?.id}`
                            }
                          >
                            <MdEdit size="25" />
                          </Link>
                        </Tooltip>
                      }
                    </li>
                  )}
                </ul>
                <div id="access-story">
                  <Link
                    to={
                      iniciativa.tipo === 'Ação'
                        ? `/historico-acao/${iniciativa.id}`
                        : `/historico-obra/${iniciativa.id}`
                    }
                  >
                    ACESSAR HISTÓRICO COMPLETO
                  </Link>
                </div>
                <div id="total-info">
                  <strong>Total: {iniciativa?.totalEventos}</strong>
                </div>
              </>
            ) : (
              <span className="center">Não há registros ainda</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardIniciativaServidor;
