import { apiBigData } from "services/api";
import { Gestor } from "types";



const service = () => {
    const resource = `manager`

    async function postGestor(data: Gestor) {
        try {
            const response = await apiBigData.post(`${resource}`, data)
      
            return response.data;
          } catch (err) {
            return Promise.reject(err);
          }
    }

    async function getGestorByCpf(cpf: string) {
      try {
        const response = await apiBigData.get(`${resource}?cpf=${cpf}`);
        return response.data;
      } catch (err) {
        return Promise.reject(err);
      }
    }

    return {
        postGestor,
        getGestorByCpf
    }
}

export const gestorService = service();