import labelKeys from '../data/labelKeys.json'

export const splitByIndex = (value: string, sliceSize: number): string[] => {
  const size = Math.ceil(value.length / sliceSize);
  const sliced: string[] = [];

  for (let i = 0; i < size; i += 1) {
    const start = i * sliceSize;

    sliced.push(value.slice(start, start + sliceSize));
  }

  return sliced;
};

export function formattedStringKey(key: string) {

 return labelKeys[key]
}
