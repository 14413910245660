import { Breadcrumb, Form } from 'antd'
import { Authorizer, Feedback } from 'components'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { gestorService } from 'services/resources/gestorService'
import { Gestor } from 'types'
import { FormGestor } from './FormGestor'
import { Buttons } from './FormGestor/Buttons/Buttons'
import { gestorToForm } from './helpers'
import { StepPanel } from './StepPanel'
import './styles.scss'

export function GestorForm() {
    const [formGestor] = Form.useForm();
    const [step, setStep] = useState(0);
    const [managerInfo, setManagerInfo] = useState<Gestor | null>(null);
    const [finished, setFinished] = useState(false);
    const [err, setErr] = useState<string>('');
    const history = useHistory();

    function handleCancel() {
        const redirect = 'iris-social';
        history.push(redirect);
    }

    function handleBack() {
        setStep(oldState => (oldState === 0 ? oldState : oldState - 1));
    }


    function onCheck(data: any) {
        const city = data.city.split(";")
        const finalData: Gestor = {
            agency: data.agency,
            birthDate: data.birthDate,
            city: city[0],
            competence: data.competence,
            cpf: data.cpf,
            email: data.email,
            name: data.name,
            matrialStatus: data.matrialStatus,
            occupation: data.occupation,
            phone: data.phone,
            sector: data.sector,
            ibge: city[1]
        }
        gestorService
        .postGestor(finalData)
        .catch(err => setErr(err.message))
        .finally(() => {
          setFinished(true);
        });
    }

    function validateStepOne() {
        const validation = formGestor.validateFields();
    
        Promise
          .all([validation])
          .then(() => setStep(cur => cur === 1 ? cur : cur+1));
    }

    function handleNext() {
        if (step === 0) {
          validateStepOne();
        } 
    }

    useEffect(() => {
        if(managerInfo) {
            formGestor.setFieldsValue(managerInfo)
        }
    }, [managerInfo, formGestor])

    function defineGestor(cpf: string) {
        gestorService.getGestorByCpf(cpf)
          .then(data => gestorToForm(data))
          .then(data => {
            const d: Gestor = {
                agency: data.professional.orgao,
                birthDate: data.personal.birthDate,
                city: data.professional.municipio ? `${data.professional.municipio};${data.professional.ibge}` : undefined,
                competence: data.professional.competencia,
                cpf: data.personal.cpf,
                ibge: data.professional.ibge,
                email: data.personal.email,
                matrialStatus: data.personal.matrialStatus,
                name: data.personal.name,
                occupation: data.professional.profissao,
                phone: data.personal.phone,
                sector: data.professional.setor
            }
            setManagerInfo(d)
          })
    }

    function handleFinish() {
      setFinished(false);
      history.replace('iris-social');
    }
    
    
    const { client } = Authorizer.useAuth().user;
    const [invalidCpf, setInvalidCpf] = useState(false);
    function isCpfEqualsToUser(cpf: string) {
        const size = cpf.length;
    
        if (size === 11 && cpf === client.userId) setInvalidCpf(true);
    
        if (size === 11 && !invalidCpf) setInvalidCpf(false);
    }

    function searchUserByCpf(cpf: string) {
        const size = cpf.length;


        if (size === 11) {
            defineGestor(cpf);
        }
    }

    const onChangeCpf = (cpf: string) => {
        isCpfEqualsToUser(cpf)
        searchUserByCpf(cpf)
    }


    return(
        <div className='gestor'>
            <div className='content'>
                <div className="breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                        <Link to="/gestor">Página Inicial</Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>
                        <Link to="/gestor-cadastro">Cadastro de gestor</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <h2 className="title">
                        Cadastro de Gestor
                    </h2>
                </div>
                <div className="form-body">
                    <StepPanel
                        step={step}
                    />
                    <FormGestor onChangeCpf={onChangeCpf} step={step} form={formGestor} finish={onCheck}>
                        <Buttons   
                            onNext={handleNext}
                            step={step}
                            onCancel={handleCancel}
                            onBack={handleBack} />
                    </FormGestor>
                </div>
                <Feedback visible={finished} error={err} onOk={handleFinish} />
            </div>
        </div>
    )
}