import React from 'react';
import './styles.scss';
import { Modal } from 'antd';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { useHistoryAcao } from 'utils/context/historyAcaoContext';
import { useHistoryObra } from 'utils/context/historyObraContext';


type Props = {
  type: 'Obra' | 'Ação'
  id: string
  nameActionCurrent: string
}

const ModalConfirmaExclusaoEvento = ({type, id, nameActionCurrent}:Props) => {
  const {
    toggleModalAcaoConfirmacaoExclusao,
    modalConfirmacaoExclusaoAcaoIsOpen,
    idEventAcaoSelected,
    handleDeleteEventAcao,
  } = useHistoryAcao();

  const {
    toggleModalObraConfirmacaoExclusao,
    modalConfirmacaoExclusaoObraIsOpen,
    idEventObraSelected,
    handleDeleteEventObra,
  } = useHistoryObra();

  return (
    <Modal
      centered
      visible={
        type === 'Obra'
          ? modalConfirmacaoExclusaoObraIsOpen
          : modalConfirmacaoExclusaoAcaoIsOpen
      }
      onCancel={
        type === 'Obra'
          ? toggleModalObraConfirmacaoExclusao
          : toggleModalAcaoConfirmacaoExclusao
      }
      onOk={
        type === 'Obra'
          ? toggleModalObraConfirmacaoExclusao
          : toggleModalAcaoConfirmacaoExclusao
      }
      width={363}
      cancelText={false}
      okText={false}
      closable={false}
      zIndex={1000}
    >
      <div className="modal">
        <div className="icon-image">
          <IoAlertCircleOutline />
        </div>
        <h1>Atenção!</h1>
        <p>Tem certeza que deseja excluir esse registro de evento?</p>
        <div className="buttons">
          <button
            className="button"
            onClick={() => {
              type === 'Ação'
                ? handleDeleteEventAcao(
                    id,
                    idEventAcaoSelected,
                    nameActionCurrent
                  )
                : handleDeleteEventObra(
                    id,
                    idEventObraSelected,
                  );
            }}
          >
            {' '}
            SIM
          </button>
          <button
            className="button"
            onClick={() =>
              type === 'Ação'
                ? toggleModalAcaoConfirmacaoExclusao()
                : toggleModalObraConfirmacaoExclusao()
            }
          >
            NÃO
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmaExclusaoEvento;
