import React, { useContext } from 'react';
import { Select } from 'antd';

import { MenuContext } from 'utils/context/menuContext';
import { Breadcrumb, PieChart, ProgressBar } from '../../components';

import MiniSun from '../../assets/miniSun.svg';
import Ranking from '../../assets/ranking.svg';
import Home from '../../assets/pieChart-icons/home-icon.svg';

import './styles.scss';

const { Option } = Select;

const FamiliesDetails = () => {
  const { titleState } = useContext(MenuContext);

  const links = [
    {
      path: '/home-dashboard',
      title: 'Página Inicial',
    },
    {
      path: '/pesquisa-cmic',
      title: 'Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará',
    },
    {
      path: '/pesquisa-cmic-detalhamento',
      title: 'Detalhamento',
    },
  ];

  return (
    <div className="details-page-container">
      <Breadcrumb links={links} />
      <div className="title">
        <h1>
          <img src={Home} alt={Home} />
          {titleState}
        </h1>
      </div>
      <div className="details-container-grid">
        <aside className="infographic-container">
          <div className="infographic">
            <Select defaultValue="Fortaleza" style={{ width: 180 }}>
              <Option value="Fortaleza" key="Fortaleza">
                Fortaleza
              </Option>
            </Select>
            <div className="overview-grid">
              <div className="indicador-element sun">
                <img src={MiniSun} alt="controle de roleta interativa" />
                <h3>40</h3>
                <p>Indicador de Moradia Adequada</p>
              </div>
              <div className="indicador-element">
                <img src={Ranking} alt="ranking de municipios" />
                <p>Ranking dos municípios</p>
              </div>
              <div className="indicador-element">
                <h3>70</h3>
                <p>Indicador de Moradia Adequada</p>
              </div>
              <div className="indicador-element">
                <h3>250</h3>
                <p>Entrevistados do Mais Infância</p>
              </div>
              <div className="pie-chart-container">
                <PieChart />
              </div>
            </div>
          </div>
        </aside>
        <aside className="overview-container">
          <h1 className="title">Resultados da Pesquisa CMIC</h1>
          <div className="container">
            <h2>
              Os materiais predominantes na construção das paredes externas do
              domicílio são:
            </h2>
            <div className="barOverview">
              <ProgressBar progress={70} bgColor="#FB6B32" />
              <p>Alvenaria sem revestimento</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={20} bgColor="#D44D20" />
              <p>Palha</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={10} bgColor="#AD3311" />
              <p>Outros materiais</p>
            </div>
          </div>

          <div className="container">
            <h2>A água utilizada para beber nesse domicílio é:</h2>
            <div className="barOverview">
              <ProgressBar progress={70} bgColor="#FB6B32" />
              <p>Tratada</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={20} bgColor="#D44D20" />
              <p>Mineral</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={10} bgColor="#AD3311" />
              <p>Fervida</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={10} bgColor="#871E07" />
              <p>Tratada de outra forma</p>
            </div>
          </div>

          <div className="container">
            <h2>Se o domicílio tem água canalizada em pelo menos um cômodo:</h2>
            <div className="barOverview">
              <ProgressBar progress={70} bgColor="#FB6B32" />
              <p>Sim</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={20} bgColor="#D44D20" />
              <p>Não</p>
            </div>
          </div>
          {/* //delete */}
          <div className="container">
            <h2>
              Os materiais predominantes na construção das paredes externas do
              domicílio são:
            </h2>
            <div className="barOverview">
              <ProgressBar progress={70} bgColor="#FB6B32" />
              <p>Alvenaria sem revestimento</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={20} bgColor="#D44D20" />
              <p>Palha</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={20} bgColor="#AD3311" />
              <p>Outros materiais</p>
            </div>
          </div>

          <div className="container">
            <h2>Se o domicílio tem água canalizada em pelo menos um cômodo:</h2>
            <div className="barOverview">
              <ProgressBar progress={70} bgColor="#FB6B32" />
              <p>Sim</p>
            </div>
            <div className="barOverview">
              <ProgressBar progress={20} bgColor="#D44D20" />
              <p>Não</p>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default FamiliesDetails;
