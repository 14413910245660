import React, { useEffect, useState } from 'react';

import { Breadcrumb, Footer } from '../../components';
import { TitlePage } from 'components/TitlePage';
import MiddleComponent from './MiddleComponent';

import { getAtendimentosAcoes, getAtendimentosObras } from '../../services/resources/acoesServices';

import './styles.scss';
import TopComponent from './TopComponent';
import BottomComponent from './BottomComponent';
import ListDadosTitle from './ListDadosTitle';
import ListDados from './ListDados';
import pipa from '../../assets/pipa.svg';
import { Spin } from 'antd';
import { Device } from 'components/Device';
import InfoProvide from 'components/InfoProvide';
import { getDinheiroInvestido, getFamiliasBeneficiadas } from 'services/resources/cmicService';
import { FormatNumberWithSufix } from 'utils/FormatNumberWithSufix';

const links = [
  {
    path: '/programa-mais-infancia',
    title: 'Página Inicial',
  },
  {
    path: '/dados-gerais',
    title: 'Equipamentos e Ações - Dados Gerais',
  },
];


interface AtendimentosAcoesProps {
  id: string;
  nome: string;
  cestasBasicasPandemia: number;
  qtdPessoasBenefeciadasMaisNutricao: number;
  qtdMudasPlantadas: number;
  qtdPessoasCapacitadas: number;
  qtdQuilosDoados: number;
  qtdToneladasDeAlimentosDoados: number;
  qtdVisitasPadin: number;
  qtdVisitasCriancaFeliz: number;
  qtdVisitasFamiliasPadin: number;
  totalEventos: number;
}

interface AtendimentosObrasProps {
  id: string;
  nome: string;
  emAndamento: number;
  concluidas: number;
  qtdTotalBeneficiados: number;
  investimentoTotal: number;
  totalEventos: number;
}

export default function GereralData() {
  const [atendimentosAcoesDados, setAtendimentosAcoesDados] =
    useState<Array<AtendimentosAcoesProps>>([]);

  const [atendimentosObrasDados, setAtendimentosObrasDados] =
    useState<Array<AtendimentosObrasProps>>([]);

  const [loadAcoes, setLoadAcoes] = useState(true);
  const [loadObras, setLoadObras] = useState(true);

  const [familiasBeneficiadas, setFamiliasBeneficiadas] = useState(0);
  const [dinheiroIvestido, setDinheiroInvestido] = useState(0);

  useEffect(() => {
    const response = async () => {
      setLoadObras(true)
      const data = await getFamiliasBeneficiadas().catch(() => {setFamiliasBeneficiadas(1)});
      const data2 = await getDinheiroInvestido('').catch(() => {setDinheiroInvestido(1)});
      
      if(data) {
        setFamiliasBeneficiadas(data[0].quantidade)
      }

      if(data2){
        setDinheiroInvestido(data2[0].investido)
      }
    }
    
    response();
  }, []);

  const [numEventosAcoes, setNumEventosAcoes] = useState({
    total: {
      id: '',
      nome: '',
      cestasBasicasPandemia: 0,
      qtdPessoasBenefeciadasMaisNutricao: 0,
      qtdMudasPlantadas: 0,
      qtdPessoasCapacitadas: 0,
      qtdQuilosDoados: 0,
      qtdToneladasDeAlimentosDoados: 0,
      qtdVisitasPadin: 0,
      qtdVisitasFamiliasPadin: 0,
      totalEventos: 0,
      
    },
    qtdPessoasCapacitadasAgente: 0,
    qtdVisitasPadin: 0,
    qtdVisitasFamiliasPadinF: 0,
    qtdToneladasDeAlimentosDoadosMN: 0,
    qtdPessoasBenefeciadasMaisNutricaoMN: 0,
    qtdQuilosDoadosMN: 0,
    cestasBasicasPandemiaMN: 0,
    totalFS: 0,
  });

  const [numEventosObras, setNumEventosObras] = useState({
    brinquedopraca: 0,
    brinquedocreche: 0,
    cei: 0,
    pracaMaisInfancia: 0
  });

  useEffect(() => {
    let isMounted = true; 
      getAtendimentosAcoes().then(data => {
        if (isMounted) setAtendimentosAcoesDados(data)
      });
      getAtendimentosObras().then(data => {
        if (isMounted) setAtendimentosObrasDados(data)
      })

      return () => { isMounted = false };
  }, []);

  useEffect(() => {

    const newAtendimentosAcoesDados = JSON.parse(JSON.stringify(atendimentosAcoesDados))

 
    if (newAtendimentosAcoesDados.length>0) {
      let arr = numEventosAcoes.total;
      for (let property in numEventosAcoes.total) {
        arr = newAtendimentosAcoesDados.reduce((acc, total) => {
          acc[`${property}`] += total[`${property}`];
          return acc;
        }, arr);
      }
      const qtd = {
        total: arr,
        qtdPessoasCapacitadasAgente: atendimentosAcoesDados.filter(
          value => value.nome === 'Capacitação dos Agentes Comunitários de Saúde',
        )[0]?.totalEventos ?? 0 ,
        qtdVisitasPadin: atendimentosAcoesDados.filter(
          value => value.nome === 'Padin',
        )[0]?.qtdVisitasPadin ?? 0,
        qtdVisitasFamiliasPadinF: atendimentosAcoesDados.filter(
          value => value.nome === 'Padin',
        )[0]?.qtdVisitasFamiliasPadin?? 0,
        qtdVisitasCriancaFeliz: atendimentosAcoesDados.filter(
          value => value.nome === 'Criança Feliz',
        )[0]?.qtdVisitasCriancaFeliz?? 0,
        
        qtdToneladasDeAlimentosDoadosMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.qtdToneladasDeAlimentosDoados ?? 0,
        qtdQuilosDoadosMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.qtdQuilosDoados ?? 0,
        cestasBasicasPandemiaMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.cestasBasicasPandemia ?? 0,
        qtdPessoasBenefeciadasMaisNutricaoMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.qtdPessoasBenefeciadasMaisNutricao ?? 0,
        totalFS: atendimentosAcoesDados.filter(
          value => value.nome === 'Formação Seduc',
        )[0]?.totalEventos ?? 0,
      };
      setNumEventosAcoes(qtd);

    }
    setLoadAcoes(false);
    
  }, [atendimentosAcoesDados]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (atendimentosObrasDados.length>0) {
      const qtd = {
        brinquedopraca: atendimentosObrasDados.filter(
          value => value.id === 'Brinquedopraça',
        )[0]?.concluidas ?? 0,
        brinquedocreche: atendimentosObrasDados.filter(
          value => value.id === 'Brinquedocreche',
        )[0]?.concluidas ?? 0,
        cei: atendimentosObrasDados.filter(
          value => value.id === 'Centro de Educação Infantil - CEI',
        )[0]?.concluidas ?? 0,
        pracaMaisInfancia: atendimentosObrasDados.filter(
          value => value.id === 'Praça Mais Infancia',
        )[0]?.concluidas ?? 0,
        complexoMaisInfancia: atendimentosObrasDados.filter(
          value => value.id === 'Complexo Mais Infância',
        )[0]?.concluidas ?? 0,
      };
      setLoadObras(false);
      setNumEventosObras(qtd);
    }
  }, [atendimentosObrasDados]); // eslint-disable-line react-hooks/exhaustive-deps

  const valuesRight = [
    {
      number: numEventosAcoes.qtdPessoasBenefeciadasMaisNutricaoMN,
      title: 'Pessoas beneficiadas ',
      subtitle: 'nas instituições pelo Mais Nutrição',
    },
    {
      number: numEventosAcoes.qtdToneladasDeAlimentosDoadosMN,
      title: 'Toneladas de Alimentos',
      subtitle: 'Doados pelo Mais Nutrição',
    },
  ];

  const comp1 = [
    {
      number: numEventosAcoes.qtdQuilosDoadosMN,
      title: 'Quilos de',
      subtitle: 'Alimentos doados',
    },
    {
      number: numEventosAcoes.cestasBasicasPandemiaMN,
      title: 'Cestas básicas ',
      subtitle: 'distribuídas',
    },
  ];

  const comp2 = [
    {
      number: `${familiasBeneficiadas === 1 ? "-" : Number(familiasBeneficiadas).toLocaleString('pt-br')}`,
      title: 'Famílias',
      subtitle: 'Beneficiadas',
    },
    {
      number: `${dinheiroIvestido === 1 ? "-" : FormatNumberWithSufix(Number(dinheiroIvestido)) }`,
      title: 'de Reais',
      subtitle: 'pagos às famílias do Cartão Mais Infância',
    },
  ];

  const device = Device.useDevice();

  return (
    <section className="container-box">
      <header>
        <Breadcrumb links={links}/>
        <TitlePage
          title="Indicadores"
          subtitle="Acompanhe o quantitativo de todas as iniciativas do Programa Mais Infância Ceará desde 2015"
          backButton
        />
      </header>
      <InfoProvide 
        message="Os dados abaixo foram coletados do Big Data Social a partir da base do sistema CMIC" 
        type="cemic"
      />
      <section className="container-box">
        <section className={`container__generalData ${device.prefix()}`} >
          <TopComponent />

          <Spin spinning={loadAcoes} size="large" tip="Carregando">
            <MiddleComponent value={numEventosAcoes} title="Ações" type="acoes">
              <ListDados lista={valuesRight} columns={1} />
              {
                device.isMD('less') && 
                  <ListDadosTitle
                    titleTop="Ações extras"
                    titleBottom="na pandemia"
                    lista={comp1}
                  />
              }
            </MiddleComponent>
          </Spin>           

          <div className="image-pipa">
            <img src={pipa} alt="pipa" />
          </div>
          <Spin spinning={loadObras} size="large" tip="Carregando">
            <MiddleComponent value={numEventosObras} title="Equipamentos" type="obras">
              {
                device.isMD('bigger') && 
                  <ListDadosTitle
                    titleTop="Ações extras"
                    titleBottom="na pandemia"
                    lista={comp1}
                  />
              }
            </MiddleComponent>
          </Spin>     

            

          <Spin spinning={familiasBeneficiadas===0 || dinheiroIvestido===0} size="large" tip="Carregando">

            <BottomComponent>
              <ListDadosTitle
                titleTop="Cartão"
                titleMiddle="Mais Infância"
                titleBottom="Ceará"
                lista={comp2}
                maisDe
              />
            </BottomComponent>
          </Spin>     
            
        </section>
      </section>
      <Footer />
    </section>
  );
}
