import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd'
import { InputCPF, InputPhone } from 'components';
import React from 'react'
import locale from 'antd/es/locale/pt_BR';
import { StyledForm, StyledFormWrapper, StyledTitle, StyledVerticalLine } from 'components/UI/FormBox/styles';

const { Item } = Form;
const { Option } = Select
const { Title } = Typography;

export function PersonalFormEdit() {
    return (
        <>
            <StyledTitle>
                <Title level={3}>Dados Pessoais</Title>
            </StyledTitle>
            <StyledFormWrapper>
                <StyledVerticalLine />
            <StyledForm>
                    <Item label="Família" name="familia">
                        <Input bordered={false} disabled />
                    </Item>
                </StyledForm>
            </StyledFormWrapper>
        </>
    )
}