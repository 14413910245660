import React, { FormEvent, useState } from 'react';
import { Divider, Input, Upload, UploadProps, message, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Content,
  Wrapper,
  CreateWorkPlanBox,
  Main,
  TopWorkPlanBox,
  ItemLabel,
  DiagnosticBox,
  DiagnosticBoxTitle,
  DiagnosticButton,
  ButtonGroup,
  CancelButton,
} from './styles';

import uploadIcon from '../../assets/articulator/upload.svg';
import {
  ArticulatorBreadcrumb,
  currentSemester,
  currentYear,
  GoBack,
} from '../../components/Articulator';
import { workPlanService } from 'services/resources/workPlanService';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { Authorizer, Footer } from 'components';
import { useEffect } from 'react';
import { agentService } from 'services';
import { RichedProfessional } from 'types';
import { systemService } from 'services/resources/systemService';

const { TextArea } = Input;

export function CreateArticulatorWorkPlan() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth?.user?.client?.userId;
  const [agent, setAgent] = useState<RichedProfessional>();

  const [numberOfVisitations, setNumberOfVisitations] = useState(150);
  const [diagnosis, setDiagnosis] = useState('');
  const [file, setFile] = useState<File>();
  const [isSendPlanButtonLoading, setIsPlanButtonLoading] = useState(false);

  const year = currentYear();
  const semester = currentSemester();
  const [status, setStatus] = useState(false);

  const history = useHistory();

  const breadcrumbItems = [
    {
      id: 1,
      to: '/articulador-inicio',
      title: 'Página inicial',
    },
    {
      id: 2,
      to: '/articulador-plano-de-trabalho',
      title: 'Plano de Trabalho',
    },
    {
      id: 3,
      to: '/articulador-cadastrar-plano-de-trabalho',
      title: 'Cadastrar plano de trabalho',
    },
  ];

  const uploadProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent?: number) =>
        `${parseFloat(String(percent?.toFixed(2)))}%`,
    },
    accept: 'application/pdf',
  };

  useEffect(() => {
    systemService
      .getStatus()
      .then(data => setStatus(data.data.status))
      .catch(() => message.error('Erro ao carregar status'));
  }, [status]);

  function handleGoBack() {
    history.push('/articulador-plano-de-trabalho');
  }

  async function handleCreateWorkPlan(e: FormEvent) {
    e.preventDefault();
    setIsPlanButtonLoading(true);

    const workplan = {
      numVisits: numberOfVisitations,
      city: agent?.city,
      diagnosis,
      agentId: agent?.id,
      year,
      semester,
    };

    try {
      const data = new FormData();

      const blobWorkplan = new Blob([JSON.stringify(workplan)], {
        type: 'application/json',
      });

      data.append('workplan', blobWorkplan);
      file && data.append('file', file);

      await workPlanService.createWorkPlan(data);
      await message.success('Plano de trabalho criado com sucesso');

      setDiagnosis('');
      setNumberOfVisitations(150);
      setFile(undefined);
      setIsPlanButtonLoading(false);
    } catch (err: any) {
      message.error(
        err?.response.data.mensagem || 'Erro ao criar novo plano de trabalho',
      );
      setIsPlanButtonLoading(false);
    } finally {
      history.goBack();
    }
  }

  function handleChangeUpload(info: UploadChangeParam<UploadFile<any>>) {
    const key = 'file';

    const { file } = info;

    if (file.status === 'uploading') {
      message.loading({ content: 'Fazendo upload do arquivo', key });
    }

    if (file.status === 'error') {
      message.error({
        content: `falha no carregamento do documento ${info.file.name}.`,
        key,
      });
    }

    if (file.status === undefined || file.status === 'done') {
      const uploadedFile = info.fileList[0].originFileObj;

      setFile(uploadedFile);

      message.success({
        content: `${uploadedFile?.name ?? ''} carregado com sucesso.`,
        key,
      });
    }
  }

  useEffect(() => {
    let isMounted = true;
    agentService
      .getProfessional(userId)
      .then(res => {
        if (isMounted) setAgent(res);
      })
      .catch(err =>
        message.error(
          err.response?.data?.mensagem || 'Cliente logado não é um articulador',
        ),
      );

    return () => {
      isMounted = false;
    };
  }, [userId]);

  return (
    <>
      <Container>
        <Content>
          <ArticulatorBreadcrumb items={breadcrumbItems} />
          <Wrapper>
            <GoBack
              onClick={handleGoBack}
              title="Cadastrar Plano de trabalho"
              subtitle={`${year}.${semester}`}
            />

            <Main>
              <CreateWorkPlanBox>
                <TopWorkPlanBox>
                  <ItemLabel>
                    <label>
                      Número de visitação de famílias previsto para esse
                      semestre
                    </label>
                    <Input
                      type="number"
                      min="150"
                      value={numberOfVisitations}
                      onChange={e =>
                        setNumberOfVisitations(Number(e.target.value))
                      }
                    />
                  </ItemLabel>
                  <ItemLabel>
                    <label>Município</label>
                    <span>{agent?.city ?? 'Não há informações'}</span>
                  </ItemLabel>
                </TopWorkPlanBox>
                <Divider />

                <DiagnosticBox>
                  <DiagnosticBoxTitle>
                    Diagnóstico do município
                  </DiagnosticBoxTitle>

                  <TextArea
                    placeholder="Faça um relato da situação atual do município"
                    value={diagnosis}
                    onChange={e => setDiagnosis(e.target.value)}
                  />

                  <Upload
                    {...uploadProps}
                    beforeUpload={() => false}
                    onChange={handleChangeUpload}
                  >
                    <DiagnosticButton>
                      <img src={uploadIcon} alt="upload document" />
                      Selecionar documento
                    </DiagnosticButton>
                  </Upload>
                </DiagnosticBox>
              </CreateWorkPlanBox>

              <ButtonGroup>
                <CancelButton type="button" onClick={() => history.goBack()}>
                  Cancelar
                </CancelButton>

                <Button
                  onClick={handleCreateWorkPlan}
                  type="primary"
                  className="send-plan-button"
                  loading={isSendPlanButtonLoading}
                  style={{
                    background: '#4B9F37',
                  }}
                  //disabled={status}
                >
                  Enviar plano
                </Button>
              </ButtonGroup>
            </Main>
          </Wrapper>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
