import { Tooltip } from 'antd';

import React, { memo, useContext, useEffect, useMemo, useState } from 'react';

import 'react-vertical-timeline-component/style.min.css';
import { TimelineContext } from 'utils/context/timelineContext';

import './styles.scss';

interface ITickmark {
  value: number;
  currentValue: number;
  ano: number;
  mes?: number;
  isTitleYear: boolean;
  isInitiative: boolean;
  infosFrame: any;
  chave: string;
  onIsYesClick: (value: boolean) => void;
}

const Tickmark = ({
  ano,
  mes,
  isTitleYear,
  value,
  currentValue,
  infosFrame,
  chave,
  isInitiative,
  onIsYesClick,
}: ITickmark): JSX.Element => {
  const {
    setCurrentInfosTimeline,
    setCurrentTick,
    setCurrentChave,
    setCurrentMonth,
  } = useContext(TimelineContext);
  const colorPattern = '#2d4040';
  const colorYearCurrent = '#FB6107';

  const [isActiveMark, setIsActiveMark] = useState(false);

  const extMes = useMemo(
    () => [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    [],
  );
  useEffect(() => {
    if (value === currentValue) {
      setCurrentChave(chave);
      setCurrentInfosTimeline(infosFrame);
      setCurrentTick(currentValue);
      setCurrentMonth(mes ?? 0);
      setIsActiveMark(true);
    } else {
      setIsActiveMark(false);
    }
  }, [
    value,
    currentValue,
    setIsActiveMark,
    setCurrentChave,
    chave,
    setCurrentInfosTimeline,
    infosFrame,
    setCurrentTick,
    setCurrentMonth,
    extMes,
    mes,
  ]);

  function handleClickTick(value) {
    onIsYesClick(true);
    setCurrentTick(value);
  }
  return (
    <div className="tickOnclick" onClick={() => handleClickTick(value)}>
      <Tooltip placement="top" title={extMes[parseInt(String(mes), 10) - 1]}>
        <div
          onClick={() => handleClickTick(value)}
          style={{
            opacity: `${isInitiative ? 0.5 : 1}`,
            height: `${isTitleYear ? 36 : 15}px`,
            border: `2px solid ${
              isActiveMark ? colorYearCurrent : colorPattern
            }`,
          }}
          className="slider__tickmark"
        >
          {isTitleYear && (
            <p
              className={
                isActiveMark ? 'title__ano-active' : 'title__ano-disable'
              }
            >
              {ano}
            </p>
          )}

          {isActiveMark && <div className="slider__tickmark-circle" />}
        </div>
      </Tooltip>
    </div>
  );
};

export default memo(Tickmark);
