import FormBuilder from 'antd-form-builder';
import { AutoComplete } from 'antd';
import moment from 'moment';
import { currencyFormatter } from 'utils/moneyformater';
import { cepMask } from 'utils/cepMask';
const emptyField = 'Não informado!';

moment().locale('pt-br')

const DateView = ({ value }) => {
  return (value ? value.format('DD/MM/YYYY') : emptyField)
} 


const ListView = ({ value }) => (value ? value.join(', ') : emptyField);
const SimpleView = ({ value }) => ((value || value === 0) ?  value : emptyField);
const BooleanView = ({ value }) => (value ? 'Sim' : 'Não');
const MoneyView = ({ value }) => currencyFormatter(value);
const CepView = ({ value }) => (value ? cepMask(value.toString()) : emptyField);
const DateViewBuilder = FormBuilder.defineWidget('date-view', DateView);
const ListViewBuilder = FormBuilder.defineWidget('list-view', ListView);
const SimpleViewBuilder = FormBuilder.defineWidget('simple-view', SimpleView);
const MoneyViewBuilder = FormBuilder.defineWidget('money-view', MoneyView);
const CepViewBuilder = FormBuilder.defineWidget('cep-view', CepView);
const BooleanViewBuilder = FormBuilder.defineWidget(
  'boolean-view',
  BooleanView,
);

const AutoCompleteBuilder = FormBuilder.defineWidget(
  'autocomplete',
  AutoComplete,
  field => {
    return { ...field, ranges: { options: field.options } };
  },
);

export const WidgetsBuilders = {
  DateViewBuilder,
  ListViewBuilder,
  SimpleViewBuilder,
  BooleanViewBuilder,
  AutoCompleteBuilder,
  MoneyViewBuilder,
  CepViewBuilder,
};
