import { citiesNamesCE } from 'utils/citiesNames';

const generalInfos = {
  fields: [
    {
      key: 'municipios',
      label: 'Municípios',
      widget: 'select',
      options: citiesNamesCE,
      widgetProps: { mode: 'multiple', allowClear: true },
      viewWidget: 'list-view',
    },
    {
      key: 'qtdInstrumentosDoados',
      label: 'Quantidade de Instrumentos Doados',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'qtdRegentesEnvolvidos',
      label: 'Quantidade de Regentes envolvidos',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'valorInvestido',
      label: 'Valor investido',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ],
};

export function bandaMusicaEscola() {
  return [
    { title: 'Informações', value: generalInfos },
  ];
}
