import React from 'react';
import { Divider } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { MarkedVisitationPerFamily } from 'types';
import { FamilyListContent } from './styles';

import doneGreenImg from 'assets/articulator/done-green.svg';
import doneRedImg from 'assets/articulator/done-red.svg';
import doneBlueImg from 'assets/articulator/done-blue.svg';

interface FamilyListMobileProps {
  markedVisitations: MarkedVisitationPerFamily[];
  currentSelectedDate: string,
}

export function FamilyVisitationListMobile({ markedVisitations, currentSelectedDate }: FamilyListMobileProps) {
  const history = useHistory();

  function handleNavigateToFamilyDetails(markedVisitation: MarkedVisitationPerFamily) {
    history.push(`/articulador-plano-de-atividades/${markedVisitation.family.nis}`);
  }
  
  return (
    markedVisitations.length > 0 ? (
      <>
        {/* <strong>Listagem de visitas</strong> */}
        <FamilyListContent>
          {markedVisitations.map((data, idx: number) => (
            moment(data.date).format('DD/MM/YYYY')===currentSelectedDate && (
              <div key={data.id}>
                <div
                  className="unique-family-content"
                  onClick={() => handleNavigateToFamilyDetails(data)}
                >
                  <div className="personal-info">
                    <strong>{data.family.nameRF}</strong>
                    <span>{data.family.neighborhood}</span>
                  </div>
                  <div className="personal-info-right">
                    <span>{moment(data.date).format('DD/MM/YYYY') || "Sem visitas"}</span>
                    {(data.visitStatusEnum === 'VISITED') && (
                      <img src={doneGreenImg} alt="indica que visita foi realizada" />
                    )}
                    {data.visitStatusEnum === 'UNVISITED' && (
                      <img src={doneRedImg} alt="indica que visita não foi realizada" />
                    )}
                    {data.visitStatusEnum === 'SCHEDULED' && (
                      <img src={doneBlueImg} alt="indica que visita está pendente" />
                    )}
                  </div>
                </div>
                <Divider />
              </div>     
            )
          ))
        } 
      </FamilyListContent>
    </>
    ): (
      <div className="not-families-container">
        <span>Sem visitas cadastradas para esta data</span>
      </div>
    )
  );
}