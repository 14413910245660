import { socialApi } from 'services/api';
import { FamilyPools, VisitationFamilies, VisitationFamily } from "types";

const service = () => {
  const resource = '/families';

  async function getVisitationFamilies(page=0, size=25, city) {
    try {
      const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
        params: {
          page,
          size,
          city,
          is_active: true
        },
      });
    
      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getFamilyByWorkplanNeighborhoodAndName(workplanId: string, nameRf: string, neighborhood: string) {
    try {
      const response = await socialApi.get<VisitationFamily[]>(`${resource}/${workplanId}/workplan`, {
        params: {
          nameRf,
          neighborhood,
        },
      });

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getFamilyByCityNeighborhoodAndNis(
    page: number | undefined,
    city: string | undefined, 
    neighborhood: string | undefined, 
    nis: string | undefined) {
    try {

      if(!nis?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            neighborhood,
            city,
            is_active: true
          },
        });


        return response.data;
      } else if(!neighborhood?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            nis,
            city,
            is_active: true
          },
        });

        return response.data;
      } else if(!city?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            nis,
            neighborhood,
            is_active: true
          },
          
        });

        return response.data;
      } else if(!neighborhood?.trim() && !nis?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            city,
            is_active: true
          },
          
        });

        return response.data;
      } else if(!city?.trim() && !nis?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            neighborhood,
            is_active: true
          },
          
        });

        return response.data;
      } else if(!neighborhood?.trim() && !city?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            city,
            is_active: true
          },
          
        });

        return response.data;
      } 

      else if(!neighborhood?.trim() && !nis?.trim() && !city?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
          params: {
            page,
            size: 25,
            is_active: true
          },
          
        });

        return response.data;
      }

      const response = await socialApi.get<VisitationFamilies>(`${resource}`, {
        params: {
          page,
          size: 25,
          city,
          neighborhood,
          nis,
          is_active: true
        },
      });

      return response.data;

    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getFamilyByNeighborhoodAndNis(page: number, pageSize=25, neighborhood?: string, nis?: string) {
    try {
      if(!nis?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}?page=${page}&pageSize=${pageSize}`, {
          params: {
            neighborhood
          },
        });

        return response.data;
      } else if(!neighborhood?.trim()) {
        const response = await socialApi.get<VisitationFamilies>(`${resource}?page=${page}&pageSize=${pageSize}`, {
          params: {
            nis
          }, 
        });

        return response.data;
      }

      const response = await socialApi.get<VisitationFamilies>(`${resource}?page=${page}&pageSize=${pageSize}`, {
        params: {
          nis,
          neighborhood
        }
      });

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getFamilyFormByNis(nis: string) {
    try {
      const response = await socialApi.get<FamilyPools>(`${resource}/${nis}/pools`)
      
      return response.data[0]
    } catch (err) {
      return Promise.reject(err)
    }
  }

  return {
    getVisitationFamilies,
    getFamilyByWorkplanNeighborhoodAndName,
    getFamilyByCityNeighborhoodAndNis,
    getFamilyByNeighborhoodAndNis,
    getFamilyFormByNis
  }
}

export const familiesService = service();