export function organizeEventoPublicacoes(data: any) {
  
  const eventFormatted =  data.map(event => {
    return {
      publicacoes: {
        titulo: event.publicacoes.titulo,
        edicao: event.publicacoes.edicao,
        autores:event.publicacoes.autores,
        local:event.publicacoes.local,
        qtdPaginas: event.publicacoes.qtdPaginas,
        ano: event.publicacoes.ano,
        /* campos opcionais */
        subtitulo: event.publicacoes?.subtitulo || '-',
        editora: event.publicacoes?.editora || '-',
        linkMaterialIntegral: event.publicacoes?.linkMaterialIntegral || '-', 
        localizacao:
        {
          municipio: event.publicacoes?.localizacao?.municipio || "-",
          rua: event.publicacoes?.localizacao?.rua || "-" ,
          bairro: event.publicacoes?.localizacao?.bairro || "-",
          cep: event.publicacoes?.localizacao?.cep || "-" ,
          numero: event.publicacoes?.localizacao?.numero || "-",
          pontoDeReferencia: event.publicacoes?.localizacao?.pontoDeReferencia || "-",
          estacao: event.publicacoes?.localizacao?.estacao || "-",
          longitude: event.publicacoes?.localizacao?.longitude || "-",
          latitude: event.publicacoes?.localizacao?.latitude || "-"
        },
        referencias:  event.publicacoes?.referencias || "-"
          } 
        }
      });
  return {
    ...data,
    elementos: eventFormatted
  }
    ;
}

