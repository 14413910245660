import React, { useCallback, useContext, useEffect, useState } from 'react';
import ObrasIconCirle from '../../assets/obras-circle-icon-green.svg';
import AcoesIconCirle from '../../assets/acoes-circle-icon-green.svg';

import { UtilContext } from '../../utils/context/ultilContext';

import {
  BaseMap,
  FilterPopup,
  Select,
  FilterStatus,
  Breadcrumb,
  Footer,
} from '../../components';

import './styles.scss';
import { getObras } from 'services/resources/obrasServices';
import { getAcoes } from 'services/resources/acoesServices';

const links = [
  {
    path: '/home-dashboard',
    title: 'Página Inicial',
  },
  {
    path: '/obras',
    title: 'Mapa de Equipamentos e Ações',
  },
];

interface IObras {
  categoria: string;
  descricao: string;
  eventos: {
    datas: {
      dataFimPrevisto: string;
      dataInicio: string;
      dataOrdemServico: string;
    };
    descricao: string;
    docs: {
      documento: string;
      imagem: string;
    };
    id: string;
    localizacao: {
      enderecoCompleto: string;
      estacao: string;
      latitude: number;
      longitude: number;
      municipio: string;
      pontoDeReferencia: string;
      regiaoPlanejamento: string;
    };
    nome: string;
    observacao: string;
    recursos: {
      mudasFrutiferas: number;
      mudasNativas: number;
      totalInvestido: number;
    };
    status: string;
    ultimaModificacao: string;
  }[];
  id: string;
  mapeamento: {
    datasMapeamento: {
      dataFimPrevisto: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      dataInicio: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      dataOrdemServico: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    docMapeamento: {
      documento: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      imagem: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    localizacaoMapeamento: {
      enderecoCompleto: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      estacao: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      latitudeLongitude: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      municipio: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      pontoDeReferencia: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      regiaoPlanejamento: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    nomeEventoMapeamento: {
      editavel: boolean;
      obrigatorio: boolean;
      usado: boolean;
    };
    observacaoMapeamento: {
      editavel: boolean;
      obrigatorio: boolean;
      usado: boolean;
    };
    recursosMapeamento: {
      mudasFrutiferas: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      mudasNativas: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
      totalInvestido: {
        editavel: boolean;
        obrigatorio: boolean;
        usado: boolean;
      };
    };
    statusMapeamento: {
      editavel: boolean;
      obrigatorio: boolean;
      usado: boolean;
    };
  };
  nome: string;
  subcategoria: string;
  tempoDe: string;
  totalDeEventos: number;
  ultimaModificacao: string;
}

interface IAcao {
  id: string;
  subcategoria: string;
  nome: string;
  descricao: string;
  categoria: string;
  tempoDe: string;
  ultimaModificacao: string;
  eventos: {
    nome: string;
    valor: number;
    endereco: {
      municipio: string;
      rua: string;
      bairro: string;
      cep: string;
      numero: string;
      pontoDeReferencia: string;
      estacao: string;
    };
    datas: {
      dataInicio: string;
      dataFim: string;
    };
    pessoas: {
      criancasAtendidas: {
        porEvento: number;
        porEdicao: {
          edicao: number;
          periodo: {
            dataInicio: string;
            dataFim: string;
          };
          numeroAtendimentos: number;
          numeroCriancasAtendidas: number;
        }[];
      };
      criancasFormadas: number;
      criancasVisitadas: number;
      criancasBeneficiadas: number;
      familiasAcompanhadas: number;
      familiasBeneficiadas: number;
      familiasBeneficiadasMes: number;
      familiasVisitadas: number;
      gestantesVisitadas: number;
      participantes: number;
      pessoasPorFaixaEtaria: {
        porEdicaoFaixaEtaria: {
          edicao: number;
          periodo: {
            dataInicio: string;
            dataFim: string;
          };
          idade: {
            menoresDe6: number;
            entre12e19: number;
            adultos: number;
            idosoMaiorQue60: number;
          };
        }[];
      };
      pessoasBeneficiadas: number;
      pessoasCapacitadas: number;
      pessoasImpactadas: number;
    };
    recursos: {
      dvdsEntregues: number;
      instrumentosDoados: number;
      mudasFrutiferas: number;
      mudasNativas: number;
      toneladasDeAlimentosRecebidos: number;
      toneladasDeAlimentosDoados: number;
      totalInvestido: number;
    };
    atividades: {
      edicao: number;
      programacao: string;
      palestrasRealizadas: number;
      apresentacoesDoCircoEscola: number;
      exibicoesRealizadas: number;
      oficinasRealizadas: number;
    };
  }[];
  mapeamento: {
    nome: {
      usado: false;
      obrigatorio: false;
      editavel: false;
    };
    valor: {
      usado: false;
      obrigatorio: false;
      editavel: false;
    };
    endereco: {
      usado: false;
      obrigatorio: false;
      editavel: false;
    };
    datas: {
      usado: false;
      obrigatorio: false;
      editavel: false;
    };
    atividadesMapeamento: {
      numeroEdicao: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      programacao: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      numeroPalestras: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      apresentacoesCircoEscola: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      numeroExibicoesFilme: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      numeroOficinasRealizadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
    };
    pessoasMapeamento: {
      pessoasBeneficiadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      pessoasImpactadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      pessoasCapacitadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      participantes: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      criancasAtendidas: {
        usado: true;
        obrigatorio: true;
        editavel: true;
      };
      pessoasPorFaixaEtaria: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      familiasBeneficiadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      familiasBeneficiadasMes: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      familiasVisitadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      familiasAcompanhadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      criancasVisitadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      gestantesVisitadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      criancasFormadas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
    };
    recursosMapeamento: {
      mudasFrutiferas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      mudasNativas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      totalInvestido: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      dvdsEntregues: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      instrumentosDoados: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      toneladaslimentosDoados: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      toneladaslimentosRecebidos: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
      mudasfrutíferas: {
        usado: false;
        obrigatorio: false;
        editavel: false;
      };
    };
  };
}

const ObrasPage = (): JSX.Element => {
  const { setShowInput } = useContext(UtilContext);
  const { selectRegion, setSelectRegion } = useContext(UtilContext);
  const [titleFixed, setTitleFixe] = useState(false);

  const handleSelectChange = useCallback(
    event => {
      setSelectRegion(event);
    },
    [setSelectRegion],
  );

  const FixedMenu = () => {
    if (window.scrollY >= 130) {
      setTitleFixe(true);
    } else {
      setTitleFixe(false);
    }
  };

  window.addEventListener('scroll', FixedMenu);

  const [dataObras, setDataObra] = useState<Array<IObras>>();
  const [dataAcao, setDataAcao] = useState<Array<IAcao>>();

  useEffect(() => {
    const response = async () => {
      const dataObras = await getObras();
      setDataObra(dataObras);
      const dataAcao = await getAcoes();
      setDataAcao(dataAcao);
    };
    response();
  }, []);

  return (
    <>
      <section className="container-box">
        <div className="header-content">
          <div className={titleFixed ? 'header-title fixed' : 'header-title'}>
            <Breadcrumb links={links} />
            <h1>Mapa de Equipamentos e Ações</h1>
            <div className="label-obras-acoes">
              <img src={ObrasIconCirle} alt="legenda obrar" />
              <span>Equipamentos</span>
              <img src={AcoesIconCirle} alt="legenda açoes" />
              <span>Ações</span>
            </div>
          </div>
        </div>
        <div className={titleFixed ? 'popups-overlay fixed' : 'popups-overlay'}>
          <div className="popups-overlay-menu left">
            <div className="select-flex">
              <Select
                setSelectRegion={handleSelectChange}
                selectRegion={selectRegion}
              />
            </div>
            <FilterPopup dataAcao={dataAcao} dataObras={dataObras} />
          </div>
          <div className="popups-overlay-menu right">
            <FilterStatus dataObras={dataObras} />
          </div>
        </div>
        <div className="map-container" onClick={() => setShowInput(false)}>
          <BaseMap dataObras={dataObras} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ObrasPage;
