import React, { createContext, useState } from 'react';

interface ICheckboxContext {
  setCheckedListObras(state: Array<string>): void;
  checkedListObras: Array<string> | undefined;
  checkObrasFilterOn: boolean
  setcheckObrasFilterOn(state: boolean): void;

  checkedListAcoes: Array<string> | undefined;
  setCheckedListAcoes(state: Array<string>): void;
  checkAcoesFilterOn: boolean
  setcheckAcoesFilterOn(state: boolean): void;

  setCheckedListStatus(state: Array<string>): void;
  checkedListStatus: Array<string>;
  checkStatusFilterOn: boolean
  setcheckStatusFilterOn(state: boolean): void;
}

export const CheckboxContext = createContext<ICheckboxContext>({
    checkedListObras: [],
    setCheckedListObras(){},
    checkObrasFilterOn: true,
    setcheckObrasFilterOn(){},

    checkedListAcoes: [],
    setCheckedListAcoes(){},
    checkAcoesFilterOn: true,
    setcheckAcoesFilterOn(){},

    checkedListStatus: ["Em andamento", "Concluida", "Inaugurada"],
    setCheckedListStatus(){},
    checkStatusFilterOn: true,
    setcheckStatusFilterOn(){}
});

export const ProviderCB = ({ children }: { children: React.ReactNode }): any => {

  const [checkedListObras, setCheckedListObras] = useState<Array<string>>();
  const [checkObrasFilterOn, setcheckObrasFilterOn] = useState(true);
  const [checkedListAcoes, setCheckedListAcoes] = useState<Array<string>>();
  const [checkAcoesFilterOn, setcheckAcoesFilterOn] = useState(true);
  const [checkedListStatus, setCheckedListStatus] = useState<Array<string>>(["Em andamento", "Concluida", "Inaugurada"]);
  const [checkStatusFilterOn, setcheckStatusFilterOn] = useState(true);

  const contextValues: ICheckboxContext = {
    checkedListObras,
    setCheckedListObras,
    checkObrasFilterOn,
    setcheckObrasFilterOn,
    checkedListAcoes,
    setCheckedListAcoes,
    checkAcoesFilterOn,
    setcheckAcoesFilterOn,
    checkedListStatus,
    setCheckedListStatus,
    checkStatusFilterOn,
    setcheckStatusFilterOn
  };

  return (
    <CheckboxContext.Provider value={contextValues}>
      {children}
    </CheckboxContext.Provider>
  );
};
