import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 auto 30px;

  .ant-form-item-label {
    font-weight: bold;
  }

  .ant-row.ant-form-item {
    margin-bottom: 2rem;
  }
  input.ant-input.ant-input-disabled, span.ant-select-selection-item, input, input::placeholder, .ant-input-prefix,
  .ant-input-number-disabled, textarea, textarea::placeholder, label.ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-disabled span {
    color: #2d4040 !important;
    cursor: default !important;
    background-color: transparent !important;
  }

  span.ant-picker-suffix {
    display: none;
  }

  .img-default {
      background: #fafafa;
      display: grid;
      align-items: center;
      grid-template-rows: 17.5rem;
      justify-content: center;
      height: 175px;
      overflow: hidden;
      svg {
        color: rgba(0, 0, 0, 0.1);
        font-size: 7rem;
      }
  }
`;
