import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

/* Interface de uma Iniciativa */
interface IIniciativa {
  tipo: 'Obra' | 'Ação';
  descricao: string;
  eventos: {}[];
  id: string;
  nome: string;
  subcategoria: string;
  tempoDe: string;
  totalEventos: number;
  ultimaModificacao: string;
  categoria: string;
}

/* Tipos do contexto */
type HomeServidorData = {
  iniciativasList: IIniciativa[];
  obrasList: IIniciativa[];
  acoesList: IIniciativa[];
  iniciativasListCurrent: IIniciativa[];
  isLoading: boolean;
  listIsEmpty: boolean;
  displayOptionFilter: string;
  orderByOptionFilter: string;
  setIsLoading: (state: boolean) => void;
  setListIsEmpty: (state: boolean) => void;
  setDisplayOptionFilter: (state: string) => void;
  setOrderByOptionFilter: (state: string) => void;
  setIniciativasListCurrent: (state: IIniciativa[]) => void;
  updateIniciativasList: (
    list: IIniciativa[],
    type: "acoes" | "obras",
  ) => void;
};

type HomeServidorProviderProps = {
  children: ReactNode;
};

export const HomeServidorContext = createContext({} as HomeServidorData);

export function HomeServidorProvider({ children }: HomeServidorProviderProps) {
  /* estado das listas juntas*/
  const [iniciativasList, setIniciativasList] = useState<Array<IIniciativa>>(
    [],
  );

  /* Receberá sempre a lista mais atualizada */
  const [iniciativasListCurrent, setIniciativasListCurrent] = useState<
    Array<IIniciativa>
  >([]);

  /* estados para obra e ação */
  const [obrasList, setObrasList] = useState<Array<IIniciativa>>([]);
  const [acoesList, setAcoesList] = useState<Array<IIniciativa>>([]);

  /* estados de situações em que lista pode estar */
  const [isLoading, setIsLoading] = useState(true);
  const [listIsEmpty, setListIsEmpty] = useState(false);

  /* estados de filtro */
  const [displayOptionFilter, setDisplayOptionFilter] = useState('Todos');
  const [orderByOptionFilter, setOrderByOptionFilter] = useState('recentes');

  /* função que atualiza a lista de iniciativa */
  function updateIniciativasList(
    list: IIniciativa[],
    type: "obras" | "acoes",
  ) {

    type === "acoes" && setAcoesList(list);
    type === "obras" && setObrasList(list);
    
  }

  useEffect(() => {
    const generalListIniciativas = [...obrasList, ...acoesList];
    
    generalListIniciativas?.length === 0 ? setListIsEmpty(true) : setListIsEmpty(false);

    setIniciativasList(generalListIniciativas);
    setIniciativasListCurrent(generalListIniciativas);
  }, [obrasList, acoesList])

  useEffect(() => {
    if (displayOptionFilter === 'Ação') setIniciativasListCurrent(acoesList);
    if (displayOptionFilter === 'Obra') setIniciativasListCurrent(obrasList);
    if (displayOptionFilter === 'Todos')
      setIniciativasListCurrent(iniciativasList);
  }, [displayOptionFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (orderByOptionFilter === 'ascAlfabetica') {
      const newListCurrent = [...iniciativasListCurrent];

      const newList: IIniciativa[] = newListCurrent.sort((a, b) => {
        return a.nome.localeCompare(b.nome);
      });
      setIniciativasListCurrent(newList);
    }

    if (orderByOptionFilter === 'descAlfabetica') {
      const newListCurrent = [...iniciativasListCurrent];
      const newList: IIniciativa[] = newListCurrent.sort((a, b) => {
        return b.nome.localeCompare(a.nome);
      });
      setIniciativasListCurrent(newList);
    }

    if (orderByOptionFilter === 'recentes') {
      const newListCurrent = [...iniciativasListCurrent];
      const newList: IIniciativa[] = newListCurrent.sort((a, b) => {
        return (
          new Date(a.ultimaModificacao).getTime() -
          new Date(b.ultimaModificacao).getTime()
        );
      });

      setIniciativasListCurrent(newList);
    }

    if (orderByOptionFilter === 'antigos') {
      const newListCurrent = [...iniciativasListCurrent];

      const newList: IIniciativa[] = newListCurrent.sort((a, b) => {
        return (
          new Date(b.ultimaModificacao).getTime() -
          new Date(a.ultimaModificacao).getTime()
        );
      });
      setIniciativasListCurrent(newList);
    }
  }, [orderByOptionFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HomeServidorContext.Provider
      value={{
        iniciativasList,
        obrasList,
        acoesList,
        iniciativasListCurrent,
        isLoading,
        listIsEmpty,
        displayOptionFilter,
        orderByOptionFilter,
        setIsLoading,
        setListIsEmpty,
        setDisplayOptionFilter,
        setOrderByOptionFilter,
        setIniciativasListCurrent,
        updateIniciativasList,
      }}
    >
      {children}
    </HomeServidorContext.Provider>
  );
}

/* Exportando o contexto  */
export const useHomeServidor = () => {
  return useContext(HomeServidorContext);
};
