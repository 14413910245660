import jsPDF from 'jspdf';

import { convertArraysFromString, formatObject, genereteRows, genereteWidthColumn } from '../utils/formatter';
import { headMaisNutricao } from '../utils/headers';

import 'jspdf-autotable';

function testFilter(arrayEvent){
  let tipos: any = []
  tipos.push(arrayEvent.filter(action => {
    return action.tipo === 'Mais nutrição doação'
  }))
  tipos.push(arrayEvent.filter(action => {
    return action.tipo === 'Mais nutrição capacitação'
  }))
  tipos.push(arrayEvent.filter(action => {
    return action.tipo === 'Distribuição de Cestas Básicas na Pandemia COVID-19'
  }))

  return tipos
}

export function MaisNutricaoPDF(eventData: any) {
  let docs: any = []
  let positions: any = []
  
  const tipos = testFilter(eventData);

  
  tipos.forEach((tipo, index) => {
    let indexPostion = 0;
    docs.push(new jsPDF())

    let position: any = []

    let rows = genereteRows(headMaisNutricao[index]);
  

    if(tipo.length > 0) {
      tipo.forEach((event) => {
        let objectLine: any = [];
        let novoObj: any = [];
        formatObject(event, novoObj);
  
        Object.keys(rows).forEach(coluna => {
          let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
          objectLine.push(objeto);
        });
        
        position.push(objectLine);
  
      });
      positions.push(position)
  
      docs[index].addPage('a4', 'landscape');
      // docs[index].text(2, 12, `Mais nutrição - (${tipo.tipo})`);
      docs[index].setFontSize(15);
      docs[index].autoTable({
        startX: 0,
        rowPageBreak: 'avoid',
        columnStyles: genereteWidthColumn(rows),
        head: headMaisNutricao[index],
        startY: 25,
        margin: {
          top: 10,
          bottom: 40,
          left: 2,
          right: 2,
        },
        headerStyles: {
          fontSize: 8,
          fontFamily: 'Roboto',
          fillColor: '#80c470',
          halign: 'center',
        },
        styles: { fontSize: 8, columnWidth: 'wrap' },
        horizontalPageBreak: false,
        body: convertArraysFromString(positions[indexPostion]),
        theme: 'striped',
      });
    
      docs[index].text(2, 12, `${tipos[index][0].tipo}`);
      indexPostion += 1;

    }

   
  }) 
 
  docs.forEach((doc, index) => {
    doc.deletePage(1); 
    if(index === 0 && tipos[0].length > 0 ){
      doc.save('mais-nutrição-doação.pdf') 
    }
   if(index === 1 && tipos[1].length > 0 ){
    doc.save('mais-nutrição-capacitação.pdf') 
   }

   if(index === 2 && tipos[2].length > 0 ){
    doc.save('cestas-basicas.pdf') 
   } 
    
  })
}