import React from 'react';

import {
  Form,
  FormInstance,
  Row,
  Col,
  Input
} from 'antd';
import { FormBox } from '../../UI/index';
import { InputPhone } from '../../UI'

const { Item } = Form;

interface InfoFormConfirmProps {
  form?: FormInstance;
}

export function InfoFormConfirm({ form }: InfoFormConfirmProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  return(
    <FormBox title="Informações do Responsável" form={currentForm}>
      <Row>
        <Col span={11}>
          <Item
            label="Nome"
            name="nome"
          >
            <Input disabled={true} bordered={false}/>
          </Item>
        </Col>

        <Col span={4} offset={1}>
          <Item
              label="Telefone"
              name="telefone"
            >
              <InputPhone disabled={true} bordered={false}/>
          </Item>
        </Col>
      </Row>
    </FormBox> 
  )
}