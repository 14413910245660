import { ErrorMessageBDS } from 'components/ErrorMessageBDS';
import React from 'react';
import { FormatNumberWithSufix } from 'utils/FormatNumberWithSufix';
import './styles.scss';

interface DadoProps {
  number: number | string;
  title: string;
  subtitle: string;
  maisDe?: boolean;
}

export default function Dado({number, title, subtitle, maisDe}: DadoProps) {
  return (
    <>
      <div className="dado">
        <div className={`numero ${maisDe && `small`} }`}>
          
          <h1>{
          }{number === "-" ? ErrorMessageBDS() : maisDe ? number : FormatNumberWithSufix(Number(number))}</h1>
        </div>
        
        <div className="texto">
          <span className="titulo">{title}</span>
          <span className="subtitulo">{subtitle}</span>
        </div>
      </div>
    </>
  );
}
