import { socialApi } from 'services/api';
import { IJustify, Justify } from 'types';

const service = () => {
  const resource = '/justify';

  async function getJustify(id: number) {
    try {
      const response = await socialApi.get<IJustify[]>(`${resource}/${id}`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function createJustify(data: Justify) {
    try {
      await socialApi.post(`${resource}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return true;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  return {
    getJustify,
    createJustify
  }
}

export const justifyService = service();