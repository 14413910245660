import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd'
import { InputCPF, InputPhone } from 'components';
import React from 'react'
import locale from 'antd/es/locale/pt_BR';
import { StyledForm, StyledFormWrapper, StyledTitle, StyledVerticalLine } from 'components/UI/FormBox/styles';

interface PersonalFormProps {
    maritalStatus: any[]
}

const { Item } = Form;
const { Option } = Select
const { Title } = Typography;

export function PersonalFormEdit({ maritalStatus}: PersonalFormProps) {
    return (
        <>
            <StyledTitle>
                <Title level={3}>Dados Pessoais</Title>
            </StyledTitle>
            <StyledFormWrapper>
                <StyledVerticalLine />
            <StyledForm>
                    <Item
                        label="CPF"
                        name="cpf"
                        wrapperCol={{ xxl: 11, xl: 11, lg: 11, md: 24, xs: 24 }}
                    >
                        <InputCPF bordered={false} disabled/>
                    </Item>

                    <Item label="Nome" name="name">
                        <Input bordered={false} disabled />
                    </Item>

                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                        <Item
                            label="Data de Nascimento"
                            name="birthDate"
                        >
                            <DatePicker
                                locale={locale.DatePicker}
                                format="DD/MM/YYYY"
                                placeholder=""
                                bordered={false} disabled
                            />
                        </Item>
                        </Col>

                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                        <Item
                            label="Estado Civil"
                            name="matrialStatus"
                        >
                            <Select  bordered={false} disabled>
                                {maritalStatus.map(status => (
                                    <Option value={status.id} key={status.id}>
                                    {status.label}
                                    </Option>
                                ))}
                            </Select>
                        </Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                            <Item label="E-mail" name="email" >
                                <Input bordered={false} disabled/>
                            </Item>
                        </Col>
                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                            <Item label="Celular" name="phone">
                                <InputPhone bordered={false} disabled/>
                            </Item>
                        </Col>
                    </Row>
                </StyledForm>
            </StyledFormWrapper>
        </>
    )
}