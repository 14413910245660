import React from 'react';
import { ChangeEvent } from '../../../types';
import { Input } from 'antd';

import { splitByIndex } from '../../../utils/strings';

interface InputCPFProps {
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export function InputCPF({
  value,
  onChange,
  bordered = true,
  disabled = false,
}: InputCPFProps): JSX.Element {
  const dots = (cpfDigits: string[]) => {
    return cpfDigits.map((d, i, arr) => {
      if (
        d.length === 3 &&
        !d.includes('.') &&
        arr[i + 1] &&
        arr[i + 1] !== ''
      ) {
        return `${d}${i > 1 ? '-' : '.'}`;
      }

      return d;
    });
  };

  const cpfMask = (cpf?: string) => {
    if (!cpf) return '';

    return dots(splitByIndex(cpf, 3)).join('');
  };

  const handleChange: ChangeEvent = e => {
    const changedValue = e.target.value.replace(/[^\d]/g, '');

    if (onChange) {
      onChange(changedValue);
    }
  };

  return (
    <Input
      onChange={handleChange}
      value={cpfMask(value)}
      maxLength={14}
      bordered={bordered}
      disabled={disabled}
    />
  );
}
