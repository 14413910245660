import React, { createContext, useState } from 'react';

interface IUtilContext {
  showInput: boolean;
  setShowInput(state: boolean): void;

  selectRegion: string;
  setSelectRegion(state: string): void;
}

export const UtilContext = createContext<IUtilContext>({
  showInput: false,
  setShowInput() {},
  selectRegion: 'FORTALEZA',
  setSelectRegion() {},
});

export const ProviderUtil = ({
  children,
}: {
  children: React.ReactNode;
}): any => {
  const [showInput, setShowInput] = useState(false);
  const [selectRegion, setSelectRegion] = useState('FORTALEZA');

  const contextValues: IUtilContext = {
    showInput,
    setShowInput,
    selectRegion,
    setSelectRegion,
  };

  return (
    <UtilContext.Provider value={contextValues}>
      {children}
    </UtilContext.Provider>
  );
};
