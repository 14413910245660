import { notificationToast } from 'components/NotificationToast';
import { api } from 'services/api';
import fileDownload from 'js-file-download';

import qs from 'qs'

export function getAcoes() {
  // get all actions
  const response = api
    .get(`/acoes`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getAcaoById(id) {
  // get action single
  const response = api
    .get(`/acoes/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getEvent(idAcao, idEvento) {
  // get action event single

  const response = api
    .get(`/acoes/${idAcao}/eventos/${idEvento}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function attEventAcao(
  idAcao,
  idEvento,
  valores,
  onConcluir,
  handleSalvar,
) {
  // get action event single
  api
    .put(`/acoes/${idAcao}/eventos/${idEvento}`, valores)
    .then(res => {
      notificationToast({
        type: 'success',
        message: 'Ação editada com sucesso',
      });
      onConcluir(true);
      handleSalvar(true);
      return res.data;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Erro ao editar ação',
      });
      handleSalvar(false);
      return Promise.reject(err);
    });
}
export function postAcoes(
  valores,
  idAcao,
  onSetValue,
  onConcluir,
  handleSalvar,
): any {
  // post event Action
  api
    .post(`/acoes/${idAcao}/eventos`, valores)
    .then(response => {
      if (onSetValue) onSetValue(response.data);
      notificationToast({
        type: 'success',
        message: 'Ação criada com sucesso',
      });
      handleSalvar(true);
      onConcluir(true);
      return response.data;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Falha ao criar ação',
      });
      handleSalvar(false);
      return Promise.reject(err);
    });
}

export function postFilesAcoes(
  idAcao,
  idEvento,
  files,
  handleSalvarArquivo,
): any {
  // post event Action
  const formData = new FormData();
  files.map((files, i) => formData.append('arquivos ', files));

  api
    .post(`/acoes/${idAcao}/${idEvento}/upload`, formData)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo(s) adicionado com sucesso',
      });
      handleSalvarArquivo('sucess');
      return response;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Erro ao adicionar arquivo(s)',
      });
      handleSalvarArquivo('error');
      return Promise.reject(err);
    });
}
export function postFilesLivro(
  idAcao,
  idEvento,
  files,
  setFilesUpload,
  setUploadFile,
  setFileSave,
  setNamesFiles,
  setIsSavingFile,
): any {
  // post event Action
  const formData = new FormData();
  files.map((files, i) => formData.append('arquivos', files));
  setIsSavingFile(true);

  api
    .post(`/acoes/${idAcao}/${idEvento}/upload/livro`, formData)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo(s) adicionado com sucesso',
      });
      setFilesUpload([]);
      setNamesFiles([]);
      setUploadFile(true);
      setIsSavingFile(false);
      setFileSave(response.data);
      return response;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Erro ao adicionar arquivo(s)',
      });
      setIsSavingFile(false);
      return Promise.reject(err);
    });
}
export function postFilesPublicacao(
  idAcao,
  idEvento,
  files,
  setFilesUpload,
  setUploadFile,
  setFileSave,
  setNamesFiles,
  setIsSavingFile,
): any {
  // post event Action
  const formData = new FormData();
  files.map((files, i) => formData.append('arquivos', files));
  setIsSavingFile(true);

  api
    .post(`/acoes/${idAcao}/${idEvento}/upload/publicacao`, formData)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo(s) adicionado com sucesso',
      });
      setFilesUpload([]);
      setNamesFiles([]);
      setUploadFile(true);
      setIsSavingFile(false);
      setFileSave(response.data);
      return response;
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Erro ao adicionar arquivo(s)',
      });
      setIsSavingFile(false);
      return Promise.reject(err);
    });
}
export function postFileDocumentario(
  idAcao,
  idEvento,
  file,
  setUploadFile,
  setFileSave,
  setSelectedFile,
  setIsSavingFile,
): any {
  // post event Action

  setIsSavingFile(true);
  const formData = new FormData();
  formData.append('arquivo', file);
  api
    .post(`/acoes/${idAcao}/${idEvento}/upload/documentario`, formData)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo adicionado com sucesso',
      });
      setSelectedFile(null);
      setUploadFile(true);
      setIsSavingFile(false);
      setFileSave(response.data);
    })
    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Erro ao adicionar arquivo',
      });
      setUploadFile(false);
      setIsSavingFile(false);
      return Promise.reject(err);
    });
}

export function deleteFileDocumentario(
  idAcao,
  idEvento,
  id,
  setDeletedFile,
): any {
  api
    .delete(`/acoes/${idAcao}/${idEvento}/${id}/documentario`)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo deletado com sucesso',
      });
      setDeletedFile(true);
      return response;
    })
    .catch(err => {
      notificationToast({ type: 'error', message: 'Erro ao deletar arquivo' });
      return Promise.reject(err);
    });
}

export function deleteNowFileDocumentario(
  idAcao,
  idEvento,
  id,
  setFileSave,
): any {
  api
    .delete(`/acoes/${idAcao}/${idEvento}/${id}/documentario`)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo deletado com sucesso',
      });
      setFileSave(null);
      return response;
    })
    .catch(err => {
      notificationToast({ type: 'error', message: 'Erro ao deletar arquivo' });
      return Promise.reject(err);
    });
}

export function deleteFileLivro(idAcao, idEvento, onDeletedFile, id): any {
  api
    .delete(`/acoes/${idAcao}/${idEvento}/${id}/livro`)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo deletado com sucesso',
      });
      onDeletedFile(true);
      return response;
    })
    .catch(err => {
      notificationToast({ type: 'error', message: 'Erro ao deletar arquivo' });
      return Promise.reject(err);
    });
}
export function deleteFilePublicacao(idAcao, idEvento, onDeletedFile, id): any {
  api
    .delete(`/acoes/${idAcao}/${idEvento}/${id}/publicacao`)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo deletado com sucesso',
      });
      onDeletedFile(true);
      return response;
    })
    .catch(err => {
      notificationToast({ type: 'error', message: 'Erro ao deletar arquivo' });
      return Promise.reject(err);
    });
}
export function deleteFileAcao(
  idAcao,
  idEvento,
  file,
  setArquivosRegistro,
  arquivoRegistro,
): any {
  // delete event Action
  api
    .delete(`/acoes/${idAcao}/${idEvento}/${file.id}`)
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo deletado com sucesso',
      });

      arquivoRegistro.splice(arquivoRegistro.indexOf(file, 0), 1);
      setArquivosRegistro([...arquivoRegistro]);

      // let remaningFiles;

      // arquivoRegistro.map(fileMap => {
      //   fileMap.id !== file.id
      // })
      return response;
    })
    .catch(err => {
      notificationToast({ type: 'error', message: 'Erro ao deletar arquivo' });
      return Promise.reject(err);
    });
}

export function downloadFileAcao(idAcao, idEvento, file, onCompletDownload): any {
  // delete event Action
  api
    .get(`/acoes/${idAcao}/${idEvento}/${file.id}`, {
      responseType: 'blob',
   
    })
    .then(response => {
      notificationToast({
        type: 'success',
        message: 'Arquivo baixado com sucesso',
      });
      onCompletDownload(false)

      fileDownload(response.data, `${file.nome}.${file.extensao}`);
    })
    .catch(err => {
      notificationToast({ type: 'error', message: 'Erro ao baixar arquivo' });
      return Promise.reject(err);
    });
}

export function getPoliclinicas() {
  const response = api
    .get('/acoes/policlinicas')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getAtendimentosAcoes() {
  const response = api
    .get(`/acoes/atendimentos`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getAtendimentosGestorAcoes() {
  const response = api
    .get(`/acoes/atendimentos/gestor`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getAtendimentosObras() {
  // get all actions
  const response = api
    .get(`/obras/atendimentos`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}


export function getInfosAction(idAcao){
  const response = api
    .get(`/acoes/${idAcao}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getEventosByFiltro(idAcao, pagina, municipios, data, regioes, tipo, tamanho) {
  const response = api
    .get(`/acoes/${idAcao}/eventos`,{
      params: {
        municipios: municipios.length === 0 ? undefined : municipios,
        data: data === "" ? undefined : data, 
        regioes: regioes.length === 0 ? undefined: regioes,
        tipo: tipo === "" ? undefined : tipo,
        tamanho,
        pagina
      },
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}