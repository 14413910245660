import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { convertArraysFromString, formatObject, genereteRows, genereteWidthColumn } from '../utils/formatter';
import {  headRegistroAcumulado, headRegistroCapacitacao,headEventoRegular  } from '../utils/headers';


function testFilter(arrayEvent){
  const newArrayRegistroCap = arrayEvent.filter(action => {
    return action?.tipo === 'Registro capacitação'
  });
  const newArrayEventoRegular = arrayEvent.filter(action => {
    return action?.tipo === 'Evento regular'
  });
  const newArrayRegistroAcumulado = arrayEvent.filter(action => {
    return action?.tipo === 'Registro acumulado'
  });

  return [newArrayRegistroCap, newArrayEventoRegular, newArrayRegistroAcumulado];
}

export function criancaFelizPDF(eventData: any) {
  const [eventRegistroCap, eventRegular, eventRegistroAcumulado] = testFilter(eventData)

  const tableRegistroCap: any = [];


  const docRegistroCap: any = new jsPDF();
  const docRegular: any = new jsPDF();
  const docRegistroAcumulado: any = new jsPDF();
 

  const rowsRegistroCaps = genereteRows(headRegistroCapacitacao);


  eventRegistroCap?.forEach(event => {
    let objectLine: any = [];
    let novoObj: any = [];
    formatObject(event, novoObj);

    Object.keys(rowsRegistroCaps).forEach(coluna => {
      let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
      
      objectLine.push(objeto);
    });

    tableRegistroCap.push(objectLine);
  });
  const tableRegular: any = [];
  const rowsRegular = genereteRows(headEventoRegular);

  docRegistroCap.addPage('a4', 'landscape');

  docRegistroCap.setFontSize(15);
  docRegistroCap.text(2, 12, `Criança Feliz - Registro Capacitação`);

  docRegistroCap.autoTable({
    startX: 0,
    rowPageBreak: 'avoid',
    columnStyles: genereteWidthColumn(rowsRegistroCaps),
    head: headRegistroCapacitacao,
    startY: 25,
    margin: {
      top: 10,
      bottom: 40,
      left: 2,
      right: 2,
    },
    headerStyles: {
      fontSize: 8,
      fontFamily: 'Roboto',
      fillColor: '#80c470',
      halign: 'center',
    },
    styles: { fontSize: 8, columnWidth: 'wrap' },
    horizontalPageBreak: false,
    body: convertArraysFromString(tableRegistroCap),
    theme: 'striped',
  });



  eventRegular?.forEach(event => {
    let objectLine: any = [];
    let novoObj: any = [];
    formatObject(event, novoObj);

    Object.keys(rowsRegular).forEach(coluna => {
      let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
      objectLine.push(objeto);
    });

    tableRegular.push(objectLine);
  });

  docRegular.addPage('a4', 'landscape');

  docRegular.setFontSize(20);
  docRegular.text(2, 12, `Criança Feliz - Evento Regular`);


  docRegular.autoTable({
    startX: 0,
    rowPageBreak: 'avoid',
    columnStyles: genereteWidthColumn(rowsRegular),
    head: headEventoRegular,
    startY: 25,
    margin: {
      top: 10,
      bottom: 40,
      left: 2,
      right: 2,
    },
    headerStyles: {
      fontSize: 8,
      fontFamily: 'Roboto',
      fillColor: '#80c470',
      halign: 'center',
    },
    styles: { fontSize: 8, columnWidth: 'wrap' },
    horizontalPageBreak: false,
    body: convertArraysFromString(tableRegular),
    theme: 'striped',
  });

  const tableRegistroAcumulado: any = [];
  const rowsRegistroAcumulado = genereteRows(headRegistroAcumulado);
  eventRegistroAcumulado?.forEach(event => {
    let objectLine: any = [];
    let novoObj: any = [];
    formatObject(event, novoObj);

    Object.keys(rowsRegistroAcumulado).forEach(coluna => {
      let objeto = novoObj.filter(valor => valor.dataKey === coluna)[0];
      objectLine.push(objeto);
    });

    tableRegistroAcumulado.push(objectLine);
  });

  docRegistroAcumulado.addPage('a4', 'landscape');

  docRegistroAcumulado.setFontSize(20);
  docRegistroAcumulado.text(2, 12, `Criança Feliz - Registro Acumulado`);


  docRegistroAcumulado.autoTable({
    startX: 0,
    rowPageBreak: 'avoid',
    columnStyles: genereteWidthColumn(rowsRegistroAcumulado),
    head: headRegistroAcumulado,
    startY: 25,
    margin: {
      top: 10,
      bottom: 40,
      left: 2,
      right: 2,
    },
    headerStyles: {
      fontSize: 8,
      fontFamily: 'Roboto',
      fillColor: '#80c470',
      halign: 'center',
    },
    styles: { fontSize: 8, columnWidth: 'wrap' },
    horizontalPageBreak: false,
    body: convertArraysFromString(tableRegistroAcumulado),
    theme: 'striped',
  });
  

 if(eventRegistroCap.length > 0) {
  docRegistroCap.deletePage(1); 
  docRegistroCap.save(`registro-capacitação.pdf`);
 }

  if(eventRegular.length > 0){
    docRegular.deletePage(1); 
    docRegular.save(`evento-regular-municipio.pdf`);
  }
  if(eventRegistroAcumulado.length > 0){
    docRegistroAcumulado.deletePage(1); 
    docRegistroAcumulado.save(`registro-acumulado.pdf`);
  
  }
}