import { socialApi } from 'services/api';

const service = () => {
    const resource = '/reports';

    async function createReport(data) {
        try {
          const response = await socialApi.post(`${resource}`, data, { responseType: 'arraybuffer' })
          
          return response.data;
    
        } catch(err) {
          return Promise.reject(err);
        }
    }

  async function monthReport(data) {
    try {
      const response = await socialApi.post(`${resource}/count`, data, { responseType: 'arraybuffer' })
        
      return response.data;
  
    }catch(err) {
      return Promise.reject(err);
    }
  }


	async function anualReport() {
		try {
		  const response = await socialApi.post(`${resource}/period`, null, {
			
            responseType: 'arraybuffer',
		  })
		  
		  return response.data;
			
		} catch(err) {
		  return Promise.reject(err);
		}
	}

    return {
          createReport,
          monthReport,
		  anualReport
    }

}

export const reportService = service();