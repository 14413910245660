import React, { useState, useEffect } from 'react';

import {
  UploadOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import {
  downloadFileAcao,
  deleteFileLivro,
  postFilesLivro,
} from 'services/resources/acoesServices';

import { LoadingOutlined } from '@ant-design/icons';

import '../styles.scss';
import { Popconfirm, Spin } from 'antd';

interface Props {
  name: '';
  extensao: '';
  tamanho: '';
}
interface PropsFiles {
  name: '';
  lastModified: number;
  size: number;
}
interface FormCreateLivroProps {
  idAcao;
  idEvento;
  filesCurrent: any;
  editMode;
  setConcluirDisabled: (value: any) => void;
}

export function FormCreateLivro({
  idAcao,
  idEvento,
  filesCurrent,
  editMode,
  setConcluirDisabled,
}: FormCreateLivroProps) {

  const [namesFiles, setNamesFiles] = useState([] as any);
  const [filesExists, setFilesExists] = useState<any>(filesCurrent ?? null);
  const [filesUpload, setFilesUpload] = useState([] as any);
  const [, setUploadFile] = useState(false);
  const [deletedFile, setDeletedFile] = useState(false);
  const [fileSave, setFileSave] = useState<any>(null);
  const [lastFileRemoved, setLastFileRemoved] = useState(null as any);
  const [isSavingFile, setIsSavingFile] = useState(false);

  const[makeDownload, setMakeDownload] = useState(false);

  const antIcon = <LoadingOutlined spin />;

  useEffect(() => {
    if (deletedFile) {
      onRemoveFile(lastFileRemoved.arquivo.name);
    }
  }, [deletedFile, lastFileRemoved]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filesUpload?.length === 0) {
      setConcluirDisabled(false);
    } else {
      setConcluirDisabled(true);
    }
  }, [filesUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileChange = event => {
    const modifiedFiles: PropsFiles[] = Array.from(event.target.files);
    const nameFile = modifiedFiles.map(file => {
      return {
        id: file.lastModified,
        name: file.name,
      };
    });
    const newFile = event.target.files;

    setNamesFiles(prev => [...prev, ...nameFile]);
    setFilesUpload(prev => [...prev, ...newFile]);
  };

  const onRemoveFile = nameFile => {
    const newNameFiles = namesFiles.filter(file => file.name !== nameFile);
    const newSelectFiles = [...filesUpload].filter(
      file => file.name !== nameFile,
    );

    if (filesExists && deletedFile) {
      filesExists.splice(filesExists.indexOf(lastFileRemoved, 0), 1);
      setFilesExists([...filesExists]);
    }

    setFilesUpload(newSelectFiles);
    setNamesFiles(newNameFiles);
  };

  useEffect(() => {
    if (fileSave) {
      setFilesExists([...(filesExists ?? []), ...fileSave]);
    }
  }, [fileSave]); // eslint-disable-line react-hooks/exhaustive-deps

  function onDownload(file) {
    setMakeDownload(true)
    downloadFileAcao(idAcao, idEvento, file, setMakeDownload);
  }

  function onRemove(file) {
    setLastFileRemoved(file);

    deleteFileLivro(idAcao, idEvento, setDeletedFile, file.arquivo.id);
  }

  return (
    <section className="container__formArquivos">
      <h3 className="ant-typography">Anexos do Lançamento</h3>

      <div className="formArquivos-files">
        <label
          htmlFor="selecao-arquivos-livros"
          className={
            namesFiles.length === 0 ? 'label-files animation' : 'label-files'
          }
        >
          <UploadOutlined />
          <p>Selecionar arquivos </p>
        </label>
        <input
          id="selecao-arquivos-livros"
          type="file"
          className="input-files"
          onChange={onFileChange}
          multiple
        ></input>

        <ul className="files-fileNames">
          {editMode && namesFiles.length > 0 && filesCurrent?.length > 0 && (
            <strong>Novos arquivos</strong>
          )}
          {namesFiles?.map(file => {
         
            return (
              <>
                <li className="content__namesFiles">
                 <div>
                 <PaperClipOutlined /> <h3>{file.name}</h3>
                  <button
                    className="remover-file"
                    onClick={() => onRemoveFile(file.name)}
                  >
                    <DeleteOutlined />
                  </button>
                 </div>
                </li>
              </>
            );
          })}

          {filesExists?.length > 0 && (
            <strong className="exists">Arquivos já cadastrados</strong>
          )}
          {filesExists?.map(file => {
            return (
              <li className="content__namesFiles">
              <div>
              <PaperClipOutlined /> <h3>{file.arquivo.nome}</h3>
                <Popconfirm
                  title="Deseja apagar esse arquivo?"
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => onRemove(file)}
                >
                  <button className="remover-file">
                    <DeleteOutlined />
                  </button>
                </Popconfirm>
                <button
                  className="download-file"
                  onClick={() => onDownload(file.arquivo)}
                >
                  <DownloadOutlined />
                </button>
              </div>
              </li>
            );
          })}
             {
                makeDownload && (
                  <div className="download-progress">
                    <div>
                      <div className="line"></div>  
                    </div>
                    <strong>Fazendo download</strong>
                  </div>
                )
              }
        </ul>
        {filesUpload.length > 0 && (
          <Popconfirm
            title="Deseja enviar esse arquivo?"
            okText="Sim"
            cancelText="Não"
            onConfirm={() => {
              postFilesLivro(
                idAcao,
                idEvento,
                filesUpload,
                setFilesUpload,
                setUploadFile,
                setFileSave,
                setNamesFiles,
                setIsSavingFile,
              );
            }}
          >
            <button
              type="button"
              className={isSavingFile ? 'enviar-doc disabled' : 'enviar-doc'}
              disabled={isSavingFile}
            >
              {!isSavingFile ? (
                'Enviar arquivos'
              ) : (
                <Spin
                  className="spin-button-file"
                  size="small"
                  tip="Enviar arquivos"
                  indicator={antIcon}
                />
              )}
            </button>
          </Popconfirm>
        )}
      </div>
    </section>
  );
}
