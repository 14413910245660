function isPublicServer(roles: string[] = []) {
  return roles.includes('ROLE_SPS_SERVIDOR');
}

function isArticulator(roles: string[] = []) {
  return roles.includes('ROLE_SPS_ARTICULADOR');
}

function isSPSAdmin(roles: string[] = []) {
  return roles.includes('ROLE_SPS_ADMIN');
}

function isCitizenAdmin(roles: string[] = []) {
  return roles.includes('ROLE_SEGURANCA_ADM');
}

function isManager(roles: string[] = []) {
  return isSPSAdmin(roles) || roles.includes("ROLE_SPS_OBRAS_ACOES")
}

function canSeePesquisaCMIC(roles: string[] = []) {
  return isSPSAdmin(roles) || roles.includes("ROLE_SPS_PESQUISA_CEMIC")
}

export const checkRoles = {
  isPublicServer,
  isArticulator,
  isSPSAdmin,
  isCitizenAdmin,
  isManager,
  canSeePesquisaCMIC
}