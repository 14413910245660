import styled from 'styled-components';

export const Content = styled.div`
  form {
    margin-bottom: 30px;
  }

  .ant-input-disabled {
    cursor: default;
    color: #000;
    padding-left: 0;
  }
`;
