import React from 'react';
import cearaMunicipios from '../../data/ceara-full.json'
import './styles.scss';
interface IRegionSelect {
  setSelectRegion: (params: string) => void;
  selectRegion: string
}

export interface IPracas {
  id: string;
  regiao: string;
  municipio: string;
  nomePraca: string;
  enderecoCompleto: string;
  latitude: number;
  longitude: number;
  dataObraInaugurada: string;
  dataObraConcluida: string;
  dataInicioObra: string;
  imagem: null | string;
}


const StateSelect = ({ setSelectRegion, selectRegion }: IRegionSelect): JSX.Element => {
  return (
    <div className="select-container">
      <select
        style={{width: 200}}
        onChange={event => setSelectRegion(event.target.value)}
        defaultValue="FORTALEZA"
        value={selectRegion}
      >
        {cearaMunicipios.features.map(optionTitle => (
          <option key={Math.random()} value={optionTitle.properties.name.toUpperCase()}>
            {optionTitle.properties.name.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StateSelect;
