import FormBuilder from 'antd-form-builder';
import moment from 'moment';

import React, { useState, useEffect } from 'react';
import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';
import { makeOptitionsNamesCREDES } from 'utils/makeOptionsCREDES';
// import { citiesNamesCE } from 'utils/citiesNames';
// import credes from '../../../../../data/optionsCREDES.json';

import './styles.scss';
interface CiclosSeducProps {
  form: any;
  viewMode?: boolean;
  size: any;
  initialValues?: any;
}

function CiclosSeduc({ form, viewMode, size, initialValues }: CiclosSeducProps) {
  const [counterList, setCounterList] = useState(0);
  const [newCont, setNewCont] = useState(false)
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    setNewCont(true);

    if(!newCont){
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      });
    }else {
      setList([])
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      });
    }
  }, [size]); // eslint-disable-line react-hooks/exhaustive-deps

  function addFields(indice) {
    
    const newList = {
      columns: 2,
      fields: [
        {
          key: `ciclosSeduc/.periodo${indice}/.dataInicio`,
          label: 'Data início',
          widget: 'date-picker',
          viewWidget: 'date-view',
          name: `dataInicio${indice}`,
          placeholder: 'Selecione a data',
          dependencies: [`dataFim${indice}`],
          rules: [
            {
              required: true,
              message: `O campo "Data início" é obrigatório.`,
            },
            {
              validator: (rule, value, callback) => {
                return new Promise<void>((resolve, reject) => {
                  const newInicial = new Date(value).getTime();

                  const dataFim = new Date(
                    form.getFieldValue(`dataFim${indice}`),
                  ).getTime();

                  if (newInicial > dataFim) {
                    reject(
                      new Error('O evento deve iniciar antes da data final'),
                    );
                  } else {
                    resolve();
                  }
                });
              },
            },
          ],
        },
        {
          key: `ciclosSeduc/.periodo${indice}/.dataFim`,
          label: 'Data fim',
          widget: 'date-picker',
          viewWidget: 'date-view',
          placeholder: 'Selecione a data',
          name: `dataFim${indice}`,
          dependencies: [`dataInicio${indice}`],
          rules: [
            {
              required: true,
              message: `O campo "Data fim" é obrigatório.`,
            },
            {
              validator: (rule, value, callback) => {
                return new Promise<void>((resolve, reject) => {
                  const newFinal = new Date(value).getTime()
                  const dataInicio = new Date(
                    form.getFieldValue(`dataFim${indice}`),
                  ).getTime()

                  if (newFinal < dataInicio) {
                    reject(
                      new Error(
                        'O evento deve finalizar depois da data inicial',
                      ),
                    );
                  } else {
                    resolve();
                  }
                });
              },
            },
          ],
        },
        {
          key: `ciclosSeduc/.cargaHoraria/${indice}`,
          label: 'Carga horária',
          widget: 'number',
          widgetProps: { min: 0, style: {width: '75%'} },
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: `O campo "Carga horária" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.municipios/${indice}`,
          label: 'CREDEs em que ocorrem',
          widget: 'select',
          options: makeOptitionsNamesCREDES(),

          widgetProps: { mode: 'multiple', allowClear: true, onChange: (value) => {
         
          } },
          viewWidget: 'list-view',

          rules: [
            {
              required: true,
              message: `O campo "Municípios" é obrigatório.`,
            },
          ],
          
        },
        {
          key: `ciclosSeduc/.conteudoProgramatico/${indice}`,
          label: 'Conteúdo programático',
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: `O campo "Conteúdo programático" é obrigatório.`,
            },
          ],
        },
        {
          key: 'label1',
          colSpan: 4,
          render() {
            return (
              <span className="span-participantes-seduc">
                Participantes por perfil
              </span>
            );
          },
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.tecnicosSeduc`,
          label: 'Técnicos SEDUC',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Técnicos SEDUC" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.tecnicosCrede`,
          label: 'Técnicos de CREDE',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Técnicos de CREDE" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.articuladoresRegionaisMaisInfancia`,
          label: 'Articuladores Regionais Mais Infância',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Articuladores Regionais Mais Infância" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.formadoresRegionais`,
          label: 'Formadores regionais',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `Esse campo é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.formadoresMunicipais`,
          label: 'Formadores Municipais',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Formadores Municipais" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.professores`,
          label: 'Professores',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Professores" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.gestoresEscolares`,
          label: 'Gestores Escolares',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Gestores Escolares" é obrigatório.`,
            },
          ],
        },
        {
          key: `ciclosSeduc/.participantesPorPerfil${indice}/.coordenadoresMunicipaisEducacaoInfantil`,
          label: 'Coordenadores Municipais de Educação Infantil',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O esse campo é obrigatório.`,
            },
          ],
        },
      ],
    };

    

    setList(prev => [...prev, newList]);
    setCounterList(prev => prev + 1);
  }

  function removeFields(index) {
    list.splice(index, 1);

    setList([...list]);
  }

  return (
    <div>
      <fieldset>
        <legend>Ciclos Seduc</legend>

        {list.map((metaList, index) => {

            metaList?.fields?.forEach(field => {
              if(viewMode) {
                if(field.label === 'Data fim' || field.label === 'Data início'){
                  delete field.dependencies;
                  delete field.rules;

                  if(field.label === 'Data fim'){
                    field['initialValue'] = form.getFieldValue(`dataFim${index}`);               

                  }else{
                    field['initialValue'] = form.getFieldValue(`dataInicio${index}`);               
                  }
                }
              }

              if(initialValues?.ciclosSeduc && !viewMode){
                if (field.key.includes('dataInicio')) {
                  field['initialValue'] = moment(initialValues?.ciclosSeduc[`${index}`]?.periodo?.dataInicio);  
                }
                else if(field.key.includes('dataFim')){
                  field['initialValue'] = moment(initialValues?.ciclosSeduc[`${index}`]?.periodo?.dataFim);  
                }
              }
            })

          return (
            <React.Fragment key={index}>
              {!viewMode && index > 0 && (
                <div className="add-button" onClick={() => removeFields(index)}>
                  <IoRemoveCircle size={25} color="var(--green-dark)" />
                  <strong>Remover Ciclo</strong>
                </div>
              )}
              <FormBuilder form={form} meta={metaList} viewMode={viewMode} />
            </React.Fragment>
          );
        })}
        {!viewMode && (
          <div className="add-button" onClick={() => addFields(counterList)}>
            <IoAddCircle size={25} color="var(--green-dark)" />
            <strong>Adicionar novo</strong>
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default CiclosSeduc;
