import React from 'react';
import { FormInstance, Typography, Form } from 'antd';

import {
  StyledTitle,
  StyledFormWrapper,
  StyledVerticalLine,
  StyledForm
} from './styles';

interface FormBoxProps<T = any> {
  title: string;
  form?: FormInstance;
  initialValues?: T;
  children: React.ReactNode;
}

const { Title } = Typography;

export function FormBox<T>(props: FormBoxProps<T>) {
  const { children, form, title, initialValues } = props;

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <StyledTitle>
        <Title level={3}>{title}</Title>
      </StyledTitle>
      <StyledFormWrapper>
        <StyledVerticalLine />
        <StyledForm>
          {children}
        </StyledForm>
      </StyledFormWrapper>
    </Form>
  )
}