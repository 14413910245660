import React from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbLink } from './styles';

type Items = {
	id: number;
	to: string;
	title: string;
}

interface IBreadCrumbProps {
	items: Items[];
}

export function Breadcrumb({ items }: IBreadCrumbProps) {
	return (
		<AntBreadcrumb>
			{items.map((item, idx: number) => (
				<AntBreadcrumb.Item
          key={item.id}
        >
          <BreadcrumbLink 
            to={item.to}
            style={ items.length-1===idx ? { fontWeight: 'bold' } : {}}
          >
            {item.title}
          </BreadcrumbLink>
				</AntBreadcrumb.Item>
			))}
			
		</AntBreadcrumb>
	);
}