
import React from 'react';
import { Select } from 'antd';
import { regioesPlanejamento } from '../../../utils/regioesplanejamento'

const RegiaoFilter = ({setSelectedRegiaoList}) => {

  const SelectRegiaoOptions = regioesPlanejamento.map(city => {
    return {
      value: city.regiao,
      label: city.regiao,
    };
  });
  
  const onSelectedRegiao = (value: string[]) => {
    setSelectedRegiaoList(value);
  };
  
  return (
    <div>
      <strong>Região de Planejamento</strong>
      <Select
        mode={'multiple'}
        placeholder="Todas as regiões"
        options={SelectRegiaoOptions}
        maxTagCount={1}
        onChange={onSelectedRegiao}
        allowClear
      />
  </div>
  );
};

export default RegiaoFilter;
