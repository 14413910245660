import React, { useEffect } from 'react';
import { Divider, Input, message, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

import {  
	Container, 
	Wrapper, 
	Section,
	WorkBox,
	WorkBoxTitle,
	WorkBoxButton,
	GeneralInfoBox,
	GeneralInfoBoxTitle,
	GeneralInfoBoxContent,
	GeneralInfoBoxSimpleText,
	HistoricBox,
	HistoricBoxTitle,
	HistoricInfoContainer,
	HistoricInfoNavigation,
	HistoricInfoYear,
	Content,
	CommentContainer,
	ButtonGroup,
	CancelButton,
	WorkBoxDescription,
} from "./styles";

import { 
  ArticulatorBreadcrumb, 
  currentSemester, 
  currentYear, 
  GoBack, 
  SimpleLoading, 
  VisitationMeta,
  ArticulatorComment
} from '../../components/Articulator';
import { CommentProps, MarkedVisitationPerFamily, RichedProfessional, WorkplanType } from 'types';
import { agentService, commentServices, visitService, workPlanService } from 'services';
import { Authorizer, Footer } from 'components';

const { TextArea } = Input;

const breadcrumbItems = [
  {
    id: 1,
    to: "/articulador-inicio",
    title: "Página inicial"
  },
  {
    id: 2,
    to: "/articulador-plano-de-trabalho",
    title: "Plano de Trabalho"
  },
];

export function ArticulatorWorkPlan() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;

	const [countLikes, setCountLikes] = useState(0);
  const [workPlans, setWorkPlans] = useState<WorkplanType[]>([]);
  const [isHistoricLoading, setIsHistoricLoading] = useState(true);
  const [agent, setAgent] = useState({} as RichedProfessional);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [isCommentButtonClicked, setIsCommentButtonClicked] = useState(false);
  const [currentWorkplan, setCurrentWorkplan] = useState<WorkplanType>();
  const [isWorkplanBoxLoading, setIsWorkplanBoxLoading] = useState(true);
  const [visitationData, setVisitationData] = useState<MarkedVisitationPerFamily[]>([]);

  const year = currentYear();
  const semester = currentSemester();

	const history = useHistory();

	function handleGoBack() {
		history.push('/articulador-inicio');
	}

  function handleNavigateToCreateWorkplan() {
    history.push('/articulador-cadastrar-plano-de-trabalho');
  }

  function handleCountLikes() {
		if(countLikes===0)
			setCountLikes(1);
		else
			setCountLikes(0);
	}

  async function handleGetApprovedOrRefusedHistoricWorkPlans(agentId: number) {
    setIsHistoricLoading(true);
    setIsWorkplanBoxLoading(true);

    try {
      const response = await workPlanService.getHistoricWorkPlans(agentId);

      setWorkPlans(response.filter(workplan => workplan.status!=='AWAITING'));    
    
      if(response.length > 0) {
        const awaitingOrApprovedWorkPlan = response.find(workplan => (workplan.status==='APPROVED' || workplan.status==='AWAITING') && workplan.year===year && workplan.semester===semester);

        if(awaitingOrApprovedWorkPlan) {
          setCurrentWorkplan(awaitingOrApprovedWorkPlan);
        } else {
          setCurrentWorkplan(response.find(workplan => workplan.year===year && workplan.semester===semester));
        }
      }
      
    } catch(err) {
      message.error('Erro a carregar histórico de planos de trabalho')
    } finally {
      setIsHistoricLoading(false);
      setIsWorkplanBoxLoading(false);
    }
  }

  async function handleCreateGeneralComment() {
    if(!comment.trim())
      return;

    setIsCommentButtonClicked(true);

    const data = { content: comment };

    try { 
      await commentServices.createCommentOnWorkplan(data);

      getGeneralCommentsAndObservation();

    } catch(err) {
      message.error('Não foi possível criar um comentário');
    }

    setIsCommentButtonClicked(false);
    setComment('');
  }
  
  function handleNavigateToWorkplanDetail(workplan: WorkplanType) {
    history.push(`/articulador-plano-de-trabalho-atual/${workplan.id}`, workplan);
  }

  async function getGeneralCommentsAndObservation() {
    try {
      const response = await commentServices.getGeneralCommentsAndObservation();

      setComments(response);
    } catch(err: any) {
      const errMsg = err.response.data.message;
      message.error(errMsg || 'Erro ao carregar comentários gerais')
    }
  }

  async function getAllVisitationDataOnAwaitingStatus(workplan: number) {
    try {
      const response = await visitService.getAllVisitationsDataExisting(workplan);
      
      setVisitationData(response);

    } catch(err) {
      message.error({ content: 'Erro ao buscar dados de visitação' });
    }
  }

  async function getCurrentWorkplanByAgentId(agentId: number): Promise<WorkplanType> {
    const response = await workPlanService.getHistoricWorkPlans(agentId);
    const firstWorkPlan = response.filter(workplan => workplan.year===year && workplan.semester===semester)[0];
    return firstWorkPlan;
  }

  useEffect(() => {
    let isMounted = true;
    agentService.getProfessional(userId)
      .then(res => {
        if(isMounted) {
          if(res) {
            const agentId = res.id;
            localStorage.setItem('agentId', String(agentId));
            setAgent(res);
            handleGetApprovedOrRefusedHistoricWorkPlans(agentId);

            getCurrentWorkplanByAgentId(agentId)
              .then(res => {
                if(res) {
                  const workplanId = res.id;
                  getAllVisitationDataOnAwaitingStatus(workplanId);
                }
              })
              .catch(err => message.error(err?.response?.data?.mensagem || 'Erro ao encontrar plano de trabalho com o ID atual!'));
          }
        }
      })
      .catch(err => message.error(err?.response?.data?.mensagem || 'Cliente logado não é um articulador')); 
    
    if(isMounted)
      getGeneralCommentsAndObservation();
    
    return () => {
      isMounted = false;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const AwaitingWorkplanBox = () => (
    currentWorkplan?.status==='AWAITING' ? (
      <>
        <WorkBoxDescription>
          O Plano cadastrado ainda não foi autorizado pelo supervisor.
        </WorkBoxDescription>
        <WorkBoxButton 
          type="button" 
          disabled  
        >
          ACESSAR PLANO
        </WorkBoxButton>
      </>
    ) : null
  );

  const ApprovedWorkplanBox = () => (
    currentWorkplan?.status==='APPROVED' ? (
      <>
        <WorkBoxDescription>
          Seu plano de trabalho para {year}.{semester} foi aprovado pelo supervisor.
        </WorkBoxDescription>
        <WorkBoxButton type="button" onClick={() => handleNavigateToWorkplanDetail(currentWorkplan)}>
          ACESSAR PLANO
        </WorkBoxButton>
      </>
    ) : null
  );

  const RefusedWorkplanBox = () => (
    currentWorkplan?.status==='REFUSED' ? (
      <>
        <WorkBoxDescription>
          O Plano cadastrado foi recusado pelo supervisor.
        </WorkBoxDescription>
        <WorkBoxButton type="button" onClick={handleNavigateToCreateWorkplan}>
          CADASTRAR PLANO
        </WorkBoxButton>
        <WorkBoxButton className="workplan-button-refused" type="button" onClick={() => handleNavigateToWorkplanDetail(currentWorkplan)}>
          ACESSAR PLANO
        </WorkBoxButton>
      </>
    ) : null
  );

  const NoCurrentWorkplanBox = () => (
    (!currentWorkplan && !isWorkplanBoxLoading) ? (
      <>
        <WorkBoxDescription>
          Ainda não há um plano de trabalho cadastrado para o semestre de {year}.{semester}
        </WorkBoxDescription>
        <WorkBoxButton type="button" onClick={handleNavigateToCreateWorkplan}>
          CADASTRAR PLANO
        </WorkBoxButton>
      </>
    ) : null
  );

  const Centered = ({ children, className="" }) => (
    <div
      className={className}
      style={{
        flex: 1,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center'
      }}
    >
      {children}
    </div>
  )

  const LoadingWorkplanBox = () => (isWorkplanBoxLoading ? <SimpleLoading tip="Carregando plano de trabalho atual" /> : null);

	return (
		<>
      <Container>
			
        <Content>
          <ArticulatorBreadcrumb 
            items={breadcrumbItems}
          />

          <Wrapper>
            <GoBack 
              onClick={handleGoBack}
              title="Plano de trabalho"
              subtitle="Relatórios semestrais"
            />
            <div 
              style={{
                width: 300,
                padding: '30px 40px',
              }}
            >
              
            </div>
            <Section>
              <WorkBox>
                <WorkBoxTitle>
                  Plano de trabalho atual
                </WorkBoxTitle>

                <LoadingWorkplanBox />
                <AwaitingWorkplanBox />
                <ApprovedWorkplanBox />
                <RefusedWorkplanBox />
                <NoCurrentWorkplanBox />
              </WorkBox>

              <GeneralInfoBox>
                <GeneralInfoBoxTitle>
                  Informações Gerais
                </GeneralInfoBoxTitle>

                <GeneralInfoBoxContent>
                  <GeneralInfoBoxSimpleText fontWeight="bold">
                    Município alocado
                  </GeneralInfoBoxSimpleText>

                  <GeneralInfoBoxSimpleText fontWeight="normal">
                    {agent?.city ?? "Sem informações"}
                  </GeneralInfoBoxSimpleText>

                  <GeneralInfoBoxSimpleText fontWeight="bold" className="separate-top">
                    Número mínimo de famílias a serem atendidas
                  </GeneralInfoBoxSimpleText>

                  <GeneralInfoBoxSimpleText fontWeight="normal">
                    {currentWorkplan?.numVisits ?? "Sem informações"}
                  </GeneralInfoBoxSimpleText>
                </GeneralInfoBoxContent>
              </GeneralInfoBox>
              
            
              <HistoricBox>
                <HistoricBoxTitle>
                  Histórico
                </HistoricBoxTitle>
                  {!isHistoricLoading ? 
                    (workPlans.map((historic, idx: number) => (
                      <div key={historic.id}>
                        <HistoricInfoContainer>
                          <HistoricInfoNavigation
                            className={historic.status==='REFUSED' ? 'refused' : ''}
                            type="button"
                            onClick={() => handleNavigateToWorkplanDetail(historic)}
                          >
                            <span>Plano de trabalho semestral</span>
                            <AiOutlineArrowRight size={24} color={historic.status==='REFUSED' ? '#ef4242' : '#33A02C'}/>
                          </HistoricInfoNavigation>
                          <HistoricInfoYear className={historic.status==='REFUSED' ? 'year-refused' : ''}>
                            {historic.year}.{historic.semester}
                          </HistoricInfoYear>


                        </HistoricInfoContainer>

                        {idx < (workPlans.length - 1) && <Divider/>}
                      </div>
                      
                    ))) : (
                      <Centered>
                        <SimpleLoading 
                          tip="Carregando histórico de planos de trabalho"
                        />
                      </Centered>
                  )}
                  {(!isHistoricLoading && workPlans.length === 0) && (
                    <Centered 
                      className="not-historic-articulator-content"
                    >
                      <span>Não há histórico de planos de trabalho</span>
                    </Centered>
                  )}
              </HistoricBox>

            </Section>
          </Wrapper>
        </Content>
        
        <CommentContainer>
          <div className="comment-wrapper">
            <ArticulatorComment 
              title="Observações e comentários gerais"
              comments={comments}
              countLikes={countLikes}
              onLike={handleCountLikes}
            />

            <TextArea 
              value={comment} 
              onChange={e => setComment(e.target.value)}
              onPressEnter={handleCreateGeneralComment}
              disabled={isCommentButtonClicked}
              style={{ cursor: 'text' }}
            />

            <ButtonGroup>
              <CancelButton type="reset" onClick={() => setComment('')}>
                Cancelar
              </CancelButton>

              <Button 
                type="primary" 
                onClick={handleCreateGeneralComment}
                className="comment-button"
                loading={isCommentButtonClicked}
                style={{
                  background: '#4B9F37',
                  border: 0
                }}>
                Comentar
              </Button>
            </ButtonGroup>
          </div>
        </CommentContainer>
      </Container>
      <Footer />
    </>
	);
}
