import React, { useEffect, useState } from 'react';
import { Descriptions } from 'antd';


import { Input, Modal } from 'antd';
import { useHistoryAcao } from 'utils/context/historyAcaoContext';
import { generetePDF } from 'utils/generetePDF';
import {
  IoDocumentTextSharp,
  IoNewspaperSharp,
  IoClose,
} from 'react-icons/io5';

import { generateXLSX } from 'utils/genereteXLSX';
import { organizeEventoPraiaAcessivel } from 'utils/organizeEventoPraiaAcessivel';
import { organizeEventoPublicacoes } from 'utils/organizeEventoPublicacoes';

import './styles.scss';
import moment from 'moment';

export default function ModalDownloadAcoes({ onOpenModal, infos, type }: any) {
  const {
    filterEvents,
    eventsListDownload,
    actionInfos,
    selectedTime,
    selectedCitiesList,
    selectedRegiaoList,
    selectedType,
    setEventsListDonwload,
    filtered,
    setFiltered,
    isDownloadPDFOrXLSX
  } = useHistoryAcao();

  const [tamanho, setTamanho] = useState(actionInfos.total);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [downloadXLSX, setDownloadXLSX] = useState(false);
  const [dateFilter, setDateFilter] = useState("");


  const isFilter = filtered && ( selectedTime?.length > 0 || selectedCitiesList?.length > 0  || selectedRegiaoList?.length > 0 || selectedType ) ? true : false

  const filtrarEventos = () => {
    filterEvents(infos.id, infos.nome, tamanho, true);
  };

  useEffect(()=> {

    let dataAtual: any = '';
    if(selectedTime?.length > 1) {
      let dataI = moment(new Date(selectedTime[0])).format('yyyy-MM');
      let dataF = moment(new Date(selectedTime[1])).format('yyyy-MM');
      dataAtual = dataI + ' até ' + dataF
      setDateFilter(dataAtual)
    }

    return() => {
      setFiltered(false);
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  
  useEffect(() => {
    if (eventsListDownload.length > 0 && eventsListDownload) {
      if (downloadPdf) {
        generetePDF(eventsListDownload, type, infos.nome);
        setEventsListDonwload([])
        setDownloadPdf(false);
      }
      if (downloadXLSX) {
        let newData = actionInfos.elementos;
        if (infos.nome === 'Praia Acessível') {
          if(eventsListDownload?.elementos){
            newData = organizeEventoPraiaAcessivel(eventsListDownload).elementos;
          }
        }
        if (infos.nome === 'Publicações') {
          if(eventsListDownload?.elementos){
            newData = organizeEventoPublicacoes(eventsListDownload).elementos;
          }
        }
        generateXLSX(newData, type, infos.nome);
        setDownloadXLSX(false);
      }
    }

    
  }, [eventsListDownload]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Modal
      visible={true}
      width={500}
      cancelText={false}
      okText={false}
      closable={false}
      zIndex={1000}
    >
      <div className="modal__downloads">
        <h1>DOWNLOAD</h1>
        <p>Total de registros disponíveis: {actionInfos.total}</p>
        {
          isFilter && 
          <Descriptions title="Filtros aplicados"  >
            { dateFilter && <Descriptions.Item label="Período">{dateFilter}</Descriptions.Item> } 
            { selectedRegiaoList?.length > 0 && <Descriptions.Item label="Região de Planejamento">{selectedRegiaoList.join(', ')}</Descriptions.Item> }
            { selectedCitiesList?.length > 0 && <Descriptions.Item label="Município">{selectedCitiesList.join(', ')}</Descriptions.Item> }
            { selectedType && <Descriptions.Item label="Tipo de registro">{selectedType}</Descriptions.Item> } 
          </Descriptions>
        }
       
        <div className="modal__downloads-input">
          <label>Quantos registros deseja baixar?</label>

          <Input
            type="number"
            value={tamanho}
            max={actionInfos.total}
            min={1}
            onChange={event => {
               setTamanho(
                Number(event.target.value) >= actionInfos.total ? 
                actionInfos.total : Number(event.target.value) 
              )}
            } 
          />
        </div>
        <div className="line-horizontal">
          <div className="lines" />
        </div>
        <div className="modal__downloads-buttons">
          <button
            disabled={isDownloadPDFOrXLSX && downloadPdf}
            onClick={() => (filtrarEventos(), setDownloadPdf(true))} // eslint-disable-line no-sequences
          >
            <IoNewspaperSharp color="#fff" size={17} />
            <p>Baixar PDF </p>
          </button>

          {/*     <CSVLink
          //     filename={`${infos.nome}.csv`}
          //     data={eventsListDownload}
          //     className="btn btn-primary"
          //   >
          //     <FaFileCsv color="var(--color-primary)" size={17} />
          //     <p>Baixar CSV </p>
          //   </CSVLink> */}

            <button
              disabled={isDownloadPDFOrXLSX && downloadXLSX}
              onClick={() => (filtrarEventos(), setDownloadXLSX(true))} // eslint-disable-line no-sequences
            >
            <IoDocumentTextSharp color="#fff" size={17} />
            <p>Baixar XLSX </p>
          </button>
        </div>

        <button
          
          className="modal__downloads-close"
          onClick={() => onOpenModal(false)}
        >
          <IoClose size={20} />
        </button>
      </div>
    </Modal>
  );
}

