import moment from 'moment';
import { api } from 'services/api';

export function getDataTimelineAno(ano) {
  const response = api
    .get(`timeline?ano=${ano}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getResumeTimeline() {
  const response = api
    .get('/timeline/resume')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getYear() {
  const response = api
    .get('timeline/anos')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}
export function getYearAll(data) {
  const response = api
    .get('/timeline', {
      params: {
        data: data === "" ? `2015-01;${moment([]).format('yyyy-MM')}`: data, 
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}
