import React from 'react';
import { FormInstance } from 'antd';
import { FormContainer } from './style'
import { ConfirmAction } from './LocFormConfirm';
import { ConstValuesFormConfirm } from './ConstFormConfirm'
import { InfoFormConfirm } from '../FormResponsavelConfirm/InfoFormConfirm'
import { ActionValuesConfirm } from './ActionValuesConfirm'
import { PraiaAcessivelConfirm } from './PraiaAcessivelConfirm'
import { NucleoFormConfirm } from './NucleoFormConfirm'


interface FormProps {
  form?: FormInstance;
  action?: any
}

interface PraiaProp {
  form?: FormInstance;
  data: any
}

export function ConfirmFormValues({ form }: FormProps) {
  return (
    <FormContainer>
      <ConfirmAction form={form} />
    </FormContainer>
  );
};

ConfirmFormValues.ConstValuesFormConfirm = function ({ form }: FormProps) {
  return (
    <FormContainer>
      <ConstValuesFormConfirm form={form} />
    </FormContainer>
  );
}

ConfirmFormValues.ActionValuesConfirm = function ({ form, action }: FormProps) {
  return (
    <FormContainer>
      <ActionValuesConfirm form={form} action={action}/>
    </FormContainer>
  );
}

ConfirmFormValues.InfoFormConfirm = function({form}: FormProps) {
  return (
    <FormContainer>
      <InfoFormConfirm form={form} />
    </FormContainer>
  );
}

ConfirmFormValues.PraiaAcessivelConfirm = function({form, data}: PraiaProp) {
  return (
    <FormContainer>
      <PraiaAcessivelConfirm form={form} data={data}/>
    </FormContainer>
  );
}

ConfirmFormValues.NucleoFormConfirm = function({form, data}: PraiaProp) {
  return (
    <FormContainer>
      <NucleoFormConfirm form={form} data={data}/>
    </FormContainer>
  );
}