import XLSX from 'xlsx';

import { formattedStringKey } from 'utils/strings';

export function titleTable(data, atualDados) {

  for (let key in data) {
    if (
      key === 'classe' ||
      key === 'ultimaModificacao' ||
      key === 'id' ||
      // key === 'tipo' ||
      key === 'anexos' ||
      key === 'ultimoEditor' ||
      key === 'arquivosRegistro'
    )
      continue;
    else {
   
      if (Array.isArray(data[key])) {
        if(typeof data[key][0] === 'string')atualDados.push(formattedStringKey(key));
        else continue
      } 
      else if (
        typeof data[key] === 'object' &&
        Array.isArray(data[key]) === false
      )
        titleTable(data[key], atualDados);
      else atualDados.push(formattedStringKey(key));
    }
  }
}

export function formatXLSXgeral(data, dataArray, linha) {
  for (let key in data) {
    if (
      key === 'classe' ||
      key === 'ultimaModificacao' ||
      key === 'id' ||
      key === 'anexos' ||
      // key === 'tipo' ||
      key === 'ultimoEditor' ||
      key === 'arquivosRegistro' 
    )
      continue;
    else {
      if (key.includes('dataComemorativa'))
      { 
        linha.push(data[key] ? 'Sim' : 'Não');
      }
      else if (Array.isArray(data[key])) {
        if ( typeof data[key][0] === 'string' ) linha.push(data[key].join(', '));
        else dataArray.push(data[key]);

      } else if (
        typeof data[key] === 'object' &&
        Array.isArray(data[key]) === false
      )
        formatXLSXgeral(data[key], dataArray, linha);
      else {
        if (key.includes('data')) {
          linha.push(new Date(data[key]).toLocaleDateString('fr-FR'));
        } else {
          linha.push(data[key]);
        }
      }
    }
  }
}



export function generalArrayFormatXLSX(data, workbook) {
  let dataArray: any = []
  let linha: any = []
  let dados: any = []
  let size: boolean = true
  let namePage: string = `Página 1`


  titleTable(data[0], linha)
  dados.push(linha)

  workbook.SheetNames.push(
    namePage
  );

  data.forEach((dado, index: number) => {
    linha = []
    formatXLSXgeral(dado, dataArray, linha);
   
    dados.push(linha)

    if(dataArray.length > 0) {
      size = false

      if(index+1 !== 1 ) {
        namePage = `Página ${index+1}`
        workbook.SheetNames.push(
          namePage  
        );
      }
    
      dataArray.forEach(dado => {
        dados.push([])
        linha = []
        titleTable(dado[0], linha)
        dados.push(linha)

        dado.forEach(linhaArray => {
          linha = []
          formatXLSXgeral(linhaArray, dataArray, linha)
          dados.push(linha)
        })
      });

      let content = XLSX.utils.aoa_to_sheet(dados);
      workbook.Sheets[
        namePage
      ] = content;

      dados = [];
      linha = [];
      
      titleTable(data[0], linha)
      dados.push(linha)

    }

    dataArray = []
  })

  if(size) {
    let content = XLSX.utils.aoa_to_sheet(dados);
    workbook.Sheets[
      `Página 1`
    ] = content;

  }

  return workbook;
  /* formatXLSXgeral(data[1], workbook) */

}