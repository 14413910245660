import { GeoJSON } from 'react-leaflet';

import React, { useEffect, useState } from 'react';

import { MapContainer } from 'react-leaflet';
import cearaGeojson from '../../data/ceara-full.json';
import './styles.scss';

import GeoJsonCMIC from 'components/GeoJsonCMIC';
import { Device } from 'components/Device';
import { getInfosMapCMICBds } from 'services/resources/cmicService';

const MapCartaoCMIC = () => {
  const [dadosMapa, setDadosMapa] = useState<any>([]);

  const device = Device.useDevice();

  useEffect(() => {
    const response = async () => {
      const data = await getInfosMapCMICBds();

      if(data){
        const mapDate = cearaGeojson.features.map(feature => {
          let currentDate = [];
          data.forEach(element => {
            if(feature.properties.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() === element.municipio.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() ){
              currentDate = { ...feature, ...element}
            }
          })
          return {...currentDate};
        });
        setDadosMapa(mapDate);
      }
    
    };
    response();
  }, []);
 

  const defaultZoom = device.isMD('bigger') ? 7.5 : ( device.isSM('exclusive') ? 7.1 : 6.8 )

  const positionLng = device.isMD('bigger') ? -5.3 : ( device.isSM('exclusive') ? -5.9 : -6.7 )



  const serviceMapStyle = {
    color: '#000000',
    weight: 0.3,
    opacity: 1,
    fillColor: '#ADC4A3',
    fillOpacity: 1,
  };

  return (
    <MapContainer
        className="map-cartao"
        center={[positionLng, -39.45]}
        zoom={defaultZoom}
        minZoom={defaultZoom}
        maxZoom={defaultZoom}
        zoomControl={false}
        dragging={false}
        inertia={false}
        doubleClickZoom={false}
        attributionControl={false}
        scrollWheelZoom={false}
        preferCanvas
      >
        <GeoJSON data={cearaGeojson as any} style={serviceMapStyle}/>
        {dadosMapa?.map((feature, i) => {
          return <GeoJsonCMIC key={i} data={feature} porc={feature.quantidade} />;
        })}
    </MapContainer>
  );
};

export default MapCartaoCMIC;
