import axios from 'axios';
import { citizenByUserApi, authApi } from '../api'
import { AuthMe, AuthGenerate } from '../../types';

export const IRIS_SOCIAL_TOKEN = `${process.env.REACT_APP_IRIS_SOCIAL_KEY}_token`;
export const GRANT_TOKEN = `grant_token`;
export const CITIZEN_TOKEN = `${process.env.REACT_APP_CITIZEN_KEY}_token`;

export const delay = (amount = 750) => new Promise(resolve => setTimeout(resolve, amount))

const service = () => {
  const resource = '/auth';

  async function me(intercept: boolean = true) {
    try {
      const api = intercept ? citizenByUserApi : authApi;

      const response = await api.get<AuthMe>(`${resource}/me`, {
        headers: {
          'Accept-Client': process.env.REACT_APP_IRIS_SOCIAL_KEY,
          'Application-Token': 'iris-social',
        }
      });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function logout() {
    try {

      const response = await axios.delete<any>(`${process.env.REACT_APP_CITIZEN_URL}${resource}`, {
        headers: {
          'Accept-Client': process.env.REACT_APP_IRIS_SOCIAL_KEY,
          'Authorization': localStorage.getItem(GRANT_TOKEN)
        }
      });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function generate(grantToken: string | null, appServerKey: string) {
    try {
      if (grantToken === null) {
        return Promise.reject(new Error('Grant token doesn\'t exist'));
      }

      const url = process.env.REACT_APP_CITIZEN_URL;
      const response = await axios
        .post<AuthGenerate>(`${url}/auth/generate`, {
        token: grantToken,
        appServerKey,
      });

      return response.data.token;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    me,
    generate,
    logout
  };
};

export const authService = service();
