import React from 'react'
import {
  Form,
  FormInstance,
  Input,
  Radio,
  DatePicker,
  Row, Col
} from 'antd';
import { FormBox } from '../../UI/index';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {InputMoney} from '../../UI'

const { TextArea } = Input

interface CurrentSituationEditProps {
  form?: FormInstance;
}

const { Item } = Form;

export function CurrentSituation({ form }: CurrentSituationEditProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  return (
    <FormBox title="Situação da Obra" form={currentForm}>
      <Item
        label="Status"
        name="statusObra"
        rules={[
          {
            required: true,
            message: 'Status não preenchido'
          }
        ]}
      >
      <Radio.Group>
        <Row>
          <Radio value="Em andamento"><span>Em andamento</span></Radio>
        </Row>
        <Row>
          <Radio value="Inaugurada"><span>Inaugurada</span></Radio>
        </Row>
        <Row>
          <Radio value="Concluida"><span>Concluido</span></Radio>
        </Row>
      </Radio.Group>
    </Item>
    <Item
      label="Data de Início"
      name="dataInicio"
      wrapperCol={{ span: 6 }}
      rules={[
        {
          required: true,
          message: 'Data não preenchido'
        }]}>
        <DatePicker  locale={locale}/>
    </Item>
    <Item
      label="Data do Fim Previsto"
      name="dataFimPrevisto"
      wrapperCol={{ span: 6 }}
      rules={[
        {
          required: true,
          message: 'Data não preenchida'
        }
      ]}>
        <DatePicker  locale={locale}/>
    </Item>
    <Item
      label="Data de Ordem de Serviço"
      name="dataOrdemServico"
      wrapperCol={{ span: 6 }}
      rules={[
        {
          required: true,
          message: 'Data não preenchida'
        }]}>
        <DatePicker  locale={locale}/>
    </Item>
    <Row>
        <Col span={4}>
          <Item
            label="Valor Investido"
            name="investido">
            <InputMoney />
          </Item>
        </Col>

        <Col span={4} offset={1}>
          <Item
            label="Valor Aditivo"
            name="aditivo">
            <InputMoney />
          </Item>
        </Col>
    </Row>
    <Item
      label="Observação"
      name="observacao">
      <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 6 }}/>
    </Item>
  </FormBox>
  );
};
