import { makeOptitionsCitiesCurrentCREDES } from "utils/makeOptionsCREDES";
import { makeOptitionsCitiesCurrentPOLOS } from "utils/makeOptionsPolos";
import { formattedStringKey } from "utils/strings";

export function formaterJSONCACS(dataPost) {

  const porTipoList: Array<{}> = [];
  const porQtdProfissionaisCapacitadosList: Array<{}> = [];

  const profissionaisCapacitados = {
    ...dataPost.capacitacaoAgentesComunitariosSaude[
    'profissionaisCapacitados/'
    ],
  };
  delete dataPost.capacitacaoAgentesComunitariosSaude[
    'profissionaisCapacitados/'
  ];

  const newMN: any = [{}];

  Object.keys(profissionaisCapacitados).forEach(key => {
    if (key.includes('tipo')) {
      porQtdProfissionaisCapacitadosList.push(profissionaisCapacitados[key]);
    } else if (key.includes('qtdProfissionaisCapacitados')) {
      porTipoList.push(profissionaisCapacitados[key]);
    }
  });

  for (let i = 0; i < porTipoList.length; i++) {
    newMN.push({
      tipo: porQtdProfissionaisCapacitadosList[i],
      qtdProfissionaisCapacitados: porTipoList[i],
    });
  }

  newMN.splice(0, 1);

  dataPost.capacitacaoAgentesComunitariosSaude['profissionaisCapacitados'] = [
    ...newMN,
  ];

  dataPost.capacitacaoAgentesComunitariosSaude['periodo'] = {
    dataInicio: dataPost.capacitacaoAgentesComunitariosSaude.dataInicio,
    dataFim: dataPost.capacitacaoAgentesComunitariosSaude.dataFim
  }

  delete dataPost.capacitacaoAgentesComunitariosSaude.dataInicio;
  delete dataPost.capacitacaoAgentesComunitariosSaude.dataFim;

  return dataPost;
}

export function formaterJSONMaisNutricao(dataPost) {
  /*  entidadeMunicipios */
  const porMunicipioList: Array<{}> = [];
  const porEntidadeList: Array<{}> = [];

  const entidadeMunicipios = {
    ...dataPost.maisNutricao.maisNutricaoDoacao['entidadeMunicipios/'],
  };
  delete dataPost.maisNutricao.maisNutricaoDoacao['entidadeMunicipios/'];

  const newMN: any = [{}];

  Object.keys(entidadeMunicipios).forEach(key => {
    if (key.includes('qtdEntidades')) {
      porEntidadeList.push(entidadeMunicipios[key]);
    } else if (key.includes('municipio')) {
      porMunicipioList.push(entidadeMunicipios[key]);
    }
  });

  for (let i = 0; i < porMunicipioList.length; i++) {
    newMN.push({
      qtdEntidades: porEntidadeList[i],
      municipio: porMunicipioList[i],
    });
  }

  newMN.splice(0, 1);

  dataPost.maisNutricao.maisNutricaoDoacao['entidadeMunicipios'] = [...newMN];

  dataPost.maisNutricao.maisNutricaoDoacao['periodoSemanal'] = {
    dataInicio: dataPost.maisNutricao.dataInicio,
    dataFim: dataPost.maisNutricao.dataFim
  }

  delete dataPost.maisNutricao.dataInicio;
  delete dataPost.maisNutricao.dataFim;

  return dataPost;
}

export function formaterJSONnep(dataPost) {

  /* formacoes */
  const porFormacaoList: Array<{}> = [];
  const porQuantidadeList: Array<{}> = [];

  /* diagnosticos */
  const porNomeList: Array<{}> = [];
  const porQtdAtendimentosList: Array<{}> = [];

  /* formacoes */
  const formacoes = { ...dataPost.nucleoEstimulacaoPrecoce['formacoes/'] };
  delete dataPost.nucleoEstimulacaoPrecoce['formacoes/'];

  const newNEPform: any = [{}];

  /* diagnosticos */
  const diagnosticos = { ...dataPost.nucleoEstimulacaoPrecoce['diagnostico/'] };
  delete dataPost.nucleoEstimulacaoPrecoce['diagnostico/'];

  const newNEPdiag: any = [{}];

  /* formacoes */
  Object.keys(formacoes).forEach(key => {
    if (key.includes('formacao')) {
      porQuantidadeList.push(formacoes[key]);
    } else if (key.includes('quantidade')) {
      porFormacaoList.push(formacoes[key]);
    }
  });

  for (let i = 0; i < porFormacaoList.length; i++) {
    newNEPform.push({
      formacao: porQuantidadeList[i],
      quantidade: porFormacaoList[i],
    });
  }
  newNEPform.splice(0, 1);

  /* diagnosticos */
  Object.keys(diagnosticos).forEach(key => {
    if (key.includes('qtdAtendimentos')) {
      porQtdAtendimentosList.push(diagnosticos[key]);
    } else if (key.includes('nomeDiagnostico')) {
      porNomeList.push(diagnosticos[key]);
    }
  });

  for (let i = 0; i < porNomeList.length; i++) {
    newNEPdiag.push({
      qtdAtendimentos: porQtdAtendimentosList[i],
      nomeDiagnostico: porNomeList[i],
    });
  }
  newNEPdiag.splice(0, 1);

  dataPost.nucleoEstimulacaoPrecoce['formacoes'] = [...newNEPform];
  dataPost.nucleoEstimulacaoPrecoce['diagnostico'] = [...newNEPdiag];
  return dataPost;
}

export function formaterJSONsda(dataPost) {

  const porMunicipioList: Array<{}> = [];

  const porCapacitacoesList: Array<{}> = [];
  const porProfFormadosList: Array<{}> = [];
  const porGestantesBeneList: Array<{}> = [];
  const porRegiao: Array<{}> = [];


  const porRegiaoGeral = { ...dataPost.sementesDoAmanha['porRegiao/'] };
  delete dataPost.sementesDoAmanha['porRegiao/'];

  const newNEP: any = [{}];

  Object.keys(porRegiaoGeral).forEach(key => {
    if (key.includes('municipiosParticipantes')) {
      porMunicipioList.push(porRegiaoGeral[key]);
    } else if (key.includes('qtdCapacitacoes')) {
      porCapacitacoesList.push(porRegiaoGeral[key]);
    } else if (key.includes('qtdProfissionaisFormados')) {
      porProfFormadosList.push(porRegiaoGeral[key]);
    } else if (key.includes('qtdGestantesPuerperasBeneficiadas')) {
      porGestantesBeneList.push(porRegiaoGeral[key]);
    } else if (key.includes('regiao')){
      porRegiao.push(porRegiaoGeral[key]);
    }
  });

  for (let i = 0; i < porRegiao.length; i++) {
    newNEP.push({
      municipiosParticipantes: porMunicipioList[i],
      qtdCapacitacoes: porCapacitacoesList[i],
      qtdProfissionaisFormados: porProfFormadosList[i],
      qtdGestantesPuerperasBeneficiadas: porGestantesBeneList[i],
      regiao: porRegiao[i]
    });
  }
  newNEP.splice(0, 1);

  dataPost.sementesDoAmanha['porRegiao'] = [...newNEP];
  dataPost.sementesDoAmanha['periodo'] = {
    dataInicio: dataPost.sementesDoAmanha.dataInicio,
    dataFim: dataPost.sementesDoAmanha.dataFim
  }

  delete dataPost.sementesDoAmanha.dataInicio;
  delete dataPost.sementesDoAmanha.dataFim;


  return dataPost;
}

export function formaterJSONpf(dataPost) {
  /*  aqui */

  const porMunicipioList: Array<{}> = [];
  const porFamiliasBeneficiadasList: Array<{}> = [];
  const porCapacitacoesList: Array<{}> = [];
  const porProfFormadosList: Array<{}> = [];
  const porRegiao: Array<{}> = [];

  const porRegiaoGeral = { ...dataPost.prevencaoEmFamilia['porRegiao/'] };
  delete dataPost.prevencaoEmFamilia['porRegiao/'];

  const newNEP: any = [{}];

  Object.keys(porRegiaoGeral).forEach(key => {
    if (key.includes('municipiosParticipantes')) {
      porMunicipioList.push(porRegiaoGeral[key]);
    } else if (key.includes('qtdFamiliaresBeneficiados')) {
      porFamiliasBeneficiadasList.push(porRegiaoGeral[key]);
    } else if (key.includes('qtdCapacitacoes')) {
      porCapacitacoesList.push(porRegiaoGeral[key]);
    } else if (key.includes('qtdProfissionaisFormados')) {
      porProfFormadosList.push(porRegiaoGeral[key]);
    } else if (key.includes('regiao')){
      porRegiao.push(porRegiaoGeral[key]);
    }
  });

  for (let i = 0; i < porRegiao.length; i++) {
    newNEP.push({
      municipiosParticipantes: porMunicipioList[i],
      qtdFamiliaresBeneficiados: porFamiliasBeneficiadasList[i],
      qtdCapacitacoes: porCapacitacoesList[i],
      qtdProfissionaisFormados: porProfFormadosList[i],
      regiao: porRegiao[i]
    });
  }
  newNEP.splice(0, 1);

  dataPost.prevencaoEmFamilia['porRegiao'] = [...newNEP];
  dataPost.prevencaoEmFamilia['periodo'] = {
    dataInicio: dataPost.prevencaoEmFamilia.dataInicio,
    dataFim: dataPost.prevencaoEmFamilia.dataFim
  }

  delete dataPost.prevencaoEmFamilia.dataInicio;
  delete dataPost.prevencaoEmFamilia.dataFim;


  return dataPost;
}

export function formaterJSONcapacitacao(dataPost) {

  const porMunicipioList: Array<{}> = [];
  const porFamiliasBeneficiadasList: Array<{}> = [];
  const porCapacitacoesList: Array<{}> = [];

  const porMunicipioGeral = {
    ...dataPost.capacitacao['porMunicipioCapacitacoes/'],
  };
  delete dataPost.capacitacao['porMunicipioCapacitacoes/'];

  const newNEP: any = [{}];

  Object.keys(porMunicipioGeral).forEach(key => {
    if (key.includes('nomeMunicipio')) {
      porMunicipioList.push(porMunicipioGeral[key]);
    } else if (key.includes('numeroCapacitacoes')) {
      porFamiliasBeneficiadasList.push(porMunicipioGeral[key]);
    } else if (key.includes('numeroPessoasFormadas')) {
      porCapacitacoesList.push(porMunicipioGeral[key]);
    }
  });

  for (let i = 0; i < porMunicipioList.length; i++) {
    newNEP.push({
      nomeMunicipio: porMunicipioList[i],
      numeroCapacitacoes: porFamiliasBeneficiadasList[i],
      numeroPessoasFormadas: porCapacitacoesList[i],
    });
  }
  newNEP.splice(0, 1);

  dataPost.capacitacao['porMunicipioCapacitacoes'] = [...newNEP];

  dataPost.capacitacao['periodo'] = {
    dataInicio: dataPost.capacitacao.dataInicio,
    dataFim: dataPost.capacitacao.dataFim
  }


  delete dataPost.capacitacao.dataInicio;
  delete dataPost.capacitacao.dataFim;

  return dataPost;
}
export function formaterJSONfs(dataPost) {

  const porDataInicioList: Array<{}> = [];
  const porDataFimList: Array<{}> = [];
  const porConteudoProgramaticoList: Array<{}> = [];
  const porParticipantesPorPerfilList: Array<{}> = [];
  const porCargaHorariaList: Array<{}> = [];
  const porPolosList: Array<{}> = [];

  const porDatasCiclo = { ...dataPost.formacaoSeduc }
  const porCiclosSeducGeral = { ...dataPost.formacaoSeduc['ciclosSeduc/'] };
  delete dataPost.formacaoSeduc['ciclosSeduc/'];

  const newNEP: any = [{}];
  Object.keys(porDatasCiclo).forEach((key, i) => {
    if (key.includes('dataInicio')) {
      porDataInicioList.push(porDatasCiclo[key]);
      delete dataPost.formacaoSeduc[key]
    } else if (key.includes('dataFim')) {
      porDataFimList.push(porDatasCiclo[key]);
      delete dataPost.formacaoSeduc[key]
    }
  });

  Object.keys(porCiclosSeducGeral).forEach((key, i) => {
    /*     if (key.includes('dataInicio')) {
          
          porDataInicioList.push(porCiclosSeducGeral[key]);
        } else if(key.includes('dataFim')){
          porDataFimList.push(porCiclosSeducGeral[key]);
        }else */
    if (key.includes('conteudoProgramatico')) {
      porConteudoProgramaticoList.push(porCiclosSeducGeral[key]);
    } else if (key.includes('participantesPorPerfil')) {
      porParticipantesPorPerfilList.push(porCiclosSeducGeral[key]);
    } else if (key.includes('cargaHoraria')) {
      porCargaHorariaList.push(porCiclosSeducGeral[key]);
    } else if (key.includes('municipios')) {
      /*       temos que transformar a lista de lista em 1 lista so aqui */
      const arrayMuni = makeOptitionsCitiesCurrentCREDES(porCiclosSeducGeral[key]);
      porPolosList.push(arrayMuni);
    }
  });

  for (let i = 0; i < porParticipantesPorPerfilList.length; i++) {
    newNEP.push({
      periodo: {
        dataInicio: porDataInicioList[i],
        dataFim: porDataFimList[i]
      },
      conteudoProgramatico: porConteudoProgramaticoList[i],
      participantesPorPerfil: porParticipantesPorPerfilList[i],
      cargaHoraria: porCargaHorariaList[i],
      municipios: porPolosList[i],
    });
  }

  newNEP.splice(0, 1);

  dataPost.formacaoSeduc['ciclosSeduc'] = [...newNEP];

  return dataPost;
}


export function formaterJSONsimic(dataPost) {
  dataPost.seminarioMaisInfancia['periodo'] = {
    dataInicio: dataPost.seminarioMaisInfancia.dataInicio,
    dataFim: dataPost.seminarioMaisInfancia.dataFim
  }

  delete dataPost.seminarioMaisInfancia.dataInicio;
  delete dataPost.seminarioMaisInfancia.dataFim;

  return dataPost;
}

export function formaterJSONcf(dataPost) {

  if (dataPost.criancaFeliz.registroCapacitacao || dataPost.criancaFeliz.registroAcumulado) {
    if (dataPost.criancaFeliz.registroAcumulado) {
      dataPost.criancaFeliz.registroAcumulado['periodo'] = {
        dataInicio: dataPost.criancaFeliz.dataInicio,
        dataFim: dataPost.criancaFeliz.dataFim,
      }
      delete dataPost.criancaFeliz.dataInicio;
      delete dataPost.criancaFeliz.dataFim;
    } else if (dataPost.criancaFeliz.registroCapacitacao) {
      dataPost.criancaFeliz.registroCapacitacao['periodo'] = {
        dataInicio: dataPost.criancaFeliz.dataInicio,
        dataFim: dataPost.criancaFeliz.dataFim,
      }
    }

    delete dataPost.criancaFeliz.dataInicio;
    delete dataPost.criancaFeliz.dataFim;
  }


  return dataPost;
}

export function formaterJSONpadin(dataPost) {

  if (dataPost.padin.formacao) {

    const porDataInicioList: Array<{}> = [];
    const porDataFimList: Array<{}> = [];
    const porConteudoProgramaticoList: Array<{}> = [];

    const porCargaHorariaList: Array<{}> = [];
    const porPolosList: Array<{}> = [];
    const porQtdFormadores: Array<{}> = [];
    const porQtdArticuladores: Array<{}> = [];
    const porQtdTecnicosSeduc: Array<{}> = [];
    const porQtdTecnicosCrede: Array<{}> = [];
    const porQtdSupervisores: Array<{}> = [];
    const porQtdAdis: Array<{}> = [];


    const porDatasCiclo = { ...dataPost.padin }
    const porCiclosPadinGeral = { ...dataPost.padin.formacao['ciclosPadin/'] };
    delete dataPost.padin.formacao['ciclosPadin/'];



    Object.keys(porDatasCiclo).forEach((key, i) => {
      if (key.includes('dataInicio')) {
        porDataInicioList.push(porDatasCiclo[key]);
        delete dataPost.padin[key]
      } else if (key.includes('dataFim')) {
        porDataFimList.push(porDatasCiclo[key]);
        delete dataPost.padin[key]
      }
    });

    const newNEP: any = [{}];


    Object.keys(porCiclosPadinGeral).forEach((key, i) => {
      if (key.includes('conteudoProgramatico')) {
        porConteudoProgramaticoList.push(porCiclosPadinGeral[key]);
      } else if (key.includes('cargaHoraria')) {
        porCargaHorariaList.push(porCiclosPadinGeral[key]);
      } else if (key.includes('polos')) {
        porPolosList.push(porCiclosPadinGeral[key]);
      }
      else if (key.includes('qtdFormadores')) {
        porQtdFormadores.push(porCiclosPadinGeral[key]);
      }
      else if (key.includes('qtdArticuladoresRegionaisMaisInfancia')) {
        porQtdArticuladores.push(porCiclosPadinGeral[key]);
      }
      else if (key.includes('qtdTecnicosSeduc')) {
        porQtdTecnicosSeduc.push(porCiclosPadinGeral[key]);
      }
      else if (key.includes('qtdTecnicosCrede')) {
        porQtdTecnicosCrede.push(porCiclosPadinGeral[key]);
      }
      else if (key.includes('qtdSupervisores')) {
        porQtdSupervisores.push(porCiclosPadinGeral[key]);
      }
      else if (key.includes('qtdAdis')) {
        porQtdAdis.push(porCiclosPadinGeral[key]);
      }
    });



    for (let i = 0; i < porConteudoProgramaticoList.length; i++) {
      newNEP.push({
        periodo: {
          dataInicio: porDataInicioList[i],
          dataFim: porDataFimList[i]
        },
        conteudoProgramatico: porConteudoProgramaticoList[i],
        cargaHoraria: porCargaHorariaList[i],
        polos: makeOptitionsCitiesCurrentPOLOS(porPolosList[i]),
        qtdFormadores: porQtdFormadores[i],
        qtdArticuladoresRegionaisMaisInfancia: porQtdArticuladores[i],
        qtdTecnicosSeduc: porQtdTecnicosSeduc[i],
        qtdTecnicosCrede: porQtdTecnicosCrede[i],
        qtdSupervisores: porQtdSupervisores[i],
        qtdAdis: porQtdAdis[i]
      });
    }

    newNEP.splice(0, 1);


    dataPost.padin.formacao['ciclosPadin'] = [...newNEP];

  }

  return dataPost;
}



export function compararObjetos(objA, objB) {
  for (let key in objB) {

    if (typeof objA[key] === 'object' && Array.isArray(objA[key]) === false) {
      if (compararObjetos(objA[key], objB[key]) === false) return false;
    } else if (Array.isArray(objB[key])) {
      if (objB[key].length !== objA[key].length) {
        return false;
      } else {
        for (let i = 0; i < objB[key].length; i++)
          if (compararObjetos(objA[key][i], objB[key][i]) === false)
            return false;
      }
    } else {
      if(objA[key] && objB[key]) {
        if (objB[key]['_isAMomentObject']) {
          if (
            new Date(objA[key]).toLocaleDateString('pt-BR') !==
            objB[key]['_d'].toLocaleDateString('pt-BR')
          ) {
            return false;
          }
        } else {
          if (objB[key] !== objA[key] ) {
            return false;
          }
        }
      } 
      else if (objA[key] && !objB[key]) return false
      else if (!objA[key] && objB[key]) return false
    }
  }

  return true;
}

function percorrerObj(obj: any, objFinal){
  for(let key in obj) {
    if (key === 'arquivosRegistro' ||
        key === 'ultimaModificacao' ||
        key === 'ultimoEditor' ||
        key === 'classe' ||
        key === 'id' 
    ) continue
    else {
      if (typeof obj[key] === 'object' && Array.isArray(obj[key]) === false) percorrerObj(obj[key], objFinal)
      else if(Array.isArray(obj[key])) {
        if(typeof obj[key][0] === 'string') objFinal[`${key}`] = formattedStringKey(key)
        else percorrerObj(obj[key][0], objFinal)
      }
      else objFinal[`${key}`] = formattedStringKey(key)
    }
  }
}


export function extractKeys(obj: any, objFinal){
  obj.forEach(value => {
    value.eventos.forEach(objeto => {
      percorrerObj(objeto, objFinal)
    })
  })
}