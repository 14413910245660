import { Col, Form, Input, Row, Select, Typography } from 'antd'
import React from 'react'
import { StyledForm, StyledFormWrapper, StyledTitle, StyledVerticalLine } from 'components/UI/FormBox/styles';
import citiesCe from '../../../../data/citiesIBGE.json'


const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;

export function GestorDataFormEdit() {
    return (
        <>
            <StyledTitle>
                <Title level={3}>Dados Profissionais</Title>
            </StyledTitle>
            <StyledFormWrapper>
                <StyledVerticalLine />
                <StyledForm>
                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                            <Item label="Município" name="city">
                                <Select bordered={false} disabled >
                                    {citiesCe.map((city, i) => {
                                        return <Option key={i} value={`${city.municipio};${city.ibge}`}>{city.municipio}</Option>
                                    })}
                                </Select>
                            </Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                            <Item label="Profissão" name="occupation">
                                <Input bordered={false} disabled/>
                            </Item>
                        </Col>
                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                            <Item label="Setor vinculado" name="sector">
                                <Input bordered={false} disabled/>
                            </Item>
                        </Col>
                    </Row>

                    
                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                            <Item label="Orgão" name="agency">
                                <Input bordered={false} disabled/>
                            </Item>
                        </Col>

                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                            <Item label="Competência do órgão " name="competence" >
                                <Select bordered={false} disabled>
                                    <Option value="STATE">Competência Estadual</Option>
                                    <Option value="CITY">Competência Municipal</Option>
                                </Select>
                            </Item>
                        </Col>
                    </Row>

                </StyledForm>
            </StyledFormWrapper>
        </>
    )
}