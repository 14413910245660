import { citiesNamesCE } from 'utils/citiesNames';

export const localizacaoGlobal = {
  fields: [
    {
      key: 'localizacao.municipio',
      label: 'Município',
      widget: 'select',
      options: citiesNamesCE,
      viewWidget: 'simple-view',
      widgetProps: { showSearch: true } 
    },
    {
      key: 'localizacao.rua',
      label: 'Rua',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Rua é obrigatório',
        },
      ],
    },
    {
      key: 'localizacao.bairro',
      label: 'Bairro',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Bairro é obrigatório',
        },
      ],
    },
    {
      key: 'localizacao.cep',
      label: 'CEP',
      widget: 'number',
      viewWidget: 'cep-view',
      rules: [
        {
          required: true,
          message: 'O campo CEP é obrigatório',
        },
       
      ],
    },
    {
      key: 'localizacao.numero',
      label: 'Número',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
      rules: [
        {
          required: true,
          message: 'O campo Numero é obrigatório',
        },
      ],
    },
    {
      key: 'localizacao.pontoDeReferencia',
      label: 'Ponto de referencia',
      viewWidget: 'simple-view',

    },
    {
      key: 'localizacao.estacao',
      label: 'Estação',
      viewWidget: 'simple-view',
    }
  ],
};
