import { GeneralInfoBoxTitle } from 'pages/ArticulatorWorkPlan/styles';
import styled, { keyframes } from 'styled-components';


export const Box = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  border: 0;
`;

export const CommentContainerTitle = styled(GeneralInfoBoxTitle)`
  margin: 0;
  text-align: left;
`;

const animComment = keyframes`
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const CommentBox = styled.div`
  width: 100%;
  height: max-content;

  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFF;;
  border-radius: 7px;

  animation: ${animComment} 1s;

  .observation {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.3rem;
   
    color: #464A4E;
  }

  & + div {
    margin: 10px 0;
  }

`;

export const ProfileLikeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ProfileDateContent = styled.div`
  display: flex;
  align-items: center;
  
  gap: 1rem;
  svg {
    width: 3.68rem;
    height: 3.68rem;
    border-radius: 50%;
    
  }
`;

export const CommentUserName = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;

  color: #464A4E;
`;


export const CommentDate = styled.span`
  font-size: 1.6rem;
  line-height: 2.2rem;

  color: #464A4E;
`;

export const LikeButton = styled.button`
  width: 3.6rem;
  height: 3.6rem;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #33A02C;
  border-radius: 5px;
  background: transparent;

`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CommentCity = styled.span`
  color: gray;
  font-size: 12px;
`;

export const CountLikesContainer = styled.div`
  position: absolute;
  bottom: -5px;
  left: -5px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.6rem;  
  height: 1.6rem;

  border-radius: 50%;
  background: #FFDE32;

  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #464A4E;

`;

