import React from 'react';

import EntidadesMunicipio from './EntidadesMunicipio';
import FormacaoList from './FormacaoList';

import ProfissionaisCapacitadosList from './ProfissionaisCapacitadosList';
import DiagnosticoList from './DiagnosticoList';
import CiclosSeduc from './CiclosSeduc';
import PorMunicipioCapacitacao from './PorMunicipioCapacitacao';
import CiclosPadin from './CiclosPadin'
import PorRegiao from './PorRegiao';

interface FieldListProps {
  form: any;
  viewMode?: boolean;
  actionCurrent: string;
  size: any;
  diagnosticoSize?: any;
  initialValues: any;
  editMode: any;
}

export function FieldsList({
  initialValues,
  form,
  viewMode,
  actionCurrent,
  size,
  diagnosticoSize,
  editMode,
}: FieldListProps) {
  
  if (actionCurrent === 'capacitacaoAgentesComunitariosSaude') {
    return (
      <>
        <ProfissionaisCapacitadosList
          form={form}
          viewMode={viewMode}
          size={size}
        />
      </>
    );
  }
  if (
    form.getFieldValue('tipo') === 'Mais nutrição doação' ||
    (form.getFieldValue('tipo') === undefined &&
      actionCurrent === 'maisNutricao')
  ) {
    
    return (
      <>
        <EntidadesMunicipio form={form} viewMode={viewMode} size={size} />
      </>
    );
  }
  if (actionCurrent === 'nucleoEstimulacaoPrecoce') {
    return (
      <>
        <FormacaoList size={size} form={form} viewMode={viewMode} />
        <DiagnosticoList
          size={diagnosticoSize}
          form={form}
          viewMode={viewMode}
        />
      </>
    );
  }
  if (
    actionCurrent === 'sementesDoAmanha' ||
    actionCurrent === 'prevencaoEmFamilia'
  ) {
    return (
      <>
        <PorRegiao
          size={size}
          form={form}
          viewMode={viewMode}
          actionCurrent={actionCurrent}
        />
      </>
    );
  }
  if (actionCurrent === 'formacaoSeduc') {

    return (
      <>
        <CiclosSeduc size={size} form={form} viewMode={viewMode} initialValues={initialValues}/> 
      </>
    );
  }
  if ((form.getFieldValue('tipo') === 'Formação'  ||
        form.getFieldValue('tipo') === undefined) && 
        actionCurrent === 'padin') 
  {

    return (
      <>
        <CiclosPadin size={size} form={form} viewMode={viewMode} initialValues={initialValues} editMode={editMode}/> 
      </>
    );
  }
  if (actionCurrent === 'capacitacao') {
    return (
      <>
        <PorMunicipioCapacitacao size={size} form={form} viewMode={viewMode} />
      </>
    );
  }
  return <></>;
}
