import { api } from 'services/api';

export async function getCrianca() {
  try {
    const response = await api.get('/cemic/children');
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export function getLastUtilization() {
  
  const response = api
    .get(`/cemic/ultima_modificacao`)

    .then(res => {
      return res.data;
    })

    .catch(err => {
      return Promise.reject(err);
    });

  return response;
}
