import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AuthMe } from 'types';

import { PublicRoute, PrivateRoute } from './Route';
import { authService, IRIS_SOCIAL_TOKEN, GRANT_TOKEN } from 'services';
import { Loading } from 'components/UI';
import { checkRoles } from './roles';

interface AuthorizerProps {
  children: React.ReactNode;
  redirect: string;
}

interface AuthorizedUser {
  user: AuthMe;
  authorized: boolean;
  adm: boolean;
  obrasAcoes: boolean;
  pesquisaCMIC: boolean;
  agent: boolean;
}

const { isArticulator, isSPSAdmin, isManager, canSeePesquisaCMIC } = checkRoles;

const AuthorizerContext = React.createContext({} as AuthorizedUser);

function useSleep<T>(fn: () => Promise<T>, ms: number) {
  const [loading, setLoading] = useState(false);
  const callbackFn = useCallback(fn, []);

  useEffect(() => {
    setLoading(true);
    const id = setTimeout(() => {
      callbackFn().finally(() => {
        setLoading(false);
      });
    }, ms);

    return () => clearTimeout(id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading;
}

function useAuth() {
  return useContext(AuthorizerContext);
}

function useAuthQueries(redirect: string) {
  const { pathname, search } = useLocation();

  const queries = new URLSearchParams(search);

  useEffect(() => {
    const token = queries.get('token');
    const grant = queries.get('grant');
    const redirectUri = queries.get('redirect-uri');

    if (token) {
      localStorage.setItem(IRIS_SOCIAL_TOKEN, token);
    }

    if (grant) {
      localStorage.setItem(GRANT_TOKEN, grant);
    }

    if (redirectUri && redirect !== pathname) {
      localStorage.setItem('REDIRECT_URI', redirectUri);
    }
  }, [queries]); // eslint-disable-line react-hooks/exhaustive-deps
}

export const AuthorizerEvents = {
  events: {},

  on(event: string, fn: () => void) {
    const events = this.events[event];

    if (events) {
      events.push(fn);
    } else {
      this.events[event] = [fn];
    }
  },

  emit(event: string) {
    const events = this.events[event];

    if (events && events.length > 0) {
      events.forEach(evt => evt());
    }
  },

  clear(event: string) {
    this.events[event] = undefined;
  },
};

export function Authorizer({ children, redirect }: AuthorizerProps) {
  const [auth, setAuth] = useState({} as AuthMe);
  const [authorized, setAuthorized] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();
  useAuthQueries(redirect);

  function logout() {
    authService.logout().then(_ => {
      window.location.href = '/iris-social';
      // setAuthorized(false);
      // setAuth({} as AuthMe);
    });
  }

  const loading = useSleep(() => {
    return authService
      .me(false)
      .then(data => {
        setAuth({ ...data, logout });
        setAuthorized(true);
      })
      .catch(() => {
        setAuth({ logout } as AuthMe);
        setAuthorized(false);
        window.localStorage.removeItem(IRIS_SOCIAL_TOKEN);
        window.localStorage.removeItem(GRANT_TOKEN);
      });
  }, 1000);

  let adm = authorized ? isSPSAdmin(auth.roles) : false;
  let obrasAcoes = authorized ? isManager(auth.roles) : false;
  let pesquisaCMIC = authorized ? canSeePesquisaCMIC(auth.roles) : false;
  let agent = authorized ? isArticulator(auth.roles) : false;

  const authorizedUser = useMemo<AuthorizedUser>(
    () => ({
      user: auth,
      authorized,
      adm,
      obrasAcoes,
      pesquisaCMIC,
      agent,
    }),
    [auth, authorized], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    AuthorizerEvents.on('unauthorized', () => {
      history.push(`${redirect}?redirect-uri=${pathname}`);
    });

    return () => AuthorizerEvents.clear('unauthorized');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthorizerContext.Provider value={authorizedUser}>
      {loading ? <Loading /> : children}
    </AuthorizerContext.Provider>
  );
}

Authorizer.useAuth = useAuth;
Authorizer.PublicRoute = PublicRoute;
Authorizer.PrivateRoute = PrivateRoute;
Authorizer.checkRoles = checkRoles;
