import React, { useEffect, useState } from 'react';
import { Descriptions } from 'antd';


import { Modal } from 'antd';
import { generetePDF } from 'utils/generetePDF';
import {
  IoDocumentTextSharp,
  IoNewspaperSharp,
  IoClose,
} from 'react-icons/io5';

import { generateXLSX } from 'utils/genereteXLSX';

import moment from 'moment';
import { useHistoryObra } from 'utils/context/historyObraContext';

import '../ModalDownloadAcoes/styles.scss';
interface ModalDownloadObrasProps {
    onOpenModal: (value: boolean) => void;
    formattedEvents: any;
    nameAndId: any;
    dataXLSX: any;
}

export default function ModalDownloadObras({ onOpenModal, formattedEvents, nameAndId, dataXLSX}: ModalDownloadObrasProps) {
    const {
        selectedCitiesList,
        selectedStatusList,
        selectedTime,
        selectedRegiaoList,
        filtered,
        setFiltered
      } = useHistoryObra();
    const [dateFilter, setDateFilter] = useState("");
    const [updatedEvents, setUpdatedEvents] = useState<any>(formattedEvents);
    const [updatedEventsXLSX, setUpdatedEventsXLSX] = useState<any>(dataXLSX);

    const isFilter = filtered && ( selectedTime?.length > 0 || selectedCitiesList?.length > 0  || selectedRegiaoList.length > 0 || selectedStatusList.length > 0 )? true : false
   
   
    useEffect(()=> {

        let dataAtual: any = '';
        if(selectedTime?.length > 1) {
          let dataI = moment(new Date(selectedTime[0])).format('yyyy-MM');
          let dataF = moment(new Date(selectedTime[1])).format('yyyy-MM');
          dataAtual = dataI + ' até ' + dataF
          setDateFilter(dataAtual)
        }

        return() => {
          setFiltered(false);
          setUpdatedEvents([]);
          setUpdatedEventsXLSX([]);
        }
      }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUpdatedEvents(formattedEvents);
    setUpdatedEventsXLSX(dataXLSX);
  }, [formattedEvents, dataXLSX])

    return (
    <Modal
      visible={true}
      width={500}
      cancelText={false}
      okText={false}
      closable={false}
      zIndex={1000}
    >
      <div className="modal__downloads">
        <h1>DOWNLOAD DE EQUIPAMENTOS</h1>
        {
          isFilter && 
          <Descriptions title="Filtros aplicados"  >
            { dateFilter && <Descriptions.Item label="Período">{dateFilter}</Descriptions.Item> } 
            { selectedRegiaoList?.length > 0 && <Descriptions.Item label="Região de Planejamento">{selectedRegiaoList.join(', ')}</Descriptions.Item> }
            { selectedCitiesList?.length > 0 && <Descriptions.Item label="Município">{selectedCitiesList.join(', ')}</Descriptions.Item> }
            { selectedCitiesList?.length > 0 && <Descriptions.Item label="Estágio de execução">{selectedStatusList.join(', ')}</Descriptions.Item> }
          </Descriptions>
        }
        <div className="line-horizontal">
          <div className="lines" />
        </div>
        <div className="modal__downloads-buttons">
          <button
            onClick={() =>   generetePDF(updatedEvents, 'obras', nameAndId)} // eslint-disable-line no-sequences
          >
            <IoNewspaperSharp color="#fff" size={17} />
            <p>Baixar PDF </p>
          </button>


          <button
            onClick={() => generateXLSX(updatedEventsXLSX, 'obra', nameAndId)} // eslint-disable-line no-sequences
          >
            <IoDocumentTextSharp color="#fff" size={17} />
            <p>Baixar XLSX </p>
          </button>
        </div>

        <button
          className="modal__downloads-close"
          onClick={() => onOpenModal(false)}
        >
          <IoClose size={20} />
        </button>
      </div>
    </Modal>
  );
}
