
import React from 'react';
import { Select } from 'antd';
import CitiesCeara from '../../../data/ceara-full.json';

const MunicipioFilter = ({setSelectedMunicipio}) => {

  const SelectMunicipiosOptions = CitiesCeara.features.map(city => {
    return {
      value: city.properties.name,
      label: city.properties.name,
    };
  });
  
  const onSelectedMunicipio = (value: string[]) => {
    setSelectedMunicipio(value);
  };
  
  return (
    <div>
      <strong>Município</strong>
      <Select
        mode={'multiple'}
        placeholder="Todos os municípios"
        options={SelectMunicipiosOptions}
        maxTagCount={1}
        onChange={onSelectedMunicipio}
        allowClear
      />
    </div>
  );
};

export default MunicipioFilter;
