export const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export function generateYears() {
  let  year = 2006;


  const today = new Date();
  const yearCurrent = today.getFullYear();

 const allYear:number[] = [year];

  while (year < yearCurrent + 1) { 
      const newDate = year + 1;

      allYear.push(newDate);
      year = newDate;
  }

  return  allYear;
}

export const yearsNames = generateYears();