import React from 'react';

interface ButtonPanelProps {
  step: number;
  articulator: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onNext: () => void;
  onBack: () => void;
}

export function ButtonPanel({
  step,
  articulator,
  onConfirm,
  onCancel,
  onNext,
  onBack,
}: ButtonPanelProps) {

  const FirstButton = () => {
    const handleClick = step === 0 ? onCancel : onBack;
    const buttonTitle = step === 0 ? 'CANCELAR' : 'VOLTAR';

    return (
      <button
        className="button-link"
        onClick={handleClick}
      >
        {buttonTitle}
      </button>
    );
  }

  const SecondButton = () => {
    if (articulator) {
      const handleClick = step === 2 ? onConfirm : onNext;
      const buttonTitle = step === 2 ? 'CONFIRMAR' : 'AVANÇAR';

      return (
        <button
          className="button-primary"
          onClick={handleClick}
        >
          {buttonTitle}
        </button>
      )
    }

    const handleClick = step === 1 ? onConfirm : onNext;
    const buttonTitle = step === 1 ? 'CONFIRMAR' : 'AVANÇAR';

    return (
      <button
        className="button-primary"
        onClick={handleClick}
      >
        {buttonTitle}
      </button>
    )
  }

  return (
    <div className="ButtonPanel">
      <FirstButton />
      <SecondButton />
    </div>
  )
}