export function addInfosInObras(dataObras: any[]) {

  const newDataObras = dataObras.map(obra => {
    if (obra.nome === 'Brinquedocreche') {
      return {
        ...obra,
        descricao: 'Kits de brinquedos implantados em creches e pré-escolas para atender as crianças que estão nesses espaços.',
        subcategoria: 'Brinquedos',
        categoria: 'Tempo de Brincar',
        tipo: 'Obra'
      }
    } else if (obra.nome === 'Brinquedopraça') {
      return {
        ...obra,
        descricao: 'Revitalização de praças com a instalação de kits de brinquedos adequados as etapas do desenvolvimento infantil. Busca a melhoria na qualidade de vida e o desenvolvimento integral das crianças em suas capacidades emocionais, motoras e cognitivas.',
        subcategoria: 'Brinquedos',
        categoria: 'Tempo de Brincar',
        tipo: 'Obra'
      }
    }
    else if (obra.nome === 'Praça Mais Infancia') {
      return {
        ...obra,
        descricao: 'Espaços compostos por playgrounds, quadras poliesportivas, áreas de convivência, bicicletário, dentre outros. Nesses espaços poderão ser realizadas atividades artísticas, culturais, educacionais e de lazer, ampliando as dimensões coletivas favoráveis ao crescimento saudável.',
        subcategoria: 'Obras',
        categoria: 'Tempo de Brincar',
        tipo: 'Obra'
      }
    }
    else if (obra.nome === 'Complexo Mais Infância') {
      return {
        ...obra,
        descricao: 'Complexo Mais infância.',
        subcategoria: 'Brinquedos',
        categoria: 'Tempo de Brincar',
        tipo: 'Obra'
      }
    }
    else if (obra.nome === 'Centro de Educação Infantil - CEI') {
      return {
        ...obra,
        descricao: 'Amplia a oferta de creches e estabelecimentos de pré-escola para a promoção do desenvolvimento integral das crianças cearenses nos municípios.',
        subcategoria: 'Educação',
        categoria: 'Tempo de Aprender',
        tipo: 'Obra'
      }
    }
    else {
      return {
        ...obra,
        tipo: 'Obra'
      }
    }
  })

  return newDataObras
}

export function addInfosInActions(dataAction: any[]) {

  return dataAction.map(action => {
    return {
      ...action,
      tipo: 'Ação'
    }
  })
}