import React from 'react'
import Modal from 'react-modal'
import { Input } from 'antd'

import alertIcon from '../../../assets/articulator/alertIcon.svg'

import {
    AprooveModal,
    ModalBody,
    ModalIcon,
    ModalTitle,
    ModalButtonContainer,
    ModalCancelButton,
    ModalConfirmButton
} from './styles'

interface ValidRefusedModalProps {
    isOpen: boolean;
    closeModal: VoidFunction;
    closeValidRefusedModal: VoidFunction;
    style: object;
    contentLabel: string;
    modalTitle: string;
    topModalBody: string;
    botModalBody: string;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    handleCreateComment: VoidFunction;
    isConfirmRefuseButtonClicked: boolean
}

const { TextArea } = Input

export function ValidRefusedModal({
    isOpen,
    contentLabel,
    closeModal,
    closeValidRefusedModal,
    topModalBody,
    botModalBody,
    modalTitle,
    style,
    comment,
    setComment,
    handleCreateComment,
    isConfirmRefuseButtonClicked
}: ValidRefusedModalProps) {

    function submit() {
        handleCreateComment()
    }
    

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={style}
            contentLabel={contentLabel}
        >
            <AprooveModal>
                <ModalIcon src={alertIcon} />
                <ModalTitle>
                    {modalTitle}
                </ModalTitle>
                <ModalBody>
                    {topModalBody}
                </ModalBody>
                <ModalBody>
                    {botModalBody}
                </ModalBody>
                <TextArea
                    className='text-area'
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    onPressEnter={handleCreateComment}
                    disabled={isConfirmRefuseButtonClicked}
                />
                <ModalButtonContainer>
                    <ModalCancelButton 
                        onClick={closeModal}
                        style={{
                            border: 'solid 1px #C2102A',
                            color: '#C2102A',
                            borderRadius: 5,
                            fontWeight: 'bold'
                        }}
                        >
                        Cancelar
                    </ModalCancelButton>

                    <ModalConfirmButton 
                        onClick={submit} 
                        loading={isConfirmRefuseButtonClicked}
                        style={{
                            border: 'solid 1px #4B9F37',
                            color: '#4B9F37',
                            borderRadius: 5,
                            fontWeight: 'bold'
                        }}>
                        Confirmar Recusa
                    </ModalConfirmButton>
                </ModalButtonContainer>
            </AprooveModal>
        </Modal>
    )

}