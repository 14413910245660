import React from 'react';
import { Form, FormInstance, Input, Select, Row, Col } from 'antd';
import 'dayjs/locale/pt-br';
import locale from 'antd/es/locale/pt_BR';

import { MaritalStatus } from '../../../types';
import { FormBox, InputCPF, InputPhone, DatePicker } from '../..';
import { Authorizer } from 'components';
import rules from './rules';

interface PersonalFormProps {
  form?: FormInstance;
  maritalStatus: MaritalStatus[];
  loading: boolean;
  onCpfChange?: (cpf: string) => void;
}

const { Item } = Form;
const { Option } = Select;


export function PersonalForm({
  form,
  onCpfChange,
  maritalStatus,
  loading
}: PersonalFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const { user } = Authorizer.useAuth();
  const { isArticulator } = Authorizer.checkRoles;
  const roles = user.roles ?? [];

  const disabled = isArticulator(roles);

  return (
    <FormBox title="Dados Pessoais" form={currentForm}>
      <Item
        label="CPF"
        name="cpf"
        wrapperCol={{ xxl: 11, xl: 11, lg: 11, md: 24, xs: 24 }}
        rules={rules.cpf}
      >
        <InputCPF onChange={onCpfChange}  disabled={disabled} />
      </Item>

      <Item label="Nome" name="name" rules={rules.name}>
        <Input disabled={disabled} />
      </Item>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item
            label="Data de Nascimento"
            name="birthdate"
            rules={rules.birthdate}
          >
            <DatePicker
              locale={locale.DatePicker}
              format="DD/MM/YYYY"
              placeholder=""
              disabled={disabled}
            />
          </Item>
        </Col>

        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
          <Item
            label="Estado Civil"
            name="maritalStatus"
            rules={rules['marital-status']}
          >
            <Select loading={loading}>
              {maritalStatus.map(status => (
                <Option value={status.id} key={status.id}>
                  {status.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item label="E-mail" name="email" rules={rules.email}>
            <Input />
          </Item>
        </Col>
        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
          <Item label="Celular" name="phone" rules={rules.phone}>
            <InputPhone />
          </Item>
        </Col>
      </Row>
    </FormBox>
  );
}
