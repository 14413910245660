export function organizeEventoObras(events: any[]) {

  const eventFormatted =  events.map(event => {
    return {
      dataOrdemServico: event?.dataOrdemServico ? new Date(event?.dataOrdemServico).toLocaleDateString('pt-br') : '-',
      endereco: event?.endereco || '-',
      estagioExecucao: event?.estagioExecucao || '-',
      estagioExecucaoId: event?.estagioExecucaoId || '-',
      grupoId: event?.grupoId || '-',
      grupoTipo: event?.grupoTipo || '-',
      investimentoTotal: event?.investimentoTotal || '-',
      id: event?.id || '-',
      latitude: event?.latitude || '-',
      longitude: event?.longitude || '-',
      mantidaComTesouroEstadual: event?.mantidaComTesouroEstadual || '-',
      municipio: event?.municipio || '-',
      municipioCodigoIBGE: event?.municipioCodigoIBGE || '-',
      municipioId: event?.municipioId || '-',
      naoVisualizarGovernador: event?.naoVisualizarGovernador || '-',
      nivelImportancia: event?.nivelImportancia || '-',
      nome: event?.nome || '-',
      orgao: event?.orgao || '-',
      orgaoGestorId: event?.orgaoGestorId || '-',
      orgaoId: event?.orgaoId || '-',
      percentualExecucaoFisica: event?.percentualExecucaoFisica || '-',
      previsaoEntrega: event?.previsaoEntrega || '-',
      qtdBeneficiados: event?.qtdBeneficiados || '-',
      qtdTotalBeneficiados: event?.qtdTotalBeneficiados || '-',
      quantidade: event?.quantidade || '-',
      enderquantidadeAnterioreco: event?.quantidadeAnterior || '-',
      quantidadeExecucaoFisica: event?.quantidadeExecucaoFisica || '-',
      regiaoId: event?.regiaoId || '-',
      secretaria: event?.secretaria || '-',
      secretariaGestorId: event?.secretariaGestorId || '-',
      secretariaId: event?.secretariaId || '-',
      statusMovimentacao: event?.statusMovimentacao || '-',
      statusMovimentacaoString: event?.statusMovimentacaoString || '-',
      tipoBeneficiado: event?.tipoBeneficiado || '-',
      tipoId: event?.tipoId || '-',
      ultimoAcompanhamentoAprovadoId: event?.ultimoAcompanhamentoAprovadoId || '-',
      ultimoAcompanhamentoId: event?.ultimoAcompanhamentoId || '-',
      unidade: event?.unidade || '-',
      unidadeId: event?.unidadeId || '-'
    }
  });


  return eventFormatted;
}

