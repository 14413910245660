import React from 'react';
import Dado from '../Dado';
import './style.scss';

interface ListDadosProps {
  lista: any[]
  columns: number;
}

export default function ListDados({lista, columns}: ListDadosProps) {
  return (
    <>
      <div className="dados" style={{gridTemplateColumns: `repeat(${columns}, 1fr)`}}>
        {lista.map((value, index) => {
          return (
            <Dado key={index} number={value.number} title={value.title} subtitle={value.subtitle} />
          )
        })}
      </div>
    </>
  );
}
