import { obrasAcoesGerais } from "./obrasAcoesGerais";
import { padinPDF } from "./padinPDF";
import { MaisNutricaoPDF } from './MaisNutricaoPDF'
import { formacaoSeducPDF } from "./formacaoSeducPDF";
import { criancaFelizPDF } from "./criancaFelizPDF";

export function generetePDF(eventData: any, type: string, name: string) {

  if(name === 'Padin') padinPDF(eventData);
  else if(name === 'Mais Nutrição') MaisNutricaoPDF(eventData);
  else if(name === 'Formação Seduc') formacaoSeducPDF(eventData);
  else if(name === 'Criança Feliz') criancaFelizPDF(eventData);
  else obrasAcoesGerais(eventData, type, name);

} 
