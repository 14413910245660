import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';
import Circle from '../../assets/circle-programa.svg';
import { Footer } from '../../components';
import pilar01 from '../../assets/pilares-01.svg';
import pilar02 from '../../assets/pilares-02.svg';
import pilar03 from '../../assets/pilares-03.svg';
import pilar04 from '../../assets/pilares-04.svg';
import criancas from '../../assets/mais-infancia.svg';
import setaDown from '../../assets/seta-down.svg';

import icon01 from '../../assets/objetivo-home-icons/icon01.svg';
import icon02 from '../../assets/objetivo-home-icons/icon02.svg';
import icon03 from '../../assets/objetivo-home-icons/icon03.svg';
import icon04 from '../../assets/objetivo-home-icons/icon04.svg';
import icon05 from '../../assets/objetivo-home-icons/icon05.svg';
import icon06 from '../../assets/objetivo-home-icons/icon06.svg';
import icon07 from '../../assets/objetivo-home-icons/icon07.svg';
import icon08 from '../../assets/objetivo-home-icons/icon08.svg';
import icon09 from '../../assets/objetivo-home-icons/icon09.svg';
import icon10 from '../../assets/objetivo-home-icons/icon10.svg';
import { Affix } from 'antd';

const BlocoObj = ({ img, title }) => {
  return (
    <div className="bloco-objetivo-single">
      <img src={img} alt="Objetivo" />
      <span>{title}</span>
    </div>
  );
};

const objetivos = [
  {
    icon: icon01,
    title: (
      <>
        Promover
        <br />o desenvolvimento infantil
      </>
    ),
  },
  {
    icon: icon02,
    title: (
      <>
        Reduzir
        <br />a extrema pobreza
      </>
    ),
  },
  {
    icon: icon03,
    title: (
      <>
        Proporcionar
        <br /> o bem-estar físico e intelectual
      </>
    ),
  },
  {
    icon: icon04,
    title: (
      <>
        Oferecer lazer
        <br /> para as crianças
      </>
    ),
  },
  {
    icon: icon05,
    title: (
      <>
        Engajar
        <br /> a sociedade civil
      </>
    ),
  },
  {
    icon: icon06,
    title: (
      <>
        Incluir famílias
        <br /> na sociedade
      </>
    ),
  },
  {
    icon: icon07,
    title: (
      <>
        Estudar
        <br /> políticas públicas
      </>
    ),
  },
  {
    icon: icon08,
    title: (
      <>
        Aumentar
        <br /> a segurança alimentar
      </>
    ),
  },
  {
    icon: icon09,
    title: (
      <>
        Tornar
        <br /> as famílias mais independentes
      </>
    ),
  },
  {
    icon: icon10,
    title: (
      <>
        Integrar
        <br /> secretarias do estado
      </>
    ),
  },
];

const InstitucionalPMI = (): JSX.Element => {
  const [animationTempoNascer, setAnimationTempoNascer] = useState(false);
  const [animationTempoCrescer, setAnimationTempoCrescer] = useState(false);
  const [animationTempoBrincar, setAnimationTempoBrincar] = useState(false);
  const [animationTempoAprender, setAnimationTempoAprender] = useState(false);
  const [firstStart, setFirstStart] = useState('');
  const [secondStart, setSecondStart] = useState('');
  const [thirdtart, setThirdStart] = useState('');
  const [restart, setRestat] = useState(true);

  setTimeout(() => {
    setRestat(!restart);
  }, 1000 * 10);

  useEffect(() => {
    setFirstStart('');
    setSecondStart('');
    setThirdStart('');

    setTimeout(() => {
      setFirstStart('on');
    }, 1000);

    setTimeout(() => {
      setFirstStart('');
    }, 1000 * 6.6);

    setTimeout(() => {
      setSecondStart('on');
    }, 1000 * 3.7);

    setTimeout(() => {
      setSecondStart('');
    }, 1000 * 9);

    setTimeout(() => {
      setThirdStart('on');
    }, 1000 * 6.5);

  }, [restart]);

  const FixedMenu = () => {
    if (window.scrollY === 0) {
      setAnimationTempoAprender(false);
      setAnimationTempoBrincar(false);
      setAnimationTempoCrescer(false);
      setAnimationTempoNascer(false);
    }
    if (window.scrollY >= 800) {
      setAnimationTempoNascer(true);
    }

    if (window.scrollY >= 900) {
      setAnimationTempoCrescer(true);
    }

    if (window.scrollY >= 1000) {
      setAnimationTempoBrincar(true);
    }

    if (window.scrollY >= 1100) {
      setAnimationTempoAprender(true);
    }
  };

  window.addEventListener('scroll', FixedMenu);

  return (
    <>      
      <div className="pmi-content-main">
        <Affix>
          <div className="pmi-header">
            <span>
              <h3>
                <a href="#programa">O Programa</a>
              </h3>
            </span>
            <span>
              <h3>
                <a href="#pilares">Pilares</a>
              </h3>
            </span>
            <span>
              <h3>
                <a href="#objetivos">Objetivos</a>
              </h3>
            </span>
            <span>
              <h3>
                <a href="#gestao">Gestão</a>
              </h3>
            </span>
          </div>
        </Affix>
        <section className="container-box">
          <div className="gif-trecho">
            <div className="box-texts-container">
              <div className={`box-text first ${firstStart}`}>
                <div className="text big">
                  O Programa Mais Infância do Ceará
                </div>
                <div className="text big">foi criado em 2015</div>
              </div>
              <div className={`box-text second ${secondStart}`}>
                <div className="text middle">para atender</div>
                <div className="text big">
                  crianças de 0 a 5 anos e 11 meses
                </div>
              </div>
              <div className={`box-text third ${thirdtart}`}>
                <div className="text middle">em todos os</div>
                <div className="text big">184 municípios do estado.</div>
              </div>
            </div>
            <a href="#programa">
              <img src={setaDown} alt="Seta pra baixo" />
            </a>
          </div>
          <div className="bloco-programa" id="programa">
            <h1>O Programa</h1>
            <div className="elements-programa">
              <div className="bloco-about">
                <h2>O que é</h2>
                <p>
                  Em parceria com diversas secretarias, o{' '}
                  <b>Programa Mais Infância Ceará </b>
                  envolve obras e ações para <b>
                    combater a desigualdade
                  </b> e{' '}
                  <b>
                    promover a justiça social e o desenvolvimento integral da
                    criança
                  </b>
                  .
                </p>
                <p>
                  Assim, sua visão de futuro é{' '}
                  <b>desenvolver a criança para desenvolver a sociedade</b>.
                </p>
              </div>
              <div className="bloco-circle">
                <h2>Público-alvo</h2>
                <img src={Circle} alt="Ciculo" />
              </div>
            </div>
          </div>
          <div className="bloco-pilares" id="pilares">
            <h1>Pilares</h1>
            <div className="content-pilares">
              <div className="divider-pilar">
                <div
                  className={
                    animationTempoNascer
                      ? 'tempos nascer animation'
                      : 'tempos nascer'
                  }
                >
                  <h3>Tempo de Nascer</h3>
                  <p>
                    Planejamento familiar e reprodutivo. Cuidado materno e da
                    criança para reduzir as mortes de gravidez de alto risco.
                  </p>
                  <img src={pilar01} alt="Primeiro pilar" />
                </div>
                <div></div>
              </div>

              <div className="divider-pilar">
                <div></div>
                <div
                  className={
                    animationTempoCrescer
                      ? 'tempos crescer animation'
                      : 'tempos crescer'
                  }
                >
                  <h3>Tempo de Crescer</h3>
                  <p>
                    Serviços e formações de profissionais, pais e cuidadores
                    para fortalecer relações familiares e comunitárias.
                  </p>
                  <img src={pilar02} alt="Segundo pilar" />
                </div>
              </div>

              <div className="divider-pilar">
                <div
                  className={
                    animationTempoBrincar
                      ? 'tempos brincar animation'
                      : 'tempos brincar'
                  }
                >
                  <h3>Tempo de Brincar</h3>
                  <p>
                    Construção e revitalização de espaços públicos com
                    brinquedos para crianças.
                  </p>
                  <img src={pilar03} alt="Terceiro pilar" />
                </div>
                <div></div>
              </div>

              <div className="divider-pilar">
                <div></div>
                <div
                  className={
                    animationTempoAprender
                      ? 'tempos aprender animation'
                      : 'tempos aprender'
                  }
                >
                  <h3>Tempo de Aprender</h3>
                  <p>
                    Construção de Centros de Educação Infantil para atender
                    crianças de 4 a 5 anos. Aumento da oferta de creches para
                    crianças de 0 a 3 anos.
                  </p>
                  <img src={pilar04} alt="Quarto pilar" />
                </div>
              </div>
            </div>
          </div>
          <div className="bloco-objetivos" id="objetivos">
            <h1>Objetivos</h1>
            <div className="bloco-obj">
              {objetivos.map(list => (
                <BlocoObj img={list.icon} title={list.title} key={list.icon} />
              ))}
            </div>
          </div>
          <div className="bloco-ebook">
            <h1>Conheça todos os detalhes do Programa nos livros digitais</h1>
            <span>
              <a
                href="https://www.ceara.gov.br/wp-content/uploads/2019/07/EBook-MaisInfanciaCeara-2019.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                ACESSAR LIVRO 2019
              </a>
            </span>
            <span>
              <a
                href="https://www.ceara.gov.br/wp-content/uploads/2022/03/Livro-Mais-Infancia-DIGITAL.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                ACESSAR LIVRO 2022
              </a>
            </span>
          </div>
          <div className="bloco-adm" id="gestao">
            <h1>Gestão</h1>
            <p>
            A Secretaria da Proteção Social, em cooperação com diversos órgãos do Estado,
            desenvolve o programa Mais Infância Ceará. Os principais órgãos 
            envolvidos compõem o Comitê Consultivo Intersetorial das 
            Políticas de Desenvolvimento Infantil do Estado do Ceará (CPDI), 
            colegiado criado para discutir e criar políticas, programas e projetos focados no desenvolvimento infantil 
            para melhorar a qualidade de vida na primeira infância.
            </p>
            <img src={criancas} alt="Crianças" />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default InstitucionalPMI;
