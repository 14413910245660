import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import { Authorizer } from '.';
import { Loading } from 'components/UI';

type RoleFunction = (role: string[]) => boolean;

export interface IRoute extends RouteProps {
  roles?: RoleFunction[]
}

interface PublicRouteProps extends IRoute { };
interface PrivateRouteProps extends IRoute { };

export function PublicRoute(props: PublicRouteProps) {
  return (
    <Route {...props} />
  )
}

export function PrivateRoute(props: PrivateRouteProps) {
  const { authorized } = Authorizer.useAuth();
  const { pathname } = useLocation();

  const [checking, setChecking] = useState(true);

  useEffect(() => {
    const id = setTimeout(() => {
      setChecking(false);
    }, 1000);

    return () => clearTimeout(id);
  }, []);

  if (checking) {
    return <Loading />
  }

  if (!authorized) {
    return <Redirect to={`/nao-autorizado?redirect-uri=${pathname}`} />
  }

  return (
    <Route {...props} />
  );
}