import styled from 'styled-components';
import { Button, ButtonProps } from 'antd';

interface ContainerProps {
  hasChanged: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #ffff;
  padding: 18px;
  max-width: 1600px;
  margin: 0 auto;
  
  .ant-picker-calendar,
  .ant-picker-calendar-full {
    max-width: 992px;
    margin: 0 auto;
  }

  .header-visitation-edit {
    display: flex;
    flex-direction: column;
    
    .show-agenda {
      align-self: flex-end;
      padding: 20px 0;
      span {
        display: flex;
        gap: 10px;
        width: 100%;
        display: flex;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;

        color: #464A4E;

        .ant-switch-checked {
          background: #87C878;
        }
      }
    }
  }

  button {
    transition: filter .2s;
    &:not(:disabled):hover {
      filter: brightness(0.9);
    }
  }

  .calendar-gray {
    background: #ffff;
    
    & > header, .ant-picker-panel {
      background: #f2f2f2;
    }
  }

  .save-updates-button {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 992px;
    margin: 20px auto;
    width: 100%;
    background: ${props => props.hasChanged ? '#4B9F37' : '#81868A'};
    border-radius: 5px;
    height: 36px;
    border: 0;


    transition: background 0.2s;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: #FFFFFF;
    text-transform: uppercase;
    cursor: ${props => props.hasChanged ? 'pointer' : 'not-allowed'};

    &:hover:not(:disabled) {
      background: #4B9F37;
      filter: brightness(0.7);
    }
  }

  .ant-modal-content {
    padding: 0;
  }
`;

export const ModalInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10;

  > span {
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    color: #2D4040;
  }

  > p {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    color: #2d4040;
    margin-top: 34px;
    margin-bottom: 0;
  }

  > div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

type ModalButtonProps = ButtonProps & {
  color: string;
  background?: string;
  hasBorder?: boolean;
};

export const ModalButton = styled(Button)<ModalButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background ?? 'transparent'};
  width: 50%;
  /* color: ${({ color }) => color}; */
  border-radius: 5px;
  height: 36px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 22;
  cursor: pointer;
  outline: 0;
  margin-right: 6px;

  /* border: ${props => props.hasBorder ? `1px solid #C2102A` : `0`}; */
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
