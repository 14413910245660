import React, { useEffect, useState } from 'react'

import './styles.scss'

import CardIniciativaServidor from 'components/CardIniciativasServidor'

import {
    MdExpandMore,
    MdNavigateNext
} from 'react-icons/md'

import { useHomeServidor } from 'utils/context/homeServidorContext'

/* interface de uma iniciativa */
interface IIniciativa {
    tipo: 'Obra' | 'Ação',
    descricao: string,
    eventos:{}[],
    id: string,
    nome: string,
    subcategoria: string,
    tempoDe: string,
    totalEventos: number,
    categoria: string
}

/* responsavel por montar os cards em cada tempo */
const MakeTemposListCardsServidor = ({ categoria }) => {

   /* pegando funções e variáveis do contexto de HomeServidor  */
    const { 
        iniciativasListCurrent,
        setIsLoading,
     } = useHomeServidor();

    /* estado da lista de um determinado tempo */
    const [iniciativasListByTempo, setIniciativasListByTempo] = useState<Array<IIniciativa>>([])

    /* estados de situações em que lista pode estar */
    const [listByTempoIsEmpty, setListByTempoIsEmpty] = useState(true)
    const [isOpen, setIsOpen] = useState(false)

    /* responsavel por montar a lista de acordo com o tempo */
    useEffect(() => { 
        const newListByTempo = [...iniciativasListCurrent?.filter(iniciativa => iniciativa.categoria === categoria)]
        setIniciativasListByTempo(newListByTempo);
    }, [iniciativasListCurrent]) // eslint-disable-line react-hooks/exhaustive-deps
   

    /* responsavel por atualizar estados sempre que a lista mudar */
    useEffect(() => {
        setListByTempoIsEmpty(true)
        setIsLoading(false)
        if(iniciativasListByTempo?.length === 0) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
            setListByTempoIsEmpty(false)
        }
    }, [iniciativasListByTempo]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='time-container'>
            <div 
                onClick={ () => { !isOpen ? setIsOpen(true) : setIsOpen(false) } } 
                className='title-time'
            >

                { !isOpen ? (
                        <MdNavigateNext size={40} color='var(--green-light' />
                    ) : (
                        <MdExpandMore size={40} color='var(--green-light' /> 
                )}
                
                <h2> {categoria} </h2>
            </div>
            <div className={isOpen ? 'time-content open' : 'time-content'}>
                {
                    !listByTempoIsEmpty ? (
                        <>
                            {
                                iniciativasListByTempo?.map(( iniciativa ) =>
                                    <CardIniciativaServidor
                                        key = { iniciativa.id }
                                        iniciativa = { iniciativa }
                                        categoria = {categoria}
                                    />
                                )
                            }
                        </>
                    ) : (
                        <span>Sem resultados para {categoria}</span>
                    )
                }
            </div>
        </div>
    )
}

export default MakeTemposListCardsServidor