import styled, { keyframes } from 'styled-components';
import Slider from 'react-slick';

interface FamilyListProps {
  isActive: boolean;
}

export const Container = styled.div`
  
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;

  padding: 2.6rem 3rem;

  .go--back {
    align-self: flex-start;
  }

  .referrer-semester {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 3rem;
    line-height: 3rem;
    text-align: right;

    color: rgba(96, 196, 112, 0.5);
  }

  @media(max-width: 1030px) {
    .referrer-semester {
      font-size: 2rem;
    }
  }

  @media(max-width: 820px) {
    .referrer-semester {
      font-size: 2rem;
    }
  }

  @media(max-width: 765px) {
    .referrer-semester {
      font-size: 2rem;
      margin-top: 0;
    }
  }

  @media(max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media(max-width: 400px) {
    .referrer-semester {
      font-size: 3rem;
    }
  }  

  @media(max-width: 420px) {
    flex-direction: column;
  }
`;

export const GenerateReport = styled.div`
  max-width: 200px;
`;

export const MiddleContainer = styled(TopContainer)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  justify-self: flex-end;
  align-self: flex-end;
`;

export const ButtonSearch = styled.button`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  justify-self: flex-end;
  align-self: flex-end;

  width: 4rem;
  height: 4rem;
  background: #F2F2F2;
  border-radius: 7px;
  border: 0;
`;


export const Main = styled.main`
  
`;

export const TabContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabButtonContainer = styled.div`
  width: 50%;
  /* margin-top: 25px; */
`;

export const TabButton = styled.button`
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 0;
  background: #FBFBFB;

  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  outline: 0;

  color: #464A4E;
  transition: background .5s;
  
  span {
    margin-left: 5px;
  }

  &.active {
    background: #F2F2F2;
    border-bottom: 3px solid #FFDE32;;
  }
`;

export const MobileCalendarContainer = styled.div<FamilyListProps>`
  display: ${props => !props.isActive ? 'none' : ''};

  .filters {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 20px;
    flex-wrap: wrap;
    gap: 15px;

    div {
      display: block;
      align-items: center;
      gap: 5px;
      min-width: 200px;

      span {
        align-self: flex-start;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.6rem;

        color: #464A4E;
      }

      .ant-select {
        width: 100%;

        span {
          color: #707070;
          font-weight: normal;
        }
        
      }
    }
  }

  .filter-mobile-button {
    display: flex;
    align-self: flex-end;

    .apply-filters-button {
      width: 100%;
      height: 36px;
      border: 0;
      background: #4B9F37;
      border-radius: 5px;

      span {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;

        color: #FFFFFF;
        text-transform: uppercase;
      }
    }
  }
`;

export const FamilyListContainer = styled.div<FamilyListProps>`
  ${props => !props.isActive ? 'display:none': ''};
  min-height: 40vh;
  background: #f2f2f2;
  padding: 0 10px;
  padding-bottom: 20px;
  overflow-x: hidden;
  flex: 1;

  .not-families-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    span {
      font-size: 1.6rem;
      font-family: 'Nunito', sans-serif;
      font-weight: bold;
      line-height: 1.6rem;

      color: #464A4E;
    }
  }

  .add-new-event-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    border-radius: 5px;
    border: 0;
    color: #FFF;
    font-family: Nunito, sans-serif;
    font-weight: bold;
    font-size: 1.7rem;
    text-transform: uppercase;
    gap: 10px;

    padding: 5px 0;
  }

  @media(max-width: 400px) {
    .filters {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
`;

export const StyledSlider = styled(Slider)<FamilyListProps>`
  ${props => !props.isActive ? 'display:none': ''};
  
  background: #f2f2f2;
  padding-bottom: 20px;
  overflow: hidden;
  

  .filters {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 35px;
    gap: 15px;
    
    div {
      display: block;
      align-items: center;
      width: 43%;
      gap: 5px;

      span {
        align-self: flex-start;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.6rem;

        color: #464A4E;
      }

      .ant-select {
        width: 100%;

        span {
          color: #707070;
          font-weight: normal;
        }
        
      }
    }
  }

  @media(max-width: 400px) {
    .filters {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  overflow-x: hidden;
  z-index: 1000000;

`;

export const SearchBoxContainer = styled.div<any>`
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  background: #F2F2F2;  
  animation: open-search .7s alternate;
  
  .search-box-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px;
    max-width: 992px;
    margin: 0 auto;
   
    .go-back-button {
      display: flex;
      align-items: center;
      justify-content: center;
    
      img {
        width: 40px;
        height: 24px;
      }
    }

    h1 {
      font-size: 1.6rem;
    }
  }

  .divider {
    background:  rgba(0, 0, 0, 0.3);;
    margin: 0 auto;
  }

  .search-filters {
    margin: 0 20px;

    input {
      width: 100%;
      height: 40px;
      padding: 5px;
      border: 0;
      background: #FFFFFF;
      border-radius: 4px;
      margin-top: 5px;
    }

    .filters {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  @keyframes open-search {
    0% {
      transform: translateY(100vh);

    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes close-search {
    0% {
      transform: translateY(0);

    }
    100% {
      transform: translateY(100vh);
    }
  }

`;

export const FilterSearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 31%;
  background: transparent;
  border-radius: 14px;
  border: 1px solid #465564;

  cursor: pointer;
  padding: 10px 0;

  transition: background .4s;
  outline: 0;

  .symbol {
    width: 20px;
    height: 12px;
  }

  span {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.6rem;

    color: #464A4E;
  }

  &.active {
    background: #FFDE32;;
    border: 0;
  }
`;

export const DateChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  margin: 0;
  gap:0;
  overflow: hidden;
  
  .date-content {
    margin: 1px 0;
    width: 100%;
    position: relative;
  }

  .date {
    margin-left: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2D4040;
    border-radius: 50%;

    width: 43px;
    height: 43px;

    strong {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      z-index: 50;

      color: #FFFFFF;
    }

  }

  span {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 4.1rem;
    color: rgba(96, 196, 112, 0.3);
    align-self: flex-end;
    justify-self: flex-end;
    margin-right: 17px;
  }

  hr {
    position: absolute;
    top: 50%;
    border: none;
    border: 2px dashed #2D4040;
    width: 100%;
    z-index: 0;
  }
`;

const animArrowDown = keyframes`
  0% {
    transform:  translateY(0px) rotate(90deg);
  }

  50% {
    transform:  translateY(-5px) rotate(90deg);
  }

  100% {
    transform: translateY(0px) rotate(90deg) ;
  }
`;

export const CalendarFullScreenView = styled.div`
  display: flex; 
  max-width: 1300px;
  margin: 0 auto;
  
  padding: 10px;

  .choose-date {
    display: none; 
  } 

  .carousel-content {
    display: none;
  }

  .main-list-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;

    color: #2D4040;
    margin-bottom: 20px;
    align-self: center; 
    text-align: center;
  }

  .pagination-desktop-families {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    margin: 10px;

    input {
      width: 50px;
    }

    button {
      background: #F2F2F2;
      border-radius: 3px;
      border: 0;

      color:#87C878;
      font-weight: bold;
      padding: 5px;
      cursor: pointer;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .more-content-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;

    font-size: 14px;
    font-weight: bold;

    animation: ${animArrowDown} alternate 0.5s infinite;    
  }

  @media(max-width: 1000px) {
    flex-direction: column;

    .grid-filter-results-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media(max-width: 2000px) {
    .calendar {
      margin-top: -22rem;
    }
  }

  @media(max-width: 1000px) {
    .calendar {
      margin-top: 0;
    }
  }
`;