import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './style';

const Icon = <LoadingOutlined style={{ fontSize: 60, color: '#4b9f37' }} spin />

export function Loading() {
  return (
    <Container>
      <Spin indicator={Icon} tip="Carregando..." />
    </Container>
  );
}