import React from 'react';
import { Input } from 'antd';
interface InputCEPProps {
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export function InputCEP({
  value,
  onChange,
  bordered = true,
  disabled = false,
}: InputCEPProps) {

  const cepMask = (cep?: string) => {
    if (!cep) return undefined;

    return cep.replace(/\D/g, '')
              .replace(/(\d{5})(\d)/, '$1-$2');
  };

  const handleChange = (e) => {
    if (onChange) {
      const changedValue = e.target.value.replace(/\D/g, '');
      onChange(changedValue);
    }
  }

  return (
    <Input
      onChange={handleChange}
      value={cepMask(value)}
      maxLength={9}
      placeholder="_____-___"
      bordered={bordered}
      disabled={disabled}
    />
  );
}