import React from 'react';
import './styles.scss';
import { Modal } from 'antd';
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useHistoryAcao } from 'utils/context/historyAcaoContext';
import { useHistoryObra } from 'utils/context/historyObraContext';


type Props = {
  type: 'Obra' | 'Ação'
}


const ModalConfirmacaoInfo = ({type}:Props) => {

  const { 
    toggleModalAcaoConfirmacaoInfo,
    modalConfirmacaoInfoAcaoIsOpen
  } = useHistoryAcao()

  const { 
    toggleModalObraConfirmacaoInfo,
    modalConfirmacaoInfoObraIsOpen
  } = useHistoryObra()

  return (
        <Modal
          centered
          visible={ type === 'Obra' ? (modalConfirmacaoInfoObraIsOpen) : (modalConfirmacaoInfoAcaoIsOpen) }
          onCancel={ type === 'Obra' ? (toggleModalObraConfirmacaoInfo) : (toggleModalAcaoConfirmacaoInfo) }
          onOk={ type === 'Obra' ? (toggleModalObraConfirmacaoInfo) : (toggleModalAcaoConfirmacaoInfo) }
          width={363}
          cancelText={false}
          okText={false}
          closable={false}
          zIndex={1000}
        >
          <div className="modal">
            <div className="icon-image">
              <IoCheckmarkCircleOutline/>
            </div>
            <h1>Tudo certo!</h1>
            <p>
              O registro desse evento foi excluído com sucesso.
            </p>
            <button 
              className="button" 
              onClick={() => { type === 'Ação' ? ( 
                toggleModalAcaoConfirmacaoInfo()
              ) : ( 
                toggleModalObraConfirmacaoInfo()
              ) } }
            >OK</button> 
          </div>
        </Modal>
  );
};

export default ModalConfirmacaoInfo;
