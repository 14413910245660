import React, { createContext, useState } from 'react';

interface IMenuContext {
  setTitleState(state: any): void;
  titleState: string;
}

export const MenuContext = createContext<any>(null);

export const Provider = ({ children }: { children: React.ReactNode }): any => {
  const [titleState, setTitleState] = useState<string>('');

  const contextValues: IMenuContext = {
    titleState,
    setTitleState,
  };

  return (
    <MenuContext.Provider value={contextValues}>
      {children}
    </MenuContext.Provider>
  );
};
