// function fixNumber(number, n){
//   // Math.floor(number*Math.pow(10,n))/Math.pow(10,n)
//   return number;
// }

export function FormatNumberWithSufix(number: number){
  if(!number){
    return 0
  }
  if(number <= 1e3) {

    return number.toLocaleString('pt-br', {maximumFractionDigits: 2})
  } else if((number > 1e3) && (number < 1e6)){
    /*  nosso caso entra NESSE */
    return `${(number/1e3).toLocaleString('pt-br', {maximumFractionDigits: 2})} mil`  
  }else if((number >= 1e6) && (number < 1e9)){
 
    const res = (number/1e6);
    if(res >= 2){
   
      return `${res.toLocaleString('pt-br', {maximumFractionDigits: 2})} milhões`  
    } else {
  
      return `${res.toLocaleString('pt-br', {maximumFractionDigits: 2})} milhão`  
    }
  }else if((number >= 1e9) && (number < 1e12)){

    const res = (number/1e9);
    if(res >= 2){
   
      return `${res.toLocaleString('pt-br', {maximumFractionDigits: 2})} bilhões`  
    } else {
    
      return `${res.toLocaleString('pt-br', {maximumFractionDigits: 2})} bilhão`  
    }
  }else{
    const res = (number/1e12);
    if(res >= 2){
      return `${res.toLocaleString('pt-br', {maximumFractionDigits: 2})} trilhões`  
    } else {
      return `${res.toLocaleString('pt-br', {maximumFractionDigits: 2})} trilhão`  
    }
  }
}

type NumberAndSuffixMakeType = {
  number: number | string,
  suffix: string
}

export function NumberAndSuffixMake(number: number):NumberAndSuffixMakeType {
  if(!number){
    return {number: 0, suffix: ''}
  }
  if(number <= 1e3) {
    return {number: number.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: ''}
  } else if((number > 1e3) && (number < 1e6)){
    return { number: (number/1e3).toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'mil'  }
  }else if((number >= 1e6) && (number < 1e9)){
    const res = (number/1e6);
    if(res >= 2){
      return { number: res.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'milhões'  }
    } else {
      return { number: res.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'milhão'  }
    }
  }else if((number >= 1e9) && (number < 1e12)){

    const res = (number/1e9);
    if(res >= 2){
      return { number: res.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'bilhões'  }
    } else {
      return { number: res.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'bilhão'  }
    }
  }else{
    const res = (number/1e12);
    if(res >= 2){
      return { number: res.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'trilhões'  }
    } else {
      return { number: res.toLocaleString('pt-br', {maximumFractionDigits: 2}), suffix: 'trilhão'  }
    }
  }
}