import styled from 'styled-components';

export const Container = styled.div`
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 20px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .title-close-content {
    display: flex;
    justify-content: space-between;

    span {
      word-break: break-word;
      width: 80%;
      font-family: Nunito;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;

      color: #464A4E;
    }
    
    .overlay-title {
      font-size: 3rem;
      font-family: Nunito, sans-serif;
      text-align: center;
      align-self: center;
      word-break: break-word;
      width: 90%;
    }

    .close-family-container-button {
      background: transparent;
      border: 0;
      
      display: flex;
      justify-content: center;
      align-items: center;

      color: #C2102A;
      font-size: 2rem;
      font-family: Nunito, sans-serif;

      cursor: pointer;
      transition: filter .2s;
      align-self: flex-start;
      outline: 0;

      &:hover {
        filter: brightness(0.7);
      }
    }
  }

  

  .overlay-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;

    border-left: 3px solid #FFDE32;
    padding-left: 10px;

    span {
      font-size: 1.5rem;
      font-family: Nunito, sans-serif;

    }
  }

  .overlay-button-group {
    width: 100%;
    /* padding: 0 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    text-align: left;

    strong {
      align-self: flex-start;
    }

    .overlay-button-activity {
      border: 0;
      font-size: 1.4rem;
      font-family: Nunito, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 20px;;

      transition: background .2s;
      cursor: pointer;

      &.selected {
        background:  #4B9F37;
        color: #FFFF;
      }

      &:hover {
        background: #4B9F37;
        opacity:  0.4;
        color: #FFFFFF;
      }

      &:active {
        background:  #4B9F37;
        color: #FFFF;
      }
    }
  }

  .date-overlay-info {
    display: flex;

    align-items: center;
    gap: 5px;
  }
`;
