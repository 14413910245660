import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .ant-menu-submenu-title {
    font-weight: bold;
    font-size: 1.6rem;
    text-transform: uppercase;

  }
`;

export const ActivityMoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-left: 3px solid yellow;
  margin: 0 20px;
  padding: 10px 20px;

  gap: 30px;

`;

export const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100%;

  strong, span {
    font-size: 1.6rem;
    line-height: 1rem;
  }
`;