import { Rule } from "rc-field-form/lib/interface";

const rules: Record<string, Rule[]> = {
  occupation: [
    {
      required: true,
      message: 'Profissão não preenchida',
    }
  ],
  graduation: [
    {
      required: true,
      message: 'Graduação não preenchida',
    }
  ],
  admissionDate: [
    {
      required: true,
      message: 'Data de admissão não preenchida',
      type: 'date',
    }
  ],
  workload: [
    {
      required: true,
      message: 'Carga horária não preenchida',
    }
  ],
  city: [
    {
      required: true,
      message: 'Município alocado não preenchido'
    }
  ]
}

export default rules;
