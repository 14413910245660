import React, { useEffect, useState } from 'react';

import { Breadcrumb, Footer } from 'components';

import { TitlePage } from 'components/TitlePage';

import ButtonCMIC from 'components/ButtonCMIC';
import BaseMapCMIC from 'components/BaseMapCMIC';

import iconBaby from '../../assets/CMIC/iconBabyWhite.svg';
import iconHome from '../../assets/CMIC/iconHomeWhite.svg';
import iconGestante from '../../assets/CMIC/iconGestanteWhite.svg';
import logoMC from '../../assets/CMIC/logoMariaCecilia.svg';
import TableCMIC from 'components/TableCMIC';

import iconSolTop from '../../assets/CMIC/sol-top.svg';
import iconSolLow from '../../assets/CMIC/sol-low.svg';

import { getInfosMapCMIC } from 'services/resources/mapServices';

import { Device } from 'components/Device';

import './styles.scss';
import InfoProvide from 'components/InfoProvide';



const PesquisaCMIC = (): JSX.Element => {
  const [dadosCMIC, setDadosCMIC] = useState<any>([]);

  const device = Device.useDevice();


  useEffect(() => {
    const response = async () => {
      const data = await getInfosMapCMIC();
      setDadosCMIC([{title: "Cidades", number: data.qtdCities},
      {title: "Entrevistados", number: data.qtdInterviewee},
      {title: "Entrevistadores", number: data.qtdInterviewer }])
    
    };
    response(); 
  }, []);

  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/pesquisa-cmic',
      title: 'Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará',
    },
  ];

  const listInfoTeste2 = [
    {
      number: 0,
      title: 'Carregando...',
    },
    {
      number: 0,
      title: 'Carregando...',
    },
    {
      number: 0,
      title: 'Carregando...',
    },
  ];
  
  return (
    <>
      <section className="container-box">
        <section className="container__pesquisaCMIC">
          <div className="container__pesquisaCMIC-header">
            <Breadcrumb links={links} />
            <TitlePage
              style={{ margin: '0' }}
              title="Pesquisa"
              subtitle="Acompanhe os dados das famílias que recebem o benefício do Cartão Mais Infância"
              backButton
            />
          </div>
          <InfoProvide 
            message="Os dados abaixo foram coletados do Big Data Social a partir da base da Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará" 
            type="cemic"
          />
          <main className={device.prefix()}>

            <nav className="container__pesquisaCMIC-navigation">
              <section className="buttons-navigation">
                <h1 className="title-navigation">Categorias de dados</h1>
                <div className="container__navigation-buttons">
                  <ButtonCMIC title="Criança" image={iconBaby} type="crianca" />
                  <ButtonCMIC
                    title="Gestante"
                    image={iconGestante}
                    type="gestante"
                  />
                  <ButtonCMIC
                    title="Domicílio"
                    image={iconHome}
                    type="domicilio"
                  />
                </div>
              </section>
              <section className="pesquisa-navigation">
                <h1 className="title-pesquisa-navigation">A Pesquisa</h1>
                <p className="pesquisa-describe">
                  A pesquisa Mais Infância Ceará foi elaborada com apoio da
                  Fundação Maria Cecília Souto Vidigal para entrevistar as
                  famílias beneficiadas pelo Cartão Mais Infância. O objetivo é
                  avaliar o desenvolvimento infantil e monitorar os cuidados
                  oferecidos às crianças. <br />
                  Os dados foram coletados em entrevistas telefônicas por meio
                  de um aplicativo para celular.
                  <br /> A seguir, são apresentados os resultados para o estado
                  do Ceará, coletados em 2021.
                </p>
                <TableCMIC listInfos={dadosCMIC??listInfoTeste2} />
                <img
                  className="logo-fm"
                  src={logoMC}
                  alt="Logo Fundação Maria Cecília"
                />
              </section>
            </nav>
            <section className="container__pesquisaCMIC-mapa">
              <img className="sol-top" src={iconSolTop} alt="sol top" />
             <div className="title__map">
              <p className="title-map-sol">
                  Municípios onde a pesquisa foi aplicada e quantidade de famílias
                  que responderam o questionário.
                </p>
                {
                  device.isMD('bigger') ? (
                    <p className="title-map-observation">
                      Passe o mouse por cima dos municípios que estão em verde
                    </p>
                  ) : (
                    <p className="title-map-observation">
                      Clique em cima dos municípios que estão em verde
                    </p>
                  )
                }
               </div>
              <BaseMapCMIC />
              <img className="sol-low" src={iconSolLow} alt="sol baixo" />
              <div className="fix-scroll-map" />
            </section>
          </main>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default PesquisaCMIC;
