import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Select, Input, Form, Divider, message, Button, Pagination } from 'antd';

import {
  BottomContent,
  BottomTitle,
  BottomWrapper,
  Box,
  BoxButton,
  BoxDescription,
  BoxTitle,
  BoxWrapper,
  Container,
  Main,
  PageTitle,
  SearchResults,
  SearchResultsLink,
  SubTitle,
  PendingIcon,
  WorkplanBox,
  ButtonGroup,
  CancelButton,
  CommentContainer,
  TitleContainer,
  LeftContent,
  RightContent,
  MobileMain,
  MobileBottomContent,
  MobileBottomWrapper
} from "./styles";

import arrowPreviousGreen from '../../assets/arrow-prev-green.svg';
import pendingIcon from '../../assets/articulator/pendent-icon.svg'
// import likeIcon from '../../assets/articulator/like.svg';
import { AgentType, CommentProps, Coordinator, VisitationFamily, WorkplanType } from 'types';
import { commentServices, familiesService, localService, workPlanService } from 'services';
import ModalWorkplansHistoric from 'components/ModalWorkplansHistoric';
import { Authorizer, Device } from 'components';
import { coordinatorService } from 'services/resources/coordinatorService';
import { Comment } from 'components/Articulator/Comment';

const { Option } = Select;
const { Item } = Form;
const { TextArea } = Input;

export function CoordinatorArticulator() {

  const [countLikes, setCountLikes] = useState(0);

  const [agent, setAgent] = useState<AgentType>()

  const [coordinator, setCoordinator] = useState<Coordinator>({} as Coordinator)

  // Family search
  const [families, setFamilies] = useState<VisitationFamily[]>([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [neighborhood, setNeighborhood] = useState('');
  const [nis, setNis] = useState('');
  const [isFamilyLoading, setIsFamilyLoading] = useState(false);
  const [totalFamilies, setTotalFamilies] = useState<number>()
  const [selectedPage, setSelectedPage] = useState<number>(1)

  // Workplan Historic
  const [isWorkplanHistoricLoading, setIsWorkplanHistoricLoading] = useState(false)
  const [agentWorkplans, setAgentWorkplans] = useState<WorkplanType[]>()
  const [isWorkplanHistoricModalOpen, setIsWorkplanHistoricModalOpen] = useState(false)

  // Comment
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [isCommentButtonClicked, setIsCommentButtonClicked] = useState(false);

  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;

  const history = useHistory()
  const location = useLocation<AgentType>()

  // Device
  const device = Device.useDevice()

  useEffect(() => {

    coordinatorService.getCoordinatorByCPF(userId).then(data => {
      setCoordinator(data);
    });

    getGeneralCommentsAndObservation()
  }, [userId])

  useEffect(() => {
    if (location.state) {
      setAgent(location.state)
    }
    else history.push('/coordenador-home')
  }, [location, history]);

  const getAgentWorkplansCB = useCallback(getAgentWorkplans, [agent])
  const handleFamiliesSettingByPageCB = useCallback(handleFamiliesSettingByPage, [agent, neighborhood, nis, selectedPage])

  useEffect(() => {
    getAgentWorkplansCB()
  }, [getAgentWorkplansCB])

  useEffect(() => {
    handleFamiliesSettingByPageCB()
  }, [handleFamiliesSettingByPageCB])

  function handleFamiliesSettingByPage() {
    familiesService.getFamilyByCityNeighborhoodAndNis(
      selectedPage - 1,
      agent?.city,
      neighborhood,
      nis).then(data => {
        setFamilies(data.content)
      })
  }

  useEffect(() => {
    getNeighborhoodByAlocatedCityAgent(agent?.city)
  }, [agent]);

  function getAgentWorkplans() {
    workPlanService.getHistoricWorkPlans(agent?.id)
      .then(data => setAgentWorkplans(data))
  }

  function pendingWorkplansHandler(): number {
    let count: number = 0
    agentWorkplans?.map(workplan => {
      if (workplan.status === 'pending') {
        count++
      }
    })
    return count
  }

  function getAgentWorkplanApproved(){
    agentWorkplans?.map(wkpAgent => {
      if(wkpAgent.status === "APPROVED"){
        const workplanId = wkpAgent.id
        console.log(workplanId, agentWorkplans)
        history.push(`/coordenador-articulador-workplan/${workplanId}`, wkpAgent);
      }
    })
  }

  function handleCountLikes() {
    if (countLikes === 0)
      setCountLikes(1);
    else
      setCountLikes(0);
  }

  async function handleSearchForFamily() {
    setIsFamilyLoading(true);

    try {
      const response = await familiesService.getFamilyByCityNeighborhoodAndNis(0, agent?.city, neighborhood, nis);
      setFamilies(response.content);
      setTotalFamilies(response.total)

    } catch (err) {
      message.error('Não encontrado');
    }

    setIsFamilyLoading(false);
  }

  function handleNavigateToFamilyDetail(family: VisitationFamily) {
    history.push(`/acompanhamento-familia/${family.nameRF}`, family);
  }

  function handleNavigateToActivities() {
    history.push(`/articulador-plano-de-atividades/`, agent);
  }

  function handleWorkplanHistoric() {
    setIsWorkplanHistoricLoading(true)

    try {
      workPlanService.getHistoricWorkPlans(agent?.id).then(data => {
        setAgentWorkplans(data)
        setIsWorkplanHistoricModalOpen(true)
        setIsWorkplanHistoricLoading(false)
      })
    } catch (err) {
      message.error('Não encontrado')
    }
  }

  async function handleCreateComment() {
    if (!comment.trim())
      return;

    setIsCommentButtonClicked(true);

    const data = {
      content: comment,
      recipient: agent?.id
    };

    try {
      await commentServices.createCommentOnWorkplan(data);

      getGeneralCommentsAndObservation()

    } catch (err) {
      message.error('Não foi possível criar um comentário');
    }

    setIsCommentButtonClicked(false);
    setComment('');
  }

  function handlePagination(pageNumber: number) {
    setSelectedPage(pageNumber)
  }

  async function getGeneralCommentsAndObservation() {
    try {
      const response = await commentServices.getGeneralCommentsAndObservation();

      setComments(response);

    } catch (err) {
      message.error('Erro ao carregar comentários gerais')
    }
  }

  async function getNeighborhoodByAlocatedCityAgent(city: string | undefined) {
    try {
      const response = await localService.getNeighborhoodByCity(city);
      setNeighborhoods(response);

    } catch (err: any) {
      message.error(err.response?.data?.mensagem || 'Não foi possível pegar famílias do bairro especificado');
    }
  }

  return (
    <Container>
      {
        device.isSM() ?
          (
            <>
              <Main>

                <ModalWorkplansHistoric
                  isOpen={isWorkplanHistoricModalOpen}
                  closeModal={() => { setIsWorkplanHistoricModalOpen(false) }}
                  workplans={agentWorkplans}
                />

                <TitleContainer>
                  <LeftContent>
                    <Link to="/coordenador-home">
                      <img src={arrowPreviousGreen} alt="back to articulator home" />
                    </Link>
                  </LeftContent>

                  <RightContent>
                    <PageTitle>
                      {agent?.name}
                    </PageTitle>
                    <SubTitle>
                      Agente de {agent?.city}
                    </SubTitle>
                  </RightContent>

                </TitleContainer>


                <BoxWrapper>
                  <WorkplanBox>
                    <BoxTitle>Plano de Trabalho</BoxTitle>
                    <BoxDescription>
                      {pendingWorkplansHandler() > 0 ? (<><PendingIcon src={pendingIcon} alt="" />{pendingWorkplansHandler()} novo(s) envio(s) de {agent?.name.split(' ')[0]}</>) : <></>}
                    </BoxDescription>

                    <BoxButton onClick={getAgentWorkplanApproved}> 
                      Plano de Trabalho
                    </BoxButton>

                    <Divider />

                    <Button
                      onClick={handleWorkplanHistoric}
                      className='search'
                      loading={isWorkplanHistoricLoading}>
                      Histórico de Planos
                    </Button>

                  </WorkplanBox>

                  <Box>
                    <BoxTitle>Plano de Atividades</BoxTitle>
                    <BoxDescription>
                    </BoxDescription>

                    <BoxButton onClick={handleNavigateToActivities} >
                      Plano de Atividades
                    </BoxButton>
                  </Box>

                </BoxWrapper>
              </Main>

              <BottomContent>
                <BottomTitle>
                  Acompanhamento de famílias
                </BottomTitle>

                <BottomWrapper>
                  <Item label="Bairro">
                    <Select
                      showSearch
                      value={neighborhood}
                      defaultValue=""
                      onChange={setNeighborhood}
                    >
                      <Option value="Selecionar">Selecione um bairro</Option>
                      {neighborhoods.map(neighbor => (
                        <Option key={neighbor} value={neighbor}>{neighbor}</Option>
                      ))}
                    </Select>
                  </Item>
                  <Item label="Número do NIS">
                    <Input
                      maxLength={11}
                      minLength={11}
                      value={nis}
                      onChange={e => setNis(e.target.value)}
                    />
                  </Item>
                  <Item label="." className="centered">
                    <Button
                      type="primary"
                      onClick={handleSearchForFamily}
                      style={{
                        background: '#4B9F37',
                        textTransform: 'uppercase',
                        border: 0,
                        fontWeight: 'bold',
                        maxWidth: '100%'
                      }}
                      loading={isFamilyLoading}
                    >
                      Buscar Família
                    </Button>
                  </Item>

                </BottomWrapper>
                <Divider />
                {
                  totalFamilies ? (<>
                    <SearchResults>
                      {families.length > 0 && families.map(family => (
                        <SearchResultsLink
                          key={family.id}
                          onClick={() => handleNavigateToFamilyDetail(family)}
                        >
                          {family.nameRF}
                        </SearchResultsLink>
                      ))}
                    </SearchResults>
                    <Pagination
                      className="pagination"
                      defaultCurrent={1}
                      total={totalFamilies}
                      pageSize={25}
                      showSizeChanger={false}
                      onChange={handlePagination} />
                  </>) : (<></>)
                }
              </BottomContent>

            </>


          )
          : (
            <>
              <MobileMain>
                <ModalWorkplansHistoric
                  isOpen={isWorkplanHistoricModalOpen}
                  closeModal={() => { setIsWorkplanHistoricModalOpen(false) }}
                  workplans={agentWorkplans}
                />

                <TitleContainer>
                  <LeftContent>
                    <Link to="/coordenador-home">
                      <img src={arrowPreviousGreen} alt="back to articulator home" />
                    </Link>
                  </LeftContent>

                  <RightContent>
                    <PageTitle>
                      {agent?.name}
                    </PageTitle>
                    <SubTitle>
                      Agente de {agent?.city}
                    </SubTitle>
                  </RightContent>

                </TitleContainer>


                <BoxWrapper>
                  <WorkplanBox>
                    <BoxTitle>Plano de Trabalho</BoxTitle>
                    <BoxDescription>
                      {pendingWorkplansHandler() > 0 ? (<><PendingIcon src={pendingIcon} alt="" />{pendingWorkplansHandler()} novo(s) envio(s) de {agent?.name.split(' ')[0]}</>) : <></>}
                    </BoxDescription>

                    <BoxButton>
                      Plano de Trabalho
                    </BoxButton>

                    <Divider />

                    <Button
                      onClick={handleWorkplanHistoric}
                      className='search'
                      loading={isWorkplanHistoricLoading}>
                      Histórico de Planos
                    </Button>

                  </WorkplanBox>

                  <Box>
                    <BoxTitle>Plano de Atividades</BoxTitle>
                    <BoxDescription>
                    </BoxDescription>

                    <BoxButton onClick={handleNavigateToActivities} >
                      Plano de Atividades
                    </BoxButton>
                  </Box>

                </BoxWrapper>
              </MobileMain>

              <MobileBottomContent>
                <BottomTitle>
                  Acompanhamento de famílias
                </BottomTitle>

                <MobileBottomWrapper>
                  <Item label="Bairro">
                    <Select
                      showSearch
                      value={neighborhood}
                      defaultValue=""
                      onChange={setNeighborhood}
                    >
                      <Option value="Selecionar">Selecione um bairro</Option>
                      {neighborhoods.map(neighbor => (
                        <Option key={neighbor} value={neighbor}>{neighbor}</Option>
                      ))}
                    </Select>
                  </Item>
                  <Item label="Número do NIS">
                    <Input
                      maxLength={11}
                      minLength={11}
                      value={nis}
                      onChange={e => setNis(e.target.value)}
                    />
                  </Item>
                  <Item label="." className="centered">
                    <Button
                      type="primary"
                      onClick={handleSearchForFamily}
                      style={{
                        background: '#4B9F37',
                        textTransform: 'uppercase',
                        border: 0,
                        fontWeight: 'bold',
                        maxWidth: '100%'
                      }}
                      loading={isFamilyLoading}
                    >
                      Buscar Família
                    </Button>
                  </Item>

                </MobileBottomWrapper>
                <Divider />
                {
                  totalFamilies ? (<>
                    <SearchResults>
                      {families.length > 0 && families.map(family => (
                        <SearchResultsLink
                          key={family.id}
                          onClick={() => handleNavigateToFamilyDetail(family)}
                        >
                          {family.nameRF}
                        </SearchResultsLink>
                      ))}
                    </SearchResults>
                    <Pagination
                      className="pagination"
                      defaultCurrent={1}
                      total={totalFamilies}
                      pageSize={25}
                      showSizeChanger={false}
                      onChange={handlePagination} />
                  </>) : (<></>)
                }
              </MobileBottomContent>

            </>

          )
      }


      <CommentContainer>
        <div className="comment-wrapper">
          <Comment
            comments={comments}
            onLike={handleCountLikes}
            countLikes={countLikes}
            title="Observações e comentários gerais"
          />

          <TextArea
            value={comment}
            onChange={e => setComment(e.target.value)}
            onPressEnter={handleCreateComment}
            disabled={isCommentButtonClicked}
          />

          <ButtonGroup>
            <CancelButton type="reset" onClick={() => setComment('')}>
              Cancelar
            </CancelButton>

            <Button
              type="primary"
              onClick={handleCreateComment}
              className="comment-button"
              loading={isCommentButtonClicked}
              style={{
                background: '#4B9F37',
                border: 0
              }}>
              Comentar
            </Button>
          </ButtonGroup>
        </div>
      </CommentContainer>

    </Container>
  );
}
