import { Col, Form, Input, Radio, Row, Select, Typography } from 'antd'
import React from 'react'
//import rules from '../rules';
import { StyledForm, StyledFormWrapper, StyledTitle, StyledVerticalLine } from 'components/UI/FormBox/styles';
import TextArea from 'antd/lib/input/TextArea';

const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;

export function DemandDataForm() {
    return (
        <>
            <StyledTitle>
                <Title level={3}>Dados da Demanda</Title>
            </StyledTitle>
            <StyledFormWrapper>
                <StyledVerticalLine />
                <StyledForm>

                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                            <Item label="Data da Visita" name="dataVisita">
                                
                            </Item>
                        </Col>

                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                            <Item label="Data da Criação da Demanda" name="dataDemanda">
                                
                            </Item>
                        </Col>
                    </Row>

                    <Row>
                        
                        <Col xxl={11} lg={11} md={24} xs={24}>
                          <Item label="Status" name="status">
                              <Radio value="true">Realizado</Radio>
                              <Radio value="false">Não Realizado</Radio>
                          </Item>
                        </Col>

                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                            <Item label="Tipo da Demanda " name="competence">
                                <Select>
                                    <Option value="Saude">Saúde</Option>
                                    <Option value="Educacao">Educação</Option>
                                    <Option value="Habitacao">Habitação</Option> 
                                </Select>
                            </Item>
                        </Col>
                    </Row>

                    <Col xxl={11} lg={11} md={24} xs={24}>
                        <Item label="Orgão Que Recebeu" name="orgao">
                          <Input />
                        </Item>
                    </Col>

                    <Col xxl={11} lg={11} md={24} xs={24}>
                        Descrição da demanda:
                        <TextArea name='Descrição da Demanda'>
                        </TextArea>
                    </Col>



                </StyledForm>
            </StyledFormWrapper>
        </>
    )
}