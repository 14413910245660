import credes from '../data/optionsCREDES.json';

export function makeOptitionsNamesCurrentCREDES(municipios: string[]){
  let newCreds:any = [];


  municipios.forEach(municipio => {
    credes.forEach(cred => {
      if(cred[0].includes(municipio) && !newCreds.includes(cred[1])) {
        newCreds.push(cred[1]);
      };
    })

  })
  return newCreds;
}

export function makeOptitionsCitiesCurrentCREDES(credesSelect: string[]){
  let newCreds:any = [];

  credesSelect.forEach(credeSelect => {
    credes.forEach(cred => {
      if(cred[1] === credeSelect) {
        newCreds.push(...cred[0]);
      };
    })

  })
  return newCreds;
}

export function makeOptitionsNamesCREDES(){
  return credes.map(cred => {
    return cred[1]
  });
}