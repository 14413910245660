import { citizenByUserApi } from '../api';
import { Communication, Personal } from 'types';

const service = () => {
  const resource = '/user-details';

  async function getPersonal(cpf: string) {
    try {
      const response = await citizenByUserApi
        .get<Personal>(`${resource}/personal/${cpf}`, { params: { isValid: true } });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getCommunication(cpf: string) {
    try {
      const response = await citizenByUserApi
        .get<Communication>(`${resource}/communication/${cpf}`, { params: { isValid: true } });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function patchCommunication(data: any) {
    try {
      const response = await citizenByUserApi
        .patch(`${resource}/communication/${data.userId}`, data)

      return response.data
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function patchPersonal(data: any) {
    try {
      const response = await citizenByUserApi
        .patch(`${resource}/personal/${data.userId}`, data)

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    getPersonal,
    getCommunication,
    patchPersonal,
    patchCommunication,
  }
}

export const userDetailsService = service();