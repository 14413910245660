import React, { useEffect, useRef } from 'react';

import { Breadcrumb, Device, FiltroHistoricoAcao } from '../../components';


import {
  IoChevronDownCircleOutline,
  IoChevronUpCircleOutline,
} from 'react-icons/io5';


import labels from '../../data/labelKeys.json';

import './styles.scss';
import { Table, Tooltip } from 'antd';

import { IoPencilSharp, IoTrashOutline } from 'react-icons/io5';

import { Link } from 'react-router-dom';
import { useHistoryAcao } from 'utils/context/historyAcaoContext';
import ModalConfirmaExclusaoEvento from 'components/ModalConfirmaExclusaoEvento';
import ModalConfirmacaoInfo from 'components/ModalConfirmacaoInfo';
import { TitlePage } from 'components/TitlePage';

import { infosActions } from 'pages/NewRegisterAction/InfosActions';
import { useState } from 'react';

import './styles.scss';
import { getEventosByFiltro, getInfosAction } from 'services/resources/acoesServices';
import { LoaderInfoAction } from 'components/LoaderInfoAction';
import { AiOutlineDownload } from 'react-icons/ai';
import ModalDownloadAcoes from 'components/ModalDownloadAcoes';

/* Definindo tipo do props do componente*/
type HistoricoAcaoProps = {
  match: {
    params: {
      id: string;
    };
  };
  
}

type Infos = {
  id: string;
  nome: string;
}

const RecursivePrint = ({ data, position }) => {
  return (
    <>
      {data?.map(element => {
        return Array.isArray(element.title) ? (
          <div className={'lista id' + position}>
            <RecursivePrint data={element.title} position={position + 1} />
          </div>
        ) : (
          <div>
            <p>
              <strong>{labels[element.dataKey]}:</strong> {element.title}
            </p>
          </div>
        );
      })}
    </>
  );
};

const MakeExpandedRowContent = ({ record }) => {
  const [elemento, setElemento] = useState<any>();

  useEffect(() => {
    let objeto: any = [];
    objeto = [];
    retornarObjeto(record, objeto);
    setElemento(objeto);
  }, [record]);

  return (
    <div className="expanded-row-render-action">
      <RecursivePrint data={elemento} position={0} />
    </div>
  );
};

function retornarObjeto(obj, objectLine) {
  for (let key in obj) {
    if (
      key === 'classe' ||
      key === 'ultimaModificacao' ||
      key === 'id' ||
      key === 'key' ||
      key === 'tipo' ||
      key === 'anexos' ||
      key === 'ultimoEditor' ||
      key === 'arquivosRegistro' ||
      key.includes('/') ||
      key.includes('formacaoSeduc') ||
      key.includes('data')
    )
      continue;

    if (typeof obj[key] === 'object' && Array.isArray(obj[key]) === false)
      retornarObjeto(obj[key], objectLine);
    else if (Array.isArray(obj[key])) {
      if (typeof obj[key][0] === 'string')
        objectLine.push({ title: obj[key].join(', '), dataKey: key });
      else {
        let objAtual: any = { title: [], dataKey: key };
        let novo: any = {};
        obj[key].forEach(valor => {
          novo = { title: [], dataKey: key };
          retornarObjeto(valor, novo.title);
          objAtual.title.push(novo);
        });
        objectLine.push(objAtual);
      }
    } else {
      if (key.includes('data'))
        objectLine.push({
          title: new Date(obj[key]).toLocaleDateString('fr-FR'),
          dataKey: key,
        });
      else objectLine.push({ title: obj[key], dataKey: key });
    }
  }
}

const HistoricoAcao = ({ match }: HistoricoAcaoProps) => {
  const componentRef = useRef<any>();

  /* Usando o contexto do Historico de uma obra ou ação*/
  const {
    updateEventsList,
    actionInfos,
    toggleModalAcaoConfirmacaoExclusao,
    setIdEventAcaoSelected,
    page,
    setPage,
    isLoading,
    setIsLoading,
    eventsList,
    selectedCitiesList,
    selectedRegiaoList,
    selectedTime,
    selectedType,
    filtered
  } = useHistoryAcao();

  const device = Device.useDevice();

  const [columns, setColumns] = useState<any>();

   const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);

  var element_scroll: any =
    document.getElementsByClassName('ant-table-wrapper')[0] || HTMLInputElement;


  const [currentActionInformation, setCurrentActionInformation] = useState<Infos>();
  const [emptyInfosAction, setEmptyInfosAction] = useState(false);


  if(element_scroll) {
    element_scroll.scrollLeft = 10000000
  }


  const [isFilterType, setIsFilterType] = useState(true)

  const isFilter = filtered && ( selectedTime?.length > 0 || selectedCitiesList?.length > 0  || selectedRegiaoList?.length > 0 || selectedType ) ? true : false

  useEffect(() => {
    const render = {
      title: 'Ações',
      dataIndex: 'acoes',
      key: 'action',
      render: (text, record) => {

        const tipo = record?.tipo
    
        return tipo !== 'Visita Municipio' && tipo !== 'Encontros Comunitários' ? (
          <div className="actions-buttons">
            <Link to={`/editar-acao/${currentActionInformation?.id}/${record?.id}`}>
              <button>
                <IoPencilSharp size={23} color={'var(--green-light)'} />
              </button>
            </Link>
            <button onClick={() => showModalAndSelectEvent(record.id)}>
              <IoTrashOutline size={23} color={'var(--green-light)'} />
            </button>
          </div>
        ) : (
          <></>
        );
      },
    };
  
    const ultimaModificacao = {
      title: 'Ultima modificação',
      dataIndex: 'ultimaModificacao',
      render: function (text, record, index) {
        return new Date(text).toLocaleDateString('pt-BR');
      },
    };
  
    for (var property in infosActions) {
      if (currentActionInformation?.nome === infosActions[property].nome) {
        setColumns(
          infosActions[property].columns
            .concat(ultimaModificacao)
            .concat(render),
        );
      }
    }

    if(currentActionInformation){
      const WithType = ['Mais Nutrição', 'Padin', 'Criança Feliz'].includes(currentActionInformation.nome)
      setIsFilterType( WithType && (filtered && selectedType) ? true : !WithType && true)
    }
  }, [currentActionInformation]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if(currentActionInformation){
      const WithType = ['Mais Nutrição', 'Padin', 'Criança Feliz'].includes(currentActionInformation.nome)
      setIsFilterType( WithType && (filtered && selectedType) ? true : !WithType && true)
    }
  }, [currentActionInformation, filtered, selectedType]) 


  /* Pegando os eventos da API */
  useEffect(() => {
    let infos
    const responseInfo = async () => {
      infos = await getInfosAction(match.params.id)

      if(infos){
        setCurrentActionInformation(infos);
        setIsLoading(true)

        const response = async () => {
          const data = await getEventosByFiltro(match.params.id, page - 1, selectedCitiesList, selectedTime, selectedRegiaoList, selectedType, 10);
          updateEventsList(data, infos?.nome);
        };
    
        response().finally(() => setIsLoading(false));
      }
    }

    responseInfo().catch(() => {setEmptyInfosAction(true)})

    

  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    
  }, [])

  /* Abre o modal e pega o id do evento selecionado */
  const showModalAndSelectEvent = (idEvent: string) => {
    toggleModalAcaoConfirmacaoExclusao();
    setIdEventAcaoSelected(idEvent);
  };

  const onChangePage = page => {
    setPage(page);
  };

  return (
    currentActionInformation === undefined ? (
      emptyInfosAction ? 
      <p style={{textAlign: 'center', marginTop: '20rem'}}>
        Ocorreu um erro!
      </p> :
        <LoaderInfoAction />
    ) : (
      <>
      <div className="historico-acao-container container-box">
        <Breadcrumb links={[
          {
            path: '/',
            title: 'Página Inicial',
          },
          {
            path: '/home-servidor',
            title: 'Lista de Equipamentos e ações',
          },
      
          {
            path: `/historico-acao/${currentActionInformation.id}`,
            title: `Histórico de registros de ${currentActionInformation.nome}`,
          },
        ]} />
        <TitlePage
          title={`Histórico de registros de ${currentActionInformation.nome}`}
          backButton
        />

        <div className={`content-container table-${device.prefix()}`}>
          <FiltroHistoricoAcao idAcao={currentActionInformation.id} nameActionCurrent={currentActionInformation.nome} />

          <div
            className={`title-icons-container ${
              eventsList?.length > 0 && !isLoading ? '' : 'solo'
            }`}
          >
            {!isLoading && (
              <strong>Total: {actionInfos?.total}</strong>
            )}

             {eventsList.length > 0 && !isLoading && (
              <div className="content-icons-download">
                <Tooltip title={isFilterType ? 'Download' : 'Filtre por um tipo de registro antes disso!'} placement={!isFilterType ? 'left' : 'top'}>
                  <button  disabled={!isFilterType} onClick={() => setIsOpenModalDownload(true)}>
                    <AiOutlineDownload color="var(--color-primary)" size={20} />
               
                  </button>
                </Tooltip>
               
                {isOpenModalDownload && (
                  <ModalDownloadAcoes
                    onOpenModal={setIsOpenModalDownload}
                    infos={currentActionInformation}
                    total={eventsList.length}
                    type={'acao'}
                  />
                )}
              </div>
            )}

            {!isLoading && (
              <Link to={`/registrar-acao/${currentActionInformation.id}`}>
                REGISTRAR NOVA EDIÇÃO
              </Link>
            )}  
          </div>
          <div ref={componentRef}>
            <Table
              columns={columns}
              dataSource={eventsList}
              locale={
                { emptyText: 
                  isFilter ? 'Sem resultados para o filtro aplicado' : 'Esta ação não possui eventos no momento!' 
                }
              }
              loading={isLoading}
              size={'middle'}
              pagination={{
                position: ['bottomCenter'],
                onChange: onChangePage,
                current: page,
                total: actionInfos?.total,
                simple: true
              }}
              expandable={{
                expandedRowRender: record => (
                  <MakeExpandedRowContent record={record} />
                ),
                expandIcon: ({ expanded, onExpand, record }) =>

                  (record?.tipo === 'Visita Municipio' || record?.tipo === 'Encontros Comunitários') &&
                  (expanded ? (
                    <button onClick={e => onExpand(record, e)}>
                      <IoChevronUpCircleOutline
                        color="var(--color-primary)"
                        size={17}
                      />
                    </button>
                  ) : (
                    <button onClick={e => onExpand(record, e)}>
                      <IoChevronDownCircleOutline
                        color="var(--color-primary)"
                        size={17}
                      />
                    </button>
                  )),
              }}
            />
          </div>
        </div>
      </div>

      <ModalConfirmaExclusaoEvento type="Ação" id={currentActionInformation.id} nameActionCurrent={currentActionInformation.nome}/>
      <ModalConfirmacaoInfo type="Ação"/>
    </>
    )
  )
   
};

export default HistoricoAcao;
