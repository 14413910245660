import React from 'react';
import { FormInstance } from 'antd';
import { FormContainer } from './style';
import { LocForm } from './LocForm';
import { ConstValuesForm } from './ConstValuesForm';
import { InfoForm } from '../FormResponsavel/InfoForm';
import { ActionValues } from './ActionValues';
import { PraiaAcessivelForm } from './PraiaAcessivelForm';
import { NucleoForm } from './NucleoForm';

interface FormProps {
  form?: FormInstance;
  action?: any;
  disabled?: boolean;
}

interface PraiaProp {
  form?: FormInstance;
  action?: any;
}

interface OutherProp {
  form?: FormInstance;
  action?: any;
  data: any;
  setData: (value: any) => void;
}
export function Form({ form }: FormProps) {
  return (
    <FormContainer>
      <LocForm form={form} />
    </FormContainer>
  );
}

Form.PraiaAcessivelForm = function ({ form }: PraiaProp) {
  return (
    <FormContainer>
      <PraiaAcessivelForm form={form} />
    </FormContainer>
  );
};

Form.ConstValuesForm = function ({ form }: FormProps) {
  return (
    <FormContainer>
      <ConstValuesForm form={form} />
    </FormContainer>
  );
};

Form.ActionValues = function ({ form, action, disabled = false }: FormProps) {
  return (
    <FormContainer>
      <ActionValues form={form} action={action} disabled={disabled} />
    </FormContainer>
  );
};

Form.NucleoForm = function ({ form, data, setData }: OutherProp) {
  return (
    <FormContainer>
      <NucleoForm form={form} data={data} setData={setData} />
    </FormContainer>
  );
};

Form.FormInfoResponsavel = function ({ form }: FormProps) {
  return (
    <FormContainer>
      <InfoForm form={form} />
    </FormContainer>
  );
};
