import React, {  useContext, useEffect, useState } from 'react';
import { WaveLoading } from 'react-loadingg';

import SliderMobile from 'components/SliderMobile';
import ModalCard from '../ModalTimeline';

import { TimelineContext } from 'utils/context/timelineContext';
import StepsMobile from './stepsMobile';

import { Select } from 'antd';

import './styles.scss';
import regioesCeara from '../../data/regioes_municipios.json';

const { Option } = Select;

type Timeline2MobileProps = {
  onDataUpgrad: (value: any) => void;
  getData: () => void;
  data: any;
}

const Timeline2Mobile = ({getData, data, onDataUpgrad}: Timeline2MobileProps): JSX.Element => {
  const { setCurrentYearScroll, dataRange,  setCitiesSelecteds, regionSelected, setRegionSelected } = useContext(TimelineContext);

  const [isClickStep, setIsClickStep] = useState(false);
  let options = {
    rootMargin: '0px 0px -75% 0px ',
    threshold: 0.5,
  };

  const filterCity = regioesCeara.map(city => {
    return city['região'].toUpperCase(); 
  });

  function callbackFunctionEntries(entries) {
    const currentYearScrollObserver = entries[0].target.id;

    // so altera o indice do componente de scroll se nao for pelo click

    if (!isClickStep) {
      setCurrentYearScroll(currentYearScrollObserver);
    }
    setIsClickStep(false);
  }

  const observer = new IntersectionObserver(callbackFunctionEntries, options);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [dataResumeTimeline, setDataResumeTimeline] = useState([]);

  const [currentResumeModal, setCurrentResumeModal] = useState(null);


  useEffect(() => {

    data.forEach(ano => {
      const currentScrollAno = document.getElementById(`${ano.ano}`);

      if (currentScrollAno) observer.observe(currentScrollAno);
    });
    resumeTimelineApi()
    return () => {
      setDataResumeTimeline([]); // This worked for me
    };
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

 
  useEffect(() => {
    getData();
    return () => {
      onDataUpgrad({}); // This worked for me
    };
  }, [dataRange]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectedRegion = (value: string) => {
    setRegionSelected(value);
    if(value.length === 0){
      setCitiesSelecteds([])
    }else{
      setCitiesSelecteds(regioesCeara.filter(r => r['região'].toUpperCase() === value)[0].municipios); 
    }
  };

  function resumeTimelineApi(){

    const newResumeTimeline = data?.map((yearInfo)=> {
      let listResume: any[] = [];
      yearInfo.meses.forEach(iniciativa => {
        iniciativa.iniciativasRes?.forEach((iniciativaInterna:any) => listResume.push(iniciativaInterna))
      })
      return {id: yearInfo.ano, resumo: listResume}
    });

    setDataResumeTimeline(newResumeTimeline);
  }

  return (
    <>
      <div className="container__timeline-mobile">
        <StepsMobile allYear={data} onClickStep={setIsClickStep} />
        
        <div
          id="box-timeline"
          className="container__timeline-relative"
          style={{ top: `-${data.length * 12 + 260}px` }}
        > 
        
          {data.length > 0? ( 
            <>
              {data?.map((forYear, index) => {
                return (
                  <section
                    key={`timeline-relative-${forYear.ano}`}
                    className="mobile__timeline"
                  >
                    <div id={forYear.ano} className="mobile__timeline-header">
                      <h1>{forYear.ano}</h1>

                      <button
                        type="button"
                        onClick={() => {
                          setIsOpenModal(true);
                          setCurrentResumeModal(dataResumeTimeline[index]);
                        }}
                      >
                        Totais
                      </button>
                      
                    </div>
                    <div className="mobile__timeline__city">
                    <Select placeholder="Selecione uma região" value={regionSelected} onChange={onSelectedRegion} style={{ width:220, fontSize:10 }}>
                           <Option value={""} style={{ color: "#aaaaaa", fontSize:10 }} >TODOS AS REGIÕES</Option>
                            { filterCity.sort().map( cityName => 
                              (
                                <Option value={cityName} style={{ fontSize:10 }}>{cityName}</Option>
                              ))
                            }
                      </Select>
                    </div>
                    <div className="mobile__timeline-wrapper">
                      <SliderMobile forYear={forYear} />
                    </div>
                  </section>
                );
              })}
            </>
          ) : (
            <section className="mobile__timeline loading">
              <div className="mobile__timeline-header">
                <WaveLoading />
              </div>
              <div className="mobile__timeline-wrapper">
                <SliderMobile />
              </div>
            </section>
          )}
        </div>
        {isOpenModal && (
          <ModalCard
            onCloseModal={setIsOpenModal}
            dataModal={currentResumeModal ?? null}
          />
        )}
      </div>
    </>
  );
};

export default Timeline2Mobile;
