import { css } from 'styled-components';

export const menuSlice = css`
  cursor: pointer;
  color: #FFFDC1;
  background: radial-gradient(
    transparent
      ${({ centerRadius }) => `${centerRadius}, #FFCE1C ${centerRadius}`}
  );
  &[filled='true'] {
    color: #F56E36;
  }
  &:hover,
  &[active='true'] {
    color: #F56E36;
    background: radial-gradient(
      transparent
        ${({ centerRadius }) => `${centerRadius}, #FFE972 ${centerRadius}`}
    );
    img {
      filter: invert(55%) sepia(96%) saturate(2473%) hue-rotate(338deg) brightness(98%) contrast(100%); 
    }
  }
  img {
    height: 26px;
  }
`;

export const subMenuSlice = css`
  cursor: pointer;
  color: #fff;
  background: radial-gradient(
    transparent
      ${({ centerRadius }) => `${centerRadius}, #FD9D27 ${centerRadius}`}
  );
  &[filled='true'] {
    color: #F56E36;
  }
  &:hover,
  &[active='true'] {
    color: #FFCE1C;
    background: radial-gradient(
      transparent
        ${({ centerRadius }) => `${centerRadius}, #FB6B32 ${centerRadius}`}
    );
  }
`;
