import { Rule } from "rc-field-form/lib/interface";

const rules: Record<string, Rule[]> = {
  hasChildren: [
    {
      required: true,
      message: 'Pergunta não respondida',
    }
  ],
  numChildren: [
    {
      required: true,
      message: 'Quantidade de filhos não informada',
    }
  ]
}

export default rules;