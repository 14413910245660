import React from 'react';
import {
  Form,
  FormInstance,
  Input,
  DatePicker,
  Row,
  Col
} from 'antd';
import { FormBox, InputMoney } from '../../UI/index';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface ConstValuesFormProps {
  form?: FormInstance;
}

export function ConstValuesFormConfirm({ form }: ConstValuesFormProps) {
  const currentForm = form;

  return (
    <FormBox title="Informações do Evento" form={currentForm}>
      <Item
        label="Nome"
        name="nome"
        wrapperCol={{ span: 14 }}
      >
        <Input defaultValue="Não informado" disabled={true} bordered={false}/>
      </Item>
      <Item
        label="Período"
        name="periodo"
        wrapperCol={{ span: 6 }}
      >
        <RangePicker format={'DD/MM/YYYY'} disabled={true} bordered={false} locale={locale}/>
      </Item>
      <Row>
        <Col span={6}>
          <Item
            label="Valor Investido"
            name="valorInvestido"
          >
            <InputMoney disabled={true} bordered={false}/>
          </Item>
        </Col>
        <Col span={6} offset={1}>
          <Item
            label="Valor Aditivo"
            name="valorAditivo"
          >
            <InputMoney disabled={true} bordered={false}/>
          </Item>
        </Col>
      </Row>
    </FormBox>
  );
};
