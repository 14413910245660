import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 auto 30px;

  .ant-form-item-label {
    font-weight: bold;
  }

  .ant-row.ant-form-item {
    margin-bottom: 2rem;
  }
`;
