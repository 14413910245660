
import React, { CSSProperties, useEffect, useState } from 'react';
import { getLastUtilization } from 'services/resources/criancaServices';

import './styles.scss';

interface QuemSomosProps {
  message: string;
  type: "webmapp" | "cemic";
  style?: CSSProperties
}

const QuemSomos = ({
  message,
  type,
  style
}: QuemSomosProps) => {

  const [lastModificationDate, setLastModificationDate] = useState('');

  useEffect(() => {
    const response = async () => {
      const data = await getLastUtilization();
      setLastModificationDate(new Date(data).toLocaleDateString('pt-br'));
    };
    response();
  }, []);

  return (
    lastModificationDate ? (
      <p className="info" style={style}>
        {`*${message}, com última atualização no dia ${type === 'cemic' ? (lastModificationDate) : new Date().toLocaleDateString('pt-br')}.`}
      </p>
    ) : (
      <></>
    )

  );
};

export default QuemSomos;
