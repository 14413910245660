import polos from '../data/polosPadin.json';

export function makeOptitionsNamesCurrentPOLOS(municipios: any){
  let newPolos:any = [];


  municipios.forEach(municipio => {
    polos.forEach(polo => {
      if(polo.municipios.includes(municipio) && !newPolos.includes(polo.polo)) {
        newPolos.push(polo.polo);
      };
    })

  })
  return newPolos;
}

export function makeOptitionsCitiesCurrentPOLOS(polosSelect: any){
  let newPolos:any = [];

  polosSelect.forEach(poloSelect => {
    polos.forEach(polo => {
      if(polo.polo === poloSelect) {
        newPolos.push(...polo.municipios);
      };
    })

  })

  return newPolos;
}

export function makeOptitionsNamesPOLOS(){
  return polos.map(polo => {
    return polo.polo
  });
}

export function makeOptitionsAllPOLOS(municipios: any){
  let newPolos:any = [];

  municipios.forEach(municipio => {
    polos.forEach(polo => {
      if(polo.municipios.includes(municipio) && !newPolos.includes(polo)) {
        newPolos.push(polo);
      };
    })

  })

  return newPolos;
}