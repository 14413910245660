import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  background: #FFF;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem 4rem;
  max-width: 1600px;

  .ant-affix {
    z-index: 999;
    top: 3.8rem;
  }

  .ant-tabs {
    background: #FFF;
    width: 100%;

    .ant-tabs-nav {
      width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .ant-tabs-tab {
      padding: 0;
    }

    .ant-tabs-tab-btn button {
      background: transparent;
      border: 0;
      padding: 12px 0;
      height: 100%;
      cursor: pointer;
    }

    .ant-tabs-tab.ant-tabs-tab-disabled {
      opacity: 0.5;
    }
    align-items: center;
    
    span.tab-title {
      color: #707070;
      cursor: pointer;
      font: 700 1.6rem Nunito;
      margin: 0;

      &.less {
        font-size: 1.4rem;
      }
 
    }
    button.ant-tabs-nav-more {
      color: #333;
    }
	}

	.ant-tabs-content-holder {
    width: 100%;
		position: 0;
	}

	.ant-tabs-ink-bar-animated {
		background: yellow;
	}


  //novo conteudo
  .tabs-content {
    background: #FFF;
    justify-content: space-between;
    display: flex;
    z-index: 999;
    overflow-x: auto;
    width: 100%;
    padding: initial;
    margin: initial;
    
    margin-bottom: 2rem;

    &::-webkit-scrollbar {
      width: 0;
    }
    .tab-button-container {
      margin: 1rem 1rem 0 1rem;
      transform: translate(0px, 0px);
      position: relative;

      .tab-button {
        background: transparent;
        border: 0;
        color: #707070;
        cursor: pointer;
        font: 700 1.6rem Nunito;
        margin: 0;
        outline: 0;
        min-width: 100px;
        /* margin-bottom: 2rem; */
     

        &.active {
          /* border-bottom: 3px solid yellow; */
        }

        
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .bottom-active-tab {
        /* position: absolute;
        bottom: 0; */
        margin-top: 1.2rem;
        width: 100%;
        height: 3px;
        background: yellow;
        transition: width 0.3s, left 0.3s, right 0.3s;
      }
  
    }
  }
`;

export const Body = styled.div`
  height: 100%;
  margin: 0 25%;
  z-index: 998;

  .ant-card {
    background: #FFF;
    /* ${props => props.theme.title==='dark' ? "border: 0": ""}; */

    h3.ant-typography {
      color: #333;;
  
    }

    label.ant-radio-wrapper {
      span {
        color: #333;;
        font-size: 1.4rem;
      }
    }
  }

  .ant-form-vertical .ant-form-item-label > label {
    font-weight: bold;
    font-size: 1.6rem;
    color: #333;;
  }

  @media(max-width: 900px) {
    .ant-card {
      .ant-tabs-nav-list {
        display: flex;
        flex-direction: row;
        /* width: initial; */
        justify-content: space-between;
        align-items: center;
      }

      label.ant-radio-wrapper {
        span {
          color: #333;;
          font-size: 1.6rem;
        }
      }

      div {
        width: 100%;
        flex-direction: column;
        align-items:flex-start;
        justify-content: flex-start;
        margin-left: 0;
        
      
        .ant-row{
          width: 100%;
          
          .ant-col.ant-col-11 {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  @media(max-width: 600px) {
    margin: 0;

  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HighlightedText = styled.strong`
  font-size: 3rem;
`;
