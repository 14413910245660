import FormBuilder from 'antd-form-builder';
import moment from 'moment';

import React, { useState, useEffect } from 'react';
import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';

import polosPadin from '../../../../../data/polosPadin.json';

import ListPolosPadin from 'pages/NewRegisterAction/ListPolosPadin';
import { makeOptitionsAllPOLOS, makeOptitionsNamesPOLOS } from 'utils/makeOptionsPolos';
import { cloneDeep } from 'lodash';

interface CiclosPadinProps {
  form: any;
  viewMode?: boolean;
  size: any;
  initialValues?: any;
  editMode: any;
}


function CiclosPadin({ form, viewMode, size, initialValues, editMode }: CiclosPadinProps) {

  const [counterList, setCounterList] = useState(0);
  const [newCont, setNewCont] = useState(false);
  const [currentPolosPadin, setCurrentPolosPadin] = useState<any>([[]]);
  const [atualIndex, setIndex] = useState(0)
  const [atualPolos, setAtualPolos] = useState<any>(undefined)

  const [list, setList] = useState<any>([]);

  useEffect(() => {
    setNewCont(true);

    if(!newCont){

      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
          
      });
    }else {
      setList([])
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      
      });
    }

    if(editMode && initialValues.length > 0) {
      const polosPadinAux: any =  []
      Array(size)
      .fill(0)
      .forEach((_, i) => {
    
        const newPolos: any = makeOptitionsAllPOLOS(initialValues.formacao?.ciclosPadin[i].polos);
    
        polosPadinAux.push(newPolos)
      })

        const teste = cloneDeep(polosPadinAux)
        setCurrentPolosPadin(teste);
    }
  }, [size]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeSelectPadin(names: any, indice: any) {
    setIndex(indice)
    setAtualPolos(names)
  }

  function addFields(indice) {
    
    const newList = {
      columns: 2,
      fields: [
        {
          key: `formacao.ciclosPadin/.periodo${indice}/.dataInicio`,
          name: `dataInicio${indice}`,
          label: 'Data início',
          widget: 'date-picker',
          viewWidget: 'date-view',
          placeholder: 'Selecione a data',
          dependencies: [`dataFim${indice}`],
          rules: [
            {
              required: true,
              message: `O campo "Data início" é obrigatório.`,
            },
            {
              validator: (rule, value, callback) => {
                return new Promise<void>((resolve, reject) => {
                  const newInicial = new Date(value).getTime();

                  const dataFim = new Date(
                    form.getFieldValue(`dataFim${indice}`),
                  ).getTime();

                  if (newInicial > dataFim) {
                    reject(
                      new Error('O evento deve iniciar antes da data final'),
                    );
                  } else {
                    resolve();
                  }
                });
              },
            },
          ],
        },
        {
          key: `formacao.ciclosPadin/.periodo${indice}/.dataFim`,
          name: `dataFim${indice}`,
          label: 'Data fim',
          widget: 'date-picker',
          viewWidget: 'date-view',
          placeholder: 'Selecione a data',
          dependencies: [`dataInicio${indice}`],
          rules: [
            {
              required: true,
              message: `O campo "Data fim" é obrigatório.`,
            },
            {
              validator: (rule, value, callback) => {
                return new Promise<void>((resolve, reject) => {
                  const newFinal = new Date(value).getTime()
                  const dataInicio = new Date(
                    form.getFieldValue(`dataFim${indice}`),
                  ).getTime()

                  if (newFinal < dataInicio) {
                    reject(
                      new Error(
                        'O evento deve finalizar depois da data inicial',
                      ),
                    );
                  } else {
                    resolve();
                  }
                });
              },
            },
          ],
        },
        {
          key: `formacao.ciclosPadin/.conteudoProgramatico/${indice}`,
          label: 'Conteúdo programático',
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: `O campo "Conteúdo programático" é obrigatório.`,
            },
          ],
        },
        {
          key: `formacao.ciclosPadin/.cargaHoraria/${indice}`,
          label: 'Carga horária',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Carga horária" é obrigatório.`,
            },
          ],
        },
          {
            key: `formacao.ciclosPadin/.qtdFormadores/${indice}`,
            label: 'Quantidade de formadores',
            widget: 'number',
            viewWidget: 'simple-view',
            widgetProps: { min: 0, style: {width: '75%'} },
            rules: [
              {
                required: true,
                message: `O campo "Quantidade de formadores" é obrigatório.`,
              },
            ],
          },
          {
            key: `formacao.ciclosPadin/.qtdArticuladoresRegionaisMaisInfancia/${indice}`,
            label: 'Quantidade de articuladores regionais mais infância',
            widget: 'number',
            viewWidget: 'simple-view',
            widgetProps: { min: 0, style: {width: '75%'} },
            rules: [
              {
                required: true,
                message: `Esse campo é obrigatório.`,
              },
            ],
          },
          {
            key: `formacao.ciclosPadin/.qtdTecnicosSeduc/${indice}`,
            label: 'Quantidade de técnicos SEDUC',
            widget: 'number',
            viewWidget: 'simple-view',
            widgetProps: { min: 0, style: {width: '75%'} },
            rules: [
              {
                required: true,
                message: `O campo "Quantidade de técnicos SEDUC" é obrigatório.`,
              },
            ],
          },
          {
            key: `formacao.ciclosPadin/.qtdTecnicosCrede/${indice}`,
            label: 'Quantidade de técnicos CREDE',
            widget: 'number',
            viewWidget: 'simple-view',
            widgetProps: { min: 0, style: {width: '75%'} },
            rules: [
              {
                required: true,
                message: `O campo "Quantidade de técnicos CREDE" é obrigatório.`,
              },
            ],
          },
          {
            key: `formacao.ciclosPadin/.qtdSupervisores/${indice}`,
            label: 'Quantidade de supervisores',
            widget: 'number',
            viewWidget: 'simple-view',
            widgetProps: { min: 0, style: {width: '75%'} },
            rules: [
              {
                required: true,
                message: `O campo "Quantidade de supervisores" é obrigatório.`,
              },
            ],
          },
          {
            key: `formacao.ciclosPadin/.qtdAdis/${indice}`,
            label: 'Quantidade de ADIS',
            widget: 'number',
            viewWidget: 'simple-view',
            widgetProps: { min: 0, style: {width: '75%'} },
            rules: [
              {
                required: true,
                message: `O campo "Quantidade de ADIS" é obrigatório.`,
              },
            ],
          },
          {
            key: `formacao.ciclosPadin/.polos/${indice}`,
            label: 'Polo de formação',
            widget: 'select',
            options: makeOptitionsNamesPOLOS(),
            widgetProps: {
              mode: 'tags',
              onChange: e => {
                onChangeSelectPadin(e, indice)
              },
            },
            rules: [
              {
                required: true,
                message: `O campo "Quantidade de ADIS" é obrigatório.`,
              },
            ],
          },
      
      ],
    };

    

    setList(prev => [...prev, newList]);
    setCounterList(prev => prev + 1);
  }

  function removeFields(index) {
    list.splice(index, 1);

    setList([...list]);
  }

  useEffect(()=> {
    if(atualPolos) {
      const newPolos: Array<any> = [];
      polosPadin.forEach(item => {
        atualPolos?.filter(nameMuni => {
          if (item.polo === nameMuni) {
            newPolos.push(item);
            return 1;
          }
          return 0;
        });
      });
  
      const newListAux = [...currentPolosPadin];
  
      newListAux[atualIndex] = [...newPolos];
      const LisxtAuxClone = cloneDeep(newListAux);
      setCurrentPolosPadin(LisxtAuxClone);  
    }

  }, [atualPolos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <fieldset>
        <legend>Ciclos Padin</legend>

        {list.map((metaList, index) => {

            metaList?.fields?.forEach(field => {
              if(viewMode) {
                if(field.label === 'Data fim' || field.label === 'Data início'){
                  delete field.dependencies;
                  delete field.rules;

                  if(field.label === 'Data fim'){
                    field['initialValue'] = form.getFieldValue(`dataFim${index}`);               

                  }else{
                    field['initialValue'] = form.getFieldValue(`dataInicio${index}`);               
                  }
                }
              }

              if(initialValues?.CiclosPadin && !viewMode){
                if (field.key.includes('dataInicio')) {
                  field['initialValue'] = moment(initialValues?.CiclosPadin[`${index}`]?.periodo?.dataInicio);  
                }
                else if(field.key.includes('dataFim')){
                  field['initialValue'] = moment(initialValues?.CiclosPadin[`${index}`]?.periodo?.dataFim);  
                }
              }
            })
    
            return (
              <React.Fragment key={index}>
                {!viewMode && index > 0 && (
                  <div className="add-button" onClick={() => removeFields(index)}>
                    <IoRemoveCircle size={25} color="var(--green-dark)" />
                    <strong>Remover Ciclo</strong>
                  </div>
                )}
                
                <FormBuilder form={form} meta={metaList} viewMode={viewMode} />
                {currentPolosPadin[index] &&
                    <ListPolosPadin polosSelect={currentPolosPadin[index]} />
                } 
            
              </React.Fragment>
            );
          

       
        })}
        {!viewMode && (
          <div className="add-button" onClick={() => addFields(counterList)}>
            <IoAddCircle size={25} color="var(--green-dark)" />
            <strong>Adicionar novo</strong>
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default CiclosPadin;
