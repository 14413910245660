import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IrisSocialRouters } from 'routes';
import { Header, Device, Authorizer } from 'components';

import 'styles/global.scss';
import 'antd/dist/antd.less';

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Device>
        <Authorizer redirect="/nao-autorizado">
          <Header />

          <IrisSocialRouters />
        </Authorizer>
      </Device>
    </BrowserRouter>
  );
};

export default App;
