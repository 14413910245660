import React, { useState, useCallback, memo, useContext, useEffect } from 'react';
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { Form, Checkbox } from 'antd';

import FilterIcon from '../../assets/filterIcon.svg';
import close from '../../assets/close.svg';

import { CheckboxContext } from '../../utils/context/checkboxContext'
import { UtilContext } from '../../utils/context/ultilContext'
import './styles.scss';


interface IObras {
      categoria: string,
      descricao: string,
      eventos: 
        {
          datas: {
            dataFimPrevisto: string,
            dataInicio: string,
            dataOrdemServico: string
          },
          descricao: string,
          docs: {
            documento: string,
            imagem: string
          },
          id: string,
          localizacao: {
            enderecoCompleto: string,
            estacao: string,
            latitude: number,
            longitude: number,
            municipio: string,
            pontoDeReferencia: string,
            regiaoPlanejamento: string
          },
          nome: string,
          observacao: string,
          recursos: {
            mudasFrutiferas: number,
            mudasNativas: number,
            totalInvestido: number
          },
          status: string,
          ultimaModificacao: string
        }[],
      id: string,
      mapeamento: {
        datasMapeamento: {
          dataFimPrevisto: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          dataInicio: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          dataOrdemServico: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          }
        },
        docMapeamento: {
          documento: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          imagem: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          }
        },
        localizacaoMapeamento: {
          enderecoCompleto: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          estacao: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          latitudeLongitude: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          municipio: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          pontoDeReferencia: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          regiaoPlanejamento: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          }
        },
        nomeEventoMapeamento: {
          editavel: boolean,
          obrigatorio: boolean,
          usado: boolean
        },
        observacaoMapeamento: {
          editavel: boolean,
          obrigatorio: boolean,
          usado: boolean
        },
        recursosMapeamento: {
          mudasFrutiferas: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          mudasNativas: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          },
          totalInvestido: {
            editavel: boolean,
            obrigatorio: boolean,
            usado: boolean
          }
        },
        statusMapeamento: {
          editavel: boolean,
          obrigatorio: boolean,
          usado: boolean
        }
      },
      nome: string,
      subcategoria: string,
      tempoDe: string,
      totalDeEventos: number,
      ultimaModificacao: string
}

interface IAcao {
  id: string,
  subcategoria: string,
  nome: string,
  descricao: string,
  categoria: string,
  tempoDe: string,
  ultimaModificacao: string,
  eventos: {
      nome: string,
      valor: number,
      endereco: {
        municipio: string,
        rua: string,
        bairro: string,
        cep: string,
        numero: string,
        pontoDeReferencia: string,
        estacao: string
      },
      datas: {
        dataInicio: string,
        dataFim: string
      },
      pessoas: {
        criancasAtendidas: {
          porEvento: number,
          porEdicao: {
            edicao: number,
            periodo: {
              dataInicio: string,
              dataFim: string
            },
            numeroAtendimentos: number,
            numeroCriancasAtendidas: number
          }[]
        },
        criancasFormadas: number,
        criancasVisitadas: number,
        criancasBeneficiadas: number,
        familiasAcompanhadas: number,
        familiasBeneficiadas: number,
        familiasBeneficiadasMes: number,
        familiasVisitadas: number,
        gestantesVisitadas: number,
        participantes: number,
        pessoasPorFaixaEtaria: {
          porEdicaoFaixaEtaria: {
            edicao: number,
            periodo: {
              dataInicio: string,
              dataFim: string
            },
            idade: {
              menoresDe6: number,
              entre12e19: number,
              adultos: number,
              idosoMaiorQue60: number
            }
          }[]
        },
        pessoasBeneficiadas: number,
        pessoasCapacitadas: number,
        pessoasImpactadas: number
      },
      recursos: {
        dvdsEntregues: number,
        instrumentosDoados: number,
        mudasFrutiferas: number,
        mudasNativas: number,
        toneladasDeAlimentosRecebidos: number,
        toneladasDeAlimentosDoados: number,
        totalInvestido: number,
      },
      atividades: {
        edicao: number,
        programacao: string,
        palestrasRealizadas: number,
        apresentacoesDoCircoEscola: number,
        exibicoesRealizadas: number,
        oficinasRealizadas: number
      }
  }[],
  mapeamento: {
  nome: {
     usado: false,
     obrigatorio: false,
     editavel: false
  },
  valor: {
     usado: false,
     obrigatorio: false,
     editavel: false
  },
  endereco: {
     usado: false,
     obrigatorio: false,
     editavel: false
  },
  datas: {
     usado: false,
     obrigatorio: false,
     editavel: false
  },
  atividadesMapeamento: {
     numeroEdicao: {
        usado: false,
        obrigatorio: false,
        editavel: false
     },
     programacao: {
        usado: false,
        obrigatorio: false,
        editavel: false
     },
     numeroPalestras: {
        usado: false,
        obrigatorio: false,
        editavel: false
     },
     apresentacoesCircoEscola: {
        usado: false,
        obrigatorio: false,
        editavel: false
     },
     numeroExibicoesFilme: {
        usado: false,
        obrigatorio: false,
        editavel: false
     },
     numeroOficinasRealizadas: {
        usado: false,
        obrigatorio: false,
        editavel: false
     }
  },
     pessoasMapeamento: {
        pessoasBeneficiadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        pessoasImpactadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        pessoasCapacitadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        participantes: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        criancasAtendidas: {
           usado: true,
           obrigatorio: true,
           editavel: true
        },
        pessoasPorFaixaEtaria: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        familiasBeneficiadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        familiasBeneficiadasMes: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        familiasVisitadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        familiasAcompanhadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        criancasVisitadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        gestantesVisitadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        criancasFormadas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        }
     },
     recursosMapeamento: {
        mudasFrutiferas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        mudasNativas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        totalInvestido: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        dvdsEntregues: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        instrumentosDoados: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        toneladaslimentosDoados: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        toneladaslimentosRecebidos: {
           usado: false,
           obrigatorio: false,
           editavel: false
        },
        mudasfrutíferas: {
           usado: false,
           obrigatorio: false,
           editavel: false
        }
     }
  }
}

function somatorioAcoes(data: Array<IAcao> | undefined, regiao: string) {
  let tamanho = 0;
  // data?.map((acao, i) =>
  //   tamanho = tamanho + acao.eventos.filter(eventos =>
  //     eventos.endereco.municipio.toUpperCase() === regiao
  //   ).length)

  return tamanho;
}

function somatorioObras(data: Array<IObras> | undefined, regiao: string) {
  let tamanho = 0;
  data?.map((obras, i) =>
    tamanho = tamanho + obras.eventos.filter(eventos =>
      eventos.localizacao.municipio.toUpperCase() === regiao
    ).length)

  return tamanho;
}

function somatorioStatus(data: Array<IObras> | undefined, regiao: string, status: string) {
  let tamanho = 0;
  data?.map((obras) =>
    tamanho = tamanho + obras.eventos.filter(eventos =>
      eventos.localizacao.municipio.toUpperCase() === regiao && eventos.status === status
    ).length
    )

  return tamanho;
}

function somatorioTotalStatus(data: Array<IObras> | undefined, regiao: string) {
  let tamanho = 0;
  data?.map((obras) =>
    tamanho = tamanho + obras.eventos.filter(eventos =>
      eventos.localizacao.municipio.toUpperCase() === regiao
    ).length
    )

  return tamanho;
}

interface IFilterPopup {
  dataObras: Array<IObras> | undefined,
  dataAcao: Array<IAcao> | undefined
}

const FilterPopup = ({dataObras, dataAcao}: IFilterPopup) => {

  const { showInput, setShowInput } = useContext(UtilContext)
  const [hoverExibition, setHoverExibition] = useState(false);
  const [showFilterObras, setshowFilterObras] = useState(false);
  const [showFilterAcoes, setshowFilterAcoes] = useState(false);
  const [showFilterStatus, setshowFilterStatus] = useState(false);

  const handleShowUpFilterObras = useCallback(() => {
    setshowFilterObras(!showFilterObras);
  }, [showFilterObras]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowUpFilterAcoes = useCallback(() => {
    setshowFilterAcoes(!showFilterAcoes);
  }, [showFilterAcoes]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleShowUpFilterStatus = useCallback(() => {
    setshowFilterStatus(!showFilterStatus);
  }, [showFilterStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowUp = useCallback(() => {
    setShowInput(!showInput);
  }, [showInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleHover = useCallback(() => {
    setHoverExibition(!hoverExibition);
  }, [hoverExibition]); // eslint-disable-line react-hooks/exhaustive-deps

  const { selectRegion } = useContext(UtilContext)

  /* codigo dos filtros */

  // checkbox obras

  const { checkedListObras, setCheckedListObras,
          checkObrasFilterOn, setcheckObrasFilterOn } 
          = useContext(CheckboxContext)

  const [indeterminateObras, setIndeterminateObras] = useState(false);
  const [checkAllObras, setCheckAllObras] = useState(true);
 
  const onChangeObras = useCallback((list) => {
    setCheckedListObras(list);
    setIndeterminateObras(!!list.length && list.length < checkBoxObras.length);
    setCheckAllObras(list.length === checkBoxObras.length);
    setcheckObrasFilterOn(list.length > 0)

  }, [checkedListObras, indeterminateObras, checkAllObras]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCheckAllChangeObras = useCallback((e) => {
    setCheckedListObras(e.target.checked ? checkBoxObras : []);
    setIndeterminateObras(false);
    setCheckAllObras(e.target.checked);
    setcheckObrasFilterOn(e.target.checked)
  }, [checkedListObras, indeterminateObras, checkAllObras, checkObrasFilterOn]); // eslint-disable-line react-hooks/exhaustive-deps

  
  // checkbox acoes

  const { checkedListAcoes, setCheckedListAcoes,
          checkAcoesFilterOn, setcheckAcoesFilterOn } 
        = useContext(CheckboxContext)

  const [indeterminateAcoes, setIndeterminateAcoes] = useState(false);
  const [checkAllAcoes, setCheckAllAcoes] = useState(true);

  const onChangeAcoes = useCallback((list) => {
    setCheckedListAcoes(list);
    setIndeterminateAcoes(!!list.length && list.length < checkBoxAcoes.length);
    setCheckAllAcoes(list.length === checkBoxAcoes.length);
    setcheckAcoesFilterOn(list.length > 0)
  },[checkedListAcoes, indeterminateAcoes, checkAllAcoes, checkAcoesFilterOn]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCheckAllChangeAcoes = useCallback((e) => {
    setCheckedListAcoes(e.target.checked ? checkBoxAcoes : []);
    setIndeterminateAcoes(false);
    setCheckAllAcoes(e.target.checked);
    setcheckAcoesFilterOn(e.target.checked)
  }, [checkedListAcoes, indeterminateAcoes, checkAllAcoes, checkAcoesFilterOn]); // eslint-disable-line react-hooks/exhaustive-deps

  // checkbox status
  const CheckBoxStatus = ["Em andamento", "Concluida", "Inaugurada"]
  const { checkedListStatus, setCheckedListStatus,
          checkStatusFilterOn, setcheckStatusFilterOn } 
        = useContext(CheckboxContext)

  const [indeterminateStatus, setIndeterminateStatus] = useState(false);
  const [checkAllStatus, setCheckAllStatus] = useState(true);

  const onChangeStatus = useCallback((list) => {
    setCheckedListStatus(list);
    setIndeterminateStatus(!!list.length && list.length < CheckBoxStatus.length);
    setCheckAllStatus(list.length === CheckBoxStatus.length);
    setcheckStatusFilterOn(list.length > 0)
  }, [checkedListStatus, indeterminateStatus, checkAllStatus, checkStatusFilterOn]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCheckAllChangeStatus = useCallback((e) => {
    setCheckedListStatus(e.target.checked ? CheckBoxStatus : []);
    setIndeterminateStatus(false);
    setCheckAllStatus(e.target.checked);
    setcheckStatusFilterOn(e.target.checked)
  },[checkedListStatus, indeterminateStatus, checkAllStatus, checkStatusFilterOn]); // eslint-disable-line react-hooks/exhaustive-deps


  const [checkBoxObras, setCheckBoxObras] = useState<Array<string>>([])

  useEffect(()=>{
    if(checkBoxObras.length === 0) {
      attCheckbox(dataObras)
    } if(checkBoxAcoes.length === 0 ) {
      attCheckboxAcoes(dataAcao)
    }
 
  },[dataAcao,dataObras]) // eslint-disable-line react-hooks/exhaustive-deps


  function attCheckbox(data){ // Checkbox obras
    data?.map(title => {
      checkBoxObras.push(title.nome)
    })
    setCheckedListObras(checkBoxObras)
    setCheckBoxObras(checkBoxObras)
  }

  const [checkBoxAcoes, setCheckBoxAcoes] = useState<Array<string>>([])

  function attCheckboxAcoes(data){ // Checkbox obras
    data?.map(title => {
      checkBoxAcoes.push(title.nome)
    })
    setCheckedListAcoes(checkBoxAcoes)
    setCheckBoxAcoes(checkBoxAcoes)
  }

  /* fim do codigo dos filtros */
    
  return (
    <div
      className={
        showInput
          ? 'aside-container visible'
          : 'aside-container disable'
      }
    >
      
      <img
        onClick={handleShowUp}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        role="presentation"
        src={FilterIcon}
        alt="Icone de filtro de exibição"
        className="filterIcon"
      />
      <div
        className={
          (showInput) || hoverExibition
            ? 'filter-popup show'
            : 'filter-popup disable'
        }
      >
        <p>Filtro de exibição</p>
        <img onClick={handleShowUp} src={close} alt="legenda obra" />
      </div>
        <div
          style={{display: showInput ? "block" : "none"}}
          className={
            showInput 
              ? 'checkbox-container show'
              : 'checkbox-container disable'
          }
        >
          <div className="checkbox-container">
            <div className="checkbox-content">
              <Form name="obrasFiltro">
                <div className="checkbox-title">
                {showFilterObras ? 
                  <IoChevronForward onClick={handleShowUpFilterObras}/>
                  : <IoChevronDown onClick={handleShowUpFilterObras}/>}
                  <Checkbox indeterminate={indeterminateObras} onChange={onCheckAllChangeObras} checked={checkAllObras}>
                    <span>Obras</span>
                  </Checkbox>
                  <div className="type-length">
                    <span>
                     {somatorioObras(dataObras, selectRegion)}
                    </span>
                  </div>
                </div>
                <div className="filters" style={{ display: showFilterObras ? "block" : "none" }}>
                  <Checkbox.Group value={checkedListObras} onChange={onChangeObras}>
                    {dataObras?.map((title, i) => 
                    <Checkbox key={i} value={title.nome}>
                    <div className="info-filter">
                      {title.nome}
                      <div className="count">
                        <span>
                        {title.eventos.filter(
                          obrasDaRegião =>
                          obrasDaRegião.nome === title.nome &&
                          obrasDaRegião.localizacao.municipio === selectRegion.toUpperCase()
                          ).length}
                        </span>
                      </div>
                    </div>
                    </Checkbox>
                    )}                    
                  </Checkbox.Group>
                </div>

                <div className="checkbox-title">
                {showFilterAcoes ? 
                  <IoChevronForward onClick={handleShowUpFilterAcoes}/>
                  : <IoChevronDown onClick={handleShowUpFilterAcoes}/>}
                  <Checkbox indeterminate={indeterminateAcoes} onChange={onCheckAllChangeAcoes} checked={checkAllAcoes}>
                    <span>Ações</span>
                  </Checkbox>
                  <div className="type-length">
                    <span>
                      {somatorioAcoes(dataAcao, selectRegion)}
                    </span>
                  </div>
                </div>
                <div className="filters" style={{ display: showFilterAcoes ? "block" : "none" }}>
                  <Checkbox.Group value={checkedListAcoes} onChange={onChangeAcoes}>
                    {dataAcao?.map((title, i) =>
                    <Checkbox key={i} value={title.nome}>
                    <div className="info-filter">
                      {title.nome}
                      <div className="count">
                        <span>

                        </span>
                      </div>
                    </div>
                  </Checkbox>
                    )}
                  </Checkbox.Group>
                </div>

                <div className="checkbox-title">
                {showFilterStatus ? 
                  <IoChevronForward onClick={handleShowUpFilterStatus}/>
                  : <IoChevronDown onClick={handleShowUpFilterStatus}/>}
                  <Checkbox indeterminate={indeterminateStatus} onChange={onCheckAllChangeStatus} checked={checkAllStatus}>
                    <span>Status</span>
                  </Checkbox>
                  <div className="type-length">
                    <span>
                      {somatorioTotalStatus(dataObras, selectRegion)}
                    </span>
                  </div>
                </div>
                <div className="filters" style={{ display: showFilterStatus ? "block" : "none" }}>
                  <Checkbox.Group value={checkedListStatus} onChange={onChangeStatus}>
                    {CheckBoxStatus.map((tempo, i) =>
                      <Checkbox key={i} value={CheckBoxStatus[i]}>
                        <div className="info-filter">
                          {tempo}
                          <div className="count">
                            <div className={"status circle" + i}/>
                            <span>
                              {somatorioStatus(dataObras, selectRegion, tempo)}
                            </span>
                          </div>
                        </div>
                      </Checkbox>
                    )}
                  </Checkbox.Group>
                </div>
              </Form>
            </div>
          </div>
        </div>
    </div>
  );
};


export default memo(FilterPopup);
