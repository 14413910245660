import { citizenByUserApi } from "services/api";

const service = () => {

  async function assignRoleToUser(cpf: string, appKey: string, roleId: number) {
    try {
       const path = `/client/${cpf}/application/${appKey}/assignments/roles`

      const response = await citizenByUserApi
        .post(path, { roleId });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    assignRoleToUser,
  }
}

export const rolesService = service();
