import React, { Fragment } from 'react';
import { FormBox } from "../../UI";
import { Form as AntForm, Divider } from "antd";
import { JotformAnswer } from 'types';

interface SectionFormProps {
	answers: JotformAnswer[];
  currentSection: string;
}

const { Item } = AntForm;

export function SectionForm({ answers, currentSection }: SectionFormProps) {
  return (
		<div>
			<FormBox
				title={currentSection}
			>
        {answers.map((a, i) => (
          <Fragment key={a.id}>
            <Item label={a.question}>
              <div>{a.answer}</div>
            </Item>
            {a.question.includes('visitação domiciliar') && <Divider />}
          </Fragment>
        ))}
			</FormBox>
		</div>
	);
}