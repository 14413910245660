import XLSX from 'xlsx';

import { formattedStringKey } from 'utils/strings';

export function formatXLSXseduc(data, workbook) {
  let newTab: any = [];

  data.forEach((formacao, index: number) => {
    workbook.SheetNames.push(
      `${formacao.formacaoSeduc.tituloDaFormacao} ${index}`,
    );

    newTab = [['Ciclos'],];
    
    formacao.formacaoSeduc.ciclosSeduc.forEach((ciclo, indexCiclo) => {
      if (indexCiclo === 0) {
        Object.keys(ciclo).forEach(attribute => {
          if (
            attribute === 'participantesPorPerfil' ||
            attribute === 'periodo'
          ) {
            Object.keys(ciclo[attribute]).forEach(attributeParticipantes => {
              newTab[0].push(formattedStringKey(attributeParticipantes));
            });
          } else {
            newTab[0].push(formattedStringKey(attribute));
          }
        });
      }
      newTab = [...newTab, [`Ciclo ${indexCiclo + 1}`]];

      Object.keys(ciclo).forEach(attribute => {
        if (attribute === 'municipios') {
          newTab[indexCiclo + 1] = [
            ...newTab[indexCiclo + 1],
            ciclo[attribute].join(', '),
          ];
        } else if (attribute === 'participantesPorPerfil') {
          Object.keys(ciclo[attribute]).forEach(attributeParticipantes => {
            newTab[indexCiclo + 1] = [
              ...newTab[indexCiclo + 1],
              ciclo[attribute][attributeParticipantes],
            ];
          });
        } else if (attribute === 'periodo') {
          Object.keys(ciclo[attribute]).forEach(attributeParticipantes => {
            newTab[indexCiclo + 1] = [
              ...newTab[indexCiclo + 1],
              new Date(ciclo[attribute][attributeParticipantes]),
            ];
          });
        } else {
          newTab[indexCiclo + 1] = [
            ...newTab[indexCiclo + 1],
            ciclo[attribute],
          ];
        }
      });
    });

    let content = XLSX.utils.aoa_to_sheet(newTab);

    workbook.Sheets[
      `${formacao.formacaoSeduc.tituloDaFormacao} ${index}`
    ] = content;
  });

  return workbook;
}
