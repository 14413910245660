import React, { memo, useCallback, useState, useContext } from 'react';

import arrowDown from '../../assets/arrow_down.svg';
import arrowUp from '../../assets/arrow_up.svg';
import './styles.scss';

import { CheckboxContext } from '../../utils/context/checkboxContext'
import { UtilContext } from '../../utils/context/ultilContext'

interface IObras {
  categoria: string,
  descricao: string,
  eventos: 
    {
      datas: {
        dataFimPrevisto: string,
        dataInicio: string,
        dataOrdemServico: string
      },
      descricao: string,
      docs: {
        documento: string,
        imagem: string
      },
      id: string,
      localizacao: {
        enderecoCompleto: string,
        estacao: string,
        latitude: number,
        longitude: number,
        municipio: string,
        pontoDeReferencia: string,
        regiaoPlanejamento: string
      },
      nome: string,
      observacao: string,
      recursos: {
        mudasFrutiferas: number,
        mudasNativas: number,
        totalInvestido: number
      },
      status: string,
      ultimaModificacao: string
    }[],
  id: string,
  mapeamento: {
    datasMapeamento: {
      dataFimPrevisto: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      dataInicio: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      dataOrdemServico: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      }
    },
    docMapeamento: {
      documento: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      imagem: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      }
    },
    localizacaoMapeamento: {
      enderecoCompleto: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      estacao: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      latitudeLongitude: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      municipio: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      pontoDeReferencia: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      regiaoPlanejamento: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      }
    },
    nomeEventoMapeamento: {
      editavel: boolean,
      obrigatorio: boolean,
      usado: boolean
    },
    observacaoMapeamento: {
      editavel: boolean,
      obrigatorio: boolean,
      usado: boolean
    },
    recursosMapeamento: {
      mudasFrutiferas: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      mudasNativas: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      },
      totalInvestido: {
        editavel: boolean,
        obrigatorio: boolean,
        usado: boolean
      }
    },
    statusMapeamento: {
      editavel: boolean,
      obrigatorio: boolean,
      usado: boolean
    }
  },
  nome: string,
  subcategoria: string,
  tempoDe: string,
  totalDeEventos: number,
  ultimaModificacao: string
}

interface IFilterStatus {
  dataObras: Array<IObras> | undefined
}

const FilterStatus = ({dataObras}: IFilterStatus) => {
  const { selectRegion } = useContext(UtilContext)                   
  const [menuActive, setMenuActive] = useState(false);

  const activateMenu = useCallback(() => {
    setMenuActive(!menuActive);
  }, [menuActive]);

  const { checkedListObras, checkObrasFilterOn,
          checkedListStatus, checkStatusFilterOn
        } 
    = useContext(CheckboxContext)

  const { showInput } = useContext(UtilContext)

  function somatorioStatusObras(data: IObras | undefined, regiao: string, status: string) {
    let tamanho = 0;
    tamanho = tamanho + Number(data?.eventos.filter(eventos =>
      eventos.localizacao.municipio.toUpperCase() === regiao && eventos.status === status
    ).length)
    return tamanho;
  }

  function somatorioTotalStatus(data: Array<IObras> | undefined, regiao: string) {
    let tamanho = 0;
    data?.map((obras) =>
      tamanho = tamanho + obras.eventos.filter(eventos =>
        eventos.localizacao.municipio.toUpperCase() === regiao && checkedListObras?.includes(obras.nome)
      ).length
      )
  
    return tamanho;
  }

  return (
    <div
      style={{display: showInput && checkStatusFilterOn && (checkObrasFilterOn)  && somatorioTotalStatus(dataObras, selectRegion) > 0 ? "flex" : "none"}}
      className="region-aside-container"
    >
      <h1>Quantidade Filtrada por Status</h1>
      <img
        src={!menuActive ? arrowDown : arrowUp}
        alt="Icon de seta pra baixo"
        onClick={activateMenu}
        role="presentation"
        className="arrow"
      />
        <table style={{height: menuActive ? "auto" : 0, 
                       display: menuActive ? "block" : "none"}}>

          <div className="status-filter" style={{display: checkObrasFilterOn ? "table-header-group" : "none"}}>
            <tr>
              <th>
                <div className="title-filter">
                  <span>Obras</span>
                </div>
              </th>
                <th style={{display: checkedListStatus.includes("Em andamento") ? "revert" : "none"}}><div className={"circle Andamento"}/></th>
                <th style={{display: checkedListStatus.includes("Concluida") ? "revert" : "none"}}><div className={"circle Concluido"}/></th>
                <th style={{display: checkedListStatus.includes("Inaugurada") ? "revert" : "none"}}><div className={"circle Inaugurado"}/></th>
            </tr>
                {dataObras?.map((obras, i) => 
                obras.eventos.filter(eventos => eventos.localizacao.municipio === selectRegion).length > 0 ? 
                <tr key={i} style={{display: checkedListObras?.includes(obras.nome) ? "revert" : "none"}}>
                  <td><span>{obras.nome}</span></td>
                  <td style={{display: checkedListStatus.includes("Em andamento") ? "revert" : "none"}}>
                    <span>
                    {somatorioStatusObras(obras, selectRegion, "Em andamento") > 0 ? somatorioStatusObras(obras, selectRegion, "Em andamento") : "-"}
                    </span>
                  </td>
                  <td style={{display: checkedListStatus.includes("Concluida") ? "revert" : "none"}}>
                    <span>
                    {somatorioStatusObras(obras, selectRegion, "Concluida") > 0 ? somatorioStatusObras(obras, selectRegion, "Concluida") : "-"}
                    </span>
                  </td>
                  <td style={{display: checkedListStatus.includes("Inaugurada") ? "revert" : "none"}}>
                    <span>
                    {somatorioStatusObras(obras, selectRegion, "Inaugurada") > 0 ? somatorioStatusObras(obras, selectRegion, "Inaugurada") : "-"}
                    </span>
                  </td>
                </tr> : <></>
                )}
          </div>
      </table>
      </div>
  );
};

export default memo(FilterStatus);
