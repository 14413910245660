import { DateFilter, MunicipioFilter, RegiaoFilter } from '../components/InputFiltros'
import React from 'react'
import TypeFilter from 'components/InputFiltros/TypeFilter'

const componentsFiltrosAll = (setSelectedTime, setSelectedCitiesList, filtrarEventos, setSelectedRegiaoList, action, setSelectedType) => {
  return <><DateFilter setSelectedTime={setSelectedTime} />
    <RegiaoFilter setSelectedRegiaoList={setSelectedRegiaoList}/>
    <MunicipioFilter setSelectedMunicipio={setSelectedCitiesList} />
    <button onClick={filtrarEventos}>FILTRAR</button>
  </>
}

const componentsFiltrosMunicipios = (setSelectedTime, setSelectedCitiesList, filtrarEventos, setSelectedRegiaoList, action, setSelectedType) => {
  return <>
    <RegiaoFilter setSelectedRegiaoList={setSelectedRegiaoList}/>
    <MunicipioFilter setSelectedMunicipio={setSelectedCitiesList} />
    <button onClick={filtrarEventos}>FILTRAR</button>
  </>
}

const componentsFiltrosAllWithType = (setSelectedTime, setSelectedCitiesList, filtrarEventos, setSelectedRegiaoList, action, setSelectedType) => {
  return <><DateFilter setSelectedTime={setSelectedTime} />
    <RegiaoFilter setSelectedRegiaoList={setSelectedRegiaoList}/>
    <MunicipioFilter setSelectedMunicipio={setSelectedCitiesList} />
    <TypeFilter setSelectedType={setSelectedType} action={action} />
    <button onClick={filtrarEventos}>FILTRAR</button>
  </>
}

export const acoesFiltros: any = 
{
  publicacoes: componentsFiltrosMunicipios,
  capacitacao: componentsFiltrosAll,
  arteNaPraca: componentsFiltrosAll,
  bandaMusicaEscola: componentsFiltrosAll,
  capacitacaoAgentesComunitariosSaude: componentsFiltrosAll,
  criancaFeliz: componentsFiltrosAllWithType,
  documentario: (setSelectedCitiesList, filtrarEventos, setSelectedRegiaoList,  action, setSelectedType) => {
    return <></>
  },
  educacaoAmbiental: componentsFiltrosAll,  
  formacaoSeduc: componentsFiltrosAll,
  lancamentoLivroRelatorio: componentsFiltrosAll, 
  maisNutricao: componentsFiltrosAllWithType, 
  nucleoEstimulacaoPrecoce: componentsFiltrosAll, 
  padin: componentsFiltrosAllWithType, 
  praiaAcessivel: componentsFiltrosAll,
  prevencaoEmFamilia: componentsFiltrosAll,
  sementesDoAmanha: componentsFiltrosAll,
  seminarioMaisInfancia: componentsFiltrosAll,
  valeGas: (setSelectedCitiesList, filtrarEventos, setSelectedRegiaoList,  action, setSelectedType) => {
    return <></>
  }, 
}