import React, { useEffect, useRef, useState } from 'react';

/* import { CSVLink } from 'react-csv'; */
import { Breadcrumb, FilterObrasHistory } from '../../components';

import { getEventsObra } from 'services/resources/obrasServices';

import { Table, Tooltip } from 'antd';

import {
  IoChevronDownCircleOutline,
  IoChevronUpCircleOutline,

} from 'react-icons/io5';

/* import { FaFileCsv } from 'react-icons/fa'; */

import { useHistoryObra } from 'utils/context/historyObraContext';
import ModalConfirmaExclusaoEvento from 'components/ModalConfirmaExclusaoEvento';
import ModalConfirmacaoInfo from 'components/ModalConfirmacaoInfo';
import { TitlePage } from 'components/TitlePage';
import moment from 'moment';

import InfoProvide from 'components/InfoProvide';
import { organizeEventoObras } from 'utils/organizeEventoObras';

import './styles.scss';
import { AiOutlineDownload } from 'react-icons/ai';
import ModalDownloadObras from 'components/ModalDownloadObras';

/* Definindo tipo do props do componente*/
type HistoricoObraProps = {
  match: {
    params: {
      id: string;
    };
  };
};

/* Definindo interface de um evento formatado*/
interface IEventFormatted {
  key: string;
  id: string;
  nome: string;
  municipio: string;
  status: string;
  data: string;
  latitude: number;
  longitude: number;
  enderecoCompleto: string;
  previsaoEntrega: any;
  secretaria: string;
  investimentoTotal: number;
  urlImg: string;
  grupoTipo: string;
}

/* Tipo de props do componente MakeExpandedRowContent */
type MakeExpandedRowContentProps = {
  record: IEventFormatted;
};

/* Monta o que aparece ao expandir a linha */
const MakeExpandedRowContent = ({ record }: MakeExpandedRowContentProps) => {
  return (
    <div className="expanded-row-render">
      <section>
        <div>
          <strong>Grupo</strong>
          <p>{record.grupoTipo}</p>
        </div>
        <div>
          <strong>Previsão de entrega</strong>
          <p>{moment(record.previsaoEntrega).format('DD/MM/YYYY')}</p>
        </div>
      </section>
      <section>
        <div>
          <strong>Latitude</strong>
          <p>{record.latitude}</p>
        </div>
        <div>
          <strong>Valor Investido</strong>
          <p>
            {' '}
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(record.investimentoTotal)}
          </p>
        </div>
      </section>
      <section>
        <div>
          <strong>Longitude</strong>
          <p>{record.longitude}</p>
        </div>
        <div>
          <strong>Secretaria</strong>
          <p>{record.secretaria}</p>
        </div>
      </section>
    </div>
  );
};

const HistoricoObra = ({ match }: HistoricoObraProps) => {
  /* Usando o contexto do Historico de uma obra*/

  const { updateEventsList, formattedEvents } = useHistoryObra();

  const nameAndId = match.params.id; //nas obras o nome é iagual ao id

  const componentRef = useRef<any>();

  const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);

  const [isLoading, setisLoading] = useState(false);
  const [dataXLSX, setDataXLSX] = useState<any>();

  var element_scroll: any =
    document.getElementsByClassName('ant-table-wrapper')[0] || HTMLInputElement;

  if (element_scroll) {
    element_scroll.scrollLeft = 10000000;
  }
  element_scroll.scrollLeft = 10000000;

  /* Pegando os eventos da API */
  useEffect(() => {
    setisLoading(true);
    const response = async () => {
      const data = await getEventsObra(match.params.id);
      
      const newDataFormatted = organizeEventoObras(data.eventos);
      setDataXLSX(newDataFormatted);

      updateEventsList(data?.eventos);
      setisLoading(false);
    };
    response();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Links para o Breadcrumb */
  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/home-servidor',
      title: 'Lista de Equipamentos e ações',
    },

    {
      path: `/historico-obra/${nameAndId}`,
      title: `Histórico de registros de ${nameAndId}`,
    },
  ];

  /*   const csvData:any  = [{
    name: 'Data science training',
    description: 'Data Science certification training',
  
    suggestedRoles: [{
        id: 16,
        category: 'DEVELOPER',
        name: 'Data Engineer'
      },
      {
        id: 17,
        category: 'DEVELOPER',
        name: 'Data Scientist'
      }
    ]
  }, {
    name: 'AWS',
    description: 'AWS certification training',
  
    suggestedRoles: [{
        id: 16,
        category: 'DEVELOPER',
        name: 'Cloud Engineer'
      },
      {
        id: 17,
        category: 'DEVELOPER',
        name: 'Network Engineer'
      }
    ]
  }];

  let dataCSV: any = []
  csvData.forEach(item => {
    dataCSV.push({
          name: item.name,
          description: item.description,
          suggestedRoles: item.suggestedRoles[0].name
      });
      for (let i = 1; i < item.suggestedRoles.length; i++) {
          const role = item.suggestedRoles[i];
          dataCSV.push({
              name: '',
              description: '',
              suggestedRoles: role.name
          });
      }
  }); */
  /* Colunas da tabela */
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      title: 'Município',
      dataIndex: 'municipio',
    },
    {
      title: 'Data (Previsão de entrega)',
      dataIndex: 'previsaoEntrega',
      render: text => {
        return new Date(text).toLocaleDateString('pt-BR');
      },
    },
    {
      title: 'Estágio de execução',
      dataIndex: `estagioExecucao`,
    },
    {
      title: 'Orgão',
      dataIndex: 'orgao',
    },
  ];

  return (
    <>
      <div className="historico-obra-container container-box">
        <Breadcrumb links={links} />
        <TitlePage
          title={`Histórico de registros de ${nameAndId}`}
          backButton
        />
        <div className="content-container">
          <FilterObrasHistory idObra={nameAndId} />

          <InfoProvide
            message="Os dados dos equipamentos são fornecidos pelo Sistema WebMapp do Governo do Ceará"
            type="webmapp"
          />

          <div
            className={`title-icons-container ${
              formattedEvents?.length > 0 && !isLoading ? '' : 'solo'
            }`}
          >
            {formattedEvents?.length > 0 && !isLoading && (
              <strong>Total: {formattedEvents?.length}</strong>
            )}
            <div className="content-icons-download">
            <Tooltip title="Download">
                  <button className="button-download-obras" onClick={() => setIsOpenModalDownload(true)}>
                    <AiOutlineDownload color="var(--color-primary)" size={20} />
               
                  </button>
                </Tooltip>
               
                {isOpenModalDownload && (
                  <ModalDownloadObras
                    onOpenModal={setIsOpenModalDownload}
                    formattedEvents={formattedEvents}
                    nameAndId={nameAndId}
                    dataXLSX={dataXLSX}
                   
                  />
                )}


              {/* <Tooltip title="Baixar em pdf">
                <button
                  onClick={() =>
                    generetePDF(formattedEvents, 'obras', nameAndId)
                  }
                >
                  <IoNewspaperSharp color="var(--color-primary)" size={17} />
                </button>
              </Tooltip>

        

              <Tooltip title="Baixar em xlsx">
                <button
                  onClick={() =>
                    generateXLSX(dataXLSX, 'obra', nameAndId)
                  }
                >
                  <IoDocumentTextSharp color="var(--color-primary)" size={17} />
                </button>
              </Tooltip> */}
            </div>
          </div>

          <div ref={componentRef}>
            <Table
              columns={columns}
              dataSource={formattedEvents}
              locale={{ emptyText: 'Este equipamento não possui eventos no momento!' }}
              loading={isLoading}
              size={'middle'}
              pagination={{ position: ['bottomCenter'] }}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: record => (
                  <MakeExpandedRowContent record={record} />
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <button onClick={e => onExpand(record, e)}>
                      <IoChevronUpCircleOutline
                        color="var(--color-primary)"
                        size={17}
                      />
                    </button>
                  ) : (
                    <button onClick={e => onExpand(record, e)}>
                      <IoChevronDownCircleOutline
                        color="var(--color-primary)"
                        size={17}
                      />
                    </button>
                  ),
              }}
            />
          </div>
        </div>
      </div>
      <ModalConfirmaExclusaoEvento type={'Obra'} id={nameAndId} nameActionCurrent={nameAndId} />
      <ModalConfirmacaoInfo type={'Obra'} />
    </>
  );
};

export default HistoricoObra;
