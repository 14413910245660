import React from 'react'
import {
  Form,
  FormInstance,
  Input,
  Select,
  Row,
  Col,
} from 'antd';
import { FormBox, InputUpload } from '../../UI/index';
import municipiosCE from '../../../data/ceara-full.json'

const {TextArea} = Input

interface InfoBasicFormProps {
  form?: FormInstance;
}

const { Item } = Form;
const { Option } = Select;

export function InfoBasicEdit({ form }: InfoBasicFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  return (
    <FormBox title="Dados da Obra" form={currentForm}>
      <Item
        label="Nome"
        name="nome"
        wrapperCol={{ span: 12 }}
        rules={[
          {
            required: true,
            message: 'Nome não preenchido'
          }
        ]}
      >
        <Input />
      </Item>

      <Item
        label="Município"
        name="municipio"
        rules={[
          {
            required: true,
            message: 'Municipio não preenchido'
          }
        ]}
        wrapperCol={{ span: 6 }}
      >
        <Select
          showSearch
          defaultValue="Selecione um município"
        >
          {municipiosCE.features.map(city => (
            <Option value={city.properties.name.toUpperCase()} key={city.properties.id}>
              {city.properties.name.toUpperCase()}
            </Option>
          ))}
        </Select>
      </Item>
      <Row>
        <Col span={12}>
          <Item
          label="Endereço Completo"
          name="enderecoCompleto"
          rules={[
            {
              required: true,
              message: 'Endereço não preenchido'
            }
          ]}
        >
          <Input />
        </Item>
        </Col>

        <Col span={11} offset={1}>
        <Item
        label="Ponto de referência"
        name="pontoDeReferencia"
      >
        <Input />
      </Item>
        </Col>
      </Row>

      <Item
        label="Descrição"
        name="descricao">
        <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 6 }}/>
      </Item>

      <Item
        label="Foto da Obra"
        name="imagem">
        <InputUpload disabled={false}/>
      </Item>
      
    </FormBox>
  );
};
