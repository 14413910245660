import React, { useContext, useEffect, useState } from 'react'
import { Select, DatePicker, Input } from 'antd'
import './styles.scss'
import moment from 'moment'
import { IoInformationCircleOutline, IoAddCircle, IoRemoveCircle } from 'react-icons/io5'
import locale from 'antd/es/date-picker/locale/pt_BR';
import cearaMunicipios from '../../data/ceara-full.json'
import { RegistroObras } from '../../utils/context/registroObrasContext'
const { Option } = Select;
const { RangePicker } = DatePicker
const { TextArea } = Input;

interface IAcao {
   id: string,
   subcategoria: string,
   nome: string,
   descricao: string,
   categoria: string,
   tempoDe: string,
   ultimaModificacao: string,
   eventos: {}[],
   mapeamento: {
   nome: {
      usado: false,
      obrigatorio: false,
      editavel: false
   },
   valor: {
      usado: false,
      obrigatorio: false,
      editavel: false
   },
   endereco: {
      usado: false,
      obrigatorio: false,
      editavel: false
   },
   datas: {
      usado: false,
      obrigatorio: false,
      editavel: false
   },
   atividadesMapeamento: {
      numeroEdicao: {
         usado: false,
         obrigatorio: false,
         editavel: false
      },
      programacao: {
         usado: false,
         obrigatorio: false,
         editavel: false
      },
      numeroPalestras: {
         usado: false,
         obrigatorio: false,
         editavel: false
      },
      apresentacoesCircoEscola: {
         usado: false,
         obrigatorio: false,
         editavel: false
      },
      numeroExibicoesFilme: {
         usado: false,
         obrigatorio: false,
         editavel: false
      },
      numeroOficinasRealizadas: {
         usado: false,
         obrigatorio: false,
         editavel: false
      }
   },
      pessoasMapeamento: {
         pessoasBeneficiadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         pessoasImpactadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         pessoasCapacitadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         participantes: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         criancasAtendidas: {
            usado: true,
            obrigatorio: true,
            editavel: true
         },
         pessoasPorFaixaEtaria: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         familiasBeneficiadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         familiasBeneficiadasMes: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         familiasVisitadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         familiasAcompanhadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         criancasVisitadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         gestantesVisitadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         criancasFormadas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         }
      },
      recursosMapeamento: {
         mudasFrutiferas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         mudasNativas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         totalInvestido: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         dvdsEntregues: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         instrumentosDoados: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         toneladaslimentosDoados: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         toneladaslimentosRecebidos: {
            usado: false,
            obrigatorio: false,
            editavel: false
         },
         mudasfrutíferas: {
            usado: false,
            obrigatorio: false,
            editavel: false
         }
      }
   }
}

interface IRegistrosAcoes {
  dataIniciativa: IAcao | undefined
  infoAcoes: IAcaoEvento
  setInfoAcoes(state: IAcaoEvento): void
}

interface IAtividades {
   elementsOn: Array<ICampos>
   infoAcoes: IAcaoEvento
   setInfoAcoes(prevState: IAcaoEvento): void
   acao: string | undefined
}

interface ICampos {
   obrigatorio: boolean,
   usado: boolean,
   editavel: boolean,
   index: number,
   title: string
}

interface IAcaoEvento {
   atividades: {
      edicao: string,
      programacao: string,
      palestrasRealizadas: string,
      apresentacoesDoCircoEscola: string,
      exibicoesRealizadas: string,
      oficinasRealizadas: string
    },
    pessoas: {
      criancasAtendidas: {
        porEvento: string,
        porEdicao: {
          edicao: number,
          periodo: {
            dataInicio: string,
            dataFim: string
          },
          numeroAtendimentos: string,
          numeroCriancasAtendidas: string
        }[]
      },
      criancasFormadas: string,
      criancasVisitadas: string,
      criancasBeneficiadas: string,
      familiasAcompanhadas: string,
      familiasBeneficiadas: string,
      familiasBeneficiadasMes: string,
      familiasVisitadas: string,
      gestantesVisitadas: string,
      participantes: string,
      pessoasPorFaixaEtaria: {
        porEdicaoFaixaEtaria: {
          edicao: number,
          periodo: {
            dataInicio: string,
            dataFim: string
          },
          idade: {
            menoresDe6: string,
            entre12e19: string,
            adultos: string,
            idosoMaiorQue60: string
          }
        }[]
      },
      pessoasBeneficiadas: string,
      pessoasCapacitadas: string,
      pessoasImpactadas: string
    },
    recursos: {
      dvdsEntregues: string,
      instrumentosDoados: string,
      mudasFrutiferas: string,
      mudasNativas: string,
      toneladasDeAlimentosRecebidos: string,
      toneladasDeAlimentosDoados: string,
      totalInvestido: string,
    },
    endereco: {
      bairro: string,
      cep: string,
      estacao: string,
      municipio: string,
      numero: string,
      pontoDeReferencia: string,
      rua: string
   }, 
   datas: any[],
   nome: string,
   edicao: string,
   valorInvestido: string,
   valorAditivo: string
}

const AtividadesCampos = ({elementsOn, infoAcoes, setInfoAcoes, acao}: IAtividades) => {
    function onChangeCampos(value, campo) {
      infoAcoes.atividades[campo] = value
      setInfoAcoes({...infoAcoes})
    }

  const elementos = [
   {
      title:"Edição",
      element: <Input placeholder="0"/>
    }, 
    {
      title: "Programação",
      element: <TextArea defaultValue={infoAcoes.atividades.programacao} onChange={(e)=>{onChangeCampos(e.target.value, "programacao")}} rows={4} autoSize={{ minRows: 4, maxRows: 6 }}/>
    },
    {
      title: "Palestras Realizadas",
      element: <Input defaultValue={infoAcoes.atividades.palestrasRealizadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "palestrasRealizadas")} value={infoAcoes.atividades.palestrasRealizadas} placeholder="0"/>
    }, 
    {
      title: "Apresentação do Circo Escola",
      element: <Input defaultValue={infoAcoes.atividades.apresentacoesDoCircoEscola} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "apresentacoesDoCircoEscola")} value={infoAcoes.atividades.apresentacoesDoCircoEscola} placeholder="0"/>
    }, 
    {
      title: "Exibições Realizadas",
      element: <Input defaultValue={infoAcoes.atividades.exibicoesRealizadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "exibicoesRealizadas")} value={infoAcoes.atividades.exibicoesRealizadas} placeholder="0"/>
    },
    {
      title: "Oficinas Realizadas",
      element:<Input defaultValue={infoAcoes.atividades.oficinasRealizadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "oficinasRealizadas")} value={infoAcoes.atividades.oficinasRealizadas} placeholder="0"/>
    }    
]

  return(
    <>
      {elementsOn.length > 0 ? elementsOn.length === 1 ? elementsOn[0].index !== 1 ? <label>Atividades</label> : <></> : <label>Atividades</label> : <></>}
      <div className="style-block">
      {elementsOn.map((campos) => {
         return(
            <>
               {campos.index !== 1 ? 
                  <div className="form-block-content number">
                     <div className="input-name">{elementos[campos.index]?.element}<label>{elementos[campos.index]?.title}<span className="message-required"> *</span></label></div>
                  </div> :
               <div className="form-block-content">
                  <div className="input-name"><label><span className="message-required">* </span>{elementos[campos.index]?.title}</label></div>
                  {elementos[campos.index]?.element}
               </div>}
            </>
         )
      })}</div>
    </>
  )
}

const Recursos = ({elementsOn, infoAcoes, setInfoAcoes}: IAtividades) => {

   function onChangeCampos(value, campo) {
      infoAcoes.recursos[campo] = value
      setInfoAcoes({...infoAcoes})
    }

   const elementos = [
      {
         title:"", // nao precisa desse campo, mas tem no back
         element: ''
       },
       {
         title:"", // nao precisa desse campo, mas tem no back
         element: ''
       },
       {
         title:"", // nao precisa desse campo, mas tem no back
         element: ''
       },
       {
         title:"DVD's Entregues",
         element: <Input defaultValue={infoAcoes.recursos.dvdsEntregues} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "dvdsEntregues")} value={infoAcoes.recursos.dvdsEntregues} placeholder="0"/>
       },
       {
         title:"Instrumentos Doados",
         element: <Input defaultValue={infoAcoes.recursos.instrumentosDoados} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "instrumentosDoados")} value={infoAcoes.recursos.instrumentosDoados} placeholder="0"/>
       },
       {
         title:"Alimentos Doados",
         element: <Input defaultValue={infoAcoes.recursos.toneladasDeAlimentosDoados} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "toneladasDeAlimentosDoados")} value={infoAcoes.recursos.toneladasDeAlimentosDoados} placeholder="0"/>
       },
       {
         title:"Alimentos Recebidos",
         element: <Input defaultValue={infoAcoes.recursos.toneladasDeAlimentosRecebidos} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "toneladasDeAlimentosRecebidos")} value={infoAcoes.recursos.toneladasDeAlimentosRecebidos} placeholder="0"/>
       },
       {
         title:"", // esse campos tem em todas as ações, então coloquei um elemento estático
         element: ''
       }
   ]
   return(
      <>
      {elementsOn.length > 0 ? <label>Recursos</label> : <></>}
      <div className="style-block">
      {elementsOn.map((campos) => {
         return(
            <>
               <div className="form-block-content number">
                  <div className="input-name">{elementos[campos.index]?.element}<label>{elementos[campos.index]?.title}</label></div>
               </div> 
            </>
         )
      })}</div>
      </>
   )
}

interface IPessoasPorFaixa {
   edicao: number,
   idade: {
      adultos: string,
      entre12e19: string,
      idosoMaiorQue60: string,
      menoresDe6: string
    },
    periodo: {
      dataInicio: string,
      dataFim: string
   }
}

interface IPessoasPorFaixaCampos {
   editionsPeople: IPessoasPorFaixa[],
   setInfoAcoes(prevState: IAcaoEvento): void
   infoAcoes: IAcaoEvento
}

const PessoasPorFaixa = ({editionsPeople, setInfoAcoes, infoAcoes}: IPessoasPorFaixaCampos) => {
   // function addEdition() {
   //    editionsPeople.push({      
   //       edicao: editionsPeople[editionsPeople.length - 1].edicao + 1,
   //       idade: {
   //          adultos: '',
   //          entre12e19: '',
   //          idosoMaiorQue60: '',
   //          menoresDe6: ''
   //        },
   //        periodo: {
   //          dataInicio: '',
   //          dataFim: ''
   //       }
   //    })
   //    setInfoAcoes({...infoAcoes})
   // }

   function onChangeCampos(value, index, campo) {
      infoAcoes.pessoas.pessoasPorFaixaEtaria.porEdicaoFaixaEtaria[index].idade[campo] = value
      setInfoAcoes({...infoAcoes})
    }

    function onChangeData(date, index) {
      infoAcoes.pessoas.pessoasPorFaixaEtaria.porEdicaoFaixaEtaria[index].periodo.dataInicio = date[0]
      infoAcoes.pessoas.pessoasPorFaixaEtaria.porEdicaoFaixaEtaria[index].periodo.dataFim = date[1]
      setInfoAcoes({...infoAcoes})
    }  

    function deleteEdition(index) {
      editionsPeople.splice(index, 1)
      setInfoAcoes({...infoAcoes})
    }

   return(
      <>
      {editionsPeople.map((edicao, index) =>
         <> 
            <label className="title-edition">{index > 0 && (<IoRemoveCircle onClick={()=>deleteEdition(index)} size={25} color='var(--green-dark'/>)}Edição {edicao.edicao}</label>
            <div className="style-block edition">
                  <div className="form-block-content">
                     <div className="input-name">
                        <span className="message-required"></span><label>Período</label>
                     </div>
                     <RangePicker
                     value={edicao.periodo.dataInicio === '' || edicao.periodo.dataFim === '' ? undefined : [moment(edicao.periodo.dataInicio, 'DD-MM-YYYY'), moment(edicao.periodo.dataFim, 'DD-MM-YYYY')]}
                     onChange={(e)=>onChangeData(e, index)}
                     format="DD-MM-YYYY"
                     locale={locale}/>
                     <div className="input-name"><label>Quantidade de pessoas atendidas por faixa etária</label></div>
                     <div className="qtd-people">
                        <div className="bloco-qtd"><Input defaultValue={edicao.idade.menoresDe6} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), index, "menoresDe6")} value={edicao.idade.menoresDe6} placeholder="0"/><p>Menores de 6 anos</p></div>
                        <div className="bloco-qtd"><Input placeholder="0"/><p>De 6 a 12 anos</p></div>
                        <div className="bloco-qtd"><Input defaultValue={edicao.idade.entre12e19} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), index, "entre12e19")} value={edicao.idade.entre12e19} placeholder="0"/><p>De 12 a 19 anos</p></div>
                        <div className="bloco-qtd"><Input defaultValue={edicao.idade.adultos} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), index, "adultos")} value={edicao.idade.adultos} placeholder="0"/><p>Adultos</p></div>
                        <div className="bloco-qtd"><Input defaultValue={edicao.idade.idosoMaiorQue60} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), index, "idosoMaiorQue60")} value={edicao.idade.idosoMaiorQue60} placeholder="0"/><p>Idosos (acima de 60 anos)</p></div>
                     </div>
                  </div>

            </div>
         </>
         )}
         <div className="add-more-edition"></div>
      </>
   )
}

const Beneficiados = ({elementsOn, infoAcoes, setInfoAcoes, acao}: IAtividades) => {
   function onChangeCampos(value, campo) {
      infoAcoes.pessoas[campo] = value
      setInfoAcoes({...infoAcoes})
    }
   
   const elementos = [
      {
         title:"Pessoas Beneficiadas",
         element: <Input defaultValue={infoAcoes.pessoas.pessoasBeneficiadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "pessoasBeneficiadas")} value={infoAcoes.pessoas.familiasBeneficiadas} placeholder="0"/>
       },
       {
         title:"Pessoas Impactadas",
         element: <Input defaultValue={infoAcoes.pessoas.pessoasImpactadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "pessoasImpactadas")} value={infoAcoes.pessoas.pessoasImpactadas} placeholder="0"/>
       },
       {
         title:"Capacitações",
         element: <Input defaultValue={infoAcoes.pessoas.pessoasCapacitadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "pessoasCapacitadas")} value={infoAcoes.pessoas.pessoasCapacitadas} placeholder="0"/>
       },
       {
         title:"Nº de Participantes",
         element: <Input defaultValue={infoAcoes.pessoas.participantes} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "participantes")} value={infoAcoes.pessoas.participantes} placeholder="0"/>
       },
       {
         title:"Crianças Atendidas",
         element: <Input defaultValue={infoAcoes.pessoas.criancasAtendidas.porEvento} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "criancasAtendidas")} value={infoAcoes.pessoas.criancasAtendidas.porEvento} placeholder="0"/>
       },
       {
         title:"",
         element: <></>
       },       
       {
         title:"Famílias Beneficiadas",
         element: <Input defaultValue={infoAcoes.pessoas.familiasBeneficiadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "familiasBeneficiadas")} value={infoAcoes.pessoas.familiasBeneficiadas} placeholder="0"/>
       },
       {
         title:"Famílias Beneficiadas Mes (?)",
         element: <Input defaultValue={infoAcoes.pessoas.familiasBeneficiadasMes} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "familiasBeneficiadasMes")} value={infoAcoes.pessoas.familiasBeneficiadasMes} placeholder="0"/>
       },
       {
         title:"Famílias Visitadas",
         element: <Input defaultValue={infoAcoes.pessoas.familiasVisitadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "familiasVisitadas")} value={infoAcoes.pessoas.familiasVisitadas} placeholder="0"/>
       },
       {
         title:"Famílias Acompanhadas",
         element: <Input defaultValue={infoAcoes.pessoas.familiasAcompanhadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "familiasAcompanhadas")} value={infoAcoes.pessoas.familiasAcompanhadas} placeholder="0"/>
       },
       {
         title:"Visitas a crianças",
         element: <Input defaultValue={infoAcoes.pessoas.criancasVisitadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "criancasVisitadas")} value={infoAcoes.pessoas.criancasVisitadas} placeholder="0"/>
       },
       {
         title:"Gestantes Visitadas",
         element: <Input defaultValue={infoAcoes.pessoas.gestantesVisitadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "gestantesVisitadas")} value={infoAcoes.pessoas.gestantesVisitadas} placeholder="0"/>
       },
       {
         title:"Crianças Formadas",
         element: <Input defaultValue={infoAcoes.pessoas.criancasFormadas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), "criancasFormadas")} value={infoAcoes.pessoas.criancasFormadas} placeholder="0"/>
       }
   ]
   return(
      <>
        {acao === "Praia Acessível" ? 
        <PessoasPorFaixa editionsPeople={infoAcoes.pessoas.pessoasPorFaixaEtaria.porEdicaoFaixaEtaria} infoAcoes={infoAcoes} setInfoAcoes={setInfoAcoes}/>
        : 
        elementsOn.length > 0 ? <label>Impactos do Evento</label> : <></>}
        <div className="style-block">
        {elementsOn.map((campos) => {
           return(
              <>
              {campos.index !== 5 ? 
               <div className="form-block-content number">
                  <div className="input-name">{elementos[campos.index]?.element}<label>{elementos[campos.index]?.title}<span className="message-required"> *</span></label></div>
               </div>
               : 
               <></>
               }
              </>
           )
        })}</div>
      </>
    )
}

interface ICriancasAtendidasEdition {
      edicao: number,
      periodo: {
         dataInicio: string,
         dataFim: string
      },
      numeroAtendimentos: string,
      numeroCriancasAtendidas: string,
}

interface IPlusEdition {
   editions: ICriancasAtendidasEdition[],
   setInfoAcoes(prevState: IAcaoEvento): void
   infoAcoes: IAcaoEvento
}

const PlusEdition = ({editions, setInfoAcoes, infoAcoes}: IPlusEdition) => { // campo exlusivo para a ação Núcleos de Estimulação Precoce
   function addEdition() {
      editions.push({      
         edicao: editions[editions.length - 1].edicao + 1,
         periodo: {
            dataInicio: '',
            dataFim: ''
         },
         numeroAtendimentos: '',
         numeroCriancasAtendidas: '',
      })
      setInfoAcoes({...infoAcoes})
   }

   function onChangeCampos(value, index, campo) {
      infoAcoes.pessoas.criancasAtendidas.porEdicao[index][campo] = value
      setInfoAcoes({...infoAcoes})
    }

    function onChangeData(date, index) {
      infoAcoes.pessoas.criancasAtendidas.porEdicao[index].periodo.dataInicio = date[0]
      infoAcoes.pessoas.criancasAtendidas.porEdicao[index].periodo.dataFim = date[1]
      setInfoAcoes({...infoAcoes})
    }  

    function deleteEdition(index) {
      editions.splice(index, 1)
      setInfoAcoes({...infoAcoes})
    }

    

   return(
      <>
      {editions.map((edicao, index) =>
         <> 
            <label className="title-edition">{index > 0 && (<IoRemoveCircle onClick={()=>deleteEdition(index)} size={25} color='var(--green-dark'/>)}Edição {index+1}</label>
            <div className="style-block edition">
                  <div className="form-block-content">
                     <div className="input-name">
                        <label><span className="message-required"></span>Período</label>
                     </div>
                     <RangePicker
                     value={edicao.periodo.dataInicio === '' || edicao.periodo.dataFim === '' ? undefined : [moment(edicao.periodo.dataInicio, 'DD-MM-YYYY'), moment(edicao.periodo.dataFim, 'DD-MM-YYYY')]}
                     onChange={(e)=>onChangeData(e, index)}
                     format="DD-MM-YYYY"
                     locale={locale}/>
                  </div>
                  <div className="form-block-content number">
                     <div className="input-name">
                        <Input defaultValue={edicao.numeroAtendimentos} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), index, "numeroAtendimentos")} value={edicao.numeroAtendimentos} placeholder="0"/>
                        <label><span className="message-required">* </span>Atendimentos</label>  
                     </div>
                     
                  </div>
                  <div className="form-block-content number">
                     <div className="input-name">
                        <Input defaultValue={edicao.numeroCriancasAtendidas} onChange={(e) => onChangeCampos(e.target.value.replace(/\D/g, ''), index, "numeroCriancasAtendidas")} value={edicao.numeroCriancasAtendidas} placeholder="0"/>
                        <label><span className="message-required">* </span>Crianças Atendidas</label>
                     </div>
                  </div>
            </div>
         </>
         )}
         <div className="add-more-edition"><IoAddCircle onClick={() => addEdition()} size={25} color='var(--green-light'/><label>Adicionar outra edição</label></div>
      </>
   )
}

const CadInfoAcoes = ({ infoAcoes, setInfoAcoes, dataIniciativa }:IRegistrosAcoes): JSX.Element => {

  function maskNumber(value: string) {
    return value
        .replace(/\D/g, '')
  }

  function maskValor(value: string) {
    var valor = value+'';
    valor = valor.replace(/\D/g,'')
    valor = valor.replace(/(\d{1})(\d{1,2})$/, "$1,$2")
    valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    valor = valor.replace(/^(\d)/g,"$1")
    return valor
  }
  
  function onChangeData(date) {
    infoAcoes.datas[0] = date[0]
    infoAcoes.datas[1] = date[1]
    setInfoAcoes({...infoAcoes})
  }

  function maskCEP(value: string) {
   return value
       .replace(/\D/g, '')
       .replace(/(\d{5})(\d)/, '$1-$2')
   }

  const { atividades, setAtividade,
          pessoas, setPessoas,
          recursos, setRecursos} = useContext(RegistroObras)


  let [indexAtividade, setIndexAtividade] = useState(0) // total de campos usados atividades
  let [indexPessoas, setIndexPessoas] = useState(0) // total de campos usados pessoas
  let [indexRecursos, setIndexRecursos] = useState(0) // total de campos usados recursos

  const [atividadesCopia, setAtividadesCopia] = useState<Array<ICampos>>([])
  const [pessoasCopia, setPessoasCopia] = useState<Array<ICampos>>([])
  const [recursosCopia, setRecursosCopia] = useState<Array<ICampos>>([])

  useEffect(()=>{
    if(dataIniciativa) {
      for (var property in dataIniciativa?.mapeamento?.atividadesMapeamento){ 
         if(property !== 'numeroEdicao' && dataIniciativa?.mapeamento?.atividadesMapeamento[property]?.obrigatorio) {
            atividadesCopia.push({
               obrigatorio: dataIniciativa?.mapeamento?.atividadesMapeamento[property]?.obrigatorio,
               usado: dataIniciativa?.mapeamento?.atividadesMapeamento[property]?.usado,
               editavel: dataIniciativa?.mapeamento?.atividadesMapeamento[property]?.editavel,
               index: indexAtividade,
               title: property
            })
            setAtividadesCopia([...atividadesCopia])
         }
         setIndexAtividade(indexAtividade++)
       } setAtividade(atividadesCopia)

       for (let propertyy in dataIniciativa?.mapeamento?.pessoasMapeamento){ 
         if(dataIniciativa?.mapeamento?.pessoasMapeamento[propertyy]?.obrigatorio) { 
            pessoasCopia.push({
               obrigatorio: dataIniciativa?.mapeamento?.pessoasMapeamento[propertyy].obrigatorio,
               usado: dataIniciativa?.mapeamento?.pessoasMapeamento[propertyy].usado,
               editavel: dataIniciativa?.mapeamento?.pessoasMapeamento[propertyy].editavel,
               index: indexPessoas,
               title: propertyy
            })
            setPessoasCopia([...pessoasCopia])
         }
         setIndexPessoas(indexPessoas++)
       } setPessoas(pessoasCopia)

       for (let propertyy in dataIniciativa?.mapeamento?.recursosMapeamento){ 
         if(dataIniciativa?.mapeamento?.recursosMapeamento[propertyy].usado && (propertyy !== 'mudasNativas' && propertyy !== 'mudasFrutiferas' && propertyy !== 'totalInvestido')) {
            recursosCopia.push({
               obrigatorio: dataIniciativa?.mapeamento?.recursosMapeamento[propertyy].obrigatorio,
               usado: dataIniciativa?.mapeamento?.recursosMapeamento[propertyy].usado,
               editavel: dataIniciativa?.mapeamento?.recursosMapeamento[propertyy].editavel,
               index: indexRecursos,
               title: propertyy
            })
            setRecursosCopia([...recursosCopia])
         }
         setIndexRecursos(indexRecursos++)
       } setRecursos(recursosCopia)
    }
  },[dataIniciativa]) // eslint-disable-line react-hooks/exhaustive-deps

 function onChangeCEP(value) {
   value.replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')

   infoAcoes.endereco.cep = value
   setInfoAcoes({...infoAcoes})
 }

 function onChangeEndereco(value, campo) {
   infoAcoes.endereco[campo] = value
   setInfoAcoes({...infoAcoes})
 }

  return (
    <div className="basic-info">
      <div className="block-form">
         <label>Dados Gerais</label>
         <div className="style-block">
         <div className="block-name">
            <div className="form-block-content">
               <div className="input-name"><label>Nome</label></div>
               <Input value={infoAcoes.nome} defaultValue={infoAcoes.nome} onChange={(e) => setInfoAcoes({...infoAcoes, nome: e.target.value})}/>
            </div>
            {dataIniciativa?.mapeamento?.atividadesMapeamento.numeroEdicao.usado && (
            <div className="form-block-content">
               <div className="input-name"><label>Edição</label></div>
               <Input defaultValue={infoAcoes.edicao} onChange={(e) => setInfoAcoes({...infoAcoes, edicao: e.target.value})} value={maskNumber(infoAcoes.edicao)}/>
            </div>)}
         </div>
         <div className="form-block-content">
               <div className="input-name"><label><span className="message-required">* </span>Período</label></div>
               <RangePicker
                  value={infoAcoes.datas[0] === '' || infoAcoes.datas[1] === '' ? undefined : [moment(infoAcoes.datas[0], 'DD-MM-YYYY'), moment(infoAcoes.datas[1], 'DD-MM-YYYY')]}
                  defaultValue={[infoAcoes.datas[0], infoAcoes.datas[1]]}
                  onChange={(e)=>onChangeData(e)}
                  format="DD-MM-YYYY"
                  locale={locale}
               />
         </div>
         </div>
         <label>Localização</label>
         <div className="style-block">
         <div className="form-block-content">
            <div className="input-name"><label><span className="message-required">* </span>Município</label></div>
            <Select
            value={infoAcoes.endereco.municipio === '' ? undefined : infoAcoes.endereco.municipio}
            defaultValue={infoAcoes.endereco.municipio === '' ? undefined : infoAcoes.endereco.municipio}
            showSearch
            style={{ width: 200 }}
            placeholder="Selecione um município"
            optionFilterProp="children"
            onChange={(e)=> onChangeEndereco(e, "municipio")}
            filterOption={(input, option) => {
              const optionValue = option?.label?.toString()?.toLowerCase() ?? '';

              return optionValue?.indexOf(input.toLowerCase()) >= 0;
            }}
         >
            {cearaMunicipios.features.map(municipio => 
               <Option value={municipio.properties.name}>{municipio.properties.name}</Option>
            )}
         </Select>
        </div>
         <div className="localizacao-input">
            <div className="form-block-content">
               <div className="input-name"><label><span className="message-required">* </span>Endereço</label></div>
               <Input value={infoAcoes.endereco.rua} defaultValue={infoAcoes.endereco.rua} placeholder="Ex: Rua da Saudade." onChange={(e) => {onChangeEndereco(e.target.value, "rua")}}/>
            </div>
         
            <div className="form-block-content">
               <div className="input-name"><label>Número</label></div>
               <Input defaultValue={infoAcoes.endereco.numero} onChange={(e) => onChangeEndereco(maskNumber(e.target.value), "numero")} value={infoAcoes.endereco.numero}/>
            </div>

            <div className="form-block-content"> 
               <div className="input-name"><label><span className="message-required">* </span>Bairro</label></div>
               <Input value={infoAcoes.endereco.bairro} defaultValue={infoAcoes.endereco.bairro} onChange={(e) => {onChangeEndereco(e.target.value, "bairro")}}/>
            </div>

            <div className="form-block-content">
               <div className="input-name"><label>CEP</label></div>
               <Input defaultValue={infoAcoes.endereco.cep} onChange={(e) => onChangeCEP(e.target.value)} value={maskCEP(infoAcoes.endereco.cep)} maxLength={9} placeholder="_____-___"/>
            </div>

            <div className="form-block-content">
               <div className="input-name"><label>Ponto de Referência</label></div>
               <Input value={infoAcoes.endereco.pontoDeReferencia} defaultValue={infoAcoes.endereco.pontoDeReferencia} onChange={(e) => {onChangeEndereco(e.target.value, "pontoDeReferencia")}}/>
            </div>
         </div>

         </div>

         {dataIniciativa?.nome === "Núcleos de Estimulação Precoce" ? 
         <PlusEdition infoAcoes={infoAcoes} setInfoAcoes={setInfoAcoes} editions={infoAcoes.pessoas.criancasAtendidas.porEdicao}/>
         : 
         <Beneficiados acao={dataIniciativa?.nome} infoAcoes={infoAcoes} setInfoAcoes={setInfoAcoes} elementsOn={pessoas} /> }
         <AtividadesCampos acao={dataIniciativa?.nome} infoAcoes={infoAcoes} setInfoAcoes={setInfoAcoes} elementsOn={atividades}/>
         

         <label>Investimentos</label>
         <div className="style-block">
         <div className="form-block-content">
            <div className="form-investimentos">
               <div>
                  <div className="input-name"><label><span className="message-required">* </span>Valor Investido</label></div>
                  <Input defaultValue={infoAcoes.valorInvestido} onChange={(e) => setInfoAcoes({...infoAcoes, valorInvestido: maskNumber(e.target.value).replace(/(\d)(\d{1,2})$/, "$1.$2")})} value={maskValor(infoAcoes.valorInvestido)} placeholder="0,00" prefix="R$"/>
               </div>

               <div>
                  <div className="input-name"><label>Valor Aditivo</label><IoInformationCircleOutline/></div>
                  <Input defaultValue={infoAcoes.valorAditivo} onChange={(e) => setInfoAcoes({...infoAcoes, valorAditivo: maskNumber(e.target.value).replace(/(\d)(\d{1,2})$/, "$1.$2")})} value={maskValor(infoAcoes.valorAditivo)} placeholder="0,00" prefix="R$"/>
               </div>
            </div>
         </div>
         </div>
         {recursos.length > 0 ? 
            <>
               <Recursos acao={dataIniciativa?.nome} infoAcoes={infoAcoes} setInfoAcoes={setInfoAcoes} elementsOn={recursos}/>
            </>
         : <></>}

      </div>
    </div>
  )
}

export default CadInfoAcoes
