import { localizacaoGlobal } from '../FieldsGlobal';

const generalInfos = {
  columns: 1,

  fields: [
    {
      key: 'nome',
      label: 'Nome',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'edicao',
      label: 'Edição',
      viewWidget: 'simple-view',
    },
    {
      key: 'descricaoObjetivo',
      label: 'Descrição objetivo',
      viewWidget: 'simple-view',
    },
    {
      key: 'qtdParticipantes',
      label: 'Número de participantes',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'programacao',
      label: 'Programação',
      viewWidget: 'simple-view',
    },
  ],
};

const periodoInfos = {
  columns: 1,
  fields: [
    {
      key: 'periodo.dataInicio',
      label: 'Data início',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'periodo.dataFim',
      label: 'Data fim',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
  ],
};
export function seminarioMaisInfancia() {
  return [
    { title: 'Informações', value: generalInfos },
    { title: 'Localização', value: localizacaoGlobal },
    { title: 'Período', value: periodoInfos },
  ];
}
