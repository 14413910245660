import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Breadcrumb, Device } from '../../components';

import './styles.scss'

import arrowPreviousGreen from '../../assets/arrow-prev-green.svg';
import affirmativeFlag from '../../assets/affirmative-flag-icon.svg';
import negativeFlag from '../../assets/negative-flag-icon.svg';
import pendingIcon from '../../assets/articulator/pendent-icon.svg'
import rightArrow from '../../assets/green-right-arrow.svg'

import { FamilyType, FamilyVisitType } from 'types/components';
import { visitService } from 'services';
import { message } from 'antd';
import { useCallback } from 'react';

export default function FamilyFollowUp() {
  const [family, setFamily] = useState<FamilyType>()
  const [historicVisits, setHistoricVisits] = useState<FamilyVisitType[]>()
  const [yearRange, setYearRange] = useState<number[]>([])

  const history = useHistory()
  const location = useLocation<FamilyType>()
  const device = Device.useDevice()

  const handleGetFamilyVisitsCB = useCallback(handleGetFamilyVisits, [family])

  useEffect(() => {
    if (location.state) {
      setFamily(location.state)
    }
    else history.push('/coordenador-home')
  }, [location, history]);

  useEffect(() => {
    handleGetFamilyVisitsCB()

  }, [handleGetFamilyVisitsCB, family])




  const links = [
    {
      path: '/cordenador-home',
      title: 'Página Inicial',
    },
    {
      path: '/acompanhamento-familia',
      title: 'Acompanhamento da Família',
    },
  ];

  function handleGetFamilyVisits() {

    try {
      visitService.getVisitsByFamilyRepresenting(family?.cpf).then(data => {
        setHistoricVisits(data)
        const aux: number[] = []
        historicVisits?.map(visit => {
          if (!(yearRange.includes(parseInt(visit.date.toString().slice(0, 4), 10)))) {
            aux.push(parseInt(visit.date.toString().slice(0, 4), 10))
          }
        })
        aux.sort((a, b) => b - a)
        setYearRange(aux)

      })
    } catch (err) {
      message.error('Historico de visitas não encontrado')
    }
  }

  function handleGetVisitStatus(visit: FamilyVisitType) {
    if (visit.visitStatusEnum === 'SCHEDULED') {
      return pendingIcon
    }
    else if (visit.visitStatusEnum === 'VISITED') {
      return affirmativeFlag
    }
    else if (visit.visitStatusEnum === 'UNVISITED') {
      return negativeFlag
    }
  }

  function parseDate(date: string): string {
    return date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4)
  }

  function getFormVisited(){
    history.push(`/pesquisa-do-articulador/${family?.nis}`)
  }

  return (
    <div>
      <div className={ device.isSM() ? "family-followup-container" : "mobile-family-followup-container" }>
        <Breadcrumb links={links} />

        <div className="header-container">
          <div className="arrow-container">
            <Link to="/coordenador-home">
              <img src={arrowPreviousGreen} alt="back to articulator home" />
            </Link>
          </div>

          <div className="title-container">
            <h1>Acompanhamento da Família</h1>
            <h2>Família {family?.nameRF}</h2>
          </div>

        </div >

        <div className="content-container">
          <div className="left-content">
            <h2>Dados Gerais da Família</h2>
            <section className="top-info">

              <div className="top-info-left">
                <div className="info-unit">
                  <h3>Nome</h3>
                  <p>{family?.nameRF}</p>
                </div>
                <div className="info-unit">
                  <h3>NIS</h3>
                  <p>{family?.nis}</p>
                </div>
              </div>

              <div className="top-info-right">
                <div className="info-unit">
                  <h3>Gênero</h3>
                  <p>{family?.sexEnum}</p>
                </div>
                <div className="info-unit">
                  <h3>CPF</h3>
                  <p>{family?.cpf}</p>
                </div>
              </div>

            </section>

            <section className="mid-info">

              <div className="mid-info-left">
                <div className="info-unit">
                  <h3>Endereço</h3>
                  <p>{family?.addressStreet}</p>
                </div>
                <div className="info-unit">
                  <h3>Município</h3>
                  <p>{family?.city}, {family?.addressNumber}</p>
                </div>
                <div className="info-unit">
                  <h3>Telefone</h3>
                  <p>{family?.phone}</p>
                </div>
              </div>

              <div className="mid-info-right">
                <div className="info-unit">
                  <h3>Bairro</h3>
                  <p>{family?.neighborhood}</p>
                </div>
                <div className="info-unit">
                  <h3>Localização</h3>
                  <p>Localização</p>
                </div>
              </div>

            </section>

            <section className="bot-info">
              <div className="bot-info-left">
                <div className="info-unit">
                  <h3>Quantidade de crianças menores de 6 anos</h3>
                  <p>Qtd criança menor q 6 anos</p>
                </div>
              </div>
            </section>
          </div>
          <div className="right-content">
            <h2>Histórico de todas as visitas</h2>
            <div className="historic-container">
              <div className="year-container">

                <div className="visits-container">
                  {historicVisits?.map((visit, j: number) => (
                    <div className="visit-box" onClick={() => {getFormVisited()}}>
                      <div className="visit-box-left">
                        <img src={handleGetVisitStatus(visit)} alt={visit.visitStatusEnum + ' Icon'} />
                        <p>{parseDate(visit.date.toString())}</p>
                      </div>

                      <div className="visit-box-right">
                        <img src={rightArrow} alt="" />
                      </div>

                    </div>
                  ))}
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  )

}