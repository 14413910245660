const generalInfos = {
  columns: 2,
  formItemLayout: null, // Must set this for inline layout
  colon: true,
  fields: [
    {
      key: 'tituloDaFormacao',
      label: 'Título da formação',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
  ],
};

export function formacaoSeduc() {
  return [{ title: 'Informações', value: generalInfos }];
}
