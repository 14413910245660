import FormBuilder from 'antd-form-builder';

import React, { useState, useEffect } from 'react';
import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';
import { citiesNamesCE } from 'utils/citiesNames';

import { regioesPlanejamento } from '../../../../../utils/regioesplanejamento'

const regioesPlanejamentoNames = regioesPlanejamento.map(regiao => {
  return regiao.regiao
})

interface porRegiaoProps {
  form: any;
  viewMode?: boolean;
  size: any;
  actionCurrent: string;
}

function PorRegiao({
  form,
  viewMode,
  size,
  actionCurrent,
}: porRegiaoProps) {
  const [counterList, setCounterList] = useState(0);
  const [newCont, setNewCont] = useState(false)
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    setNewCont(true);

    if(!newCont){
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      });
    }else {
      setList([])
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      });
    }
   
  }, [size]); // eslint-disable-line react-hooks/exhaustive-deps

  function addFields(indice) {
    const newList = {
      columns: 2,
      fields: [
        {
          key: `porRegiao/.regiao/${indice}`,
          label: 'Região',
          widget: 'select',
          options: regioesPlanejamentoNames,
          widgetProps: { showSearch: true },
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: 'O campo "Região" é obrigatório',
            },
          ],
        },
        {
          key: `porRegiao/.municipiosParticipantes/${indice}`,
          label: 'Municípios Participantes',
          widget: 'select',
          options: citiesNamesCE,
          widgetProps: { mode: 'multiple', allowClear: true },
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: 'O campo "Município Participantes" é obrigatório',
            },
          ],
        },
        {
          key: `porRegiao/.${
            actionCurrent === 'prevencaoEmFamilia'
              ? 'qtdFamiliaresBeneficiados'
              : 'qtdGestantesPuerperasBeneficiadas'
          }/${indice}`,
          label: `Quantidade de ${
            actionCurrent === 'prevencaoEmFamilia' ? 'familiares beneficiados' : 'gestantes/puérperas beneficiadas'
          } `,
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: `O campo "Quantidade de ${
                actionCurrent === 'prevencaoEmFamilia' ? 'familiares beneficiados' : 'gestantes/puérperas beneficiadas'
              } " é obrigatório`,
            },
          ],
        },
        {
          key: `porRegiao/.qtdCapacitacoes/${indice}`,
          label: 'Quantidade de capacitações',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: 'O campo "Quantidade de capacitações" é obrigatório',
            },
          ],
        },
        {
          key: `porRegiao/.qtdProfissionaisFormados/${indice}`,
          label: 'Quantidade de profissionais formados',
          widget: 'number',
          viewWidget: 'simple-view',
          widgetProps: { min: 0, style: {width: '75%'} },
          rules: [
            {
              required: true,
              message: 'O campo "Quantidade de profissionais formados" é obrigatório',
            },
          ],
        },
      ],
    };
    setList(prev => [...prev, newList]);
    setCounterList(prev => prev + 1);
  }

  function removeFields(index) {
    list.splice(index, 1);

    setList([...list]);
  }

  return (
    <div>
      <fieldset>
        <legend>Por Região </legend>
        {list.map((metaList, index) => {
          return (
            <React.Fragment key={index}>
              {!viewMode && index > 0 && (
                <div className="add-button" onClick={() => removeFields(index)}>
                  <IoRemoveCircle size={25} color="var(--green-dark)" />
                  <strong>Remover Região</strong>
                </div>
              )}
              <FormBuilder form={form} meta={metaList} viewMode={viewMode} />
            </React.Fragment>
          );
        })}
        {!viewMode && (
          <div className="add-button" onClick={() => addFields(counterList)}>
            <IoAddCircle size={25} color="var(--green-dark" />
            <strong>Adicionar nova</strong>
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default PorRegiao;
