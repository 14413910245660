import { currentSemester, currentYear, SimpleLoading } from 'components';
import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { WorkplanType } from 'types/components';

import arrowPreviousGreen from '../../assets/arrow-prev-green.svg';

import pendentIcon from '../../assets/articulator/pendent-icon.svg'
import aproovedIcon from '../../assets/articulator/aprooved-icon.svg'
import refusedIcon from '../../assets/articulator/refused-icon.svg'
import rightArrow from '../../assets/green-right-arrow.svg'

import { workPlanService } from '../../services/resources/workPlanService'
import { systemService } from '../../services/resources/systemService'

import './styles.scss'
import { Form, message, Switch, Select, Button, } from 'antd';

import { Device } from 'components'
const { Item } = Form;
const { Option } = Select;

const WORKPLANIMGS = {
  AWAITING: <img alt='Pending Icon' src={pendentIcon} />,
  APPROVED: <img alt='Approved Icon' src={aproovedIcon} />,
  REFUSED: <img alt='Refused Icon' src={refusedIcon} />
}

export default function CoordinatorAllWorkplans() {
  
  const [workplans, setWorkplans] = useState<WorkplanType[]>([])
  const [workplansByStatus, setWorkplansByStatus] = useState<WorkplanType[]>([])

  const[status, setStatus] = useState(false);
  const[loadingStatus, setLoadingStatus] = useState(false);

  const[loadingWorkplanSelect, setLoadingWorkplanSelect] = useState(false);
  const[selectedStatus, setSelectedStatus] = useState('');
  const[loadingWorkplans, setLoadingWokplans] = useState(false);

  const history = useHistory()
  const device = Device.useDevice()
  console.log(status)

 /* function handleSelectChange(value: any) {
    let findedStatus = workplans.find(workplan => workplan.status === value);
  
    if (findedStatus) {
      setSelectedStatus(findedStatus);
    }else{
        message.error('Não foi encontrado nenhum plano de trabalho com o status selecionado')
    }
  }*/

  function handleSelectChange(value: string){
    setSelectedStatus(value);
  }

  async function handleWorkplansByStatus() {
    setLoadingWokplans(true);
    await workPlanService.getWorkPlansByStatus(selectedStatus)
    .then(data => {
        setWorkplansByStatus(data)
        let x = data.filter(data => data.status == selectedStatus);
        if(x.length < 1){
          message.error('Não foi encontrado nenhum plano de trabalho com o status selecionado')
        } 
      })
      .catch(() => (message.error('Erro ao buscar planos de trabalho')));
      setLoadingWokplans(false);
    }

  useEffect(() => {
    workPlanService.getWorkPlans()
      .then(data => {
        setWorkplans(data.filter(data => data.year === currentYear() && data.semester === currentSemester()))
      })
      .catch(() => message.error('Erro ao listar os planos de trabalho'))
  }, []) 

  useEffect(() => {
    setLoadingStatus(true)
    systemService.getStatus()
    .then(data => {setStatus(data.data.status)})
    .catch(() => message.error('Erro'))
    .finally(() => setLoadingStatus(false))
  }, [status])

  function getStatusImg(workplan: WorkplanType) {
    return WORKPLANIMGS[workplan.status];
  }

  function getYearWithSemester(workplan: WorkplanType) {
    return workplan.year + workplan.semester*0.1;
  }

  function handleNavigateAgentWorkplans(workplan: WorkplanType) {
    history.push(`/coordenador-articulador-workplan/${workplan.id}`, workplan);
  }

  function disableSendWorkplan(checked: boolean){
    try {
      systemService.statusDisable(checked)
      setStatus(checked)
    } catch (error) {
      message.error('Não foi possível desabilitar o envio de planos de trabalho.')
    }
  }

  const years = useMemo(() => Array.from(
    new Set(workplans
      .map(workplan => workplan.year + workplan.semester * 0.1)
      .sort((a, b) => a - b)
    )),
    [workplans]);

    return (
      <div className='page-container'>
        <div className='content-container'>
          <section className='title-container'>
            <Link to='/coordenador-home'>
              <img src={arrowPreviousGreen} alt="back to coordinator home" />
            </Link>
            <h1>Planos de Trabalho </h1>
            <Switch 
              checkedChildren="desativar envios" 
              unCheckedChildren="ativar envios" 
              onClick={disableSendWorkplan}
              style={{marginLeft: '10px', fontWeight: 'bold', display: 'flex', alignSelf: 'center'}}
              loading={loadingStatus}
              checked={status}
            />
              
          </section>
          <div className='workplanSearch' style={{width: '31rem', marginTop: '20px', display: 'flex', justifyContent: 'space-between'}}>
            <Item name="city">
              <Select placeholder='Status do plano' loading={loadingWorkplanSelect} onChange={handleSelectChange} showSearch>
                <Option value="APPROVED">APROVADO</Option>
                <Option value="REFUSED">RECUSADO</Option>
                <Option value="AWAITING">PENDENTE</Option>
              </Select>
            </Item>

            <Button style={{backgroundColor: '#33a02c', color: '#f2f2f2'}}
              onClick={() => handleWorkplansByStatus()}
              className='search'
              loading={loadingWorkplans}>
              BUSCAR PLANOS
            </Button>
          </div>

          <section className={ device.isSM() ? 'inner-content-container' : 'mobile-inner-content-container'}>
            
            <div className='workplans-container'>
              
              {!loadingWorkplans ?
                (years.map((year: number, i: number) => (
                  <div key={`${year}-${i}`}>
                    <div className="year-container">
                      <div className="workplan-units-container">
                        {workplansByStatus
                          .filter((workplan) => getYearWithSemester(workplan) === year)
                          .map((workplan) => (
                            <div
                              className="workplan-box"
                              onClick={() => handleNavigateAgentWorkplans(workplan)}
                              key={workplan.id}
                            >
                              <div className="workplan-box-left">
                                {getStatusImg(workplan)}
                                <p>{workplan.agentId?.name} - {workplan.city.toUpperCase()}</p>
                              </div>
  
                              <div className="workplan-box-right">
                                <p>{getYearWithSemester(workplan)}</p>
                                <img src={rightArrow} alt="" />
                              </div>
  
                            </div>
                          ))}
                      </div>
  
                      <span className="vertical-year">{year}</span>
                    </div>
  
                    <div className="year-divisor"></div>
                  </div>
                ))
                ) : (
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <SimpleLoading
                      tip="Carregando histórico de planos de trabalho"
                    />
                  </div>
                )
              }
            </div>
          </section>
        </div>
      </div>
    )
  }
  
