import XLSX from 'xlsx';

import { titleTable } from '../formatXLSXgeral';

export function formatXLSXpadin(data, workbook) {
  let newTab: any = [];
  let newTabVisita: any = [];
  let newTabEncoComu: any = [];
  let linhaVM: any = ["Ano", 
                     "Mês", 
                     "Município", 
                     "Crianças atendidas de 0 a 11 meses", 
                     "Crianças atendidas de 12 a 23 meses",
                     "Crianças atendidas de 24 a 35 meses",
                     "Crianças atendidas de 36 a 47 meses",
                     "Crianças atendidas fora da faixa etária",
                     "Famílias Atendidas",
                     "Visitas Registradas"]
  let linhaF: any = []

  let linhaEU: any =["Ano", 
                    "Mês",
                    "Município",
                    "visitas registradas",
                    "Famílias atendidas",
                    "Crianças atendidas de 0 a 11 meses", 
                    "Crianças atendidas de 12 a 23 meses",
                    "Crianças atendidas de 24 a 35 meses",
                    "Crianças atendidas de 36 a 47 meses",
                    "Crianças atendidas fora da faixa etária",
                   ]


  data.forEach((padinObj, index: number) => {
    const padin = padinObj.padin;

    if(padin.tipo === "Visita Municipio") {
      if(index === 0){
        workbook.SheetNames.push(
          "Visita Município",
        );
      }
     
      if(linhaVM[3] === "Crianças atendidas de 0 a 11 meses"){
        newTabVisita.push(linhaVM)
      }
   
      linhaVM = []
      Object.keys(padin.visitaMunicipio).forEach(key => {
        linhaVM.push(padin.visitaMunicipio[key])
      })
      newTabVisita.push(linhaVM)

    } else if(padin.tipo === "Encontros Comunitários") {
      if(index === 0){
        workbook.SheetNames.push(
          "Encontros Comunitários",
        );
        newTabEncoComu.push(linhaEU)

      }

  
      linhaEU = []
   
        Object.keys(padin.encontrosComunitarios).forEach(key => {
          linhaEU.push(padin.encontrosComunitarios[key])
        })
        newTabEncoComu.push(linhaEU)
    } else {
      workbook.SheetNames.push(
        `${padin.tipo} ${index + 1}`,
      );
      
      linhaF = [''] 
      titleTable(padin.formacao.ciclosPadin[0], linhaF)
      newTab.push(linhaF)

      padin.formacao.ciclosPadin.forEach((element, indexCiclo) => {  
        linhaF = [`Ciclo ${indexCiclo + 1}`] 
        Object.keys(element).forEach(key => {
          if(key === 'periodo') {
            linhaF.push(new Date(element[key].dataInicio).toLocaleDateString('fr-FR'));
            linhaF.push(new Date(element[key].dataFim).toLocaleDateString('fr-FR'));
          } else if(key === 'polos') linhaF.push(element[key].join(', '));
          else linhaF.push(element[key])
        })
        newTab.push(linhaF)
      });

      let content = XLSX.utils.aoa_to_sheet(newTab);

      workbook.Sheets[
        `${padin.tipo} ${index + 1}`
      ] = content; 
      newTab = []
    }
  });

 if(newTabVisita.length > 0){
  let content = XLSX.utils.aoa_to_sheet(newTabVisita);
  workbook.Sheets[
    "Visita Município"
  ] = content;
 }

 if(newTabEncoComu.length > 0){
  let content = XLSX.utils.aoa_to_sheet(newTabEncoComu);
  workbook.Sheets[
    "Encontros Comunitários"
  ] = content;
 }
  return workbook;
}
