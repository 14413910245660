import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container } from './style';

const host = process.env.REACT_APP_LOGIN_URL;
const appKey = process.env.REACT_APP_IRIS_SOCIAL_KEY;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Unauthorized() {
  const query = useQuery();
  
  const redirectURI = query.get('redirect-uri') ?? '/programa-mais-infancia';
  const url = `${host}/?origin=${redirectURI}&app-key=${appKey}`

  return (
    <Container>
      <h3>Usuário não autorizado!</h3>
      <div className="buttons">
        <Link to="/programa-mais-infancia">TELA INICIAL</Link>
        <a href={url}>FAZER LOGIN</a>
      </div>
    </Container>
  );
}