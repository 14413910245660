export const headCapacitacaoAgentes= [
  [
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Profissionais capacitados',
      colSpan: 2,
    },
    {
      content: 'Período',
      colSpan: 2,
    },
    
  ],
  [
    { title: 'Formato', dataKey: 'formato' },
    { title: 'Municípios', dataKey: 'municipios' },
    { title: 'Agentes capacitados', dataKey: 'qtdAgentesCapacitados' },
    { title: 'Turmas ', dataKey: 'qtdTurmas' },
    { title: 'Valor Aditivo ', dataKey: 'valorAditivo' },
    { title: 'Valor Investido', dataKey: 'valorInvestido' },
   
    { title: 'Tipo', dataKey: 'tipo' },
    { title: 'Profissionais Capacitados', dataKey: 'qtdProfissionaisCapacitados' },
   
    { title: 'Data inicial', dataKey: 'dataInicio' },
    { title: 'Datal final', dataKey: 'dataFim' },
  
    
  ],
];

export const headPrevFamilia= [
  [
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Por Região',
      colSpan: 5,
    },
    {
      content: 'Período',
      colSpan: 2,
    },
    
  ],
  [
    { title: 'Descrição', dataKey: 'descricaoProjeto' },
    { title: 'Edição', dataKey: 'edicao' },
    { title: 'Nome', dataKey: 'nome' },
  
    { title: 'Valor Inicial', dataKey: 'valorInicial' },
    { title: 'Valor Aditivo ', dataKey: 'valorAditivo' },

    { title: 'Região', dataKey: 'regiao'},
    { title: 'Municípios Participantes', dataKey: 'municipiosParticipantes' },
    { title: 'Capacitações', dataKey: 'qtdCapacitacoes' },
    { title: 'Familiares beneficiados', dataKey: 'qtdFamiliaresBeneficiados' },
    { title: 'Profissionais formados', dataKey: 'qtdProfissionaisFormados' },
   
    { title: 'Data inicial', dataKey: 'dataInicio' },
    { title: 'Datal final', dataKey: 'dataFim' },
  
    
  ],
];

export const headNEP = [
  [
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },

    {
      content: 'Diagnosticos',
      colSpan: 2,
    },
    {
      content: 'Formações',
      colSpan: 2,
    },
  ],
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'Município', dataKey: 'municipio' },
    { title: 'Localização', dataKey: 'localizacao' },
    { title: 'Mês', dataKey: 'mes' },
    { title: 'Ano', dataKey: 'ano' },
    { title: 'Atendimentos', dataKey: 'qtdAtendimentos' },
    {
      title: 'Crianças atendidas',
      dataKey: 'qtdCriancasAtendidas',
    },
    { title: 'Nome diagnostico', dataKey: 'nomeDiagnostico' },
    { title: 'Atendimentos', dataKey: 'qtdAtendDiagnostico' },
    { title: 'Formação', dataKey: 'formacao' },
    { title: 'Quantidade ', dataKey: 'quantidade' },
  ],
];


export const headNCapacitacao = [
  [

    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Municipio capacitações',
      colSpan: 3,
    },
    {
      content: 'Período',
      colSpan: 2,
    },
    
  ],
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'Descrição', dataKey: 'descricao' },
  
    { title: 'Valor investido ', dataKey: 'valorInvestido' },
    { title: 'Valor Aditivo ', dataKey: 'valorAditivo' },
    { title: 'Publico alvo ', dataKey: 'publicoAlvo' },
   
    { title: 'Capacitações gerais', dataKey: 'capacitacoesGerais' },
    { title: 'Nome município', dataKey: 'nomeMunicipio' },
    { title: 'Capacitações', dataKey: 'numeroCapacitacoes' },
    { title: 'Pessoas formadas', dataKey: 'numeroPessoasFormadas' },
    { title: 'Data inicial', dataKey: 'dataInicio' },
    { title: 'Datal final', dataKey: 'dataFim' },
  
    
  ],
];

export const headPadinVisitaMunicipio = [
  [ { title: 'Ano', dataKey: 'ano' },
   { title: 'Mês', dataKey: 'mes' },
   { title: 'Município', dataKey: 'municipio' },
   { title: 'Qtd de crianças atendidas de 0 a 11 meses', dataKey: 'qtdCriancasAtendidas0a11Meses' },
   { title: 'Qtd de crianças atendidas de 12 a 23 meses', dataKey: 'qtdCriancasAtendidas12a23Meses' },
   { title: 'Qtd de crianças atendidas de 24 a 35 meses', dataKey: 'qtdCriancasAtendidas24a35Meses' },
   { title: 'Qtd de crianças atendidas de 36 a 47 meses', dataKey: 'qtdCriancasAtendidas36a47Meses' },
   { title: 'Qtd de crianças atendidas fora da faixa etária', dataKey: 'qtdCriancasAtendidasForaFaixaEtaria' },
   { title: 'Qtd de famílias atendidas', dataKey: 'qtdFamiliasAtendidas' },
   { title: 'Qtd de visitas registradas', dataKey: 'qtdVisitasRegistradas' },

   ]
 ];

 export const headPadinEncontrosComunitarios= [
  [ { title: 'Ano', dataKey: 'ano' },
   { title: 'Mês', dataKey: 'mes' },
   { title: 'Município', dataKey: 'municipio' },
   { title: 'Qtd de visitas registradas', dataKey: 'qtdVisitasRegistradas'},
   { title: 'Qtd de famílias atendidas', dataKey: 'qtdFamiliasAtendidas'},
   { title: 'Qtd de crianças atendidas de 0 a 11 meses', dataKey: 'qtdCriancasAtendidas0a11Meses' },
   { title: 'Qtd de crianças atendidas de 12 a 23 meses', dataKey: 'qtdCriancasAtendidas12a23Meses' },
   { title: 'Qtd de crianças atendidas de 24 a 35 meses', dataKey: 'qtdCriancasAtendidas24a35Meses' },
   { title: 'Qtd de crianças atendidas de 36 a 47 meses', dataKey: 'qtdCriancasAtendidas36a47Meses' },
   { title: 'Qtd de crianças atendidas fora da faixa etária', dataKey: 'qtdCriancasAtendidasForaFaixaEtaria' },
   ]
 ];

 export const headPadinFormacao = [
  [
    {
      content: 'Ciclos',
      colSpan: 12,
    },
    
  ],
  [
    { title: 'Carga horária', dataKey: 'cargaHoraria' },
    { title: 'Conteúdo programático', dataKey: 'conteudoProgramatico' },  
    { title: 'Polos ', dataKey: 'polos' },
    { title: 'Municípios por polo ', dataKey: 'municipios' },
    { title: 'ADIS', dataKey: 'qtdAdis' },
    { title: 'Articuladores regionais mais infância', dataKey: 'qtdArticuladoresRegionaisMaisInfancia' },
    { title: 'Formadores', dataKey: 'qtdFormadores' },
    { title: 'Supervisores', dataKey: 'qtdSupervisores' },
    { title: 'Técnicos CREDE', dataKey: 'qtdTecnicosCrede' },
    { title: 'Técnicos SEDUC', dataKey: 'qtdTecnicosSeduc' }, 
    { title: 'Data início', dataKey: 'dataInicio' },
    { title: 'Data fim', dataKey: 'dataFim' },
  ],
];

export const headRegistroAcumulado = [
  [
    { title: 'Número de famílias', dataKey: 'numeroFamilias' },
    { title: 'Número de visitar registradas', dataKey: 'numeroVisitasRegistradas' },  
    { title: 'Crianças de 0 a 36 meses', dataKey: 'qtdCriancasDe0a36meses' },
    { title: 'Crianças de 37 a 72 meses', dataKey: 'qtdCriancasDe37a72meses' },
    { title: 'Gestantes beneficiadas', dataKey: 'qtdGestantesBeneficiadas' },
    { title: 'Data início', dataKey: 'dataInicio' },
    { title: 'Data fim', dataKey: 'dataFim' },
  ],
];
export const headRegistroCapacitacao= [
  [
    { title: 'Descrição', dataKey: 'descricao' },
    { title: 'Perfil público', dataKey: 'perfilPublico' },  
    { title: 'Quantidade de público', dataKey: 'qtdPublico' },
    { title: 'Regiões', dataKey: 'regioes' },
    { title: 'Tema capacitação', dataKey: 'temaCapacitacao' },
    { title: 'Data início', dataKey: 'dataInicio' },
    { title: 'Data fim', dataKey: 'dataFim' },
  ],
];
export const headValeGas = [
  [
    { title: 'Número de familiasa atendias', dataKey: 'numeroFamiliasAtendias' },
    { title: 'Número recargas distribuidas', dataKey: 'numeroRecargasDistribuidas' },  
  ],
];
export const headEventoRegular = [
  [
    { title: 'Ano', dataKey: 'ano' },
    { title: 'Mês', dataKey: 'mes' },  
    { title: 'Município', dataKey: 'municipio' },
    { title: 'Número de famílias', dataKey: 'numeroFamilias' },
    { title: 'Número de visitas registradas', dataKey: 'numeroVisitasRegistradas' },
    { title: 'Crianças de 0 a 36 meses', dataKey: 'qtdCriancasDe0a36meses' },
    { title: 'Crianças de 37 a 72 meses', dataKey: 'qtdCriancasDe37a72meses' },
    { title: 'Gestantes beneficiadas', dataKey: 'qtdGestantesBeneficiadas' },

  ],
]; 

export const obrasTitle = [
  [ { title: 'Data ordem de serviço', dataKey: 'dataOrdemServico' },
   { title: 'Estágio de execução', dataKey: 'estagioExecucao' },
   { title: 'Grupo tipo', dataKey: 'grupoTipo' },
   { title: 'Investimento total', dataKey: 'investimentoTotal' },
   { title: 'Município', dataKey: 'municipio' },
   { title: 'Nível de importância', dataKey: 'nivelImportancia' },
   { title: 'Nome', dataKey: 'nome' },
   { title: 'Orgão', dataKey: 'orgao' },
   { title: 'Percentual execução física', dataKey: 'percentualExecucaoFisica' },
   { title: 'Previsão de entrega', dataKey: 'previsaoEntrega' },
   { title: 'Beneficiados', dataKey: 'qtdBeneficiados' },
   {
     title: 'Total de beneficiados',
     dataKey: 'qtdTotalBeneficiados',
   },
   { title: 'Quantidade', dataKey: 'quantidade' },
   { title: 'Quantidade anterior', dataKey: 'quantidadeAnterior' },
   { title: 'Quantidade execução física', dataKey: 'quantidadeExecucaoFisica' },
   { title: 'Status movimentação', dataKey: 'statusMovimentacao' },
   { title: 'Tipo beneficiado', dataKey: 'tipoBeneficiado' },
 
   { title: 'Unidade', dataKey: 'unidade' },
   ]
 ];

 
export const headDocumentario = [
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'DVDs doados', dataKey: 'qtdDvdsDoados' },
    { title: 'Exibições', dataKey: 'qtdExibicoes' },
  ],
];

export const headPublicacoes = [
  [
    { title: 'Ano', dataKey: 'ano' },
    { title: 'Autores', dataKey: 'autores' },
    { title: 'Edição', dataKey: 'edicao' },
    { title: 'Editora', dataKey: 'editora' },
    { title: 'Link material integral', dataKey: 'linkMaterialIntegral' },
    { title: 'Local', dataKey: 'local' },

    { title: 'Quantidade de páginas', dataKey: 'qtdPaginas' },
    { title: 'Referências', dataKey: 'referencias' },
    { title: 'Sub título', dataKey: 'subtitulo' },
    { title: 'Título', dataKey: 'titulo' },

  ],
];


export const headArteNaPraca = [
  [
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Localização',
      colSpan: 7,
    },

  ],
  [
    

    { title: 'Nome', dataKey: 'nome' },
    { title: 'Programação', dataKey: 'programacao' },
    { title: 'Data', dataKey: 'data' },

    { title: 'Bairro', dataKey: 'bairro' },
    { title: 'CEP', dataKey: 'cep' },
    { title: 'Estação', dataKey: 'estacao' },
    { title: 'Município', dataKey: 'municipio' },
    { title: 'Número', dataKey: 'numero' },
    { title: 'Ponto de referência', dataKey: 'pontoDeReferencia' },
    { title: 'Rua', dataKey: 'rua' },


  ],
];

export const headSementesDoAmanha = [
  [

    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Por Região',
      colSpan: 5,
    },
    {
      content: 'Período',
      colSpan: 2,
    },
    
  ],
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'Edição', dataKey: 'edicao' },
    { title: 'Descrição do projeto', dataKey: 'descricaoProjeto' },  
    { title: 'Valor Inicial ', dataKey: 'valorInicial' },
    { title: 'Valor Aditivo ', dataKey: 'valorAditivo' },

    { title: 'Região', dataKey: 'regiao' },
    { title: 'Municipios Participantes', dataKey: 'municipiosParticipantes' },
    { title: 'Capacitações', dataKey: 'qtdCapacitacoes' },
    { title: 'Gestantes/Puérperas beneficiadas', dataKey: 'qtdGestantesPuerperasBeneficiadas' },
    { title: 'Profissionais formados', dataKey: 'qtdProfissionaisFormados' },

    { title: 'Data inicial', dataKey: 'dataInicio' },
    { title: 'Datal final', dataKey: 'dataFim' }, 
  ],
];

export const headSeminarioInternacionalMaisInfancia = [
  [

    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Localização',
      colSpan: 8,
    },
    {
      content: 'Período',
      colSpan: 2,
    },
    
  ],
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'Edição', dataKey: 'edicao' },  
    { title: 'descricão do objetivo', dataKey: 'descricaoObjetivo' },
    { title: 'Participantes', dataKey: 'qtdParticipantes' },
    { title: 'Programação', dataKey: 'programacao' },
   
    { title: 'Municipio', dataKey: 'municipio' },
    { title: 'Rua', dataKey: 'rua' },
    { title: 'Bairro', dataKey: 'bairro' },
    { title: 'CEP', dataKey: 'cep' },
    { title: 'Número', dataKey: 'numero' },
    { title: 'Ponto de referência', dataKey: 'pontoDeReferencia' },
    { title: 'Longitude', dataKey: 'longitude' },
    { title: 'Latitude', dataKey: 'latitude' },

    { title: 'Data inicial', dataKey: 'dataInicio' },
    { title: 'Datal final', dataKey: 'dataFim' }, 
  ],
];

export const headLancamentoDeLivroOuRelatorio = [
  [

    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Local do Lançamento',
      colSpan: 9,
    },
  ],
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'Edição', dataKey: 'edicao' },  
    { title: 'Data do lançamento', dataKey: 'dataLancamento' },
    { title: 'Referências', dataKey: 'referencias' },

   
    { title: 'Municipio', dataKey: 'municipio' },
    { title: 'Rua', dataKey: 'rua' },
    { title: 'Bairro', dataKey: 'bairro' },
    { title: 'CEP', dataKey: 'cep' },
    { title: 'Número', dataKey: 'numero' },
    { title: 'Ponto de referência', dataKey: 'pontoDeReferencia' },
    { title: 'Estação', dataKey: 'estacao' },
    { title: 'Longitude', dataKey: 'longitude' },
    { title: 'Latitude', dataKey: 'latitude' },
  ],
];

export const headPraiaAcessivel = [
  [

    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Localização',
      colSpan: 9,
    },
    {
      content: 'Atendimentos',
      colSpan: 5,
    },
  ],
  [
    { title: 'Nome', dataKey: 'nome' },
    { title: 'Data', dataKey: 'data' },
    { title: 'É data comemorativa?', dataKey: 'dataComemorativa' },

   
    { title: 'Municipio', dataKey: 'municipio' },
    { title: 'Rua', dataKey: 'rua' },
    { title: 'Bairro', dataKey: 'bairro' },
    { title: 'CEP', dataKey: 'cep' },
    { title: 'Número', dataKey: 'numero' },
    { title: 'Ponto de referência', dataKey: 'pontoDeReferencia' },
    { title: 'Estação', dataKey: 'estacao' },
    { title: 'Longitude', dataKey: 'longitude' },
    { title: 'Latitude', dataKey: 'latitude' },

    { title: 'Menores de 12 anos', dataKey: 'menoresDe12anos' },
    { title: 'Entre 13 e 17 anos', dataKey: 'entre12e19' },
    { title: 'Adultos', dataKey: 'adultos' },
    { title: 'Idoso maior que 60', dataKey: 'idosoMaiorQue60' },

  ],
];


export const headTodaBandaDeMusicaEscola = [
  [
    { title: 'Municipios', dataKey: 'municipios' },
    { title: 'Instrumentos doados', dataKey: 'qtdInstrumentosDoados' },
    { title: 'Regentes envolvidos', dataKey: 'qtdRegentesEnvolvidos' },
    { title: 'Valor investido', dataKey: 'valorInvestido' },
  ],
];

export const headEducacaoAmbiental = [
  [
    { title: 'Equipamento', dataKey: 'equipamento' },
    { title: 'Tipo', dataKey: 'tipo' },
    { title: 'Municípios', dataKey: 'municipio' },
    { title: 'Data de inauguração', dataKey: 'dataInauguracao' },
    { title: 'Mudas plantadas', dataKey: 'qtdMudasPlantadas' },
    { title: 'Mudas doadas', dataKey: 'qtdMudasDoadas' },
  ],
];

export const headMaisNutricao = [
  [
    [
      {
        content: '',
        colSpan: 1,
      },
      {
        content: '',
        colSpan: 1,
      },
      {
        content: 'Entidades por Município',
        colSpan: 2,
      },
      {
        content: 'Período',
        colSpan: 2,
      },
    ],
    [
      { title: 'Toneladas de Alimentos Doados', dataKey: 'qtdAlimentosDoados' },
      { title: 'Quantidade de Pessoas Atendidas', dataKey: 'qtdPessoasAtendidas' },  
    
      { title: 'Municipio', dataKey: 'municipio' },
      { title: 'Quantidade de Entidades', dataKey: 'qtdEntidades' },
  
      { title: 'Data inicial', dataKey: 'dataInicio' },
      { title: 'Datal final', dataKey: 'dataFim' }, 
    ]
  ],
  [
    [
      { title: 'Data da Capacitação', dataKey: 'dataCapacitacao' },
      { title: 'Formato', dataKey: 'formato' },
      { title: 'Municípios', dataKey: 'municipios' },
      { title: 'Quantidade de Pessoas Capacitadas', dataKey: 'qtdPessoasCapacitadas' },
      { title: 'Temas', dataKey: 'temas' },
    ]
  ],
  [
    [
      { title: 'Municípios', dataKey: 'municipios' },
      { title: 'Quantidade de Municípios Contemplados', dataKey: 'numeroMunicipiosContemplados' },
      { title: 'Quantidade de Pessoas Atendidas', dataKey: 'qtdePessoasAtendidas' },
      { title: 'Quantidade de Quilos Doados', dataKey: 'qtdeQuilosDoados' },
      { title: 'Quantidade de Cestas Doadas', dataKey: 'unidadesCestasDoadas' },
    ]
  ],
];

export const headFormacaoSeduc = [
  [
    {
      content: 'Ciclos',
      colSpan: 14,
    },
  ],
  [
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: '',
      colSpan: 1,
    },
    {
      content: 'Participantes por perfil',
      colSpan: 8,
    },

  ],
  [
    { title: 'Carga horária', dataKey: 'cargaHoraria' },
    { title: 'Conteúdo programático', dataKey: 'conteudoProgramatico' },
    { title: 'CREDES', dataKey: 'credes' },
    { title: 'Municípios das CREDES', dataKey: 'municipios' },

    { title: 'Data inicial', dataKey: 'dataInicio' },
    { title: 'Datal final', dataKey: 'dataFim' },

    { title: 'Articuladores regionais mais infância', dataKey: 'articuladoresRegionaisMaisInfancia' },
    { title: 'Coordenadores municipais educação infantil', dataKey: 'coordenadoresMunicipaisEducacaoInfantil' },
    { title: 'Formadores municipais', dataKey: 'formadoresMunicipais' },
    { title: 'Formadores regionais', dataKey: 'formadoresRegionais' },
    { title: 'Gestores escolares', dataKey: 'gestoresEscolares' },
    { title: 'Professores', dataKey: 'professores' },
    { title: 'Técnicos CREDE', dataKey: 'tecnicosCrede' },
    { title: 'Técnicos SEDUC', dataKey: 'tecnicosSeduc' },

  ],
];