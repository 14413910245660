import { localizacaoGlobal } from '../FieldsGlobal';

const generalInfos = {
  fields: [
    {
      key: 'nome',
      label: 'Nome',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'data',
      label: 'Data',
      widget: 'date-picker',
      placeholder: 'Selecione a data',
      viewWidget: 'date-view',
    },
    {
      key: 'dataComemorativa',
      label: 'Data comemorativa?',
      widget: 'checkbox',
      viewWidget: 'boolean-view',
      rules: [
        {
          required: false,
        },
      ],
      widgetProps: { defaultChecked: true },

    },
  ],
};
const atendimentosInfos = {
  fields: [
    {
      key: 'atendimentos.menoresDe12anos',
      label: 'Menores de 12 anos',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'atendimentos.entre13e17',
      label: 'Entre 13 e 17 anos',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'atendimentos.adultos',
      label: 'Adultos',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'atendimentos.idosoMaiorQue60',
      label: 'Idoso (maior que 60 anos)',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ],
};
export function praiaAcessivel() {
  return [
    { title: 'Informações', value: generalInfos },
    { title: 'Localização', value: localizacaoGlobal },
    { title: 'Atendimentos', value: atendimentosInfos },
  ];
}
