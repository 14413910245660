import React, {createContext, useState} from 'react'

interface ICampos {
  obrigatorio: boolean,
  usado: boolean,
  editavel: boolean,
  index: number,
  title: string
}

interface IRegistrosValueObras {
  datas: {
    dataFimPrevisto: string,
    dataInicio: string,
    dataOrdemServico: string
  },
  descricao: string,
  docs: {
    documento: string,
    imagem: string
  },
  localizacao: {
    enderecoCompleto: string,
    estacao: string,
    latitude: number,
    longitude: number,
    municipio: string,
    pontoDeReferencia: string,
    regiaoPlanejamento: string
  },
  ultimoEditor: {
    nome: string,
    telefone: string
  },
  nome: string,
  observacao: string,
  recursos: {
    mudasFrutiferas: number,
    mudasNativas: number,
    totalInvestido: number
  },
  status: string,
  valorAditivo: number
}

export interface IRegistroAcoes {
  nome: string,
  endereco: {
    bairro: string,
    cep: string,
    estacao: string,
    latitude: number,
    longitude: number,
    municipio: string,
    numero: string,
    pontoDeReferencia: string,
    rua: string
  },
  datas: {
    dataFim: string,
    dataInicio: string
  },
  ultimoEditor: {
    nome: string,
    telefone: string
  },
  valor: number,
  valorAditivo: number,
  arteNaPraca?: {
    edicao: number,
    numeroOficinasRealizadas: number
  },
  bandaMusicaEscola?: {
    qtdCriancasFormadas: number,
    qtdInstrumentosDoados: number
  },
  capacitacaoAgentesComunitariosSaude?: {
    numeroCapacitacoes: number
  },
  documentario?: {
    numeroDvdsEntregues: number,
    numeroExibicoes: number
  },
  lancamentoLivroRelatorio?: {
    nome: string
  },
  nucleoEstimulacaoPrecoce?: {
    criancasAtendidas?: {
      porEdicaoCriancas?: {
        edicao: number,
        numeroAtendimentos: number,
        numeroCriancasAtendidas: number,
        periodo?: {
          dataFim: string,
          dataInicio: string
        }
      }[],
      porEvento?: number
    }
  },
 
  praiaAcessivel?: {
    data: string,
    estacao: string,
    pessoasPorFaixaEtariaAcao: {
      edicao: number,
      idade: {
        adultos: number,
        entre12e19: number,
        idosoMaiorQue60: number,
        menoresDe6: number
      },
      periodo: {
        dataFim: string,
        dataInicio: string
      }
    }[]
  },
  prevencaoEmFamilia?: {
    numeroCapacitacoesProfissionais: number
  },
  sementesDoAmanha?: {
    numeroCapacitacoes: number
  },
  seminarioMaisInfancia?: {
    numeroPalestras: number,
    numeroParticipantes: number,
    programacao: string
  }
}

interface IRegistroObras {
    valueRegistroObras: IRegistrosValueObras;
    setValueRegistroObras(state: IRegistrosValueObras): void;

    valueRegistroAcoes: IRegistroAcoes;
    setvalueRegistroAcoes(state: any | null): void;

    atividades: Array<ICampos>
    pessoas: Array<ICampos>
    recursos: Array<ICampos>
    setAtividade(state: ICampos[]): void;
    setPessoas(state: ICampos[]): void;
    setRecursos(state: ICampos[]): void;
}

export const RegistroObras = createContext<IRegistroObras>({
    valueRegistroObras: {
      datas: {
        dataFimPrevisto: '',
        dataInicio: '',
        dataOrdemServico: ''
      },
      descricao: '',
      docs: {
        documento: '',
        imagem: ''
      },
      localizacao: {
        enderecoCompleto: '',
        estacao: '',
        latitude: 0,
        longitude: 0,
        municipio: '',
        pontoDeReferencia: '',
        regiaoPlanejamento: ''
      },
      nome: '',
      observacao: '',
      recursos: {
        mudasFrutiferas: 0,
        mudasNativas: 0,
        totalInvestido: 0
      },
      status: '',
      valorAditivo: 0,
      ultimoEditor: {
        nome: '',
        telefone: ''
      },
    },
    setValueRegistroObras(){},
    valueRegistroAcoes: {
      nome: '',
      endereco: {
        bairro: '',
        cep: '',
        estacao: '',
        latitude: 0,
        longitude: 0,
        municipio: '',
        numero: '',
        pontoDeReferencia: '',
        rua: ''
      },
      datas: {
        dataFim: '',
        dataInicio: ''
      },
      ultimoEditor: {
        nome: '',
        telefone: ''
      },
      valor: 0,
      valorAditivo: 0,
    },
    setvalueRegistroAcoes(){},
    atividades: [],
    pessoas: [],
    recursos: [],
    setAtividade(){},
    setPessoas(){},
    setRecursos(){},

})

export const ProviderRegistro = ({children}: {children: React.ReactNode}): any => {
    const [atividades, setAtividade] = useState<ICampos[]>([]) // campos das atividades obrogatorios
    const [pessoas, setPessoas] = useState<ICampos[]>([]) // campos do mapeamento pessoas obrigatórios
    const [recursos, setRecursos] = useState<ICampos[]>([]) // campos do mapeamento recursos obrigatórios

    const [valueRegistroObras, setValueRegistroObras] = useState<IRegistrosValueObras>(
    {
      datas: {
        dataFimPrevisto: '',
        dataInicio: '',
        dataOrdemServico: ''
      },
      descricao: '',
      docs: {
        documento: '',
        imagem: ''
      },
      localizacao: {
        enderecoCompleto: '',
        estacao: '',
        latitude: 0,
        longitude: 0,
        municipio: '',
        pontoDeReferencia: '',
        regiaoPlanejamento: ''
      },
      nome: '',
      observacao: '',
      recursos: {
        mudasFrutiferas: 0,
        mudasNativas: 0,
        totalInvestido: 0
      },
      status: '',
      valorAditivo: 0,
      ultimoEditor: {
        nome: '',
        telefone: ''
      },
    }
  );

  const [valueRegistroAcoes, setvalueRegistroAcoes] = useState<any>(null);

    const contextValues: IRegistroObras = {
        valueRegistroObras,
        setValueRegistroObras,
        valueRegistroAcoes,
        setvalueRegistroAcoes,
        atividades,
        pessoas,
        recursos,
        setAtividade,
        setPessoas,
        setRecursos
    }

    return(
        <RegistroObras.Provider value={contextValues}>
            {children}
        </RegistroObras.Provider>
    )
}