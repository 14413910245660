import { onlyNumbers } from './general';

const LENGTH = 8;

function isValidLength(cep: string) {
  return cep.length === LENGTH;
}

export function isValidCEP(cep: string): boolean {
  if (!cep || typeof cep !== 'string') return false;

  const digits = onlyNumbers(cep);

  return isValidLength(digits);
}
