import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import './styles.scss';

const antIcon = <LoadingOutlined spin />;

export function LoaderInfoAction() {

  return (
    <Spin 
      className="spin-loading-action" 
      size="large" 
      tip="Buscando as informações da ação..."
      indicator={antIcon}  
    /> 
  );
}