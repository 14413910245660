import React, { Factory, useEffect, useState } from 'react';
import { Authorizer, Feedback, Footer, GoBack } from 'components';
import { Link, useHistory, useLocation} from 'react-router-dom';
import { Table, Spin, message, Pagination  } from 'antd';
import { agentService } from 'services';
import { FamilyVisitsPerAgent,  } from 'types'; 
import { Breadcrumb } from 'components/Articulator/Breadcrumb'; 



export function ArticulatorFamiliesVisits() {
  const history = useHistory();
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;
  const [familiesVisits, setFamiliesVisits] = useState<FamilyVisitsPerAgent[]>([]); 
  const [familiesVisitsLoading, setFamiliesVisitsLoading] = useState(false);


  const breadcrumbItems = [
    {
      id: 1,
      to: "/articulador-inicio",
      title: "Página inicial"
    },
    {
      id: 2,
      to: "/articulador-familias-visitadas",
      title: "Famílias Visitadas"
    }
  ];

  function handleGoBack(){
    return history.push('/articulador-inicio');
  }

  async function handleGetAgent() {
    const response = await agentService.getProfessional(userId);
    return response;
  }

  const handleDataFamiliesPerAgent = async () => {
    const agentCity= (await handleGetAgent()).city;
    const response = await agentService.getFamiliesVisits(agentCity);
    return response;
  };

  async function getDataFamilies() {
    setFamiliesVisitsLoading(true);

    await handleDataFamiliesPerAgent()
      .then(res => setFamiliesVisits(res))
      .catch(error => console.error(error))
    
    setFamiliesVisitsLoading(false);
  }


  useEffect(() => {
    try{
      getDataFamilies();
    }catch(err){
      message.error('Erro a carregar famílias visitas do agente');
    }
  }, []);


  const columns = [
    {
      title: 'NIS',
      dataIndex: 'nis',
      key: "nis"
    },
    {
      title: 'Nome da Responsável Familiar',
      dataIndex: 'nameRF',
      key: 'nameRF'
    },
    {
      title: 'Bairro',
      dataIndex: 'neighborhood',
      key: "neighborhood"
    },
    {
      title: 'Logradouro',
      dataIndex: 'addressStreet',
      key: "addressStreet"
    },
    {
      title: 'Número',
      dataIndex: 'addressNumber',
      key: "addressNumber"
    }
  ];

  return (
    <>

    <div style={{padding: 20, marginLeft: 20}}>
        <Breadcrumb 
          items={breadcrumbItems}
        />
    </div>
    <div style={{padding: 20}}>
      <GoBack 
          onClick={handleGoBack}
          title="Famílias Visitadas Ativas"
          subtitle="Lista de Famílias Visitadas Ativas"
      />
    </div>
    <div style={{display: 'flex'}}>
      <div style={{background: '#9bc75f', width: '300px', height: '100px', margin: '10px', borderRadius: '10px'}}>
            <p style={{fontWeight : 'bold', textAlign : 'center', marginTop: '20px', marginBottom:'0px'}}> VISITADAS </p>
            <p style={{fontWeight : 'bold', textAlign : 'center', fontSize: '20px'}}>{familiesVisits.length ? familiesVisits.length : ''}</p>
      </div>
    </div>
    <div style={{marginBottom: 100, marginLeft: 20, marginRight: 20}}>
      {!familiesVisits ? ("Sem famílias Encontradas/Visitadas") : (
          <Table dataSource={familiesVisits} columns={columns}
          loading={familiesVisitsLoading}/>
        )
      }
    </div>
    
    <Footer />

    </>
  )
}
