import { socialApi } from "services/api";
import { Professional, Children, RichedProfessional, Agent, FamilyVisitsPerAgent } from "types";
import {
  RichAgent,
  PoorPersonalAgent,
  PoorCommunicationAgent,
} from "iris-social/service/articulator";

const service = () => {
  const resource = `/agents`;

  async function searchAgents(name: string, city: string | undefined) {
    try {
      const response = await socialApi.get<Agent[]>(`${resource}`, {
        params: {
          name,
          city
        }
      })

      return response.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async function getAgent(cpf: string) {
    try {
      const response = await socialApi.get<RichAgent>(`${resource}/${cpf}/citizen`);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function postAgents(data: PoorPersonalAgent) {
    try {
      const response = await socialApi.post(`${resource}/`, data);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function patchAgent(data: PoorCommunicationAgent) {
    try {
      const response = await socialApi.patch(`${resource}/me`, data);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getProfessional(cpf: string) {
    try {
      const response = await socialApi.get<RichedProfessional>(`${resource}/${cpf}`);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function postProfessional(data: Professional) {
    try {
      const response = await socialApi.post(`${resource}/`, data)

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function patchChildren(data: Children) {
    try {
      const response = await socialApi.patch(`${resource}/me`, data);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getFamiliesVisits(city : string) {
    try {
      const response = await socialApi.get<FamilyVisitsPerAgent[]>(`${resource}/${city}/visits`);
      return response.data;
    }
    catch (err) {
      return Promise.reject(err);
    }
  }

  async function getFamiliesNotVisiteds(city : string) {
    try {
      const response = await socialApi.get<FamilyVisitsPerAgent[]>(`${resource}/${city}/families`);
      return response.data;
    }
    catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    searchAgents,
    getAgent,
    postAgents,
    patchAgent,
    getProfessional,
    postProfessional,
    patchChildren,
    getFamiliesVisits,
    getFamiliesNotVisiteds
  }

}

export const agentService = service();