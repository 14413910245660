import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Card } from 'antd';
import { FormArticulator, Loading } from 'components';
import { AddressForm } from 'components/FormArticulator/UserAddressForm';
import { FormInstance } from 'antd/es/form/Form';

import { MaritalStatus, UF, City } from 'types';
import { localService, utilsCitizenService } from 'services';

import {
  Personal,
  Professional,
  Address,
  Family,
  ArticulatorValues,
} from './helper';

interface FormProps {
  step: number;
  articulator: boolean;
  data: ArticulatorValues | null;
  personalForm: FormInstance<Personal>;
  professionalForm: FormInstance<Professional>;
  addressForm: FormInstance<Address>;
  familyForm: FormInstance<Family>;
  loading?: boolean;
  onCpf?: (cpf: string) => void;
  onLoaded?: () => void;
}

export function Form({
  step,
  articulator,
  data,
  personalForm,
  professionalForm,
  addressForm,
  familyForm,
  loading = false,
  onCpf,
  onLoaded,
}: FormProps) {
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus[]>([]);
  const [maritalLoading, setMaritalLoading] = useState(false);

  const [states, setStates] = useState<UF[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    setMaritalLoading(true);
    utilsCitizenService
      .getMaritalStatus()
      .then(data => setMaritalStatus(data))
      .finally(() => setMaritalLoading(false));
  }, []);

  useEffect(() => {
    setLocalLoading(true);
    const statePromise = localService.getBrazilStates();
    const cityPromise = localService.getCities();

    Promise.all([statePromise, cityPromise])
      .then(([s, c]) => {
        setStates(s);
        setCities(c);
      })
      .finally(() => setLocalLoading(false));
  }, []);

  const handleLoaded = useCallback(() => onLoaded && onLoaded(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (maritalStatus.length > 0) {
      handleLoaded();
    }
  }, [maritalStatus, handleLoaded]);

  const confirmData = useMemo(() => {
    const stateId = data?.address?.addressStateId ?? '';
    const cityId = data?.address?.addressCityId ?? '';

    return {
      ...data,
      address: {
        ...data?.address,
        addressStateId: states.find(state => state.id === stateId)?.name ?? '',
        addressCityId: cities.find(city => city.id === cityId)?.name ?? '',
      },
    } as ArticulatorValues;
  }, [data, states, cities]);

  const Form1 = () => {
    if (step === 0) {
      return (
        <>
          <FormArticulator
            loading={maritalLoading}
            maritalStatus={maritalStatus}
            form={personalForm}
            onCpfChange={onCpf}
          />
          <FormArticulator.ProfessionalForm
            form={professionalForm}
            cities={cities}
            loading={loading}
          />
        </>
      );
    }

    return null;
  };

  const Form2 = () => {
    if (articulator && step === 1) {
      return (
        <>
          <AddressForm
            form={addressForm}
            states={states}
            cities={cities}
            loading={localLoading}
          />
          <FormArticulator.FamilyForm form={familyForm} />
        </>
      );
    }

    return null;
  };

  const ConfirmForm = () => {
    if ((articulator && step === 2) || (!articulator && step === 1)) {
      return (
        <FormArticulator.ConfirmForm
          data={confirmData}
          articulator={articulator}
        />
      );
    }

    return null;
  };

  const renderForm = () => {
    if (loading || localLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Form1 />
        <Form2 />
        <ConfirmForm />
      </div>
    );
  };

  return <Card>{renderForm()}</Card>;
}
