import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 800px;

  .ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-label {
    font-weight: bold;
  }
`;
