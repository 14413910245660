import React, { useEffect, useState, useMemo } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  FormInstance,
  notification,
} from 'antd';
import cep from 'cep-promise';

import { UF, City } from '../../../types';
import { FormBox, InputCEP } from '../..';
import rules from './rules';

interface AddressFormProps {
  form?: FormInstance;
  cities: City[];
  states: UF[];
  loading?: boolean;
}

const { Item } = Form;
const { Option } = Select;

export function AddressForm({
  form,
  states,
  cities,
  loading = false,
}: AddressFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const [selectedUf, setSelectedUf] = useState<number>(-1);

  useEffect(() => {
    setSelectedUf(currentForm.getFieldValue('addressStateId'));
  }, [currentForm]);

  const filteredCities = useMemo(() => {
    if (selectedUf === -1) return [];

    return cities.filter(city => city.stateId === selectedUf);
  }, [states, selectedUf]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleCepChange(cepValue: string) {
    if (cepValue.length >= 8) {
      cep(cepValue).then((data: any) => {
        if (data.state !== 'CE') {
          return notification.error({
            message: 'CEP não pertence ao estado do Ceará',
            placement: 'bottomRight',
          });
        }

        const stateId = states.find(state => state.uf === data.state)?.id;
        const cityId = cities.find(city => city.name === data.city)?.id;

        setSelectedUf(stateId ?? -1);
        currentForm.setFieldsValue({
          zipCode: data.cep,
          address: data.street,
          district: data.neighborhood,
          addressStateId: stateId,
          addressCityId: cityId,
        });
      });
    }

    if (cepValue.length === 0) {
      currentForm.resetFields();
    }
  }

  function handleUfChange(value: number) {
    setSelectedUf(value);
  }

  return (
    <FormBox title="Residência" form={currentForm}>
      <Item
        label="CEP"
        name="zipCode"
        wrapperCol={{ xxl: 6, xl: 6, lg: 6, md: 24, xs: 24 }}
        rules={rules.zipCode}
      >
        <InputCEP onChange={handleCepChange} />
      </Item>

      <Row>
        <Col xxl={17} xl={17} lg={17} md={24} xs={24}>
          <Item label="Endereço" name="address" rules={rules.address}>
            <Input />
          </Item>
        </Col>

        <Col
          xxl={{ span: 6, offset: 1 }}
          xl={{ span: 6, offset: 1 }}
          lg={{ span: 6, offset: 1 }}
          md={24}
          xs={24}
        >
          <Item label="Número" name="addressNumber" rules={rules.addressNumber}>
            <Input />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item label="Complemento" name="addressComplement">
            <Input />
          </Item>
        </Col>

        <Col
          xxl={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
          md={24}
          xs={24}
        >
          <Item label="Bairro" name="district" rules={rules.district}>
            <Input />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item label="UF" name="addressStateId" rules={rules.addressState}>
            <Select loading={loading} onChange={handleUfChange}>
              {states.map(uf => (
                <Option value={uf.id} key={uf.id}>
                  {uf.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col
          xxl={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
          md={24}
          xs={24}
        >
          <Item
            label="Município"
            name="addressCityId"
            rules={rules.addressCity}
          >
            <Select loading={loading} disabled={selectedUf === -1}>
              {filteredCities.map(city => (
                <Option value={city.id} key={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </FormBox>
  );
}
