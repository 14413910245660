import React, { useContext, useEffect, useState } from 'react';


import { TimelineContext } from 'utils/context/timelineContext';
import QuantitativeTotalInitiatives from 'components/quantitativeTotalInitiatives';
import SliderMarke from './sliderMarke';
import VerticalTimeLine from '../verticalTimeline';
import { Spin } from 'antd';

import './styles.scss';
import FilterTimiline from 'components/FilterTimiline';


type TimelineProps = {
  loading: boolean,
  data: any,
  getData: () => void,
  onDataUpgrad: (value: any) => void
}

const Timeline2 = ({loading, data, getData, onDataUpgrad}: TimelineProps): JSX.Element => {
  const { currentTick, currentChave, dataRange, citiesSelecteds, regionSelected } = useContext(TimelineContext);

  const [dataResumeTimeline, setDataResumeTimeline] = useState([]);

  const [initiativesVeryMonth, setInitiativesVeryMonth] = useState<any>([]);

  const [frameStart, setFrameStart] = useState(0);
  
  let listMeses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const namesActionsWithCiclo =  
    [
      "Padin - Formação", 
      "Formação Seduc"
    ]

  useEffect(() => {
    getData();
    return () => {
      onDataUpgrad({}); // This worked for me
    };
  }, [dataRange]); // eslint-disable-line react-hooks/exhaustive-deps

  function resumeTimelineApi(){
    if(Array.isArray(data)){
      const newResumeTimeline: any = data.map((yearInfo)=> {
        let listResume: any[] = [];
       
        yearInfo.meses.forEach(iniciativa => {
          iniciativa.iniciativasRes?.forEach((iniciativaInterna:any) => {
            const newIniciativa = {...iniciativaInterna}
            listResume.push(newIniciativa)
          })
        })
        
        const newListResume = listResume.reduce((acc: any, cur) => {
          const isRepeted = acc?.some(valor => valor.idIniciativa === cur.idIniciativa);
          if(isRepeted) {
            return [...acc]
          }

          const matchedIndex = acc?.findIndex(valor =>  valor.nome === cur.nome);
          
          if(namesActionsWithCiclo.includes(cur.nome)){
          
            if (matchedIndex >= 0) {
              acc[matchedIndex].qtd =  acc[matchedIndex].qtd + 1;
              return acc;
            }
            else return [...acc,  {...cur, qtd: 1}]
          }else {
            if (matchedIndex >= 0) {
              acc[matchedIndex].qtd = cur.qtd + acc[matchedIndex].qtd;
              return acc;
            }
            else return [...acc, cur]
          }

        }, []);  
        
        return {id: yearInfo.ano, resumo: newListResume}
      });
   
      setDataResumeTimeline(newResumeTimeline);
    } 
  }

  function transformData(){
    if (data.length > 0) {
      // novo array com todos os meses do ano, não só os que possuem iniciativas
      const newIniciativesArray: any = data.map(year => {
        let newListAllMonth = [
          { mes: 1, iniciativasRes: [] },
          { mes: 2, iniciativasRes: [] },
          { mes: 3, iniciativasRes: [] },
          { mes: 4, iniciativasRes: [] },
          { mes: 5, iniciativasRes: [] },
          { mes: 6, iniciativasRes: [] },
          { mes: 7, iniciativasRes: [] },
          { mes: 8, iniciativasRes: [] },
          { mes: 9, iniciativasRes: [] },
          { mes: 10, iniciativasRes: [] },
          { mes: 11, iniciativasRes: [] },
          { mes: 12, iniciativasRes: [] },
        ];

        listMeses.forEach(function (initiative) {
          year.meses.forEach(mes => {
            if (initiative === mes.mes) {
              newListAllMonth.splice(mes.mes - 1, 1, mes);
            }
          });
        });
        if (year.ano === 0) return { ano: 2015, meses: newListAllMonth };
        return { ano: year.ano, meses: newListAllMonth };
      });

      const start = newIniciativesArray[0].meses.findIndex(element => {
        return element.iniciativasRes.length !== 0;
      });

      setFrameStart(start);
      setInitiativesVeryMonth(newIniciativesArray);
    }
  }

  useEffect(() => {
    transformData();

    resumeTimelineApi();

  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Spin spinning={loading} size="large" tip="Carregando" style={{color: "#80c470"}} >
    <div className="container__timeline">
      <FilterTimiline />
      <SliderMarke data={initiativesVeryMonth} frameStart={frameStart} />

      <div className="container__timeline-frames">
        {initiativesVeryMonth?.map((ano, index) => {
          const currentMonths = ano?.meses;
            return (
              <>
                { 
                currentMonths.map((iniciativa: any, i) => {
                
                  if (`${index*7}${i*5}` === currentChave) {

                    return (
                      <React.Fragment key={`key-child-${index}${i}`}>
                        <VerticalTimeLine
                          currentFrame={iniciativa}
                          posicion={currentTick}
                          ano={ano.ano}
                        />
                        <QuantitativeTotalInitiatives
                          currentFrame={dataResumeTimeline[index]}
                          ano={ano.ano}
                          citiesSelecteds={citiesSelecteds}
                          regionSelected={regionSelected}
                        />
                      </React.Fragment>
                    );
                  }

                  return <React.Fragment  key={`key-child-${index}${i}`}/>

                })
                }
              </>
            )
    
        })}
      </div>
    </div>
    </Spin>
  );
};

export default Timeline2;
