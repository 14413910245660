import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { formatXLSXseduc } from './formatXLSXseduc';
import { generalArrayFormatXLSX } from './formatXLSXgeral';
import { formatXLSXpadin } from './formatXLSXpadin';
import { formatXLSXmaisNutricao } from './formatXLSXmaisNutricao';
import { formatXLSXcriancaFeliz } from './formatXLSXcriancaFeliz'
import { formatXLSXpraia } from './formatXLSXpraia';

function transformBuffer(binary) {
  var buf = new ArrayBuffer(binary.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < binary.length; i++) view[i] = binary.charCodeAt(i) & 0xff;
  return buf;
}

function removeDocumentario(data){
  const newData = data.map(doc => {
    delete doc.documentario.documentario
    return doc;
  });

  return newData;
}

export function generateXLSX(data, type, name) {

  const workbook = XLSX.utils.book_new(); 

  
  let newWorkbook;
  if(name === 'Documentários') data = removeDocumentario(data)

  if(name === 'Formação Seduc') newWorkbook = formatXLSXseduc(data, workbook)
  else if(name === 'Padin') newWorkbook = formatXLSXpadin(data, workbook)
  else if(name === 'Praia Acessível') newWorkbook = formatXLSXpraia(data, workbook)
  else if(name === 'Mais Nutrição') newWorkbook = formatXLSXmaisNutricao(data, workbook)
  else if(name === 'Criança Feliz') newWorkbook = formatXLSXcriancaFeliz(data, workbook)
  else newWorkbook = generalArrayFormatXLSX(data, workbook);
  

  let arquivoEscrito = XLSX.write(newWorkbook, {
    bookType: 'xlsx',
    type: 'binary',
  });

  saveAs(
    new Blob([transformBuffer(arquivoEscrito)], {
      type: 'application/octet-stream',
    }),
    `${name}.xlsx`,
  );
}
