import { socialApi } from 'services/api';
import { FamilyVisit, MarkedVisitationPerFamily, Visit } from 'types';

const service = () => {
  const resource = '/visits';

  async function getAllVisitationsDataExisting(workplanId: number) {
    try {
      const response = await socialApi.get<MarkedVisitationPerFamily[]>(`/workplans/${workplanId}${resource}`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }
  
  async function getVisitsByFamilyRepresenting(cpf: string | undefined) {
    try {
      const response = await socialApi.get(`${resource}/${cpf}`);

      const visits: FamilyVisit[] = response.data.content
      
      return visits;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getVisitationFamiliesByStatusAndNeighborhood(workplanId: number, neighborhood: string, status: string) {
    let finalResponse: MarkedVisitationPerFamily[] = [];
    
    try {
      console.log(neighborhood, status)
      if(neighborhood==='0' && status==='0') {
        const response = await socialApi.get<MarkedVisitationPerFamily[]>(`/workplans/${workplanId}${resource}`);

        finalResponse = response.data;
      }
      else if(neighborhood!=='0' && status!=='0') {
        const response = await socialApi.get<MarkedVisitationPerFamily[]>(`/workplans/${workplanId}${resource}`, {
          params: {
            neighborhood,
            status
          },
        })

        finalResponse = response.data;
      } else if(neighborhood==='0' && status!=='0') {
        const response = await socialApi.get<MarkedVisitationPerFamily[]>(`/workplans/${workplanId}${resource}`, {
          params: {
            status
          },
        })

        finalResponse = response.data;
      } else if(neighborhood!=='0' && status==='0') {
        const response = await socialApi.get<MarkedVisitationPerFamily[]>(`/workplans/${workplanId}${resource}`, {
          params: {
            neighborhood
          },
        })

        finalResponse = response.data;
      }
      return finalResponse;
    } catch(err) {
      return Promise.reject(err);
    } 
  }

  async function createVisit(data: Visit) {
    try {
      await socialApi.post(`${resource}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return true;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  return {
    getAllVisitationsDataExisting,
    getVisitsByFamilyRepresenting,
    getVisitationFamiliesByStatusAndNeighborhood,
    createVisit,
  }
}

export const visitService = service();