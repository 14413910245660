import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Container, StepsContainer } from '../NewRegisterObra/styles';
import { Steps, Form as AntForm } from 'antd';
import {
  Breadcrumb,
  FormObrasEdit,
  FormObrasConfirm,
  Form,
  ConfirmFormValues,
} from '../../components';
import { getEventObra, attEventObra } from 'services/resources/obrasServices';
import { RegistroObras } from 'utils/context/registroObrasContext';
import {
  InfoBasicValues,
  CurrentSituation,
  infoResponsavelValues,
  FormDataObras,
} from '../../utils/types';
import { Link } from 'react-router-dom';

const { Step } = Steps;

interface IEditarObra {
  match: {
    params: {
      idObra: string;
      idEvento: string;
    };
  };
}

interface IObras {
  datas: {
    dataFimPrevisto: string;
    dataInicio: string;
    dataOrdemServico: string;
  };
  descricao: string;
  docs: {
    documento: string;
    imagem: string;
  };
  localizacao: {
    enderecoCompleto: string;
    estacao: string;
    latitude: number;
    longitude: number;
    municipio: string;
    pontoDeReferencia: string;
    regiaoPlanejamento: string;
  };
  ultimoEditor: {
    nome: string;
    telefone: string;
  };
  nome: string;
  observacao: string;
  recursos: {
    mudasFrutiferas: number;
    mudasNativas: number;
    totalInvestido: number;
  };
  status: string;
  valorAditivo: number;
}

const { useForm } = AntForm;

const EditarObra = ({ match }: IEditarObra): JSX.Element => {
  const [basicInfoForm] = useForm<InfoBasicValues>();
  const [curentSituationForm] = useForm<CurrentSituation>();
  const [responsavelForm] = useForm<infoResponsavelValues>();
  const [formData, setFormData] = useState<FormDataObras | null>(null);
  const idObra = match.params.idObra;
  const idEvento = match.params.idEvento;
  const [dataObraEvent, setDataObraEvent] = useState<IObras>();

  const { setValueRegistroObras, valueRegistroObras } =
    useContext(RegistroObras);

  function SaveInfo() {
    valueRegistroObras.descricao = formData?.infobasic?.descricao
      ? formData?.infobasic?.descricao
      : '';
    valueRegistroObras.nome = formData?.infobasic?.nome
      ? formData?.infobasic?.nome
      : '';
    valueRegistroObras.docs.imagem = formData?.infobasic?.imagem
      ? formData?.infobasic?.imagem
      : '';
    valueRegistroObras.localizacao.enderecoCompleto = formData?.infobasic
      ?.enderecoCompleto
      ? formData?.infobasic?.enderecoCompleto
      : '';
    valueRegistroObras.localizacao.municipio = formData?.infobasic?.municipio
      ? formData?.infobasic?.municipio
      : '';
    valueRegistroObras.localizacao.pontoDeReferencia = formData?.infobasic
      ?.pontoDeReferencia
      ? formData?.infobasic?.pontoDeReferencia
      : '';
    valueRegistroObras.valorAditivo = Number(
      formData?.currentS?.aditivo
        .replace(/\D/g, '')
        .replace(/(\d)(\d{1,2})$/, '$1.$2'),
    );
    valueRegistroObras.recursos.totalInvestido = Number(
      formData?.currentS?.investido
        .replace(/\D/g, '')
        .replace(/(\d)(\d{1,2})$/, '$1.$2'),
    );
    valueRegistroObras.datas.dataFimPrevisto =
      formData?.currentS?.dataFimPrevisto;
    valueRegistroObras.datas.dataInicio = formData?.currentS?.dataInicio;
    valueRegistroObras.datas.dataOrdemServico =
      formData?.currentS?.dataOrdemServico;
    valueRegistroObras.status = formData?.currentS?.statusObra
      ? formData?.currentS?.statusObra
      : '';
    valueRegistroObras.observacao = formData?.currentS?.observacao
      ? formData?.currentS?.observacao
      : '';
    valueRegistroObras.ultimoEditor.nome = formData?.responsavel?.nome
      ? formData?.responsavel?.nome
      : '';
    valueRegistroObras.ultimoEditor.telefone = formData?.responsavel?.telefone
      ? formData?.responsavel?.telefone
      : '';
    setValueRegistroObras(valueRegistroObras);
    attEventObra(idObra, idEvento, valueRegistroObras);

    // setConcluir(true);
  }

  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/home-servidor',
      title: 'Lista de Obras e Ações',
    },
    {
      path: `/editar-obra/${idObra}/${idEvento}`,
      title: `Editar registro de `,
    },
  ];

  useEffect(() => {
    const response = async () => {
      const data = await getEventObra(idObra, idEvento);
      setDataObraEvent(data);
    };
    response();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataObraEvent) {
      basicInfoForm.setFieldsValue({
        nome: dataObraEvent.nome,
        enderecoCompleto: dataObraEvent.localizacao.enderecoCompleto,
        municipio: dataObraEvent.localizacao.municipio,
        pontoDeReferencia: dataObraEvent.localizacao.pontoDeReferencia,
        imagem: dataObraEvent.docs.imagem,
        descricao: dataObraEvent.descricao,
      });

      curentSituationForm.setFieldsValue({
        statusObra: dataObraEvent.status,
        observacao: dataObraEvent.observacao,
        investido: String(dataObraEvent.recursos.totalInvestido),
        aditivo: String(dataObraEvent.valorAditivo),
        dataFimPrevisto: moment(
          dataObraEvent?.datas?.dataFimPrevisto,
          'DD-MM-YYYY',
        ),
        dataInicio: moment(dataObraEvent?.datas?.dataInicio, 'DD-MM-YYYY'),
        dataOrdemServico: moment(
          dataObraEvent?.datas?.dataOrdemServico,
          'DD-MM-YYYY',
        ),
      });
    }
  }, [dataObraEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: 'Informações Básicas',
      content: (
        <>
          <FormObrasEdit form={basicInfoForm} />
          <Form.FormInfoResponsavel form={responsavelForm} />
        </>
      ),
    },
    {
      title: 'Situação Atual',
      content: (
        <FormObrasEdit.CurrentSituationForm form={curentSituationForm} />
      ),
    },
    {
      title: 'Confirmação',
      content: (
        <>
          <FormObrasConfirm form={basicInfoForm} />
          <FormObrasConfirm.CurrentSituationForm form={curentSituationForm} />
          <ConfirmFormValues.InfoFormConfirm form={responsavelForm} />
        </>
      ),
    },
  ];

  function defineValuesSecond() {
    setFormData(d => ({
      ...(d ?? {}),
      ...formData,
      currentS: curentSituationForm.getFieldsValue(),
    }));
  }

  const secondStep = () => {
    const curentSituationFormValidate = curentSituationForm.validateFields;

    Promise.all([curentSituationFormValidate()])
      .then(() => defineValuesSecond())
      .then(() => setCurrent(current + 1));
  };

  function defineValuesFist() {
    setFormData(d => ({
      ...(d ?? {}),
      infobasic: basicInfoForm.getFieldsValue(),
      responsavel: responsavelForm.getFieldsValue(),
    }));
  }

  const firstStep = () => {
    const infoBasicValidate = basicInfoForm.validateFields;
    const constResponsavelValidate = responsavelForm.validateFields;

    Promise.all([infoBasicValidate(), constResponsavelValidate()])
      .then(() => defineValuesFist())
      .then(() => setCurrent(current + 1));
  };

  const next = valor => {
    if (current === 0) {
      firstStep();
    } else if (current === 1) {
      secondStep();
    }
  };

  // const [_, setConcluir] = useState(false);

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Container>
        <Breadcrumb links={links} />
        {/* <TitlePage
          title={`Editar registro de ${infosCurrentIniciativa.name}`}
          backButton
        /> */}

        <StepsContainer>
          <div className="steps-header">
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          <div className="form-content">{steps[current].content}</div>
          <div className="buttons-form">
            {current > 0 && (
              <span
                className="button-form left"
                style={{ margin: '0 8px' }}
                onClick={() => prev()}
              >
                VOLTAR
              </span>
            )}
            {current === 0 && (
              <Link to={'/home-servidor'}>
                <span className="button-form cancel">CANCELAR</span>
              </Link>
            )}
            {current < steps.length - 1 && (
              <>
                <span
                  className="button-form right"
                  onClick={() => next(current)}
                >
                  AVANÇAR
                </span>
              </>
            )}
            {current === steps.length - 1 && (
              <span className="button-form right" onClick={() => SaveInfo()}>
                CONCLUIR
              </span>
            )}
          </div>
          {/* {concluir && (
            <ModalConfirma
              mensagem={`${dataObraEvent?.nome} editada com sucesso!`}
            />
          )} */}
        </StepsContainer>
      </Container>
    </>
  );
};

export default EditarObra;
