import React from 'react';
import moment from 'moment';
import { Menu } from 'antd';

import { Activity } from 'types';

import { Container, ActivityMoreInfo, Vertical } from './styles';

type ActivityMobileListProps = {
  activities: Activity[];
  currentSelectedDate: string;
}

const { SubMenu } = Menu;

export function ActivityMobileList({ activities, currentSelectedDate }: ActivityMobileListProps) {
  return (
    <Container>
      {activities.length > 0 && (
        <>
        {/* <strong style={{ textAlign:'center' }}>Outras listagens</strong> */}
        <Menu
          mode="inline"
        >
          {activities.map((activity) => (
            moment(activity.start).format('DD/MM/YYYY')===currentSelectedDate && (
              <SubMenu key={activity.id} title={activity.type.name}>
                <Menu.ItemGroup className="activity-item-group">
                  <ActivityMoreInfo>
                    <Vertical>
                      <strong>Data</strong>
                      <span>{moment(activity.start).format('DD/MM/YYYY')}</span>
                    </Vertical>
                    <Vertical>
                      <strong>Horário</strong>
                      <span>
                        {moment(activity.start).format('HH:mm')} &nbsp; até &nbsp;
                        {moment(activity.end).format('HH:mm')}
                      </span>
                    </Vertical>
                    <Vertical>
                      <strong>Status</strong>
                      {activity.checked ? (
                        <span>Já realizado</span>
                      ) : (
                        <span>Não realizado</span>
                      )}
                    </Vertical>
                    <Vertical>
                      <strong>Observação</strong>
                      <span>{activity.observation}</span>
                    </Vertical>
                  </ActivityMoreInfo>
                </Menu.ItemGroup>
              </SubMenu>
            
            )
          ))}
        </Menu>
        </>
      )}
    </Container>
  );
}