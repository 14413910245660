import React, { useState } from 'react';

import { AutoComplete, Input, Select } from 'antd';
import { SelectProps } from 'antd/es/select';

import './styles.scss';
import { useHomeServidor } from 'utils/context/homeServidorContext';

type FilterHomeServidorProps = {
  isLoadingObras: boolean,
}

const FilterHomeServidor = ({isLoadingObras}: FilterHomeServidorProps) => {
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);

  const {
    setOrderByOptionFilter,
    setDisplayOptionFilter,
    iniciativasList,
    setIsLoading,
    isLoading,
    setIniciativasListCurrent,
    displayOptionFilter,
    orderByOptionFilter,
  } = useHomeServidor();

  /* Opções de exibição de iniciativas*/
  const DisplayOptions = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Obra', label: 'Somente Equipamentos' },
    { value: 'Ação', label: 'Somente Ações' },
  ];

  /* Opções de ordenação das iniciativas */
  const OrderByOptions = [
    { value: 'recentes', label: 'Mais recentes' },
    { value: 'antigos', label: 'Mais antigos' },
    { value: 'ascAlfabetica', label: 'Ordem alfabética (A-Z)' },
    { value: 'descAlfabetica', label: 'Ordem alfabética (Z-A)' },
  ];

  /* Função para ser chamada ao selecionar opção de exibição */
  const onChangeDisplayIniciativas = (value: string) => {
    setDisplayOptionFilter(value);
  };

  /* Função para ser chamada ao selecionar opção de ordenação */
  const onChangeOrderByIniciativas = (value: string) => {
    setOrderByOptionFilter(value);
  };

  /* Função para ser chamada ao selecionar opção de exibição */
  const onSubmitSearchIniciativas = (value: any) => {
    setIsLoading(true);
    const copyList = [...iniciativasList]; 
    const list = copyList.filter(iniciativa =>
      iniciativa.nome.toLowerCase().includes(value.toLowerCase()),
    );

    setIniciativasListCurrent(list);

    setIsLoading(false);
  };

  /* Isso retorna somente os nomes das iniciativas */
  let iniciativasNamesList = iniciativasList?.map(iniciativa => {
    return iniciativa.nome;
  });

  //Tirando nomes duplicados da lista
  iniciativasNamesList = iniciativasNamesList.filter(
    (name, i) => iniciativasNamesList.indexOf(name) === i,
  );

  /* Formatando nomes e deixando em lista alfabética */
  const iniciativasNamesListASCFormatted = iniciativasNamesList
    ?.sort((a, b) => a?.localeCompare(b))
    .map(name => {
      return {
        value: name,
      };
    });

  /* Responsavel por buscar o que foi digitado */
  const searchResult = (query: string) => {
    return iniciativasNamesListASCFormatted.filter(iniciativaName =>
      iniciativaName.value?.toLowerCase().includes(query.toLowerCase()),
    );
  };

  /* Vai setar nas opções o nomes das iniciativas retornadas e isso vai atualizar o estado*/
  const handleSearch = (value: string) => {
    setOptions(value ? searchResult(value) : []);
  };

  return (
    <div className="container-filter">
      <div className="container-filter-iniciativas">
        <div>
          <div>
            <span>Exibir</span>
            <Select
              maxTagCount="responsive"
              options={DisplayOptions}
              onChange={onChangeDisplayIniciativas}
              value={displayOptionFilter}
              disabled={isLoadingObras || isLoading}
            />
          </div>
          <div>
            <span>Ordenar por</span>
            <Select
              maxTagCount="responsive"
              options={OrderByOptions}
              onChange={onChangeOrderByIniciativas}
              value={orderByOptionFilter}
              disabled={isLoadingObras || isLoading}
            />
          </div>
        </div>
        <div>
          <AutoComplete
            options={options}
            onSearch={handleSearch}
            onSelect={onSubmitSearchIniciativas}
            disabled={isLoadingObras || isLoading}
          >
            <Input.Search
              allowClear
              placeholder="Busque um equip. / ação"
              inputMode="search"
              onSearch={onSubmitSearchIniciativas}
            />
          </AutoComplete>
        </div>
      </div>
    </div>
  );
};

export default FilterHomeServidor;
