import React, { useEffect, useState } from 'react';

import { Breadcrumb, Device, Footer } from '../../components';

import './styles.scss';

import { TitlePage } from 'components/TitlePage';
import { DataBox } from './DataBox';
import { getAtendimentosGestorAcoes, getAtendimentosObras } from 'services/resources/acoesServices';
import { FormatNumberWithSufix, NumberAndSuffixMake } from 'utils/FormatNumberWithSufix';
import { DataInfoBoxWithTitle } from './DataInfoBoxWithTitle';
import { DataInfo } from './DataInfo';
import { getDinheiroInvestido, getFamiliasBeneficiadas, getVisitasRealizadas } from 'services/resources/cmicService';
import { LoaderQuantitativos } from './LoaderQuantitativos';
import { Tooltip } from 'antd';

interface AtendimentosAcoesProps {
  atendimentosPraia: number,
  cestasBasicasPandemia: number,
  estacoesPraiaAcessivel: number,
  municipiosArteNaPraca: number,
  municipiosMaisNutricao: number,
  qtdPessoasBenefeciadasMaisNutricao: number,
  nome: string,
  qtdFormados: number,
  qtdMudasPlantadas: number,
  qtdQuilosDoados: number,
  qtdToneladasDeAlimentosDoados: number,
  qtdVisitasCriancaFeliz: number,
  qtdVisitasPadin: number,
  totalEventos: number,
  qtdeCriancasPadin: number,
  qtdeFamiliasPadin: number,
  qtdeCriancasCriancaFeliz: number,
  qtdeFamiliasCriancaFeliz: number,
  qtdEncontrosComunitarios: number,
  qtdPessoasBenefeciadasMaisNutricaoMN: number,
  qtdEncontrosComunitariosPadin: number,
  _id: string
}

interface AtendimentosObrasProps {
  id: string;
  nome: string;
  emAndamento: number;
  concluidas: number;
  qtdTotalBeneficiados: number;
  investimentoTotal: number;
  totalEventos: number;
}

function QuantitativosGerais() {

  const [atendimentosAcoesDados, setAtendimentosAcoesDados] =
    useState<Array<AtendimentosAcoesProps>>([]);

  const [atendimentosObrasDados, setAtendimentosObrasDados] =
    useState<Array<AtendimentosObrasProps>>([]);

  const [familiasBeneficiadas, setFamiliasBeneficiadas] = useState<Number|undefined|string>(undefined);
  const [dinheiroIvestido, setDinheiroInvestido] = useState<Number|undefined|string>(undefined);
  const [visitasRealizadasAgentes, setVisitasRealizadasAgentes] = useState<Number|undefined|string>(undefined);


  const [loadAcoes, setLoadAcoes] = useState(true);
  const [loadObras, setLoadObras] = useState(true);

  const [errorAcoes, setErrorAcoes] = useState(false);
  const [errorObras, setErrorObras] = useState(false);

  const [numEventosAcoes, setNumEventosAcoes] = useState({
    total: {
      atendimentosPraia: 0,
      cestasBasicasPandemia: 0,
      estacoesPraiaAcessivel: 0,
      municipiosArteNaPraca: 0,
      municipiosMaisNutricao: 0,
      qtdPessoasBenefeciadasMaisNutricao: 0,
      nome: '',
      qtdFormados: 0,
      qtdMudasPlantadas: 0,
      qtdQuilosDoados: 0,
      qtdToneladasDeAlimentosDoados: 0,
      qtdVisitasCriancaFeliz: 0,
      qtdVisitasPadin: 0,
      totalEventos: 0,
      qtdeCriancasPadin: 0,
      qtdeFamiliasPadin: 0,
      qtdeCriancasCriancaFeliz: 0,
      qtdeFamiliasCriancaFeliz: 0,
      qtdEncontrosComunitarios: 0,
      _id: ''

    },
    qtdPessoasCapacitadasAgente: 0,
    qtdVisitasPadin: 0,
    qtdVisitasFamiliasPadinF: 0,
    qtdToneladasDeAlimentosDoadosMN: 0,
    qtdQuilosDoadosMN: 0,
    cestasBasicasPandemiaMN: 0,
    qtdVisitasCriancaFeliz: 0,

    formadosPadin: 0,
    formadosCriancaFeliz: 0,
    formadosNuclioEstPrecoce: 0,
    formadosAgenteComunitario: 0,
    formadosPrevencaoEmFamilia: 0,
    formadosSementesDoAmanha: 0,

    municipiosContempladosMN: 0,
    municipiosVisitadosArte: 0,

    qtdPessoasBenefeciadasMaisNutricaoMN: 0,

    qtdEncontrosComunitariosPadin: 0,


    atendimentosPraia: 0,

    totalFS: 0,
    totalNucleo: 0,
    totalPraia: 0,
    totalArte: 0,
  });

  const [numEventosObras, setNumEventosObras] = useState({
    brinquedopraca: 0,
    brinquedocreche: 0,
    cei: 0,
    pracaMaisInfancia: 0,
    complexoMaisInfancia: 0
  });

  const device = Device.useDevice();

  useEffect(() => {
    let isMounted = true;
    getAtendimentosGestorAcoes().then(data => {
      if (isMounted) setAtendimentosAcoesDados(data)
    }).catch( () => {
      if(isMounted) setErrorAcoes(true)
    });
     getAtendimentosObras().then(data => {
      if (isMounted) setAtendimentosObrasDados(data)
    }).catch( () => {
      if(isMounted) setErrorObras(true)
    });

    getFamiliasBeneficiadas()
      .then((data) => {if(isMounted) setFamiliasBeneficiadas(data ? data[0]?.quantidade : 0)})
      .catch(() => {setFamiliasBeneficiadas('error')})

    getDinheiroInvestido('')
      .then((data) => {if(isMounted) setDinheiroInvestido(data ? data[0]?.investido : 0)})
      .catch(() => {setDinheiroInvestido('error')})

    getVisitasRealizadas()
      .then((data) => {if(isMounted) setVisitasRealizadasAgentes(data ? data[0]?.quantidade : 0)})
      .catch(() => {setVisitasRealizadasAgentes('error')})
  

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    const newAtendimentosAcoesDados = JSON.parse(JSON.stringify(atendimentosAcoesDados))

    if (newAtendimentosAcoesDados.length > 0) {
      let arr = numEventosAcoes.total;

      for (let property in numEventosAcoes.total) {
        arr = newAtendimentosAcoesDados.reduce((acc, total) => {
          acc[`${property}`] += total[`${property}`];
          return acc;
        });
      }

      const qtd = {
        total: arr,
        qtdPessoasCapacitadasAgente: atendimentosAcoesDados.filter(
          value => value.nome === 'Capacitação dos Agentes Comunitários de Saúde',
        )[0]?.qtdVisitasCriancaFeliz ?? 0,
        qtdVisitasPadin: atendimentosAcoesDados.filter(
          value => value.nome === 'Padin',
        )[0]?.qtdVisitasPadin ?? 0,
        qtdVisitasFamiliasPadinF: atendimentosAcoesDados.filter(
          value => value.nome === 'Padin',
        )[0]?.qtdVisitasPadin ?? 0,
        qtdVisitasCriancaFeliz: atendimentosAcoesDados.filter(
          value => value.nome === 'Criança Feliz',
        )[0]?.qtdVisitasCriancaFeliz ?? 0,

        qtdToneladasDeAlimentosDoadosMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.qtdToneladasDeAlimentosDoados ?? 0,
        qtdQuilosDoadosMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.qtdQuilosDoados ?? 0,
        cestasBasicasPandemiaMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.cestasBasicasPandemia ?? 0,
        municipiosContempladosMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.municipiosMaisNutricao ?? 0,
        qtdPessoasBenefeciadasMaisNutricaoMN: atendimentosAcoesDados.filter(
          value => value.nome === 'Mais Nutrição',
        )[0]?.qtdPessoasBenefeciadasMaisNutricao ?? 0,
        totalFS: atendimentosAcoesDados.filter(
          value => value.nome === 'Formação Seduc',
        )[0]?.totalEventos ?? 0,
        formadosPadin: atendimentosAcoesDados.filter(
          value => value.nome === 'Padin',
        )[0]?.qtdFormados ?? 0,
        formadosNuclioEstPrecoce: atendimentosAcoesDados.filter(
          value => value.nome === 'Núcleos de Estimulação Precoce',
        )[0]?.qtdFormados ?? 0,
        formadosAgenteComunitario: atendimentosAcoesDados.filter(
          value => value.nome === 'Capacitação dos Agentes Comunitários de Saúde',
        )[0]?.qtdFormados ?? 0,
        formadosPrevencaoEmFamilia: atendimentosAcoesDados.filter(
          value => value.nome === 'Prevenção em Família',
        )[0]?.qtdFormados ?? 0,
        formadosSementesDoAmanha: atendimentosAcoesDados.filter(
          value => value.nome === 'Sementes do Amanhã',
        )[0]?.qtdFormados ?? 0,
        formadosCriancaFeliz: atendimentosAcoesDados.filter(
          value => value.nome === 'Criança Feliz',
        )[0]?.qtdFormados ?? 0,
        totalNucleo: atendimentosAcoesDados.filter(
          value => value.nome === 'Núcleos de Estimulação Precoce',
        )[0]?.totalEventos ?? 0,
        totalPraia: atendimentosAcoesDados.filter(
          value => value.nome === 'Praia Acessível',
        )[0]?.estacoesPraiaAcessivel ?? 0,
        atendimentosPraia: atendimentosAcoesDados.filter(
          value => value.nome === 'Praia Acessível',
        )[0]?.atendimentosPraia ?? 0,
        totalArte: atendimentosAcoesDados.filter(
          value => value.nome === 'Arte na Praça',
        )[0]?.totalEventos ?? 0,
        municipiosVisitadosArte: atendimentosAcoesDados.filter(
          value => value.nome === 'Arte na Praça',
        )[0]?.municipiosArteNaPraca ?? 0,
        qtdEncontrosComunitariosPadin: atendimentosAcoesDados.filter(
          value => value.nome === 'Padin'
        )[0]?.qtdEncontrosComunitarios
      };


      setNumEventosAcoes(qtd);

    }
    setLoadAcoes(false);

  }, [atendimentosAcoesDados]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (atendimentosObrasDados.length > 0) {
      const qtd = {
        brinquedopraca: atendimentosObrasDados.filter(
          value => value.id === 'Brinquedopraça',
        )[0]?.concluidas ?? 0,
        brinquedocreche: atendimentosObrasDados.filter(
          value => value.id === 'Brinquedocreche',
        )[0]?.concluidas ?? 0,
        cei: atendimentosObrasDados.filter(
          value => value.id === 'Centro de Educação Infantil - CEI',
        )[0]?.concluidas ?? 0,
        pracaMaisInfancia: atendimentosObrasDados.filter(
          value => value.id === 'Praça Mais Infancia',
        )[0]?.concluidas ?? 0,
        complexoMaisInfancia: atendimentosObrasDados.filter(
          value => value.id === 'Complexo Mais Infância',
        )[0]?.concluidas ?? 0,

      };
      setLoadObras(false);
      setNumEventosObras(qtd);
    }
  }, [atendimentosObrasDados]); // eslint-disable-line react-hooks/exhaustive-deps


  /* Links do Breadcrumb */
  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/quantitativos-gerais',
      title: 'Quantitativos Gerais',
    },
  ];

  const visitasDomiciliaresList = [
    // {
    //   number: loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
    //     numEventosAcoes.qtdVisitasPadin + numEventosAcoes.qtdVisitasCriancaFeliz + 15042
    //   ).number,
    //   suffix: NumberAndSuffixMake(
    //     numEventosAcoes.qtdVisitasPadin + numEventosAcoes.qtdVisitasCriancaFeliz + 15042
    //   ).suffix,
    //   titleBlack: '',
    //   titleOrange: 'visitas',
    //   withBackground: true
    // },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.qtdVisitasPadin).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.qtdVisitasPadin).suffix,
      titleBlack: 'visitas',
      titleOrange: 'PADIN',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.total.qtdeFamiliasPadin).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.total.qtdeFamiliasPadin).suffix,
      titleBlack: 'famílias',
      titleOrange: 'PADIN',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.total.qtdeCriancasPadin).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.total.qtdeCriancasPadin).suffix,
      titleBlack: 'crianças',
      titleOrange: 'PADIN',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.qtdVisitasCriancaFeliz).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.qtdVisitasCriancaFeliz).suffix,
      titleBlack: 'visitas',
      titleOrange: 'Criança Feliz',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.total.qtdeFamiliasCriancaFeliz).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.total.qtdeFamiliasCriancaFeliz).suffix,
      titleBlack: 'famílias',
      titleOrange: 'Criança Feliz',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.total.qtdeCriancasCriancaFeliz).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.total.qtdeCriancasCriancaFeliz).suffix,
      titleBlack: 'crianças',
      titleOrange: 'Criança Feliz',
    },
    {
      number: NumberAndSuffixMake(15042).number,
      suffix: NumberAndSuffixMake(15042).suffix,
      titleBlack: 'visitas',
      titleOrange: 'PforR',
    }
  ]

  const formacoesList = [
    // {
    //   number: loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
    //     numEventosAcoes.total.qtdFormados + 1300 + 760 + 1494
    //   ).number,
    //   suffix: NumberAndSuffixMake(
    //     numEventosAcoes.total.qtdFormados + 1300 + 760 + 1494
    //   ).suffix,
    //   titleBlack: '',
    //   titleOrange: 'formados',
    //   withBackground: true
    // },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.formadosPadin
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.formadosPadin
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'PADIN',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.formadosCriancaFeliz
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.formadosCriancaFeliz
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Criança Feliz',
    },
    {
      number: NumberAndSuffixMake(
        760
      ).number,
      suffix: NumberAndSuffixMake(
        760
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'PforR',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.formadosNuclioEstPrecoce
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.formadosNuclioEstPrecoce
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Profissionais do Núcleo de Estimulação Precoce',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.formadosAgenteComunitario
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.formadosAgenteComunitario
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Agentes Comunitários da Saúde',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.formadosPrevencaoEmFamilia
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.formadosPrevencaoEmFamilia
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Prevenção em Família',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.formadosSementesDoAmanha
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.formadosSementesDoAmanha
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Sementes do Amanhã',
    },
    {
      number: NumberAndSuffixMake(
        1300
      ).number,
      suffix: NumberAndSuffixMake(
        1300
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Nascer Ceará',
    },
    {
      number: NumberAndSuffixMake(
        1494
      ).number,
      suffix: NumberAndSuffixMake(
        1494
      ).suffix,
      titleBlack: 'formados',
      titleOrange: 'Formação de Gestores da Educação Infantil',
    }
  ]

  const equipamentosList = [
    // {
    //   number: NumberAndSuffixMake(
    //     numEventosObras.cei + numEventosObras.brinquedopraca + numEventosObras.pracaMaisInfancia
    //     + numEventosObras.brinquedocreche + numEventosAcoes.totalNucleo + 2 + numEventosAcoes.totalPraia + 2 + 1 + 1
    //   ).number,
    //   suffix: NumberAndSuffixMake(
    //     numEventosObras.cei
    //   ).suffix,
    //   titleBlack: '',
    //   titleOrange: 'equipamentos entregues',
    //   withBackground: true
    // },
    {
      number: errorObras ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadObras ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosObras.cei
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosObras.cei
      ).suffix,
      titleBlack: '',
      titleOrange: 'CEIs',
    },
    {
      number: errorObras ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadObras ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosObras.brinquedopraca
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosObras.brinquedopraca
      ).suffix,
      titleBlack: '',
      titleOrange: 'Brinquedopraças',
    },
    {
      number: errorObras ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadObras ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosObras.pracaMaisInfancia
      ).number,
      suffix:NumberAndSuffixMake(
        numEventosObras.pracaMaisInfancia
      ).suffix,
      titleBlack: '',
      titleOrange: 'Praças Mais Infância',
    },
    {
      number: errorObras ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadObras ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosObras.brinquedocreche
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosObras.brinquedocreche
      ).suffix,
      titleBlack: '',
      titleOrange: 'Brinquedocreches',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.totalNucleo
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.totalNucleo
      ).suffix,
      titleBlack: '',
      titleOrange: 'Núcleos de Estimulação Precoce',
    },
    {
      number: NumberAndSuffixMake(
        2
      ).number,
      suffix: NumberAndSuffixMake(
        2
      ).suffix,
      titleBlack: '',
      titleOrange: 'Fábricas Mais Nutrição',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosAcoes.totalPraia
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosAcoes.totalPraia
      ).suffix,
      titleBlack: 'Estações do',
      titleOrange: 'Praia Acessível',
    },
    {
      number: errorObras ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadObras ? <LoaderQuantitativos /> : NumberAndSuffixMake(
        numEventosObras.complexoMaisInfancia
      ).number,
      suffix: NumberAndSuffixMake(
        numEventosObras.complexoMaisInfancia
      ).suffix,
      titleBlack: '',
      titleOrange: 'Complexo Mais Infância',
    },
    {
      number: NumberAndSuffixMake(
        1
      ).number,
      suffix: NumberAndSuffixMake(
        1
      ).suffix,
      titleBlack: '',
      titleOrange: 'Espaço Mais Infância',
    },
    {
      number: NumberAndSuffixMake(
        1
      ).number,
      suffix: NumberAndSuffixMake(
        1
      ).suffix,
      titleBlack: '',
      titleOrange: 'Espaço na Biblioteca Pública Estadual do Ceará',
    },
    {
      number: NumberAndSuffixMake(
        1
      ).number,
      suffix: NumberAndSuffixMake(
        1
      ).suffix,
      titleBlack: '',
      titleOrange: 'Cidade Mais Infância',
    },
  ]

  const maisNutricaoList = [
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.qtdToneladasDeAlimentosDoadosMN).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.qtdToneladasDeAlimentosDoadosMN).suffix,
      titleBlack: 'toneladas de',
      titleOrange: 'alimentos distribuídos'
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.municipiosContempladosMN).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.municipiosContempladosMN).suffix,
      titleBlack: '',
      titleOrange: 'municípios contempladas',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.qtdPessoasBenefeciadasMaisNutricaoMN).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.qtdPessoasBenefeciadasMaisNutricaoMN).suffix,
      titleBlack: '',
      titleOrange: 'pessoas beneficiadas nas instituições',
    },
    {
      number: errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.cestasBasicasPandemiaMN).number,
      suffix: NumberAndSuffixMake(numEventosAcoes.cestasBasicasPandemiaMN).suffix,
      titleBlack: '',
      titleOrange: 'cestas básicas',
      withBackground: true,
      titleCard: 'Ações na pandemia'
    }
  ]

  return (
    <>
      <div className={`content ${device.prefix()}`}>
        <header className="container-box">
          <Breadcrumb links={links} />
          <TitlePage
            title="Quantitativos Gerais"
            subtitle="Acompanhe o que foi feito no Programa Mais Infância"
            backButton
          />
        </header>

        <section>
          <DataBox
            title="Visitas domiciliares"
            titleNumber={FormatNumberWithSufix(numEventosAcoes.qtdVisitasPadin + numEventosAcoes.total.qtdeFamiliasPadin + 
              numEventosAcoes.total.qtdeCriancasPadin + + numEventosAcoes.total.qtdeFamiliasCriancaFeliz + 
              numEventosAcoes.total.qtdeCriancasCriancaFeliz + 
              numEventosAcoes.qtdVisitasCriancaFeliz + 15042).toString()}
            dataList={visitasDomiciliaresList}
          />
        </section>


        <section className='yellow-section'>
          <DataBox
            title="Formados"
            titleNumber={FormatNumberWithSufix(numEventosAcoes.total.qtdFormados + 1300 + 760 + 1494).toString()}
            dataList={formacoesList}
          />
        </section>

        <section>
          <DataBox
            title="Equipamentos entregues à população"
            titleNumber={FormatNumberWithSufix(
              numEventosObras.cei + numEventosObras.brinquedopraca + numEventosObras.pracaMaisInfancia
              + numEventosObras.brinquedocreche + numEventosAcoes.totalNucleo + 2 + numEventosAcoes.totalPraia + numEventosObras.complexoMaisInfancia + 1 + 1 + 1
            ).toString()}
            dataList={equipamentosList}

          />
        </section>

        <section className='yellow-section'>
          <DataBox
            title="Mais Nutrição"
            dataList={maisNutricaoList}
          />
        </section>

        <section className="section-double">
          {device.isMD('bigger') && <div></div>}
          <DataInfoBoxWithTitle
            title="Cartão Mais Infância"
            children={
              <div>
                <DataInfo
                  number={familiasBeneficiadas==='error' ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : familiasBeneficiadas === undefined ? <LoaderQuantitativos /> : NumberAndSuffixMake(Number(familiasBeneficiadas)).number}
                  suffix={NumberAndSuffixMake(Number(familiasBeneficiadas)).suffix}
                  titleOrange={'familias atendidas'}
                />
                <DataInfo
                  number={dinheiroIvestido==='error' ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : dinheiroIvestido === undefined ? <LoaderQuantitativos /> : NumberAndSuffixMake(Number(dinheiroIvestido)).number}
                  suffix={NumberAndSuffixMake(Number(dinheiroIvestido)).suffix}
                  withMoney
                  titleOrange={'reais investidos'}
                />

              </div>
            }
          />

          <DataInfoBoxWithTitle
            title="Arte na praça"
            withBackground
            children={
              <div>
                <DataInfo
                  number={errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.totalArte).number}
                  suffix={NumberAndSuffixMake(numEventosAcoes.totalArte).suffix}
                  titleOrange={'edições realizadas'}
                />
                <DataInfo
                  number={errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.municipiosVisitadosArte).number}
                  suffix={NumberAndSuffixMake(numEventosAcoes.municipiosVisitadosArte).suffix}
                  titleOrange={'municípios visitados'}
                />
              </div>
            }
          />
          
          {device.isMD('bigger') && <div></div>}
          
        </section>
        <section className="yellow-section">
          <div className="section-double bigger">
            <div></div>
            <DataInfo
              titleCard='Praia Acessível'
              subtitleCard='Desde o início'
              number={errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : loadAcoes ? <LoaderQuantitativos /> : NumberAndSuffixMake(numEventosAcoes.atendimentosPraia).number}
              suffix={NumberAndSuffixMake(numEventosAcoes.atendimentosPraia).suffix}
              titleOrange={'atendimentos'}
            />
            <DataInfo
              titleCard='Agentes'
              subtitleCard='Mais Infancia'
              number={visitasRealizadasAgentes === 'error' ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : visitasRealizadasAgentes === undefined ? <LoaderQuantitativos /> : NumberAndSuffixMake(Number(visitasRealizadasAgentes)).number}
              suffix={NumberAndSuffixMake(Number(visitasRealizadasAgentes)).suffix}
              titleOrange={'visitas'}
              withBackground
            />
            <DataInfo
              titleCard='Encontros comunitários'
              subtitleCard='PADIN'
              number={errorAcoes ? <Tooltip title="Erro ao buscar esta informação"> - </Tooltip> : errorAcoes === undefined ? <LoaderQuantitativos /> : NumberAndSuffixMake(Number(numEventosAcoes.qtdEncontrosComunitariosPadin)).number}
              suffix={NumberAndSuffixMake(Number(numEventosAcoes.qtdEncontrosComunitariosPadin)).suffix}
              titleOrange={'encontros'}
            />
            <div></div>

          </div>
        </section>
        

      </div>
      <Footer />
    </>
  );
};

export default QuantitativosGerais;
