import React, { useEffect, useState } from 'react';
import L, { LatLngExpression } from 'leaflet';

import { GeoJsonProps } from 'components/RegionGeoJson/types';

import './styles.scss';
import FloatingBlocks from './FloatingBlocks';

interface MapIconJsonProps {
  data: GeoJsonProps;
}
const MapIcon: React.FC<MapIconJsonProps> = props => {
  const { data } = props;

  const [, setCenter] = useState<LatLngExpression>();

  const [typeRender, setTypeRender] = useState<string>('cima');
  const [qtdFamilies, setQtdFamilies] = useState<number>(0);
  const [nameCity, setNameCity] = useState<string>('');

  function ReverseLatLng(center: any) {
    const newCenter: LatLngExpression = [center.lng, center.lat];

    if (newCenter[1] < -39.5 || newCenter[0] > -3) {

      setTypeRender('lado');
    } else {
      setTypeRender('cima');
    }

    return newCenter;
  }
  useEffect(() => {
    const poly = L.polygon(data.geoJson[0].geometry.coordinates);
    const center = ReverseLatLng(poly.getBounds().getCenter());

    const city = data.geoJson[0].properties.name;

    if (
      city === 'Aquiraz' ||
      city === 'Crato' ||
      city === 'Quixadá' ||
      city === 'Camocim'
    ) {
      setTypeRender('baixo');
    }

    setCenter(center);
    setQtdFamilies(parseInt(data.qtdFamilias, 10));
    setNameCity(city);
  }, [data]);

  return (
    <FloatingBlocks
      type={typeRender}
      qtdFamilies={qtdFamilies}
      nameCity={nameCity}
    />
  );
};
export default MapIcon;
