import { formattedStringKey } from "utils/strings";

export function convertArraysFromString(currentArray: any) {

  const newArray = currentArray.map(lineArray => {
    return lineArray.map(line => {

      if (Array.isArray(line?.title)) {
        return { ...line, title: line.title.join('') };
      }
      return line;
    });
  });

  

  return newArray;
}

export function genereteWidthColumn(data) {
  let newDate: any;
  let totalKeys = 0;

  Object.keys(data).forEach((_) => {
    totalKeys = totalKeys + 1;
  });
  
  Object.keys(data).forEach((_, index) => {
    newDate = {
      ...newDate,
      [index]: {
        cellWidth: (293 / totalKeys),
      },
    };
  });
  return newDate;
}

function percorrerObj(obj: any, objFinal){

  const newObject = {...objFinal};
  for(let key in obj) {
    if (key === 'arquivosRegistro' ||
        key === 'ultimaModificacao' ||
        key === 'ultimoEditor' ||
        key === 'classe' ||
        key === 'id' 
    ) continue
    else {
      if (typeof obj[key] === 'object' && Array.isArray(obj[key]) === false) percorrerObj(obj[key], newObject)
      else if(Array.isArray(obj[key])) {
        if(typeof obj[key][0] === 'string') newObject[`${key}`] = formattedStringKey(key)
        else percorrerObj(obj[key][0], newObject)
      }
      else newObject[`${key}`] = formattedStringKey(key)
    }
  };

  return newObject;
}

export function extractKeys(obj: any, objFinal){
  obj.forEach(value => {
    value.eventos.forEach(objeto => {
      percorrerObj(objeto, objFinal)
    })
  })
}


export function genereteRows(currentRows) {
  let newRow;

  if(Array.isArray(currentRows[2])){
    currentRows[2].forEach(row =>{
      newRow = {...newRow, [row.dataKey]: row.dataKey}
    })
  }else if(Array.isArray(currentRows[1])){
    currentRows[1].forEach(row =>{
      newRow = {...newRow, [row.dataKey]: row.dataKey}
    })
  }else{
    currentRows[0].forEach(row =>{
      newRow = {...newRow, [row.dataKey]: row.dataKey}
    })
  }

  return newRow;
}

export function formatObject(obj, objectLine) {
  for (let key in obj) {
    if (
      key === 'classe' ||
      key === 'ultimaModificacao' ||
      key === 'id' ||
      key === 'tipo' ||
      key === 'anexos' ||
      key === 'ultimoEditor' ||
      key === 'arquivosRegistro' ||
      key.includes('/')
    )
      continue; 
      if (key.includes('dataComemorativa'))
     { 
      
      objectLine.push({
        title: obj[key] ? 'Sim' : 'Não',
        dataKey: key,
      })
     }
      else if (key.includes('data') || key.includes('previsao'))
      objectLine.push({
        title: new Date(obj[key]).toLocaleDateString('fr-FR'),
        dataKey: key,
      });
    else if (typeof obj[key] === 'object' && Array.isArray(obj[key]) === false)
      formatObject(obj[key], objectLine);
    else if (Array.isArray(obj[key])) {
      if (typeof obj[key][0] === 'string')
        objectLine.push({ title: obj[key].join(', '), dataKey: key });
      else if(key === 'ciclosPadin'){
       
        obj[key].forEach((ciclo) => {
          Object.keys(ciclo).forEach(valueCicloPadin => {
            
            objectLine.push({ title: valueCicloPadin, dataKey: key });
          });
        });
      
      }
      else {

        obj[key].forEach((eixo, index) => {
          Object.keys(eixo).forEach(value => {
            const indexOfValue = objectLine.findIndex(i => {
              const valueCompare =
                value === 'qtdAtendimentos' ? 'qtdAtendDiagnostico' : value;
              return i.dataKey === valueCompare;
            });

            if (index === 0) {
              objectLine.push({
                title: [`${eixo[value]}\n`],
                dataKey:
                  value === 'qtdAtendimentos' ? 'qtdAtendDiagnostico' : value,
              });
            } else {
              objectLine[indexOfValue].title.push(`${eixo[value]}\n`);
            }
          });
        });
      }
    } else {
      if (key.includes('data') || key.includes('previsao'))
        objectLine.push({
          title: new Date(obj[key]).toLocaleDateString('fr-FR'),
          dataKey: key,
        });
      else objectLine.push({ title: obj[key], dataKey: key });
    }
  }
}