import React from 'react';

import './styles.scss';

import MaisInfanciaLogoImg from 'assets/mais-infancia-logo.svg';
import { BackButton } from 'components/BackButton/BackButton';

type TitlePageProps = {
  title: string;
  subtitle?: string;
  backButton?: boolean;
  style?: {};
}

export const TitlePage = ({ title, subtitle, backButton, style }: TitlePageProps) => {
  return (
    <div>
      <div className="title-page" style={ style }> 
          <section>
            { backButton && <BackButton size={60} /> }
            <div>
                <h1>{title}</h1>
                { subtitle && <h3>{subtitle}</h3> }
            </div>
          </section>
          <img className="logo" src={MaisInfanciaLogoImg} alt="Logo mais infãncia" />
      </div>
    </div>
  )
}