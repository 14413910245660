import { socialApi } from 'services/api';
import { Activity, ActivityType } from 'types';

const service = () => {
  const resource = 'activities';

  async function createActivity(data) {
    try {
      const response = await socialApi.post<Activity>(`${resource}`, data);

      return response.data;

    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getActivitiesByAgentId(id: string) {
    try {
      const response = await socialApi.get<Activity[]>(`agents/${id}/${resource}`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getActivityTypes() {
    try {
      const response = await socialApi.get<ActivityType[]>('activity-types');

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getActivitiesByWorkplan(workplanId: number) {
    try {
      const response = await socialApi.get<Activity[]>(`/workplans/${workplanId}/${resource}`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function updateActivity(data, activityId: number) {
    try {
      const response = await socialApi.put<Activity>(`${resource}/${activityId}`, data);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    } 
  }

  return {
    createActivity,
    getActivitiesByAgentId,
    getActivityTypes,
    updateActivity,
    getActivitiesByWorkplan
  }
}

export const activityService = service();