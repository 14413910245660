import styled from 'styled-components';
import { ButtonGroup } from '../ArticulatorWorkPlan/styles';

export { 
  Container, 
  Content, 
  Wrapper,
  CommentContainer,
  CancelButton
} from '../ArticulatorWorkPlan/styles';

export const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.3rem;

  width: 100%;
  max-width: 1300px;
  margin: 7rem auto;
`;


export const CreateWorkPlanBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: #f2f2f2;
  border-radius: 7px;

  align-self: center;
  padding: 2.9rem 4rem;

  .ant-divider {
    background: rgba(0, 0, 0, 0.3);
  }

  @media(max-width: 500px) {
    padding: 1rem;
  }
`;

export const TopWorkPlanBox = styled.div`
  display: flex;
  height: max-content;
  border-left: 3px solid #FFDE32; 

  @media(max-width: 500px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 20px;
  }
`;

export const ItemLabel = styled.div`
  margin-left: 1.8rem;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 700;
    color: #464A4E;
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .ant-input {
    width: 9rem;
  }

  + div {
    margin-left: 7rem;
  }

  @media(max-width: 500px) {
    
    & + div {
      margin-left: 1.8rem;
      justify-self: flex-end;
    }
  }
`;

export const DiagnosticBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.1rem;

  textarea.ant-input {
    min-height: 24rem;

    &:disabled {
      color: #464A4E;
    }
  }
`;

export const DiagnosticBoxTitle = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #2D4040;
`;


export const VerticalDate = styled.span`
  font-weight: 800;
  font-size: 10rem;
  line-height: 13.6rem;
  text-align: right;

  color: rgba(96, 196, 112, 0.3);
  
  position: absolute;
  top: 50%;
  right: 0;

  transform: rotate(-90deg) translate(50%, 50%);
`;

export const ButtonGroupComment = styled(ButtonGroup)`
  .comment-button {
    background: #4B9F37;
    cursor: pointer;
    transition: filter .3s;
    border: 0;
    
    &:hover {
      background: #4B9F37;
      filter: brightness(0.7);
    }
  }
`;