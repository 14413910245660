import React from "react";

import './styles.scss';

type DataInfoBoxWithTitleProps = {
  title: string,
  withBackground?: boolean,
  children: any
}



export function DataInfoBoxWithTitle({title, withBackground=false, children}: DataInfoBoxWithTitleProps) {
  return (
    <div className={`dados-infos ${withBackground && `background`}`}>
      <h2>{title}</h2>
      {children}
    </div>
  );
}