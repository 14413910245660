import React from 'react';
import { Form, FormInstance, InputNumber, DatePicker, Typography } from 'antd';
import { FormBox } from '../../UI/index';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';

const { RangePicker } = DatePicker;
const { Title } = Typography;

interface NucleoFormProps {
  form?: FormInstance;
  data: any;
  setData: (value: any) => void;
}

const { Item } = Form;

export function NucleoForm({ form, data, setData }: NucleoFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  function addEdition(index) {
    const dataT = {
      edicao: index + 1,
      numeroAtendimentos: 0,
      numeroCriancasAtendidas: 0,
      periodo: {
        dataFim: '',
        dataInicio: '',
      },
    };

    setData({
      ...data,
      nucleoEstimulacaoPrecoce: {
        criancasAtendidas: {
          porEdicaoCriancas: [
            ...data.nucleoEstimulacaoPrecoce.criancasAtendidas
              .porEdicaoCriancas,
            dataT,
          ],
        },
      },
    });
  }

  function removeEdition(index) {
    data.nucleoEstimulacaoPrecoce.criancasAtendidas.porEdicaoCriancas.splice(
      index,
      1,
    );
    setData({ ...data });
  }

  return (
    <FormBox title="Pessoas beneficiadas por edição" form={currentForm}>
      {data.nucleoEstimulacaoPrecoce.criancasAtendidas.porEdicaoCriancas.map(
        (item, index) => (
          <React.Fragment key={index}>
            <div className="butto-control">
              <Title level={5}>
                {index > 0 ? (
                  <IoRemoveCircle
                    onClick={() => removeEdition(index)}
                    size={25}
                    color="var(--green-dark"
                  />
                ) : (
                  <></>
                )}
                Edição {item.edicao}
              </Title>
            </div>
            <Item
              label="Período"
              name={`periodo-${item.edicao - 1}`}
              wrapperCol={{ span: 6 }}
              rules={[{ required: true, message: 'Campo não preenchido' }]}
            >
              <RangePicker locale={locale} />
            </Item>
            <Item
              label="Número de Atendimentos"
              name={`atendimento-${item.edicao - 1}`}
              rules={[{ required: true, message: 'Campo não preenchido' }]}
            >
              <InputNumber min={0} />
            </Item>
            <Item
              label="Crianças Atendidas"
              name={`kid-atendimento-${item.edicao - 1}`}
              rules={[{ required: true, message: 'Campo não preenchido' }]}
            >
              <InputNumber min={0} />
            </Item>
          </React.Fragment>
        ),
      )}
      <div className="butto-control">
        <Title level={5}>
          <IoAddCircle
            onClick={() =>
              addEdition(
                data.nucleoEstimulacaoPrecoce.criancasAtendidas
                  .porEdicaoCriancas[
                  data.nucleoEstimulacaoPrecoce.criancasAtendidas
                    .porEdicaoCriancas.length - 1
                ].edicao,
              )
            }
            size={25}
            color="var(--green-dark"
          />{' '}
          Adicionar edição
        </Title>
      </div>
    </FormBox>
  );
}
