import React, { useState, useEffect } from 'react';
import { Form as AntForm, Breadcrumb, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import {
  agentToForm,
  ArticulatorValues,
  Personal,
  Professional,
  Address,
  Family,
  formToAgent,
  formToUpgradableAgent,
} from './helper';
import { Authorizer, Feedback } from 'components';
import { agentService } from 'services';

import { StepPanel } from './StepPanel';
import { ButtonPanel } from './ButtonPanel';
import { Form } from './Form';

import './styles.scss';

const { useForm } = AntForm;
const { isPublicServer, isArticulator, isSPSAdmin } = Authorizer.checkRoles;

export function Articulator() {
  const [addressForm] = useForm<Address>();
  const [familyForm] = useForm<Family>();
  const [personalForm] = useForm<Personal>();
  const [professionalForm] = useForm<Professional>();

  const [step, setStep] = useState(0);
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [finished, setFinished] = useState(false);
  const [articulatorInfo, setArticulatorInfo] = useState<ArticulatorValues | null>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<string>('');

  const { client, roles = [] } = Authorizer.useAuth().user;

  const history = useHistory();
  const admin = isPublicServer(roles) || isSPSAdmin(roles);
  const articulator = isArticulator(roles);

  useEffect(() => {
    if (invalidCpf) {
      notification.error({
        message: 'Usuário não pode ser um agente mais infância',
        description: 'Você não pode tornar a si mesmo um agente mais infância',
        top: 20,
        duration: 6,
        style: {
          top: '120px',
        }
      });
    }
  }, [invalidCpf]);

  useEffect(() => {
    if (articulatorInfo) {
      const { personal, professional, address, family } = articulatorInfo

      personalForm.setFieldsValue(personal);
      professionalForm.setFieldsValue(professional);
      addressForm.setFieldsValue(address);
      familyForm.setFieldsValue(family);
    }
  }, [articulatorInfo, personalForm, professionalForm, addressForm, familyForm]);

  function handleLoaded() {
    searchUserByCpf(client.userId);
  }

  function handleCancel() {
    const redirect = isArticulator(roles) ? 'articulador-inicio' : 'coordenador-home';
    history.push(redirect);
  }

  function handleBack() {
    setStep(oldState => (oldState === 0 ? oldState : oldState - 1));
  }

  function defineArticulator(cpf: string) {
    setLoading(true);
    agentService.getAgent(cpf)
      .then(data => agentToForm(data))
      .then(data => setArticulatorInfo(data))
      .finally(() => setLoading(false));
  }

  function defineStepOne() {
    setArticulatorInfo(cur => ({
      ...(cur || {} as ArticulatorValues),
      personal: personalForm.getFieldsValue(),
      professional: professionalForm.getFieldsValue(),
    }))
  }

  function defineStepTwo() {
    setArticulatorInfo(cur => ({
      ...(cur || {} as ArticulatorValues),
      address: addressForm.getFieldsValue(),
      family: familyForm.getFieldsValue()
    }))
  }

  function validateStepOne() {
    const validation1 = personalForm.validateFields;
    const validation2 = professionalForm.validateFields;

    Promise
      .all([validation1, validation2])
      .then(() => setStep(cur => cur === 1 ? cur : cur+1));
  }

  function validateStepTwo() {
    const validation1 = familyForm.validateFields;
    const validation2 = addressForm.validateFields;

    Promise
      .all([validation1, validation2])
      .then(() => setStep(cur => cur === 2 ? cur : cur+1));
  }

  function handleNext() {
    if (step === 0) {
      defineStepOne()
      validateStepOne();
    } else {
      defineStepTwo()
      validateStepTwo();
    }
  }

  function isCpfEqualsToUser(cpf: string) {
    const size = cpf.length;

    if (size === 11 && cpf === client.userId) setInvalidCpf(true);

    if (size === 11 && !invalidCpf) setInvalidCpf(false);
  }

  function searchUserByCpf(cpf: string) {
    const size = cpf.length;

    const adminRule = admin && cpf !== client.userId;
    const articulatorRule = articulator;

    if (size === 11 && (adminRule || articulatorRule)) {
      defineArticulator(cpf);
    }
  }

  function handleCpf(cpf: string) {
    isCpfEqualsToUser(cpf);

    searchUserByCpf(cpf);
  }

  function createArticulator() {
    if (articulatorInfo) {
      const body = formToAgent(articulatorInfo)

      setLoading(true)
      agentService
        .postAgents(body)
        .catch(err => setErr(err.message))
        .finally(() => {
          setLoading(false)
          setFinished(true);
        });
    }
  }

  function updateArticulator() {
    if (articulatorInfo) {
      setLoading(true);
      agentService.getAgent(articulatorInfo.personal.cpf)
        .then(data => formToUpgradableAgent(articulatorInfo, data))
        .then(data => agentService.patchAgent(data))
        .catch(err => setErr(err.message))
        .finally(() => {
          setLoading(false);
          setFinished(true);
        })
    }
  }

  function handleConfirm () {
    if (isPublicServer(roles) || isSPSAdmin(roles)) {
      return createArticulator;
    }

    return updateArticulator;
  }

  function handleFinish() {
    setFinished(false);
    if (err) {
      history.replace('/agente-mais-infancia');
    } else {
      const path = articulator ? 'articulador-inicio' : 'coordenador-home';

      history.replace(path);
    }
  }

  return (
    <div className="Articulator">
      <div className="content">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/coordenador-home">Página Inicial</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to="/agente-mais-infancia">Cadastro de Articulador</Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <h2 className="title">
            Cadastro de Agente Mais Infância
          </h2>
        </div>

        <div className="form-body">
          <StepPanel
            step={step}
            articulator={articulator}
          />

          <Form
            step={step}
            articulator={articulator}
            data={articulatorInfo}
            loading={loading}
            personalForm={personalForm}
            professionalForm={professionalForm}
            familyForm={familyForm}
            addressForm={addressForm}
            onCpf={handleCpf}
            onLoaded={handleLoaded}
          />

          <ButtonPanel
            step={step}
            articulator={articulator}
            onBack={handleBack}
            onNext={handleNext}
            onCancel={handleCancel}
            onConfirm={handleConfirm()}
          />
        </div>
      </div>

      <Feedback visible={finished} error={err} onOk={handleFinish} />
    </div>
  );
}
