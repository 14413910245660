import React, { createContext, ReactNode, useState } from 'react';

interface TimelineContextData {
  isActiveMark: boolean;
  setIsActiveMark: (value: boolean) => void;
  currentTick: number;
  setCurrentTick: (value: number) => void;
  currentInfosTimeline: any;
  setCurrentInfosTimeline: (value: any) => void;
  teste: () => void;
  currentChave: string;
  setCurrentChave: (value: string) => void;
  currentMonth: number;
  setCurrentMonth: (value: number) => void;
  currentYearScroll: string;
  setCurrentYearScroll: (value: string) => void;
  dataRange: string;
  setDataRange: (value: string) => void;
  regionSelected: string;
  setRegionSelected: (value: string) => void;
  citiesSelecteds: string[];
  setCitiesSelecteds: (value: string[]) => void;
}

interface TimelineContextProviderProps {
  children: ReactNode;
}

export const TimelineContext = createContext({} as TimelineContextData);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function TimelineContextProvider({
  children,
}: TimelineContextProviderProps) {
  const [isActiveMark, setIsActiveMark] = useState<boolean>(false);

  const [currentMonth, setCurrentMonth] = useState<number>(0);
  const [currentYearScroll, setCurrentYearScroll] = useState<string>('2017');
  const [currentTick, setCurrentTick] = useState<number>(0);
  const [currentChave, setCurrentChave] = useState<string>('00');
  const [currentInfosTimeline, setCurrentInfosTimeline] = useState<any>();
  const [dataRange, setDataRange] = useState<any>('');
  const [regionSelected, setRegionSelected] = useState<string>('');
  const [citiesSelecteds, setCitiesSelecteds] = useState<string[]>([]);


  function teste() {
    setIsActiveMark(!isActiveMark);
  }

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <TimelineContext.Provider
      value={{
        currentTick,
        isActiveMark,
        setCurrentTick,
        setIsActiveMark,
        currentInfosTimeline,
        setCurrentInfosTimeline,
        teste,
        currentChave,
        setCurrentChave,
        currentMonth,
        setCurrentMonth,
        currentYearScroll,
        setCurrentYearScroll,
        dataRange,
        setDataRange,
        regionSelected,
        setRegionSelected,
        citiesSelecteds,
        setCitiesSelecteds
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
}
