import styled from 'styled-components';
import { flexLayout } from 'styles/layouts';

export const Container = styled.div`
  height: 80vh;

  ${flexLayout('center', 'center', 'column')}

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }

  .buttons {
    a {
      margin: 0 10px;
      padding: 5px 20px;

      border: none;
      border-radius: 5px;

      background-color: #4B9F37;
      color: #fff;

      cursor: pointer;
      transition: filter .1s linear;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

`;