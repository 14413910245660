import React from 'react'
import './styles.scss';

import { Breadcrumb } from '../../components';
import { Button, Steps, message, Form } from 'antd';

const { Step } = Steps;


const CadTipoObraAcao = () => {
    const [current, setCurrent] = React.useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const links = [
        {
            path: '/home-dashboard',
            title: 'Página Inicial',
        },
        {
            path: '/home-admin',
            title: 'Lista Obra e ações',
        },
        {
            path: '/cadastrar-tipo-obra-acao',
            title: 'Cadastro de novo tipo de obra ou ação'
        }
    ];

    const steps = [
        {
            title: 'Dados Iniciais ',
            content: "1"
        },
        {
            title: 'Informações necessárias',
            content: "2"
        },
        {
            title: 'Confirmação',
            content: "3"
        },
    ];


    return (
        <div className="cad-tipo-obra-acao__container">
            <Breadcrumb links={links} />
            <h1>Cadastro de novo tipo de obra ou ação</h1>
            <span>
                Cadastre um novo tipo de obra ou ação que ainda
                não faça parte das iniciativas do Governo do Estado
                no Programa Mais Infância Ceará.
            </span>
            <div className="steps-container">
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <Form >
                    {steps[current].content}
                </Form>   
                <div className="steps-action">
                    {current === 0 && (
                        <Button >
                            CANCELAR
                        </Button>
                    )}
                    {current > 0 && (
                        <Button onClick={() => prev()}>
                            VOLTAR
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            AVANÇAR
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => message.success('Processing complete!')}>
                            CONCLUIR
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );

}

export default CadTipoObraAcao;