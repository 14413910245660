import moment from "moment";
import { Gestor } from "types";

export function gestorToForm(manager: Gestor) {
  
    const personal = {
      cpf: manager.cpf,
      name: manager.name,
      email: manager.email,
      matrialStatus: manager.matrialStatus,
      birthDate: manager.birthDate ? moment(manager.birthDate) : undefined,
      phone: manager.phone
    };
  
    const professional = {
        municipio: manager.city ?? undefined,
        profissao: manager.occupation,
        setor: manager.sector,
        orgao: manager.agency,
        competencia: manager.competence,
        ibge: manager.ibge
    };
  
  
    return { personal, professional };
  }