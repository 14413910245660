import React from 'react';

import { GeoJSON, Tooltip } from 'react-leaflet';

import './styles.scss';
interface RegionGeoJsonProps {
  data: any;
  porc: number;
}
const COLOR_0 = '#ADC4A3';
const COLOR_1 = '#8BB87B';
const COLOR_2 = '#4B9F37';
const COLOR_3 = '#327824';

const GeoJsonCMIC: React.FC<RegionGeoJsonProps> = props => {
  const { data, porc } = props;

  function getColorRegion() {
    if (porc <= 100) return COLOR_0;
    if (porc > 100 && porc <= 500) return COLOR_1;
    if (porc > 500 && porc <= 1000) return COLOR_2;
    return COLOR_3;
  }

  const styleGeoJson = {
    color: '#ffffffb5',
    weight: 0.5,

    opacity: 1,
    fillOpacity: 1,
    fillColor: getColorRegion(),
  };

  if (data) {
    return (
      <>
        <GeoJSON data={data.geometry as any} style={styleGeoJson}>
          <Tooltip direction="top">
            {data.properties?.name} - {porc}
          </Tooltip>
        </GeoJSON>
      </>
    );
  }
  return null;
};
export default GeoJsonCMIC;
