import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
} from 'antd';
import { FormBox } from '../../UI/index';
import TextArea from 'antd/lib/input/TextArea';
import rules from 'components/CadInfoAcoes/rules';
import municipiosCE from '../../../data/ceara-full.json';

import './styles.scss';

interface ActionValuesProps {
  form?: FormInstance;
  action?: any;
  disabled: boolean;
}

const { Item } = Form;
const { Option } = Select;
const optionOnibus = [
  { value: 'Inauguração/revitaização de praças' },
  { value: 'Datas comemorativas' },
  { value: 'Inaiugaração/revitalização de equipamentos' },
  { value: 'Receptivos de eventos' },
];

export function ActionValues({ form, action, disabled }: ActionValuesProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const [valuesAcao, setValuesAcao] = useState<any>([]);

  const elemetos = {
    edicao: {
      element: (
        <Item
          label="Edição"
          name="edicao"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Edição não preenchida' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    local: {
      element: (
        <Item
          label="Local"
          name="local"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    nome: {
      element: (
        <Item
          label="Nome"
          name="nome"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    localização: {
      element: (
        <>
          <Item
            label="Municipio"
            name="municipio"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <Input type="text" disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Rua"
            name="rua"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <Input type="text" disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Bairro"
            name="bairro"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <Input type="text" disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="CEP"
            name="cep"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <Input type="text" disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Numero"
            name="numero"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <Input type="text" disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Ponto de referência"
            name="ponto de referencia"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <Input type="text" disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Longitude"
            name="longitude"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Latitude"
            name="latitude"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
        </>
      ),
    },
    mes: {
      element: (
        <Item
          label="Mês"
          name="mes"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    atendimentos: {
      element: (
        <>
          <Item
            label="Menores de 6 anos"
            name="menoresDe6"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Entre 6 e 12 anos"
            name="entre6e12"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Entre 12 e 19 anos"
            name="entre12e19"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Adultos"
            name="adultos"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Idoso maior que 60 anos"
            name="idosoMaiorQue60"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
        </>
      ),
    },
    ano: {
      element: (
        <Item
          label="Ano"
          name="ano"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    equipamento: {
      element: (
        <Item
          label="Equipamento"
          name="equipamento"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    dataInauguracao: {
      element: (
        <Item
          label="Data de inauguração"
          name="dataInauguracao"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <DatePicker disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    lancamento: {
      element: (
        <Item
          label="Data de lançamento"
          name="lancamento"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <DatePicker disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    totalMudasPlantadas: {
      element: (
        <Item
          label="Total de mudas plantadas"
          name="totalMudasPlantadas"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    totalMudasDoadas: {
      element: (
        <Item
          label="Total de mudas doadas"
          name="totalMudasDoadas"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdCriancas: {
      element: (
        <Item
          label="Quantidade de crianças"
          name="qtdCriancas"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    descricao: {
      element: (
        <Item
          label="Descrição"
          name="descricao"
          rules={[{ required: true, message: 'Descrição não preenchida' }]}
        >
          <TextArea
            rows={4}
            autoSize={{ minRows: 4, maxRows: 6 }}
            disabled={disabled}
            bordered={!disabled}
          />
        </Item>
      ),
    },
    descricaoProjeto: {
      element: (
        <Item
          label="Descrição"
          name="descricaoProjeto"
          rules={[{ required: true, message: 'Descrição não preenchida' }]}
        >
          <TextArea
            rows={4}
            autoSize={{ minRows: 4, maxRows: 6 }}
            disabled={disabled}
            bordered={!disabled}
          />
        </Item>
      ),
    },

    descricaoObjetivo: {
      element: (
        <Item
          label="Descrição e Ojetivo"
          name="descricaoObjetivo"
          rules={[{ required: true, message: 'Campo não preenchida' }]}
        >
          <TextArea
            rows={4}
            autoSize={{ minRows: 4, maxRows: 6 }}
            disabled={disabled}
            bordered={!disabled}
          />
        </Item>
      ),
    },

    qualEvento: {
      element: (
        <Item
          label="Qual o evento?"
          name="qualEvento"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <AutoComplete
            options={optionOnibus}
            disabled={disabled}
            bordered={!disabled}
            style={{ width: 200 }}
            placeholder="Qual o evento"
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
        </Item>
      ),
    },
    qtdPessoasbeneficiadas: {
      element: (
        <Item
          label="Quantidade de pessoas beneficiadas"
          name="qtdPessoasbeneficiadas"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdFamiliasBeneficiadas: {
      element: (
        <Item
          label="Quantidade de famílias beneficiadas"
          name="qtdFamiliasBeneficiadas"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroOficinasRealizadas: {
      element: (
        <Item
          label="Oficinas Realizadas"
          name="numeroOficinasRealizadas"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdCriancasFormadas: {
      element: (
        <Item
          label="Crianças Formadas"
          name="qtdCriancasFormadas"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdInstrumentosDoados: {
      element: (
        <Item
          label="Instrumentos Doados"
          name="qtdInstrumentosDoados"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdCapacitacoes: {
      element: (
        <Item
          label="Capacitações"
          name="qtdCapacitacoes"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdCapacitacoesProfissionais: {
      element: (
        <Item
          label="Capacitações profissionais"
          name="qtdCapacitacoesProfissionais"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroDvdsEntregues: {
      element: (
        <Item
          label="DVD's Entregues"
          name="numeroDvdsEntregues"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroExibicoes: {
      element: (
        <Item
          label="Exibições"
          name="numeroExibicoes"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    criancasAtendidas: {
      element: (
        <Item
          label="Crianças Atendidas"
          name="criancasAtendidas"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroApresentacoesCircoEscola: {
      element: (
        <Item
          label="Apresentação do Circo Escola"
          name="numeroApresentacoesCircoEscola"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroCapacitacoesProfissionais: {
      element: (
        <Item
          label="Capacitações"
          name="numeroCapacitacoesProfissionais"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroPalestras: {
      element: (
        <Item
          label="Palestras Realizadas"
          name="numeroPalestras"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    numeroParticipantes: {
      element: (
        <Item
          label="Participantes"
          name="numeroParticipantes"
          wrapperCol={{ span: 6 }}
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    programacao: {
      element: (
        <Item
          label="Programação"
          name="programacao"
          rules={[{ required: true, message: 'Programação não preenchida' }]}
        >
          <TextArea
            rows={4}
            autoSize={{ minRows: 4, maxRows: 6 }}
            disabled={disabled}
            bordered={!disabled}
          />
        </Item>
      ),
    },
    municipio: {
      element: (
        <Item
          label="Município"
          name="municipio"
          rules={rules.city}
          wrapperCol={{ span: 6 }}
        >
          <Select showSearch disabled={disabled} bordered={!disabled}>
            {municipiosCE.features.map(city => (
              <Option value={city.properties.name} key={city.properties.id}>
                {city.properties.name}
              </Option>
            ))}
          </Select>
        </Item>
      ),
    },

    visitasRealizadasCriancas: {
      element: (
        <Item
          label="Visitas realizadas em crianças"
          name="visitasRealizadasCriancas"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    visitasRealizadasGestantes: {
      element: (
        <Item
          label="Visitas realizadas em gestantes"
          name="visitasRealizadasGestantes"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    regiao: {
      element: (
        <Item
          label="Região"
          name="regiao"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    numeroRegentesFormados: {
      element: (
        <Item
          label="Regentes Formados"
          name="numeroRegentesFormados"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    participantesPorPerfil: {
      element: (
        <>
          <Item
            label="Supervisores"
            name="supervisores"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Adis"
            name="adis"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Articuladores"
            name="articuladores"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Técnicos Creds"
            name="tecnicosCreds"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
          <Item
            label="Técnicos Seduc"
            name="tecnicosSeduc"
            rules={[{ required: true, message: 'Campo não preenchido' }]}
          >
            <InputNumber min={0} disabled={disabled} bordered={!disabled} />
          </Item>
        </>
      ),
    },
    tipoFormacao: {
      element: (
        <Item
          label="Tipo Formação"
          name="tipoFormacao"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Input type="text" disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    cargaHoraria: {
      element: (
        <Item
          label="Carga horária"
          name="cargaHoraria"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    numeroVisitasRegistradas: {
      element: (
        <Item
          label="Visitas registradas"
          name="numeroVisitasRegistradas"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    numeroFamiliasAtendidas: {
      element: (
        <Item
          label="Famílias atendidas"
          name="numeroFamiliasAtendidas"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    qtdCriancasAtendidas0a11Meses: {
      element: (
        <Item
          label="Crianças atendidas de 0 a 11 meses"
          name="qtdCriancasAtendidas0a11Meses"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },
    qtdCriancasAtendidas12a23Meses: {
      element: (
        <Item
          label="Crianças atendidas de 12 a 23 meses"
          name="qtdCriancasAtendidas12a23Meses"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    qtdCriancasAtendidas24a35Meses: {
      element: (
        <Item
          label="Crianças atendidas de 24 a 35 meses"
          name="qtdCriancasAtendidas24a35Meses"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    qtdCriancasAtendidas36a47Meses: {
      element: (
        <Item
          label="Crianças atendidas de 36 a 47 meses"
          name="qtdCriancasAtendidas36a47Meses"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    qtdCriancasAtendidasForaFaixaEtaria: {
      element: (
        <Item
          label="Crianças atendidas fora da faixa etária"
          name="qtdCriancasAtendidasForaFaixaEtaria"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <InputNumber min={0} disabled={disabled} bordered={!disabled} />
        </Item>
      ),
    },

    polos: {
      element: (
        <Item
          label="Informe a lista de polos"
          name="polos"
          rules={[{ required: true, message: 'Campo não preenchido' }]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            tokenSeparators={[',']}
            disabled={disabled}
            bordered={!disabled}
          />
        </Item>
      ),
    },
  };

  useEffect(() => {
    if (action) {
      for (var property in action) {
        valuesAcao.push(property);

        setValuesAcao([...valuesAcao]);
      }
    }
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  return valuesAcao.length > 0 ? (
    <FormBox title="" form={currentForm}>
      {valuesAcao.map((campos, index) => (
        <React.Fragment key={index}>{elemetos[campos]?.element}</React.Fragment>
      ))}
    </FormBox>
  ) : (
    <></>
  );
}
