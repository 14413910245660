import { Form, FormInstance } from 'antd'
import { FormContainer } from 'components/FormAction/style';
import React from 'react'
import { GestorDataFormEdit } from './EditForm/GestorDataFormEdit';
import { PersonalFormEdit } from './EditForm/PersonalFormEdit';
import { GestorDataForm } from './RegistroForm/GestorDataForm';
import { PersonalForm } from './RegistroForm/PersonalForm';

import "./styles.scss"
interface FormProps {
    form: FormInstance;
    children: React.ReactNode;
    finish: (props: any) => void;
    step: number;
    onChangeCpf: (cpf: string) => void
}

export function FormGestor({form, children, finish, step, onChangeCpf}: FormProps) {

    const maritalStatus = [
        {label: 'Casado', id: 'MARRIED'},
        {label: 'Solteiro', id: 'SINGLE'},
        {label: 'Divorciado', id: 'DIVORCED'},
        {label: 'Viúvo', id: 'WIDOWER'},
        {label: 'Separado Consensualmente', id: 'CONSENSUALLY_SEPARATED'},
        {label: 'Separado Judicialmente', id: 'JUDICIALLY_SEPARATED'},
        {label: 'Desquitado', id: 'SEPARATED'},
        {label: 'Outros', id: 'OTHERS'},
    ]
    return(
        <>
            <Form layout="vertical" form={form} onFinish={finish} >
                <div className='form-content'>
                    <FormContainer>
                        {step === 0 ?
                            <PersonalForm onChangeCpf={onChangeCpf} maritalStatus={maritalStatus}/>
                        :
                            <PersonalFormEdit maritalStatus={maritalStatus}/>
                        }
                        
                    </FormContainer>
                    <FormContainer>
                        {
                            step === 0 ?
                                <GestorDataForm />
                            :
                                <GestorDataFormEdit />
                        }
                    </FormContainer>
                </div>
                {children}
            </Form>

            
        </>
    )
}