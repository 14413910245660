import React, { useEffect, useMemo, useState } from 'react';
import 'antd/dist/antd.css';

import { SectionForm } from '../../components/FormArticulator/SectionForm';

import { Tabs, Card, Affix, message } from 'antd';
import { Container, Content, Body, CenteredContainer, HighlightedText } from './styles';
import { familiesService } from 'services';
import { JotformAnswer } from 'types';
import { useHistory, useParams } from 'react-router-dom';
import { GoBack, SimpleLoading } from 'components';
import { JotformAnswerHandler2 } from 'utils/jotformAnswerHandler';


const { TabPane } = Tabs;

const dataTabs = [
  {
    id: 1,
    tab: 'SEÇÃO 01',
  },
  {
    id: 2,
    tab: 'SEÇÃO 02',
  },
  {
    id: 3,
    tab: 'SEÇÃO 03'
  },
  {
    id: 4, 
    tab: 'SEÇÃO 04'
  },
  {
    id: 5,
    tab: 'SEÇÃO 05'
  },
];

function wordToLowerCase(word: string) {
  return word.toLowerCase();
}

function resolveAge(age: number, divisor=12) {
  const years = Math.floor(age / divisor);
  const monthes = age % divisor;

  const textMonth = divisor === 365 ? Math.floor(monthes / 30) : monthes;

  return `${years} ano(s) e ${textMonth} mes(es)`;
}


export function SearchArticulator() {
  const [formAnswers, setFormAnswers] = useState<JotformAnswer[]>([]);
  const [formLoading, setFormLoading] = useState(false);
  const [tabToRender, setTabToRender] = useState(0);

  const params = useParams() as { id: string };
  
  const familyNis = params.id;
  const history = useHistory();

  const sections = useMemo(() => {
    const sectionOne = formAnswers.filter(f => f.number >= 0 && f.number <= 33 && f.name!=='insiraUma');
    const sectionTwo = formAnswers.filter(f => f.number >= 34 && f.number <= 156)
      .map(a => {
        if(wordToLowerCase(a.question).includes('idade')) {
          return {
            ...a,
            answer: resolveAge(Number(a.answer), wordToLowerCase(a.question).includes('anos') ? 365 : 12)
          }
        }
        return a;
      });
    const sectionThree = formAnswers.filter(f => f.number >= 157 && f.number <= 178);
    const sectionFour = formAnswers.filter(f => f.number >= 179 && f.number <= 222);
    const sectionFive = formAnswers
      .filter(f => f.number >= 223)
      .map(a => {
        if(a.name==='aoTodo') {
          return {
            ...a,
            answer: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(a.answer))
          }
        }
        return a;
      });

    return {
      0: sectionOne,
      1: sectionTwo,
      2: sectionThree,
      3: sectionFour,
      4: sectionFive
    }
  }, [formAnswers]);

  function handleChangeTab(tabIndex: number) {
    if (typeof window === undefined) {
      return;
    }

    window.scrollTo(0, 0);
    setTabToRender(tabIndex);
  }

  function renderForm(tabIndex: number) {
    const curSection = sections[tabIndex];
    return (
      <SectionForm
        answers={curSection}
        currentSection={`Seção 0${tabIndex+1}`}
      />
    )
  }

  useEffect(() => {
    setFormLoading(true);
    familiesService.getFamilyFormByNis(familyNis)
      .then((data: { answers: JotformAnswer[] }) => {
        const answers = data?.answers;
        if(answers) {
          const handledAnswers = JotformAnswerHandler2(answers)
            .sort((a, b) => a.number - b.number);
          
          setFormAnswers(handledAnswers);
        }
      })
      .catch((err: any) => {
        message.error('Erro ao carregar respostas do formulário!')
      })
      .finally(() => setFormLoading(false));
  }, [familyNis])
  
  return (
    <Container >
      <Content>
        <GoBack 
          onClick={() => history.goBack()}
          title="Formulários"
          subtitle="Respostas formulário JotForm"
        />

        <Affix offsetTop={0}>
          <Tabs defaultActiveKey='1' centered tabBarStyle={{ overflowX: 'scroll' }}>
            {dataTabs.map((d, i) => (
              <TabPane disabled={formAnswers.length===0} tab={
                <button 
                  type='button' 
                  onClick={() => handleChangeTab(i)}
                >
                  {d.tab}
                </button>
                } 
                key={d.id}
              />                
            ))}

          </Tabs>
        </Affix>
        <Body >
          <Card style={{ zIndex: 10 }} >
            {!formLoading ? (
              formAnswers.length===0 ? (
                <CenteredContainer>
                  <HighlightedText>
                    Não há formulário associado!
                  </HighlightedText>
                </CenteredContainer>
              ) : 
              (renderForm(tabToRender))
            ) : (
              <CenteredContainer>
                <SimpleLoading tip="Carregando formulários, aguarde" size="large"/>
              </CenteredContainer>
            )}
          </Card>
        </Body>
      </Content>
    </Container> 
  );

}
