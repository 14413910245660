import { meses, yearsNames } from 'pages/NewRegisterAction/utils/inputs';
import { citiesNamesCE } from 'utils/citiesNames';


const visitaMunicipioInfos = {
  columns: 2,
  fields: [
    {
      key: 'visitaMunicipio.mes',
      label: 'Mês',
      widget: 'select',
      options: meses,
      viewWidget: 'simple-view',
    },
    {
      key: 'visitaMunicipio.ano',
      label: 'Ano',
      widget: 'select',
      options: yearsNames,
      rules: [
        {
          required: true,
          message: 'O campo Ano é obrigatório',
        },
      ],
    },
    {
      key: 'visitaMunicipio.municipio',
      label: 'Município',
      widget: 'select',
      options: citiesNamesCE,
      viewWidget: 'simple-view',
      widgetProps: { showSearch: true }
    },
    {
      key: 'visitaMunicipio.qtdVisitasRegistradas',
      label: 'Quantidade de visitas registradas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'visitaMunicipio.qtdFamiliasAtendidas',
      label: 'Quantidade de famílias atendidas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'visitaMunicipio.qtdCriancasAtendidas0a11Meses',
      label: 'Quantidade de crianças atendidas de 0 a 11 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'visitaMunicipio.qtdCriancasAtendidas12a23Meses',
      label: 'Quantidade de crianças atendidas de 12 a 23 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'visitaMunicipio.qtdCriancasAtendidas24a35Meses',
      label: 'Quantidade de crianças atendidas de 24 a 35 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'visitaMunicipio.qtdCriancasAtendidas36a47Meses',
      label: 'Quantidade de crianças atendidas de 36 a 47 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'visitaMunicipio.qtdCriancasAtendidasForaFaixaEtaria',
      label: 'Quantidade de crianças atendidas fora da faixa etária',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ],
};

// const formacaoInfos = {
//   fields: [
//     {
//       key: 'formacao.periodo.dataInicio',
//       label: 'Data de início',
//       widget: 'date-picker',
//       viewWidget: 'date-view',
//       placeholder: "Selecione a data",
//       rules: [
//         {
//           required: true,
//           message: 'O campo Data início é obrigatório',
//         },
//       ],
//     },
//     {
//       key: 'formacao.periodo.dataFim',
//       label: 'Data fim',
//       widget: 'date-picker',
//       placeholder: "Selecione a data",
//       viewWidget: 'date-view',
//       rules: [
//         {
//           required: true,
//           message: 'O campo Data fim é obrigatório',
//         },
//       ],
//     },
//     {
//       key: 'formacao.tipoFormacao',
//       label: 'Tipo de formação',
//       viewWidget: 'simple-view',
//     },
//     {
//       key: 'formacao.cargaHoraria',
//       label: 'Carga horária',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.qtdFormadores',
//       label: 'Quantidade de formadores',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.participantesPorPerfil.supervisores',
//       label: 'Supervisores',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.participantesPorPerfil.adis',
//       label: 'ADIs',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.participantesPorPerfil.articuladores',
//       label: 'Articuladores regionais mais infância',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.participantesPorPerfil.tecnicosCreds',
//       label: 'Técnicos de CRED',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.participantesPorPerfil.tecnicosSeduc',
//       label: 'Técnicos SEDUC',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
  
//     {
//       key: 'formacao.localizacao.municipio',
//       label: 'Município',
//       widget: 'select',
//       options: citiesNamesCE,
//       viewWidget: 'simple-view',
//     },
//     {
//       key: 'formacao.localizacao.rua',
//       label: 'Rua',
//       viewWidget: 'simple-view',
//     },
//     {
//       key: 'formacao.localizacao.bairro',
//       label: 'Bairro',
//       viewWidget: 'simple-view',
//     },
//     {
//       key: 'formacao.localizacao.cep',
//       label: 'CEP',
//       widget: 'number',
//       viewWidget: 'cep-view',
//       rules: [
//         {
//           required: true,
//           message: 'O campo CEP é obrigatório',
//         },
       
//       ],
//     },
//     {
//       key: 'formacao.localizacao.numero',
//       label: 'Número',
//       widget: 'number',
//       viewWidget: 'simple-view',
//       widgetProps: { min: 0 },
//     },
//     {
//       key: 'formacao.localizacao.pontoDeReferencia',
//       label: 'Ponto de referência',
//       viewWidget: 'simple-view',
//     },
//     {
//       key: 'formacao.localizacao.estacao',
//       label: 'Estação',
//       viewWidget: 'simple-view',
//     },

//   ],
// };
export function padin() {
  return [
    // { title: 'Tipo', value: tipo },
    // { title: 'Formação', value: formacaoInfos },
    { title: 'Visita município', value: visitaMunicipioInfos },
 
  ];
}
