import { yearsNames } from 'pages/NewRegisterAction/utils/inputs';
import { citiesNamesCE } from 'utils/citiesNames';

import { regioesPlanejamento } from '../../../../utils/regioesplanejamento'

const regioesPlanejamentoNames = regioesPlanejamento.map(regiao => {
  return regiao.regiao
})


const tipo = {
  fields: [
    {
      key: 'tipo',
      label: 'Selecione o tipo desejado: ',
      widget: 'radio-group',
      options: [
        ['Evento regular', 'Evento regular'],
        ['Registro acumulado', 'Registro acumulado'],
        ['Registro capacitação', 'Registro capacitação'],
      ],
      initialValue: 'Evento regular',
      viewWidget: 'simple-view',
    },
  ],
};


const eventoRegular = {
  fields: [
    {
      key: 'eventoRegular.mes',
      label: 'Mês',
      widget: 'select',
      options: [
        [1, 'Janeiro'],
        [2,'Fevereiro'],
        [3,'Março'],
        [4,'Abril'],
        [5,'Maio'],
        [6,'Junho'],
        [7,'Julho'],
        [8,'Agosto'],
        [9,'Setembro'],
        [10,'Outubro'],
        [11,'Novembro'],
        [12,'Dezembro']
      ],
      viewWidget: 'simple-view',
    },
    {
      key: 'eventoRegular.ano',
      label: 'Ano',
      widget: 'select',
      options: yearsNames,
    },
    {
      key: 'eventoRegular.municipio',
      label: 'Município',
      widget: 'select',
      options: citiesNamesCE,
      viewWidget: 'simple-view',
      widgetProps: { showSearch: true } 
    },
    {
      key: 'eventoRegular.numeroVisitasRegistradas',
      label: 'Número de visitas registradas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'eventoRegular.numeroFamilias',
      label:
        'Número de famílias atendidas pelo PCF',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'eventoRegular.qtdCriancasDe0a36meses',
      label: 'Quantidade de crianças de 0 a 36 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'eventoRegular.qtdCriancasDe37a72meses',
      label: 'Quantidade de crianças de 37 a 72 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'eventoRegular.qtdGestantesBeneficiadas',
      label: 'Quantidade de gestantes beneficiadas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ],
};

const registroAcumulado = {
  fields: [
    {
      key: 'registroAcumulado.periodo.dataInicio',
      label: 'Período início',
      widget: 'date-picker',
      viewWidget: 'date-view',
      placeholder: 'Selecione a data',
    },
    {
      key: 'registroAcumulado.periodo.dataFim',
      label: 'Período fim',
      widget: 'date-picker',
      viewWidget: 'date-view',
      placeholder: 'Selecione a data',
    },

    {
      key: 'registroAcumulado.numeroVisitasRegistradas',
      label: 'Número de visitas registradas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroAcumulado.numeroFamilias',
      label: 'Número de famílias',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroAcumulado.qtdCriancasDe0a36meses',
      label: 'Quantidade de crianças de 0 a 36 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroAcumulado.qtdCriancasDe37a72meses',
      label: 'Quantidade de crianças de 37 a 72 meses',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroAcumulado.qtdGestantesBeneficiadas',
      label: 'Quantidade de gestantes beneficiadas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ],
};

const registroCapacitacao = {
  fields: [
    {
      key: 'registroCapacitacao.periodo.dataInicio',
      label: 'Período início',
      widget: 'date-picker',
      viewWidget: 'date-view',
      placeholder: 'Selecione a data',
    },
    {
      key: 'registroCapacitacao.periodo.dataFim',
      label: 'Período fim',
      widget: 'date-picker',
      viewWidget: 'date-view',
      placeholder: 'Selecione a data',
    },

    {
      key: 'registroCapacitacao.perfilPublico',
      label: 'Perfil público',
      viewWidget: 'simple-view',
    },
    {
      key: 'registroCapacitacao.qtdPublico',
      label: 'Quantidade de público',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroCapacitacao.temaCapacitacao',
      label: 'Tema capacitação',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroCapacitacao.descricao',
      label: 'Descrição',
      widget: 'textarea',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'registroCapacitacao.municipios',
      label: 'Municípios participantes',
      widget: 'select',
      options: citiesNamesCE,
      widgetProps: { mode: 'multiple', allowClear: true },
      viewWidget: 'list-view',
    },
    {
      key: 'registroCapacitacao.regioes',
      label: 'Regiões',
      widget: 'select',
      options: regioesPlanejamentoNames,
      widgetProps: { mode: 'multiple', allowClear: true },
      viewWidget: 'list-view',
    },
  ],
};
export function criancaFeliz() {
  return [
    { title: 'Tipo', value: tipo },
    { title: 'Evento regular', value: eventoRegular },
    { title: 'Registro acumulado', value: registroAcumulado },
    { title: 'Registro capacitação', value: registroCapacitacao },
  ];
}
