import React from 'react'

import './styles.scss';

type listInfos = {
  id: number,
  title: JSX.Element,
  number: string | JSX.Element
}

interface CardInfosPandemicProps {
  icon: string,
  listFamilies: listInfos[],
  listChilds: listInfos[],
}

export const CardInfosPandemic = ({
    icon,
    listFamilies,
    listChilds
  }: CardInfosPandemicProps): JSX.Element => {
    
  return(
    <section className="container__CardInfosPandemic" >  
      <div className="icon-card">
        <img src={icon} alt="Card icon" />
      </div>
      <div className="container__infos-families">
        <h1>Famílias na pandemia</h1>
        <ul>
          { listFamilies.map(info => {
            return (
              <li key={ info.id }>
                <div className="li-container">
                  <h2>{info.number}</h2>
                  <p>%</p>
                </div>
                <p className="title">{ info.title }</p>
              </li>
            );
          }) }
        </ul>
      </div>
      
      <div className="container__infos-childs">
        <h1>Crianças na pandemia</h1>
        <ul>
          { listChilds.map(info => {
            return (
              <li key={ info.id }>
                <div className="li-container">
                  <h2>{ info.number }</h2>
                  <p>%</p>
                </div>
                <p className="title">{ info.title }</p>
              </li>
            );
          }) }
        </ul>
      </div>
    </section>
  )
}