import NavigationCMIC from 'components/NavigationCMIC';
import React, { useEffect, useState } from 'react';

import { Breadcrumb, Device, Footer } from 'components';
import { TitlePage } from 'components/TitlePage';
import SectionInfosCMIC from 'components/SectionInfosCMIC';
import './styles.scss';
import SectionInfoYellow from 'components/SectionInfoYellow';
import { CardMediumGeral, CardMediumImage } from 'components/CardsCMIC';

import domicilioIconImg from '../../assets/CMIC/domicilio/domicilio-icon.svg';
import cartaoIconImg from '../../assets/CMIC/domicilio/cartao-icon.svg';
import childHandIconImg from '../../assets/CMIC/domicilio/child-hand.svg';
import homeHandIconImg from '../../assets/CMIC/domicilio/home-hand.svg';
import raiaIconImg from '../../assets/CMIC/domicilio/raia-icon.svg';
import peoplesIconImg from '../../assets/CMIC/domicilio/peoples-icon.svg';
import zonaUrbanaIconImg from '../../assets/CMIC/domicilio/zona-urbana-icon.svg';
import zonaRuralIconImg from '../../assets/CMIC/domicilio/zona-rural-icon.svg';
import soltandoPipaImg from '../../assets/CMIC/domicilio/soltando-pipa.svg';
import torneiraIconImg from '../../assets/CMIC/domicilio/torneira-icon.svg';
import vasoIconImg from '../../assets/CMIC/domicilio/vaso-icon.svg';
import paredeIconImg from '../../assets/CMIC/domicilio/parede-icon.svg';
import girlImg from '../../assets/CMIC/domicilio/girl.svg';
import covidIconImg from '../../assets/CMIC/domicilio/covid-icon.svg';
import wifiEnableIconImg from '../../assets/CMIC/domicilio/wifi-enable-icon.svg';
import wifiDisableIconImg from '../../assets/CMIC/domicilio/wifi-disable-icon.svg';
import homeIconImg from '../../assets/CMIC/domicilio/home-icon.svg';

import { CardInfo } from './CardInfos';
import { CardDoubleInfos } from './CardDoubleInfos';
import { CardInfosPandemic } from './CardInfosPandemic';
import EstimatedCard from 'components/EstimatedCard';
import { getResidenceData } from 'services/resources/residenceService';

import findData from 'utils/findAndFormatCMIC';
import InfoProvide from 'components/InfoProvide';

type DomicilioQuestion = {
  question: string;
  answer: string;
};

const DomicilioCMIC = (): JSX.Element => {
  const [dataDomicilio, setDataDomicilio] = useState<Array<DomicilioQuestion>>();

  const device = Device.useDevice();


  useEffect(() => {
    const response = async () => {
      const data = await getResidenceData();
      setDataDomicilio(data);

    };
    response().catch(() => setDataDomicilio([]));
  }, []);

  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/pesquisa-cmic',
      title: 'Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará',
    },
    {
      path: '/pesquisa-cmic/domicilio',
      title: 'Domicilio',
    },
  ];

  const accompaniedChildren = [
    {
      number: findData(
        'A criança participa de algum programa de visitação domiciliar? - PADIN',
        dataDomicilio,
      ),
      title: 'Programa de Apoio ao Desenvolvimento Infantil (PADIN)',
    },
    {
      number: findData(
        'A criança participa de algum programa de visitação domiciliar? - Criança feliz',
        dataDomicilio,
      ),
      title: 'Criança Feliz',
    },
    {
      number: findData(
        'A criança participa de algum programa de visitação domiciliar? - Cresça com o seu filho',
        dataDomicilio,
      ),
      title: 'Cresça com o seu filho',
    },
    {
      number: findData(
        'A criança participa de algum programa de visitação domiciliar? - Não',
        dataDomicilio,
      ),
      title: 'Nâo participam de algum programa de visitação domiciliar',
    },
  ];

  const houseStructure = [
    {
      number: findData(
        'Qual é o material predominante na construção das paredes externas no domicílio? - Alvenaria',
        dataDomicilio,
      ),
      title: 'Alvenaria com ou sem revestimento',
    },
    {
      number: findData(
        'Qual é o material predominante na construção das paredes externas no domicílio? - Taipa',
        dataDomicilio,
      ),
      title: 'Taipa',
    },
    {
      number: findData(
        'Qual é o material predominante na construção das paredes externas no domicílio? - Outros',
        dataDomicilio,
      ),
      title: 'Outro material',
    },
  ];

  const listFamilies = [
    {
      id: 0,
      number: findData(
        'Desde que a pandemia começou você levou seu filho para se vacinar? - Não',
        dataDomicilio,
      ),
      title: (
        <>
          <strong>não</strong> levaram seu(s) filho(s) para se vacinar
        </>
      ),
    },
    {
      id: 1,
      number: findData(
        'O agente comunitário de saúde visitou a casa da família durante o período de pandemia? - Não',
        dataDomicilio,
      ),
      title: (
        <>
          {' '}
          <strong>não</strong> foram visitadas pelo agente comunitário de saúde
        </>
      ),
    },
  ];

  const listChilds = [
    {
      id: 0,
      number: findData(
        'A criança foi acompanhada, durante a pandemia, pela equipe da saúde da família no posto de saúde? - Não',
        dataDomicilio,
      ),
      title: (
        <>
          {' '}
          <strong>não</strong> foram acompanhadas pela equipe do Saúde da
          Família no posto de saúde
        </>
      ),
    },
  ];

  return (
    <>
      <section className="container-box">
        <section className="container__domicilio">
          <div className="container__domicilio-header">
            <Breadcrumb links={links} />
            <TitlePage style={{ margin: '0' }} title="Domicílio" backButton />
          </div>
          <InfoProvide 
            message="Os dados abaixo foram coletados do Big Data Social a partir da base da Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará" 
            type="cemic"
          />
          <section className={`container__domicilio-center ${
              device.isMD('less') && 'isXS'
            }`}>
              

            <SectionInfoYellow
              image={domicilioIconImg}
              title="Benefícios recebidos e programas de acompanhamento"
            >
              <ul className="center__saude-cards">
                <li>
                  <CardMediumGeral
                    text={
                      <p>
                        No Ceará, 35,9% das famílias recebem Bolsa Família. Fonte: IPECE, 2020.
                      </p>
                    }
                  >
                    <div className="bolsa-familia-cmic">
                      <div className="cards-icon">
                        <div className="card">
                          <img
                            src={cartaoIconImg}
                            alt="Cartão do Bolsa Família"
                          />
                          <p>Bolsa Família</p>
                        </div>
                        <strong>+</strong>
                        <div className="card">
                          <img src={cartaoIconImg} alt="Cartão CMIC" />
                          <p>CMIC</p>
                        </div>
                      </div>
                      <div className="porcent">
                        <h2>
                          {findData(
                            'A sua família recebe ou recebeu algum outro benefício do governo do Estado durante esse período de pandemia?',
                            dataDomicilio,
                          )}
                        </h2>
                        <p>%</p>
                      </div>
                    </div>
                  </CardMediumGeral>

                  <CardMediumGeral
                    text="Ao todo nos 24 municípios, são 92% das crianças*. Fonte: Datapedia, 2020. *Média obtida para os 24 municípios da pesquisa."
                    style={{ marginTop: '2rem' }}
                  >
                    <div className="saude-familia">
                      <img src={childHandIconImg} alt="Criaça na mão" />
                      <div className="infos">
                        <p>Crianças acompanhadas pelo Saúde da Família</p>
                        <div className="porcent">
                          <h2>
                            {findData(
                              'A criança foi acompanhada, durante a pandemia, pela equipe da saúde da família no posto de saúde? - Sim',
                              dataDomicilio,
                            )}
                          </h2>
                          <p>%</p>
                        </div>
                      </div>
                    </div>
                  </CardMediumGeral>
                </li>

                <li>
                  <CardMediumImage
                    iconImage={homeHandIconImg}
                    title="Crianças acompanhadas por programas de visitação domiciliar"
                    listInfos={accompaniedChildren}
                  />
                </li>
              </ul>
            </SectionInfoYellow>
            <div className="info-right">
              <CardInfosPandemic
                icon={covidIconImg}
                listFamilies={listFamilies}
                listChilds={listChilds}
              />

              {
                device.isMD('bigger') && (
                  <div className="image">
                    <img src={girlImg} alt="Menina" />
                  </div>
                )
              }
            </div>
          </section>

          <SectionInfosCMIC
            title="Localização do domicílio"
            subtitle="Domicílio"
          >
            <ul className={`center__saude-cards ${
              device.isMD('less') && 'isXS'
            }`}>
              <li>
                <CardInfo
                  image={raiaIconImg}
                  title="Famílias que respondem não ter lugares públicos nas redondezas para lazer das crianças"
                  number={findData(
                    'Existem lugares públicos (praças, parques, etc) para as crianças brincarem próximo a sua casa? - Não',
                    dataDomicilio,
                  )}
                />
              </li>
              <li>
                <CardInfo
                  image={peoplesIconImg}
                  title="Famílias que respondem existir muito conflito ou violência na sua comunidade"
                  number={findData(
                    'Existe muito conflito/violência na comunidade em que você mora? - Sim',
                    dataDomicilio,
                  )}
                />
              </li>
              <li>
                <CardDoubleInfos
                  infosLeft={{
                    image: zonaUrbanaIconImg,
                    title: 'Zona Urbana',
                    number: findData('Mora na zona: - Urbana', dataDomicilio),
                  }}
                  infosRight={{
                    image: zonaRuralIconImg,
                    title: 'Zona Rural',
                    number: findData('Mora na zona: - Rural', dataDomicilio),
                  }}
                />
              </li>
            </ul>
          </SectionInfosCMIC>
          <section className="container__domicilio-footer">
            <h1 className="title-footer">Infraestrutura do domicílio</h1>
            <ul className={`center__saude-cards ${
              device.isMD('less') && 'isXS'
            }`}>
              <li>
                <EstimatedCard
                  iconEnable={wifiEnableIconImg}
                  iconDisable={wifiDisableIconImg}
                  title="domicílios possuem internet"
                  number={
                    findData('Possui internet em casa?', dataDomicilio)
                  }
                  iconWifi={homeIconImg}
                />
                <CardInfo
                  image={torneiraIconImg}
                  title="Domicílios que não possuem água canalizada
                  (em, pelo menos, 1 cômodo)"
                  number={findData(
                    'O domicílio possui água canalizada em pelo menos um cômodo? - Não',
                    dataDomicilio,
                  )}
                />
              </li>
              <li>
                <CardInfo
                  image={vasoIconImg}
                  title="Domicílios que não possuem banheiro ou sanitário"
                  number={findData(
                    'No domicílio, existe banheiro ou sanitário? - Não',
                    dataDomicilio,
                  )}
                />
                <CardMediumImage
                  iconImage={paredeIconImg}
                  title="Estrutura de construção da moradia"
                  listInfos={houseStructure}
                />
              </li>
              {
                device.isMD('bigger') && (
                  <li>
                    <img src={soltandoPipaImg} alt="Soltando pipa" />
                  </li>
                )
              }
            </ul>
          </section>
        </section>
      </section>
      <NavigationCMIC type="domicilio" />
      <Footer />
    </>
  );
};

export default DomicilioCMIC;
