import React from "react";
import { useHistory } from "react-router-dom";

import MdChevronLeft from '../../assets/backButton/md-chevron-left.svg';

// Interface para o props do BackButton
type BackButtonProps = {
  size: number,
}

export const BackButton = ({ size }: BackButtonProps) => {
  const history = useHistory();

  // Estilos do botão 
  const styleButton = {
    cursor: 'pointer',
    paddingRight: '1rem',
    widht: size
  }

  return (
    <div onClick={ history.goBack } style={ styleButton }> 
      <img src={ MdChevronLeft } alt="Voltar" />
    </div>
  )
}