import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import { citiesNamesCE } from 'utils/citiesNames';

const generalInfos = {
  fields: [
    {
      key: 'nome',
      label: 'Nome',
      required: true,
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'edicao',
      label: 'Edição',
      rules: [
        {
          required: true,
          message: 'O campo Edição é obrigatório',
        },
      ],
      viewWidget: 'simple-view',
    },
    {
      key: 'dataLancamento',
      label: 'Data lançamento',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'referencias',
      label: 'Referências bibliográficas',
      widget: 'select',
      widgetProps: { mode: 'tags' },
      viewWidget: 'list-view',
    },
  ],
};

const localLancamentoInfos = {
  fields: [
    {
      key: 'localLancamento.municipio',
      label: 'Município',
      widget: 'select',
      options: citiesNamesCE,
      viewWidget: 'simple-view',
      widgetProps: { showSearch: true } 
    },
    {
      key: 'localLancamento.rua',
      label: 'Rua',
      rules: [
        {
          required: true,
          message: 'O campo Numero é obrigatório',
        },
      ],
    },
    {
      key: 'localLancamento.bairro',
      label: 'Bairro',
      rules: [
        {
          required: true,
          message: 'O campo Bairro é obrigatório',
        },
      ],
    },
    {
      key: 'localLancamento.cep',
      label: 'CEP',
      rules: [
        {
          required: true,
          message: 'O campo CEP é obrigatório',
        },
        {
          validator: (rule, value, callback) => {
            return new Promise<void>((resolve, reject) => {
              if (!isValidCEP(value)) {
                reject(new Error('CEP inválido.'));
              } else {
                resolve();
              }
            });
          },
        },
      ],
    },
    {
      key: 'localLancamento.numero',
      label: 'Número',
      widget: 'number',
      rules: [
        {
          required: true,
          message: 'O campo Numero é obrigatório',
        },
      ],
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'localLancamento.pontoDeReferencia',
      label: 'Ponto de referencia',
      viewWidget: 'simple-view',
    },
    {
      key: 'localLancamento.estacao',
      label: 'Estação',
      viewWidget: 'simple-view',
    },
  ],
};

export function lancamentoLivro() {
  return [
    { title: 'Informações geral', value: generalInfos },
    { title: 'Local de lançamento', value: localLancamentoInfos },
  ];
}
