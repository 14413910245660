import React from 'react';

import { Collapse } from 'antd';

import './styles.scss';

interface ListPolosPadinProps {
  polosSelect: Array<any>;
}
function ListPolosPadin({ polosSelect }) {
  const { Panel } = Collapse;

  return (
    <div className="container-ListPolosPadin">
      <Collapse accordion>
        {polosSelect.map((polo, index) => {
          return (
            <Panel
              header={`Municípios beneficiados pelo polo de ${polo.polo}`}
              key={index}
            >
              {polo.municipios?.map((municipio, i) => {
                
                if(i+1 === polo.municipios.length) return <span key={i}>{municipio}.</span>;
                  
                return <span key={i}>{municipio}, </span>;
                  
              })}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

export default ListPolosPadin;
