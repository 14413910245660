import React, { useEffect, useState } from 'react'
import { Input, Divider, Upload, Select } from 'antd';
import './styles.scss'
import cearaMunicipios from '../../data/ceara-full.json'
const { Dragger } = Upload;
const { Option } = Select;

/* interface um evento de obra */
interface IEventObra {
  atividades: {
    apresentacoesDoCircoEscola: number,
    edicao: number,
    exibicoesRealizadas: number,
    oficinasRealizadas: number,
    palestrasRealizadas: number,
    programacao: number
  },

  datas: {
    dataEntrega: string,
    dataFim: string,
    dataInalguracao: string,
    dataInicio: string,
    previsaoEntrega: string
  },
  docs: {
    documento: string,
    imagem: string
  },
  id: string,
  localizacao: {
    enderecoCompleto: string,
    estacao: string,
    latitude: number,
    longitude: number,
    municipio: string,
    regiaoPlanejamento: string
  },
  nome: string,
  pessoas: {
    criancasAtendidas: number,
    criancasFormadas: number,
    criancasVisitadas: number,
    familiasAcompanhadas: number,
    familiasBeneficiadas: number,
    familiasBeneficiadasMes: number,
    familiasVisitadas: number,
    gestantesVisitadas: number,
    participantes: number,
    pessoasAtendidasPorFaixaEtaria: {
      adultos: number,
      entre12e19: number,
      idosoMaiorQue60: number,
      menoresDe6: number
    },
    pessoasBeneficiadas: number,
    pessoasCapacitadas: number,
    pessoasImpactadas: number
  },
  recursos: {
    dvdsEntregues: number,
    instrumentosDoados: number,
    mudasFrutiferas: number,
    mudasNativas: number,
    toneladasDeAlimentosDoados: number,
    toneladasDeAlimentosRecebidos: number,
    totalInvestido: number
  },
  status: string,
  ultimaModificacao: string,
  descricao: string
}

type RegistrosObrasProps = {
  event: IEventObra;
}


const FormBrinquedopraca = ({ event }: RegistrosObrasProps ): JSX.Element => {


  const [infoDataObra, setInfoDataObra] = useState({
    nome: '',
    municipio: '',
    endereco: '',
    numero: '',
    bairro: '',
    cep: '',
    foto: '',
  })

  useEffect(() => {
    setInfoDataObra({
      nome: event?.nome,
      municipio: event?.localizacao.municipio?.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase()),
      endereco: '',
      numero: '',
      bairro: '',
      cep: '',
      foto: event?.docs.imagem,
    })

  }, [event])
  

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  function maskCEP(value: string) {
      return value
          ?.replace(/\D/g, '')
          ?.replace(/(\d{5})(\d)/, '$1-$2')
  }

  function maskNumber(value: string) {
    return value
        ?.replace(/\D/g, '')
  }

  function onChangeCEP(value) {
    value?.replace(/\D/g, '')
         ?.replace(/(\d{5})(\d)/, '$1-$2')
    setInfoDataObra({...infoDataObra, cep: value })
  }

  const UploadImage = async (e: any) => {
    const file = e.file
    const base64 = await getBase64(file.originFileObj)
    let strBase64 = String(base64)
    setInfoDataObra({...infoDataObra, foto: strBase64 })

  }

  const onChangeSelect = (municipio) => {
    setInfoDataObra({...infoDataObra, municipio: municipio })
  }
  
  return(
    <div>
      <div className="form-block-content">
        <div className="input-name"><label>Nome</label></div>
        <Input value={infoDataObra.nome} onChange={(e) => setInfoDataObra({...infoDataObra, nome: e.target.value })} style={{width: '34em'}} placeholder="Ex: Praça Luís Gonzaga, Praça da Igreja Matriz."/>
        <p style={{font: '700 1.4rem Nunito', color: '#BFBFBF', marginTop: '.4em'}}>Orientação: Praça + Nome</p>
      </div>

      <div className="form-block-content">
        <div className="input-name"><label>Município</label></div>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Selecione um município"
          optionFilterProp="children"
          onChange={onChangeSelect}
          value={infoDataObra.municipio}
          filterOption={(input, option) => {
            const optionValue = option?.value?.toString().toLowerCase() ?? '';

            return optionValue.indexOf(input.toLowerCase()) >= 0;
          }
        }
        >
          {cearaMunicipios.features.map(municipio => 
            <Option value={municipio.properties.name}>{municipio.properties.name}</Option>
          )}
        </Select>
      </div>

      <div className="divisor-form">
        <Divider orientation="left">Localização</Divider>
      </div>
            
      <div className="localizacao-input">
        <div className="form-block-content">
          <div className="input-name"><label>Endereço</label></div>
          <Input value={infoDataObra.endereco} onChange={(e) => setInfoDataObra({...infoDataObra, endereco: e.target.value })} placeholder="Ex: Rua da Saudade."/>
        </div>
        
        <div className="form-block-content">
          <div className="input-name"><label>Número</label></div>
          <Input value={maskNumber(infoDataObra.numero)} onChange={(e) => setInfoDataObra({...infoDataObra, numero: maskNumber(e.target.value) })}/>
        </div>

        <div className="form-block-content"> 
          <div className="input-name"><label>Bairro</label></div>
          <Input value={infoDataObra.bairro} onChange={(e) => setInfoDataObra({...infoDataObra, bairro: e.target.value })}/>
        </div>

        <div className="form-block-content">
          <div className="input-name"><label>CEP</label></div>
          <Input onChange={(e) => onChangeCEP(e.target.value)} value={maskCEP(infoDataObra.cep)} maxLength={9} placeholder="_____-___"/>
        </div>
      </div>
      <div className="form-block-content">
      <div className="input-name"><label>Foto da Obra <small>(Formatos suportados: .jpeg, .png, .tif)</small></label></div>
        <Dragger   
          onChange={UploadImage}
          accept="image/png, image/jpeg">

          <div className="upload-form">
            <div><label>Arraste e solte uma foto aqui</label></div>
            <span>CARREGAR FOTO</span>
          </div>
        </Dragger>
      </div>
      </div>
  )
}

export default FormBrinquedopraca;