import React from 'react';
import { Steps } from 'components';
import "./styles.scss"

interface StepPanelProps {
  step: number;
}

const coordinatorSteps = ['Informações gerais', 'Confirmação'];

export function StepPanel({ step }: StepPanelProps) {

  return (
    <div className="StepPanel">
      <Steps current={step} steps={coordinatorSteps} />
    </div>
  )
}