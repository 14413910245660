import { Rule } from 'rc-field-form/lib/interface';

const rules: Record<string, Rule[]> = {
  periodo: [
   {
    required: true,
    message: "Período não preenchido"
   }
  ],
  nome: [
    {
      required: true,
      message: "Nome não preenchido"
    }
  ]
}

export default rules;