import React, { Dispatch, SetStateAction } from 'react';
import { Button, ButtonProps, Input, Radio, Space } from 'antd';

import { Container } from './styles';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { ActivityType } from 'types';
import { SimpleLoading } from '../SimpleLoading';

interface CreateActivityBoxProps {
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  observation: string;
  setObservation: Dispatch<SetStateAction<string>>;
  startTime: string;
  setStartTime: Dispatch<SetStateAction<string>>;
  endTime: string;
  setEndTime: Dispatch<SetStateAction<string>>;
  onSaveButtonClick: () => void;
  dateClicked: moment.Moment | undefined;
  onClose?: () => void;
  isClosable?: boolean;
  isLoading?: boolean;
  activityType: ActivityType[];
}

const { TextArea } = Input;

export function CreateActivityBox({ 
  type,
  setType,
  observation,
  setObservation,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  onSaveButtonClick,
  dateClicked,
  onClose,
  isClosable=false,
  isLoading=false,
  activityType,
 }: CreateActivityBoxProps) {

  return (
    <Container>
      {activityType.length > 0 ? (
        <>
        <div className="title-close-content">
        <strong className="overlay-title">Criar nova atividade</strong>

        {isClosable && (
          <button 
            type="button" 
            onClick={onClose}
            className="close-family-container-button"
          >
            x
          </button>
        )}
      </div>
      <div className="overlay-container">
        <div className="overlay-button-group">
          <strong>Atividade</strong>
          <Space direction="vertical">
            <Radio.Group  value={type} onChange={e => setType(e.target.value)}>
              {activityType.map((activity) => (
                <Radio
                  value={activity.id}
                  key={activity.id}
                >
                  {activity.name}
                </Radio>
              ))}
            </Radio.Group>
          </Space>
        </div>
        
        <div className="date-overlay-info">
          <AiOutlineClockCircle size={24} color="gray"/>
          <strong style={{ color: 'gray' }}>{dateClicked?.format('DD/MM/YYYY')}</strong>
          <Input 
            type="time"
            style={{ width: '40%' }} 
            value={startTime} 
            onChange={e => setStartTime(e.target.value)}
          />
          <Input 
            type="time"
            style={{  width: '40%' }} 
            value={endTime} 
            onChange={e => setEndTime(e.target.value)}
          />
        </div>
        <div className="overlay-observation">
          <strong>Observação</strong>
          <TextArea value={observation} onChange={e => setObservation(e.target.value)} style={{ width: '100%' }}/>
        </div>

        <ButtonGreen
          htmlType="button"
          onClick={onSaveButtonClick}
          loading={isLoading}
        >
          Salvar
        </ButtonGreen>
      </div>
      </>
      ) : (
        <SimpleLoading tip="Carregando as informações, por favor aguarde!"/>
      )}
    </Container>
  );
}

const ButtonGreen = ({ ...rest }: ButtonProps) => (
  <Button
    {...rest}
    style={{
      background: '#4B9F37',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 7,
      color: '#FFF',
      textTransform: 'uppercase',
      border: 0,
      fontFamily: 'Nunito, sans-serif',
      fontSize: '1.6rem',

      fontWeight: 'bold',
    }}
  />
)