import React from 'react';
import {
  Form,
  FormInstance,
  InputNumber,
  DatePicker,
  Row, Col,
  Typography
} from 'antd';
import { FormBox } from '../../UI/index';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { RangePicker } = DatePicker;
const { Title } = Typography;

interface PraiaAcessivelConfirmProps {
  form?: FormInstance;
  data: any
}

const { Item } = Form;

export function PraiaAcessivelConfirm({ form, data }: PraiaAcessivelConfirmProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  
  return (
    <FormBox title="Pessoas beneficiadas por edição" form={currentForm}>
      {data.praiaAcessivel.pessoasPorFaixaEtariaAcao.map((item, index) => 
      <React.Fragment key={index}>
        <div className="butto-control">
          <Title level={5}>
            Edição {item.edicao}
          </Title>
        </div>
        <Item
          label="Período"
          name={`periodo-${item.edicao-1}`}
          wrapperCol={{ span: 6 }}
          rules={[ { required: true, message: 'Campo não preenchido' } ]}>
          <RangePicker locale={locale} bordered={false} disabled={true} />
        </Item>
      <Row>
       <Col>
        <Item
          label="Menores de 6 anos"
          name={`menores-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
       </Col>
       <Col offset={1}>
        <Item
          label="De 6 a 12"
          name={`preadolescente-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
       </Col>
       <Col offset={1}>
        <Item
          label="De 12 a 19"
          name={`adolescente-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
       </Col>
       <Col offset={1}>
        <Item
          label="Adultos"
          name={`adultos-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
       </Col>
       <Col offset={1}>
        <Item
          label="Idosos"
          name={`idosos-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
       </Col>
      </Row>
      </React.Fragment>
    )}
    </FormBox> 
  );
};