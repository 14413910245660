import React from 'react';
import { Form, FormInstance, Input, Select, Row, Col } from 'antd';
import cep from 'cep-promise';
import { FormBox, InputCEP, InputNumber } from '../../UI/index';
import municipiosCE from '../../../data/ceara-full.json';
import rules from './rules';

interface AddressFormProps {
  form?: FormInstance;
}

const { Item } = Form;
const { Option } = Select;

export function LocForm({ form }: AddressFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  function handleCepChange(cepValue: string | undefined) {
    if (cepValue) {
      if (cepValue.length >= 8) {
        cep(cepValue).then((data: any) => {
          currentForm.setFieldsValue(data);
        });
      }
      if (cepValue.length === 0) {
        currentForm.resetFields();
      }
    }
  }

  return (
    <FormBox title="Localização" form={currentForm}>
      <Item label="CEP" name="cep" wrapperCol={{ span: 6 }} rules={rules.cep}>
        <InputCEP onChange={handleCepChange} />
      </Item>

      <Item
        label="Município"
        name="city"
        rules={rules.city}
        wrapperCol={{ span: 6 }}
      >
        <Select showSearch>
          {municipiosCE.features.map(city => (
            <Option value={city.properties.name} key={city.properties.id}>
              {city.properties.name}
            </Option>
          ))}
        </Select>
      </Item>

      <Row>
        <Col span={17}>
          <Item label="Endereço" name="street" rules={rules.street}>
            <Input />
          </Item>
        </Col>

        <Col span={3} offset={1}>
          <Item label="Número" name="house-number">
            <InputNumber />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Item label="Bairro" name="neighborhood" rules={rules.neighborhood}>
            <Input />
          </Item>
        </Col>
        <Col span={9} offset={1}>
          <Item label="Ponto de Referência" name="reference">
            <Input />
          </Item>
        </Col>
      </Row>
    </FormBox>
  );
}
