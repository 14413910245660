import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Input, Divider, Button, message } from 'antd';
import { MdFileDownload } from 'react-icons/md';
import fileDownload from 'js-file-download';


import {
  Container,
  Content,
  Wrapper,
  Main,
  CreateWorkPlanBox,
  DiagnosticBox,
  DiagnosticBoxTitle,
  ItemLabel,
  TopWorkPlanBox,
  CommentButton,
  CommentContainer,
  ButtonGroup,
  CancelButton,
  VerticalDate,
  RefuseButton,
  ApproveButton,
  Icon
} from './styles';

import { AproovedModal } from '../../components/Articulator/AproovedModal'
import { ValidRefusedModal } from '../../components/Articulator/ValidRefusedModal'
import { ArticulatorComment } from 'components/Articulator';

import aproovedIcon from '../../assets/articulator/aprooved-white-icon.svg'
import refusedIcon from '../../assets/articulator/refused-icon.svg'

import { ArticulatorBreadcrumb, GoBack } from '../../components/Articulator';
import { CommentProps, Coordinator, IHistoricWorkPlan } from 'types';
import { commentServices, workPlanService } from 'services';
import { Authorizer } from 'components';
import { coordinatorService } from 'services/resources/coordinatorService';


const { TextArea } = Input;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '380px',
    height: '340px'
  },
};

const refusedModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '380px',
    height: '480px'
  },
};

function base64ToArrayBuffer(base64) {
  var binaryLen = base64.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
     var ascii = base64.charCodeAt(i);
     bytes[i] = ascii;
  }
  return bytes;
}

export function CoordinatorWorkplan() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;

  const params = useParams<{ id: string }>();

  const [countLikes, setCountLikes] = useState(0);
  const [workplan, setWorkplan] = useState<IHistoricWorkPlan>();
  const [workplanUrl, setWorkplanUrl] = useState('');

  const [aprooveModalIsOpen, setAprooveModalIsOpen] = useState(false);
  const [aprooveRefuseModalIsOpen, setAprooveRefuseModalIsOpen] = useState(false);
  const [refuseValidModalIsOpen, setRefuseValidModalIsOpen] = useState(false);

  const [isConfirmRefuseButtonClicked, setIsConfirmRefuseButtonClicked] = useState(false)

  const [coordinator, setCoordinator] = useState<Coordinator>({} as Coordinator)

  // Comments
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [isCommentButtonClicked, setIsCommentButtonClicked] = useState(false);

  const history = useHistory();
  const location = useLocation<IHistoricWorkPlan>();

  const getCommentsWorkplanCB = useCallback(getCommentsWorkPlan, [history, params])

  useEffect(() => {
    getCommentsWorkplanCB()
  }, [getCommentsWorkplanCB])

  useEffect(() => {
    if (location && location.state) {
      setWorkplan(location.state)
    }
  }, [location]);

  useEffect(() => {
    console.log(workplan);

    if (workplan) {
      workPlanService
        .getWorkplanDocument(workplan.id)
        .then(file => {
          const blob = new Blob([file], { type: 'application/pdf' });

          setWorkplanUrl(window.URL.createObjectURL(blob));
        });

    }
  }, [workplan]);


  function handleGoBack() {
    history.push(`/coordenador-articulador/${workplan?.agentId.id}`, workplan?.agentId);
  }

  function handleCountLikes() {
    if (countLikes === 0)
      setCountLikes(1);
    else
      setCountLikes(0);
  }

  async function getCommentsWorkPlan() {
    if(!params)
      history.goBack();

    const workplanId = params.id;

    try {
      const response = await commentServices.getCommentsByWorkplanId(workplanId);

      setComments(response);
    } catch(err) {
      message.error('Erro ao buscar plano de trabalho atual')
    }
  }

  async function handleCreateComment() {
    if (!comment.trim())
      return;

    setIsCommentButtonClicked(true);
    const workplanId = +params.id;

    const data = {
      content: comment,
      recipient: workplan?.agentId.id,
      workplan: workplanId
    };

    try {
      await commentServices.createCommentOnWorkplan(data);
      
      getCommentsWorkPlan();

    } catch (err) {
      message.error('Não foi possível criar um comentário');
    }

    setIsCommentButtonClicked(false);
    setComment('');
  }

  function handleAprooveWorkplan() {
    try {
      workPlanService.aprooveWorkPlan(workplan?.id).then((data) => {
        if(data.status === 'APPROVED') {
          openAprooveModal()
        }
      })
    } catch (err) {
      message.error('Erro ao aceitar plano de trabalho')
    }
  }

  async function handleRefuse() {
    if (!comment.trim()) {
      message.error('É necessário justificar a recusa')
      return;
    }

    setIsConfirmRefuseButtonClicked(true);
    const workplanId = +params.id;
    const recipientId = workplan?.agentId;

    const data = {
      content: comment,
      recipient: recipientId,
      workplanId
    };

    try {
      await workPlanService.refuseWorkPlan(workplanId, data)
      closeValidRefusedModal();
      getCommentsWorkPlan();

    } catch (err) {
      message.error('Não foi possível recusar o plano de trabalho');
    }

    setIsConfirmRefuseButtonClicked(false);
    setComment('');
  }

  function openAprooveModal() {
    setAprooveModalIsOpen(true)
  }

  function openRefuseModal() {
    setRefuseValidModalIsOpen(true)
  }

  function closeModal() {
    setAprooveModalIsOpen(false)
    setRefuseValidModalIsOpen(false)
    setAprooveRefuseModalIsOpen(false)
  }

  function closeValidRefusedModal() {
    setRefuseValidModalIsOpen(false)
    setAprooveRefuseModalIsOpen(true)
  }

  async function handleDownloadWorkplanFile() {
    if (!workplan) return;

    try {
      const data = await workPlanService.getWorkplanDocument(workplan.id);

      fileDownload(data, workplan.fileName);
    } catch {
      message.error('Não foi possível fazer o download do arquivo');
    }
    

  }

  return (
    <Container>
      <Content>

        <AproovedModal
          closeModal={closeModal}
          aprooveWorkplan={handleAprooveWorkplan}
          isOpen={aprooveModalIsOpen}
          style={modalStyles}
          contentLabel="Aprooved Modal"
          modalBody="Plano de trabalho aprovado"
          modalTitle="Tudo certo!"
          modalButtonText="OK"
        />

        <AproovedModal
          closeModal={closeModal}
          aprooveWorkplan={closeModal}
          isOpen={aprooveRefuseModalIsOpen}
          style={modalStyles}
          contentLabel="Aprooved Modal"
          modalBody="Plano de trabalho recusado. O Agente será notificado"
          modalTitle="Tudo certo!"
          modalButtonText="OK"
        />

        <ValidRefusedModal
          closeModal={closeModal}
          closeValidRefusedModal={closeValidRefusedModal}
          isOpen={refuseValidModalIsOpen}
          style={refusedModalStyles}
          contentLabel='Valid Refused Modal'
          topModalBody='Ao recusar, você não terá mais acesso a esta proposta de Plano de Trabalho.'
          botModalBody='É necessário justificar o porquê da recusa abaixo.'
          modalTitle='Atenção!'
          comment={comment}
          handleCreateComment={handleRefuse}
          setComment={setComment}
          isConfirmRefuseButtonClicked={isConfirmRefuseButtonClicked}
        />

        <Wrapper>
          <GoBack
            onClick={handleGoBack}
            title="Plano de trabalho"
            subtitle={workplan?.year.toString()}
          />

          <Main>
            <CreateWorkPlanBox>
              <TopWorkPlanBox>
                <ItemLabel>
                  <label>Número de visitação de famílias previsto para esse semestre</label>
                  <span>{workplan?.numVisits}</span>
                </ItemLabel>
                <ItemLabel>
                  <label>Município</label>
                  <span>{workplan?.city}</span>
                </ItemLabel>
              </TopWorkPlanBox>
              <Divider />

              <DiagnosticBox>
                <DiagnosticBoxTitle>
                  Diagnóstico do município
                </DiagnosticBoxTitle>

                <TextArea
                  disabled
                  placeholder="Faça um relato da situação atual do município"
                  value={workplan?.diagnosis}
                />

                <Button className="download-button" onClick={handleDownloadWorkplanFile}>
                  <MdFileDownload />
                  <span>Fazer download do arquivo</span>
                </Button>

              </DiagnosticBox>

            </CreateWorkPlanBox>

          </Main>
        </Wrapper>
      </Content>
      <CommentContainer>
        <div className="comment-wrapper">
          <ArticulatorComment 
            title="Observações e comentários gerais"
            comments={comments}
            countLikes={countLikes}
            onLike={handleCountLikes}
          />

          <TextArea
            value={comment}
            onChange={e => setComment(e.target.value)}
            onPressEnter={handleCreateComment}
            disabled={isCommentButtonClicked}
          />

          <ButtonGroup>
            <CancelButton type="reset" onClick={() => setComment('')}>
              Cancelar
            </CancelButton>

            <Button
              type="primary"
              onClick={handleCreateComment}
              className="comment-button"
              loading={isCommentButtonClicked}
              style={{
                background: '#4B9F37',
                border: 0
              }}>
              Comentar
            </Button>
          </ButtonGroup>
          {
            workplan?.status === 'AWAITING' ? (<>
              <ButtonGroup>
                <RefuseButton onClick={openRefuseModal}>
                  <Icon src={refusedIcon} />
                  Recusar
                </RefuseButton>

                <ApproveButton onClick={handleAprooveWorkplan}>
                  <Icon src={aproovedIcon} />
                  Aprovar
                </ApproveButton>
              </ButtonGroup>
            </>) : (<></>)
          }
        </div>
      </CommentContainer>
    </Container>
  );
}
