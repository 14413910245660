import axios from 'axios';
import {
  authService,
  IRIS_SOCIAL_TOKEN,
  GRANT_TOKEN,
  CITIZEN_TOKEN,
} from './resources/authService';
import { AuthorizerEvents } from 'components/Authorizer';
import { notificationToast } from 'components/NotificationToast';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiBigData = axios.create({
  baseURL: process.env.REACT_APP_BIG_DATA,
});

export const ibgeApi = axios.create({
  baseURL: process.env.REACT_APP_IBGE_URL,
});

export const citizenByUserApi = axios.create({
  baseURL: process.env.REACT_APP_CITIZEN_URL,
});

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_CITIZEN_URL,
});

export const socialApi = axios.create({
  baseURL: process.env.REACT_APP_SOCIAL_URL,
});

export const irisSocialApi = axios.create({
  baseURL: process.env.REACT_APP_IRIS_SOCIAL_API,
});

citizenByUserApi.interceptors.request.use(config => {
  const typeToken = config.headers['Application-Token'];
  const tokenKey =
    typeToken === 'iris-social' ? IRIS_SOCIAL_TOKEN : CITIZEN_TOKEN;
  const token = localStorage.getItem(tokenKey);

  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
    },
  };
});

apiInstance.interceptors.request.use(config => {
  const token = localStorage.getItem(IRIS_SOCIAL_TOKEN);
  if (token) {
    return {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Authorization: token,
      },
    };
  }
  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
    },
  };
});
apiBigData.interceptors.request.use(config => {
  const token = localStorage.getItem(IRIS_SOCIAL_TOKEN);
  if (token) {
    return {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Authorization: token,
      },
    };
  }
  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
    },
  };
});

apiInstance.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    
    notificationToast({
      type: 'error',
      message: response?.data?.mensagem || 'Ocorreu um erro. Entre em contato com a administração!',
    });

    if (response?.status === 401) {
      const host = process.env.REACT_APP_LOGIN_URL;
      const appKey = process.env.REACT_APP_IRIS_SOCIAL_KEY;
      const url = `${host}/?origin=/programa-mais-infancia&app-key=${appKey}`;
      window.location.replace(url);

    } else return Promise.reject(error);
  },
);

authApi.interceptors.request.use(config => {
  const token = localStorage.getItem(IRIS_SOCIAL_TOKEN);

  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
    },
  };
});

socialApi.interceptors.request.use(config => {
  const token = localStorage.getItem(IRIS_SOCIAL_TOKEN);
  if (token) {
    return {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Authorization: token,
      },
    };
  } else {
    return {
      ...config,
    };
  }
});
irisSocialApi.interceptors.request.use(config => {
  const token = localStorage.getItem(IRIS_SOCIAL_TOKEN);
  if (token) {
    return {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Authorization: token,
      },
    };
  } else {
    return {
      ...config,
    };
  }
});

citizenByUserApi.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response?.status === 401) {
      const grantToken = localStorage.getItem(GRANT_TOKEN);
      const appServerKey = process.env.REACT_APP_CITIZEN_KEY as string;

      return authService
        .generate(grantToken, appServerKey)
        .then(token => {
          localStorage.setItem(CITIZEN_TOKEN, token);
        })
        .then(() => citizenByUserApi.request(error.config))
        .catch(() => {
          AuthorizerEvents.emit('unauthorized');
        });
    } else {
      return Promise.reject(error);
    }
  },
);
export const apiBDS = apiBigData;
export const api = apiInstance;
