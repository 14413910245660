import React from 'react';

import { GeoJSON, Tooltip } from 'react-leaflet';
import { GeoJsonProps } from './types';

import './styles.scss';
import MapIcon from 'components/MapIcon';
interface RegionGeoJsonProps {
  data: GeoJsonProps;
}
const COLOR_0 = '#87C878';
const COLOR_1 = '#4B9F37';

const RegionGeoJson: React.FC<RegionGeoJsonProps> = props => {
  const { data } = props;

  function getColorRegion(qtdFamilies: number) {
    if (qtdFamilies <= 120) return COLOR_0;
    return COLOR_1;
  }
  const handleClickMunicip = (e: any) => {

    /* layer.bindPopup(
      '<h1>' +
        feature.properties.f1 +
        '</h1><p>name: ' +
        feature.properties.f2 +
        '</p>',
    ); */
  };

  const styleGeoJson = {
    color: '#4B9F37',
    weight: 1,

    opacity: 1,
    fillOpacity: 1,
    fillColor: getColorRegion(parseInt(data.qtdFamilias)),
  };
  const onEachFeatureMuni = (feature: any, layer: any) => {
    layer.on({
      click: handleClickMunicip,
    });
  };

  if (data?.geoJson) {
    return (
      <>
        <GeoJSON
          data={data.geoJson as any}
          style={styleGeoJson}
          onEachFeature={onEachFeatureMuni}
        >
          <Tooltip>
            <MapIcon data={data} />
          </Tooltip>
        </GeoJSON>
      </>
    );
  }
  return null;
};
export default RegionGeoJson;
