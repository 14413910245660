import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

interface ButtonCMICProps {
  image: string;
  title: 'Gestante' | 'Criança' | 'Domicílio';
  type: 'gestante' | 'crianca' | 'domicilio';
}

const ButtonCMIC = ({ image, title, type }: ButtonCMICProps): JSX.Element => {
  const history = useHistory();
  function handleClickButton() {
    history.push(`/pesquisa-cmic/${type}`);
  }
  return (
    <>
      <button
        className="container__buttonCMIC"
        onClick={() => handleClickButton()}
        type="button"
      >
        <div className="container__buttonCMIC-left">
          <img src={image} alt="img botão navegação" />
        </div>
        <div className="container__buttonCMIC-right">
          <h1>{title}</h1>
        </div>
      </button>
    </>
  );
};

export default ButtonCMIC;
