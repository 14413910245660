export function organizeEventoPraiaAcessivel(data: any) {
  
  const eventFormatted =  data.map(event => {
 
    return {
      praiaAcessivel: {
        nome: event.praiaAcessivel.nome,
        data: event.praiaAcessivel.data,
        dataComemorativa: event.praiaAcessivel.dataComemorativa,
        atendimentos: {...event.praiaAcessivel.atendimentos },
        localizacao: {
          municipio: event?.praiaAcessivel.localizacao?.municipio || '-',
          rua: event?.praiaAcessivel.localizacao?.rua || '-',
          bairro: event?.praiaAcessivel.localizacao?.bairro || '-',
          cep: event?.praiaAcessivel.localizacao?.cep || '-',
          numero: event?.praiaAcessivel.localizacao?.numero || '-',
          pontoDeReferencia: event?.praiaAcessivel.localizacao?.pontoDeReferencia || '-',
          estacao: event?.praiaAcessivel.localizacao?.estacao || '-',
          longitude: event?.praiaAcessivel.localizacao?.longitude || '-',
          latitude: event?.praiaAcessivel.localizacao?.latitude || '-'
        }
      }
        
    }
  });
  return {
    ...data,
    elementos: eventFormatted
  }
    ;
}

