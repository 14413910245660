import { Steps, Form, Spin } from 'antd';
import { Authorizer, Breadcrumb, ModalConfirma } from 'components';
import { FormCreateFiles } from 'components/FilesActions/FormCreateFiles';

import { TitlePage } from 'components/TitlePage';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import './styles.scss';

import GenerateForm from './GeneralForm';
import { infosActions } from './InfosActions';
import {
  attEventAcao,
  getEvent,
  getInfosAction,
  postAcoes,
  postFilesAcoes,
} from 'services/resources/acoesServices';
import { makeJSONAction } from 'utils/makeJSONAction';
import { APIRetornActions } from 'utils/types';
import { getJSONActionEspecifc } from 'utils/getJSONActionEspecifc';
import {
  formaterJSONCACS,
  formaterJSONMaisNutricao,
  formaterJSONnep,
  formaterJSONsda,
  formaterJSONpf,
  formaterJSONfs,
  compararObjetos,
  formaterJSONcf,
  formaterJSONcapacitacao,
  formaterJSONsimic,
  formaterJSONpadin,
} from './utils';
import { FormCreateDocumentario } from 'components/FilesActions/FormCreateDocumentario';
import { FormCreateLivro } from 'components/FilesActions/FormCreateLivro';
import { LoadingOutlined } from '@ant-design/icons';
import { notificationToast } from 'components/NotificationToast';
import { LoaderInfoAction } from 'components/LoaderInfoAction';

export { WidgetsBuilders } from '../../utils/Widgets';

type Props = {
  functionValue: Array<{ title: string; value: any }>;
  nome: string;
  value: string;
};

const { Step } = Steps;

type NewRegisterActionProps = {
  editMode?: boolean;
};

type Infos = {
  id: string;
  nome: string;
  error?: false | boolean;
}


function NewRegisterAction({ editMode }: NewRegisterActionProps): JSX.Element {
  const [form] = Form.useForm();

  const [valuesForm, setValuesForm] = useState({});
  const { idAcao, idEvento } =
    useParams<{ id?: string; idAcao?: string; idEvento?: string }>();
  const [selectedFile, setSelectedFile] = useState<any>([] as any);
  const [camposNameFunction, setCamposNameFunction] = useState<Props>(
    {} as Props,
  );
  const [ultimoEditor, setUltimoEditor] = useState<any>(null);

  const [stepCurrent, setStepCurrent] = useState(0);
  const [concluir, setConcluir] = useState(false);
  const [infosCreateActions, setInfosCreateActions] = useState<any>(null);
  const [valueRegistroAcoes, setvalueRegistroAcoes] = useState<any>(null);
  const [valueRegistroAcoesEditados, setvalueRegistroAcoesEditados] =
    useState<any>(null);
  const [arquivosRegistro, setArquivosRegistro] = useState<any>(null);
  const [concluirDisabled, setConcluirDisabled] = useState(false);
  const [concluirFiles, setConcluirFiles] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isFinished, setIsFinished] = useState(false);
  const antIcon = <LoadingOutlined spin />;
  const [isLoadingEventEdited, setIsLoadingEventEdited] = useState(false);

  const [currentActionInformation, setCurrentActionInformation] = useState<Infos>();
  const [emptyInfosAction, setEmptyInfosAction] = useState(false);


  const auth = Authorizer.useAuth();

  useEffect(() => {

    let infos
    const responseInfo = async () => {
      infos = await getInfosAction(idAcao)

      if(infos){
        setCurrentActionInformation(infos);

        for (var property in infosActions) {
          if (infos?.nome === infosActions[property].nome) {
            setCamposNameFunction(infosActions[property]);
          }
        }
    
        setvalueRegistroAcoes([]);
    
        if (editMode) {
          const response = async () => {
            setIsLoadingEventEdited(true);
            const data: APIRetornActions = await getEvent(idAcao, idEvento);
    
            setvalueRegistroAcoesEditados(data);
    
            if (data) {
              setvalueRegistroAcoes(
                getJSONActionEspecifc(infos.nome, data),
              );
    
              const ultimaModificacao = new Date(
                data.ultimaModificacao,
              ).toLocaleDateString('pt-BR');
    
              setUltimoEditor({
                ...data.ultimoEditor,
                ultimaModificacao: ultimaModificacao,
              });
    
              setArquivosRegistro(data.arquivosRegistro);
            }
            setIsLoadingEventEdited(false);
          };
          response();
        }
      }
    }

    responseInfo().catch(() => {setEmptyInfosAction(true)});

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handlerConcluir() {
    setIsSaving(true);
    if (infosCreateActions && selectedFile.length > 0) {
      postFilesAcoes(
        currentActionInformation?.id,
        infosCreateActions?.id,
        selectedFile,
        handleSalvarArquivo,
      );
    } else if (editMode && selectedFile.length > 0) {
      postFilesAcoes(idAcao, idEvento, selectedFile, handleSalvarArquivo);
    } else {
      setConcluirFiles(true);
      setConcluir(true);
      setIsSaving(false);
    }
  }

  function onSave() {

    const ultimoEditor = {
      nome: auth.user.client.name,
      email: auth.user.client.email,
    };


    let dataPost: any = {
      ultimoEditor,
      ...makeJSONAction(currentActionInformation?.nome || '', valuesForm),
    };



    if (
      currentActionInformation?.nome ===
      'Capacitação dos Agentes Comunitários de Saúde'
    ) {
      dataPost = { ...formaterJSONCACS(dataPost) };
    } else if (currentActionInformation?.nome === 'Mais Nutrição') {
      if (dataPost.maisNutricao.maisNutricaoDoacao) {
        dataPost = { ...formaterJSONMaisNutricao(dataPost) };
      }
    } else if (
      currentActionInformation?.nome === 'Núcleos de Estimulação Precoce'
    ) {
      dataPost = { ...formaterJSONnep(dataPost) };
    } else if (currentActionInformation?.nome === 'Sementes do Amanhã') {
      dataPost = { ...formaterJSONsda(dataPost) };
      delete dataPost.sementesDoAmanha.total
    } else if (currentActionInformation?.nome === 'Prevenção em Família') {
      dataPost = { ...formaterJSONpf(dataPost) };
      delete dataPost.prevencaoEmFamilia.total
    } else if (currentActionInformation?.nome === 'Formação Seduc') {
      dataPost = { ...formaterJSONfs(dataPost) };
    } else if (currentActionInformation?.nome === 'Capacitação') {
      dataPost = { ...formaterJSONcapacitacao(dataPost) };
    } else if (currentActionInformation?.nome === 'Criança Feliz') {
      dataPost = { ...formaterJSONcf(dataPost) }
    } else if (currentActionInformation?.nome === 'Seminário Internacional Mais Infância Ceará') {
      dataPost = { ...formaterJSONsimic(dataPost) }
    } else if (currentActionInformation?.nome === 'Padin') {
      dataPost = { ...formaterJSONpadin(dataPost) }
    }
    // } else if (currentActionInformation?.nome === 'Toda Banda de Música é uma escola') {
    //   dataPost = { ...formaterJSONtbde(dataPost) }
    // }

    if (editMode) {
      const editado = compararObjetos(
        valueRegistroAcoesEditados[camposNameFunction.value],
        dataPost[camposNameFunction.value],
      );

      if (editado) {
        setStepCurrent(stepCurrent + 1);
      } else {
        setIsSaving(true);
        attEventAcao(
          idAcao,
          idEvento,
          dataPost,
          setConcluirFiles,
          handleSalvar,
        );
      }
    } else {
      setIsSaving(true);
      postAcoes(
        dataPost,
        idAcao,
        setInfosCreateActions,
        setConcluirFiles,
        handleSalvar,
      );
    }


  }

  function handleSalvar(increment) {
    setIsSaving(false);
    if (increment) {
      setStepCurrent(stepCurrent + 1);
    }
  }

  function handleSalvarArquivo(type) {
    setIsSaving(false);
    if (type === 'error') {
      setConcluir(false);
    } else {
      setConcluirFiles(true);
      setConcluir(true);
    }
  }

  const next = () => {
    // if (stepCurrent === 1) {
      
    //   setStepCurrent(stepCurrent + 1);
    //   onSave();
    // } else {
      form.validateFields().then(() => {
        if (stepCurrent !== 1) {
          setIsFinished(true);
          setValuesForm(form.getFieldsValue());
          setStepCurrent(stepCurrent + 1);
        }
        
      }).catch(() => {
        if (stepCurrent !== 1) {
          notificationToast({
            type: 'warn',
            message: 'Campos não foram preenchidos',
          })
        }
      }
      ).finally(() => {
        if (stepCurrent === 1) {
          onSave();
        }
      });
    // }
  };

  const prev = () => {
    setStepCurrent(stepCurrent - 1);
  };

  

  function makeStepsForm() {


    const steps = [
      {
        title: 'Informações',
        content: (
          <>
            <GenerateForm
  
              isFinished={isFinished}
              infosCurrent={camposNameFunction}
              form={form}
              editMode={editMode}
              initialValues={valueRegistroAcoes}
              onInitialValues={setvalueRegistroAcoes}
              ultimoEditor={ultimoEditor}
            />
          </>
        ),
      },
      {
        title: 'Confirmação',
        content: (
          <GenerateForm
            isFinished={isFinished}
            infosCurrent={camposNameFunction}
            form={form}
            initialValues={valueRegistroAcoes}
            editMode={editMode}
            viewMode
          />
        ),
      },
      {
        title: 'Adicionar arquivos',
        content: (
          <>
            {currentActionInformation?.nome === 'Documentários' && (
              <FormCreateDocumentario
                fileCurrent={valueRegistroAcoes?.documentario}
                idAcao={idAcao}
                idEvento={idEvento ?? infosCreateActions?.id}
                setConcluirDisabled={setConcluirDisabled}
              />
            )}
            {currentActionInformation?.nome ===
              'Lançamento de Livro ou Relatório' && (
                <FormCreateLivro
                  idAcao={idAcao}
                  idEvento={idEvento ?? infosCreateActions?.id}
                  filesCurrent={valueRegistroAcoes?.livrosRelatorios}
                  editMode={editMode}
                  setConcluirDisabled={setConcluirDisabled}
                />
              )}
  
            <FormCreateFiles
              idAcao={idAcao}
              idEvento={idEvento}
              onSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
              arquivosRegistro={arquivosRegistro}
              setArquivosRegistro={setArquivosRegistro}
              editMode={editMode}
            />
          </>
        ),
      },
    ];

    return (
      <div className="steps-container">
        <div className="steps-header">
          <Steps current={stepCurrent}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className="form-content">{steps[stepCurrent].content}</div>
        <div className="buttons-form">
          {stepCurrent > 0 && stepCurrent < 2 && (
            <button
              className="button-form left"
              style={{ margin: '0 8px' }}
              onClick={() => prev()}
            >
              VOLTAR
            </button>
          )}
          {stepCurrent < steps.length - 1 && (
            <>
              <Link to={'/home-servidor'}>
                <button className="button-form cancel">CANCELAR</button>
              </Link>
              <button
                className={
                  !isSaving ? 'button-form right' : 'button-form right disable'
                }
                onClick={next}
                disabled={isSaving}
              >
                {stepCurrent === 1 ? (
                  !isSaving ? (
                    'SALVAR'
                  ) : (
                    <Spin
                      className="spin-button"
                      size="small"
                      tip="SALVAR"
                      indicator={antIcon}
                    />
                  )
                ) : (
                  'AVANÇAR'
                )}
              </button>
            </>
          )}
          {stepCurrent === steps.length - 1 && (
            <button
              className={
                concluirDisabled || isSaving
                  ? 'button-form right disable'
                  : 'button-form right'
              }
              onClick={() => handlerConcluir()}
              disabled={concluirDisabled}
            >
              {!isSaving ? (
                'CONCLUIR'
              ) : (
                <Spin
                  className="spin-button"
                  size="small"
                  tip="CONCLUIR"
                  indicator={antIcon}
                />
              )}
            </button>
          )}
        </div>
        {concluir && concluirFiles && (
          <ModalConfirma
            mensagem={
              editMode
                ? `${currentActionInformation?.nome} editada com sucesso!`
                : `${currentActionInformation?.nome} registrada com sucesso!`
            }
            id={currentActionInformation?.id || ''}
          />
        )}
      </div>
    );
  }

  return (

    currentActionInformation === undefined ? (
      emptyInfosAction ? 
      <p style={{textAlign: 'center', marginTop: '20rem'}}>
        Ocorreu um erro!
      </p> :
        <LoaderInfoAction />
    ) : 
      <>
        <div className="registro-acao-container container-box">
          <Breadcrumb links={
            [
              {
                path: '/',
                title: 'Página Inicial',
              },
              {
                path: '/home-servidor',
                title: 'Lista de Equipamentos e Ações',
              },
              {
                path: editMode
                  ? `/editar-acao/${idAcao}/${idEvento}`
                  : `/registrar-acao/${idAcao}`,
                title: editMode
                  ? `Edição de ${currentActionInformation?.nome}`
                  : `Novo registro de ${currentActionInformation?.nome}`,
              },
            ]
          } />
          <TitlePage
            title={
              editMode
                ? `Edição de Registro de ${currentActionInformation?.nome}`
                : `Registro de ${currentActionInformation?.nome}`
            }
            backButton
          />

          {!editMode || !isLoadingEventEdited ? (
            makeStepsForm()
          ) : (
            <Spin
              className="spin-loading"
              tip="Buscando"
              size="large"
              indicator={antIcon}
            />
          )}
        </div>
      </>
  );
}

export default NewRegisterAction;
