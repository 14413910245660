import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Content = styled.div`
  padding: 2.4rem 5rem;
  background: #FFF;

  max-width: 1400px;
  margin: 0 auto;

  .ant-breadcrumb {
    span {
      color: #81868A;
    }
    span.ant-breadcrumb-link {
      a {
        color: #81868A;
      }
    }
  }

  button {
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media(max-width: 500px) {
    padding: .5rem;
  }
`;

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;

  @media(max-width: 500px) {
    padding-top: 3rem;
  }
 
`;
export const Main = styled.main`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 2.3rem;

  max-width: 1300px;
  margin: 7rem auto;
`;

export const CreateWorkPlanBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: #f2f2f2;
  border-radius: 7px;
 

  padding: 2.9rem 4rem;

  .ant-divider {
    background: rgba(0, 0, 0, 0.3);
  }
  @media(max-width: 500px) {
    padding: 1rem;
  }
`;

export const TopWorkPlanBox = styled.div`
  display: flex;
  height: max-content;
  border-left: 3px solid #FFDE32; 

  @media(max-width: 500px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
 
`;

export const ItemLabel = styled.div`
  margin-left: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-weight: 700;
    color: #464A4E;
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .ant-input {
    width: 9rem;
  }

  + div {
    margin-left: 7rem;
  }

  @media(max-width: 500px) {
    & + div {
      margin-left: 1.8rem;
      justify-self: flex-end;
    }
  }
`;

export const DiagnosticBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.1rem;

  textarea.ant-input {
    min-height: 24rem;
  }

  @media(max-width: 500px) {
    
  }
`;

export const DiagnosticBoxTitle = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #2D4040;
`;

export const DiagnosticButton = styled.button`
  display: flex;
  width: 22.4rem;
  justify-content: space-around;
  align-items: center;
  padding: .8rem 1rem;
  background: #4B9F37;
  border-radius: 5px;
  border: 0;
  align-self: flex-start;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;

  color: #FFFFFF;
`;


export const ButtonGroup = styled.div`
  display: flex;
  align-self: flex-end;

  gap: 1rem;

  button {
    width: 16.4rem;
    height: 3.6rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    border-radius: 5px;
  }

  .send-plan-button {
    background: #4B9F37;
    border: 0;
    color: #FFFFFF;
    transition: filter .3s;

    @media(max-width: 400px) {
      order: -1;
    }

    &:hover {
      background: #4B9F37;
      filter: brightness(0.7);
    }
  }

  @media(max-width: 400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: initial;
    width: 100%;
    
    button {
      width: 100%;
    }
  }
`;

export const CancelButton = styled.button`
  background: #FFFFFF;
  border: 1px solid #33A02C;
  
  color: #33A02C;

  
`;

export const SendPlanButton = styled.button`
  background: #4B9F37;
  border: 0;
  color: #FFFFFF;

  @media(max-width: 400px) {
    order: -1;
  }
`;