import React from 'react';
import moment from 'moment';
import { CgProfile } from 'react-icons/cg';

import { CommentProps } from 'types';
import { 
  CommentContainerTitle, 
  CommentBox, 
  CommentDate,
  CommentUserName,
  CommentCity,
  ProfileDateContent, 
  ProfileLikeContainer, 
  Column,
  Box
} from './styles';

// import likeIcon from 'assets/articulator/like.svg';
import { SimpleLoading } from '../SimpleLoading';

type CommentType = {
  comments: CommentProps[];
  onLike: () => void;
  countLikes: number;
  title: string;
  isLoading?: boolean;
}

export function Comment({ comments, onLike, countLikes, title, isLoading }: CommentType) {
  const Loading = () => (isLoading ? <SimpleLoading tip="Carregando comentários, aguarde" size="large"/> : null);

  const sortedComments = comments
    .map(c => ({ 
      ...c, 
      attDate: moment(c.attDate), 
      creationDate: moment(c.creationDate),
    }))
    .sort((a, b) => b.creationDate.diff(a.creationDate));

  return (
    <>
      <CommentContainerTitle>
        {title}
      </CommentContainerTitle>
      <Loading />
      <Box>
        {(comments && !isLoading) && (
          sortedComments.map(comment => (
            <CommentBox key={comment.id}>
              {/* {comment.new && <span>Novo</span>} */}
              <ProfileLikeContainer>
                <ProfileDateContent>
                  <CgProfile color="#ddd"/>
                  <Column>
                    <CommentUserName>{comment.coordinatorId ? comment.coordinatorName : comment?.user?.name}</CommentUserName>
                    <CommentCity>{comment.coordinatorId ? `` : `(${comment?.user?.city})`}</CommentCity>
                  </Column>
                  <CommentDate>{comment.creationDate.format('DD/MM/YYYY HH:mm')}</CommentDate>
                </ProfileDateContent>

                {/* <LikeButton onClick={onLike}>
                  <img src={likeIcon} alt="like comment" />

                  {countLikes > 0 && (
                    <CountLikesContainer>
                      <span>{countLikes}</span>
                    </CountLikesContainer>
                  )}
                </LikeButton> */}
              </ProfileLikeContainer>

              <span className="observation">{comment.content}</span>
            </CommentBox>
          ))
        )}
      </Box>
    </>
  );
}
