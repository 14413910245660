// eslint-disable-next-line import/no-unresolved
import { isValidCEP } from '@firestormapps/utils';
import { Rule } from 'rc-field-form/lib/interface';

const rules: Record<string, Rule[]> = {
  cep: [
    {
      required: true,
      validator(_, value) {
        if (isValidCEP(value)) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('CEP inválido'));
      },
    },
  ],
  street: [
    {
      required: true,
      message: 'Endereço não preenchido',
    },
  ],
  'house-number': [
    {
      required: true,
      message: 'Número não preenchido',
    },
  ],
  neighborhood: [
    {
      required: true,
      message: 'Bairro não preenchido',
    },
  ],
  city: [
    {
      required: true,
      message: 'Município não preenchido',
    },
  ],
};

export default rules;
