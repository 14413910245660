import { Breadcrumb, Device, Footer } from 'components';
import MapCartaoCMIC from 'components/MapCartaoCMIC';
import { TitlePage } from 'components/TitlePage';
import React, { useEffect, useState } from 'react';

import cartao_gif from '../../assets/CMIC/trapezio-cartao.png';
import kids from '../../assets/cartao-kids.svg';

import iconSolTop from '../../assets/CMIC/sol-esquerdo-top.svg';
import iconSolLow from '../../assets/CMIC/sol-lado-baixo.svg';

import iconBaixo from '../../assets/arrow_down.svg';
import iconCima from '../../assets/arrow_up.svg';
import './styles.scss';
import InfoProvide from 'components/InfoProvide';
import { getDinheiroInvestido, getFamiliasBeneficiadas } from 'services/resources/cmicService';
import { Spin } from 'antd';
import { ErrorMessageBDS } from 'components/ErrorMessageBDS';
import { FormatNumberWithSufix } from 'utils/FormatNumberWithSufix';

const CartaoCMIC = (): JSX.Element => {
  const [saibaMaisVisible, setSaibaMaisVisible] = useState(false);
  const [familiasBeneficiadas, setFamiliasBeneficiadas] = useState<Number|undefined|string>(undefined);
  const [dinheiroIvestido, setDinheiroInvestido] = useState<Number|undefined|string>(undefined);
  const [dinheiroIvestidoYearCurrent, setDinheiroInvestidoYearCurrent] = useState<Number|undefined|string>(undefined);

  const YearCurrent = new Date().getFullYear(); 



  const device = Device.useDevice();

  useEffect(() => {
    const response = async () => {
      const data = await getFamiliasBeneficiadas().catch(() => {setFamiliasBeneficiadas('error')});
      const data2 = await getDinheiroInvestido('').catch(() => {setDinheiroInvestido('error')});
      const data3 = await getDinheiroInvestido(`${YearCurrent}`).catch(() => {setDinheiroInvestidoYearCurrent('error')});
      

      console.log(data, data2, data3);


      
      if(data && data[0]?.quantidade) {
        setFamiliasBeneficiadas(data[0].quantidade)
      } else {
        setFamiliasBeneficiadas('empty')
      }

      if(data2 && data2[0]?.investido){
    
        setDinheiroInvestido(data2[0].investido)
      } else {
        setDinheiroInvestido('empty')
      }

      if(data3 && data3[0]?.investido){
        setDinheiroInvestidoYearCurrent(data3[0].investido)
      } else {
        setDinheiroInvestidoYearCurrent('empty')
      }


    }

    response();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  
  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: '/cartao-cmic',
      title: 'Cartão CMIC',
    },
  ];
  return (
    <>
      <section className="container-box">
        <section className="container__cartao">
          <div className="container__cartao-header">
            <Breadcrumb links={links} />
            <TitlePage
              style={{ margin: '0' }}
              title="Cartão"
              subtitle="Conheça os benefícios do Cartão Mais Infância"
              backButton
            />
          </div>
        <main className={device.prefix()??"undefined"}> 
        {/* <p style={{margin: 0, fontSize: '1.4rem'}}>*Os dados abaixo são fornecidos pelo Sistema Big Data Social da base 
        CEMIC, com última atualização no dia {new Date().toLocaleDateString('pt-br')}.</p> */}

        <InfoProvide 
          message="Os dados abaixo foram coletados do Big Data Social a partir da base do sistema CMIC" 
          type="cemic"
        />

        <Spin spinning={familiasBeneficiadas === undefined } size="large" tip="Carregando">
          <section className="container__cartao-top">
              <div className="infos">
                <h2>
                  {familiasBeneficiadas === 'error' || familiasBeneficiadas === 'empty'? ErrorMessageBDS() : !familiasBeneficiadas ? 0 : Number(familiasBeneficiadas).toLocaleString('pt-br') }
                </h2>
                <span className="title-style">
                  <p>famílias são beneficiadas</p>
                </span>

                <p>em todos os</p>
                <h2>184</h2>
                <span className="title-style">
                  <p>municípios cearenses!</p>
                </span>
              </div>
              <div className="image">
                <img src={cartao_gif} alt="cartão gif" />
              </div>
            </section>
          </Spin>

          <section className="container__cartao-mapa">
            <img className="sol-top" src={iconSolTop} alt="sol no topo" />
            <img className="sol-low" src={iconSolLow} alt="sol baixo" />
            <div className="content-map">

            <div className="container__cartao-titles">

                <div className="bloc'error'">
                  <Spin spinning={dinheiroIvestidoYearCurrent === undefined} size="small" tip="Carregando">  
                    <h2>
                      <p className="font-quadrada">
                        { dinheiroIvestidoYearCurrent === 'error' || dinheiroIvestido === 'empty' ? ErrorMessageBDS() : FormatNumberWithSufix(Number(dinheiroIvestidoYearCurrent))} 
                      </p>
                    </h2>
                  </Spin>
                  <p className="subTitle">
                    de reais foram investidos em {YearCurrent}
                  </p>
                </div>
                <div className="blocundefined">
                  <Spin spinning={dinheiroIvestido === undefined} size="small" tip="Carregando">
                    <h2>
                      <p className="font-quadrada"> {dinheiroIvestido === 'error' || dinheiroIvestido === 'empty' ? ErrorMessageBDS() : FormatNumberWithSufix(Number(dinheiroIvestido)) } </p> 
                    </h2>
                  </Spin>
                  <p className="subTitle">
                    de reais já foram investidos de 2019 a {YearCurrent}
                  </p>
                </div>

            </div>
            <div className="cartao-mapa-infos">
              <div className="map-detail">
                <div className="detail-labels-map">
                  <h3>
                    Quantidade de famílias que recebem o benefício do Cartão
                  </h3>
                  <div className="label">
                    <p> 0 - 100 </p>
                    <div className="span" style={{ background: '#ADC4A3' }} />
                  </div>
                  <div className="label">
                    <p>100 - 500</p>{' '}
                    <div className="span" style={{ background: '#8BB87B' }} />
                  </div>
                  <div className="label">
                    <p>500 - 1000</p>{' '}
                    <div className="span" style={{ background: '#4B9F37' }} />
                  </div>
                  <div className="label">
                    <p>Maior que 1000 (mil)</p>{' '}
                    <div className="span" style={{ background: '#327824' }} />
                  </div>
                </div>
              </div>
              <MapCartaoCMIC />
            </div>


            </div>
          </section>

          <section className="container__cartao-verde">
            <div className="cartao-verde-line">
              <div className="cartao-verde-column">
                <h1>O que é o Cartão Mais Infância? Como funciona?</h1>
                <p>
                  O Cartão Mais Infância é um programa de transferência de renda
                  do Governo do Estado. Um benefício financeiro mensal, no valor
                  de 100,00, destinado às famílias extremamente pobres e que
                  atendam aos critérios do programa.
                </p>
                <p>
                  As famílias são selecionadas na base de dados do Cadastro
                  Único e a relação das famílias é repassada para os municípios
                  onde as famílias residem.
                  <br />
                  As equipes dos Municípios se encarregam de localizar as
                  famílias e confirmar se a situação delas continua igual ao
                  período em que fizeram o Cadastro Único e portanto, se ainda
                  precisam receber o beneficio do CMIC.
                </p>
              </div>

              <div className="cartao-verde-column">
                <div>
                  <h1>Quem deve procurar?</h1>
                  <p>
                    Famílias inscritas no Cadastro Único (CadÚnico), com o
                    perfil exigido.
                  </p>
                </div>
                <div>
                  <h1>O benefício CMIC é pago por criança?</h1>
                  <p>
                    Não. O benefício é pago por família, independentemente do
                    número de filhos na faixa etária de 0 a 5 anos e 11 meses.
                  </p>
                </div>
              </div>
            </div>
            <button
              className="saber-mais"
              onClick={() => setSaibaMaisVisible(!saibaMaisVisible)}
            >
              {saibaMaisVisible ? 'Ocultar' : 'Saiba mais'}
              <img
                src={saibaMaisVisible ? iconCima : iconBaixo}
                alt="icon cima baixo"
              />
            </button>
            <section className={saibaMaisVisible ? '' : 'invisible'}>
              <div className="cartao-verde-line">
                <div className="cartao-verde-column">
                  <h1>Quem pode receber o Cartão Mais Infância?</h1>
                  <p>A família que cumprir os seguintes critérios:</p>
                  <p>
                    • Ter Crianças na composição familiar, com idade entre 0 e 5
                    anos e 11 meses
                    <br />
                    • Morar no Estado do Ceará
                    <br />
                    • Ter Renda por pessoa na família de até R$89,00, ou seja,
                    quando ao somar tudo o que todos que moram na casa ganham,
                    de trabalho formal e informal e dividir pelo total de
                    moradores, o valor por pessoa for igual ou inferior a R$
                    89,00.
                    <br />
                    • Ter os dados no Cadastro Único atualizados no máximo há
                    dois anos;
                    <br />• Receber o Bolsa Família;
                  </p>
                </div>

                <div className="cartao-verde-column">
                  <div>
                    <h1>
                      Como posso saber se fui selecionada para o Cartão Mais
                      Infância Ceará?
                    </h1>
                    <p>
                      Com o número do CPF do responsável pela família no
                      Cadastro Único é feita a pesquisa. O beneficiário pode
                      acessar o chat da SPS ou ligar para o número (85)
                      3101-4613.
                    </p>
                  </div>
                </div>
              </div>
              <div className="cartao-verde-line">
                <div className="cartao-verde-column">
                  <h1>Como é recebido o valor do benefício?</h1>

                  <p>
                    O Governo do Estado do Ceará, por meio da Secretaria da
                    Proteção Social , Justiça, Cidadania, Mulheres e Direitos
                    Humanos – SPS, deposita os recursos no Banco do Brasil, em
                    conta aberta para cada uma das famílias. O responsável
                    familiar da família selecionada precisa ir à agência do
                    Banco do Brasil para receber o seu cartão e registar a sua
                    senha. Depois do recebimento do cartão, o beneficiário pode
                    sacar o benefício financeiro no caixa eletrônico ou no
                    correspondente bancário do Banco do Brasil e, também, pode
                    usar o cartão do CMIC na função de débito, realizando as
                    compras diretamente no comércio local
                  </p>
                </div>

                <div className="cartao-verde-column">
                  <div>
                    <h1>Qual o dia do pagamento do Cartão Mais Infância?</h1>
                    <p>
                      No dia 15 de cada mês. Caso o dia 15 seja um dia feriado
                      ou no final de semana, o pagamento passa para o primeiro
                      dia útil seguinte.
                    </p>
                  </div>
                  <div>
                    <h1>Onde ir para realizar ou atualizar o cadastro no Cad Único?</h1>
                    <p>
                      Compareça ao CRAS mais perto de você para fazer seu
                      cadastro.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <div className="images">
              <img src={kids} alt="crianças brincando" />
            </div>
          </section>
          
        </main>
       
        </section>
      </section>
      <Footer />
    </>
  );
};

export default CartaoCMIC;
