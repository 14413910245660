import styled from 'styled-components';

interface PercentWidthProps {
  width: string;
}

export const AverageVisualizationContent = styled.div`
  border-left: 3px solid #FFDE32;
  /* width: 50%; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  span {
    margin-left: 8px;
    font-weight: bold;
    font-size: 1.6rem;
    /* line-height: 1rem; */

    color: #464A4E;
  }
`;

export const PercentageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  gap: 10px;

  span {
    font-size: 1.6rem;
    color: #464A4E;
    font-weight: normal;
  }
`;

export const PercentAverage = styled.div`
  width: 100%;
  height: 9px;
  background: #C4C4C4;
  border-radius: 6px;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2rem;

  color: #464A4E;
`;

export const PercentWidth = styled.div<PercentWidthProps>`
  height: 100%;
  background: #FFDE32;
  width: ${props => props.width};

  border-radius: 8px;
`;

