import React from 'react';

import { Select } from 'antd';
import { DateFilter, MunicipioFilter, RegiaoFilter } from '../../components/InputFiltros'


import './styles.scss';


import { useHistoryObra } from 'utils/context/historyObraContext';
import moment from 'moment';


type FilterObrasHistoryProps = {
  idObra: string
}

const FilterObrasHistory = ({idObra}: FilterObrasHistoryProps) => {
  
  const {
    selectedCitiesList,
    selectedStatusList,
    selectedTime,
    selectedRegiaoList,
    setSelectedCitiesList,
    setSelectedStausList,
    filterEvents,
    setSelectedRegiaoList,
    setSelectedTime
  } = useHistoryObra();
  

  /* Opçoes de Status do filtro  */
  const SelectStatusOptions = [
    { value: 'Estágio de Execução', label: 'Estágio de Execução' },
    { value: 'Em Execução', label: 'Em Execução' },
    { value: 'Entregue a população', label: 'Entregue à População' },
    { value: 'Aguardando Inauguração', label: 'Aguardando Inauguração' },
    { value: 'Aguardando Ordem de Serviço', label: 'Aguardando Ordem de Serviço' },
    { value: 'Em Licitação', label: 'Em Licitação' },
    { value: 'Não Iniciado', label: 'Não Iniciado' },
    { value: 'Paralisado', label: 'Paralisado' },
  ];

  const onSelectedMunicipio = (value: string[]) => {
    setSelectedCitiesList(value);
  };

  const onSelectedStatus = (value: string[]) => {
    setSelectedStausList(value);
  };

  const tagsCities = selectedCitiesList.map(city => {
    return (
      <p>{city.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase())}</p>
    );
  });

  const tagsStatus = selectedStatusList.map(status => {
    return <p>{status}</p>;
  });

  const tagsRegioes = selectedRegiaoList.map(regiao => {
    return (
      <p>{regiao}</p>
    )
  })

  return (
    <div className="history-list-filter">
      <section>
        <DateFilter setSelectedTime={setSelectedTime} />
        <RegiaoFilter setSelectedRegiaoList={setSelectedRegiaoList}/>
        <MunicipioFilter setSelectedMunicipio={onSelectedMunicipio} />
        <div>
          <strong>Estágio de execução</strong>
          <Select
            mode={'tags'}
            placeholder="Selecione o estágio de execução"
            options={SelectStatusOptions}
            maxTagCount={1}
            onChange={onSelectedStatus}
            allowClear
          />
        </div>
        
        <button onClick={filterEvents}>FILTRAR</button>
      </section>
      <section>
       {
          selectedTime?.length > 1 && (
            <p> De {moment(new Date(selectedTime[0])).format('yyyy-MM')} até {moment(new Date(selectedTime[1])).format('yyyy-MM')}</p>
          )
        }
        {tagsRegioes}
        {tagsCities}
        {tagsStatus}
      </section>
    </div>
  );
};

export default FilterObrasHistory;
