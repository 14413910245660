import React from 'react'

import './styles.scss';

interface CardInfoProps {
  image?: string
  title: string
  number: string | JSX.Element
}

export const CardInfo = ({
    image,
    title,
    number
  }: CardInfoProps): JSX.Element => {
    
  return(
    <section className="container__cardInfo" >
      { image && <img src={image} alt="Icon do card" /> }
      
      <div className="body-info">
        <h1>{title}</h1>
        <div className="percent">
          <h2>{number}</h2>
          <p>%</p>
        </div>
      </div>
    </section>
  )
}