import { citiesNamesCE } from 'utils/citiesNames';

const generalInfos = {
  fields: [
    {
      key: 'equipamento',
      label: 'Equipamento',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Equipamento é obrigatório',
        },
      ],
    },
    {
      key: 'tipo',
      label: 'Tipo',
      viewWidget: 'simple-view',
      widget: 'autocomplete',
      options: [
        {value: 'CEI'}, 
        {value: 'Brinquedopraça'}, 
        {value: 'Praça mais Infancia'}, 
        {value: 'Complexo Mais Infancia'}, 
        {value: 'Polo de Lazer'}],
      rules: [
        {
          required: true,
          message: 'O campo Tipo é obrigatório',
        },
      ],
    },
    {
      key: 'municipio',
      label: 'Município',
      widget: 'select',
      options: citiesNamesCE,
      viewWidget: 'simple-view',    
      widgetProps: { showSearch: true }  
    },
    {
      key: 'dataInauguracao',
      label: 'Data inauguração',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'qtdMudasPlantadas',
      label: 'Quantidade de mudas plantadas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 }
    },
    {
      key: 'qtdMudasDoadas',
      label: 'Quantidades de mudas doadas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 }
    },
  ],
};

export function educacaoAmbiental() {
  return [{ title: 'Informações gerais', value: generalInfos }];
}
