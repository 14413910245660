import React from 'react';
import { isValidPhone } from '@firestormapps/utils';
import {
  Form,
  FormInstance,
  Row,
  Col,
  Input
} from 'antd';
import { FormBox } from '../../UI/index';
import { InputPhone } from '../../UI'

const { Item } = Form;

interface InfoFormProps {
  form?: FormInstance;
}

export function InfoForm({ form }: InfoFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;


  return(
    <FormBox title="Informações do Responsável" form={currentForm}>
      <Row>
        <Col span={11}>
          <Item
            label="Nome"
            name="nome"
            rules={[{
              required: true,
              message: "Nome do Responsável não preenchido"
            }]}
          >
            <Input />
          </Item>
        </Col>

        <Col span={4} offset={1}>
          <Item
              label="Telefone"
              name="telefone"
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    if (isValidPhone(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Telefone inválido'));
                  }
                }
              ]}
            >
              <InputPhone/>
          </Item>
        </Col>
      </Row>
    </FormBox> 
  )
}