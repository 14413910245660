import { socialApi } from '../api';

const service = () => {
    const resource = '/system';

    async function statusDisable(checked: boolean) {
        try {
          await socialApi.put(`${resource}/${1}?status=${checked}`, {
            
          });
        } catch(err) {
          return Promise.reject(err);
        }
    }

    async function getStatus(){
      try{
      const response = socialApi.get(`${resource}/${1}`)
        return response;
      } catch(err) {
        return Promise.reject(err);
      }
    }
    
    
return {statusDisable, getStatus}
    
}

export const systemService = service();
