const generalInfos = {
  columns: 2,
  formItemLayout: null, // Must set this for inline layout
  colon: true,
  fields: [
    {
      key: 'nome',
      label: 'Nome',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'qtdExibicoes',
      label: 'Número de exibições',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 }
    },
    {
      key: 'qtdDvdsDoados',
      label: 'Número de DVDs doados',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 }
    },
  ],
};

export function documentario() {
  return [{ title: 'Informações', value: generalInfos }];
}
