import React from 'react';
import './styles.scss'
import gif_top from '../../../assets/trecho-gif-dados-gerais.gif';

export default function TopComponent() {

  return (
    <>
      <div className="container__top">
        <div className="image"><img src={gif_top} alt="cartão gif" /></div>
      </div>
    </>
  );
}
