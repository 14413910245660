import React from 'react'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal';

import aproovedIcon from '../../../assets/articulator/sucess.svg'

import {
    AprooveModal,
    ModalBody,
    ModalButton,
    ModalIcon,
    ModalTitle
} from './styles'

interface AproovedModalProps {
    isOpen: boolean;
    closeModal: VoidFunction;
    aprooveWorkplan: VoidFunction;
    style: object;
    contentLabel: string;
    modalTitle: string;
    modalBody: string;
    modalButtonText: string
}

export function AproovedModal({
    isOpen,
    contentLabel,
    closeModal,
    aprooveWorkplan,
    modalBody,
    modalButtonText,
    modalTitle,
    style
}: AproovedModalProps) {
    const history = useHistory()

    function handleOkButton() {
        history.push('/coordenador-home')
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleOkButton}
            style={style}
            contentLabel={contentLabel}
        >
            <AprooveModal>
                <ModalIcon src={aproovedIcon} />
                <ModalTitle>
                    {modalTitle}
                </ModalTitle>
                <ModalBody>
                    {modalBody}
                </ModalBody>
                <ModalButton onClick={handleOkButton}>
                    {modalButtonText}
                </ModalButton>
            </AprooveModal>
        </Modal>
    )
        
}