import React from 'react';

import './styles.scss';

interface CardSmallHorizontalProps {
  number: string | JSX.Element;
  title: string;
  type?: 'porc' | 'meses' | 'horas' | 'text';
  style?: {};
}

const CardSmallHorizontal = ({
  number,
  title,
  type,
  style,
}: CardSmallHorizontalProps): JSX.Element => {
  return (
    <>
      <section className="container__smallHorizontal" style={style}>
        <div className="container__smallHorizontal-left">
          {type === 'meses' ? (
            <>
              <h1>{number}</h1>
              <p>{number === '1' ? "mês" : "meses"}</p>
            </>
          ) : type === 'horas' ? (
            <>
              <h1>{number}</h1>
              <p>{number === '1' ? "hora" : "horas"}</p>
            </>
          ) : type === 'porc' ? (
            <div className="percent">
              <h1>{number}</h1>
              <p>%</p>
            </div>
          ) : (
            <>
              <h1>{number}</h1>
              {type ? <p>{type}</p> : <></>}
            </>
          )}
        </div>
        <div className="container__smallHorizontal-right">
          <p>{title}</p>
        </div>
      </section>
    </>
  );
};

export default CardSmallHorizontal;
