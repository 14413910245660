
import {  notification } from 'antd';

interface NotificationToastProps {
    type: string;
    message: string;
    description?: string;
}

export const notificationToast = ({type, message, description}: NotificationToastProps) => {
    notification[type]({
      message,
      description:
      description ?? null,
    });
  };
