
import React, { useContext } from 'react';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { TimelineContext } from '../../utils/context/timelineContext';

import './style.scss';

import regioesCeara from '../../data/regioes_municipios.json';

const { RangePicker } = DatePicker;
const { Option } = Select;

const FilterTimiline = () => {

  const { setDataRange, setRegionSelected, regionSelected, setCitiesSelecteds} = useContext(TimelineContext);
  
 
  const filterCity = regioesCeara.map(city => {
    return city['região'].toUpperCase(); 
  });

  const onSelectedTime = (value: any) => {
  
    if(value?.length > 1) {
      let dataI = moment(new Date(value[0])).format('yyyy-MM');
      let dataF = moment(new Date(value[1])).format('yyyy-MM');
      const dataAtual = dataI + ';' + dataF;

      setDataRange(dataAtual);
    }
  };

  
  const onSelectedCity = (value: string) => {
    setRegionSelected(value);
    if(value.length === 0){
      setCitiesSelecteds([])
    }else{
      setCitiesSelecteds(regioesCeara.filter(r => r['região'].toUpperCase() === value)[0].municipios); 
    }
  };


  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment('2015-01');
  }
  
  return (
    <>
        <div className="filer-timiline">
          <RangePicker 
            locale={locale} 
            picker="month" 
            bordered={false} 
            disabledDate = {disabledDate} 
            onChange={onSelectedTime} 
            defaultValue={[moment('2015-01', 'yyyy-MM'), moment(moment([]).format('yyyy-MM'), 'yyyy-MM')]}/>

          <Select placeholder="Selecione uma região" onChange={onSelectedCity} style={{ width: 250 }} value={regionSelected}>
           <Option value={''} style={{ color: "#aaaaaa" }} >TODAS AS REGIÕES</Option>
            { filterCity.sort().map( (cityName, idx) => 
              (
                <Option key={idx} value={cityName}>{cityName}</Option>
              ))
            }
          </Select>
        </div>
    </>
  );
};

export default FilterTimiline;
