import styled from 'styled-components';

export const AprooveModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const ModalIcon = styled.img`
  width: 100px;
`;

export const ModalTitle = styled.h2`
  margin: 20px 0;
  font-weight: 700;
  color: #465564;
`;

export const ModalBody = styled.p`
  margin-bottom: 10px;
  text-align: center;
  max-width: 280px;
  color: #464A4E;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
`;

export const ModalButton = styled.button`
  margin-top: 18px;
  width: 10.4rem;
  height: 3.6rem;
  background: #87C878;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #FFFFFF;
  text-transform: uppercase;
`;