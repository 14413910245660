import { socialApi } from "services/api";
import { Coordinator } from "types";

const service = () => {
  const resource = '/coordinators';

  async function getCoordinators() {
    try {
      const { data } = await socialApi.get<Coordinator[]>(`${resource}`);

      return data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getCoordinator(id: string) {
    try {
      const { data } = await socialApi.get<Coordinator>(`${resource}/id/${id}`);

      return data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getCoordinatorByCPF(cpf: string) {
    try {
      const { data } = await socialApi.get<Coordinator>(`${resource}/cpf/${cpf}`);

      return data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function createCoordinators(cpf: string) {
    try {
      const { data } = await socialApi
        .post<Coordinator>(`${resource}`, { cpf });

      return data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  return {
    getCoordinators,
    getCoordinator,
    getCoordinatorByCPF,
    createCoordinators,
  };
}

export const coordinatorService = service();
