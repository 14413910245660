import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd'
import { InputCPF, InputPhone } from 'components';
import React from 'react'
import locale from 'antd/es/locale/pt_BR';
//import rules from '../rules';
import { StyledForm, StyledFormWrapper, StyledTitle, StyledVerticalLine } from 'components/UI/FormBox/styles';
import {MarkedVisitationPerFamily } from 'types';

const { Item } = Form;
const { Option } = Select
const { Title } = Typography;

export function PersonalForm() {
    

    return (
        <>
            <StyledTitle>
                <Title level={3}>Dados Pessoais</Title>
            </StyledTitle>
            <StyledFormWrapper>
                <StyledVerticalLine />
                <StyledForm>
                        <Item label="Família" name="familia">
                            <Input>
                              {}
                            </Input>
                        </Item>
                </StyledForm>
            </StyledFormWrapper>
        </>
    )
}