import React, { memo, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import './styles.scss';

const HamburguerMenu = () => {
  const [sandwichMenuIsOn, handleToogleSandwichMenuIsOn] = useState(false);
  const [showSubMenuSandObras, setShowSubMenuSandObras] = useState(false);

  return (
    <>
      <div
        onClick={() => handleToogleSandwichMenuIsOn(!sandwichMenuIsOn)}
        className={sandwichMenuIsOn ? 'menu-toggle on' : 'menu-toggle'}
      >
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
      </div>

      <nav className={sandwichMenuIsOn ? 'nav-toggle on' : 'nav-toggle'}>
        <strong>
          <Link
            to="/programa-mais-infancia"
            className="after"
            onClick={() => handleToogleSandwichMenuIsOn(false)}
          >
            Início
          </Link>
        </strong>
        <div className="options-menu">
          <div
            className={
              showSubMenuSandObras ? 'option-title selected' : 'option-title'
            }
            onClick={() => setShowSubMenuSandObras(!showSubMenuSandObras)}
          >
            <strong>Equipamentos e Ações</strong>{' '}
            {!showSubMenuSandObras ? (
              <MdKeyboardArrowDown size={25} />
            ) : (
              <MdKeyboardArrowUp size={25} />
            )}
          </div>
          <div
            className={
              showSubMenuSandObras ? 'option-body open' : 'option-body'
            }
          >
            <Link
              onClick={() => handleToogleSandwichMenuIsOn(false)}
              to="/dados-gerais"
            >
              Indicadores
            </Link>
            <Link
              onClick={() => handleToogleSandwichMenuIsOn(false)}
              to="/obras-detalhamento"
            >
              Linha do Tempo
            </Link>
             <Link
                onClick={() => handleToogleSandwichMenuIsOn(false)}
                to="/obras"
              >
                Mapa
              </Link>
          </div>
        </div>
        <strong>
          <Link
            to="/cartao-cmic"
            className="after"
            onClick={() => handleToogleSandwichMenuIsOn(false)}
          >
            Cartão mais infância
          </Link>
        </strong>
        <strong>
          <a className="after" href="google.com">
            Acesso à Informação
          </a>
        </strong>
        <strong>
          <a className="after" href="google.com">
            Mapa do Site
          </a>
        </strong>
        <strong>
          <a href="google.com">Políticas de Privacidade</a>
        </strong>
      </nav>
    </>
  );
};

export default memo(HamburguerMenu);
