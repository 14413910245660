import {
  arteNaPraca,
  bandaMusicaEscola,
  documentario,
  educacaoAmbiental,
  nucleoEstimulacaoPrecoce,
  praiaAcessivel,
  seminarioMaisInfancia,
  padin,
  sementesDoAmanha,
  maisNutricao,
  capacitacaoAgentesComunitariosSaude,
  publicacoes,
  prevencaoEmFamilia,
  criancaFeliz,
  capacitacao,
  formacaoSeduc,
  valeGas,
} from '../GenerateFields';
import { lancamentoLivro } from '../GenerateFields/FieldsLancamentoLivro';

type infosActionsType = { 
  nome: string,
  value: string,
  functionValue: any,
  columns: {
    title: string,
    dataIndex: string,
    key?: string,
    render?:  any
  }[],
}

export const infosActions: infosActionsType[] = [
  {
    nome: 'Arte na Praça',
    value: 'arteNaPraca',
    functionValue: arteNaPraca(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome',
      },
      {
        title: 'Programação',
        dataIndex: 'programacao',
      },
      {
        title: 'Data',
        dataIndex: 'data',
        render: function(text, record, index) {
          return new Date(text).toLocaleDateString('pt-br')
        }
      },
    ]
  },
  {
    nome: 'Criança Feliz',
    value: 'criancaFeliz',
    functionValue: criancaFeliz(),
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'tipo',
      },
    ]
  },
  {
    nome: 'Toda Banda de Música é uma escola',
    value: 'bandaMusicaEscola',
    functionValue: bandaMusicaEscola(),
    columns: [
      {
        title: 'Municipios',
        dataIndex: 'municipios',
        render: function(text, record, index) { 
            return text ? `${text}` : "Sem municipios"
        }
      },
      {
        title: 'Instrumentos doados',
        dataIndex: 'qtdInstrumentosDoados',
      },
      {
        title: 'Regentes envolvidos',
        dataIndex: 'qtdRegentesEnvolvidos',
      },
    ]
  },
  {
    nome: 'Capacitação dos Agentes Comunitários de Saúde',
    value: 'capacitacaoAgentesComunitariosSaude',
    functionValue: capacitacaoAgentesComunitariosSaude(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome',
      },
      {
        title: 'Formato',
        dataIndex: 'formato',
      },
      {
        title: 'Municipios',
        dataIndex: 'municipios',
        render: function(text, record, index) { 
            return text ? `${text}` : "Sem municipios"
        }
      },
      
    ]
  },
  {
    nome: 'Capacitação',
    value: 'capacitacao',
    functionValue: capacitacao(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'Descrição',
        dataIndex: 'descricao', 
      },
      {
        title: 'Publico alvo',
        dataIndex: 'publicoAlvo', 
      },
    ]
  },
  {
    nome: 'Formação Seduc',
    value: 'formacaoSeduc',
    functionValue: formacaoSeduc(),
    columns: [
      {
        title: 'Titulo',
        dataIndex: 'tituloDaFormacao', 
      },
    ]
  },
  {
    nome: 'Documentários',
    value: 'documentario',
    functionValue: documentario(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'DVDs doados',
        dataIndex: 'qtdDvdsDoados', 
      },
      {
        title: 'Exibições',
        dataIndex: 'qtdExibicoes', 
      }
    ]
  },
  {
    nome: 'Lançamento de Livro ou Relatório',
    value: 'lancamentoLivroRelatorio',
    functionValue: lancamentoLivro(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'Edição',
        dataIndex: 'edicao', 
      },
    ]
  },
  {
    nome: 'Núcleos de Estimulação Precoce',
    value: 'nucleoEstimulacaoPrecoce',
    functionValue: nucleoEstimulacaoPrecoce(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'Município',
        dataIndex: 'municipio', 
      },
      {
        title: 'Localização',
        dataIndex: 'localizacao', 
      },
      {
        title: 'Data',
        dataIndex: 'mes',
        render: function(text, record, index) {
     
          return `${record.mes}/${record.ano}`
        }
      },

    ]
  },
  {
    nome: 'Praia Acessível',
    value: 'praiaAcessivel',
    functionValue: praiaAcessivel(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
        
      },
      {
        title: 'Data',
        dataIndex: 'data', 
        render: function(text, record, index) {return new Date(text).toLocaleDateString('pt-BR')}
      },
      {
        title: 'Data Comemorativa?',
        dataIndex: 'dataComemorativa', 
        render: function(text, record, index) {return text ? 'Sim' : 'Não'}
      },

    ]
  },
  {
    nome: 'Prevenção em Família',
    value: 'prevencaoEmFamilia',
    functionValue: praiaAcessivel(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
        
      },
    ]
  },
  {
    nome: 'Sementes do Amanhã',
    value: 'sementesDoAmanha',
    functionValue: sementesDoAmanha(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'Edição',
        dataIndex: 'edicao', 
      },
      {
        title: 'Descrição do projeto',
        dataIndex: 'descricaoProjeto', 
      },

    ]
  },
  {
    nome: 'Seminário Internacional Mais Infância Ceará',
    value: 'seminarioMaisInfancia',
    functionValue: seminarioMaisInfancia(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'Edição',
        dataIndex: 'edicao', 
      },
      {
        title: 'Descrição do objetivo',
        dataIndex: 'descricaoObjetivo', 
      },
    ]
  },
  {
    nome: 'Educação Ambiental',
    value: 'educacaoAmbiental',
    functionValue: educacaoAmbiental(),
    columns: [
      {
        title: 'Municipio',
        dataIndex: 'municipio', 
      },
      {
        title: 'Equipamento',
        dataIndex: 'equipamento', 
      },
      {
        title: 'Inauguração',
        dataIndex: 'dataInauguracao',
        render: function(text, record, index) {return new Date(text).toLocaleDateString('pt-BR')}
      },

    ]
  },
  {
    nome: 'Padin',
    value: 'padin',
    functionValue: padin(),
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'tipo', 
      },

    ]
  },
  {
    nome: 'Publicações',
    value: 'publicacoes',
    functionValue: publicacoes(),
    columns: [
      {
        title: 'Título',
        dataIndex: 'titulo', 
      },
      {
        title: 'Edição',
        dataIndex: 'edicao', 
      },
    ]
  },
  {
    nome: 'Mais Nutrição',
    value: 'maisNutricao',
    functionValue: maisNutricao(),
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'tipo', 
      },
    ]
  },
  {
    nome: 'Prevenção em Família',
    value: 'prevencaoEmFamilia',
    functionValue: prevencaoEmFamilia(),
    columns: [
      {
        title: 'Nome',
        dataIndex: 'nome', 
      },
      {
        title: 'Edição',
        dataIndex: 'edicao', 
      },
      {
        title: 'Descrição',
        dataIndex: 'descricaoProjeto', 
      },
    ]
  },
  {
    nome: 'Vale Gás',
    value: 'valeGas',
    functionValue: valeGas(),
    columns: [
      {
        title: 'Famílias atendidas',
        dataIndex: 'numeroFamiliasAtendias', 
      },
      {
        title: 'Recargas distribuídas',
        dataIndex: 'numeroRecargasDistribuidas', 
      },
    ]
  },
];
