import { LoaderCMIC } from "components/LoaderCMIC";
import React from "react";


function toPercentageCMIC(numberStr: string, dataList: any[]) {



  if(dataList?.length === 0){
    return '- ';
  }

  if(dataList === undefined) {
    return <LoaderCMIC />
  }

  if(!numberStr?.includes('.')) {
    return numberStr
  }
  
  if (numberStr?.includes('.0') || numberStr?.includes('.1') || numberStr?.includes('.9')){
    return parseFloat(numberStr).toFixed();
  }

  return parseFloat(numberStr).toFixed(1);
}

const findData = (question: string, dataList) => {
  const data:string = (dataList?.find(value => value.questao === question)?.resposta) 

  return toPercentageCMIC(data, dataList);

}

export default findData;