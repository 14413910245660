import { MaritalStatus } from '../../types';
import { citizenByUserApi } from '../api';

const service = () => {
  async function getMaritalStatus() {
    try {
      const response = await citizenByUserApi.get<MaritalStatus[]>('/marital-status');

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    getMaritalStatus,
  }
}

export const utilsCitizenService = service();