import { citizenByUserApi } from "services/api";
import { CitizenRegister } from "types";

const service = () => {

  async function registerCitizen(data: any) {
    try {
      const response = await citizenByUserApi.post<CitizenRegister>(`/citizen`, data);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    registerCitizen,
  }
}

export const registryService = service();
