import { localizacaoGlobal } from '../FieldsGlobal';

import locale from 'antd/lib/date-picker/locale/pt_BR';

const generalInfos = {
  fields: [
    {
      key: 'nome',
      label: 'Nome',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo Nome é obrigatório',
        },
      ],
    },
    {
      key: 'programacao',
      label: 'Programação',
      widget: 'textarea',
      viewWidget: 'simple-view',
    },
    {
      key: 'data',
      label: 'Data',
      widget: 'date-picker',
      viewWidget: 'date-view',
      widgetProps: { locale: locale }
    }
  ],
};

export function arteNaPraca() {
  return [
    { title: 'Informações gerais', value: generalInfos },
    { title: 'Localização', value: localizacaoGlobal },
  ];
}
