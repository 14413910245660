import React from 'react';
import {
  Form,
  FormInstance,
  InputNumber,
  DatePicker,
  Typography
} from 'antd';
import { FormBox } from '../../UI/index';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { RangePicker } = DatePicker;
const { Title } = Typography;

interface NucleoFormConfirmProps {
  form?: FormInstance;
  data: any
}

const { Item } = Form;

export function NucleoFormConfirm({ form, data }: NucleoFormConfirmProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  return (
    <FormBox title="Pessoas beneficiadas por edição" form={currentForm}>
      {data.nucleoEstimulacaoPrecoce.criancasAtendidas.porEdicaoCriancas.map((item, index) => 
      <React.Fragment key={index}>
        <div className="butto-control">
          <Title level={5}>
            Edição {item.edicao}
          </Title>
        </div>
        <Item
          label="Período"
          name={`periodo-${item.edicao-1}`}
          wrapperCol={{ span: 6 }}
          rules={[ { required: true, message: 'Campo não preenchido' } ]}>
          <RangePicker bordered={false} disabled={true} locale={locale}/>
        </Item>
        <Item
          label="Número de Atendimentos"
          name={`atendimento-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
        <Item
          label="Crianças Atendidas"
          name={`kid-atendimento-${item.edicao-1}`}>
          <InputNumber placeholder="0" bordered={false} disabled={true} min={0}/>
        </Item>
      </React.Fragment>
    )}
    </FormBox> 
  );
};