import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col } from 'antd';

import { FormBox, InputCPF, InputCEP, InputPhone, Loading } from 'components';
import { ArticulatorValues } from 'pages/Articulator/helper';
import { Content } from './styles';
import { utilsCitizenService } from 'services';
import { MaritalStatus } from 'types';

interface ConfirmFormProps {
  data: ArticulatorValues | null;
  articulator: boolean;
}

const { Item } = Form;

export function ConfirmForm({ data, articulator }: ConfirmFormProps) {
  const [loading, setLoading] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus[]>([]);

  useEffect(() => {
    setLoading(true);
    utilsCitizenService
      .getMaritalStatus()
      .then(data => setMaritalStatus(data))
      .finally(() => setLoading(false));
  }, [])

  if (!data) {
    return null;
  }

  if (loading) {
    return <Loading />
  }

  const { personal, professional, family } = data;

  const initialPersonal = {
    ...personal,
    birthdate: personal.birthdate.format('DD/MM/YYYY'),
    maritalStatus: maritalStatus.find(d => d.id === personal.maritalStatus)?.label
  }

  const initialProfessional = {
    ...professional,
    admissionDate: professional.admissionDate.format('DD/MM/YYYY'),
  }

  const initialFamily = !articulator ? {} : {
    ...family,
    hasChildren: family.hasChildren ? 'Sim' : 'Não',
  }

  return (
    <Content>
      <FormBox<typeof initialPersonal>
        title="Dados Pessoais"
        initialValues={initialPersonal}
      >
        <Item>
          <Item label="CPF" name="cpf">
            <InputCPF bordered={false} disabled />
          </Item>

          <Item label="Nome" name="name">
            <Input bordered={false} disabled />
          </Item>

          <Row>
            <Col xxl={11} lg={11} md={24} xs={24}>
              <Item label="Data de Nascimento" name="birthdate">
                <Input bordered={false} disabled />
              </Item>
            </Col>

            <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
              <Item label="Estado Civil" name="maritalStatus">
                <Input bordered={false} disabled />
              </Item>
            </Col>
          </Row>

          <Row>
            <Col xxl={11} lg={11} md={24} xs={24}>
              <Item label="E-mail" name="email">
                <Input bordered={false} disabled />
              </Item>
            </Col>
            <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
              <Item label="Celular" name="phone">
                <InputPhone bordered={false} disabled />
              </Item>
            </Col>
          </Row>
        </Item>
      </FormBox>

      <FormBox<typeof initialProfessional>
        title="Dados Profissionais"
        initialValues={initialProfessional}
      >
        <Row>
          <Col xxl={11} lg={11} md={24} xs={24}>
            <Item label="Profissão" name="occupation">
              <Input bordered={false} disabled />
            </Item>
          </Col>

          <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
            <Item label="Formação / Graduação" name="graduation">
              <Input bordered={false} disabled />
            </Item>
          </Col>
        </Row>

        <Row>
          <Col xxl={11} lg={11} md={24} xs={24}>
            <Item label="Data de Admissão" name="admissionDate">
              <Input
                bordered={false}
                disabled
              />
            </Item>
          </Col>
          <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
            <Item label="Carga Horária (Horas semanais)" name="workload">
              <Input bordered={false} disabled />
            </Item>
          </Col>
        </Row>

        <Item
          label="Município Alocado"
          name="city"
          wrapperCol={{ xxl: 11, xl: 11, lg: 11, md: 24, xs: 24 }}
        >
          <Input bordered={false} disabled />
        </Item>
      </FormBox>

      {articulator && (
        <FormBox<typeof data.address>
          title="Residência"
          initialValues={data.address}
        >
          <Item
            label="CEP"
            name="zipCode"
            wrapperCol={{ xxl: 6, xl: 6, lg: 6, md: 24, xs: 24 }}
          >
            <InputCEP bordered={false} disabled />
          </Item>

          <Row>
            <Col xxl={17} xl={17} lg={17} md={24} xs={24}>
              <Item label="Endereço" name="address">
                <Input bordered={false} disabled />
              </Item>
            </Col>

            <Col
              xxl={{ span: 6, offset: 1 }}
              xl={{ span: 6, offset: 1 }}
              lg={{ span: 6, offset: 1 }}
              md={24}
              xs={24}
            >
              <Item label="Número" name="addressNumber">
                <Input bordered={false} disabled />
              </Item>
            </Col>
          </Row>

          <Row>
            <Col xxl={11} lg={11} md={24} xs={24}>
              <Item label="Complemento" name="addressComplement">
                <Input bordered={false} disabled />
              </Item>
            </Col>

            <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
              <Item label="Bairro" name="district">
                <Input bordered={false} disabled />
              </Item>
            </Col>
          </Row>

          <Row>
            <Col xxl={11} lg={11} md={24} xs={24}>
              <Item label="UF" name="addressStateId">
                <Input bordered={false} disabled />
              </Item>
            </Col>

            <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
              <Item label="Cidade" name="addressCityId">
                <Input bordered={false} disabled />
              </Item>
            </Col>
          </Row>
        </FormBox>
      )}

      {articulator && (
        <FormBox<typeof initialFamily>
          title="Dados Familiares"
          initialValues={initialFamily}
        >
          <Item label="Tem filhos com idade até 6 anos?" name="hasChildren">
            <Input bordered={false} disabled />
          </Item>

          <Item
            label="Quantos?"
            name="numChildren"
            hidden={!data.family.hasChildren}
          >
            <Input bordered={false} disabled />
          </Item>
        </FormBox>
      )}
    </Content>
  );
}
