import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  FormInstance,
  notification,
} from 'antd';
import cep from 'cep-promise';

import { UF, City } from '../../../types';
import { FormBox, InputCEP } from '../..';
import { localService } from '../../../services';
import rules from './rules';

interface AddressFormProps {
  form?: FormInstance;
}

const { Item } = Form;
const { Option } = Select;

export function AddressForm({ form }: AddressFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const [loadingUf, setLoadingUf] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [selectedUf, setSelectedUf] = useState<UF>();
  const [selectedCity, setSelectedCity] = useState('');
  const [ufs, setUfs] = useState<UF[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  useEffect(() => {
    setLoadingUf(true);
    localService
      .getBrazilStates()
      .then(data => setUfs(data))
      .finally(() => setLoadingUf(false));
  }, []);

  useEffect(() => {
    if (selectedUf) {
      setLoadingCity(true);
      localService
        .getCitiesByUF(selectedUf.id)
        .then(data => setCities(data))
        .finally(() => setLoadingCity(false));
    }
  }, [selectedUf]);

  useEffect(() => {
    if (cities.length > 0 && selectedCity !== '') {
      const choosenCity = cities.find(
        city => city.name.toLowerCase() === selectedCity,
      );

      currentForm.setFieldsValue({
        city: `${choosenCity?.name}-${choosenCity?.id}`,
      });
      setSelectedCity('');
    }
  }, [cities, selectedCity, form]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleCepChange(cepValue: string) {
    if (cepValue.length >= 8) {
      cep(cepValue).then((data: any) => {
        if (data.state !== 'CE') {
          return notification.error({
            message: 'CEP não pertence ao estado do Ceará',
            placement: 'bottomRight',
          });
        }

        const state = localService.initialState[data.state];
        const choosenUf = ufs.find(uf => uf.name === state);

        currentForm.setFieldsValue({
          ...data,
          state: `${choosenUf?.name}-${choosenUf?.id}`,
        });

        setSelectedUf(choosenUf);
        setSelectedCity(data.city.toLowerCase());
      });
    }

    if (cepValue.length === 0) {
      currentForm.resetFields();
    }
  }

  function handleUfChange(value: string) {
    const [, id] = value.split('-');

    const choosenUf = ufs.find(uf => uf.id === parseInt(id, 10));

    if (choosenUf) {
      setSelectedUf(choosenUf);
      currentForm.setFieldsValue({ city: undefined });
    }
  }

  return (
    <FormBox title="Residência" form={currentForm}>
      <Item
        label="CEP"
        name="cep"
        wrapperCol={{ xxl: 6, xl: 6, lg: 6, md: 24, xs: 24 }}
        rules={rules.cep}
      >
        <InputCEP onChange={handleCepChange} />
      </Item>

      <Row>
        <Col xxl={17} xl={17} lg={17} md={24} xs={24}>
          <Item label="Endereço" name="street" rules={rules.street}>
            <Input />
          </Item>
        </Col>

        <Col
          xxl={{ span: 6, offset: 1 }}
          xl={{ span: 6, offset: 1 }}
          lg={{ span: 6, offset: 1 }}
          md={24}
          xs={24}
        >
          <Item
            label="Número"
            name="house-number"
            rules={rules['house-number']}
          >
            <Input />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item label="Complemento" name="house-complement">
            <Input />
          </Item>
        </Col>

        <Col
          xxl={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
          md={24}
          xs={24}
        >
          <Item label="Bairro" name="neighborhood" rules={rules.neighborhood}>
            <Input />
          </Item>
        </Col>
      </Row>

      <Row>
        <Col xxl={11} lg={11} md={24} xs={24}>
          <Item label="UF" name="state" rules={rules.state}>
            <Select loading={loadingUf} onChange={handleUfChange} showSearch>
              {ufs.map(uf => (
                <Option value={`${uf.name}-${uf.id}`} key={uf.id}>
                  {`${uf.name}`}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col
          xxl={{ span: 11, offset: 2 }}
          lg={{ span: 11, offset: 2 }}
          md={24}
          xs={24}
        >
          <Item label="Município" name="city" rules={rules.city}>
            <Select loading={loadingCity} disabled={!selectedUf} showSearch>
              {cities.map(city => (
                <Option value={`${city.name}-${city.id}`} key={city.id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </FormBox>
  );
}
