import React from 'react';
import {
  Form,
  FormInstance,
  Input,
  DatePicker,
  Row,
  Col
} from 'antd';
import { FormBox, InputMoney } from '../../UI/index';
import rules from './rules'
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface ConstValuesFormProps {
  form?: FormInstance;
}

export function ConstValuesFormEdit({ form }: ConstValuesFormProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const valueMoney = (e) => {
    currentForm.setFieldsValue(e)
  }

  return (
    <FormBox title="Informações do Evento" form={currentForm}>
      <Item
        label="Nome"
        name="nome"
        wrapperCol={{ span: 11 }}
      >
        <Input />
      </Item>
      <Item
        label="Período"
        name="periodo"
        wrapperCol={{ span: 7 }}
        rules={rules.periodo}
      >
        <RangePicker disabled={true} locale={locale}/>
      </Item>
      <Row>
        <Col span={3}>
          <Item
            label="Valor Investido"
            name="valorInvestido"
          >
            <InputMoney onChange={valueMoney}/>
          </Item>
        </Col>
        <Col span={3} offset={1}>
          <Item
            label="Valor Aditivo"
            name="valorAditivo"
          >
            <InputMoney onChange={valueMoney}/>
          </Item>
        </Col>
      </Row>
    </FormBox>
  );
};
