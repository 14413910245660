import React from 'react';
import './styles.scss'

import cartao from '../../../assets/cartaoCMIC.svg';

interface BottomComponentProps {
  children: React.ReactNode;
}

export default function BottomComponent({children}: BottomComponentProps) {

  return (
    <>
      <div className="container__bottom">
        <div className="image"><img src={cartao} alt="cartão" /></div>
        <div className="content-right">
          {children}
        </div>
      </div>
    </>
  );
}
