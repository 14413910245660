import React from 'react';
import './styles.scss';

import ListDados from '../ListDados';
import { Device } from 'components';
import InfoProvide from 'components/InfoProvide';

type Atendimento = {
  id: string;
  nome: string;
  qtdMudasPlantadas: number;
  qtdPessoasCapacitadas: number;
  qtdQuilosDoados: number;
  qtdToneladasDeAlimentosDoados: number;
  qtdVisitasPadin: number;
  qtdVisitasFamiliasPadin: number;
  totalEventos: number;
};

interface MiddleComponentProps {
  title: string;
  type: string;
  children: React.ReactNode;
  value: any;
}

export default function MiddleComponent({
  title,
  type,
  children,
  value
}: MiddleComponentProps) {


  const valuesLeftObras = () => [
   /*  {
      number: value.total.concluidas,
      title: 'Obras',
      subtitle: 'Concluídas',
    },
    {
      number: value.total.emAndamento,
      title: 'Obras',
      subtitle: 'Em andamento',
    },
    {
      number: (value.total.qtdTotalBeneficiados/1000000).toFixed(2),
      title: 'Milhões',
      subtitle: 'de pessoas beneficiadas',
    },
    {
      number: (value.total.investimentoTotal/1000000).toFixed(2),
      title: 'Milhões',
      subtitle: 'de reais investidos',
    }, */
    {
      number:  value.brinquedopraca,
      title: 'Brinquedopraças',
      subtitle: 'Entregues à população',
    },
    {
      number: value.brinquedocreche,
      title: 'Brinquedocreches',
      subtitle: 'Entregues à população',
    },
    {
      number: value.cei,
      title: 'Centros de Educação Infantil',
      subtitle: 'Entregues à população',
    },
    {
      number: value.pracaMaisInfancia,
      title: 'Praças Mais Infância',
      subtitle: 'Entregues à população',
    }, 
    {
      number: value.complexoMaisInfancia,
      title: 'Complexos Mais Infância',
      subtitle: 'Entregues à população',
    },
  ];


  /*
  
    ATENÇÃO: AO VOLTAR A COLOCA ESSES DADOS LEMBRAR TIRAR O MARGIN-LEFT DO H2 DE AÇÃO

  
  */

  const valuesLeftAcoes = () => [
    // {
    //   number: value.total.qtdCriancasVisitadas,
    //   title: 'Crianças',
    //   subtitle: 'Visitadas',
    // },
    // {
    //   number: value.total.qtdCriancasFormadas,
    //   title: 'Crianças',
    //   subtitle: 'formadas no Toda Banda de Musica é uma escola',
    // },
    {
      number: value.totalFS,
      title: 'Formações pela',
      subtitle: 'Secretaria de Educação',
    },
    {
      number: value.qtdVisitasPadin,
      title: 'Total de visitas',
      subtitle: 'registradas pelo Padin',
    },
    {
      number: value.total.qtdMudasPlantadas,
      title: 'Mudas',
      subtitle: 'Plantadas',
    },
    
    {
      number: value.qtdVisitasCriancaFeliz,
      title: 'Total de visitas ',
      subtitle: 'registradas pelo Criança Feliz',
    },
    // {
    //   number: value.qtdDocumentario,
    //   title: 'Documentários',
    //   subtitle: 'publicados',
    // },
    // {
    //   number:  value.qtdArteNaPraca,
    //   title: 'Artes',
    //   subtitle: 'na Praça',
    // },
    // {
    //   number: value.qtdLivros,
    //   title: 'Lançamentos',
    //   subtitle: 'de Livro ou Relatório',
    // },
    // {
    //   number: value.total.qtdMudasPlantadas,
    //   title: 'Mudas',
    //   subtitle: 'Plantadas',
    // },
    // {
    //   number: value.total.qtdPessoasCapacitadas,
    //   title: 'Pessoas',
    //   subtitle: 'Capacitadas',
    // },
  ];

  const device = Device.useDevice();

  return (
    <>
      <section className={'container__middle ' + type}>

      
        <h2 className={title === 'Ações' ? 'acao' : ''}>
          {title}
        </h2>

        { type === 'obras' && 
          <InfoProvide 
            message="Os dados dos equipamentos são fornecidos pelo Sistema WebMapp do Governo do Ceará" 
            type="webmapp"
            style={{paddingLeft: device.isSM('bigger') ? '5rem' : '1rem'}}
          />
        } 
        

        <div className="container__middle-separar">
          <div className="left">
            <ListDados 
            lista={type === "acoes" ? valuesLeftAcoes() : valuesLeftObras()} 
            columns={ device.isMD('bigger') ? 2 : 1} />
          </div>

          <div className="right">{children}</div>
        </div>
      </section>
    </>
  );
}
