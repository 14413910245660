import React from 'react';
import { Row, Col } from 'antd';

import './styles.scss';

interface TableCMIC {
  listInfos: Array<{ title: string; number: number }>;
}

const TableCMIC = ({ listInfos }: TableCMIC): JSX.Element => {
  return (
    <>
      <section className="container__tableCMIC">
        <Row style={{ background: 'var(--green-dark)', color: 'white' }}>
          Pesquisa CMIC em números
        </Row>
        {listInfos.map((valor, index) => (
          <Row
            style={
              (index + 1) % 2 === 0
                ? { background: '#F5F5F5' }
                : { background: 'white' }
            }
          >
            <Col>
              <div>{valor.title}</div>
              <div>{valor.number}</div>
            </Col>
          </Row>
        ))}
      </section>
    </>
  );
};

export default TableCMIC;
