import React from 'react';
import { FormInstance } from 'antd';
import { FormContainer } from './style'
import { InfoBasicEdit } from './InfoBasicEdit';
import {CurrentSituation} from './CurrentSituationEdit'


interface FormProps {
  form?: FormInstance;
}

export function FormObrasEdit({ form }: FormProps) {
  return (
    <FormContainer>
      <InfoBasicEdit form={form} />
    </FormContainer>
  );
};


FormObrasEdit.CurrentSituationForm = function ({ form}: FormProps) {
  return (
    <FormContainer>
      <CurrentSituation form={form} />
    </FormContainer>
  );
}
