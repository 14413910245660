import React, { Factory, useEffect, useState } from 'react';
import { Authorizer, Feedback } from 'components';
import { Link, useHistory } from 'react-router-dom';
import { demandService } from 'services/resources/demandService';
import { Demand, Agent, FamilyType } from 'types';
import { DemandForm } from './DemandForm';
import { demandToForm} from './helper'
import { StepPanel } from './StepPanel';
import { ButtonPanel } from './ButtonPanel';
import { Footer } from 'components';
import './styles.scss'
import {
  Breadcrumb, 
  Form as AntForm,
  Select, 
  Input,
  Form,
  Divider,
  message,
  notification,
  Button,
  Col,
  Row,
  Typography,
  Cascader,
  DatePicker,
  InputNumber,
  Radio,
  Switch,
  TreeSelect,
} from 'antd';


export function ArticulatorForwardingDemands() {
  const [demandForm] = Form.useForm();
  const [step, setStep] = useState(0);
  const [finished, setFinished] = useState(false);
  const [err, setErr] = useState<string>('');
  const history = useHistory();
  const { client, roles = [] } = Authorizer.useAuth().user;
  const { useForm } = AntForm;
  //const { Item } = Form;
  const { Title } = Typography;
  const { Option } = Select;
  const { isPublicServer, isArticulator, isSPSAdmin } = Authorizer.checkRoles;
  
  const articulator = isArticulator(roles);


  function handleCancel() {
    const redirect = isArticulator(roles) ? '/articulador-inicio' : '/coordenador-home';
    history.push(redirect);
  }

  function handleBack() {
    setStep(oldState => (oldState === 0 ? oldState : oldState - 1));
  }

  function onCheck(data: any) {
    const agent : Agent = data.agent.id
    const family : FamilyType = data.family.id;
    const finalData: Demand = {
        id: data.id,
        descricao: data.descricao,
        tipoDaDemanda: data.tipoDemanda,
        statusDaDemanda: data.statusDaDemanda, 
        dataCriacaoDemanda: data.dataCriacaoDemanda,
        dataVisita: data.dataVisita,
        demandaAtendida: data.demandaAtendida,
        orgaoRecebeuDemanda: data.orgaoRecebeuDemanda,
        agent,
        family
    }
    demandService.
    postDemand(finalData)
    .catch(err => setErr(err.message))
    .finally(() => {
    setFinished(true);
    });
  }

  function defineStepOne() {
    
  }

  function defineStepTwo() {
    
  }

  function validateStepOne() {

  }

  function validateStepTwo() {
    
  }

  function handleNext() {
    if (step === 0) {
      defineStepOne()
      validateStepOne();
    } else {
      defineStepTwo()
      validateStepTwo();
    }
  }

  function createDemand() {
    
  }

  function updateArticulator() {

  }

  function handleConfirm () {
    if (isPublicServer(roles) || isSPSAdmin(roles)) {
      return createDemand;
    }

    return updateArticulator;
  }



  return(
    <>

    <div className='BreadCrumbArtiForwDema'>
      <Breadcrumb>
      
        <Breadcrumb.Item>
          <Link to="/articulador-inicio">Página Inicial</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to="/articulador-plano-de-atividades">Plano de Trabalho</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to="/articulador-encaminhamento-de-demandas">Encaminhamento de Demandas</Link>
        </Breadcrumb.Item> 
      </Breadcrumb>

      <h2 className="title">
        Encaminhamento de Demandas
      </h2>
    </div>

    <div className='form-body'>

    <StepPanel step={step} articulator={articulator}/>

    <DemandForm step={step} form={demandForm} finish={onCheck}/>

    <ButtonPanel 
       step={step}
       articulator={articulator}
       onBack={handleBack}
       onNext={handleNext}
       onCancel={handleCancel}
       onConfirm={handleConfirm()}
    />

    </div>

    <Footer/>
    </>
  )

}
