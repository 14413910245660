import React, { useEffect, useState } from 'react';

import {
  UploadOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import {
  deleteFileDocumentario,
  deleteNowFileDocumentario,
  downloadFileAcao,
  postFileDocumentario,
} from 'services/resources/acoesServices';

import { LoadingOutlined } from '@ant-design/icons';
import { Popconfirm, Spin } from 'antd';

import '../styles.scss';

interface FormCreateDocumentarioProps {
  idAcao?: string;
  idEvento?: string;
  setConcluirDisabled: (value: any) => void;
  fileCurrent: any;
}
export function FormCreateDocumentario({
  idAcao,
  idEvento,
  setConcluirDisabled,
  fileCurrent,
}: FormCreateDocumentarioProps) {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const[makeDownload, setMakeDownload] = useState(false);
  const [isUploadFile, setUploadFile] = useState(false);
  const [deletedFile, setDeletedFile] = useState(false);
  const [fileSave, setFileSave] = useState<any>(null);
  const [isSavingFile, setIsSavingFile] = useState(false);

  const antIcon = <LoadingOutlined spin />;

  const onFileChange = event => {
    const modifiedFiles = event.target.files[0];
    setUploadFile(false);
    setSelectedFile(modifiedFiles);
 
  };


  const onRemoveFile = () => {
    deleteFileDocumentario(idAcao, idEvento, fileCurrent.id, setDeletedFile);
  };

  const onRemoveFileLocal = () => {
    setUploadFile(false);
    setSelectedFile(null);
  };


  useEffect(() => {
    if (selectedFile === null) {
      setConcluirDisabled(false);
    } else {
      setConcluirDisabled(true);
    }
  }, [selectedFile]); // eslint-disable-line react-hooks/exhaustive-deps

                                    
  function onDownload(file) {
    setMakeDownload(true)
    downloadFileAcao(idAcao, idEvento, file, setMakeDownload);

    
  }

  return (
    <section className="container__formArquivos">
      <h3 className="ant-typography">Arquivo do documentário</h3>

      <div className="formArquivos-files">
        <label
          htmlFor="selecao-arquivo-doc"
          className={selectedFile ? 'label-files animation' : 'label-files'}
        >
          <UploadOutlined />
          <p>Selecionar um documentário</p>
        </label>
        <input
          id="selecao-arquivo-doc"
          type="file"
          className="input-files"
          onChange={onFileChange}
        ></input>

        <ul className="files-fileNames">
          {selectedFile?.name && (
            <>
              <strong>Novo documentário selecionado</strong>
              <li className="content__namesFiles">
          
              <div>
                <PaperClipOutlined />
                  <h3>{selectedFile?.name}</h3>

                  <button
                    className="remover-file"
                    onClick={() => onRemoveFileLocal()}
                  >
                    <DeleteOutlined />
                  </button>
              </div>
              </li>
            </>
          )}
          {fileSave?.nome && (
            <>
              <strong>Novo documentário adicionado</strong>
              <li className="content__namesFiles">
                <div>
                <PaperClipOutlined />
                  <h3>{fileSave.nome}</h3>
                  <Popconfirm
                    title="Deseja apagar esse documentário?"
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={() =>
                      deleteNowFileDocumentario(
                        idAcao,
                        idEvento,
                        fileSave.id,
                        setFileSave,
                      )
                    }
                  >
                    <button className="remover-file">
                      <DeleteOutlined />
                    </button>
                  </Popconfirm>
                  <button
                    className="download-file"
                    onClick={() => onDownload(fileSave)}
                  >
                    <DownloadOutlined />
                  </button>
                </div>
                {
                  makeDownload && (
                  <div className="download-progress">
                    <div>
                      <div className="line"></div>  
                    </div>
                    <strong>Fazendo download</strong>
                  </div>
                )
              }
              </li>
            </>
          )}

          {fileCurrent?.nome && !isUploadFile && (
            <>
              <strong className="exists">
                {!deletedFile
                  ? 'Documentário já existente'
                  : 'Documentário deletado'}
              </strong>
              <li className="content__namesFiles">
             <div>
             <PaperClipOutlined />
                <h3 className={deletedFile ? 'deleted' : ''}>
                  {fileCurrent.nome}
                </h3>
                <Popconfirm
                  title="Deseja apagar esse arquivo?"
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={onRemoveFile}
                >
                  {!deletedFile && (
                    <button className="remover-file">
                      <DeleteOutlined />
                    </button>
                  )}
                </Popconfirm>
                {!deletedFile && (
                  <button
                    className="download-file"
                    onClick={() => onDownload(fileCurrent)}
                    disabled={makeDownload}
                  >
                    <DownloadOutlined />
                  </button>
                )}
               </div>
              {
                makeDownload && (
                  <div className="download-progress">
                    <div>
                      <div className="line"></div>  
                    </div>
                    <strong>Fazendo download</strong>
                  </div>
                )
              }
              </li>
            </>
          )}
        </ul>

        {selectedFile?.name && !isUploadFile && (
          <Popconfirm
            title="Deseja enviar esse documentário?"
            okText="Sim"
            cancelText="Não"
            onConfirm={() => {
              postFileDocumentario(
                idAcao,
                idEvento,
                selectedFile,
                setUploadFile,
                setFileSave,
                setSelectedFile,
                setIsSavingFile,
              );
            }}
          >
            <button
              type="button"
              className={isSavingFile ? 'enviar-doc disabled' : 'enviar-doc'}
              disabled={isSavingFile}
            >
              {!isSavingFile ? (
                'Enviar documentário'
              ) : (
                <Spin
                  className="spin-button-file"
                  size="small"
                  // tip="Enviar documentário"
                  indicator={antIcon}
                />
              )}
            </button>
          </Popconfirm>
        )}
      </div>
    </section>
  );
}
