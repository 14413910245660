import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

interface InputMoneyProps {
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  onChange?: (value: string | undefined) => void;
}

function maskValor(value: string) {
  var valor = value + '';
  valor = valor.replace(/\D/g, '');
  valor = valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2');
  valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  valor = valor.replace(/^(\d)/g, '$1');
  return valor;
}

export function InputMoney({
  value,
  onChange,
  bordered = true,
  disabled = false,
}: InputMoneyProps) {
  const [text, setText] = useState(value);

  const moneyMask = (value?: string) => {
    if (!value) return undefined;
    return maskValor(value.replace('.', ''));
  };

  const handleChange = e => {
    setText(
      e.target.value.replace(/\D/g, '').replace(/(\d)(\d{1,2})$/, '$1.$2'),
    );
  };

  useEffect(() => {
    setText(text);
    if (onChange) {
      onChange(text);
    }
  }, [text]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Input
      onChange={handleChange}
      value={moneyMask(value)}
      bordered={bordered}
      disabled={disabled}
      placeholder="0,00"
      prefix="R$"
    />
  );
}
