import React, { useContext, useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { TimelineContext } from 'utils/context/timelineContext';

import iconLeft from '../../assets/leftSet.svg';
import iconRight from '../../assets/rightSet.svg';
import './styles.scss';
import Tickmark from './tickmark';
import { ITimelineCard } from './types';

interface ISliderMarke {
  data: ITimelineCard[];
  frameStart: number;
}
const SliderMarke = ({ data, frameStart }: ISliderMarke): JSX.Element => {
  const { currentTick, setCurrentTick } = useContext(TimelineContext);

  const [qtdTicks, setQtdTicks] = useState<number>(0);

  const [isYesClick, setIsYesClick] = useState(false);

  let frame = 0;

  useEffect(() => {
    const qtdTotalMonths = data.length * 12;
    setQtdTicks(qtdTotalMonths);
    setCurrentTick(frameStart);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const elementScroll: any =
    document.getElementById('container__slider-componente') || HTMLInputElement;
  function handleScrollBack() {
    setCurrentTick(currentTick - 8);

    if (elementScroll) {
      elementScroll.scrollLeft -= 380;
    }

    if (elementScroll.scrollLeft === 0) {
      setCurrentTick(0);
    }
  }
  function handleScrollForward() {
    setCurrentTick(currentTick + 8);

    const maxScrollLeft = elementScroll.scrollWidth - elementScroll.clientWidth;

    if (elementScroll) {
      elementScroll.scrollLeft += 380;
    }

    if (
      elementScroll.scrollLeft + 1 >= maxScrollLeft ||
      currentTick > qtdTicks - 1
    ) {
      setCurrentTick(qtdTicks - 1);
    }
  }

  function handleChangeSlider(value: any) {
    if (!isYesClick) {
      setCurrentTick(value);
    }
    setIsYesClick(false);
  }
  function genereteMark() {
    let framesMonths;

    let isTitleYear = true;

    data.forEach((ano: any, index: number) => {
      const currentMonths = ano?.meses;
      const currentYear = ano?.ano;

      currentMonths.forEach((nome: any, i: number) => {
        framesMonths = {
          ...framesMonths,
          [frame]: (
            <Tickmark
              onIsYesClick={setIsYesClick}
              chave={`${index*7}${i*5}`}
              ano={currentYear}
              isTitleYear={isTitleYear}
              mes={nome?.mes ?? null}
              infosFrame={nome}
              value={frame}
              currentValue={currentTick}
              isInitiative={nome.iniciativasRes.length === 0 ? true : false}
            />
          ),
        };
        isTitleYear = false;
        frame += 1;
      });

      isTitleYear = true;

      framesMonths = { ...framesMonths };
    });

    return framesMonths;
  }

  return (
    <section className="container__slider-header">
      <button
        type="button"
        onClick={() => handleScrollBack()}
        className="slider__button-left"
      >
        <img src={iconLeft} alt="seta esquerda" />
      </button>
      <div id="container__slider-componente">
        {qtdTicks > 0 && (
          <Slider
            style={{ width: `${qtdTicks * 6}%` }}
            onChange={handleChangeSlider}
            value={currentTick}
            included={false}
            step={null}
            dots
            max={qtdTicks}
            marks={genereteMark()}
          />
        )}
      </div>
      <button
        type="button"
        onClick={() => handleScrollForward()}
        className="slider__button-right"
      >
        <img src={iconRight} alt="seta esquerda" />
      </button>
    </section>
  );
};

export default SliderMarke;
