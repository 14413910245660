import { citiesNamesCE } from 'utils/citiesNames';


const generalInfos = {
  fields: [
    {
      key: 'titulo',
      label: 'Título',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo "Título" é obrigatório',
        },
      ],
    },
    {
      key: 'subtitulo',
      label: 'Subtítulo',
      viewWidget: 'simple-view',
      
    },
    {
      key: 'editora',
      label: 'Editora',
      viewWidget: 'simple-view',
    },
    {
      key: 'edicao',
      label: 'Edição',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo "Edição" é obrigatório',
        },
      ],
    },
    {
      key: 'linkMaterialIntegral',
      label: 'Link para acesso da versão digital',
      viewWidget: 'simple-view',
    },
    {
      key: 'referencias',
      label: 'Como a publicação pode ser referenciada',
      viewWidget: 'simple-view',
    },
    {
      key: 'autores',
      label: 'Nome dos autores e/ou organizadores',
      widget: 'select',
      widgetProps: { mode: 'tags' },
      viewWidget: 'list-view',
      rules: [
        {
          required: true,
          message: 'O campo "Nome dos autores e/ou organizadores" é obrigatório',
        },
      ],
    },
    {
      key: 'local',
      label: 'Local onde foi publicado',
      viewWidget: 'simple-view',
      rules: [
        {
          required: true,
          message: 'O campo "Local onde foi publicado" é obrigatório',
        },
      ],
    },
    {
      key: 'qtdPaginas',
      label: 'Quantidade de páginas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
      rules: [
        {
          required: true,
          message: 'O campo "Quantidade de páginas" é obrigatório',
        },
      ],
    },
    {
      key: 'ano',
      label: 'Ano da publicação',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
      rules: [
        {
          required: true,
          message: 'O campo "Ano" é obrigatório',
        },
      ],
    },

  ],  
};

const localizacao = {
  fields: [
    {
      key: 'localizacao.municipio',
      label: 'Município',
      widget: 'select',
      options: citiesNamesCE,
      widgetProps: { showSearch: true },
      viewWidget: 'simple-view',
    },
    {
      key: 'localizacao.rua',
      label: 'Rua',
      viewWidget: 'simple-view',
    },
    {
      key: 'localizacao.bairro',
      label: 'Bairro',
      viewWidget: 'simple-view',
    },
    {
      key: 'localizacao.cep',
      label: 'CEP',
      viewWidget: 'simple-view',
    },
    {
      key: 'localizacao.numero',
      label: 'Número',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'localizacao.pontoDeReferencia',
      label: 'Ponto de referencia',
      viewWidget: 'simple-view',
    },
  ],
};

export function publicacoes() {
  return [
    { title: 'Informações gerais', value: generalInfos },
    { title: 'Local de lançamento da publicação (caso houver)', value: localizacao },
  ];
}
