import React from 'react'
import { useHistory } from 'react-router-dom'

import Modal from 'react-modal';
import { WorkplanType } from 'types';

import { Device } from 'components'

import './styles.scss'

import rightArrow from '../../assets/green-right-arrow.svg'
import pendentIcon from '../../assets/articulator/pendent-icon.svg'
import aproovedIcon from '../../assets/articulator/aprooved-icon.svg'
import refusedIcon from '../../assets/articulator/refused-icon.svg'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '440px'
  },
};

const mobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '440px'
  },
};

interface ModalWorkplansHistoricProps {
  isOpen: boolean;
  closeModal: VoidFunction;
  workplans?: WorkplanType[]
}

const WORKPLANIMGS = {
  AWAITING: <img alt='Pending Icon' src={pendentIcon} />,
  APPROVED: <img alt='Approved Icon' src={aproovedIcon} />,
  REFUSED: <img alt='Refused Icon' src={refusedIcon} />
}

export default function ModalWorkplansHistoric({ isOpen, closeModal, workplans }: ModalWorkplansHistoricProps) {

  const history = useHistory()
  const device = Device.useDevice()

  function handleNavigateWorkplan(workplan: WorkplanType) {
    history.push(`/coordenador-articulador-workplan/${workplan.id}`, workplan);
  }

  function getStatusImg(workplan: WorkplanType) {
    return WORKPLANIMGS[workplan.status];
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={ device.isSM() ? modalStyles : mobileModalStyles}
    >
      <div className="modal">

        <h2>Histórico de planos de trabalho</h2>

        <div className={ device.isSM() ? 'workplans-container' : 'mobile-workplans-container'}>

          <div className="workplan-units-container">
            {workplans?.map((workplan, key: number) => (
              <div 
                key={key} className="workplan-box" 
                onClick={() => handleNavigateWorkplan(workplan)}
              >
                <div className="workplan-box-left">
                      {getStatusImg(workplan)}
                </div>
                <div className="workplan-box-right">
                  <p>{workplan.year + workplan.semester*0.1}</p>
                  <img src={rightArrow} alt=""/>
                </div>

              </div>
            ))}
          </div>

        </div>
      </div>
    </Modal>
  )
}