import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Input, Divider, message, Button } from 'antd';

import {
	Container,
	Content,
	Wrapper,
	Main,
	CreateWorkPlanBox,
	DiagnosticBox,
	DiagnosticBoxTitle,
	ItemLabel,
	TopWorkPlanBox,
	CommentContainer,
	ButtonGroupComment,
	CancelButton,
} from './styles';


import { ArticulatorBreadcrumb, ArticulatorComment, GoBack } from '../../components/Articulator';
import { agentService, commentServices } from 'services';
import { CommentProps, IHistoricWorkPlan, RichedProfessional } from 'types';
import { Authorizer, Footer } from 'components';

const { TextArea } = Input;

export function CurrentArticulatorWorkPlan() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client.userId;

  const params = useParams<{ id: string }>();
  const location = useLocation<IHistoricWorkPlan>();
  const [agent, setAgent] = useState({} as RichedProfessional);
	const [countLikes, setCountLikes] = useState(0);
  const [workPlan, setWorkPlan] = useState<IHistoricWorkPlan>();
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<CommentProps[]>([]);
  const [isCommentButtonClicked, setIsCommentButtonClicked] = useState(false);

	const history = useHistory();
	const breadcrumbItems = [
		{
			id: 1,
			to: "/articulador-inicio",
			title: "Página inicial"
		},
		{
			id: 2,
			to: "/articulador-plano-de-trabalho",
			title: "Plano de Trabalho"
		},
		{
			id: 3,
			to: "/articulador-plano-de-trabalho-atual",
			title: "Plano de trabalho atual"
		},
	];

	function handleGoBack() {
		history.push("/articulador-plano-de-trabalho");
	}

	function handleCountLikes() {
		if(countLikes===0)
			setCountLikes(1);
		else
			setCountLikes(0);
	}

  async function getCommentsWorkPlan() {
    if(!params)
      history.goBack();

    const workplanId = params.id;

    try {
      const response = await commentServices.getCommentsByWorkplanId(workplanId);

      setComments(response);
    } catch(err) {
      message.error('Erro ao buscar plano de trabalho atual')
    }
  }

  async function handleCreateComment() {
    if(!comment.trim())
      return;

    setIsCommentButtonClicked(true);
    const workplan = Number(params.id);
    const authorAgent = agent;

    const data = {
      attDate: new Date(),
      creationDate: new Date(),
      content: comment,
      user: authorAgent,
      workplan,
    };

    try { 
      await commentServices.createCommentOnWorkplan(data);

      getCommentsWorkPlan();

    } catch(err) {
      message.error('Não foi possível criar um comentário');
    } finally {
      setIsCommentButtonClicked(false);
      setComment('');
    }
  }

  function handleCancelCreationOfComment() {
    setComment('');
  }

  useEffect(() => {
    getCommentsWorkPlan();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(location && location.state) {
      setWorkPlan(location.state);
    }
  }, [location]);

  useEffect(() => {
    agentService.getProfessional(userId)
      .then(res => {
        if(res) {
          setAgent(res);
        }
      })
      .catch(err => message.error(err?.response.data?.mensagem || 'Cliente logado não é um articulador'))
  }, [userId]);

	return (
		<Container>
			<Content>
				<ArticulatorBreadcrumb
					items={breadcrumbItems}
				/>

				<Wrapper>
					<GoBack
						onClick={handleGoBack}
						title="Plano de trabalho"
						subtitle={`${workPlan?.year}.${workPlan?.semester}`}
					/>

					<Main>
						<CreateWorkPlanBox>
							<TopWorkPlanBox>
								<ItemLabel>
									<label>Número de visitação de famílias previsto para esse semestre</label>
									<span>{workPlan?.numVisits}</span>
								</ItemLabel>
								<ItemLabel>
									<label>Município</label>
									<span>{workPlan?.city}</span>
								</ItemLabel>
							</TopWorkPlanBox>
							<Divider />

							<DiagnosticBox>
								<DiagnosticBoxTitle>
									Diagnóstico do município
								</DiagnosticBoxTitle>

								<TextArea
									disabled
									placeholder="Faça um relato da situação atual do município"
									value={workPlan?.diagnosis} 
                  style={{
                    resize: 'none'
                  }}
								/>
							</DiagnosticBox>

						</CreateWorkPlanBox>

					</Main>
				</Wrapper>
			</Content>
			<CommentContainer>
        <div className="comment-wrapper">
          <ArticulatorComment 
            title="Observações e comentários deste plano de trabalho"
            comments={comments}
            countLikes={countLikes}
            onLike={handleCountLikes}
          />

          <TextArea 
            value={comment} 
            onChange={e => setComment(e.target.value)}
            onPressEnter={handleCreateComment}
            disabled={isCommentButtonClicked}
          />

          <ButtonGroupComment>
            <CancelButton
              type="button"
              onClick={handleCancelCreationOfComment}
            >
              Cancelar
            </CancelButton>

            <Button 
              type="primary" 
              onClick={handleCreateComment}
              className="comment-button"
              loading={isCommentButtonClicked}
              style={{
                background: '#4B9F37'
              }}
            >
              Comentar
            </Button>
          </ButtonGroupComment>
          {/* <VerticalDate>
            {year}.{semester}
          </VerticalDate> */}
        </div>
			</CommentContainer>

      <Footer />
		</Container>
	);
}