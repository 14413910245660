import React, { useCallback, useContext, useState } from 'react';

import { TimelineContext } from 'utils/context/timelineContext';
import { Breadcrumb, Device, Footer } from '../../components';

import './styles.scss';

import Timeline2 from 'components/timeline2.0';
import Timeline2Mobile from 'components/Timeline2Mobile';
import { TitlePage } from 'components/TitlePage';
import InfoProvide from 'components/InfoProvide';
import { getYearAll } from 'services/resources/timelineServices';


const links = [
  {
    path: '/',
    title: 'Página Inicial',
  },
  {
    path: '/obras-detalhamento',
    title: 'Equipamentos e Ações',
  },
];

const ObrasDetalhamento = (): JSX.Element => {
  const device = Device.useDevice();

  const {
    dataRange
  } = useContext(TimelineContext);

  const [ loading, setLoading] = useState(true);
  const [allDataByYearDesktop, setAllDataByYearDesktop] = useState<any>([]);
  const [allDataByYearMobile, setAllDataByYearMobile] = useState<any>([]);


  function getSortByYear(posicionOne, posicionTwo) {
    return posicionOne.ano - posicionTwo.ano;
  }
  
  const getAllInfos = useCallback(() => {
    const response = async () => {
      setLoading(true)
      const data = await getYearAll(dataRange);
      if(data) {

        const arrayDisordered = data.tempos;

        if (arrayDisordered) {
          arrayDisordered.sort(getSortByYear);
         
          setAllDataByYearDesktop(arrayDisordered);
        }
      }
    };
    response().finally(() => {setLoading(false)});
  }, [dataRange]);

  const getAllInfosMobile = useCallback(() => {
    const response = async () => {
      const data = await getYearAll(dataRange);
      const arrayDisordered = [...data?.tempos];
  
      setAllDataByYearMobile(arrayDisordered);
   /*    if (arrayDisordered) {
        arrayDisordered.sort(getSortByYear);
        arrayDisordered[0].ano = 2015;
        arrayDisordered[2].meses[1].iniciativasRes.push({
          categoria: 'Ação',
          idIniciativa: 'acaomocada',
          nome: 'Criação do cartão mais infância',
          qtd: 1,
          regioes: ['Todas'],
        });
      
       
      } */
    };
    response();
  }, [dataRange]);
  
  return (
    <section className="container__obrasDetalhamentoGeral container-box">
      <div className="container__obrasDetalhamentoGeral-header">
        <Breadcrumb links={links} />
        <TitlePage
          title="Linha do tempo"
          subtitle=" Acompanhe o total de equipamentos e ações já realizadas pelo governo do
            estado, divididas em meses e anos."
          backButton
        />
        <InfoProvide 
          message="Os dados dos equipamentos são fornecidos pelo Sistema WebMapp do Governo do Ceará" 
          type="webmapp"
        />


        
      </div>
      <div className="obras-detail__content">
        {device.isSM('less') ? 
          <Timeline2Mobile 
            onDataUpgrad={setAllDataByYearMobile} 
            getData={getAllInfosMobile} 
            data={allDataByYearMobile}
          /> : 
          <Timeline2 
            onDataUpgrad={setAllDataByYearDesktop} 
            loading={loading}
            data={allDataByYearDesktop}  
            getData={getAllInfos}
          /> 
        }
      </div>
      <Footer />
    </section>
  );
};

export default ObrasDetalhamento;
