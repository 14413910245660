import React from 'react';
import { Spin, SpinProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface SimpleLoadingProps extends SpinProps {
  tip?: string;
}

export function SimpleLoading({ tip="", ...rest }: SimpleLoadingProps) {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return <Spin  {...rest} indicator={antIcon} tip={tip} style={{ color: '#4B9F37' }} size="large"/>
}