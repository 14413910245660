import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TimelineContext } from 'utils/context/timelineContext';
import { ITimelineFrame } from '../timeline2.0/types';

import iconAcoe from '../../assets/iconTimelineAcao.svg';
import iconObra from '../../assets/iconTimelineObra.svg';

import './style.scss';
import 'react-vertical-timeline-component/style.min.css';

interface IVerticalTimeLine {
  currentFrame: ITimelineFrame;

  posicion?: number;
  defaultMonth?: number;
  ano?: number;
}
const VerticalTimeLine = ({
  currentFrame,
  ano,
  posicion,
  defaultMonth
}: IVerticalTimeLine): JSX.Element => {
  const { currentMonth, citiesSelecteds, regionSelected } = useContext(TimelineContext);

  const [, setFollowPosicion] = useState(0);

  const [, setIndexImage] = useState(0);

  const memoized2017CreateCartao = useCallback(() => {
    const render = currentFrame?.iniciativasRes.some(
      acao => acao.idIniciativa === 'acaomocada',
    );
    if (currentFrame.mes === 12 && !render) {
      currentFrame?.iniciativasRes.push({
        categoria: 'Ação',
        idIniciativa: 'acaomocada',
        nome: 'Criação do cartão mais infância',
        qtd: 1,
        regioes: ['Todas'],
      });
    }
  }, [currentFrame]);

  useEffect(() => {
    if (ano === 2017) memoized2017CreateCartao();
    if (posicion) {
      if (posicion > 35) {
        setFollowPosicion(10);
      } else {
        setFollowPosicion(posicion - 5);
      }
    }
  }, [posicion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const indexImageAux = defaultMonth || currentMonth ;
    setIndexImage(indexImageAux);
  }, [currentMonth, defaultMonth]);

  const extMes = useMemo(
    () => [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    [],
  );
  function uppercase(value: string) {
    return value.charAt(0) + value.substr(1).toLowerCase();
  }

  function filterByMunicipio(iniciativa) {
    if (iniciativa.regioes && (iniciativa.regioes.some(elem => citiesSelecteds.includes(elem)) || !regionSelected || regionSelected.length === 0)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div
      className={`wrapper ${
        currentFrame.iniciativasRes.length > 0 ? 'animate ' : ''
      }`}
    >
      <div className="currente__mes">
        <h1>
          {extMes[parseInt(String(defaultMonth), 10) - 1] ??
            extMes[parseInt(String(currentMonth), 10) - 1]}
        </h1>
      </div>
  
      {currentFrame.iniciativasRes.length > 0 ? 
        (
        <div className="container-row">
          <div className="center-line" />
          <div className="container-row-cards">
            {currentFrame.iniciativasRes.filter(filterByMunicipio).map((iniciativa: any, i: number) => {
              return (
                <div key={`row${i}`} className="row">
                  <section>
                    <img
                      className="icon"
                      src={
                        iniciativa.categoria === 'Obra' ? iconObra : iconAcoe
                      }
                      alt="icone"
                    />
                  </section>
                  <section className="rectange">
                    <div className="details">
                      <h1>{iniciativa.qtd}</h1>
                      <h2>{iniciativa.nome}</h2>
                    </div>
                    <p className="list__regions">
                      {iniciativa?.regioes?.map((regiao, index) => {
                        if (index + 1 === iniciativa.regioes.length) {
                          return `${uppercase(regiao)} `;
                        }
                        return `${uppercase(regiao)}, `;
                      })}
                    </p>
                  </section>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="container-not-initiatives">
          <section className="rectange">
            <div className="details">
              <h2>Esse mês não possui ações</h2>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default memo(VerticalTimeLine);
