import React, { useState } from 'react';
import { Select } from 'antd';

import { FamiliesMap, PieChart, Breadcrumb } from '../../components';

import Regions from '../../data/ceara.json';

import '../../styles/antd-styles.less';
import './styles.scss';

const { Option } = Select;

const links = [
  {
    path: '/home-dashboard',
    title: 'Página Inicial',
  },
  {
    path: '/pesquisa-cmic',
    title: 'Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará',
  },
];

const FamiliesPage = (): JSX.Element => {
  const [selectedRegion, setSelectedRegion] = useState('Fortaleza');

  return (
    <>
      <div className="page-container">
        <div className="navigation-title">
          <Breadcrumb links={links} />
          <h1>Resultado da Pesquisa CMIC</h1>
        </div>
        <div className="container-grid">
          <aside className="map-card-container">
            <div className="infographic-overlay">
              {Regions && (
                <Select
                  value={selectedRegion}
                  style={{ width: 180 }}
                  onChange={e => setSelectedRegion(e)}
                >
                  {Regions.features.map(region => (
                    <Option
                      value={region.properties.NOME}
                      key={region.properties.NOME}
                    >
                      {region.properties.NOME}
                    </Option>
                  ))}
                </Select>
              )}
              <ul>
                <li>
                  <h1>1°</h1>
                  Colocação no ranking dos 24 municípios
                </li>
                <li>
                  <h1>70</h1>
                  Índice geral
                </li>
                <li>
                  <h1>50</h1>
                  Índice do Ceará
                </li>
              </ul>
            </div>
            <h3 className="aside-title">Município</h3>
            <p>Selecione um município ou clique no mapa</p>
            <FamiliesMap
              selectedRegion={selectedRegion}
              setSelectedRegion={setSelectedRegion}
            />
          </aside>
          <aside className="card-overview-container">
            <h3 className="aside-title">Bloco</h3>
            <p>Selecione um bloco abaixo</p>
            <div className="interactive-overview-container">
              <PieChart />
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default FamiliesPage;
