import { socialApi } from 'services/api';

const service = () => {
  const resource = '/pools';

  async function getJotFormPools(pageNumber: number, pageSize: number) {
    try {
      const response = await socialApi.get(`${resource}`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getJotFormByResearch(researchId: number) {
    try {
      const response = await socialApi.get(`${resource}/${researchId}`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  return {
    getJotFormPools,
    getJotFormByResearch
  }
};

export const jotformService = service();
