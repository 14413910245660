export function maskValor(value: string) {
 var valor = value + '';
  valor = valor.replace(/\D/g, '');
  valor = valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2');
  valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  valor = valor.replace(/^(\d)/g, '$1');

  // const valor = Number(value).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.").toString();
  return valor;

}

export const currencyFormatter =  value => {
  return new Intl.NumberFormat("pt-BR", {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};


export const currencyParser = val => {
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = "0.0";
    }

    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, "");
    var decimal = new Intl.NumberFormat('pt-BR').format(1.1).replace(/1/g, "");
    var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};