import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  height: 85vh;
  place-items: center;

  div.ant-spin-text {
    color: #4b9f37;
    font-size: 18px;
  }
`