import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import { Skeleton } from 'antd';

import './styles.scss';
interface IObraInfo {
  datas: {
    dataFimPrevisto: string,
    dataInicio: string,
    dataOrdemServico: string
  },
  descricao: string,
  docs: {
    documento: string,
    imagem: string
  },
  id: string,
  localizacao: {
    enderecoCompleto: string,
    estacao: string,
    latitude: number,
    longitude: number,
    municipio: string,
    pontoDeReferencia: string,
    regiaoPlanejamento: string
  },
  nome: string,
  observacao: string,
  recursos: {
    mudasFrutiferas: number,
    mudasNativas: number,
    totalInvestido: number
  },
  status: string,
  ultimaModificacao: string
}
export interface IGeoPraças {
  id: number;
  regiao: string;
  municipio: string;
  nomePraca: string;
  enderecoCompleto: string;
  latitude: number;
  longitude: number;
  dataObraInaugurada: string;
  status: string;
  dataInicioObra: string;
  imagem: string;
}

interface IMarkerProps {
  marker: IObraInfo;
  icon: string;
}

const MapMarker: any = ({ marker, icon }: IMarkerProps): JSX.Element => {
  const iconConfig = new Icon({
    iconUrl: `${icon}`,
    iconSize: [40, 40],
    className: 'family-icon',
  });

  return (
    <div className="map-marker-container">
      <Marker
        key={marker.id}
        position={[marker.localizacao.latitude, marker.localizacao.longitude]}
        icon={iconConfig}
      >
        <Popup>
          <div className="popup-container">
            <a href={`/dashboard/${marker.id}`}>
              <div className="description">
                <h1>{marker.nome}</h1>
                <p>{marker.localizacao.municipio.toLocaleUpperCase()}</p>
                <div className="address">
                  <span>{marker.localizacao.enderecoCompleto}</span>
                  <span>
                    {marker.status === 'Inaugurada' || marker.status === 'Concluida'
                      ? `Concluida em: ${marker.datas.dataOrdemServico.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}`
                      : 'Sem dado de entrega'}
                  </span>
                </div>
              </div>
            </a>
            <div className="fake-image">
              {marker.docs.documento ? (
                <img
                  src={marker.docs.imagem}
                  alt={marker.docs.imagem ? marker.nome : 'Sem dados da foto'}
                />
              ) : (
                <Skeleton.Image style={{ width: 85, height: 85 }} />
                )}
            </div>
          </div>
        </Popup>
      </Marker>
    </div>
  );
};

export default MapMarker;
