import styled from 'styled-components';

export { 
  Container, 
  Content, 
  Wrapper,
  CommentContainer,
  CommentButton,
  ButtonGroup,
  CancelButton
} from '../ArticulatorWorkPlan/styles';

export const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.3rem;
  margin: 7rem 2.3rem 7rem;
`;


export const CreateWorkPlanBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: #f2f2f2;
  border-radius: 7px;

  align-self: center;
  padding: 2.9rem 4rem;

  .ant-divider {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const TopWorkPlanBox = styled.div`
  display: flex;
  height: max-content;
  border-left: 3px solid #FFDE32; 

 
`;

export const ItemLabel = styled.div`
  margin-left: 1.8rem;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 700;
    color: #464A4E;
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .ant-input {
    width: 9rem;
  }

  + div {
    margin-left: 7rem;
  }
`;

export const DiagnosticBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.1rem;

  textarea.ant-input {
    min-height: 24rem;

    &:disabled {
      color: #464A4E;
    }
  }

  .download-button {
    display: flex;
    align-items: center;

    width: max-content;

    &.disabled {
      cursor: not-allowed;
    }

    span {
      margin-left: 5px;
    }
  }
`;

export const DiagnosticBoxTitle = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.7rem;
  color: #2D4040;
`;


export const VerticalDate = styled.span`
  font-weight: 800;
  font-size: 10rem;
  line-height: 13.6rem;
  text-align: right;

  color: rgba(96, 196, 112, 0.3);

  transform: rotate(-90deg) translate(-50%, 50%);
  
  position: absolute;
  right: 0;
`;

export const RefuseButton = styled.button`
  width: 16.4rem;
  height: 3.6rem; background: #FFFFFF;
  border: 1px solid #C2102A;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #C2102A;

  text-transform: uppercase;
`;

export const ApproveButton = styled.button`
  width: 16.4rem;
  height: 3.6rem;
  background: #4B9F37;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #FFFFFF;
  text-transform: uppercase;
`;

export const Icon = styled.img`
  margin-right: 6px;
`;

export const AprooveModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalIcon = styled.img`
  width: 20px;
`;

export const ModalTitle = styled.h2`
  font-weight: 700;
  color: #465564;
`;

export const ModalBody = styled.p`
  text-align: center;
  max-width: 280px;
  color: #464A4E;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
`;

export const ModalButton = styled.button`
  width: 10.4rem;
  height: 3.6rem;
  background: #87C878;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #FFFFFF;
  text-transform: uppercase;
`;

export const ModalCancelButton = styled.button`
  margin-right: 5px;
  width: 12.4rem;
  height: 3.6rem; background: #FFFFFF;
  border: 1px solid #C2102A;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #C2102A;

  text-transform: uppercase;
`;

export const ModalConfirmButton = styled.button`
  margin-left: 5px;
  width: 20.4rem;
  height: 3.6rem;
  background: #4B9F37;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #FFFFFF;
  text-transform: uppercase;
`;