import { citizenByUserApi, socialApi } from '../api';

import { UF, City } from '../../types';

const service = () => {
  const initialState = {
    'AC': 'Acre',
    'AL': 'Alagoas',
    'AP': 'Amapá',
    'AM': 'Amazonas',
    'BA': 'Bahia',
    'CE': 'Ceará',
    'ES': 'Espírito Santo',
    'GO': 'Goiás',
    'MA': 'Maranhão',
    'MT': 'Mato Grosso',
    'MS': 'Mato Grosso do Sul',
    'MG': 'Minas Gerais',
    'PA': 'Pará',
    'PB': 'Paraíba',
    'PR': 'Paraná',
    'PE': 'Pernambuco',
    'PI': 'Piauí',
    'RJ': 'Rio de Janeiro',
    'RN': 'Rio Grande do Norte',
    'RS': 'Rio Grande do Sul',
    'RO': 'Rondônia',
    'RR': 'Roraima',
    'SC': 'Santa Catarina',
    'SP': 'São Paulo',
    'SE': 'Sergipe',
    'TO': 'Tocantins',
    'DF': 'Distrito Federal',
  }

  async function getBrazilStates() {
    try {
      const response = await citizenByUserApi.get<UF[]>('/states',);

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getCities() {
    try {
      const response = await citizenByUserApi.get<City[]>(`/cities`);

      return response.data
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getCitiesByUF(ufId: number) {
    try {
      const response = await citizenByUserApi.get<City[]>(`/cities`);

      return response
        .data
        .filter(city => city.stateId === ufId);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getNeighborhoodByCity(cityName: string | undefined) {
    try {
        
        const response = await socialApi.get(`/cities/${cityName}/neighborhoods`)
        
        return response.data;
    } catch(err) {
      return Promise.reject(err)
    }
  }

  return {
    getBrazilStates,
    getCities,
    getCitiesByUF,
    getNeighborhoodByCity,
    initialState,
  };
};

export const localService = service();
