import {
  deleteEventObra,
  getEventsObra,
} from 'services/resources/obrasServices';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import moment from 'moment';

import { regioesPlanejamento } from '../../utils/regioesplanejamento'

interface IObraEvents {
  atividades: {
    apresentacoesDoCircoEscola: number;
    edicao: number;
    exibicoesRealizadas: number;
    oficinasRealizadas: number;
    palestrasRealizadas: number;
    programacao: number;
  };

  datas: {
    dataEntrega: string;
    dataFim: string;
    dataInalguracao: string;
    dataInicio: string;
    previsaoEntrega: string;
  };
  docs: {
    documento: string;
    imagem: string;
  };
  id: string;
  localizacao: {
    enderecoCompleto: string;
    estacao: string;
    latitude: number;
    longitude: number;
    municipio: string;
    regiaoPlanejamento: string;
  };
  nome: string;
  pessoas: {
    criancasAtendidas: number;
    criancasFormadas: number;
    criancasVisitadas: number;
    familiasAcompanhadas: number;
    familiasBeneficiadas: number;
    familiasBeneficiadasMes: number;
    familiasVisitadas: number;
    gestantesVisitadas: number;
    participantes: number;
    pessoasAtendidasPorFaixaEtaria: {
      adultos: number;
      entre12e19: number;
      idosoMaiorQue60: number;
      menoresDe6: number;
    };
    pessoasBeneficiadas: number;
    pessoasCapacitadas: number;
    pessoasImpactadas: number;
  };
  recursos: {
    dvdsEntregues: number;
    instrumentosDoados: number;
    mudasFrutiferas: number;
    mudasNativas: number;
    toneladasDeAlimentosDoados: number;
    toneladasDeAlimentosRecebidos: number;
    totalInvestido: number;
  };
  status: string;
  ultimaModificacao: string;
  descricao: string;
}

/* Inteface de um evento */
interface IEventFormatted {
  key: string;
  id: string;
  nome: string;
  municipio: string;
  status: string;
  data: string;
  latitude: number;
  longitude: number;
  enderecoCompleto: string;
  mudasNativas: number;
  mudasFrutiferas: number;
  valorInvestido: string;
  urlImg: string;
}

/* Tipos do contexto */
type HistoryObraData = {
  obraEventsList: IObraEvents[];
  formattedEvents: any[];
  filtered: boolean;
  setFiltered: (state: boolean) => void;
  formattedEventsAux: any[];
  selectedCitiesList: string[];
  selectedStatusList: string[];
  selectedRegiaoList: string[];
  selectedTime: string[];
  modalConfirmacaoExclusaoObraIsOpen: boolean;
  modalConfirmacaoInfoObraIsOpen: boolean;
  idEventObraSelected: string;
  setSelectedCitiesList: (state: string[]) => void;
  setSelectedRegiaoList: (state: string[]) => void;
  setSelectedStausList: (state: string[]) => void;
  setSelectedTime: (state: string[]) => void;
  setIdEventObraSelected: (state: string) => void;
  updateEventsList: (eventsList: IObraEvents[]) => void;
  filterEvents: () => void;
  handleDeleteEventObra: (idAcao: string, idEvent: string) => void;
  toggleModalObraConfirmacaoExclusao: () => void;
  toggleModalObraConfirmacaoInfo: () => void;
};

type HistoryObraProviderProps = {
  children: ReactNode;
};

export const HistoryObraContext = createContext({} as HistoryObraData);

export function HistoryObraProvider({ children }: HistoryObraProviderProps) {
  const [obraEventsList, setObraEventsList] = useState<Array<any>>([]);
  const [formattedEvents, setFormattedEvents] = useState<
    Array<any>
  >([]);
  const [formattedEventsAux, setFormattedEventsAux] = useState<
    Array<any>
  >([]);

  /* Estados do filtro */
  const [selectedCitiesList, setSelectedCitiesList] = useState<Array<string>>(
    [],
  );
  const [selectedStatusList, setSelectedStausList] = useState<Array<string>>(
    [],
  );
  
  const [selectedRegiaoList, setSelectedRegiaoList] = useState<Array<string>>(
    [],
  );

  const [filtered, setFiltered] = useState(false);
  
  
  const [selectedTime, setSelectedTime] = useState<Array<string>>([]);

  /* Estados do modal */
  const [
    modalConfirmacaoExclusaoObraIsOpen,
    setModalConfirmacaoExclusaoObraIsOpen,
  ] = useState(false);
  const [modalConfirmacaoInfoObraIsOpen, setModalConfirmacaoInfoObraIsOpen] =
    useState(false);
  const [idEventObraSelected, setIdEventObraSelected] = useState('');

  /* Função responsavel por formatar uma lisra de eventos */
  function FormatEvents(eventsList: any[]) {
    const events = eventsList?.map(event => {
      return {...event,key: event.id,
        dataOrdemServico: moment(event.dataOrdemServico).format("DD/MM/YYYY"),
        municipio: event.municipio
              .toLowerCase()
              .replace(/(^|\s)\S/g, l => l.toUpperCase()),
      }

    //   return {
    //     key: event.id,
    //     id: event.id,
    //     nome: event.nome,
    //     municipio: event.localizacao.municipio
    //       .toLowerCase()
    //       .replace(/(^|\s)\S/g, l => l.toUpperCase()),
    //     status: event.status === 'Inalgurada' ? 'Inaugurada' : event.status,
    //     data: new Date(event.ultimaModificacao).toLocaleDateString('pt-br', {
    //       year: 'numeric',
    //       month: 'numeric',
    //     }),
    //     latitude: event.localizacao.latitude,
    //     longitude: event.localizacao.longitude,
    //     enderecoCompleto: event.localizacao.enderecoCompleto,
    //     mudasNativas: event.recursos.mudasNativas,
    //     mudasFrutiferas: event.recursos.mudasNativas,
    //     valorInvestido: event.recursos.totalInvestido.toLocaleString('pt-br', {
    //       style: 'currency',
    //       currency: 'BRL',
    //     }),
    //     urlImg:
    //       event.docs.imagem === 'uma url para imagem' ? '' : event.docs.imagem,
    //     descricao: event.descricao,
    //   };
    // });

    
    });

    setFormattedEvents(events);
    setFormattedEventsAux(events);
  }

  /* Função que gera um update na listas de evento  */
  function updateEventsList(eventsList: IObraEvents[]) {
    setObraEventsList(eventsList);
    FormatEvents(eventsList);
  }

  /* Função que filtra a lista */
  function filterEvents() {
    setFiltered(true);
    setFormattedEvents([]);
    let newListFilter = [...formattedEventsAux];
    if(selectedStatusList?.length > 0) {

      const obrasStatus = newListFilter.filter(obra =>  selectedStatusList.includes(obra.estagioExecucao))

      newListFilter = [...obrasStatus];

    }
    
    if(selectedRegiaoList?.length > 0) {
      const municipiosRegioes = regioesPlanejamento
      .filter(regiao => 
        selectedRegiaoList.includes(regiao.regiao))
      .reduce((x, y) => {
        x.municipios = x.municipios.concat(y.municipios)
        return x
      });
      
      const obrasRegiao = newListFilter.filter(obra => 
        municipiosRegioes.municipios.includes(obra.municipio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")))    
      newListFilter = [...obrasRegiao];

    }

    if(selectedCitiesList?.length > 0) {
      const cidades: any = []
      selectedCitiesList.forEach(obra => cidades.push(obra.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")))
      const obrasCities = newListFilter.filter(obra => cidades.includes(obra.municipio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")))
  
      newListFilter = [...obrasCities];
    }

    if(selectedTime?.length > 1){
      let ano = new Date(selectedTime[0]).getFullYear()
      let mes = new Date(selectedTime[0]).getMonth()
    
      let dataIni: any = new Date(ano, mes, 1)
      
      ano = new Date(selectedTime[1]).getFullYear()
      mes = new Date(selectedTime[1]).getMonth()
      
      let dataFim: any = new Date(ano, mes+1, 0)
    
      dataIni = dataIni.getTime();
      dataFim = dataFim.getTime();

      const obrasTime = newListFilter.filter(obra => (new Date(obra.previsaoEntrega).getTime() >= dataIni) && (new Date(obra.previsaoEntrega).getTime() <= dataFim) ) 

      newListFilter = [...obrasTime];
    }

    setFormattedEvents(newListFilter)
  }

  /* Função que apagas os eventos e lista novamente */
  function handleDeleteEventObra(idObra: string, idEvent: string) {
    setModalConfirmacaoExclusaoObraIsOpen(false);

    const response = async () => {
      const data = await deleteEventObra(idObra, idEvent);
      const data2 = await getEventsObra(idObra);
      updateEventsList(data2);
      

      data && setModalConfirmacaoInfoObraIsOpen(true);
    };
    response();
  }

  /* Alterna se o modal vai aparecer ou não e guarda o id do evento*/
  function toggleModalObraConfirmacaoExclusao() {
    setModalConfirmacaoExclusaoObraIsOpen(!modalConfirmacaoExclusaoObraIsOpen);

    // setIdEventObraSelected(idEvent)
  }

  /* Alterna se o modal vai aparecer ou não */
  function toggleModalObraConfirmacaoInfo() {
    setModalConfirmacaoInfoObraIsOpen(!modalConfirmacaoInfoObraIsOpen);
    setModalConfirmacaoExclusaoObraIsOpen(false);
  }

  return (
    <HistoryObraContext.Provider
      value={{
        obraEventsList,
        updateEventsList,
        formattedEvents,
        filtered,
        setFiltered,
        formattedEventsAux,
        setSelectedRegiaoList,
        selectedCitiesList,
        selectedStatusList,
        selectedTime,
        selectedRegiaoList,
        setSelectedCitiesList,
        setSelectedStausList,
        setSelectedTime,
        setIdEventObraSelected,
        modalConfirmacaoExclusaoObraIsOpen,
        modalConfirmacaoInfoObraIsOpen,
        filterEvents,
        handleDeleteEventObra,
        toggleModalObraConfirmacaoExclusao,
        toggleModalObraConfirmacaoInfo,
        idEventObraSelected,
      }}
    >
      {children}
    </HistoryObraContext.Provider>
  );
}

/* Exportando o contexto  */
export const useHistoryObra = () => {
  return useContext(HistoryObraContext);
};
