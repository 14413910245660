import XLSX from 'xlsx';


import { formatXLSXgeral, titleTable } from '../formatXLSXgeral';

export function formatXLSXmaisNutricao(data, workbook) {
  let newTabDoacao: any = [];
  let newTabCapacitacao: any = [];
  let newTabCB: any = []; // cestas básicas
  let entidadeAtual: any = []



  data.forEach((maisNutricaoObj, index: number) => {
    const maisNutricao = maisNutricaoObj.maisNutricao;

    if(maisNutricao.tipo === 'Mais nutrição doação') {
      workbook.SheetNames.push(
        `${maisNutricao.tipo} ${index+1}`,
      );

      newTabDoacao.push(['Data Início', 'Data Fim', 'Toneladas de Alimentos doados', 'Quantidade de Pessoas Atendidas'])
      
      newTabDoacao.push([new Date(maisNutricao.maisNutricaoDoacao.periodoSemanal.dataInicio).toLocaleDateString('pt-BR'), 
                   new Date(maisNutricao.maisNutricaoDoacao.periodoSemanal.dataFim).toLocaleDateString('pt-BR'), 
                   maisNutricao.maisNutricaoDoacao.qtdAlimentosDoados, 
                   maisNutricao.maisNutricaoDoacao.qtdPessoasAtendidas])
      newTabDoacao.push([])
      newTabDoacao.push(['Municipio', 'Quantidade de Entidades'])
      maisNutricao.maisNutricaoDoacao.entidadeMunicipios.forEach(entidade => {
        let entidadeAtual: any = []
        entidadeAtual.push(entidade.municipio)
        entidadeAtual.push(entidade.qtdEntidades)
        newTabDoacao.push(entidadeAtual)
      })

      let content = XLSX.utils.aoa_to_sheet(newTabDoacao);

      workbook.Sheets[
        `${maisNutricao.tipo} ${index+1}`
      ] = content;

      newTabDoacao = []

    } else if(maisNutricao.tipo === 'Mais nutrição capacitação') { 
      if(index===0){
        titleTable(maisNutricao, entidadeAtual)
        newTabCapacitacao.push(entidadeAtual)
    
        workbook.SheetNames.push(
          'Mais nutrição capacitação',
        );
      }
      entidadeAtual = []
      formatXLSXgeral(maisNutricao, [], entidadeAtual)
      newTabCapacitacao.push(entidadeAtual) ;
      workbook.Sheets[
        "Mais nutrição capacitação"
      ] = XLSX.utils.aoa_to_sheet(newTabCapacitacao);

    } else {
        if(index === 0){
          titleTable(maisNutricao, entidadeAtual)
          newTabCB.push(entidadeAtual)
          
          workbook.SheetNames.push(
            'Cestas Básicas'
          );
        }
      entidadeAtual = []
      formatXLSXgeral(maisNutricao, [], entidadeAtual)
      newTabCB.push(entidadeAtual);
      workbook.Sheets[
        "Cestas Básicas"
      ] = XLSX.utils.aoa_to_sheet(newTabCB);
    }

  });

  return workbook;
}
