import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Form, FormInstance } from 'antd'
import { FormContainer } from 'components/FormAction/style';
import { Authorizer, Feedback } from 'components'
import { PersonalForm}  from './PersonalForm'
import { PersonalFormEdit} from './PersonalFormEdit'
import {DemandDataForm} from './DemandDataForm'
import {DemandDataFormEdit} from './DemandDataFormEdit'

interface FormProps {
  form: FormInstance;
  finish: (props: any) => void;
  step: number;
}

export function DemandForm({form, finish, step}: FormProps){

  return(
    <>
        <Form layout="vertical" form={form} onFinish={finish} >
            <div className='form-content'>
                  <FormContainer>
                      {step === 0 ?
                          <PersonalForm/>
                        :
                          <PersonalFormEdit/>
                      }
                  </FormContainer>
                  <FormContainer>
                      {step === 0 ?
                          <DemandDataForm/>
                        :
                          <DemandDataFormEdit/>
                      }
                  </FormContainer>
            </div>
        </Form>
    </>
  )

}

