import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { makeOptitionsNamesCurrentCREDES } from 'utils/makeOptionsCREDES';
import { convertArraysFromString, genereteRows, genereteWidthColumn } from '../utils/formatter';
import {  headFormacaoSeduc } from '../utils/headers';


const docFormacao: any = new jsPDF();

export function formacaoSeducPDF(eventData: any) {
  
  eventData.forEach((event, index) => {

    const tableFormacao: any = [];
    let dataInicial: any;
    let dataFim: any;

      const rowsFormacaoSeduc = genereteRows(headFormacaoSeduc);
      let novoObj: any = [...event.ciclosSeduc];
      novoObj.forEach((ciclo, index)=> {
        let objectLine: any = [];
        if(index === 0) {
          dataInicial = new Date(ciclo['periodo'].dataInicio).toLocaleDateString('pt-BR')
        }
        if(index === novoObj.length-1) {
          dataFim = ciclo['periodo'].dataFim ? new Date(ciclo['periodo'].dataFim).toLocaleDateString('pt-BR') : 'Sem data'
        }
        Object.keys(rowsFormacaoSeduc).forEach(coluna => {
      
          if(coluna==="municipios"){
            objectLine.push({title: ciclo["municipios"].join(', '), dataKey: coluna});
 
           }else if(coluna==="credes"){
             const credes = makeOptitionsNamesCurrentCREDES(ciclo["municipios"]);
             objectLine.push({title: credes.join(', '), dataKey: coluna});
           }else if(coluna==="articuladoresRegionaisMaisInfancia"){
            objectLine.push({title: ciclo["participantesPorPerfil"].articuladoresRegionaisMaisInfancia, dataKey: coluna});
          }else if(coluna==="coordenadoresMunicipaisEducacaoInfantil"){
            objectLine.push({title: ciclo["participantesPorPerfil"].coordenadoresMunicipaisEducacaoInfantil, dataKey: coluna});
          }else if(coluna==="formadoresMunicipais"){
            objectLine.push({title: ciclo["participantesPorPerfil"].formadoresMunicipais, dataKey: coluna});
          }else if(coluna==="formadoresRegionais"){
            objectLine.push({title: ciclo["participantesPorPerfil"].formadoresRegionais, dataKey: coluna});
          }else if(coluna==="gestoresEscolares"){
            objectLine.push({title: ciclo["participantesPorPerfil"].gestoresEscolares, dataKey: coluna});
          }else if(coluna==="professores"){
            objectLine.push({title: ciclo["participantesPorPerfil"].professores, dataKey: coluna});
          }else if(coluna==="tecnicosCrede"){
            objectLine.push({title: ciclo["participantesPorPerfil"].tecnicosCrede, dataKey: coluna});
          }else if(coluna==="tecnicosSeduc"){
            objectLine.push({title: ciclo["participantesPorPerfil"].tecnicosSeduc, dataKey: coluna});
          }else if(coluna==="dataInicio"){
            objectLine.push({title: new Date(ciclo["periodo"].dataInicio).toLocaleDateString('pt-BR'), dataKey: coluna});
          }else if(coluna==="dataFim"){
            objectLine.push({title: ciclo['periodo'].dataFim ? new Date(ciclo['periodo'].dataFim).toLocaleDateString('pt-BR') : 'Sem data', dataKey: coluna});
          }else {
            objectLine.push({title: ciclo[coluna], dataKey: coluna});
          }
        });
        tableFormacao.push(objectLine);
      })



      docFormacao.addPage('a4', 'landscape');
  
      docFormacao.setFontSize(15);
      docFormacao.text(2, 12, `Formação Seduc (${index+1}) de ${dataInicial} até ${dataFim} `);
      docFormacao.autoTable({
      startX: 0,
      rowPageBreak: 'avoid',
      columnStyles: genereteWidthColumn(rowsFormacaoSeduc),
      head: headFormacaoSeduc,
      startY: 25,
      margin: {
        top: 10,
        bottom: 40,
        left: 2,
        right: 2,
      },
      headerStyles: {
        fontSize: 9,
        fontFamily: 'Roboto',
        fillColor: '#80c470',
        halign: 'center',
      },
      styles: { fontSize: 9, columnWidth: 'wrap' },
      horizontalPageBreak: false,
      body: convertArraysFromString(tableFormacao),
      theme: 'grid',
    });

   })
 

 docFormacao.deletePage(1); 
 docFormacao.save(`formacao-seduc.pdf`);


}