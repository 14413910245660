import dayjs, { Dayjs } from 'dayjs';
import {
  RichAgent,
  PoorPersonalAgent,
  PoorCommunicationAgent,
} from 'iris-social/service/articulator';

export interface Personal {
  cpf: string;
  name: string;
  birthdate: Dayjs;
  maritalStatus: number;
  email: string;
  phone: string;
}

export interface Professional {
  occupation: string;
  graduation: string;
  admissionDate: Dayjs;
  workload: number;
  city: string;
}

export interface Address {
  zipCode: string;
  address: string;
  addressNumber: string;
  addressComplement?: string;
  addressStateId: number | string;
  addressCityId: number | string;
  district: string;
}

export interface Family {
  hasChildren: boolean;
  numChildren?: number;
}

export interface ArticulatorValues {
  personal: Personal;
  professional: Professional;
  address: Address;
  family: Family;
}

export function agentToForm(agent: RichAgent) {
  const {
    citizen,
    communication,
    personal: personalInfo,
    agent: agentInfo,
  } = agent;

  const phone = communication.userPhones[0].number;
  const birthdate = personalInfo.birth.date ?? undefined;
  const admissionDate = agentInfo
    ? `${agentInfo.admissionYear}-${agentInfo.admissionMonth}-01`
    : undefined;

  const personal: Personal = {
    cpf: citizen.userId,
    name: citizen.name,
    email: citizen.email,
    maritalStatus: personalInfo.maritalStatusId ?? 2,
    birthdate: dayjs(birthdate),
    phone,
  };

  const professional: Professional = {
    occupation: agentInfo?.occupation ?? '',
    graduation: agentInfo?.graduation ?? '',
    workload: agentInfo?.workload ?? 0,
    city: agentInfo?.city ?? '',
    admissionDate: dayjs(admissionDate),
  };

  const address: Address = {
    zipCode: communication.zipCode,
    address: communication.address,
    addressNumber: communication.addressNumber,
    addressStateId: communication.addressStateId,
    addressCityId: communication.addressCityId,
    district: communication.district,
    addressComplement: communication.addressComplement,
  };

  const family: Family = {
    hasChildren: !!agentInfo?.numChildren,
    numChildren: agentInfo?.numChildren,
  };

  return { personal, professional, address, family };
}

export function formToAgent(formValue: ArticulatorValues): PoorPersonalAgent {
  const { personal: personalInfo, professional, family } = formValue;

  const ddd = personalInfo.phone.slice(0, 2);
  const phone = personalInfo.phone.slice(2);

  const agent = {
    cpf: personalInfo.cpf,
    name: personalInfo.name,
    email: personalInfo.email,
    city: professional.city,
    workload: professional.workload,
    admissionYear: professional.admissionDate.year(),
    admissionMonth: professional.admissionDate.month() + 1,
    occupation: professional.occupation,
    graduation: professional.graduation,
    numChildren: family?.numChildren ?? 0,
    phones: [{ ddd, number: phone }],
  };

  const personal = {
    userId: personalInfo.cpf,
    name: personalInfo.name,
    email: personalInfo.email,
    maritalStatusId: personalInfo.maritalStatus,
    birth: { date: personalInfo.birthdate.format('YYYY-MM-DD') },
  };

  return { agent, personal };
}

export function formToUpgradableAgent(
  formValue: ArticulatorValues,
  data: RichAgent,
): PoorCommunicationAgent {
  const { personal, professional, family, address } = formValue;

  const ddd = personal.phone.slice(0, 2);
  const phone = personal.phone.slice(2);

  const agent = {
    name: personal.name,
    city: professional.city,
    workload: professional.workload,
    numChildren: family.numChildren ?? 0,
    isFirstAccess: undefined,
    admissionYear: professional.admissionDate.year(),
    admissionMonth: professional.admissionDate.month() + 1,
    occupation: professional.occupation,
    graduation: professional.graduation,
    email: personal.email,
    phones: [{ ddd, number: phone }],
    identifier: data.agent?.identifier,
    cpf: personal.cpf,
  };

  const communication = {
    id: data.communication.id,
    userId: personal.cpf,
    address: address.address,
    addressNumber: address.addressNumber,
    addressStateId: address.addressStateId as number,
    addressCityId: address.addressCityId as number,
    addressComplement: address.addressComplement,
    district: address.district,
    zipCode: address.zipCode,
    countryId: 1,
    userPhones: data.communication.userPhones,
  };

  return { agent, communication };
}
