import styled from 'styled-components';

export const TopContent = styled.div`
  display: flex;
  gap: 2.3rem;
`;

export const GoBackButton = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    /* height: 2rem;
    width: 1.2rem; */
  }  
`;

export const PageTitleContent = styled.div`
  display: flex;
  flex-direction: column;

  
`;

export const PageTitle = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.1rem;
  letter-spacing: 0rem;
  text-align: left;
  color: #2D4040;

  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }

`;

export const PageSubTitle = styled.span`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.7rem;
  letter-spacing: 0rem;
  text-align: left;
  color: #2D4040;

  @media screen and (max-width: 767px) {
    font-size: 1.6rem;
  }
`;