import styled from 'styled-components';

import { ProgressProps } from '.';

export const Container = styled.div<ProgressProps>`
  height: 25px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid ${props => props.bgColor};
`;

export const FillerBar = styled.div<ProgressProps>`
  height: 100%;
  width: ${props => props.progress}%;
  background: ${props => props.bgColor};
  border: 1px solid ${props => props.bgColor};
  border-radius: inherit;
  text-align: right;
  animation: 0.4s progress-slide;
  transition: width 0.25s cubic-bezier(0.08, 0.82, 0.17, 1);

  span {
    padding: 5px;
    color: #fff;
    font: 700 14px Roboto;
  }

  @keyframes progress-slide {
    from {
      width: 0%;
    }
    to {
      width: ${props => props.progress}%;
    }
  }
`;
