import { socialApi } from 'services/api';
import { CommentProps, CreateCommentProps, CreateCommentRefuse } from 'types';

const service = () => {
  const resource = '/comments';

  async function getCommentsByWorkplanId(workplanId: string | number | undefined) {
    try {
      const response = await socialApi.get<CommentProps[]>(`${resource}/workplan/${workplanId}`);

      return response.data;

    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function createCommentOnWorkplan(data: CreateCommentProps | CreateCommentRefuse) {
    try {
      const response = await socialApi.post<CommentProps>(`${resource}`, data);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getGeneralCommentsAndObservation() {
    try {
      const response = await socialApi.get<CommentProps[]>(`${resource}/general`);

      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  return {
    getCommentsByWorkplanId,
    createCommentOnWorkplan,
    getGeneralCommentsAndObservation,
  }
}

export const commentServices = service();
