import NavigationCMIC from 'components/NavigationCMIC';
import React, { useState, useEffect } from 'react';

import { Breadcrumb, Footer } from 'components';
import { TitlePage } from 'components/TitlePage';
import SectionInfosCMIC from 'components/SectionInfosCMIC';
import './styles.scss';
import SectionInfoYellow from 'components/SectionInfoYellow';
import {
  CardMediumGeral,
  CardMediumImage,
  CardSmallHorizontal,
} from 'components/CardsCMIC';

import { getCrianca } from '../../services/resources/criancaServices';

import baby from '../../assets/CMIC/crianca/baby.svg';
import boy from '../../assets/CMIC/crianca/boy.svg';
import iconCaderneta from '../../assets/CMIC/iconCaderneta.svg';
import boyReading from '../../assets/CMIC/crianca/boyReading.svg';
import light from '../../assets/CMIC/crianca/light.svg';
import twobabys from '../../assets/CMIC/crianca/twobabys.svg';
import zonaUrbana from '../../assets/CMIC/crianca/zonaUrbana.svg';
import zonaRural from '../../assets/CMIC/crianca/zonaRural.svg';
import momWithBaby from '../../assets/CMIC/crianca/momWithBaby.svg';
import findData from 'utils/findAndFormatCMIC';

import { Device } from 'components/Device';
import InfoProvide from 'components/InfoProvide';

interface dataCrianca {
  questao: string;
  resposta: string;
}

const CriancaCMIC = (): JSX.Element => {

  const device = Device.useDevice();


  const [dataCrianca, setDataCrianca] = useState<Array<dataCrianca>>();

  useEffect(() => {
    const response = async () => {
      const data = await getCrianca();
      setDataCrianca(data);
    };
    response().catch(() => setDataCrianca([]));
  }, []);

  const links = [
    {
      path: '/',
      title: 'Página Inicial',
    },
    {
      path: 'pesquisa-cmic',
      title: 'Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão Mais Infância Ceará',
    },
    {
      path: '/pesquisa-cmic/crianca',
      title: 'Criança',
    },
  ];

  const listInfoTeste = [
    {
      number: findData(
        'Porcentagem de crianças com caderneta - Possuem',
        dataCrianca,
      ),
      title: 'Possuem',
    },
    {
      number: findData(
        'Porcentagem de crianças sem caderneta - Perderam',
        dataCrianca,
      ),
      title: 'Já tiveram, mas perderam',
    },
  ];

  const listInfoTeste2 = [
    {
      number: findData("Crianças não matriculadas porque não existe creche", dataCrianca),
      title: 'Não existe creche e/ou escola perto de casa',
    },
    {
      number: findData("Crianças não matriculadas porque falta vaga na creche", dataCrianca),
      title: 'Falta de vagas',
    },
    {
      number: findData("Crianças não matriculadas porque há medo de violência", dataCrianca),
      title: 'Têm medo da violência',
    },
  ];


  return (
    <>
      <section className="container-box">
        <section className="container__crianca">
          <div className="container__crianca-header">
            <Breadcrumb links={links} />
            <TitlePage style={{ margin: '0' }} title="Criança" backButton />
          </div>
          <InfoProvide 
            message="Os dados abaixo foram coletados do Big Data Social a partir da base da Avaliação da Paternalidade e Perfil das famílias beneficiadas pelo cartão mais infância Ceará" 
            type="cemic"
          />

          <section className={`container__crianca-center ${
              device.isMD('less') && 'isXS'
          }`}>

            <SectionInfoYellow
              text="*Quando apenas uma pessoa assume a parentalidade de outra. Exemplo: Mãe solo."
              image={baby}
              title="Informações gerais da criança"
            >
              <ul className="center__saude-cards">
                <li>
                  <div className="ComponentTotal">
                    <div className="componentImagem">
                      <img src={twobabys} alt="dois bebês" />
                      <div className="text">
                        <h2>Crianças da Pesquisa CMIC</h2>
                        <h1>
                          {findData('Crianças da pesquisa cemic', dataCrianca)}
                        </h1>
                      </div>
                    </div>
                    <div className="componentDuplo">
                      <ComponenteInfo
                        title="Idade de 0 a 3 anos"
                        number={findData(
                          'Porcentagem de crianças menores de 3 anos',
                          dataCrianca,
                        )}
                        style={{ borderRight: '4px solid #FBDB5B' }}
                      />
                      <ComponenteInfo
                        title="Idade de 4 a 6 anos"
                        number={findData(
                          'Porcentagem de crianças menores de 4 a 6 anos',
                          dataCrianca,
                        )}
                      />
                    </div>
                  </div>

                  <CardMediumGeral
                    text="No Ceará, 74% das crianças moram na Zona Urbana. Fonte: FMC, 2018-2020."
                    style={{ marginTop: '2rem' }}
                  >
                    <div className="componentDuplo">
                      <ComponenteInfo
                        image={zonaUrbana}
                        title="Zona Urbana"
                        number={findData(
                          'Porcentagem de familias na zona urbana',
                          dataCrianca,
                        )}
                        style={{ borderRight: '4px solid #FBDB5B' }}
                      />
                      <ComponenteInfo
                        image={zonaRural}
                        title="Zona Rural"
                        number={findData(
                          'Porcentagem de familias na zona rural',
                          dataCrianca,
                        )}
                      />
                    </div>
                  </CardMediumGeral>
                </li>

                <li>
                  <CardMediumGeral
                    text="No Ceará, cerca de 41% das famílias são monoparentais chefiadas por mulheres em 2018. Fonte: IPECE, 2019."
                  >
                    <div className="ComponentTotal">
                      <div className="componentImagem">
                        <img src={momWithBaby} alt="dois bebês" />
                        <div className="text">
                          <h2>Crianças que estão em família monoparental*</h2>
                          <div className="percent">
                            <h1>{findData("Crianças que estão em familia monoparental", dataCrianca)}</h1>
                            <p>%</p>
                          </div>
                        </div>
                      </div>
                      <div className="componentDuplo">
                        <ComponenteInfo
                          title="Idade de 0 a 3 anos"
                          number={findData("Crianças menores de 3 anos em familia monoparental", dataCrianca)}
                          style={{ borderRight: '4px solid #FBDB5B' }}
                        />
                        <ComponenteInfo
                          title="Idade de 4 a 6 anos"
                          number={findData("Crianças entre 4 e 6 anos em familia monoparental", dataCrianca)}
                        />
                      </div>
                    </div>
                  </CardMediumGeral>
                </li>
              </ul>
            </SectionInfoYellow>
            <div className="infoRight">
              <h1 className="title">Desenvolvimento Infantil</h1>
              <CardSmallHorizontal
                number={findData(
                  'Media de horas que as crianças assistem tv',
                  dataCrianca,
                )}
                title="Média de horas diárias que a criança fica assistindo televisão"
                type="horas"
                style={{ margin: '3rem 0' }}
              />

              {
                device.isMD('bigger') && (
                  <img
                    className="image-boy"
                    src={boy}
                    alt="imagem de um menino no skate"
                  />
                )
              }
            </div>
          </section>
          <SectionInfosCMIC title="Saúde" subtitle="Criança">
            <ul className={`center__saude-cards ${
              device.isMD('less') && 'isXS'
            }`}>
              <li>
                <CardMediumImage
                  iconImage={iconCaderneta}
                  title="Caderneta de vacinação"
                  listInfos={listInfoTeste}
                />
              </li>

              <li>
                <CardSmallHorizontal
                  number={findData(
                    'Média de idade das crianças amamentadas',
                    dataCrianca,
                  )}
                  title="é a média de idade das crianças amamentadas"
                  type="meses"
                  style={{ marginBottom: '1rem' }}
                />
                <CardSmallHorizontal
                  number={findData(
                    'Porcentagem de crianças que mamaram na primeira hora de vida',
                    dataCrianca,
                  )}
                  title="crianças foram amamentadas na primeira hora de vida"
                  type="porc"
                />
              </li>

              <li>
                <CardMediumGeral
                  text="Cerca de 47% das crianças foram amamentadas até os 6 meses*. Fonte: Datapedia, 2019. *Média obtida para os 24 municípios da pesquisa."
                >
                  <CardSmallHorizontal
                    number={findData(
                      'Porcentagem de crianças que mamaram até os 6 meses',
                      dataCrianca,
                    )}
                    title="criancas foram amamentadas até os 6 primeiros meses de vida"
                    type="porc"
                  />
                </CardMediumGeral>
                <div style={{ position: 'absolute', zIndex: -1, top: '10rem' }}>
                  <img src={light} alt="luz de ideia" />
                </div>
              </li>
            </ul>
          </SectionInfosCMIC>
          <section className={`container__crianca-footer ${
              device.isMD('less') && 'isXS'
            }`}>
            <h1 className="title-footer">Ensino</h1>
            <ul className={`center__saude-cards ${
              device.isMD('less') && 'isXS'
            }`}>
              <li>
                <CardMediumGeral
                  text="A estimativa é de 37% para todos os 24 municípios*. Fonte: Datapedia, 2019. *Média obtida para os 24 municípios da pesquisa."
                  style={{ marginBottom: '3rem' }}
                >
                  <CardSmallHorizontal
                    number={findData("Crianças de 0 a 3 anos que estão matriculadas em creches", dataCrianca)}
                    title="das crianças de 0 a 3 anos estão matriculadas em creches"
                    type="porc"
                  />
                </CardMediumGeral>

                <CardMediumGeral
                  text="A estimativa é de 96% para todos os 24 municípios*. Fonte: Datapedia, 2019. *Média obtida para os 24 municípios da pesquisa."
                >
                  <CardSmallHorizontal
                    number={findData("Crianças de 4 a 6 anos que estão matriculadas em pre-escolas", dataCrianca)}
                    title="das crianças de 4 a 6 anos estão matriculadas em pré-escolas"
                    type="porc"
                  />
                </CardMediumGeral>
              </li>

              <li>
                <div className="info-extra">
                  <p>
                    Por que nem todas as crianças frequentam escola ou creche?
                  </p>
                </div>
                <CardMediumImage
                  title="Principais motivos apontados pelos pais e responsáveis"
                  listInfos={listInfoTeste2}
                />
              </li>

              {
                device.isMD('bigger') && (
                  <li>
                    <div className="info-img">
                      <img src={boyReading} alt="menino lendo" />
                    </div>
                  </li>
                )
              }
            </ul>
          </section>
        </section>
      </section>
      <NavigationCMIC type="crianca" />
      <Footer />
    </>
  );
};

interface ComponenteInfoProps {
  image?: string;
  title: string;
  number: number | string | JSX.Element;
  style?: {};
}

export const ComponenteInfo = ({
  image,
  title,
  number,
  style,
}: ComponenteInfoProps): JSX.Element => {
  return (
    <section className="container__blocoInfo" style={style}>
      {image ? <img src={image} alt="icon do bloco" /> : <></>}
      <h1 className="title-info">{title}</h1>
      <div className="percent">
        <h2>{number}</h2>
        <p>%</p>
      </div>
    </section>
  );
};

export default CriancaCMIC;
