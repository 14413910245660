import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { routes } from './routes';
import { Authorizer } from 'components';
import { agentService } from 'services';

const { PublicRoute, PrivateRoute, useAuth, checkRoles } = Authorizer;

export function IrisSocialRouters() {
  const history = useHistory();
  const auth = useAuth();

  const irisRoutes = routes();
  const user = auth.user;

  const publicRoutes = irisRoutes.filter(
    ({ roles }) => roles === undefined || roles.length === 0,
  );

  const privateRoutes = irisRoutes.filter(
    ({ roles }) => roles !== undefined && roles.length > 0,
  );

  useEffect(() => {
    const redirectURI = localStorage.getItem('REDIRECT_URI');

    if (redirectURI && redirectURI !== 'undefined') {
      localStorage.removeItem('REDIRECT_URI');
      history.push(redirectURI);
    }
  }, [history]);

  useEffect(() => {
    const { roles } = user;
    const isArticulator = checkRoles.isArticulator(roles);

    if (user && user.client && isArticulator) {
      agentService.getAgent(user.client.userId).then(data => {
        if (data.agent?.isFirstAccess) {
          history.push('/agente-mais-infancia');
        }
      });
    }
  }, [user, history]);

  return (
    <Switch>
      {publicRoutes.map(route => (
        <PublicRoute {...route} />
      ))}

      {privateRoutes.map(route => (
        <PrivateRoute {...route} />
      ))}

      <Route>
        <Redirect to="programa-mais-infancia" />
      </Route>
    </Switch>
  );
}
