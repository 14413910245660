import { Button } from 'antd';
import React from 'react';

interface ButtonPanelProps {
  step: number;
  onCancel: () => void;
  onNext: () => void;
  onBack: () => void;
}

export function Buttons({
  step,
  onCancel,
  onNext,
  onBack,
}: ButtonPanelProps) {

  const FirstButton = () => {
    const handleClick = step === 0 ? onCancel : onBack;
    const buttonTitle = step === 0 ? 'CANCELAR' : 'VOLTAR';

    return (
      <button
        className="button-link"
        onClick={handleClick}
      >
        {buttonTitle}
      </button>
    );
  }

  const SecondButton = () => {
    const buttonTitle = step === 1 ? 'CONFIRMAR' : 'AVANÇAR';

    if(step === 1)
        return (
            <Button
                type="primary" htmlType="submit"
                className="button-primary"
            >
                {buttonTitle}
            </Button>
        )

    return (
        <button
            className="button-primary"
            onClick={onNext}
        >
            {buttonTitle}
        </button>
    )
  }

  return (
    <div className="ButtonPanel">
      <FirstButton />
      <SecondButton />
    </div>
  )
}