import React from 'react';
import { Modal } from 'antd';

import './style.scss'

interface FeedbackProps {
  visible: boolean;
  error?: string;
  onOk?: () => void;
}

const ConfirmIcon = () => (
  <svg viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.0793 1.20342C50.3412 -0.380583 47.6382 -0.256443 46.0498 1.49445L20.0012 30.2505L7.41676 17.4245C5.75557 15.7378 3.05685 15.7121 1.37865 17.3732C-0.303857 19.0299 -0.329485 21.7398 1.32739 23.4266L17.0802 39.4806C17.8874 40.3025 18.9805 40.7606 20.1248 40.7606C20.1504 40.7606 20.1804 40.7606 20.206 40.7649C21.3846 40.7393 22.4949 40.2341 23.2848 39.3608L52.3694 7.25274C53.9539 5.49732 53.8258 2.79175 52.0793 1.20342Z"
      fill="#87C878"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <path
      transform="scale(.26458)"
      d="m164.72 13.199a8.698 8.698 0 0 0-6.1035 2.6426l-64.127 66.152-64.127-66.152a8.698 8.698 0 0 0-12.297-0.19141 8.698 8.698 0 0 0-0.19336 12.297l64.504 66.541-64.504 66.541a8.698 8.698 0 0 0 0.19336 12.297 8.698 8.698 0 0 0 12.297-0.1914l64.127-66.152 64.127 66.152a8.698 8.698 0 0 0 12.297 0.1914 8.698 8.698 0 0 0 0.19336-12.297l-64.504-66.541 64.504-66.541a8.698 8.698 0 0 0-0.19336-12.297 8.698 8.698 0 0 0-6.1934-2.4512z"
      fill="#d9536c"
    />
  </svg>
);

export function Feedback({ visible, error, onOk }: FeedbackProps) {

  const confirmMessage = 'Cadastro concluído com sucesso!';

  const title = error ? 'Algo deu errado!' : 'Tudo certo!';

  return (
    <Modal visible={visible} closable={false}>
      <div className="Feedback">
        <div className={`icon ${error ? 'icon-error' : ''}`}>
          {error ? <ErrorIcon /> : <ConfirmIcon />}
        </div>
        <h3>{title}</h3>

        <p>{error || confirmMessage}</p>
        <button onClick={() => onOk && onOk()}>OK</button>
      </div>
    </Modal>
  );
}