import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 5rem;
`

export const StepsContainer = styled.div`
  max-width: 1100px;
  width: 90%;
  margin: 5rem auto;

  p{
    color: var(--text-plain);
    font-size: 16px;
    text-align: justify;
  }

  span.button-form {
    padding: 1rem 4rem;
    border-radius: 12px;
    cursor: pointer;
    transition: .25s;
    &:hover {
      opacity:.8;
    }
  }

  .buttons-form {
    text-align: right;
    margin: 4rem 0;
  }

  span.button-form.right {
    background: var(--color-primary);
    color: white;
  }

  span.button-form.left, span.button-form.cancel {
    background: white;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    margin-right: 1rem;
  }

  

  .steps-header {
    width: 75%;
    margin: 0 auto;
    .ant-steps-item-title {
      font-weight: 700;
      font-family: Nunito;
    }

    .ant-steps-label-horizontal {
      flex-wrap: wrap !important;
    }

    .ant-steps-item-process > 
    .ant-steps-item-container > 
    .ant-steps-item-content > 
    .ant-steps-item-title {
      color: var(--primary-light);
    }

    .ant-steps-item-process > 
    .ant-steps-item-container > 
    .ant-steps-item-icon {
      background: var(--primary-light);
      border: none;
    }

    .ant-steps-item-finish 
    .ant-steps-item-icon {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }

    .ant-steps-item-finish > 
    .ant-steps-item-container > 
    .ant-steps-item-content > 
    .ant-steps-item-title {
      color: var(--color-primary);
    }

    .anticon svg {
      color: white;
    }
  }

  .form-content {
    padding: 5rem;
    border: 2px solid rgba(0,0,0,0.1);
    margin: 26px 0;
  }
`
