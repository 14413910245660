import React from 'react';
import { FormInstance } from 'antd';

import { City, MaritalStatus } from '../../types';
import { ArticulatorValues } from 'pages/Articulator/helper';
import { FormContainer } from './styles';
import { AddressForm } from './AddressForm';
import { ConfirmForm } from './ConfirmForm';
import { FamilyForm } from './FamilyForm';
import { PersonalForm } from './PersonalForm';
import { ProfessionalForm } from './ProfessionalForm';

interface FormProps {
  form?: FormInstance;
  children?: React.ReactNode;
}

interface PersonalFormProps {
  form?: FormInstance;
  maritalStatus: MaritalStatus[];
  loading: boolean;
  onCpfChange?: (cpf: string) => void;
}

interface ConfirmFormProps {
  data: ArticulatorValues | null;
  articulator: boolean;
}

export function FormArticulator({
  form,
  onCpfChange,
  maritalStatus,
  loading,
}: PersonalFormProps): JSX.Element {
  return (
    <FormContainer>
      <PersonalForm
        form={form}
        maritalStatus={maritalStatus}
        loading={loading}
        onCpfChange={onCpfChange}
      />
    </FormContainer>
  );
}

FormArticulator.AddressForm = function AddressFormComponent({ form, children='' }: FormProps) {
  return (
    <FormContainer>
      <AddressForm form={form} />
      {children}
    </FormContainer>
  );
};

FormArticulator.ConfirmForm = function ConfirmFormComponent({ data, articulator }: ConfirmFormProps) {
  return (
    <FormContainer>
      <ConfirmForm data={data} articulator={articulator} />
    </FormContainer>
  );
};

FormArticulator.FamilyForm = function FamilyFormComponent({ form }: FormProps) {
  return (
    <FormContainer>
      <FamilyForm form={form} />
    </FormContainer>
  );
};

interface ProfessionalFormProps extends FormProps {
  cities: City[];
  loading?: boolean;
}

FormArticulator.ProfessionalForm = function ProfessionalFormComponent({
  form,
  cities,
  loading,
}: ProfessionalFormProps) {
  return (
    <FormContainer>
      <ProfessionalForm cities={cities} loading={loading} form={form} />
    </FormContainer>
  );
};
