import React from 'react'
import {
  Form,
  FormInstance,
  Input,
  Select,
  Row,
  Col,
} from 'antd';
import { FormBox, InputCEP, InputNumber } from '../../UI';
import municipiosCE from '../../../data/ceara-full.json'
const { Item } = Form;
const { Option } = Select;

interface ConfirmValuesProp {
  form?: FormInstance;
}

export function ConfirmAction({form}: ConfirmValuesProp) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;
  
  return(
    <FormBox title="Localização" form={currentForm}>
      <Item
        label="CEP"
        name="cep"
        wrapperCol={{ span: 6 }}
      >
        <InputCEP bordered={false} disabled={true}/>
      </Item>

      <Item
        label="Município"
        name="city"
        wrapperCol={{ span: 6 }}
      >
        <Select
          showSearch
          bordered={false} disabled={true}
          showArrow={false}
        >
          {municipiosCE.features.map(city => (
            <Option value={city.properties.name} key={city.properties.id}>
              {city.properties.name}
            </Option>
          ))}
        </Select>
      </Item>

      <Row>
        <Col span={6}>
          <Item
            label="Endereço"
            name="street"
          >
            <Input bordered={false} disabled={true}/>
          </Item>
        </Col>

        <Col span={6} offset={1}>
          <Item
            label="Número"
            name="house-number"
          >
            <InputNumber bordered={false} disabled={true}/>
          </Item>
        </Col>
      </Row>

      <Row>
        <Col span={6}>
          <Item
            label="Bairro"
            name="neighborhood"
          >
            <Input bordered={false} disabled={true}/>
          </Item>
        </Col>
        <Col span={6} offset={1}>
          <Item label="Ponto de Referência" name="reference">
            <Input placeholder="Não informado" bordered={false} disabled={true}/>
          </Item>
        </Col>
      </Row>
    </FormBox>
  )
}