import { JotformAnswer } from "types";
import parse from 'html-react-parser';


export function JotformAnswerHandler(answers: JotformAnswer[], name: string): string {
    
    const filteredAnswers = answers.filter(answer => (answer.name === name))
    

  return filteredAnswers[0] ? filteredAnswers[0].answer : ''
}

export function JotformAnswerHandler2(answers: JotformAnswer[]): JotformAnswer[] {   
  const parsedAnswers = answers.map(f => {
    const parsedAnswer = parse(f.answer) as string;
    return { ...f, answer: parsedAnswer }
  }).filter(a => !!a.question);

  return parsedAnswers;
}