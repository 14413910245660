import React from 'react';
import { Select as AntSelect } from 'antd'
import { SelectValue } from 'antd/lib/select';

interface ISelectProps  {
	items: string[];
	onChange: (value: SelectValue) => void;
	value?: SelectValue | SelectValue[] | undefined;
	mode?: "multiple" | "tags" | undefined;
	defaultValue?: number | number[] | undefined;
}

const { Option: AntOption } = AntSelect;

export function Select({ 
	items, 
	onChange, 
	mode=undefined, 
	defaultValue=undefined, 
	value=undefined 
}: ISelectProps) {
	return (
		<AntSelect
			onChange={onChange}
			mode={mode}
			defaultValue={defaultValue}
		>
			{items.map((item, idx: number) => (
				<AntOption key={item} value={idx}>{item}</AntOption>
			))}
		</AntSelect>
	)
}