import styled from 'styled-components';
import {Button} from 'antd'

export const AprooveModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .text-area {
    width: 90%;
    height: 200px;
    margin-bottom: 20px;
    resize: none;
  }
`;

export const ModalIcon = styled.img`
  width: 100px;
`;

export const ModalTitle = styled.h2`
  margin: 10px 0;
  font-weight: 700;
  color: #465564;
`;

export const ModalBody = styled.p`
  margin-bottom: 20px;
  text-align: center;
  max-width: 300px;
  color: #464A4E;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
`;

export const ModalCancelButton = styled(Button)`
  margin-right: 5px;
  width: 12.4rem;
  height: 3.6rem; background: #FFFFFF;
  border: 1px solid #C2102A;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #C2102A;

  text-transform: uppercase;
`;

export const ModalConfirmButton = styled(Button)`
  margin-left: 5px;
  width: 20.4rem;
  height: 3.6rem;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #FFFFFF;
  text-transform: uppercase;
`;