import React, { useState, useEffect } from 'react';
import {
  Form,
  FormInstance,
  InputNumber,
} from 'antd';
import { FormBox } from '../../UI/index';
import TextArea from 'antd/lib/input/TextArea';

interface ActionValuesConfirmProps {
  form?: FormInstance;
  action?: any;
}

const { Item } = Form;

const elemetos = {
  edicao: {
    element: 
    <Item
      label="Edição"
      name="edicao"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber  disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroOficinasRealizadas: {
    element: 
    <Item
      label="Oficinas Realizadas"
      name="numeroOficinasRealizadas"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  qtdCriancasFormadas: {
    element: 
    <Item
      label="Crianças Formadas"
      name="qtdCriancasFormadas"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  qtdInstrumentosDoados: {
    element: 
    <Item
      label="Instrumentos Doados"
      name="qtdInstrumentosDoados"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroCapacitacoes: {
    element: 
    <Item
      label="Capacitações"
      name="numeroCapacitacoes"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroDvdsEntregues: {
    element: 
    <Item
      label="DVD's Entregues"
      name="numeroDvdsEntregues"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroExibicoes: {
    element: 
    <Item
      label="Exibições"
      name="numeroExibicoes"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  criancasAtendidas: {
    element: 
    <Item
      label="Crianças Atendidas"
      name="criancasAtendidas"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroApresentacoesCircoEscola: {
    element: 
    <Item
      label="Apresentação do Circo Escola"
      name="numeroApresentacoesCircoEscola"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroCapacitacoesProfissionais: {
    element: 
    <Item
      label="Capacitações"
      name="numeroCapacitacoesProfissionais"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroPalestras: {
    element: 
    <Item
      label="Palestras Realizadas"
      name="numeroPalestras"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  numeroParticipantes: {
    element: 
    <Item
      label="Participantes"
      name="numeroParticipantes"
      wrapperCol={{ span: 6 }}
    >
      <InputNumber disabled={true} bordered={false} min={0}/>
    </Item>
  },
  programacao: {
    element: 
    <Item
      label="Programação"
      name="programacao"
    >
      <TextArea bordered={false} disabled={true} autoSize/>
    </Item>
  }
}

export function ActionValuesConfirm({ form, action }: ActionValuesConfirmProps) {
  const [antForm] = Form.useForm();
  const currentForm = form ?? antForm;

  const [valuesAcao, setValuesAcao] = useState<any>([])

  useEffect(()=> {
    if(action) {
      for(var property in action) {
        valuesAcao.push(property)
        setValuesAcao([...valuesAcao])
      }
    }
  }, [action]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    valuesAcao.length > 0 ?
    <FormBox title="" form={currentForm}>
      {valuesAcao.map((campos) => 
          elemetos[campos]?.element
        )}
    </FormBox> : <></>
  );
};