import React from 'react';
import { Input } from 'antd';

interface InputNumberProps {
  value?: string;
  bordered?: boolean;
  disabled?: boolean;
  onChange?: (value: string | undefined) => void;
}

export function InputNumber({
  value,
  onChange,
  bordered = true,
  disabled = false,
}: InputNumberProps) {
  const numberMask = (value?: string) => {
    if (!value) return '';
    return value.replace(/\D/g,'')
  };

  const handleChange = (e) => {
    const changedValue = e.target.value.replace(/\D/g,'')

    if (onChange) {
      onChange(changedValue);
    }
  }

  return (
    <Input
      onChange={handleChange}
      value={numberMask(value)}
      placeholder={"0"}
      bordered={bordered}
      disabled={disabled}
    />
  );
}