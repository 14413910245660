import React from 'react';
import './styles.scss'

import Dado from '../Dado'

interface ListDadosTitleProps {
  titleTop: string
  titleMiddle?: string
  titleBottom: string
  lista: any[]
  maisDe?: boolean
}

export default function ListDadosTitle({titleTop, titleMiddle, titleBottom, lista, maisDe=false}: ListDadosTitleProps) {
  return (
    <>
      <section className="component-title">
        <div className="title">
          <h2 className="top">{titleTop}</h2>
          <h2 className="middle">{titleMiddle}</h2>
          <h2 className="bottom">{titleBottom}</h2>
        </div>
      
        <div className="right">
            <div className="dados" style={{gridTemplateColumns: 'repeat(1, 1fr)'}}>
              {lista.map((value, index) => {
                return (
                  <Dado key={index} number={value.number} title={value.title} subtitle={value.subtitle} maisDe={maisDe}/>
                )
              })}
            </div>
          </div>
      </section>
    </>
  );
}
