import { citiesNamesCE } from 'utils/citiesNames';

const tipo = {
  fields: [
    {
      key: 'tipo',
      label: 'Selecione o tipo desejado: ',
      widget: 'radio-group',
      initialValue: 'Mais nutrição doação',
      options: [
        ['Mais nutrição doação', 'Mais nutrição doação'],
        ['Mais nutrição capacitação', 'Mais nutrição capacitação'],
        ['Distribuição de Cestas Básicas na Pandemia COVID-19', 'Distribuição de Cestas Básicas na Pandemia COVID-19'],
      ],
    },
  ],
};

const cestas = {
  fields: [
    {
      key: 'cestasBasicasPandemia.municipios',
      label: 'Municípios',
      widget: 'select',
      options: citiesNamesCE,
      widgetProps: { mode: 'multiple', allowClear: true },
      viewWidget: 'list-view',
    },
    {
      key: 'cestasBasicasPandemia.numeroMunicipiosContemplados',
      label: 'Quantidade de municipios contemplados',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'cestasBasicasPandemia.qtdePessoasAtendidas',
      label: 'Quantidade de pessoas atendidas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'cestasBasicasPandemia.qtdeQuilosDoados',
      label: 'Quantidade de quilos doados',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'cestasBasicasPandemia.unidadesCestasDoadas',
      label: 'Quantidade de unidade de cestas doadas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
  ]
}

const doacao = {
  fields: [
    {
      key: 'maisNutricaoDoacao.qtdAlimentosDoados',
      label: 'Toneladas de alimentos doados',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'maisNutricaoDoacao.qtdPessoasAtendidas',
      label: 'Quantidade de pessoas atendidas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'maisNutricaoDoacao.periodoSemanal.dataInicio',
      label: 'Data início',
      placeholder: 'Selecione a data',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'maisNutricaoDoacao.periodoSemanal.dataFim',
      label: 'Data fim',
      placeholder: 'Selecione a data',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
  ],
};

const capacitacao = {
  fields: [
    {
      key: 'maisNutricaoCapacitacao.qtdPessoasCapacitadas',
      label: 'Quantidade de pessoas capacitadas',
      widget: 'number',
      viewWidget: 'simple-view',
      widgetProps: { min: 0 },
    },
    {
      key: 'maisNutricaoCapacitacao.municipios',
      label: 'Municípios',
      widget: 'select',
      options: citiesNamesCE,
      widgetProps: { mode: 'multiple', allowClear: true },
      viewWidget: 'list-view',
    },
    {
      key: 'maisNutricaoCapacitacao.dataCapacitacao',
      label: 'Data da capacitação',
      placeholder: 'Selecione a data',
      widget: 'date-picker',
      viewWidget: 'date-view',
    },
    {
      key: 'maisNutricaoCapacitacao.temas',
      label: 'Temas',
      widget: 'select',
      widgetProps: {
        mode: 'tags',
        notFoundContent: 'Nenhum tema informado ainda',
      },
      viewWidget: 'list-view',
    },
    {
      key: 'maisNutricaoCapacitacao.formato',
      label: 'Formato',
      widget: 'select',
      viewWidget: 'simple-view',
      options: ['Online', 'Presencial'],
    },
  ],
};

export function maisNutricao() {
  return [
    { title: 'Tipo', value: tipo },
    { title: 'Doação', value: doacao },
    { title: 'Capacitação', value: capacitacao },
    { title: 'Distribuição de Cestas Básicas na Pandemia COVID-19', value: cestas },
  ];
}
