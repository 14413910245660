type RegioesPlanejamento = {
  regiao: string
  municipios: string[]
}

export const regioesPlanejamento: RegioesPlanejamento[] = 
[
  {
    regiao: 'Cariri',
    municipios: 
    [
      'abaiara',
      'altaneira',
      'antonina do norte',
      'araripe',
      'assaré',
      'aurora',
      'barbalha',
      'barro',
      'brejo santo',
      'campos sales',
      'caririaçu',
      'crato',
      'farias brito',
      'granjeiro',
      'jardim',
      'jati',
      'juazeiro do norte',
      'lavras da mangabeira',
      'mauriti',
      'milagres',
      'missao velha',
      'nova olinda',
      'penaforte',
      'porteiras',
      'potengi',
      'salitre',
      'santana do cariri',
      'tarrafas',
      'varzea alegre'
    ]
  },
  {
    regiao: 'Maciço do Baturité',
    municipios: 
    [
      'acarape',
      'aracoiaba',
      'aratuba',
      'barreira',
      'baturite',
      'capistrano',
      'guaramiranga',
      'itapiúna',
      'mulungu',
      'ocara',
      'pacoti',
      'palmacia',
      'redencao',
    ]
  },
  {
    regiao: 'Litoral Norte',
    municipios: 
    [
      'acaraú',
      'barroquinha',
      'bela cruz',
      'camocim',
      'chaval',
      'cruz',
      'granja',
      'itarema',
      'jijoca de jericoacoara',
      'marco',
      'martinópole',
      'morrinhos',
      'uruoca',
    ]
  },
  {
    regiao: 'Centro Sul',
    municipios: 
    [
      'acopiara',
      'baixio',
      'carius',
      'catarina',
      'cedro',
      'icó',
      'iguatu',
      'ipaumirim',
      'jucás',
      'oros',
      'quixelô',
      'saboeiro',
      'umari',
    ]
  },
  {
    regiao: 'Sertão dos Inhamuns',
    municipios: 
    [
      'aiuaba',
      'arneiroz',
      'parambu',
      'quiterianópolis',
      'taua'
    ]
  },
  {
    regiao: 'Sertão de Sobral',
    municipios: 
    [
      'alcantaras',
      'cariré',
      'coreau',
      'forquilha',
      'frecheirinha',
      'graca',
      'groaíras',
      'massap',
      'meruoca',
      'miraíma',
      'moraújo',
      'mucambo',
      'pacujá',
      'pires ferreira',
      'reriutaba',
      'santana do acaraú',
      'senador sa',
      'sobral',
      'varjota',
    ]
  },
  {
    regiao: 'Vale do Jaguaribe',
    municipios: 
    [
      'alto santo',
      'ererê',
      'iracema',
      'jaguaretama',
      'jaguaribara',
      'jaguaribe',
      'limoeiro do norte',
      'morada nova',
      'palhano',
      'pereiro',
      'potiretama',
      'quixeré',
      'russas',
      'sao joao do jaguaribe',
      'tabuleiro do norte'
    ]
  },
  {
    regiao: 'Litoral Oeste / Vale do Curu',
    municipios: 
    [
      'amontada',
      'apuiarés',
      'general sampaio',
      'irauçuba',
      'itapage',
      'itapipoca',
      'pentecoste',
      'tejuçuoca',
      'tururu',
      'umirim',
      'uruburetama'
    ]
  },
  {
    regiao: 'Grande Fortaleza',
    municipios: 
    [
      'aquiraz',
      'cascavel',
      'caucaia',
      'chorozinho',
      'eusebio',
      'fortaleza',
      'guaiuba',
      'horizonte',
      'itaitinga',
      'maracanau',
      'maranguape',
      'pacajus',
      'pacatuba',
      'paracuru',
      'paraipaba',
      'pindoretama',
      'sao goncalo do amarante',
      'sao luis do curu',
      'trairi'
    ]
  },
  {
    regiao: 'Litoral Leste',
    municipios: 
    [
      'aracati',
      'beberibe',
      'fortim',
      'icapui',
      'itaicaba',
      'jaguaruana',
    ]
  },
  {
    regiao: 'Sertão dos Crateús',
    municipios: 
    [
      'ararenda',
      'catunda',
      'crateús',
      'hidrolandia',
      'independencia',
      'ipaporanga',
      'ipueiras',
      'monsenhor tabosa',
      'nova russas',
      'novo oriente',
      'poranga',
      'santa quitéria',
      'tamboril',
    ]
  },
  {
    regiao: 'Sertão Central',
    municipios: 
    [
      'banabuiú',
      'choro',
      'deputado irapuan pinheiro',
      'ibaretama',
      'ibicuitinga',
      'milhã',
      'pedra branca',
      'piquet carneiro',
      'quixadá',
      'quixeramobim',
      'senador pompeu',
      'solonópole'
    ]
  },
  {
    regiao: 'Sertão de Canindé',
    municipios: 
    [
      'boa viagem',
      'canindé',
      'caridade',
      'itatira',
      'madalena',
      'paramoti'
    ]
  },
  {
    regiao: 'Serra da Ibiapaba',
    municipios: 
    [
      'carnaubal',
      'croata',
      'guaraciaba do norte',
      'ibiapina',
      'ipu',
      'são benedito',
      'tianguá',
      'ubajara',
      'viçosa do ceara'
    ]
  }
]