import React, { memo, useEffect, useState, useContext } from 'react';
import { IResumeTimelineCard } from 'components/timeline2.0/types';
import { TimelineContext } from 'utils/context/timelineContext';

import iconClose from '../../assets/close.svg';
import './styles.scss';

interface IModalCard {
  dataModal: IResumeTimelineCard | null;

  onCloseModal: (value: boolean) => void;
}
const ModalCard = ({
  dataModal,

  onCloseModal,
}: IModalCard): JSX.Element => {
  const [totalInitiatives, setTotalInitiatives] = useState(0);
  const [isButtonOpen] = useState(true);
  const { citiesSelecteds, regionSelected } = useContext(TimelineContext);

  const filterByMunicipio = (iniciativa: any) => {
    if (iniciativa.regioes && (iniciativa.regioes.some(elem => citiesSelecteds.includes(elem)) || !regionSelected || regionSelected.length === 0)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {

    setTotalInitiatives(0);
    if (dataModal) {
      const total = dataModal?.resumo.filter(filterByMunicipio).reduce(
        (accumulator, numero) => accumulator + numero.qtd,
        0,
      );
      setTotalInitiatives(total);
    } // eslint-disable-next-line
  }, [dataModal]);

  return (
    <div className="modal__timeline">
      <section className="container__modal-card">
        <div className="header__modal-card">
          <button type="button" onClick={() => onCloseModal(false)}>
            <img src={iconClose} alt="Icone de fechar modal" />
          </button>
        </div>
        <main>
          <h1>Quantitativo de {dataModal?.id} </h1>
          {isButtonOpen && (
            <ul>
              {dataModal?.resumo.filter(filterByMunicipio).map((iniciativa, i) => {
                return (
                  <li key={i}>
                    <h3>{iniciativa.nome}</h3>
                    <p>{iniciativa.qtd}</p>
                  </li>
                );
              })}
            </ul>
          )}
          <div className="line-horizontalTotal" />
          <div className="container__modal-footer">
            <p>Total: {totalInitiatives}</p>
          </div>
        </main>
      </section>
    </div>
  );
};

export default memo(ModalCard);
