import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IResumeTimelineCard } from 'components/timeline2.0/types';
import setaBaixo from '../../assets/setBaixo.svg';

import './styles.scss';

interface IQuantitativeTotal {
  currentFrame: IResumeTimelineCard;
  ano: number;
  citiesSelecteds: string[];
  regionSelected: string;
}
const QuantitativeTotalInitiatives = ({
  currentFrame,
  ano,
  citiesSelecteds,
  regionSelected
}: IQuantitativeTotal): JSX.Element => {
  const [totalInitiatives, setTotalInitiatives] = useState(0);
  const [isButtonOpen, setIsButtonOpen] = useState(true);
  
  const memoized2017CreateCartao = useCallback(() => {
    const render = currentFrame?.resumo.some(
      acao => acao.categoria === 'cartaoMocado',
    );
    if (ano === 2017 && !render) {
      currentFrame.resumo.push({
        qtd: 1,
        categoria: 'cartaoMocado',
        nome: 'Criação do cartão mais infância',
      });
    }
    
  }, [currentFrame]); // eslint-disable-line react-hooks/exhaustive-deps

  function filterByMunicipio(iniciativa) {
    if (iniciativa.regioes && (iniciativa.regioes.some(elem => citiesSelecteds.includes(elem)) || !regionSelected || regionSelected.length === 0)){
      return true;
    } else {
      return false;
    }
  }

  const renderIniciatives = useMemo(
    () =>
      currentFrame?.resumo.filter(filterByMunicipio).map((iniciativa, i) => {
        return (
          <li key={i}>
            <h3>{iniciativa.nome}</h3>
            <p>{iniciativa.qtd}</p>
          </li>
        );
      }),
    [ano, citiesSelecteds], // eslint-disable-line react-hooks/exhaustive-deps
  );
  
  useEffect(() => {
    memoized2017CreateCartao();
    setTotalInitiatives(0);
    const total = currentFrame?.resumo.filter(filterByMunicipio).reduce(
      (accumulator, numero) => accumulator + numero.qtd,
      0,
    );
    setTotalInitiatives(total);
  }, [ano, citiesSelecteds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="container__quantitativeTotal">
      <div className="header__quantitativeTotal">
        <button type="button" onClick={() => setIsButtonOpen(!isButtonOpen)}>
          <img src={setaBaixo} alt="seta para baixo" />
        </button>
        <h1>Quantitativo de {currentFrame?.id} </h1>
      </div>
      <div className="container__quantitative-central">
        {isButtonOpen && <ul>{renderIniciatives}</ul>}
        <div className="line-horizontalTotal" />
        <div className="container__quantitative-footer">
          <p>Total: {totalInitiatives}</p>
        </div>
      </div>
    </section>
  );
};

export default memo(QuantitativeTotalInitiatives);
