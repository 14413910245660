import { Device } from "components";
import React from "react";

import './styles.scss';

type DataInfoProps = {
  number: number | string | JSX.Element,
  suffix: string,
  titleBlack?: string,
  titleOrange: string,
  withBackground?: boolean,
  titleCard?: string,
  subtitleCard?: string,
  withMoney?: boolean,
}

export function DataInfo({number, suffix, titleOrange, titleBlack, withBackground=false, titleCard, subtitleCard, withMoney=false}: DataInfoProps) {

  const posiblesSuffixes = ['milhões', 'trilhões', 'bilhões', 'toneladas'] 

  const withDe = posiblesSuffixes.includes(suffix)

  const device = Device.useDevice();

  return (
    <div 
      className={`dado-info ${withBackground && `background`}`} 
      style={titleCard ? (titleCard === 'Ações na pandemia' && device.isMD('bigger')) ? 
            {minWidth: '30rem', transform: 'translateY(-5rem)'} : 
            {minWidth: '30rem'} : withMoney ?  {marginLeft: '3rem'} : {}} >
      { titleCard && <h2 style={subtitleCard ? {fontSize: '3rem', margin: '0'} : {}}>{titleCard}</h2> }
      { subtitleCard && <strong>{subtitleCard}</strong>}
      <h3>{number}</h3>
      { suffix && <h4>{suffix}</h4> }
      <div className="title-info">
        { (withDe || titleBlack) && <strong className="black-text">{withDe ? 'de ' : ' '} {titleBlack ? `${titleBlack} ` : ''}</strong> }
        <strong className="orange-text" style={ titleBlack ? {display: 'block'} : {}}>{titleOrange}</strong>
      </div>
      { withMoney && <strong className="money">R$</strong> }
    </div>
  );
}