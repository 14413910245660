import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import { Device as DeviceObj, Breakpoint } from './Device';

interface DeviceProps {
  children: React.ReactNode;
  breakpoint?: Breakpoint;
}

const DeviceContext = React.createContext(new DeviceObj());

function useDevice() {
  return useContext(DeviceContext);
}

export function Device({ children, breakpoint }: DeviceProps) {
  const [device, setDevice] = useState(new DeviceObj(breakpoint));

  useEffect(() => {
    const listener = () => {
      setDevice(new DeviceObj(breakpoint));
    }

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, [breakpoint]);

  return (
    <DeviceContext.Provider value={device}>
      { children }
    </DeviceContext.Provider>
  );
}

Device.useDevice = useDevice;
