
import React from 'react';
import { Select } from 'antd';

const TypeFilter = ({setSelectedType, action}) => {

  const ValueAction = () => {
    if (action === 'padin') return [
      {
        label: 'Formação',
        value: 'Formação'
      },
      {
        label: 'Visita município',
        value: 'Visita municipio'
      },
      {
        label: 'Encontros Comunitários',
        value: 'Encontros Comunitários'
      }
       
    ]
    else if (action === 'maisNutricao') return [
      {
        label: 'Doação',
        value: 'Mais nutrição doação'
      },
      {
        label: 'Capacitação',
        value: 'Mais nutrição capacitação'
      },
      {
        label: 'Cestas Básicas',
        value: 'Distribuição de Cestas Básicas na Pandemia COVID-19',
      }
    ]
    else return [
      {
        label: 'Evento regular',
        value: 'Evento regular'
      },
      {
        label: 'Registro acumulado',
        value: 'Registro acumulado'
      },
      {
        label: 'Registro capacitação',
        value: 'Registro capacitação'
      },
    ]
  }


  const SelectTypeOptions = ValueAction().map(option => {
    return {
      value: option.value,
      label: option.label,
    };
  })

  const onSelectedType = (value: string[]) => {
    setSelectedType(value);
  };
  
  return (
    <div>
      <strong>Tipo de registro</strong>
      <Select
        placeholder="Todos os registros"
        options={SelectTypeOptions}
        maxTagCount={1}
        onChange={onSelectedType}
        allowClear
      />
  </div>
  );
};

export default TypeFilter;
