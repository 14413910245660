import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

type LinkProps = {
  path: string;
  title: string;
};

type BreadcrumbProps = {
  links: LinkProps[];
};

const Breadcrumb = ({ links }: BreadcrumbProps): JSX.Element => {
  const [linksState] = useState(links);

  return (
    <div className="breadcrumb-container">
      {linksState && (
        <>
          {linksState.map(link => (
            <div className="links" key={link.path}>
              <Link to={link.path}>{link.title}</Link>
              <p>/</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default memo(Breadcrumb);
