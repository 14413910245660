import FormBuilder from 'antd-form-builder';

import React, { useState, useEffect } from 'react';
import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';

interface FormacaoListProps {
  form: any;
  viewMode?: boolean;
  size: any;
}

function FormacaoList({ form, viewMode, size }: FormacaoListProps) {
  const [counterList, setCounterList] = useState(0);
  const [newCont, setNewCont] = useState(false)
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    setNewCont(true);

    if(!newCont){
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      });
    }else {
      setList([])
      Array(size)
      .fill(0)
      .forEach((_, i) => {
        addFields(i);
      });
    }
   
  }, [size]); // eslint-disable-line react-hooks/exhaustive-deps

  function addFields(indice) {
    const newList = {
      columns: 2,
      fields: [
        {
          key: `formacoes/.formacao/${indice}`,
          label: 'Formação de profissional',
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: 'O campo "Formação de profissional" é obrigatório',
            },
          ],
        },
        {
          key: `formacoes/.quantidade/${indice}`,
          label: 'Quantidade de profissionais',
          widget: 'number',
          widgetProps: { min: 0, style: {width: '75%'} },
          viewWidget: 'simple-view',
          rules: [
            {
              required: true,
              message: 'O campo "Quantidade de profissionais" é obrigatório',
            },
          ],
        },
      ],
    };

    setList(prev => [...prev, newList]);
    setCounterList(prev => prev + 1);
  }

  function removeFields(index) {
    list.splice(index, 1);

    setList([...list]);
  }

  return (
    <div>
      <fieldset>
        <legend>Formações</legend>

        {list.map((metaList, index) => {
          return (
            <React.Fragment key={index}>
              {!viewMode && index > 0 && (
                <div className="add-button" onClick={() => removeFields(index)}>
                  <IoRemoveCircle size={25} color="var(--green-dark)" />
                  <strong>Remover Formação</strong>
                </div>
              )}
              <FormBuilder form={form} meta={metaList} viewMode={viewMode} />
            </React.Fragment>
          );
        })}
        {!viewMode && (
          <div className="add-button" onClick={() => addFields(counterList)}>
            <IoAddCircle size={25} color="var(--green-dark)" />
            <strong>Adicionar novo</strong>
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default FormacaoList;
