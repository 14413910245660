import { Device } from 'components';
import React from 'react';

import './styles.scss';

interface CardSmallProps {
  iconImage?: string;
  title: string;
  listInfos: Array<{ number: string | number | JSX.Element; title: string }>;
  style?: {};
}
const CardMediumImage = ({
  iconImage,
  title,
  listInfos,
  style,
}: CardSmallProps): JSX.Element => {
  const device = Device.useDevice();
  return (
    <section
      className={`container__medidumImage ${device.isXS() && 'cards-isXS'}`}
      style={style}
    >
      <div className="container__medidumImage-header">
        {iconImage ? <img src={iconImage} alt="imagem card" /> : <></>}
        <h1>{title}</h1>
      </div>

      <ul className="container__medidumImage-center">
        {listInfos.map(info => {
          return (
            <li>
              <div className="li-container">
                <h2>{info.number}</h2>
                <p>%</p>
              </div>
              <p className="title">{info.title}</p>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default CardMediumImage;
