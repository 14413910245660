export type Breakpoint = {
  lg: number;
  md: number;
  sm: number;
  xs: number;
}

type Condition = 'bigger' | 'less' | 'exclusive';
type ConditionWithoutLess = 'bigger' | 'exclusive';

export class Device {
  private width: number;
  private breakpoint: Breakpoint = {
    lg: 1600,
    md: 992,
    sm: 576,
    xs: 320,
  };

  constructor(breakpoint?: Breakpoint) {
    this.width = window.innerWidth;
    this.breakpoint = breakpoint ?? this.breakpoint;
  }

  isLG(condition: Condition = 'bigger') {
    const { width, breakpoint } = this;

    if (condition === 'bigger' || condition === 'exclusive') {
      return width >= breakpoint.lg;
    }

    return width <= breakpoint.lg;
  }

  isMD(condition: Condition = 'bigger') {
    const { width, breakpoint } = this;

    if (condition === 'bigger') {
      return width >= breakpoint.md;
    }

    if (condition === 'less') {
      return width <= breakpoint.md;
    }

    return width >= breakpoint.md && width < breakpoint.lg;
  }

  isSM(condition: Condition = 'bigger') {
    const { width, breakpoint } = this;

    if (condition === 'bigger') {
      return width >= breakpoint.sm;
    }

    if (condition === 'less') {
      return width <= breakpoint.sm;
    }

    return width >= breakpoint.sm && width < breakpoint.md;
  }

  isXS(condition: ConditionWithoutLess = 'bigger') {
    const { width, breakpoint } = this;

    if (condition === 'bigger') {
      return width >= breakpoint.xs;
    }

    return width >= breakpoint.xs && width < breakpoint.sm;
  }

  isUnavailable() {
    return this.width < this.breakpoint.xs;
  }

  dimensions() {
    const { lg, md, sm, xs } = this.breakpoint;

    if (this.isLG()) {
      return Object.freeze({ min: lg, max: lg });
    }

    if (this.isMD()) {
      return Object.freeze({ min: md, max: lg-1 });
    };

    if (this.isSM()) {
      return Object.freeze({ min: sm, max: md-1 })
    };

    if (this.isXS()) {
      return Object.freeze({ min: xs, max: sm-1 });
    }

    return Object.freeze({ min: 0, max: xs-1 });
  }

  prefix() {
    if (this.isLG()) return 'lg';
    if (this.isMD()) return 'md';
    if (this.isSM()) return 'sm';
    if (this.isXS()) return 'xs';

    return undefined;
  }
}
