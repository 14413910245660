import React, { useEffect, useMemo, useRef, useState } from 'react';
import { 
  Affix, 
  Form as AntForm,
  Select, 
  Spin, 
  Pagination, 
  message, 
  Button, 
  Input,
} from 'antd';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { BiCalendar } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { GoPlus } from 'react-icons/go';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { Footer, Authorizer } from 'components';
import { Activity, ActivityType, AgentType, MarkedVisitationPerFamily, VisitationFamily } from 'types';
import { activityService, agentService, familiesService, localService, visitService, workPlanService, reportService } from 'services';
import { 
  Calendar, 
  GoBack, 
  FamilyVisitationListMobile, 
  CreateActivityBox, 
  currentYear, 
  currentSemester, 
  FamilyList,
  ActivityMobileList,
  useWindowDimensions,
} from 'components/Articulator';
import { 
  Container, 
  TopContainer, 
  Main,
  TabContent,
  TabButtonContainer,
  TabButton,
  FamilyListContainer,
  DateChooseContainer,
  CalendarFullScreenView,
  MobileCalendarContainer,
  GenerateReport,
} from "./styles";

import { ActivityFilters } from '../../components/Articulator';

const { Item } = AntForm;
const { Option } = Select;
const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const SPSAdmin = 'ROLE_SPS_ADMIN'
const SPSArticulator = 'ROLE_SPS_ARTICULADOR'

const STYLES = {
  generateReport: {
    select: {
      width: 200
    }
  }
}

export function ArticulatorActivitiesPlan() {
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;
  const roles = auth.user.roles;
  const [agentCity, setAgentCity] = useState('');
  const [agentId, setAgentId] = useState(-1);

  const [activities, setActivities] = useState<Activity[]>([]);
  const [workplanId, setWorkplanId] = useState(0);

  // Logged Role
  const [curRole, setCurRole] = useState('');
  
  const [year, setYear] = useState('');
  const yearWorkplan = currentYear();
  const semester = currentSemester();
  const [semesterSelect, setSemesterSelect] = useState<{ year: number, semester: number, id: number }[]>([]);
  const [semesterSelectValue, setSemesterSelectValue] = useState('');
  const [navigateCalendarMonth, setNavigateCalendarMonth] = useState('');
  const [navigateCalendarYear, setNavigateCalendarYear] = useState('');
  const [headerYearSemesterValue, setHeaderYearSemesterValue] = useState('');


  const [reportMonth, setReportMonth] = useState('')
  const [isReportLoading, setIsReportLoading] = useState(false)

  const familyListContainerRef = useRef<HTMLDivElement>(null);

  const [tabToRender, setTabToRender] = useState(0);
  const [isCreateActivityClicked, setIsCreateActivityClicked] = useState(false);

  const now = new Date();
  const monthNow = now.getMonth();

  const [dateClicked, setDateClicked] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(months[monthNow]);
  const [fullDateSelected, setFullDateSelected] = useState(String(new Date()));
  const [isVistationDataLoading, setIsVisitationDataLoading] = useState(true);

  const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { width } = useWindowDimensions();

  const location = useLocation<AgentType>()
  const history = useHistory();
  
  const [families, setFamilies] = useState<VisitationFamily[]>([]);
  const [status, setStatus] = useState('0');
  const [neighborhood, setNeighborhood] = useState('0');
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const [markedVisitationFamilies, setMarkedVisitationFamilies] = useState<MarkedVisitationPerFamily[]>([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const [observation, setObservation] = useState('');
  const [type, setType] = useState('');

  const timeNow = moment(new Date()).format('HH:00'); // minuto da hora atual;
  const [startTime, setStarTime] = useState(timeNow);
  const [endTime, setEndTime] = useState(moment(new Date().setHours(new Date().getHours()+1)).format('HH:00'));
  const [dateSelected, setDateSelected] = useState<moment.Moment>();

  const [showOnlyVisitationDataInCalendar, setShowOnlyVisitationDataInCalendar] = useState(false);
  const [isCreateActivityButtonClicked, setIsCreateActivityButtonClicked] = useState(false);
  const [showCreateActivityBox, setShowCreateActivityBox] = useState(false);

  const dataTabs = [
    { id: 1, label: 'Lista de Famílias', Icon: AiOutlineUnorderedList },
    { id: 2, label: 'Calendário', Icon: BiCalendar },
  ];

  const accessedAgentByCoordinator = location.state;

  function handleChangeMobileActiveTab(tabIndex: number) {
    window.scrollTo(0, 0);
    setTabToRender(tabIndex);
  }

  function onCalendarChange(date: moment.Moment) {
    setIsVisitationDataLoading(true);
    setDateClicked(Number(date.format('DD')));
    setCurrentMonth(months[date.month()]);
    setFullDateSelected(date.format('DD/MM/YYYY'));
    setIsVisitationDataLoading(false);
    setDateSelected(date);
  }

  async function getNeighborhoodByAlocatedCityAgent(city: string) {
    try {
      const response = await localService.getNeighborhoodByCity(city);

      setNeighborhoods(response);

    } catch(err: any) {
      message.error(err.response?.data?.mensagem || 'Não foi possível pegar famílias do bairro especificado');
    }
  }

  async function handleGetVisitationFamiliesInfoByCity(_page: number, _city: string) {
    setIsVisitationDataLoading(true);
    const isMobile = width < 768;

    if(familyListContainerRef.current && isMobile)
      familyListContainerRef.current.scrollTo(0, 0);

    try {
      const response = await familiesService.getVisitationFamilies(_page, 25, _city);
    
      setFamilies(response.content);  
      setTotalPages(response.numberOfPages);
    } catch(err) {
      message.error('Erro ao buscar famílias')
    } finally {
      setIsVisitationDataLoading(false);
    } 
  }

  async function handleGetAgent() {
    const response = await agentService.getProfessional(userId);
    return response;
  }

  async function getFamiliesOnCalendarByStatusAndNeighborhood() {
    setIsFilterLoading(true);

    try {
      const response = await visitService.getVisitationFamiliesByStatusAndNeighborhood(workplanId, neighborhood, status);

      setMarkedVisitationFamilies(response);
    } catch(err) {
      message.error('Erro ao buscar famílias')
    }

    setIsFilterLoading(false);
   
  }

  async function handleGetAllVisitationsDataByWorkplanId(workplan: number) {
    const key = 'calendarLoading';

    message.loading({ content: 'Carregando dados do calendário', key });
    try {
      const response = await visitService.getAllVisitationsDataExisting(workplan);

      setMarkedVisitationFamilies(response);
      message.success({ content: 'Dados do calendário carregados com sucesso', key });

    } catch(err) {
      message.error({ content: 'Erro ao buscar dados de visitação', key });
    }

  }

  async function handleCreateActivity() {
    if(!type || !observation)
      return message.error('Preencha todos os campos!!');

    setIsCreateActivityButtonClicked(true);

    const endDate = dateSelected;
    endDate?.hours(Number(endTime.substr(0, 2)));
    endDate?.minutes(Number(endTime.substr(3, 2)));
    const parsedEndDate = endDate;

    const clonedDateSelected = dateSelected?.clone()

    const startDate = clonedDateSelected;
    startDate?.hours(Number(startTime.substr(0, 2)));
    startDate?.minutes(Number(startTime.substr(3, 2)));
    const parsedStarDate = startDate;

    const data = {
      start: parsedStarDate,
      observation,
      end: parsedEndDate,
      type,
      workplan: workplanId,
    }

    try {
      await activityService.createActivity(data)

      getActivitiesByWorkplanId(workplanId);

      setObservation('');
      setType('');
      setIsCreateActivityClicked(false);

      message.success('Atividade criada com sucesso');
    } catch(err:any) {
      message.error(err.response.data.mensagem)
    }
    setIsCreateActivityButtonClicked(false);
    setShowCreateActivityBox(false);
  }

  async function getActivitiesByWorkplanId(workplan: number) {
    try {
      const response = await activityService.getActivitiesByWorkplan(workplan);

      setActivities(response);
    } catch(err) {
      message.error('Erro ao buscar dados de agendamento!!!');
    }
  }

  async function getActivityTypes() {
    try {
      const response = await activityService.getActivityTypes();

      setActivityTypes(response);

    } catch(err) {
      message.error('Erro ao buscar tipos');
    }
  }

  function handleNavigateCalendarToMonthAndYearBySemester(_month: string, _year: string) {
    setNavigateCalendarMonth(_month);
    setNavigateCalendarYear(_year);
  }

  async function getWorkplanId(agentId: number) {
    const response = await workPlanService.getHistoricWorkPlans(agentId);
    //const semesterSelectInfos = response.map(workplan => ({ year: workplan.year, semester: workplan.semester, id: workplan.id }))
    const semesterSelectInfos = response.filter(workplan => workplan.status==='APPROVED')
    setSemesterSelect(semesterSelectInfos);

    if(!!semesterSelectValue) {
      const selectedWorkplanBySemester = response.find(workplan => String(workplan.id)===semesterSelectValue);   
      if(!selectedWorkplanBySemester) {
        return null;
      }
      const year = String(selectedWorkplanBySemester.year);
      const month = selectedWorkplanBySemester.semester === 1 ? '0' : '6';   
      handleNavigateCalendarToMonthAndYearBySemester(month, year);
      setHeaderYearSemesterValue(`${selectedWorkplanBySemester.year}.${selectedWorkplanBySemester.semester}`)
      setWorkplanId(Number(selectedWorkplanBySemester.id));
      return selectedWorkplanBySemester;
    }

    const firstWorkPlan = response.find(workplan => workplan.year===yearWorkplan && workplan.semester===semester && workplan.status==='APPROVED');
    setWorkplanId(Number(firstWorkPlan?.id));
    setHeaderYearSemesterValue(`${firstWorkPlan?.year || currentYear()}.${firstWorkPlan?.semester || currentSemester()}`);

    return firstWorkPlan;
  }

  function handleReportGenerate() {
    setIsReportLoading(true);
    const id = localStorage.getItem("agentId");
    reportService.createReport({
      "agentId": parseInt(String(id)),
      "year": year,
      "month": months.indexOf(reportMonth)+1
    }).then((response) => {
      var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      saveAs(blob, 'Relatório-atividades.xls');
    });

    setIsReportLoading(false);
  }

  function handleRole() {
    if( roles.includes(SPSAdmin) ) {
      setCurRole('coordinator')
    } 
    else if( roles.includes(SPSArticulator) ) {
      setCurRole('agent')
    } else {
      setCurRole('')
    }
  }

  const functionTeste = () => {
    if(accessedAgentByCoordinator) {
      getWorkplanId(accessedAgentByCoordinator.id)
        .then(res => {
          if(res) {
            handleGetAllVisitationsDataByWorkplanId(res.id);
            getActivitiesByWorkplanId(res.id);
          }
        }).catch(err => message.error(err?.response?.data?.mensagem || 'Erro ao buscar informações'));
    } else {
      handleGetAgent()
      .then((res) => {
        getWorkplanId(res.id)
          .then(res => {
            if(res) {
              handleGetAllVisitationsDataByWorkplanId(res.id);
              getActivitiesByWorkplanId(res.id);
            }
          }).catch(err => message.error(err?.response?.data?.mensagem || 'Erro ao buscar informações'));

        getNeighborhoodByAlocatedCityAgent(res.city);
        handleGetVisitationFamiliesInfoByCity(0, res.city);
        setAgentCity(res.city);
        setAgentId(res.id);
      })
      .catch(err => message.error(err.response?.data?.mensagem || 'Deu erro'));
    }
  }

  useEffect(() => {
    onCalendarChange(moment());
  }, []);

  useEffect(() => {
    handleRole();
  }, []);


  useEffect(() => {
    functionTeste();
  }, [userId, semesterSelectValue]);

  useEffect(() => {
    getActivityTypes();
  }, []);

  const antIcon = <LoadingOutlined size={40} spin/>;

  const ResponsiveActivityFilter = () => (
    <ActivityFilters 
      isFilterLoading={isFilterLoading}
      neighborhoodState={neighborhood}
      setNeighborhood={setNeighborhood}
      neighborhoods={neighborhoods}
      onFilterButtonClicked={getFamiliesOnCalendarByStatusAndNeighborhood}
      status={status}
      setStatus={setStatus}
      showOnlyVisitationDataInCalendar={showOnlyVisitationDataInCalendar}
      setShowOnlyVisitationDataInCalendar={setShowOnlyVisitationDataInCalendar} 
      isMobileScreen={width < 768}
    />
  );
  const FamilyAdapatativeList = ({ mobile }) => {
    return (
      <>
        <FamilyList
          families={families}
          mobile={mobile}
          loading={isVistationDataLoading}
        />
        {!mobile && !isVistationDataLoading && (
          <div className="more-content-indicator">
            {'>'}
            {'>'}
          </div>
        )}
        {!isVistationDataLoading && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Pagination 
              current={currentPage}
              showSizeChanger={false}
              defaultCurrent={1} 
              total={totalPages} 
              pageSize={1}
              onChange={(page, pageSize) => {
                if(page > 0) {
                  setCurrentPage(page);
                  handleGetVisitationFamiliesInfoByCity(page-1, agentCity);
                }
              }}
              responsive
              hideOnSinglePage
              simple={!mobile}
            />
          </div>
        )}
      </>
    )
  }

  function renderTabPerIndexInMobileView(tabIndex: number) {
    return (
      <>
        <FamilyListContainer ref={familyListContainerRef} isActive={tabIndex===0}>
          {!isVistationDataLoading ? (
            <FamilyAdapatativeList mobile={true} />
            ) : (
            <div style={{
              paddingTop: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 40
            }}>
              <Spin indicator={antIcon} tip="Aguarde" size="large"/>
            </div>
          )}
          
        </FamilyListContainer>

        <MobileCalendarContainer 
          style={{ background: '#f2f2f2', gridArea: 'calendar' }}
          isActive={tabIndex===1}
        >
          <ResponsiveActivityFilter />
      
          {adaptativeCalendar(true)}
          </MobileCalendarContainer>

          {(dateClicked!==0 && tabIndex===1) && (
            <>
              <DateChooseContainer className="choose-date">
                <span>{currentMonth}</span>
                <div className="date-content">
                  <div className="date">
                    <strong>{dateClicked < 10 ? `0${dateClicked}` : dateClicked}</strong>
                  </div>
                  <hr />
                </div>
              </DateChooseContainer>

              <FamilyListContainer 
                isActive
                style={{ 
                  paddingTop: 10, 
                  background: '#f2f2f2', 
                  paddingBottom: 10, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                }}
                className="carousel-content"
              >
                {curRole === 'agent' && (<button
                  type="button"
                  className="add-new-event-button"
                  onClick={() => setIsCreateActivityClicked(!isCreateActivityClicked)}
                  style={isCreateActivityClicked ? { background: 'red' } : { background: 'green' }}
                  title={isCreateActivityClicked ? 'Cancelar' : 'Adicionar nova atividade'}
                >
                  {!isCreateActivityClicked ? (
                    <GoPlus color="#FFF"/>
                  ) : (
                    <span> - </span>
                  )}
                </button>)}

                {isCreateActivityClicked && curRole === 'agent' && (
                  adaptativeCreateActivityBox(false)
                )}
                <FamilyVisitationListMobile
                  markedVisitations={markedVisitationFamilies}
                  currentSelectedDate={fullDateSelected}
                />
               {!showOnlyVisitationDataInCalendar && (
                  <ActivityMobileList
                    activities={activities}
                    currentSelectedDate={fullDateSelected}
                  />
                )}
            </FamilyListContainer>
          </>
        )}
      </>
    );
  }


  const adaptativeCreateActivityBox = (isClosable=true) => (
    <CreateActivityBox
      activityType={activityTypes}
      type={type}
      setType={setType}
      observation={observation}
      setObservation={setObservation}
      dateClicked={dateSelected}
      endTime={endTime}
      setEndTime={setEndTime}
      onSaveButtonClick={handleCreateActivity}
      startTime={startTime}
      setStartTime={setStarTime}
      isLoading={isCreateActivityButtonClicked}
      isClosable={isClosable}
      onClose={() => setShowCreateActivityBox(false)}
    />
  );

  const selectSemesterValueByChoose = useMemo(() => {
    const foundValue = semesterSelect.find(s => String(s.id)===semesterSelectValue);

    if(foundValue) {
      const { year, semester } = foundValue; 
      return `${year}.${semester}`;
    }
    return '';
  }, [semesterSelect, semesterSelectValue]);
  const SelectSemester = (
    !!semesterSelect && (
      <Select 
        style={{ width: 200 }} 
        onChange={value => setSemesterSelectValue(String(value))}
        value={selectSemesterValueByChoose || "Selecione um semestre"}
      >
        {semesterSelect.map(s => (
          <Option 
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} 
            key={s.id} 
            value={s.id}
          >
            {s.year}.{s.semester}
          </Option>
        ))}  
      </Select>
    )
  );

  const adaptativeCalendar = (isMobile=false) => (
    <Calendar 
      onChange={(date) => onCalendarChange(date)} 
      dateClicked={dateSelected} 
      activities={activities}
      setActivities={setActivities}
      filteredVisits={markedVisitationFamilies}
      workplanId={workplanId} 
      agentId={String(agentId)}
      showOnlyVisitationDataInCalendar={showOnlyVisitationDataInCalendar}
      isCreateNewActivityButtonClicked={isCreateActivityButtonClicked}
      isAgent={curRole==='agent'}
      isMobile={isMobile}
      createActivityBoxOverlay={adaptativeCreateActivityBox}
      showsCreateActivityBox={showCreateActivityBox}
      onCreateActivityBoxButtonClicked={() => setShowCreateActivityBox(true)}
      SelectSemester={SelectSemester}
      navigate={{
        month: navigateCalendarMonth,
        year: navigateCalendarYear
      }}
      selectValue={semesterSelectValue}
    />           
  )

  return (
    <>
    <Container>
      <TopContainer>
        <div className="column--top">
          <GoBack 
            className="go--back"
            onClick={()=>{
              if (curRole === 'agent') {
                history.push('/articulador-inicio')
              } 
              else if (curRole === 'coordinator') {
                history.push(`/coordenador-home`)
              }
              
            }}
            title="Plano de Atividades"
            subtitle="Escala mensal"  
          />
        {curRole === 'coordinator' && (
          <GenerateReport>
            <Item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: 0 }} label="Mês" name="month">
              <Select
                value={!!reportMonth ? reportMonth : 'Selecione o mês'}
                onChange={setReportMonth}
                style={STYLES.generateReport.select}
                
              >
                {months.map(month => (
                  <Option key={month} value={month}>{month}</Option>
                ))}
              </Select>
            </Item>
            <Item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} label="Ano" name="year">
              <Input placeholder="Ano" value={year} onChange={e => setYear(e.target.value)}/>
            </Item>
            <Item label="" >
              <Button
                type="primary"
                onClick={() => handleReportGenerate()}
                style={{
                  background: '#4B9F37',
                  textTransform: 'uppercase',
                  border: 0,
                  fontWeight: 'bold',
                  width: '100%'
                }}
                loading={isReportLoading}
              >
                Gerar Relatório
              </Button>
            </Item>
          </GenerateReport>
        )}
          
        </div>
        <div style={{ 
          marginTop: (curRole === 'coordinator') ? '-22rem' : '0rem'
        }}>
          <span className="referrer-semester">
            {headerYearSemesterValue}
          </span>
        </div>
        
      </TopContainer>
      <Main>
        {width < 768 ? (
          <>
            <Affix offsetTop={0}>
              <TabContent>
                {dataTabs.map((tab, idx: number) => (
                  <TabButtonContainer key={tab.id}>
                    <TabButton 
                      className={idx===tabToRender ? 'active': ''}
                      onClick={() => handleChangeMobileActiveTab(idx)}
                    >
                      <tab.Icon />
                      <span>{tab.label}</span>
                    </TabButton>
                  </TabButtonContainer>
                ))}
              </TabContent>
            </Affix>   
            {renderTabPerIndexInMobileView(tabToRender)}
         
          </> 
        ) : (
          <CalendarFullScreenView >
            <div className="grid-filter-results-container">
              <ResponsiveActivityFilter />
              {curRole==='agent' && (
                <div style={{ 
                  marginTop: 20, 
                  maxHeight: 300, 
                  overflowY: 'scroll', 
                  width: '30rem', 
                  display:'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <span className="main-list-label">
                    Listagem de famílias
                     
                  </span>
                  <FamilyAdapatativeList mobile={false} />
                </div>
              )}
            </div>
            
              <div style={{ 
                marginTop: (curRole !== 'coordinator') ? '22rem' : '0rem'
              }}>
                <div className="calendar">
                  {adaptativeCalendar()}
                </div>
              </div> 
            
            
          </CalendarFullScreenView>
        )}
      </Main>
    </Container>
    <Footer />
    </>
  );
}
