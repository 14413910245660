import { Col, Form, Input, Row, Select, Typography } from 'antd'
import React from 'react'
//import rules from '../rules';
import { StyledForm, StyledFormWrapper, StyledTitle, StyledVerticalLine } from 'components/UI/FormBox/styles';

const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;

export function DemandDataFormEdit() {
    return (
        <>
            <StyledTitle>
                <Title level={3}>Dados da Demanda</Title>
            </StyledTitle>
            <StyledFormWrapper>
                <StyledVerticalLine />
                <StyledForm>

                    
                    <Row>
                        <Col xxl={11} lg={11} md={24} xs={24}>
                            <Item label="Orgão" name="agency">
                                <Input />
                            </Item>
                        </Col>

                        <Col xxl={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} md={24} xs={24}>
                            <Item label="Competência do órgão " name="competence">
                                <Select>
                                    <Option value="STATE">Competência Estadual</Option>
                                    <Option value="CITY">Competência Municipal</Option>
                                </Select>
                            </Item>
                        </Col>
                    </Row>

                </StyledForm>
            </StyledFormWrapper>
        </>
    )
}