import {
  deleteEventAcao,
  getEventsAcao,
} from 'services/resources/obrasServices';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { getJSONActionEspecifc } from 'utils/getJSONActionEspecifc';
import { getEventosByFiltro } from '../../services/resources/acoesServices'
import moment from 'moment';

/* Tipos do contexto */
type HistoryAcaoData = {
  actionInfos: any;
  selectedCitiesList: string[];
  selectedRegiaoList: string[];
  selectedTime: string[];
  selectedType: string;
  filtered: boolean;
  modalConfirmacaoExclusaoAcaoIsOpen: boolean;
  modalConfirmacaoInfoAcaoIsOpen: boolean;
  idEventAcaoSelected: string;
  setSelectedCitiesList: (state: string[]) => void;
  setSelectedRegiaoList: (state: string[]) => void;
  setSelectedTime: (state: string[]) => void;
  setSelectedType: (state: string) => void;
  setFiltered: (state: boolean) => void;
  setIdEventAcaoSelected: (state: string) => void;
  updateEventsList: (eventsList: any, nameActionCurrent: string) => void;
  filterEvents: (idAcao: string, nameActionCurrent: string, tamanho?:number, isDownload?:boolean) => void;
  handleDeleteEventAcao: (idAcao: string, idEvent: string, nameActionCurrent: string)  => void;
  toggleModalAcaoConfirmacaoExclusao: () => void;
  toggleModalAcaoConfirmacaoInfo: () => void;
  page: number;
  setPage: (page: number) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
  isDownloadPDFOrXLSX: boolean;
  eventsList: any;
  eventsListDownload: any;
  setEventsListDonwload: (event: any) => void;
};

type HistoryAcaoProviderProps = {
  children: ReactNode;
};

export const HistoryAcaoContext = createContext({} as HistoryAcaoData);

export function HistoryAcaoProvider({ children }: HistoryAcaoProviderProps) {
  const [actionInfos, setActionInfos] = useState<any>(null);

  /* Estados do filtro */
  const [selectedCitiesList, setSelectedCitiesList] = useState<Array<string>>(
    [],
  );
  
  const [selectedRegiaoList, setSelectedRegiaoList] = useState<Array<string>>(
    [],
  );

  const [selectedType, setSelectedType] = useState<string>('')

  const [selectedTime, setSelectedTime] = useState<Array<string>>([]);

  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [eventsList, setEventsList] = useState<Array<any>>([]);

  const [eventsListDownload, setEventsListDonwload] = useState<Array<any>>([]);

  const [filtered, setFiltered] = useState(false);

  const [isDownloadPDFOrXLSX, setIsDownloadPDFOrXLSX] = useState(false);

  /* Estados do modal */
  const [
    modalConfirmacaoExclusaoAcaoIsOpen,
    setModalConfirmacaoExclusaoAcaoIsOpen,
  ] = useState(false);
  const [modalConfirmacaoInfoAcaoIsOpen, setModalConfirmacaoInfoAcaoIsOpen] =
    useState(false);
  const [idEventAcaoSelected, setIdEventAcaoSelected] = useState('');

  /* Função responsavel por formatar uma lisra de eventos */

  /* Função que gera um update na listas de evento  */
  function updateEventsList(actionInfos: any, nameActionCurrent: string) {
    setActionInfos(actionInfos);

    if (actionInfos?.elementos.length > 0) {
      const newEventsList = actionInfos?.elementos.map(element => {
        return getJSONActionEspecifc(nameActionCurrent, element);
      });
      setEventsList(newEventsList);
   
    }
    else{
      setEventsList([]);
    
    }
  }
  /* Função que gera um update na listas de evento  */
  function updateEventsListDownload(actionInfos: any , nameActionCurrent) {
    setActionInfos(actionInfos);

    if (actionInfos?.elementos.length > 0) {
     
      const newEventsList = actionInfos?.elementos.map(element => {
        return getJSONActionEspecifc(nameActionCurrent, element);
      });
      setEventsListDonwload(newEventsList);
    }
    else{
 
      setEventsListDonwload([])
    }
  }


  function filterEvents(idAcao, nameActionCurrent, tamanho=10, isDownload=false) {
    setFiltered(true);
    let dataAtual: any = '';
    if(selectedTime?.length > 1) {
      let dataI = moment(new Date(selectedTime[0])).format('yyyy-MM');
      let dataF = moment(new Date(selectedTime[1])).format('yyyy-MM');
      dataAtual = dataI + ';' + dataF
    }

    setPage(1)
    
    const response = async () => {
      if(!isDownload){
        setIsLoading(true);
      } else {
        setIsDownloadPDFOrXLSX(true);
      }
      const data = await getEventosByFiltro(idAcao, 0, selectedCitiesList, dataAtual, selectedRegiaoList, selectedType, tamanho);
      if(isDownload){
        updateEventsListDownload(data, nameActionCurrent);
      }else {
        updateEventsList(data, nameActionCurrent);
      }
       
     };

    
     response().finally( () => {
      setIsLoading(false)
      setIsDownloadPDFOrXLSX(false)
     })
   }
  

  /* Função que apagas os eventos e lista novamente */
  function handleDeleteEventAcao(idAcao: string, idEvent: string, nameActionCurrent: string) {
    setModalConfirmacaoExclusaoAcaoIsOpen(false);

    const response = async () => {
      const data = await deleteEventAcao(idAcao, idEvent);
      const data2 = await getEventsAcao(idAcao, page - 1, setIsLoading);
      updateEventsList(data2, nameActionCurrent);

      data && setModalConfirmacaoInfoAcaoIsOpen(true);
    };
    response();
  }

  /* Alterna se o modal vai aparecer ou não e guarda o id do evento*/
  function toggleModalAcaoConfirmacaoExclusao() {
    setModalConfirmacaoExclusaoAcaoIsOpen(!modalConfirmacaoExclusaoAcaoIsOpen);
  }

  /* Alterna se o modal vai aparecer ou não */
  function toggleModalAcaoConfirmacaoInfo() {
    setModalConfirmacaoInfoAcaoIsOpen(!modalConfirmacaoInfoAcaoIsOpen);
    setModalConfirmacaoExclusaoAcaoIsOpen(false);
  }

  return (
    <HistoryAcaoContext.Provider
      value={{
        actionInfos,
        updateEventsList,
        selectedRegiaoList,
        setSelectedRegiaoList,
        selectedCitiesList,
        selectedTime,
        selectedType,
        filtered,
        setSelectedCitiesList,
        setSelectedTime,
        setSelectedType,
        setFiltered,
        setIdEventAcaoSelected,
        modalConfirmacaoExclusaoAcaoIsOpen,
        modalConfirmacaoInfoAcaoIsOpen,
        filterEvents,
        handleDeleteEventAcao,
        toggleModalAcaoConfirmacaoExclusao,
        toggleModalAcaoConfirmacaoInfo,
        idEventAcaoSelected,
        page,
        setPage,
        setIsLoading,
        isLoading,
        eventsList,
        eventsListDownload,
        setEventsListDonwload,
        isDownloadPDFOrXLSX
      }}
    >
      {children}
    </HistoryAcaoContext.Provider>
  );
}

/* Exportando o contexto  */
export const useHistoryAcao = () => {
  return useContext(HistoryAcaoContext);
};
