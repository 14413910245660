import styled from 'styled-components';

export const StyledSteps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .steps-box {
    display: flex;

    justify-content: center;
    align-items: center;

    &-xs::before, &-xs::after {
      display: block;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #f0f0f0;
      margin: 0 15px;

      @media (max-width: 450px) {
        width: 30px;
      }
    }
  }
`;

export const StyledStep = styled.div`

`;

interface StyledSeparatorProps {
  finished: boolean;
}

export const StyledSeparator = styled.div<StyledSeparatorProps>`
  height: 2px;
  width: 6vw;
  background-color: ${({ finished }) => finished ? '#008241' : '#465564'};

  margin: 0 20px;
`

interface StyledChildrenProps extends StyledSeparatorProps {
  current: boolean;
}

function getBorderColor(finished: boolean, current: boolean) {
  if (finished) return '#008241';

  if (current) return '#4B9F37';

  return '#465564';
} 

function getBgColor(finished: boolean, current: boolean) {
  if (finished) return '#008241';

  if (current) return '#4B9F37';

  return 'transparent'
}

function getColor(finished: boolean, current: boolean) {
  if (finished || current) return '#fff'

  return '#465564'
}

export const StyledIdentifier = styled.span<StyledChildrenProps>`
  font-size: 18px;
  font-weight: 700;
  color: ${({ finished, current }) => getColor(finished, current)};
  
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  border: 1px solid ${({ finished, current }) => getBorderColor(finished, current)};
  border-radius: 50%;

  background-color: ${({ finished, current }) => getBgColor(finished, current)};
`;

export const StyledTitle = styled.h3<StyledChildrenProps>`
  margin: 0;
  margin-left: 5px;
  display: inline-block;

  color: ${({ finished, current }) => getBorderColor(finished, current)};
  font-weight: 700;
  font-size: 16px;
`;
