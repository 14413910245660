import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, Select, Button, message, Pagination } from 'antd';

import './styles.scss'
import {currentSemester} from '../../components/Articulator'

import { localService } from '../../services/resources/localService'
import { AgentType, IWorkPlan, LocalCity, VisitationFamily, WorkplanType } from 'types';

import pendentIcon from '../../assets/articulator/pendent-icon.svg'
import aproovedIcon from '../../assets/articulator/aprooved-icon.svg'
import refusedIcon from '../../assets/articulator/refused-icon.svg'
import rightArrow from '../../assets/green-right-arrow.svg'
import ModalAgentSearch from 'components/ModalAgentSearch';

import { workPlanService } from '../../services/resources/workPlanService'
import { agentService, familiesService, reportService } from 'services';
import { SimpleLoading } from 'components/Articulator/SimpleLoading';

import { Device } from 'components'
import FileSaver, { saveAs } from 'file-saver';

const { Item } = Form;
const { Option } = Select;
const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export function CoordinatorHome() {

  const [isWorkplansLoading, setIsWorkplansLoading] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [selectedCity, setSelectedCity] = useState<LocalCity>();
  const [cities, setCities] = useState<LocalCity[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<string>();
  const [districts, setDistricts] = useState<string[]>([]);
  const [searchedAgents, setSearchedAgents] = useState<AgentType[]>()
  const [yearRange, setYearRange] = useState<number[]>([])

  // Agent search
  const [agentName, setAgentName] = useState('')
  const [isAgentSearchLoading, setIsAgentSearchLoading] = useState(false)
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);

  // Family search
  const [isFamilyLoading, setIsFamilyLoading] = useState(false)
  const [nis, setNis] = useState('')
  const [families, setFamilies] = useState<VisitationFamily[]>([])
  const [totalFamilies, setTotalFamilies] = useState<number>()
  const [selectedPage, setSelectedPage] = useState<number>(1)

  //Report Articulator
  const [reportMonth, setReportMonth] = useState('');
  const [year, setYear] = useState('');
  const [isAgentReportLoading, setIsAgentReportLoading] = useState(false)
  const [anualReportLoading, setIsAnualReportLoading] = useState(false)
 
  const [allWorkplans, setAllWorkplans] = useState<WorkplanType[]>([])
  const [workplans, setWorkplans] = useState<WorkplanType[]>([])
  const [countWorkplanRefused, setCountWorkplanRefused] = useState(0);
  const [countWorkplanAwaiting, setCountWorkplanAwaiting] = useState(0);
  const [countWorkplanApproved, setCountWorkplanApproved] = useState(0);
  const atualDate = parseInt(new Date().toISOString());
  const history = useHistory();
  const device = Device.useDevice()

  const handleYearRangeCB = useCallback(handleYearRange, [yearRange, workplans])
  const handleFamilyByPageCB = useCallback(handleFamilyByPage, [selectedPage, selectedCity, selectedDistrict, nis])

  useEffect(() => {
    setLoadingCity(true)
    localService
      .getCitiesByUF(1)
      .then(data => setCities(data))
      .finally(() => setLoadingCity(false))
  }, [])

  useEffect(() => {
    if (selectedCity) {
      setLoadingDistricts(true)
      localService
        .getNeighborhoodByCity(selectedCity.name)
        .then(data => setDistricts(data))
        .finally(() => setLoadingDistricts(false))
    }
  }, [selectedCity])

  useEffect(() => {
    handleYearRangeCB()
  }, [handleYearRangeCB])

  useEffect(() => {
    handleFamilyByPageCB()
  }, [handleFamilyByPageCB])

  useEffect(() => {
    setIsWorkplansLoading(true);
    workPlanService.getWorkPlans()
      .then(data => {
        setAllWorkplans(data.filter(data => data.year === atualDate && data.semester === currentSemester()));
       const response = data.filter(data => data.status === 'AWAITING');
       setWorkplans(response)
      })
      .catch(() => message.error('Erro ao listar os planos de trabalho'))
      .finally(() => setIsWorkplansLoading(false));
  }, [])

  useEffect(() => {
    getCountWorkplans()
  }, [allWorkplans])

  function handleFamilyByPage() {
    familiesService.getFamilyByCityNeighborhoodAndNis(
      selectedPage - 1,
      selectedCity?.name,
      selectedDistrict,
      nis).then(data => {
        setFamilies(data.content)
      })
  }

  function closeModal() {
    setSearchModalIsOpen(false)
  }

  function handleYearRange() {
    const aux: number[] = yearRange
    workplans?.map(workplan => {
      aux.push(workplan.year + workplan.semester * 0.1)
      aux.sort((a, b) => b - a)
      setYearRange(aux)
    })
  }

  function getYearWithSemester(workplan: WorkplanType) {
    return workplan.year + workplan.semester*0.1;
  }

  function getCountWorkplans(){
    let countApproved: number = 0, countAwaiting: number = 0, countReffused: number = 0;
    allWorkplans.forEach(element => {
      if(element.status === 'AWAITING'){
        countAwaiting++
      }else if(element.status === 'APPROVED'){
        countApproved++
      }else{
        countReffused++
      }
    });
    setCountWorkplanAwaiting(countAwaiting);
    setCountWorkplanApproved(countApproved);
    setCountWorkplanRefused(countReffused);
  }

  function handleCityChange(value: any) {
    const findedCity = cities.find(city => city.name === value);

    if (findedCity) {
      setSelectedCity(findedCity);
    }
  }

  function handleDistrictChange(value: any) {
    const findedDistricts = districts.find(district => district === value);

    if (findedDistricts) {
      setSelectedDistrict(findedDistricts);
    }
  }

  function statusRandler(workplan) {
    if (workplan.status === 'AWAITING') {
      return (
        <img alt='Awaiting Icon' src={pendentIcon} />
      )
    } else if (workplan.status === 'APPROVED') {
      return (
        <img alt='Approved Icon' src={aproovedIcon} />
      )
    } else if (workplan.status === 'REFUSED') {
      return (
        <img alt='Refused Icon' src={refusedIcon} />
      )
    }
  }

  function handleNavigateAgentWorkplans(workplan: WorkplanType) {
    history.push(`/coordenador-articulador-workplan/${workplan.id}`, workplan);
  }

  function handleNavigateToFamilyDetail(family: VisitationFamily) {
    history.push(`/acompanhamento-familia/${family.nameRF}`, family)
  }

  function handleAgentSearch() {
    setIsAgentSearchLoading(true)

    try {
      agentService.searchAgents(agentName, selectedCity?.name) 
      .then(data => {
        const newAgents = data.filter(item => item.isFirstAccess == false) 
        setSearchedAgents(newAgents)
        setSearchModalIsOpen(true)
        setIsAgentSearchLoading(false)
      })

    } catch (err) {
      message.error('Não encontrado')
    }

  }

  async function handleReportGenerate() {
    
		if(year === "" || reportMonth === ""){
			message.error('É necessário inserir os campos ano e mês corretamente')
		}else{
			setIsAgentReportLoading(true);

			await reportService.monthReport({
				"year": year,
				"month": months.indexOf(reportMonth)+1
			  }).then((response) => {
				  console.log(response)
				var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
				saveAs(blob, 'Relatório-mensal.xls');
			});

			setIsAgentReportLoading(false);
		}	
  }

  async function anualReport(){
    setIsAnualReportLoading(true);
    
    await reportService.anualReport()
    .then((response) => {
      console.log(response)
      var blob = new Blob([response], {type: 'application/vnd.oasis.opendocument.spreadsheet'});
      saveAs(blob, 'relatorio-anual.xls');
    });
    
    setIsAnualReportLoading(false);
  }

  function handleSearchFamily() {
    setIsFamilyLoading(true)

    try {
      familiesService.getFamilyByCityNeighborhoodAndNis(0, selectedCity?.name, selectedDistrict, nis)
        .then(data => {
          setTotalFamilies(data.total)
          setFamilies(data.content)
          setIsFamilyLoading(false)
        })

    } catch (err) {
      message.error('Família(s) não encontrada(s)')
    }

  }

  function handlePagination(pageNumber: number) {
    setSelectedPage(pageNumber)
  }

  const years = useMemo(() => Array.from(
    new Set(workplans
      .map(workplan => workplan.year + workplan.semester * 0.1)
      .sort((a, b) => a - b)
    )),
    [workplans]);
    
  return (
    <div className='outer-container'>

      <div className='coordinator-home-container'>
        <h1 className={device.isSM() ? 'title' : 'mobile-title'}>Agente CMIC</h1>
        <div className='content-container'>

          <ModalAgentSearch
            isOpen={searchModalIsOpen}
            closeModal={closeModal}
            agents={searchedAgents}
          />

          <div className={device.isSM() ? 'top-content-container' : 'mobile-top-content-container'}>

            <div className='new-workplans'>
              <h2>Novos Planos de Trabalho</h2>

              <div className='workplans-container'>
                  {!isWorkplansLoading ?
                    (years.map((year: number, i: number) => (
                      <div key={`${year}-${i}`}>
                        <div className="year-container">
                          <div className="workplan-units-container">
                            {workplans
                              .filter((workplan) => getYearWithSemester(workplan) === year)
                              .map((workplan) => (workplan.status === 'AWAITING' ?
                                <div
                                  className="workplan-box"
                                  onClick={() => handleNavigateAgentWorkplans(workplan)}
                                  key={workplan.id}
                                >
                                <div className="workplan-box-left">
                                  {statusRandler(workplan)}
                                  <p>{workplan.agentId?.name}</p>
                                </div>


                                  <div className="workplan-box-right">
                                    <p>{getYearWithSemester(workplan)}</p>
                                    <img src={rightArrow} alt="" />
                                  </div>

                                </div>
                              : (
                                <h2 className="no-workplans">
                                Sem Planos de Trabalho pendentes
                              </h2>          
                              )))}
                          </div>

                          <span className="vertical-year">{year}</span>
                        </div>

                        <div className="year-divisor"></div>
                      </div>
                    ))
                    ) : (
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <SimpleLoading
                          tip="Carregando histórico de planos de trabalho"
                        />
                      </div>
                    )
                  }
                  {(workplans.length === 0 && !isWorkplansLoading) && (
                    <div className="empty-workplans">
                      <strong className="empty-workplan-text">Sem planos de trabalho pendentes!</strong>
                    </div>
                  )}
              </div>

              <div className='caption-container'>
                <div className='caption'>
                  <img src={aproovedIcon} alt="" />
                  <p>Aprovado: {countWorkplanApproved}</p>
                </div>
                <div className='caption'>
                  <img src={pendentIcon} alt="" />
                  <p>Pendente: {countWorkplanAwaiting}</p>
                </div>
                <div className='caption'>
                  <img src={refusedIcon} alt="" />
                  <p>Recusado: {countWorkplanRefused}</p>
                </div>
              </div>

              <Link to='/coordenador-todos-planos-de-trabalho' className='access-all'>ACESSAR TODOS</Link>

            </div>

            <div className='articulator-search'>
              <div className='articulator-search-content'>

                <h2>Busca por agente</h2>
                <div className='drop-down'>
                  <h3>Município alocado</h3>
                  <Item name="city">
                    <Select placeholder='Municipio' loading={loadingCity} onChange={handleCityChange} showSearch>
                      {cities.map(city => (
                        <Option value={city.name} key={city.id}>
                          {`${city.name}`}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>

                <div className='name'>
                  <h3>Nome</h3>
                  <Item name="name">
                    <Input placeholder='Nome ou sobrenome' onChange={e => setAgentName(e.target.value)} />
                  </Item>
                </div>

                <Button
                  onClick={handleAgentSearch}
                  className='search'
                  loading={isAgentSearchLoading}>BUSCAR AGENTE
                </Button>

              </div>

              <Link to='/agente-mais-infancia' className='register'>CADASTRAR AGENTE</Link>

            </div>

              	<div className='articulator-report'>
					<div className='articulator-report-content'>

						<h2>Relatórios</h2>
						<div className='drop-down'>
							<h3>Mês</h3>
							<Item style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: 0 }} name="month">
								<Select
									value={!!reportMonth ? reportMonth : 'Selecione o mês'}
									onChange={setReportMonth}
									style={{width: 200}}
									
								>
									{months.map(month => (
									<Option key={month} value={month}>{month}</Option>
									))}
								</Select>
							</Item>
						</div>

						<div className='name'>
							<h3>Ano</h3>
								<Item name="year">
									<Input placeholder="Ano" value={year} onChange={e => setYear(e.target.value)}/>
								</Item>
						</div>

						<Button
							onClick={handleReportGenerate}
							className='month'
							loading={isAgentReportLoading}>GERAR RELATÓRIO MENSAL
						</Button>
						
					</div>
						<Button onClick={anualReport} className='report' loading={anualReportLoading}>GERAR RELATÓRIO ANUAL</Button>
              	</div>
        </div>

          <div className={device.isSM() ? 'bot-content-container' : 'mobile-bot-content-container'}>

            <div className='bot-content-inside-container'>

              <h2>Acompanhamento de famílias</h2>

              <div className='family-search'>

                <div className='input-cell'>
                  <h3>Município alocado</h3>
                  <Item className='input' name="city">
                    <Select placeholder='Municipio' loading={loadingCity} onChange={handleCityChange} showSearch>
                      {cities.map(city => (
                        <Option value={city.name} key={city.id}>
                          {`${city.name}`}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>

                <div className='input-cell'>
                  <h3>Bairro</h3>
                  <Item className='input' name="district">
                    <Select placeholder='Bairro' loading={loadingDistricts} disabled={!setSelectedCity} onChange={handleDistrictChange} showSearch>
                      {districts.map((district: string, key: number) => (
                        <Option value={district} key={key}>
                          {`${district}`}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </div>

                <div className='input-cell'>
                  <h3>Número do NIS</h3>
                  <Item className='input' name="nis">
                    <Input placeholder='Número do NIS' onChange={e => setNis(e.target.value)} />
                  </Item>
                </div>

                <Button
                  className='family-search-button'
                  onClick={handleSearchFamily}
                  loading={isFamilyLoading}
                >
                  BUSCAR FAMÍLIA
                </Button>

              </div>

              {
                totalFamilies ? (
                  <>
                    <div className='family-search-result'>
                      {
                        families.length > 0 && families.map(family => (
                          <p
                            key={family.id}
                            onClick={() => handleNavigateToFamilyDetail(family)}
                          >
                            {family.nameRF}
                          </p>
                        ))
                      }
                    </div>
                    <Pagination
                      className="pagination"
                      defaultCurrent={1}
                      total={totalFamilies}
                      pageSize={25}
                      showSizeChanger={false}
                      onChange={handlePagination} />
                  </>
                ) : (<></>)
              }

            </div>


          </div>
        </div>
      </div>

    </div>
  )

}