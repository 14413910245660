import React from 'react';
import { VisitationFamily } from 'types';
import { FamilyListContentDesktop, FamilyListContentMobile } from './styles';
import { Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { SimpleLoading } from 'components';

type FamiliesVisitType = {
  families: VisitationFamily[];
  mobile?: boolean;
  loading?: boolean
}

export function FamilyList({ families, mobile=false, loading=false }: FamiliesVisitType) {
  const history = useHistory();

  function handleNavigateToFamilyDetails(family: VisitationFamily) {
    history.push(`/articulador-plano-de-atividades/${family.nis}`);
  }

  return (
    mobile ? (
      <FamilyListContentMobile>
        {families.length > 0 ? (
          families.map((data) => (
            <div key={data.id}>
              <div
                className="unique-family-content"
                onClick={() => handleNavigateToFamilyDetails(data)}
              >
                <div className="personal-info">
                  <strong>{data.nameRF}</strong>
                  <span>{data.neighborhood}</span>
                </div>
                <div className="personal-info-right">
                  <span>Sem visitas</span>
                </div>
              </div>
              <Divider />
            </div>     
          ))) : (
          <div className="not-families-container">
            <span>Não há famílias</span>
          </div>
        )}
      </FamilyListContentMobile>
    ) : (
      <FamilyListContentDesktop>
        {loading && <SimpleLoading size="large" tip="Carregando..."/>}
        {families.length > 0 && !loading ? (
          <>
            {families.map((data) => (
              <div 
                key={data.id}
                className="wrapper-family-content"
              >
                <div
                  className="unique-family-content"
                  onClick={() => handleNavigateToFamilyDetails(data)}
                >
                  <div className="personal-info">
                    <strong>{data.nameRF}</strong>
                  </div>
                </div>
              </div>
            ))}
  
          </>     
        ) : (
            !loading && (
              <div className="not-families-container">  
                <span>Não há famílias</span>  
              </div>
            )
          )
        }
      </FamilyListContentDesktop>
    )
  )
}
