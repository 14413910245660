import styled from 'styled-components';
interface SimpleText {
  fontWeight: 'normal' | 'bold';
}

export const Container = styled.div`
  height: max-content;
  
`;

export const Content = styled.div`
  padding: 2.4rem 5rem;
  background: #FFF;

  max-width: 1400px;
  margin: 0 auto;

  .ant-breadcrumb {
    span {
      color: #81868A;
    }
    span.ant-breadcrumb-link {
      a {
        color: #81868A;
      }
    }
  }

  @media(max-width: 400px) {
    padding: 1rem;
  }
`;

export const Wrapper = styled.main`
  padding-top: 6rem;
`;

export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: 
    "workbox historicbox"
    "generalinfobox historicbox";
  
  gap: 4rem;
  padding: 6rem 12rem;
  max-width: 1300px;
  margin: 0 auto;

  @media(max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.5fr 1fr;
    grid-template-areas: 
    "workbox generalinfobox"
    "historicbox historicbox";

    gap: 2rem;
    padding: 4rem;
  }

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5 1fr;
    grid-template-areas: 
    "workbox"
    "generalinfobox"
    "historicbox";

    padding: 1rem;
  }

`;

export const WorkBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  grid-area: workbox;
  
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  gap: 2rem;
`;

export const WorkBoxTitle = styled.span`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.7rem;
  letter-spacing: 0em;
  text-align: center;

  color: #2D4040;

`;

export const WorkBoxDescription = styled.p`
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;

  color: #464A4E;
`;

export const WorkBoxButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22.4rem;
  height: 3.2rem;

  background: #4B9F37;
  border-radius: 5px;

  text-decoration: none;
  text-transform:uppercase;
  color: #FFF;
  
  font-weight: bold;
  line-height: 2.2rem;

  padding: .5rem;
  border: 0;
  cursor: pointer;
  transition: filter 0.3s;

  &:not(:disabled):hover {
    color: #FFF;
    filter: brightness(0.7);
  }

  &:disabled {
    background: #DCDCDC;
    cursor: not-allowed;
    color: #81868A;
  }

  &.workplan-button-refused {
    background:#ef4242;
  }
`;

export const GeneralInfoBox = styled.div`
  grid-area: generalinfobox;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: max-content;
  width:  100%;
  background: #FFF;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 2rem;
`;

export const GeneralInfoBoxTitle = styled(WorkBoxTitle)`
  text-align: center;
`;

export const GeneralInfoBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid #FFDE32;
  margin-top: 3rem;
  height: max-content;
  
`;

export const GeneralInfoBoxSimpleText = styled.span<SimpleText>`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #464A4E;
  margin-left: 1rem;
  
  + span {
    margin-top: 1rem;
  }

  &.separate-top {
    margin-top: 2.4rem;
  }
`;

export const HistoricBox = styled.div`
  grid-area: historicbox;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-height: 50rem;
  min-height: 300px;

  background: #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  padding: 2rem;
  overflow-y: scroll;

  .not-historic-articulator-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 2.2rem;
      line-height: 2.2rem;
      color: #464A4E;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #81868A;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F2F2F2;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #81868A;
    border-radius: 7px;
  }

  .ant-divider {
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.3);
  }

`;

export const HistoricBoxTitle = styled(GeneralInfoBoxTitle)`
  margin-bottom: 2rem;
`;

export const HistoricInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0;

  @media(max-width: 400px) {
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  
`;

export const HistoricInfoNavigation = styled.button`
  width: 70%;
  height: max-content;
  border: 1px solid #33A02C;
  border-radius: 7px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  cursor: pointer;
  transition: filter .2s;

  span {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    /* width: 70%; */
    word-break: break-word;
    color: #33A02C;
  }

  &.refused {
    border-color: #ef4242;
    span {
      color: #ef4242;
    }
  }

  &:hover {
    filter: brightness(0.7);
  }
`;

export const HistoricInfoYear = styled.h1`
  font-weight: 800;
  font-size: 4rem;
  line-height: 5.5rem;
  text-align: right;
  
  color: #33A02C;

  &.year-refused {
    color: #ef4242;
  }

  opacity: 0.3;
`;

export const CommentContainer = styled.div`
  flex: 1;
  padding: 6rem 17rem;
  display: flex;
  flex-direction: column;
  
  background: #f2f2f2;
  height: 100%;
  position: relative;

  textarea.ant-input {
    min-height: 10rem;
    background: #FFF;
    color: #464A4E;
  }

  .comment-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 992px;
    margin: 0 auto;
    gap: 2rem;
    position: relative;
  }

  @media(max-width: 800px) {
    padding: 3rem;
  }
`;  


export const ButtonGroup = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 1rem;

  button {
    width: 16.4rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    height: 3.6rem; 
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
  }
 
  @media(max-width: 550px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: initial;
    
    button {
      width: 100%;

      &:last-child {
        order: -1;
      }
    }
  }
`;

export const CancelButton = styled.button`
  background: #FFFFFF;
  border: 1px solid #33A02C;
  color: #33A02C;
  text-transform: uppercase;
  cursor: pointer;
  transition: .3s;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const CommentButton = styled.button`
  background: #4B9F37;
  border: 0;
  color: #FFFFFF;
  
`;
