import React from 'react';
import { match as matchType, RouteProps } from 'react-router-dom';

import 'moment/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';


import { Provider } from 'utils/context/menuContext';
import { ProviderCB } from 'utils/context/checkboxContext';
import { ProviderUtil } from 'utils/context/ultilContext';
import { ProviderRegistro } from 'utils/context/registroObrasContext';
import { HistoryObraProvider } from 'utils/context/historyObraContext';
import { HistoryAcaoProvider } from 'utils/context/historyAcaoContext';
import { HomeServidorProvider } from 'utils/context/homeServidorContext';

import {
  ObrasPage,
  //QuemSomos,
  ObrasDetalhamento,
  HomeServidor,
  InstitucionalPMI,
  HistoricoObra,
  HistoricoAcao,
  GeneralData,
  CoordinatorHome,
  ArticulatorHome,
  ArticulatorWorkPlan,
  CreateArticulatorWorkPlan,
  CurrentArticulatorWorkPlan,
  SearchArticulator,
  ArticulatorActivitiesPlan,
  FamilySearchedForArticulatorDetail,
  EditDateOfVisitArticulator,
  FamilyFollowUp,
  CoordinatorArticulator,
  CoordinatorWorkplan,
  Articulator,
  Unauthorized,
  CoordinatorAllWorkplans,
  GestanteCMIC,
  DomicilioCMIC,
  CriancaCMIC,
  QuantitativosGerais,
  GestorForm,
  ArticulatorForwardingDemands,
  ArticulatorFamiliesVisits,
  ArticulatorFamiliesNotVisited
} from '../pages';

import { Authorizer } from 'components';
import PesquisaCMIC from 'pages/PesquisaCMIC';
import CartaoCMIC from 'pages/CartaoCMIC';
import NewRegisterAction from 'pages/NewRegisterAction';
import { ConfigProvider } from 'antd';
import { TimelineContextProvider } from 'utils/context/timelineContext';

type EditActionProps = { idAcao: string; idEvento: string };
type WithId = { id: string };

interface MatchRouteProps<T> {
  match: matchType<T>;
}

type RoleFunction = (roles: string[]) => boolean;

export type SocialRouteProps = RouteProps & {
  key: string;
  roles?: RoleFunction[];
};

const { checkRoles } = Authorizer;
const {
  isArticulator,
  isSPSAdmin,
  isPublicServer,
  isManager,
  canSeePesquisaCMIC,
} = checkRoles;

export const routes = (): SocialRouteProps[] => [
  // {
  //   key: 'home',
  //   path: `/home`,
  //   exact: false,
  //   component: Home,
  // },
  // {
  //   key: 'home-admin',
  //   path: `/home-admin`,
  //   exact: false,
  //   component: HomeAdmin,
  // },
  {
    key: 'home-servidor',
    path: `/home-servidor`,
    exact: false,
    roles: [isManager],
    component: () => (
      <HomeServidorProvider>
        <HomeServidor />
      </HomeServidorProvider>
    ),
  },
  // {
  //   key: 'cadastrar-tipo-obra-acao',
  //   path: `/cadastrar-tipo-obra-acao`,
  //   exact: false,
  //   component: CadTipoObraAcao,
  // },
  // {
  //   key: 'institucional',
  //   path: `/institucional`,
  //   exact: false,
  //   component: Institucional,
  // },
  {
    key: 'obras',
    path: `/obras`,
    exact: false,
    component: () => (
      <ProviderCB>
        <ProviderUtil>
          <ObrasPage />
        </ProviderUtil>
      </ProviderCB>
    ),
  },
  {
    key: 'obras-detalhamento',
    path: `/obras-detalhamento`,
    exact: false,
    component: () => (
      <TimelineContextProvider>
        <ObrasDetalhamento />
      </TimelineContextProvider>
    )
  },
  // {
  //   key: 'pesquisa-cmic',
  //   path: `/pesquisa-cmic-antigo`,
  //   exact: false,
  //   component: () => (
  //     <Provider>
  //       <FamiliesPage />
  //     </Provider>
  //   ),
  // },
  // {
  //   key: 'pesquisa-cmic-detalhamento',
  //   path: `/pesquisa-cmic-detalhamento`,
  //   exact: false,
  //   component: () => (
  //     <Provider>
  //       <FamiliesDetails />
  //     </Provider>
  //   ),
  // },
  {
    key: 'pesquisa-cmic-gestante',
    path: `/pesquisa-cmic/gestante`,
    exact: false,
    roles: [canSeePesquisaCMIC],
    component: () => (
      <Provider>
        <GestanteCMIC />
      </Provider>
    ),
  },
  {
    key: 'pesquisa-cmic-gestante',
    path: `/pesquisa-cmic/crianca`,
    exact: false,
    roles: [canSeePesquisaCMIC],
    component: () => (
      <Provider>
        <CriancaCMIC />
      </Provider>
    ),
  },
  {
    key: 'pesquisa-cmic-domicilio',
    path: `/pesquisa-cmic/domicilio`,
    exact: false,
    roles: [canSeePesquisaCMIC],
    component: () => (
      <Provider>
        <DomicilioCMIC />
      </Provider>
    ),
  },
  {
    key: 'pesquisa-cmic',
    path: `/pesquisa-cmic`,
    exact: false,
    roles: [canSeePesquisaCMIC],
    component: () => (
      <Provider>
        <PesquisaCMIC />
      </Provider>
    ),
  },
  {
    key: 'cartao-cmic',
    path: `/cartao-cmic`,
    exact: false,
    component: () => (
      <Provider>
        <CartaoCMIC />
      </Provider>
    ),
  },

  // {
  //   key: 'pesquisa-cmic-crianca',
  //   path: `/pesquisa-cmic/crianca`,
  //   exact: false,
  //   component: () => (
  //     <Provider>
  //       <CriancaCMIC />
  //     </Provider>
  //   ),
  // },
  // {
  //   key: 'quem-somos',
  //   path: `/quem-somos`,
  //   exact: false,
  //   component: QuemSomos,
  // },
  {
    key: 'programa-mais-infancia',
    path: `/programa-mais-infancia`,
    exact: false,
    component: InstitucionalPMI,
  },
  // {
  //   // HERE
  //   key: 'home-novo-registro',
  //   path: '/home-novo-registro',
  //   exact: false,
  //   roles: [isManager],
  //   component: (props: MatchRouteProps<WithId>) => (
  // 
  //       <ProviderRegistro>
  //         <NewRegisterObra match={props.match} />
  //       </ProviderRegistro>
  // 
  //   ),
  // },
  {
    key: 'historico-obra',
    path: '/historico-obra/:id',
    exact: false,
    roles: [isManager],
    component: (props: MatchRouteProps<WithId>) => (
      <HistoryObraProvider>
        <HistoricoObra {...props} />
      </HistoryObraProvider>
    ),
  },
  {
    key: 'quantitativos-gerais',
    path: '/quantitativos-gerais',
    exact: false,
    roles: [isManager],
    component: () => (
      <QuantitativosGerais />
    ),
  },
  {
    key: 'historico-acao',
    path: '/historico-acao/:id',
    exact: false,
    roles: [isManager],
    component: (props: MatchRouteProps<WithId>) => (
      <HistoryAcaoProvider>
        <HistoricoAcao {...props} />
      </HistoryAcaoProvider>
    ),
  },
  {
    key: 'home-novo-registro-with-params',
    path: '/registrar-acao/:idAcao',
    exact: false,
    roles: [isManager],
    component: (props: MatchRouteProps<WithId>) => (
      <ProviderRegistro>
        <ConfigProvider locale={locale}>
          <NewRegisterAction />
        </ConfigProvider>
      </ProviderRegistro>
    ),
  },
  // {
  //   key: 'home-novo-registro-acao',
  //   path: '/registrar-obra/:id',
  //   exact: false,
  //   component: (props: MatchRouteProps<WithId>) => (
  // 
  //       <ProviderRegistro>
  //         <NewRegisterObra {...props} />
  //       </ProviderRegistro>
  // 
  //   ),
  // },
  // {
  //   key: 'home-editar-obra',
  //   path: '/editar-obra/:idObra/:idEvento',
  //   exact: false,
  //   component: (props: MatchRouteProps<EditObraProps>) => (
  // 
  //       <ProviderRegistro>
  //         <EditarObra {...props} />
  //       </ProviderRegistro>
  // 
  //   ),
  // },
  {
    key: 'home-editar-acao',
    path: '/editar-acao/:idAcao/:idEvento',
    exact: false,
    roles: [isManager],
    component: (props: MatchRouteProps<EditActionProps>) => (
      <ProviderRegistro>
        <ConfigProvider locale={locale}>
          <NewRegisterAction editMode />
        </ConfigProvider>
      </ProviderRegistro>
    ),
  },
  {
    key: 'dados-gerais',
    path: `/dados-gerais`,
    exact: false,
    component: GeneralData,
  },
  {
    key: 'articulator-home',
    path: `/articulador-inicio`,
    exact: false,
    roles: [isArticulator],
    component: ArticulatorHome,
  },
  {
    key: 'articulator-workplan',
    path: `/articulador-plano-de-trabalho`,
    exact: false,
    roles: [isArticulator],
    component: ArticulatorWorkPlan,
  },
  {
    key: 'articulator-create-workplan',
    path: `/articulador-cadastrar-plano-de-trabalho`,
    exact: false,
    roles: [isArticulator],
    component: CreateArticulatorWorkPlan,
  },
  {
    key: 'articulator-current-workplan',
    path: `/articulador-plano-de-trabalho-atual/:id`,
    exact: false,
    roles: [isArticulator],
    component: CurrentArticulatorWorkPlan,
  },
  {
    key: 'searcharticulator',
    path: `/pesquisa-do-articulador/:id`,
    exact: false,
    roles: [isArticulator],
    component: SearchArticulator,
  },
  {
    key: 'articulatorvisitation',
    path: `/articulador-plano-de-atividades`,
    exact: true,
    roles: [isArticulator],
    component: ArticulatorActivitiesPlan,
  },
  {
    key: 'articulatorvisitationfamilydetail',
    path: `/articulador-plano-de-atividades/:id`,
    exact: true,
    roles: [isArticulator, isSPSAdmin],
    component: FamilySearchedForArticulatorDetail,
  },
  {
    key: 'articulatorvisitationfamilyedit',
    path: `/articulador-plano-de-atividades/:id/editar-visita`,
    exact: false,
    roles: [isArticulator],
    component: EditDateOfVisitArticulator,
  },
  {
    key: 'acompanhamento-familia',
    path: `/acompanhamento-familia/:id`,
    roles: [isArticulator],
    component: FamilyFollowUp,
  },

 {
    key: 'articuladorforwardingdemands',
    path: `/articulador-encaminhamento-de-demandas`,
    roles: [isArticulator],
    component: ArticulatorForwardingDemands
  },

  {
    key: 'articuladorfamiliesvisits',
    path: `/articulador-familias-visitadas`,
    roles: [isArticulator],
    component: ArticulatorFamiliesVisits
  },

  {
    key: 'articuladorfamiliesnotvisited',
    path: `/articulador-familias-nao-visitadas`,
    roles: [isArticulator],
    component: ArticulatorFamiliesNotVisited
  },

  {
    key: 'coordenador-home',
    path: `/coordenador-home`,
    roles: [isSPSAdmin],
    component: CoordinatorHome,
  },
  {
    key: 'coordenador-articulador',
    path: `/coordenador-articulador/:id`,
    roles: [isSPSAdmin],
    component: CoordinatorArticulator,
  },
  {
    key: 'coordenador-articulador',
    path: `/coordenador-todos-planos-de-trabalho`,
    roles: [isSPSAdmin],
    component: CoordinatorAllWorkplans,
  },
  {
    key: 'coordenador-articulador-workplan',
    path: `/coordenador-articulador-workplan/:id`,
    roles: [isSPSAdmin],
    component: CoordinatorWorkplan,
  },
  {
    key: 'agente-mais-infancia',
    path: `/agente-mais-infancia`,
    roles: [isSPSAdmin, isArticulator, isPublicServer],
    component: Articulator,
  },
  {
    key: 'gestor-cadastro',
    path: `/gestor-cadastro`,
    roles: [isSPSAdmin, isPublicServer],
    component: GestorForm,
  },
  {
    key: 'nao-autorizado',
    path: `/nao-autorizado`,
    component: Unauthorized,
  },
];
