import { GeoJSON } from 'react-leaflet';

import React, { useCallback, useEffect, useState } from 'react';

import { MapContainer } from 'react-leaflet';
import cearaGeojson from '../../data/ceara-full.json';
import './styles.scss';
import { getInfosMapCMIC } from 'services/resources/mapServices';

import { GeoJsonProps } from '../RegionGeoJson/types';
import RegionGeoJson from 'components/RegionGeoJson';
import { Device } from 'components/Device';

type DataProps = {
  dadosCidade: Array<{ city: string; familyies: string }>;
  qtdCities: number;
  qtdInterviewee: number;
  qtdInterviewer: number;
};
const BaseMapCMIC = () => {
  const [dataInfosApi, setDataInfosApi] = useState<DataProps | null>(null);
  const [dataGeoJson, setDataGeoJson] = useState<Array<GeoJsonProps> | null>(null)

 

  const device = Device.useDevice();


  const serviceMapStyle = {
    color: '#4B9F37',
    weight: 1.3,
    opacity: 1,
    fillColor: '#fafafa',
    fillOpacity: 1,
  };

  const getBaseMap = useCallback(() => {
    const response = async () => {
      const data = await getInfosMapCMIC();
      setDataInfosApi(data);
    };
    response();
  }, [dataInfosApi]); // eslint-disable-line react-hooks/exhaustive-deps

  /*   const defaultZoom = 7.4; */

  useEffect(() => {
    getBaseMap();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data:any = dataInfosApi?.dadosCidade?.map(city => {
      const filterCity = cearaGeojson.features.filter(geometryCity => {
        return geometryCity.properties.name === city.city;
      });
      return { geoJson: filterCity, qtdFamilias: city.familyies };
    });

    if (data) setDataGeoJson(data);
  }, [dataInfosApi]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MapContainer
        className="Map"
        center={[-5.3, -39.45]}
        zoom={ device.isMD('less') ? 7 : 7.49999 }
        maxZoom={ device.isMD('less') ? 7 : 10 }
        minZoom={ device.isMD('less') ? 7 : 7.5 }
        scrollWheelZoom={false}
        preferCanvas
        dragging={device.isMD('less') ? false : true }
      >
        <GeoJSON data={cearaGeojson as any} style={serviceMapStyle} />
        {dataGeoJson?.map(data => {
          return <RegionGeoJson data={data} />;
        })}
        {/*      {dataGeoJson?.map(data => {
          return <MapIcon data={data} />;
        })} */}
      </MapContainer>
    </>
  );
};

export default BaseMapCMIC;
