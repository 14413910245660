import { socialApi } from "services/api";
import { CreateCommentRefuse, IWorkPlan, WorkplanType } from 'types';

const service = () => {
  const resource = '/workplans';

  async function getWorkPlans(size=0, page=0) {
    try {
      const response = await socialApi.get<IWorkPlan[]>(`${resource}`);
      
      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getWorkPlansByStatus(status: string | undefined) {
    try {
      const response = await socialApi.get<IWorkPlan[]>(`${resource}`, {
        params: {
          status
        }
      });
      
      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function getHistoricWorkPlans(agentId: number | string | undefined) {
    try {
      const response = await socialApi.get<WorkplanType[]>(`${resource}/${agentId}`);
  
      return response.data;
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function createWorkPlan(data: FormData) {
    try {
      await socialApi.post(`${resource}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    } catch(err) {
      return Promise.reject(err);
    }
  }

  async function aprooveWorkPlan(workplanId: number | undefined) {
    try {
      const response = await socialApi.patch<IWorkPlan>(`${resource}/${workplanId}`, null, {
        params: {
          statusEnum: "APPROVED"
        },
        headers: {
          "content-type": "application/json"
        }
      })

      return response.data
    } catch(err) {
      return Promise.reject(err)
    }
  }

  async function refuseWorkPlan(workplanId: number | undefined, comment: CreateCommentRefuse) {
    try {
      const response = await socialApi.patch(`${resource}/${workplanId}`, comment, {
        params: {
          statusEnum: 'REFUSED'
        },
        headers: {
          "content-type": "application/json"
        }
      })

      return response.data
    } catch(err) {
      return Promise.reject(err)
    }
  }

  async function getWorkplanDocument(workplanId: number) {
    try {
      const { data } = await socialApi.get<Blob>(`${resource}/${workplanId}/files`, {
        responseType: 'blob',
      });

      return data.slice(0, data.size, 'application/pdf');
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return {
    getWorkPlans,
    getHistoricWorkPlans,
    getWorkplanDocument,
    createWorkPlan,
    aprooveWorkPlan,
    refuseWorkPlan,
    getWorkPlansByStatus
  }
}

export const workPlanService = service();
