import { notificationToast } from 'components/NotificationToast';
import { api } from 'services/api';

export function getEventsObra(id: string) {
  const response = api
    .get(`/obras/${id}`)

    .then(res => {
      return res.data;
    })

    .catch(err => {
      return Promise.reject(err);
    });

  return response;
}

export function getEventsAcao(id: string, pagination, setIsLoading) {
  setIsLoading(true);
  const response = api
    .get(`/acoes/${id}/eventos?pagina=${pagination}`)

    .then(res => {
      setIsLoading(false);
      return res.data;
    })

    .catch(err => {
      setIsLoading(false);
      return Promise.reject(err);
    });

  return response;
}

export function getEventsObraFilter(
  id: string,
  cities: string,
  status: string,
  date: string,
) {
  const response = api
    .get(
      `/obras/${id}/filter?status${status}&municipio${cities}&date${date}`,
    )

    .then(res => {
      return res.data;
    })

    .catch(err => {
      return Promise.reject(err);
    });
  return response;
}

export function getObras() {
  const response = api
    .get(`/obras`)

    .then(res => {
      return res.data;
    })

    .catch(err => {
      return Promise.reject(err);
    });

  return response;
}

export function getEventObra(idObra: string, idEvent: string) {
  const response = api
    .get(`/obras/${idObra}/eventos/${idEvent}`)

    .then(res => {
      return res.data;
    })

    .catch(err => {
      return Promise.reject(err);
    });

  return response;
}

export function deleteEventAcao(idAcao: string, idEvent: string) {
  const response = api
    .delete(`/acoes/${idAcao}/eventos/${idEvent}`)

    .then(res => {
      notificationToast({
        type: 'success',
        message: 'Evento deletado com sucesso',
      });
      return res.data;
    })

    .catch(err => {
      notificationToast({
        type: 'error',
        message: 'Erro ao deletar evento',
      });
      return Promise.reject(err);
    });

  return response;
}

export function deleteEventObra(idObra: string, idEvent: string) {
  const response = api
    .delete(`/obras/${idObra}/eventos/${idEvent}`)

    .then(res => {
      return res.data;
    })

    .catch(err => {
      return Promise.reject(err);
    });

  return response;
}

export function attEventObra(idObra, idEvento, valores) {
  // get action event single
  api
    .put(`/obras/${idObra}/eventos/${idEvento}`, valores)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function postEventObra(valores, idObra) {
  // post event obra
  api
    .post(`/obras/${idObra}/eventos`, valores)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
