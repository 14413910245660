
type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'

type AlignItems =
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'

type Direction =
  | 'row'
  | 'row-reverse'
  | 'column'
  | 'column-reverse'

export function flexLayout(
  justifyContent: JustifyContent,
  alignItems: AlignItems,
  direction: Direction = 'row',
) {
  return `
    display: flex;
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    flex-direction: ${direction};
  `
};
