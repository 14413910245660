import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { makeOptitionsAllPOLOS } from 'utils/makeOptionsPolos';
import policlinicas from '../../../data/policlinicas.json';

import { Form, Radio } from 'antd';

import FormBuilder from 'antd-form-builder';


import { FieldsList } from '../GenerateFields/FieldsList';
import { Device } from 'components';
import { namesPoliclinicas } from 'utils/policlinicas';
import { currencyFormatter, currencyParser } from 'utils/moneyformater';

import { cepMask } from 'utils/cepMask';
import { isValidCEP } from 'utils/cep';

interface GenerateFormProps {
  infosCurrent: any;
  form: any;
  viewMode?: boolean;
  initialValues?: any;
  editMode?: boolean;
  onInitialValues?: (value: any) => void;
  ultimoEditor?: any;
  isFinished: boolean;
}

function GenerateForm({
  infosCurrent,
  form,
  viewMode,
  initialValues,
  editMode,
  onInitialValues,
  ultimoEditor,
  isFinished, 
}: GenerateFormProps) {
  const [, updateState] = useState<any>();
  const [ , setCurrentPolosPadin] = useState<any>([[]]);
  const [valueTotal, setValueTotal] = useState(0)
  const forceUpdate = useCallback(() => updateState({}), []);

    form.setFieldsValue({
      total: valueTotal
    });
  
  useEffect(() => {  

    form.resetFields();
   
    if (initialValues?.formacao && initialValues?.formacao?.ciclosPadin?.length > 0)
      onChangeSelectPadin(initialValues?.formacao?.ciclosPadin);


   
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=> {
   
    const valorInicial = form.getFieldValue(`valorInicial`) ?? 0
    const valorAditivo = form.getFieldValue(`valorAditivo`) ?? 0

    const currentValueTotal = valorInicial + valorAditivo
    setValueTotal(currentValueTotal)
 
  }, [form.getFieldValue(`valorInicial`), form.getFieldValue(`valorAditivo`)]) // eslint-disable-line react-hooks/exhaustive-deps

  const device = Device.useDevice();
  infosCurrent.functionValue?.map(campo => {

    campo.value['columns'] = device.isSM('less') ? 1 : 2;
  
    campo.value.fields.forEach(field => {
      if (field.widget === 'date-picker') {
        field['placeholder'] = 'Selecione a data';
      }

      if(field.widget === 'number' && field.key !== 'total') {
        field['widgetProps'] = { min: 0, style: {width: '75%'}}
      }

      if(infosCurrent.value !== "publicacoes"){
        
        if ((field.key !== 'registroCapacitacao.regiao') && (field.key !== 'dataComemorativa') && (field.key !== 'total')) {
          field['rules'] = [
            {
              required: true,
              message: `O campo "${field.label}" é obrigatório.`,
            },
          ];
        }
      }

      if(infosCurrent.value === "praiaAcessivel" && ((field.key === 'data') || field.key === 'dataComemorativa')){
        field['widgetProps'] = 
          {
            disabled: editMode,
          }
      }

      if (field.key.includes('cestasBasicasPandemia.municipios')){
        field['widgetProps'] = {
          onChange: (value) => {
            form.setFieldsValue({
                cestasBasicasPandemia: {
                  numeroMunicipiosContemplados: value.length
                },
              },);  
          }, mode: 'multiple', allowClear: true 
        }
      }
      

      if (field.key.includes('numeroMunicipiosContemplados')){
        field['widgetProps'] = {
          disabled: true
        }
      } 
      
      if(field.label.includes('Valor')){
        field['placeholder'] = '0,00';
      }

      if(field.label === 'Ano') {
        field['initialValue'] = new Date().getFullYear();
      }

      if (
        field.key.includes('valorInicial') ||
        field.key.includes('valorAditivo') ||
        field.key.includes('valor') ||
        field.key === 'total'
      ) {

        field['viewWidget'] = 'money-view';
        field['widget'] = 'number';

        if(field.key === 'total') {
          field['widgetProps'] = {
            formatter: value => currencyFormatter(value),
            style: {width: '75%'},
            parser: currencyParser,
            bordered: false,
            disabled: true
          }
        } else {
          field['widgetProps'] = {
            formatter: value => currencyFormatter(value),
            style: {width: '75%'},
            parser: currencyParser
          };
        }

      }

      if( field.key.includes('cep') ){
        field['widget'] = 'number';

        if (infosCurrent.value === "publicacoes") {
          field['rules'] = [
            {
              validator: (rule, value, callback) => {
                return new Promise<void>((resolve, reject) => {
               
                  if (value && !isValidCEP(value.toString())) {
                    reject(new Error('CEP inválido.'))
                  } else {
                    resolve()
                  }
                  
                })
              },
            }
          ];
        } else{ 
          field.rules.push(
            (
              {
                validator: (rule, value, callback) => {
                  return new Promise<void>((resolve, reject) => {
                    
                    if (!isValidCEP(value?.toString())) {
                      reject(new Error('CEP inválido.'))
                    } else {
                      resolve()
                    }
                    
                  })
                },
              }
            )
          )
        }
        
        field['viewWidget'] = 'cep-view';
        field['widgetProps'] = {};
      
        field.widgetProps['formatter'] = value => cepMask(value);
        field.widgetProps['maxLength'] = 10;
        field.widgetProps['controls'] = true;
        field.widgetProps['style'] = {width: '100%',};

      }

      if (field.key.includes('dataInicio')) {
        if(editMode && viewMode === undefined) {
          if(field.key.includes('periodoFormacao.dataInicio')){
            field['initialValue'] = initialValues?.periodoFormacao?.dataInicio;
          } else if(field.key.includes('periodoSemanal.dataInicio')){
            if(initialValues?.maisNutricaoDoacao){
              field['initialValue'] = initialValues.maisNutricaoDoacao.periodoSemanal.dataInicio;               
            }
          } else if(field.key.includes('periodoEntrega.dataInicio')){
            field['initialValue'] = initialValues?.periodoEntrega?.dataInicio;               
          } else if(field.key.includes('periodo.dataInicio') && infosCurrent.nome === "Criança Feliz"){
            if(initialValues?.registroAcumulado){
              field['initialValue'] = initialValues.registroAcumulado.periodo.dataInicio;               
            }
            if(initialValues?.registroCapacitacao){
              field['initialValue'] = initialValues.registroCapacitacao.periodo.dataInicio;               
            }
          } else if(field.key.includes('periodo.dataInicio') && infosCurrent.nome === "Padin"){
            if(initialValues?.formacao){
              field['initialValue'] = initialValues.formacao.periodo.dataInicio;               
            }
          }
          else {
            field['initialValue'] = initialValues?.periodo?.dataInicio;   
          }
        }

        if(viewMode) {
          delete field.dependencies;
          delete field.rules;
          delete field.initialValue;
      
          if(field.key.includes('periodoFormacao.dataInicio')){
          field['initialValue'] = form.getFieldValue('dataInicioFormacao');
          }else{
            field['initialValue'] = form.getFieldValue('dataInicio'); 
          } 

        } else {
          if(field.key.includes('periodoFormacao.dataInicio')){
            field['name'] = 'dataInicioFormacao';

            field['dependencies'] = ["dataFimFormacao"];
          }else{
            field['name'] = 'dataInicio'
            field['dependencies'] = ["dataFim"];                   
          }

          field['viewWidget'] = 'date-view';

          field.rules.push({
            validator: (rule, value, callback) => {
              return new Promise<void>((resolve, reject) => {
                const newDataInicio = new Date(value).getTime();

                let dataFinal = 0;

                if (field.name === 'dataInicioFormacao') {
                  dataFinal = new Date(
                    form.getFieldValue(`dataFimFormacao`),
                  ).getTime()
                } else if(field.name === 'dataInicio') {
                  dataFinal = new Date(
                    form.getFieldValue(`dataFim`),
                  ).getTime();
                }

                if (dataFinal && (newDataInicio > dataFinal)) {
                  reject(new Error('O evento deve iniciar antes da data final'));
                } else {
                  resolve();
                }
              }); 
            },
          });
        }
      }


      if (field.key.includes('dataFim')) {
        if(editMode && !viewMode) {
          if(field.key.includes('periodoFormacao.dataFim')){
            field['initialValue'] = initialValues?.periodoFormacao?.dataFim;
          }else if(field.key.includes('periodoSemanal.dataFim')){
            if(initialValues?.maisNutricaoDoacao){
              field['initialValue'] = initialValues.maisNutricaoDoacao.periodoSemanal.dataFim;               
            }
          } else if(field.key.includes('periodoEntrega.dataFim')){
            field['initialValue'] = initialValues?.periodoEntrega?.dataFim;               
          } else if(field.key.includes('periodo.dataFim') && infosCurrent.nome === "Criança Feliz"){
            if(initialValues?.registroAcumulado){
              field['initialValue'] = initialValues.registroAcumulado.periodo.dataFim;               
            }
            if(initialValues?.registroCapacitacao){
              field['initialValue'] = initialValues.registroCapacitacao.periodo.dataFim;               
            }
          } else if(field.key.includes('periodo.dataFim') && infosCurrent.nome === "Padin"){
            if(initialValues?.formacao){
              field['initialValue'] = initialValues.formacao.ciclosPadin.periodo.dataFim;               
            }
          } else {
            field['initialValue'] = initialValues?.periodo?.dataFim;   
          }
       
        }

        if(viewMode){
          delete field.dependencies;
          delete field.rules;
          delete field.initialValue;

    
          if(field.key.includes('periodoFormacao.dataFim')){
            field['initialValue'] = form.getFieldValue('dataFimFormacao');           
              

          }else{
            field['initialValue'] = form.getFieldValue('dataFim');   
                  
          }

        } else{
          if(field.key.includes('periodoFormacao.dataFim')){
            field['dependencies'] = ["dataInicioFormacao"];
            field['name'] = 'dataFimFormacao';
          }else{
            field['dependencies'] = ["dataInicio"];
            
            field['name'] = 'dataFim';
            
          }

          field.rules.push({
            validator: (rule, value, callback) => {
              
              return new Promise<void>((resolve, reject) => {
                const newDataFim = new Date(value).getTime();

                let dataInicio = 0;

                if (field.name === 'dataFimFormacao') {
                  dataInicio = new Date(
                    form.getFieldValue(`dataInicioFormacao`),
                  ).getTime();
                } else if(field.name === 'dataFim') {
                  dataInicio = new Date(
                    form.getFieldValue(`dataInicio`),
                  ).getTime();
                }
              
                if (dataInicio && (newDataFim < dataInicio)) {
                  reject(
                    new Error('O evento deve finalizar depois da data inicial'),
                  );
                } else {
                  resolve();
                }
              });
            },
          });
        }
      }

    });

  });

  function handleTypeMaisNutricao() {
    
    if(editMode){
      form.setFieldsValue({
        tipo: initialValues?.tipo
      });
    }
  
    if (
      form.getFieldValue('tipo') === 'Mais nutrição doação' ||
      form.getFieldValue('tipo') === undefined
    ) {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[1].title}</legend>

          <FormBuilder
            form={form}
            meta={infosCurrent.functionValue[1].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    } else if (form.getFieldValue('tipo') === 'Mais nutrição capacitação') {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[2].title}</legend>

          <FormBuilder
            form={form}                        
            meta={infosCurrent.functionValue[2].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    } else {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[3].title}</legend>

          <FormBuilder
            form={form}                        
            meta={infosCurrent.functionValue[3].value}
            viewMode={viewMode}
          />
        </fieldset>
      )
    }
  }

  function handleTypeCriancaFeliz() {

    if(editMode){
      form.setFieldsValue({
        tipo: initialValues?.tipo
      });
    }
    if (
      form.getFieldValue('tipo') === 'Evento regular' ||
      form.getFieldValue('tipo') === undefined
    ) {
     
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[1].title}</legend>

          <FormBuilder
            form={form}
            meta={infosCurrent.functionValue[1].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    } else if (form.getFieldValue('tipo') === 'Registro acumulado') {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[2].title}</legend>

          <FormBuilder
            form={form}
            meta={infosCurrent.functionValue[2].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    } else  {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[3].title}</legend>

          <FormBuilder
            form={form}
            meta={infosCurrent.functionValue[3].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    }
  }
  const metaPadin = {
    fields: [
      {
        key: 'formacao.polos',
        label: 'Polo de formação',
        widget: 'select',
        options: ['Fortaleza', 'Camocim', 'Sobral', 'Crateús', 'Crato'],
        widgetProps: {
          mode: 'tags',
          onChange: e => {
            onChangeSelectPadin(e);
          },
        },
      },
    ],
  };


  function handleTypePadin() {
    if (
      form.getFieldValue('tipo') === 'Visita Municipio'
    ) {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[0].title}</legend>
          <FormBuilder form={form} meta={metaPadin} viewMode={viewMode} />
          <FormBuilder
            form={form}
            meta={infosCurrent.functionValue[0].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    } 
  }
  function handleTypePraiaAcessivel() {
    if(editMode){
      form.setFieldsValue({
        dataComemorativa: initialValues?.dataComemorativa
      });
    }
    if (!form.getFieldValue('dataComemorativa')) {

      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[1].title}</legend>

          <FormBuilder
            form={form}
            meta={infosCurrent.functionValue[1].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    }
  }
  function selectTypeMaisNutricao() {
    if (infosCurrent.value === 'maisNutricao') {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[0].title}</legend>

          <FormBuilder
            disabled={editMode}
            form={form}
            meta={infosCurrent.functionValue[0].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    }
  }
  
  function selectTypePraiaAcessivel() {
    if (infosCurrent.value === 'praiaAcessivel') {
      return (
        <>
          <fieldset>
            <legend>{infosCurrent.functionValue[0].title}</legend>

            <FormBuilder
              form={form}
              meta={infosCurrent.functionValue[0].value}
              viewMode={viewMode}
            />
          </fieldset>
          <fieldset>
            <legend>{infosCurrent.functionValue[2].title}</legend>

            <FormBuilder
              form={form}
              meta={infosCurrent.functionValue[2].value}
              viewMode={viewMode}
            />
          </fieldset>
        </>
      );
    }
  }

  function selectTypeCriancaFeliz() {
    if (infosCurrent.value === 'criancaFeliz') {
      return (
        <fieldset>
          <legend>{infosCurrent.functionValue[0].title}</legend>

          <FormBuilder
            //disabled={editMode}
            form={form}
            meta={infosCurrent.functionValue[0].value}
            viewMode={viewMode}
          />
        </fieldset>
      );
    }
  }

  const metaRadioPadin = {
    key: 'tipo',
    label: 'Selecione o tipo desejado: ',
    widget: 'radio-group',
    initialValue: 'Formação',
    children: [  { label: 'Visita município', value: 'Visita município', disabled: true },
    { label: 'Formação', value: 'Formação', disabled: false }].map(option => <Radio value={option.value} disabled={option.disabled}>{option.label}</Radio>),
  };

  function selectTypePadin() {
    if (infosCurrent.value === 'padin') {
      return (
        <fieldset>
          <legend>Tipo: </legend>

          <FormBuilder
            disabled={editMode}
            form={form}
            meta={metaRadioPadin}
            viewMode={viewMode}
          />
        </fieldset>
      );
    }
  }

  function onChangeSelectPadin(names: any) {
    
      const polos = initialValues.formacao.ciclosPadin
      const polosPadinAux: any =  []
      Array(polos.size)
      .fill(0)
      .forEach((_, i) => {
    
        const newPolos: any = makeOptitionsAllPOLOS(polos[i].polos);
    
        polosPadinAux.push(newPolos)
      })

        const clonePlos = cloneDeep(polosPadinAux)
        setCurrentPolosPadin(clonePlos);
  }
  
  function onChangeSelectPoliclinicas(name: string) {
    if (onInitialValues) {
      policlinicas.forEach(item => {
        if (item.policlinicas === name) {
          onInitialValues({
            initialValues,
            nome: item.policlinicas,
            municipio: item.municipio,
            localizacao: item.localizacao,
          });
        }
      });
    }
  }

  const metaNucleo = {
    fields: [
      {
        key: 'nome',
        label: 'Nome da policlínica',
        widget: 'select',
        options: namesPoliclinicas,
        widgetProps: {
          onChange: e => {
            onChangeSelectPoliclinicas(e);
          },
        },
      },
    ],
  };
  function makeForm() {
 
    let tamanho = 0;
    let diagnostico = 0;

    if (initialValues?.porRegiao)
      tamanho = initialValues?.porRegiao?.length;
    else if (initialValues?.profissionaisCapacitados)
      tamanho = initialValues?.profissionaisCapacitados?.length;
    else if (initialValues?.maisNutricaoDoacao)
      tamanho = initialValues?.maisNutricaoDoacao?.entidadeMunicipios?.length;
    else if (initialValues?.formacoes)
      tamanho = initialValues?.formacoes?.length;
    else if (initialValues?.ciclosSeduc)
      tamanho = initialValues?.ciclosSeduc?.length;
    else if (initialValues?.formacao?.ciclosPadin)
    {
      tamanho = initialValues?.formacao?.ciclosPadin?.length;


    }
    else if (initialValues?.porMunicipioCapacitacoes)
      tamanho = initialValues?.porMunicipioCapacitacoes?.length;

    if (initialValues?.diagnostico)
      diagnostico = initialValues?.diagnostico?.length;

    diagnostico = diagnostico === 0 ? 1 : diagnostico;
    tamanho = tamanho === 0 ? 1 : tamanho;

    return (
      <Form
        form={form}
        onValuesChange={forceUpdate}
        layout="vertical"
        initialValues={initialValues}
      >
        {selectTypeMaisNutricao()}
        {selectTypeCriancaFeliz()}
        {selectTypePadin()}
        {selectTypePraiaAcessivel()}
        {infosCurrent.value === 'maisNutricao' ? (
          handleTypeMaisNutricao()
        ) : infosCurrent.value === 'criancaFeliz' ? (
          handleTypeCriancaFeliz()
        ) : infosCurrent.value === 'padin' ? (
          handleTypePadin()
        ) : infosCurrent.value === 'praiaAcessivel' ? (
          handleTypePraiaAcessivel()
        ) : (
          <>
            {infosCurrent.functionValue?.map(campo => {
              return (
                <>
                  <fieldset>
                    <legend>{campo.title}</legend>
                    {infosCurrent.value === 'nucleoEstimulacaoPrecoce' &&
                      campo.title === 'Informações geral' && (
                        <FormBuilder
                          form={form}
                          meta={metaNucleo}
                          viewMode={viewMode}
                        />
                      )}
                     
                    <FormBuilder
                      form={form}
                      meta={campo.value}
                      viewMode={viewMode}
                    />
                  </fieldset>
                </>
              );
            })}
          </>
        )}


        <FieldsList
          editMode={editMode}
          initialValues={initialValues}
          form={form}
          viewMode={viewMode}
          actionCurrent={infosCurrent.value}
          size={tamanho}
          diagnosticoSize={diagnostico}
        />

        {ultimoEditor && editMode && (
          <fieldset>
            <legend>Informações do último editor</legend>
            <strong style={{ display: 'block' }}>
              Nome: {ultimoEditor.nome}
            </strong>
            <strong>E-mail: {ultimoEditor.email}</strong>
            <strong style={{ marginBottom: '1rem', display: 'block' }}>
              Data da modificação: {ultimoEditor.ultimaModificacao}
            </strong>
          </fieldset>
        )}
      </Form>
    );
  }
  return <>{(initialValues !== null || !editMode) && makeForm()}</>;
}

export default GenerateForm;
