import moment from 'moment';
import { makeOptitionsNamesCurrentCREDES } from './makeOptionsCREDES';
import { makeOptitionsNamesCurrentPOLOS } from './makeOptionsPolos';
import { APIRetornActions } from './types';

export function getJSONActionEspecifc(
  nameAction: string,
  actions: APIRetornActions,
) {
  if (nameAction === 'Arte na Praça')
    return {
      ...actions.arteNaPraca,
      data: moment(actions.arteNaPraca?.data),
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
  if (nameAction === 'Toda Banda de Música é uma escola') {
    const action = {
      ...actions.bandaMusicaEscola,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };

    return action;
  }
  if (nameAction === 'Capacitação dos Agentes Comunitários de Saúde') {
    let arrayValues = {
      ...actions.capacitacaoAgentesComunitariosSaude,
      periodo: {
        dataInicio: moment(
          actions.capacitacaoAgentesComunitariosSaude?.periodo?.dataInicio,
        ),
        dataFim: moment(
          actions.capacitacaoAgentesComunitariosSaude?.periodo?.dataFim,
        ),
      },
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };

    Array(
      actions?.capacitacaoAgentesComunitariosSaude?.profissionaisCapacitados
        ?.length,
    )
      .fill(0)
      .forEach((_, i) => {
        arrayValues = {
          ...arrayValues,
          [`profissionaisCapacitados/`]: {
            ...arrayValues[`profissionaisCapacitados/`],
            [`tipo/${i}`]:
              actions?.capacitacaoAgentesComunitariosSaude
                ?.profissionaisCapacitados[i]?.tipo,
            [`qtdProfissionaisCapacitados/${i}`]:
              actions?.capacitacaoAgentesComunitariosSaude
                ?.profissionaisCapacitados[i]?.qtdProfissionaisCapacitados,
          },
        };
      });

    return arrayValues;
  }
  if (nameAction === 'Documentários')
    return {
      ...actions.documentario,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
  if (nameAction === 'Lançamento de Livro ou Relatório') {
    const action = {
      ...actions.lancamentoLivroRelatorio,
      dataLancamento: moment(actions.lancamentoLivroRelatorio?.dataLancamento),
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
  
    return action;
  }
  if (nameAction === 'Núcleos de Estimulação Precoce') {
    let action = {
      ...actions.nucleoEstimulacaoPrecoce,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
    Array(actions.nucleoEstimulacaoPrecoce?.formacoes.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`formacoes/`]: {
            ...action[`formacoes/`],
            [`formacao/${i}`]:
              actions.nucleoEstimulacaoPrecoce?.formacoes[i].formacao,
            [`quantidade/${i}`]:
              actions.nucleoEstimulacaoPrecoce?.formacoes[i].quantidade,
          },
        };
      });

    Array(actions.nucleoEstimulacaoPrecoce?.diagnostico?.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`diagnostico/`]: {
            ...action[`diagnostico/`],
            [`nomeDiagnostico/${i}`]:
              actions.nucleoEstimulacaoPrecoce?.diagnostico[i].nomeDiagnostico,
            [`qtdAtendimentos/${i}`]:
              actions.nucleoEstimulacaoPrecoce?.diagnostico[i].qtdAtendimentos,
          },
        };
      });
 
    return action;
  }

  if (nameAction === 'Praia Acessível') {
    const action = {
      ...actions.praiaAcessivel,
      data: moment(actions.praiaAcessivel?.data),
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };

    return action;
  }
  if (nameAction === 'Prevenção em Família') {
    let action = {
      ...actions.prevencaoEmFamilia,
      periodo: {
        dataInicio: moment(actions.prevencaoEmFamilia?.periodo?.dataInicio),
        dataFim: moment(actions.prevencaoEmFamilia?.periodo?.dataFim),
      },
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };

    Array(actions.prevencaoEmFamilia?.porRegiao.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`porRegiao/`]: {
            ...action[`porRegiao/`],
            [`municipiosParticipantes/${i}`]:
              actions.prevencaoEmFamilia?.porRegiao[i].municipiosParticipantes,
            [`qtdCapacitacoes/${i}`]:
              actions.prevencaoEmFamilia?.porRegiao[i].qtdCapacitacoes,
            [`qtdFamiliaresBeneficiados/${i}`]:
              actions.prevencaoEmFamilia?.porRegiao[i]
                .qtdFamiliaresBeneficiados,
            [`qtdProfissionaisFormados/${i}`]:
              actions.prevencaoEmFamilia?.porRegiao[i]
                .qtdProfissionaisFormados,
            [`regiao/${i}`]:
              actions.prevencaoEmFamilia?.porRegiao[i]
                .regiao,
          },
        };
      });

    return action;
  }
  if (nameAction === 'Sementes do Amanhã') {
    let action = {
      ...actions.sementesDoAmanha,
      periodo: {
        dataInicio: moment(actions.sementesDoAmanha?.periodo?.dataInicio),
        dataFim: moment(actions.sementesDoAmanha?.periodo?.dataFim),
      },
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };

    Array(actions.sementesDoAmanha?.porRegiao.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`porRegiao/`]: {
            ...action[`porRegiao/`],
            [`municipiosParticipantes/${i}`]:
              actions.sementesDoAmanha?.porRegiao[i].municipiosParticipantes,
            [`qtdCapacitacoes/${i}`]:
              actions.sementesDoAmanha?.porRegiao[i].qtdCapacitacoes,
            [`qtdGestantesPuerperasBeneficiadas/${i}`]:
              actions.sementesDoAmanha?.porRegiao[i]
                .qtdGestantesPuerperasBeneficiadas,
            [`qtdProfissionaisFormados/${i}`]:
              actions.sementesDoAmanha?.porRegiao[i]
                .qtdProfissionaisFormados,
            [`regiao/${i}`]:
            actions.sementesDoAmanha?.porRegiao[i]
              .regiao,
          },
        };
      });
  
    return action;
  }
  if (nameAction === 'Seminário Internacional Mais Infância Ceará') {
    const action = {
      ...actions.seminarioMaisInfancia,
      periodo: {
        dataInicio: moment(actions.seminarioMaisInfancia?.periodo?.dataInicio),
        dataFim: moment(actions.seminarioMaisInfancia?.periodo?.dataFim),
      },
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
    return action;
  }
  if (nameAction === 'Educação Ambiental') {
    const action = {
      ...actions.educacaoAmbiental,
      dataInauguracao: moment(actions.educacaoAmbiental?.dataInauguracao),
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
    return action;
  }
  if (nameAction === 'Vale Gás') {
    const action = {
      ...actions.valeGas,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
    return action;
  }
  if (nameAction === 'Padin') {
    let action: any = actions;
 
    if (actions.padin?.formacao) {
      action = {
        ...action.padin,
        id: actions.id,
        key: actions.id,
        ultimaModificacao: actions.ultimaModificacao,
        ultimoEditor: actions.ultimoEditor,
        formacaoSeduc: {
          ...action.formacao,
          periodo: {
            dataInicio: moment(actions.padin.formacao.ciclosPadin?.periodo?.dataInicio),
            dataFim: moment(actions.padin.formacao.ciclosPadin.periodo?.dataFim),
          },
        },
      }

      
       Array(actions.padin.formacao.ciclosPadin.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`dataFim${i}`]:  moment(
            actions.padin?.formacao.ciclosPadin[i].periodo?.dataFim
          ),
          [`dataInicio${i}`]:  moment(
            actions.padin?.formacao.ciclosPadin[i].periodo?.dataInicio
          ),
            formacao: {
              ...action.formacao,
             
              [`ciclosPadin/`]: {
                ...action.formacao[`ciclosPadin/`],
                [`cargaHoraria/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].cargaHoraria,
                [`conteudoProgramatico/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].conteudoProgramatico,
                [`qtdAdis/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].qtdAdis,
                [`qtdArticuladoresRegionaisMaisInfancia/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].qtdArticuladoresRegionaisMaisInfancia,  
                [`qtdFormadores/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].qtdFormadores,  
                [`qtdSupervisores/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].qtdSupervisores,  
                [`qtdTecnicosCrede/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].qtdTecnicosCrede,  
                [`qtdTecnicosSeduc/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].qtdTecnicosSeduc, 
                [`polos/${i}`]: 
                  makeOptitionsNamesCurrentPOLOS(actions.padin?.formacao.ciclosPadin[i].polos), 
                [`municipios/${i}`]: 
                  actions.padin?.formacao.ciclosPadin[i].municipios,
              },
          }
        };
      });

      return action;
    } else if (actions.padin?.visitaMunicipio || actions.padin?.encontrosComunitarios ){
      const action = {
        ...actions.padin,
        id: actions.id,
        key: actions.id,
        ultimaModificacao: actions.ultimaModificacao,
        ultimoEditor: actions.ultimoEditor,
      };
      return action;
    }
    return actions.padin;
  }

  if (nameAction === 'Publicações') {
    const action = {
      ...actions.publicacoes,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
      lancamento: moment(actions.publicacoes?.lancamento),
    };
    return action;
  }

  if (nameAction === 'Mais Nutrição') {
    let action: any = actions;
    if (action.maisNutricao?.maisNutricaoCapacitacao?.dataCapacitacao) {
      action = {
        ...action.maisNutricao,
        id: actions.id,
        key: actions.id,
        ultimaModificacao: actions.ultimaModificacao,
        ultimoEditor: actions.ultimoEditor,
        maisNutricaoCapacitacao: {
          ...action.maisNutricao?.maisNutricaoCapacitacao,
          dataCapacitacao: moment(
            action.maisNutricao?.maisNutricaoCapacitaca?.dataCapacitacao,
          ),
        },
      };
    }
    if (action.maisNutricao?.maisNutricaoDoacao) {
      action = {
        ...action.maisNutricao,
        id: actions.id,
        key: actions.id,
        ultimaModificacao: actions.ultimaModificacao,
        ultimoEditor: actions.ultimoEditor,
        maisNutricaoDoacao: {
          ...action.maisNutricao?.maisNutricaoDoacao,
          periodoSemanal: {
            dataInicio: moment(
              action.maisNutricao?.maisNutricaoDoacao?.periodoSemanal
                ?.dataInicio,
            ),
            dataFim: moment(
              action.maisNutricao?.maisNutricaoDoacao?.periodoSemanal?.dataFim,
            ),
          },
        },
      };

      Array(action.maisNutricaoDoacao?.entidadeMunicipios?.length)
        .fill(0)
        .forEach((_, i) => {
          action = {
            ...action,
            maisNutricaoDoacao: {
              ...action.maisNutricaoDoacao, // eslint-disable-next-line no-useless-computed-key
              ['entidadeMunicipios/']: {
                ...action.maisNutricaoDoacao['entidadeMunicipios/'],
                [`qtdEntidades/${i}`]:
                  actions.maisNutricao?.maisNutricaoDoacao?.entidadeMunicipios[
                    i
                  ]?.qtdEntidades,
                [`municipio/${i}`]:
                  actions.maisNutricao?.maisNutricaoDoacao?.entidadeMunicipios[
                    i
                  ]?.municipio,
              },
            },
          };
        });
    }
    if(action.maisNutricao?.cestasBasicasPandemia) {
      action = {
        ...action.maisNutricao,
        id: actions.id,
        key: actions.id,
        ultimaModificacao: actions.ultimaModificacao,
        ultimoEditor: actions.ultimoEditor,
        maisNutricaoCapacitacao: {
          ...action.maisNutricao?.cestasBasicasPandemia,
        },
      };
    }

    return action;
  }
  if (nameAction === 'Criança Feliz') {
    let action = {
      ...actions.criancaFeliz,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
    if (actions.criancaFeliz?.registroCapacitacao) {
      action = {
        ...action,
        registroCapacitacao: {
          ...action.registroCapacitacao,
          periodo: {
            dataInicio: moment(actions.criancaFeliz.registroCapacitacao?.periodo?.dataInicio),
            dataFim: moment(actions.criancaFeliz.registroCapacitacao?.periodo?.dataFim),
          },
        },
      };
    } else if (actions.criancaFeliz?.registroAcumulado) {
      action = {
        ...action,
        registroAcumulado: {
          ...action.registroAcumulado,
          periodo: {
            dataInicio: moment(actions.criancaFeliz.registroAcumulado?.periodo?.dataInicio),
            dataFim: moment(actions.criancaFeliz.registroAcumulado?.periodo?.dataFim),
          },
        },
      };
    }
    return action;
  }

  if (nameAction === 'Capacitação') {
    let action = {
      ...actions.capacitacao,
      periodo: {
        dataInicio: moment(actions.capacitacao?.periodo?.dataInicio),
        dataFim: moment(actions.capacitacao?.periodo?.dataFim),
      },
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
    };
    Array(actions.capacitacao?.porMunicipioCapacitacoes.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`porMunicipioCapacitacoes/`]: {
            ...action[`porMunicipioCapacitacoes/`],
            [`nomeMunicipio/${i}`]:
              actions.capacitacao?.porMunicipioCapacitacoes[i].nomeMunicipio,
            [`numeroCapacitacoes/${i}`]:
              actions.capacitacao?.porMunicipioCapacitacoes[i]
                .numeroCapacitacoes,
            [`numeroPessoasFormadas/${i}`]:
              actions.capacitacao?.porMunicipioCapacitacoes[i]
                .numeroPessoasFormadas,
          },
        };
      });

    return action;
  }
  if (nameAction === 'Formação Seduc') {
    let action = actions.formacaoSeduc;
    action = {
      ...action,
      id: actions.id,
      key: actions.id,
      ultimaModificacao: actions.ultimaModificacao,
      ultimoEditor: actions.ultimoEditor,
      formacaoSeduc: {
        ...action.formacaoSeduc,
        periodo: {
          dataInicio: moment(actions.prevencaoEmFamilia?.periodo?.dataInicio),
          dataFim: moment(actions.prevencaoEmFamilia?.periodo?.dataFim),
        },
      },
    };

    
    Array(actions.formacaoSeduc?.ciclosSeduc.length)
      .fill(0)
      .forEach((_, i) => {
        action = {
          ...action,
          [`ciclosSeduc/`]: {
            ...action[`ciclosSeduc/`],
            [`periodo${i}/`]: {
              dataInicio: moment(
                actions.formacaoSeduc?.ciclosSeduc[i].periodo?.dataInicio,
              ),
              dataFim: moment(
                actions.formacaoSeduc?.ciclosSeduc[i].periodo?.dataFim,
              ),
            },
            [`conteudoProgramatico/${i}`]:
              actions.formacaoSeduc?.ciclosSeduc[i].conteudoProgramatico,
            [`participantesPorPerfil${i}/`]: {
              ...actions.formacaoSeduc?.ciclosSeduc[i].participantesPorPerfil,
            },
            [`cargaHoraria/${i}`]:
              actions.formacaoSeduc?.ciclosSeduc[i].cargaHoraria,
            [`municipios/${i}`]: makeOptitionsNamesCurrentCREDES(actions.formacaoSeduc?.ciclosSeduc[i].municipios),
          },
        };
      });

    return action;
  }
}
