import React, { useState } from 'react';

import './styles.scss';

type EstimatedCardProps = {
  withBackground?: boolean | false;
  number: string | JSX.Element,
  title: string,
  subtitle?: string;
  iconEnable: string;
  iconDisable: string;
  iconWifi?: string | false;
};

const EstimatedCard = ({ number, title, subtitle, iconEnable, iconDisable, withBackground, iconWifi }: EstimatedCardProps): JSX.Element => {
  const [iconsTotal] = useState<Array<number>>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  return (
    <section className={withBackground ? `container__estimated background` : `container__estimated`}>
      <h1>
        <span>{number}</span> em cada <span>10</span> {title} { subtitle && <small>{subtitle}</small>}
      </h1>
      <div className="grid-icons">
        {iconsTotal.map((_, index) => {
          return index < Number(number) ? (
            <img key={index} src={iconEnable} alt="Icon colorido" />
          ) : (
            <img key={index} src={iconDisable}  alt="Icon pintado preto e branco" />
          );
        })}
      </div>
      {
        iconWifi && 
          <div className="grid-icons">
            <img src={iconWifi} alt="Icon Casa" />
            <img src={iconWifi} alt="Icon Casa" />
            <img src={iconWifi} alt="Icon Casa" />
            <img src={iconWifi} alt="Icon Casa" />
            <img src={iconWifi} alt="Icon Casa" />
          </div>
        
      }
      

    </section>
  );
};

export default EstimatedCard;
