import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconBaby, IconHome, IconMom } from './utils/icons';

import './styles.scss';

interface NavigationCMICProps {
  type: 'gestante' | 'crianca' | 'domicilio';
}

const NavigationCMIC = ({ type }: NavigationCMICProps): JSX.Element => {
  const history = useHistory();

  const [isHouverMom, setIsHouverMom] = useState<boolean>(false);
  const [isHouverBaby, setIsHouverBaby] = useState<boolean>(false);
  const [isHouverHome, setIsHouverHome] = useState<boolean>(false);

  function handleClickButton(currentType: string) {
    history.push(`/pesquisa-cmic/${currentType}`);
  }

  function handleConvertedColor(current: string) {
    if (current === type) return '#ffff';
    return '#2D4040';
  }
  function handleConvertedColorInverse(current: string) {
    if (current === type) return '#2D4040';
    return '#fff';
  }
  function mouseOverMom(currentType: string) {
    if (currentType === 'gestante') setIsHouverMom(true);
    if (currentType === 'crianca') setIsHouverBaby(true);
    if (currentType === 'domicilio') setIsHouverHome(true);
  }
  function mouseOutMom(currentType: string) {
    if (currentType === 'gestante') setIsHouverMom(false);
    if (currentType === 'crianca') setIsHouverBaby(false);
    if (currentType === 'domicilio') setIsHouverHome(false);
  }
  return (
    <section className="container_navigationCMIC">
      <ul>
        <li
          className="navigation-li"
          style={{ background: handleConvertedColorInverse('crianca') }}
        >
          <button
            onClick={() => handleClickButton('crianca')}
            onMouseOver={() => mouseOverMom('crianca')}
            onMouseOut={() => mouseOutMom('crianca')}
            style={{ background: handleConvertedColorInverse('crianca') }}
          >
            <IconBaby color={handleConvertedColor('crianca')} />
          </button>
          {isHouverBaby && (
            <span
              style={{
                background: handleConvertedColorInverse('crianca'),
                color: handleConvertedColor('crianca'),
              }}
            >
              Criança
            </span>
          )}
        </li>
        <li className="navigation-li">
          <button
            onClick={() => handleClickButton('gestante')}
            onMouseOver={() => mouseOverMom('gestante')}
            onMouseOut={() => mouseOutMom('gestante')}
            style={{ background: handleConvertedColorInverse('gestante') }}
          >
            <IconMom color={handleConvertedColor('gestante')} />
          </button>
          {isHouverMom && (
            <span
              style={{
                background: handleConvertedColorInverse('gestante'),
                color: handleConvertedColor('gestante'),
              }}
            >
              Gestante
            </span>
          )}
        </li>
        <li className="navigation-li" style={{ background: '#ffffffs' }}>
          <button
            onClick={() => handleClickButton('domicilio')}
            onMouseOver={() => mouseOverMom('domicilio')}
            onMouseOut={() => mouseOutMom('domicilio')}
            style={{ background: handleConvertedColorInverse('domicilio') }}
          >
            <IconHome color={handleConvertedColor('domicilio')} />
          </button>
          {isHouverHome && (
            <span
              style={{
                background: handleConvertedColorInverse('domicilio'),
                color: handleConvertedColor('domicilio'),
              }}
            >
              Domicílio
            </span>
          )}
        </li>
      </ul>
    </section>
  );
};

export default NavigationCMIC;
