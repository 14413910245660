import { Device } from 'components';
import React, { ReactNode } from 'react';

import './styles.scss';
interface SectionInfosCMICProps {
  title: string;
  subtitle: string;
  children: ReactNode;
}

const SectionInfosCMIC = ({
  title,
  subtitle,
  children,
}: SectionInfosCMICProps): JSX.Element => {
  const device = Device.useDevice();

  return (
    <section className="container__infosCMIC">
      <div className="container__infosCMIC-top">
        <h1 className="title-top">{title}</h1>
        {children}
      </div>

      <h2 className={`subtitle-info ${device.isXS('exclusive') && 'isXS'}`}>
        {subtitle}
      </h2>
    </section>
  );
};

export default SectionInfosCMIC;
