import styled from 'styled-components';


export const FamilyListContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: familylistcontent;

  margin: 10px auto;
  max-width: 992px;
  width: 100%;

  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* margin-left: 10px; */
  overflow-y: scroll;
  
  .unique-family-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    transition: background .7s;

    &:hover {
      background: #ddd;
    }

    .personal-info, .personal-info-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .personal-info-right {
      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;

        color: #464A4E;
      }

      img {
        align-self: flex-end;
        width: 11px;
        height: 14px;
      }
    }
  }

  .ant-divider {
    margin: 0;
  }
`;
